export const validateFaixa = (value: string) => {
  if (!value) return true

  const number = Number(value)
  if (!number) {
    return false
  }

  if (number > 999_999) return false

  return true
}
