import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core'
import { WhatsApp } from '@material-ui/icons'
import { useRef, useState } from 'react'
import { exportComponentAsPNG } from 'react-component-export-image'
import snackbarStore from 'src/stores/snackbar'
import { fetchMessageLoteDivulgarWhatsapp } from '../hooks/lotesRepository'
import { useLote } from '../hooks/useLote'
import { LotesDetail } from '../types'
import { ImageToExport } from './ImageToExport'
import { WrapImage } from './WrapImage'

interface DialogPropsFrete {
  lote: LotesDetail
  modal: boolean
  handleModal: () => void
}

export function DivulgarFreteDialog({
  modal,
  handleModal,
  lote
}: DialogPropsFrete) {
  const componentRef = useRef<HTMLDivElement>(null)
  const [loadingWhatsapp, setLoadingWhatsapp] = useState(false)
  const [, actions] = useLote()

  const handleWhatsapp = async (loteId: string) => {
    setLoadingWhatsapp(true)
    const response = await fetchMessageLoteDivulgarWhatsapp(loteId)
    if (response.ok) {
      const texto = response.data
      window.open(`https://api.whatsapp.com/send?text=${texto}`, '_blank')
    } else {
      snackbarStore.setMessage((response.data as any).message)
    }
    setLoadingWhatsapp(false)
  }

  const handleClose = () => {
    handleModal()
    actions.reset()
  }

  return (
    <Dialog
      open={modal}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">{'Divulgação de Frete'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-description">
          <WrapImage ref={componentRef}>
            <ImageToExport lote={lote} />
          </WrapImage>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          onClick={() =>
            exportComponentAsPNG(componentRef, {
              fileName: 'Frete.png',
              html2CanvasOptions: {
                backgroundColor: '#4a001b'
              }
            })
          }
        >
          Baixar Imagem
        </Button>

        <Button
          style={{
            background: '#00e676'
          }}
          onClick={() => handleWhatsapp(lote.id)}
          autoFocus
          endIcon={
            <WhatsApp
              fontSize="large"
              style={{
                color: '#fff'
              }}
            />
          }
        >
          {loadingWhatsapp ? (
            <CircularProgress size={18} />
          ) : (
            <Typography variant="body2">Divulgar via WhatsApp</Typography>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
