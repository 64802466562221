import { useEffect, useState } from 'react'
import { isTratoHabilitado } from './lotesRepository'

export const useIsTratoHabilitado = () => {
  const [habilitado, setHabilitado] = useState(false)

  const fetch = async () => {
    isTratoHabilitado().then(response => {
      if (response.ok) {
        setHabilitado(response.data?.result ?? false)
      } else {
        setHabilitado(false)
      }
    })
  }

  useEffect(() => {
    fetch()

    return () => {
      setHabilitado(false)
    }
  }, [])

  return { isTratoHabilitado: habilitado, reload: fetch }
}
