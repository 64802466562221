import { Box } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import Chart from 'react-apexcharts'
import { ButtonSort } from 'src/components/ButtonSort'
import locales from '../chartLocales'
import { ChartSkeleton } from '../components/ChartSkeleton'
import dashboardStore from './store'

function GraficoCaminhoesPorCliente() {
  const [isAscendingQtd, setIsAscendingQtd] = useState<boolean>(true)
  const [isAscendingName, setIsAscendingName] = useState<boolean>(true)
  const { categories, series } = dashboardStore.graficoCaminhoesPorCliente

  const options: ApexCharts.ApexOptions = {
    chart: {
      id: 'caminhoes-cliente',
      defaultLocale: 'pt',
      locales,
      toolbar: {
        export: {
          csv: {
            headerCategory: 'Categoria'
          }
        }
      }
    },
    colors: ['#632336'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true
      }
    },
    xaxis: { categories },
    title: {
      text: 'Quantidade de Caminhões por Cliente',
      align: 'center',
      style: {
        fontFamily: 'Gilroy',
        fontWeight: 'normal',
        fontSize: '16px'
      }
    }
  }

  function handleSortQtd() {
    dashboardStore.sortDataCaminhoesClientePerQtd(isAscendingQtd)
    setIsAscendingQtd(isAscendingQtd => !isAscendingQtd)
  }

  function handleSortName() {
    dashboardStore.sortDataCaminhoesClientePerName(isAscendingName)
    setIsAscendingName(isAscendingName => !isAscendingName)
  }

  const chartLength =
    categories.length && categories.length > 10 ? categories.length * 30 : 495

  return (
    <>
      {dashboardStore.loading ? (
        <ChartSkeleton rows={22} title="Quantidade de Caminhões por Cliente" />
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="flex-end"
            position="absolute"
            zIndex={2}
          >
            <ButtonSort
              textSortName="Ordenar por cliente"
              textSortQuantity="Ordernar por quantidade"
              sortName={handleSortName}
              sortQuantity={handleSortQtd}
            />
          </Box>
          <div
            style={{
              overflow: 'auto',
              height: '512px'
            }}
          >
            <Chart
              options={options}
              series={series}
              type="bar"
              width="100%"
              height={chartLength}
            />
          </div>
        </>
      )}
    </>
  )
}

export default observer(GraficoCaminhoesPorCliente)
