import { Grid } from '@material-ui/core'
import { CheckboxInput } from 'src/packages/g10-components/CheckboxInput'

interface Props {
  prefix?: string
}

export const DadosLote = (props: Props) => {
  const prefix = props.prefix ? `config.${props.prefix}` : 'config'

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.codigo`} label="Código" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.descricao`} label="Descrição" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.operacaoKMM`} label="Operação KMM" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.status`} label="Status" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.numeroPedido`}
          label="Número do Pedido"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.freteEmpresa`} label="Frete Empresa" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.freteSubcontratadoPJ`}
          label="Frete Subcontratado PJ"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.freteSubcontratadoPF`}
          label="Frete Subcontratado PF"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.quantidadeRetirada`}
          label="Quantidade Retirada"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.quantidadeTotal`} label="Quantidade" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.saldo`} label="Saldo" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.dataInicio`}
          label="Data Início Retirada"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.dataTermino`}
          label="Data Término Retirada"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.tipoContratacao`}
          label="Tipo de Contratação"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.especie`} label="Espécie" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.emailCriador`} label="Criador" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.analistaResponsavel`}
          label="Responsável"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.previsaoMargem`}
          label="Previsão de Margem"
        />
      </Grid>
    </Grid>
  )
}
