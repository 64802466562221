import { UnidadeNegocioDetail } from '../unidades-negocio/types'

export interface CentroCusto {
  id?: string
  descricao: string
  unidadeNegocioId: string
  UnidadeNegocio?: UnidadeNegocioDetail
  status?: StatusCentroCusto
  telefone: string
  endereco: string
  cnpj: string
  nomeExibicao: string
  inscricaoEstadual: string
}

export interface ICentroCustoForm {
  id?: string
  descricao: string
  unidadeNegocioId: string
  telefone: string
  endereco: string
  cnpj: string
  nomeExibicao: string
  inscricaoEstadual: string
}

export interface CentroCustoDetail extends CentroCusto {
  id: string
  codigo: string
  _count?: {
    Cotacoes: number
    Lote: number
    NegociacoesNaoFechadas: number
    Programacao: number
  }
}

export interface VerifyCentroCustoUsed {
  Cotacoes: number
  Lote: number
  NegociacoesNaoFechadas: number
  Programacao: number
}

export type StatusCentroCusto = 'ATIVO' | 'INATIVO'

export enum StatusCentroCustoEnum {
  ATIVO = 'ATIVO',
  INATIVO = 'INATIVO'
}

export const statusCentroCusto = [
  { value: 'ATIVO', label: 'Ativo' },
  { value: 'INATIVO', label: 'Inativo' }
]
