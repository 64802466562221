import { IconButton, Tooltip } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import React from 'react'
import { Link } from 'react-router-dom'
import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import { CancelWithWarningRecord } from 'src/components/CancelWithWarningRecord'
import {
  DatePickerFilter,
  DefaultColumnFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import { EditarStatusRecord } from 'src/components/EditarStatusRecord'
import When from 'src/components/When'
import { formatDate } from 'src/utils/formatters'
import { status } from './types/types'

interface IContratosColumnProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const ContratoColumns = ({
  onDelete,
  authorization
}: IContratosColumnProps): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo',
      Filter: props =>
        DefaultColumnFilter({
          column: props.column as HeaderGroup<{}>,
          onlyNumber: true
        })
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: status
        })
    },
    {
      Header: 'Cliente',
      accessor: 'cliente'
    },
    {
      Header: 'Agrupador de Produtos',
      accessor: 'agrupadorProdutos'
    },
    {
      Header: 'Data Início Contrato',
      accessor: 'dataInicio',
      Cell: ({ value }: any) => <>{formatDate(new Date(value))}</>,
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Data Término Contrato',
      accessor: 'dataTermino',
      Cell: ({ value }: any) => <>{formatDate(new Date(value))}</>,
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <EditarStatusRecord
                  isStatusChangeable={props.row.original.isStatusChangeable}
                  status={props.row.original.status}
                  id={props.row.original.id}
                  optionsStatus={props.row.original.nextPossibleStatus}
                  isContratos
                />
              </When>
              <Tooltip
                title="Consultar registro"
                aria-label="Consultar registro"
              >
                <IconButton
                  component={Link}
                  to={`contratos/${props.row.original.id}?view=true`}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
              <When value={authorization?.canUpdate} equals>
                <CancelWithWarningRecord
                  title="Cancelar Contrato"
                  message="O status será alterado para Cancelado"
                  warningText="Não será possível realizar alterações no contrato, nem utilizá-lo para cadastrar novos lotes"
                  onDelete={() => onDelete(props.row.original.id)}
                  disabled={props.row.original.status === 'CANCELADO'}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
