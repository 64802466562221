import { TextField } from '@material-ui/core'
import { useField } from 'react-final-form'
import InputMask from 'react-input-mask'

interface FFMaskedInputProps {
  disabled?: boolean
  name: string
  label: string
  mask: string
  type?: string
  endAdornment?: React.ReactNode
}

function FFMaskedInput(props: FFMaskedInputProps) {
  const { input, meta } = useField(props.name)
  const { label, mask, type, endAdornment } = props

  return (
    <InputMask mask={mask} maskChar="" alwaysShowMask={false} {...input}>
      {(inputProps: unknown) => (
        <TextField
          {...inputProps}
          label={label}
          type={type}
          variant="outlined"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          fullWidth
          InputProps={{
            endAdornment,
            name: props.name,
            disabled: props.disabled
          }}
        />
      )}
    </InputMask>
  )
}

export default FFMaskedInput
