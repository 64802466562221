import { Dialog, DialogContent, Grid } from '@material-ui/core'
import { SimularFreteForm } from './SimularFreteForm'

interface Props {
  isOpen: boolean
  handleClickBack: () => void
  tabelaId: string
  pernaIndex: Nullable<number>
  loteId: string
}

export const SimularFreteModal = ({
  isOpen,
  handleClickBack,
  tabelaId,
  pernaIndex,
  loteId
}: Props) => {
  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClickBack}>
      <DialogContent>
        <Grid container justifyContent="space-between">
          <SimularFreteForm
            tabelaId={tabelaId}
            handleClickBack={handleClickBack}
            pernaIndex={pernaIndex}
            loteId={loteId}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  )
}
