interface CentroCusto {
  id: string
}

export interface UnidadeNegocio {
  id?: string
  codigo: string
  descricao: string
  margemEsperada: number
  CentroCusto?: CentroCusto[]
}

export interface UnidadeNegocioDetail extends UnidadeNegocio {
  id: string
  status: StatusUnidadesNegocio
}

export interface AlterarStatusData {
  newStatus: StatusUnidadesNegocio
}

export type StatusUnidadesNegocio = 'ATIVO' | 'INATIVO' | ''

export const statusUnidadesNegocio = [
  { value: 'ATIVO', label: 'Ativo' },
  { value: 'INATIVO', label: 'Inativo' }
]
