import { Button, Divider, Grid } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { createRef, MutableRefObject, useEffect, useState } from 'react'
import ReactToPrint from 'react-to-print'
import { MessagesYup } from 'src/modules/messages'
import { mapIdsSelectOptions } from 'src/utils/mapIdsSelectOptions'
import * as yup from 'yup'
import { Paper } from '../../components'
import DateInput from '../../components/Formik/Forms/DateInput'
import SelectInput from '../../components/Formik/Forms/SelectInput'
import Spacer from '../../components/Spacer'
import useDashboardStyles from '../home/dashboard/dashboard.styles'
import AsyncMultipleSelect from '../home/dashboard/filters/AsyncMultipleSelect'
import RelatorioPrint from './relatorioPrint/RelatorioPrint'
import relatoriosStore from './store'
import { Relatorio, tiposRelatorio } from './types'

const schema = yup.object().shape({
  tipoEmissao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  clientes: yup
    .array()
    .test(
      'tipo-cliente',
      MessagesYup.MENSAGEM_OBRIGATORIO,
      function (value: any, context: yup.TestContext<Record<string, any>>) {
        if (context.parent.tipoEmissao === 'CLIENTE') {
          return value.length >= 1
        }
        return true
      }
    ),
  centrosCusto: yup
    .array()
    .test(
      'tipo-centro-custo',
      MessagesYup.MENSAGEM_OBRIGATORIO,
      function (value: any, context: yup.TestContext<Record<string, any>>) {
        if (context.parent.tipoEmissao === 'CC') {
          return value.length >= 1
        }
        return true
      }
    ),
  dataInicioCarregamento: yup
    .date()
    .nullable()
    .typeError(MessagesYup.INVALID_DATE),
  dataFimCarregamento: yup.date().nullable().typeError(MessagesYup.INVALID_DATE)
})

interface Props {
  relatorio: Relatorio
}

export const RelatoriosList = ({ relatorio }: Props) => {
  const [printRefs, setPrintRefs] = useState<MutableRefObject<any>[]>([])

  useEffect(() => {
    if (relatorio.tipoEmissao !== '') {
      setPrintRefs(
        Array.from({ length: relatorio.pages.length }, () => createRef())
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatorio.pages.length])

  return (
    <>
      <Spacer y={4} />
      {relatorio.tipoEmissao !== '' && (
        <Paper>
          <Grid container xs={12} sm={12}>
            {relatorio.pages.map((element, indice) => (
              <>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: indice > 0 ? '4rem' : 0,
                    marginBottom: indice > 0 ? '1rem' : 0,
                    marginLeft: indice > 0 ? '.5rem' : 0,
                    marginRight: indice > 0 ? '.5rem' : 0
                  }}
                >
                  {indice > 0 && <Divider />}
                </Grid>
                <Grid item xs style={{ textAlign: 'end', marginBottom: 20 }}>
                  <ReactToPrint
                    documentTitle={`Relatório - Posição de Carregamento ${
                      relatorio.tipoEmissao === 'CLIENTE'
                        ? `Cliente ${element.headerName}`
                        : `Centro de Custo ${element.headerName}`
                    }`}
                    trigger={() => (
                      <Button variant="contained" color="primary">
                        Imprimir página <PrintIcon style={{ marginLeft: 10 }} />
                      </Button>
                    )}
                    content={() => printRefs[indice].current}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <RelatorioPrint
                    relatorio={element}
                    tipoEmissao={relatorio.tipoEmissao}
                    dataEmissao={relatorio.dataEmissao}
                  />
                </Grid>

                <div hidden>
                  <div style={{ padding: 7 }} ref={printRefs[indice]}>
                    <RelatorioPrint
                      relatorio={element}
                      tipoEmissao={relatorio.tipoEmissao}
                      dataEmissao={relatorio.dataEmissao}
                      isPrint={true}
                    />
                  </div>
                </div>
              </>
            ))}
          </Grid>
        </Paper>
      )}
    </>
  )
}

const RelatoriosFilter = observer(() => {
  const classes = useDashboardStyles()

  useEffect(() => {
    relatoriosStore.resetAll()
  }, [])

  return (
    <Paper>
      <Formik
        initialValues={relatoriosStore.filters}
        onSubmit={relatoriosStore.setFilters}
        enableReinitialize
        validationSchema={schema}
        onReset={() => {
          relatoriosStore.clearResponse()
        }}
      >
        {({ resetForm, values }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  name="tipoEmissao"
                  label="Tipo de emissão"
                  options={tiposRelatorio}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AsyncMultipleSelect
                  label="Clientes"
                  name="clientes"
                  url="/grupos-negociadores"
                  labelField="nome"
                  filters={{ notIn: mapIdsSelectOptions(values.clientes) }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AsyncMultipleSelect
                  label="Centros de Custo"
                  name="centrosCusto"
                  url="/centros-custo/ativos"
                  labelField="descricao"
                  filters={{ notIn: mapIdsSelectOptions(values.centrosCusto) }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DateInput
                  name="dataInicioCarregamento"
                  label="Data Inicio Carregamento"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <DateInput
                  name="dataFimCarregamento"
                  label="Data Fim Carregamento"
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      className={classes.filterAction}
                      variant="outlined"
                      onClick={() => {
                        resetForm()
                        relatoriosStore.clearFilters()
                      }}
                    >
                      Limpar
                    </Button>
                  </Grid>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      className={classes.filterAction}
                      type="submit"
                      color="primary"
                      variant="outlined"
                    >
                      Emitir
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Paper>
  )
})
const Relatorios = () => {
  return (
    <>
      <RelatoriosFilter />
      <RelatoriosList relatorio={relatoriosStore.relatoriosResponse} />
    </>
  )
}
export default observer(Relatorios)
