import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { ProdutoDetail } from '../types'

class ProdutosStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  produtos = new ApisauceDataStore<ProdutoDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/produtos',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}

export const produtosStore = new ProdutosStore()
export default ProdutosStore
