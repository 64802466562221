import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import useCanSave from 'src/hooks/useCanSave'
import { useDefaultGoBack } from 'src/hooks/useDefaultGoBack'
import { FormWrap } from 'src/packages/g10-components/FormWrap'
import { EntitySaver } from 'src/services/entitySaver'
import { ConfigRelatorioInterno } from '../components/ConfigRelatorio'
import { TipoRelatorioInput } from '../components/TipoRelatorioInput'
import { RelatorioInternoFormData, TipoRelatorioInterno } from '../types'
import { generateRelatorio } from '../utils/api'
import {
  initialValuesLote,
  initialValuesProgramacao
} from '../utils/initialValues'
import { adapterSwitch } from '../utils/switchers'
import { observer } from 'mobx-react-lite'
import relatorioInternoStore from '../stores'
import { useEffect } from 'react'

export const RelatorioInternoForm = observer(() => {
  const canSave = useCanSave({ tag: 'initial' }, 'Relatórios Internos')
  const goBack = useDefaultGoBack()

  const handleSubmit = async (values: RelatorioInternoFormData) => {
    const adapter = adapterSwitch[values.tipoRelatorio]
    const adaptedData = adapter.adapt(values)
    const url = adapter.getSubmitUrl()

    await EntitySaver(() => generateRelatorio(url, adaptedData), {
      onSuccess: () => goBack(),
      feedbackError: 'Erro ao gerar relatório.'
    })
  }

  useEffect(() => {
    return () => relatorioInternoStore.setSelected(TipoRelatorioInterno.LOTE)
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={
        relatorioInternoStore.selected === TipoRelatorioInterno.LOTE
          ? initialValuesLote
          : initialValuesProgramacao
      }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, errors, submitCount, isSubmitting }) => (
        <FormWrap
          nameButton="Gerar"
          handleSubmit={handleSubmit}
          errors={errors}
          loading={isSubmitting}
          submitCount={submitCount}
          disableSubmit={isSubmitting || !canSave}
          disableBack={isSubmitting}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TipoRelatorioInput />
            </Grid>
            <Grid item xs={12}>
              <ConfigRelatorioInterno />
            </Grid>
          </Grid>
        </FormWrap>
      )}
    </Formik>
  )
})
