import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { GrupoNegociadorDetail } from '../types'

class GrupoNegociadorStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  grupoNegociador = new ApisauceDataStore<GrupoNegociadorDetail>(
    apiSauceInstance,
    {
      useNodes: true,
      path: '/grupos-negociadores',
      resultsField: 'edges',
      countField: 'totalCount'
    }
  )
}
export const grupoNegociadorStore = new GrupoNegociadorStore()
export default GrupoNegociadorStore
