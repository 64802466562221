import { StatusProgramacao } from 'src/modules/programacoes/types'
import {
  CreateRelatorioProgramacaoDto,
  RelatorioInternoProgramacaoFormData
} from '../types'
import { ICreateRelatorioAdapter } from './CreateRelatorioAdapter.interface'

export class CreateRelatorioProgramacaoAdapter
  implements
    ICreateRelatorioAdapter<
      RelatorioInternoProgramacaoFormData,
      CreateRelatorioProgramacaoDto
    >
{
  adapt(
    values: RelatorioInternoProgramacaoFormData
  ): CreateRelatorioProgramacaoDto {
    const { config, filters } = values

    return {
      description: values.description || undefined,
      filters: {
        dataCarregamentoDe: filters.dataCarregamentoDe || undefined,
        dataCarregamentoAte: filters.dataCarregamentoAte || undefined,
        dataDescarregamentoDe: filters.dataDescarregamentoDe || undefined,
        dataDescarregamentoAte: filters.dataDescarregamentoAte || undefined,
        origem: filters.origemId?.name.split(' - ')[0],
        destino: filters.destinoId?.name.split(' - ')[0],
        placaPrincipal: filters.placaPrincipal || undefined,
        grupoNegociador: filters.grupoNegociadorId?.name || undefined,
        centroCustoId: filters.centroCustoId?.value || undefined,
        produto: filters.produtoId?.name || undefined,
        codigoLote: filters.codigoLote ? Number(filters.codigoLote) : undefined,
        cpfMotorista: filters.cpfMotorista || undefined,
        status: filters.status?.length
          ? filters.status.map(({ value }) => value as StatusProgramacao)
          : undefined,
        createdAtDe: filters.createdAtDe || undefined,
        createdAtAte: filters.createdAtAte || undefined
      },
      config: config
    }
  }

  getSubmitUrl() {
    return 'relatorios-internos/programacao'
  }
}
