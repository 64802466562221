import PaperMD from '@material-ui/core/Paper'
import { withStyles, WithStyles } from '@material-ui/core/styles'

type Props = {
  noTopBorderRadius?: boolean
} & WithStyles

export const Paper = withStyles(theme => ({
  root: {
    borderRadius: theme.spacing(2),
    boxShadow: '0px 4px 20px rgba(0,0,0,0.1)',
    padding: theme.spacing(3),
    position: 'relative'
  }
}))(({ classes, noTopBorderRadius, ...rest }: Props) => (
  <PaperMD
    {...rest}
    classes={classes}
    style={
      noTopBorderRadius
        ? { borderTopLeftRadius: 0, borderTopRightRadius: 0 }
        : undefined
    }
  />
))
