import { TextField, TextFieldProps } from '@material-ui/core'
import { useMemo } from 'react'

export interface HTMLNumericElement
  extends Omit<HTMLInputElement, 'value' | 'name'> {
  value: number | null | ''
  name?: string
}

export type CurrencyFieldProps = Omit<TextFieldProps, 'onChange'> & {
  value?: number | string
  onChange?(e: React.ChangeEvent<HTMLNumericElement>): void

  precision?: number
  thousandChar?: string
  decimalChar?: string
  maxValue?: number
}

function verifyNumber(string: string) {
  const numericRepresentation = string.replace(/[,.]/g, '')

  return {
    isNumber: !isNaN(Number(numericRepresentation)),
    numberFormat: !isNaN(Number(numericRepresentation))
      ? Number(numericRepresentation)
      : null
  }
}

function CurrencyField(props: CurrencyFieldProps) {
  const {
    value,
    precision = 2,
    thousandChar = '.',
    decimalChar = ',',
    ...inputProps
  } = props
  const defaultValue = value === '' ? NaN : Number(value)

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: precision,
        maximumFractionDigits: precision
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [thousandChar, decimalChar, precision]
  )

  if (!decimalChar) {
    throw new Error('Decimal char should not be an empty string!')
  }
  if (!thousandChar) {
    throw new Error('Thousand char should not be an empty string!')
  }

  function format(number: number) {
    const result = formatter
      .format(number)
      .replace(',', decimalChar)
      .replaceAll('.', thousandChar)

    return result
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    if (e.key === ' ') e.preventDefault()

    if (
      e.ctrlKey ||
      e.shiftKey ||
      e.key === 'Backspace' ||
      e.key === 'Enter' ||
      e.key === 'Tab' ||
      e.key === 'ArrowRight' ||
      e.key === 'ArrowLeft' ||
      e.key === 'Delete'
    )
      return
    if (!verifyNumber(e.key).isNumber) e.preventDefault()
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const newEvent: React.ChangeEvent<HTMLNumericElement> = {
      ...e,
      currentTarget: {
        ...e.currentTarget,
        name: props.name,
        value: 0
      },
      target: {
        ...e.target,
        name: props.name,
        value: 0
      }
    }
    let numericRepresentation = e.target.value

    numericRepresentation = numericRepresentation.replaceAll(thousandChar, '')
    numericRepresentation = numericRepresentation.replace(decimalChar, '')

    if (numericRepresentation === '') {
      e.target.value = ''
      newEvent.target.value = ''
      newEvent.currentTarget.value = ''
      return props.onChange && props.onChange(newEvent)
    }

    const { isNumber, numberFormat } = verifyNumber(numericRepresentation)

    if (isNumber && numberFormat !== null && numberFormat >= 0) {
      const withPrecision = numberFormat / 10 ** precision

      if (props.maxValue && withPrecision > props.maxValue) {
        return
      }

      const formattedNumber = format(withPrecision)

      newEvent.target.value = withPrecision
      newEvent.currentTarget.value = withPrecision

      e.target.value = formattedNumber

      props.onChange && props.onChange(newEvent)
    }
  }

  const hasValue = value !== undefined
  let inputDefaultValue
  let inputValue

  if (hasValue) {
    if (isNaN(defaultValue) || value === '') {
      inputValue = ''
    } else {
      inputValue = format(defaultValue)
    }
  }

  if (!hasValue && !isNaN(defaultValue)) {
    inputDefaultValue = format(defaultValue)
  }

  return (
    <TextField
      defaultValue={inputDefaultValue}
      {...inputProps}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      value={inputValue}
    />
  )
}

export default CurrencyField
