import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'

import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { TableCellHead, TableContainer } from 'src/components'
import NoResultsTable from 'src/components/NoResultsTable'
import { formatDate } from 'src/utils/formatters'

import store from '../../store/historico-programacoes-store'
import { HistoricoProgramacaoMotorista } from '../../types/historico-programacoes'

export const ModalHistoricoProgramacoes = observer(() => {
  useEffect(() => {
    store.historicoProgramacoes.populate()
  }, [])

  const renderTable = () => {
    return (
      <TableContainer
        totalCount={store.historicoProgramacoes.totalCount}
        loading={store.historicoProgramacoes.isLoading}
        disabledPagination
      >
        {store?.historicoProgramacoes?.list.length ? (
          <Table>
            <TableHead>
              <TableCellHead label="Data" />
              <TableCellHead label="Produto" />
              <TableCellHead label="Placa Cavalo" />
              <TableCellHead label="Capacidade" />
            </TableHead>
            <TableBody>
              {store?.historicoProgramacoes?.list.map(
                (programacao: HistoricoProgramacaoMotorista) => {
                  return (
                    <TableRow>
                      <TableCell>
                        {formatDate(programacao?.dataHoraCarregamento)}
                      </TableCell>
                      <TableCell>{programacao?.produto}</TableCell>
                      <TableCell>{programacao?.placaPrincipal}</TableCell>
                      <TableCell>
                        {programacao?.capacidadeCargaVeiculo}
                      </TableCell>
                    </TableRow>
                  )
                }
              )}
            </TableBody>
          </Table>
        ) : (
          <NoResultsTable loading={store?.historicoProgramacoes.isLoading} />
        )}
      </TableContainer>
    )
  }

  const renderTotalCount = () => {
    const totalCount = store?.historicoProgramacoes?.totalCount
    return (
      <Typography variant="body1">
        Ao todo:{' '}
        {totalCount > 1 ? `${totalCount} viagens` : `${totalCount} viagem`}
      </Typography>
    )
  }

  return (
    <Dialog
      open={store?.modalHistorico}
      onClose={() => store?.closeModalHistorico()}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Últimas Programações</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item sm={12}>
            {renderTable()}
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="flex-end"
          style={{ padding: '8px 0 16px' }}
        >
          {renderTotalCount()}
        </Grid>
      </DialogContent>
    </Dialog>
  )
})
