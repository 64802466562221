import {
  Button,
  Chip,
  ClickAwayListener,
  Grid,
  IconButton,
  Popper,
  TextField,
  Tooltip
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import EventIcon from '@material-ui/icons/Event'
import FilterListIcon from '@material-ui/icons/FilterList'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { isValid } from 'date-fns'
import * as ramda from 'ramda'
import React, { ReactNode, useEffect, useState } from 'react'
import { formatDate } from 'src/utils/formatters'
import { Paper } from '.'

export interface Filter {
  name: string
  title: string
  type: 'date' | 'string'
  value?: Date | string | null
}

interface CustomFilterProps {
  onSubmit: (filter: Filter[]) => void
  onRemove: (name: string) => void
  onReset: () => void
  filters: Filter[]
}

export const FilterIcon = ({
  title,
  Icon,
  handleOnClick
}: {
  title: string
  Icon: ReactNode
  handleOnClick: (event: any) => void
}) => {
  return (
    <Tooltip title={title} style={{ marginLeft: 15 }}>
      <IconButton onClick={handleOnClick}>{Icon}</IconButton>
    </Tooltip>
  )
}

export const CustomFilter = ({
  onSubmit,
  onRemove,
  onReset,
  filters
}: CustomFilterProps) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState(null)

  const [itemsFilter, setItemsFilter] = useState<Filter[]>(ramda.clone(filters))

  useEffect(() => {
    setItemsFilter(ramda.clone(filters))
  }, [filters])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
    setFilterOpen(filterOpen => !filterOpen)
  }

  const handleChange = (value: Date | string | null, name: string) => {
    const newItemsFilter = itemsFilter.map(item => {
      if (item.name === name) {
        item.value = value
      }
      return item
    })

    setItemsFilter(newItemsFilter)
  }

  const findItemFilterValueByName = (name: string): Date | string | null => {
    const itemFound = itemsFilter.find(filterValue => filterValue.name === name)
    return itemFound && itemFound.value ? itemFound.value : null
  }

  const activeFilterItems = filters.map((filter, index) => {
    if (filter.value) {
      let title = String(filter.value)
      if (filter.value instanceof Date) title = formatDate(filter.value)

      return (
        <Grid item key={index}>
          <Tooltip title={filter.title} placement="top" arrow>
            <Chip label={title} onDelete={() => onRemove(filter.name)} />
          </Tooltip>
        </Grid>
      )
    }
  })

  const validateItemsFilter = (): boolean =>
    itemsFilter.every(
      filterItem =>
        ramda.isNil(filterItem.value) ||
        isValid(filterItem.value) ||
        ramda.is(String, filterItem.value)
    )

  const clearFilter = () => {
    setFilterOpen(false)
    setItemsFilter(ramda.clone(filters))
  }

  return (
    <>
      <Grid container spacing={2}>
        {activeFilterItems}
        <FilterIcon
          title="Adicionar filtro"
          Icon={<FilterListIcon />}
          handleOnClick={handleClick}
        />
        <FilterIcon
          title="Cancelar Filtro"
          Icon={<ClearIcon />}
          handleOnClick={onReset}
        />
      </Grid>
      <Popper
        open={filterOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        style={{
          width: 450,
          zIndex: 100
        }}
      >
        <Paper noTopBorderRadius>
          <ClickAwayListener onClickAway={() => clearFilter()}>
            <Grid container spacing={2}>
              {itemsFilter.map((filter, index) => (
                <Grid item xs={12} key={index}>
                  {filter.type === 'date' && (
                    <KeyboardDatePicker
                      format="dd/MM/yyyy"
                      invalidDateMessage="A data informada é inválida."
                      inputVariant="outlined"
                      label={filter.title}
                      fullWidth
                      onChange={date => {
                        handleChange(date, filter.name)
                      }}
                      value={findItemFilterValueByName(filter.name)}
                      cancelLabel="Cancelar"
                      keyboardIcon={
                        <Tooltip title="Selecionar data">
                          <EventIcon />
                        </Tooltip>
                      }
                    />
                  )}
                  {filter.type === 'string' && (
                    <TextField
                      variant="outlined"
                      fullWidth
                      label={filter.title}
                      onChange={event => {
                        handleChange(event.target.value, filter.name)
                      }}
                      value={findItemFilterValueByName(filter.name)}
                    />
                  )}
                </Grid>
              ))}
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (validateItemsFilter()) {
                        setFilterOpen(false)
                        onSubmit(itemsFilter)
                      }
                    }}
                  >
                    Aplicar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </>
  )
}
