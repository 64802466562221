import { TextField } from '@material-ui/core'
import {
  Autocomplete,
  AutocompleteChangeReason,
  AutocompleteCloseReason,
  AutocompleteProps,
  AutocompleteRenderOptionState
} from '@material-ui/lab/'
import { useField } from 'formik'
import React, { ChangeEvent, useState } from 'react'

interface AutoCompleteProps<T extends Value<any>>
  extends Omit<AutocompleteProps<T, true, false, false>, 'renderInput'> {
  name: string
  label: string
  renderOptions?: (params: AutocompleteRenderOptionState) => React.ReactNode
}

interface Value<T> {
  label: string
  value: T
}

export const MultipleAutocompleteInput = <T extends Value<any>>({
  name,
  label,
  clearText = 'Limpar',
  closeText = 'Fechar',
  loadingText = 'Carregando...',
  noOptionsText = 'Sem opções',
  openText = 'Abrir',
  selectOnFocus = true,
  clearOnBlur = true,
  handleHomeEndKeys = true,
  options,
  renderOption,
  ...props
}: AutoCompleteProps<T>) => {
  const [open, setOpen] = useState(false)
  const [field, , helpers] = useField(name)

  const value = typeof field.value === 'string' ? null : field.value

  const handleChange = (
    e: ChangeEvent<{}>,
    value: T[],
    reason: AutocompleteChangeReason
  ) => {
    props.onChange?.(e, value, reason)

    helpers.setValue(value)
  }

  const handleClose = (
    _: React.ChangeEvent<{}>,
    reason: AutocompleteCloseReason
  ) => {
    if (!['select-option', 'remove-option'].includes(reason)) {
      setOpen(false)
    }
  }

  return (
    <Autocomplete
      multiple
      {...props}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={handleClose}
      onChange={handleChange}
      onBlur={field.onBlur}
      clearText={clearText}
      closeText={closeText}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      openText={openText}
      options={options}
      getOptionSelected={(option, value) => option.label === value?.label}
      getOptionLabel={option => option.label}
      value={value ?? []}
      selectOnFocus={selectOnFocus}
      clearOnBlur={clearOnBlur}
      handleHomeEndKeys={handleHomeEndKeys}
      renderInput={params => (
        <TextField {...params} variant="outlined" label={label} />
      )}
      renderOption={renderOption}
    />
  )
}
