import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import {
  GrupoNegociadorFilter,
  GrupoNegociadorQueryFilter
} from './GrupoNegociadorFilter'
import { GrupoNegociadorTable } from './GrupoNegociadorTable'
import { grupoNegociadorRepository } from './hooks/grupoNegociadorRepository'
import { grupoNegociadorStore } from './stores'

const GruposNegociadores = () => {
  const { initialValues } = useQueryParams<GrupoNegociadorQueryFilter>()
  const ability = useAbility()

  async function handleDelete(id: string) {
    const response = await grupoNegociadorRepository.delete(id)
    grupoNegociadorStore.grupoNegociador.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    grupoNegociadorStore.grupoNegociador.setPage(
      Number(initialValues.page) || 1
    )
    grupoNegociadorStore.grupoNegociador.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  const authorization = getAuthorizations(ability, 'Grupos Negociadores')

  return (
    <>
      <GrupoNegociadorFilter />
      <GrupoNegociadorTable
        authorization={authorization}
        onDelete={handleDelete}
      />
    </>
  )
}

export default GruposNegociadores
