import {
  AssessmentOutlined,
  AssignmentTurnedIn,
  Block,
  CategoryOutlined,
  Dashboard,
  Domain,
  Event,
  Explore,
  FlagOutlined,
  Group,
  ListAlt,
  LocalShipping,
  LocationCityOutlined,
  Money,
  Person,
  Public,
  Settings,
  ShoppingBasket
} from '@material-ui/icons'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AssignmentIcon from '@material-ui/icons/Assignment'
import CreateIcon from '@material-ui/icons/Create'
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import Receipt from '@material-ui/icons/LibraryBooks'
import MapIcon from '@material-ui/icons/Map'
import PlaceIcon from '@material-ui/icons/Place'
import { Item } from '../../components/AppWrap/MenuItems'
import AgrupadoresProdutos from '../agrupador-produtos/AgrupadoresProdutosList'
import { AgrupadorProdutosMasterDetail } from '../agrupador-produtos/master-detail/AgrupadorProdutosMasterDetail'
import CentrosCusto from '../centros-custo/CentrosCustoList'
import { CentroCustoMasterDetail } from '../centros-custo/master-detail/CentroCustoMasterDetail'
import Cidades from '../cidades/CidadesList'
import { CidadeMasterDetail } from '../cidades/master-detail/CidadeMasterDetail'
import Configuracoes from '../configuracoes/ConfiguracoesList'
import { ConfiguracoesMasterDetail } from '../configuracoes/master-detail/ConfiguracoesMasterDetail'
import ContratosList from '../contratos/ContratosList'
import { ContratosMasterDetail } from '../contratos/master-detail/ContratosMasterDetail'
import ControleLogs from '../controle-logs/ControleLogs'
import { Cotacoes } from '../cotacoes/CotacoesList'
import { CotacaoMasterDetail } from '../cotacoes/master-detail/CotacaoMasterDetail'
import Estados from '../estados/EstadosList'
import { EstadoMasterDetail } from '../estados/master-detail/EstadoMasterDetail'
import GruposNegociadores from '../grupos-negociadores/GrupoNegociadorList'
import { GrupoNegociadorMasterDetail } from '../grupos-negociadores/master-detail/GrupoNegociadorMasterDetail'
import { LocaisList } from '../locais/LocaisList'
import { LocalMasterDetail } from '../locais/master-detail/LocalMasterDetail'
import { LotesList } from '../lotes/LotesList'
import { LoteMasterDetail } from '../lotes/master-detail/LotesMasterDetail'
import MapaProgramacoes from '../mapa-programacoes/Mapa'
import Modalidades from '../modalidades/ModalidadesList'
import { ModalidadesMasterDetail } from '../modalidades/master-detail/ModalidadesMasterDetail'
import NegociacoesPerdidas from '../negociacoes-nao-fechadas/NegociacoesNaoFechadasList'
import { NegociacaoNaoFechadaMasterDetail } from '../negociacoes-nao-fechadas/master-detail/NegociacaoNaoFechadaMasterDetail'
import Pais from '../paises/PaisList'
import { PaisMasterDetail } from '../paises/master-detail/PaisMasterDetail'
import Pessoas from '../pessoas/PessoaList'
import { PessoaMasterDetail } from '../pessoas/master-detail/PessoaMasterDetail'
import Produtos from '../produtos/ProdutosList'
import { ProdutoMasterDetail } from '../produtos/master-detail/ProdutosMaterDetail'
import Programacoes from '../programacoes/ProgramacaoList'
import { ProgramacoesMasterDetail } from '../programacoes/master-detail/ProgramacoesMasterDetail'
import RelatoriosInternos from '../relatorios-internos/RelatorioInternoList'
import { RelatorioInternoForm } from '../relatorios-internos/master-detail/RelatorioInternoForm'
import RelatoriosList from '../relatorios/RelatoriosList'
import { SimularFreteContratoForm } from '../simular-frete-contrato/master-detail/SimularFreteContratoForm'
import TabelaICMS from '../tabela-icms/TabelaIcmsList'
import UnidadesNegocio from '../unidades-negocio/UnidadeNegocioList'
import { UnidadeNegocioMasterDetail } from '../unidades-negocio/master-detail/UnidadeNegocioMasterDetail'
import DashboardProgramacoes from './dashboard/programacoes/DashboardProgramacoes'

const menuItems: Item[] = [
  {
    name: 'Localidades',
    label: 'Localidades',
    icon: Explore,
    group: true,
    items: [
      {
        name: 'Países',
        label: 'Países',
        pathname: '/pais',
        icon: Public,
        group: false,
        list: Pais,
        detail: PaisMasterDetail
      },
      {
        name: 'Estados',
        label: 'Estados',
        pathname: '/estados',
        icon: FlagOutlined,
        group: false,
        list: Estados,
        detail: EstadoMasterDetail
      },
      {
        name: 'Cidades',
        label: 'Cidades',
        pathname: '/cidades',
        icon: LocationCityOutlined,
        group: false,
        list: Cidades,
        detail: CidadeMasterDetail
      },
      {
        name: 'Locais',
        label: 'Locais',
        icon: PlaceIcon,
        group: false,
        pathname: '/locais',
        list: LocaisList,
        detail: LocalMasterDetail
      }
    ]
  },
  {
    name: 'Agrupadores de Produtos',
    label: 'Agrupadores de Produtos',
    pathname: '/agrupadores-produtos',
    icon: GroupWorkIcon,
    group: false,
    list: AgrupadoresProdutos,
    detail: AgrupadorProdutosMasterDetail
  },
  {
    name: 'Produtos',
    label: 'Produtos',
    pathname: '/produtos',
    icon: ShoppingBasket,
    group: false,
    list: Produtos,
    detail: ProdutoMasterDetail
  },
  {
    name: 'Unidades de Negócio',
    label: 'Unidades de Negócio',
    pathname: '/unidades-negocio',
    icon: Domain,
    group: false,
    list: UnidadesNegocio,
    detail: UnidadeNegocioMasterDetail
  },
  {
    name: 'Centros de Custo',
    label: 'Centros de Custo',
    pathname: '/centros-custo',
    icon: Money,
    group: false,
    list: CentrosCusto,
    detail: CentroCustoMasterDetail
  },
  {
    name: 'Modalidades',
    label: 'Modalidades',
    pathname: '/modalidades',
    icon: FeaturedPlayListOutlinedIcon,
    group: false,
    list: Modalidades,
    detail: ModalidadesMasterDetail
  },
  {
    name: 'Pessoas',
    label: 'Pessoas',
    pathname: '/pessoas',
    icon: Person,
    group: false,
    list: Pessoas,
    detail: PessoaMasterDetail
  },
  {
    name: 'Grupos Negociadores',
    label: 'Grupos Negociadores',
    pathname: '/grupos-negociadores',
    icon: Group,
    group: false,
    list: GruposNegociadores,
    detail: GrupoNegociadorMasterDetail
  },
  {
    name: 'Negociações não fechadas',
    label: 'Negociações Não Fechadas',
    pathname: '/negociacoes-perdidas',
    icon: Block,
    group: false,
    list: NegociacoesPerdidas,
    detail: NegociacaoNaoFechadaMasterDetail
  },
  {
    name: 'Cotações',
    label: 'Cotações',
    pathname: '/cotacoes',
    icon: CreateIcon,
    group: false,
    list: Cotacoes,
    detail: CotacaoMasterDetail
  },
  {
    name: 'Lotes',
    label: 'Lotes',
    pathname: '/lotes',
    icon: CategoryOutlined,
    group: false,
    list: LotesList,
    detail: LoteMasterDetail
  },
  {
    name: 'Programações',
    label: 'Programações',
    pathname: '/programacoes',
    icon: Event,
    group: false,
    list: Programacoes,
    detail: ProgramacoesMasterDetail
  },
  {
    name: 'Validação de Lotes',
    label: 'Validar Lotes',
    pathname: '/lotes-validacao',
    icon: AssignmentTurnedIn,
    group: false,
    list: LotesList,
    detail: LoteMasterDetail
  },
  {
    name: 'Controle de Logs',
    label: 'Controle de Logs',
    pathname: '/controle-logs',
    icon: AssignmentIcon,
    group: false,
    list: ControleLogs
  },
  {
    name: 'Configurações',
    label: 'Configurações',
    pathname: '/configuracoes',
    icon: Settings,
    group: false,
    list: Configuracoes,
    detail: ConfiguracoesMasterDetail
  },
  {
    name: 'Tabela ICMS',
    label: 'Tabela ICMS',
    pathname: '/tabela-icms',
    icon: ListAlt,
    group: false,
    list: TabelaICMS
  },
  {
    name: 'Contratos',
    label: 'Contratos',
    icon: Receipt,
    group: false,
    pathname: '/contratos',
    list: ContratosList,
    detail: ContratosMasterDetail
  },
  {
    name: 'Dashboard',
    label: 'Dashboard Programações',
    icon: Dashboard,
    group: false,
    pathname: '/',
    list: DashboardProgramacoes
  },
  {
    name: 'Relatórios',
    label: 'Relatórios',
    icon: AssessmentIcon,
    group: false,
    pathname: '/relatorios',
    list: RelatoriosList
  },
  {
    name: 'Relatórios Internos',
    label: 'Relatórios Internos',
    icon: AssessmentOutlined,
    group: false,
    pathname: '/relatorios-internos',
    list: RelatoriosInternos,
    detail: RelatorioInternoForm
  },
  {
    name: 'Simular Frete Contrato',
    label: 'Simular Frete Contrato',
    icon: LocalShipping,
    group: false,
    pathname: '/simular-frete-contrato',
    list: SimularFreteContratoForm
  },
  {
    name: 'Mapas de Programações',
    label: 'Mapas de Programações',
    icon: MapIcon,
    group: false,
    pathname: '/mapa',
    list: MapaProgramacoes
  }
]

export default menuItems
