import { TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useField } from 'formik'
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { apiSauceInstance } from 'src/services/api'

export interface SelectOption {
  value: string
  name: string
}

interface Value {
  name: string
  value: string
  [key: string]: any
}

interface MultipleSelectProps {
  name: string
  label: string
  url: string
  labelField: string
  placeholder?: string
  size?: 'small' | 'medium'
  filters?: Record<string, any>
  joinStateAndCity?: boolean
}

export default function AsyncMultipleSelect(props: MultipleSelectProps) {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState<Value[]>([])
  const [field, meta, helpers] = useField(props.name)

  async function handleSearch(search?: string): Promise<void> {
    setLoading(true)

    const response = await apiSauceInstance.get<any>(props.url, {
      [props.labelField]: search,
      limit: 10,
      ...props.filters
    })

    setLoading(false)

    setOptions(
      response.data?.edges?.map((currentValue: any) => {
        const name = props.joinStateAndCity
          ? `${currentValue.node[props.labelField]} - ${
              currentValue.node.Estado.sigla
            }`
          : currentValue.node[props.labelField]

        return {
          name,
          value: currentValue.node.id
        }
      }) ?? []
    )
  }

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  useEffect(() => {
    helpers.setValue([])
  }, [props.url])

  return (
    <Autocomplete
      multiple
      open={open}
      clearText="Limpar"
      onOpen={() => {
        setOpen(true)
        handleSearch()
      }}
      onClose={() => setOpen(false)}
      onChange={(_e, value) => helpers.setValue(value)}
      onInputChange={debounce((_e, value) => handleSearch(value), 700)}
      options={options}
      loading={loading}
      size={props.size}
      value={field.value}
      getOptionLabel={option => option.name}
      filterSelectedOptions
      noOptionsText="Sem opções"
      loadingText="Carregando..."
      renderInput={params => (
        <TextField
          {...params}
          variant="outlined"
          label={props.label}
          placeholder={props.placeholder}
          error={meta.touched && !!meta.error}
          helperText={meta.touched && meta.error}
        />
      )}
    />
  )
}
