import {
  Button,
  CircularProgress,
  Divider,
  Typography
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { formatLabelPerson } from 'src/modules/pessoas/utils'
import { EntitySaver, ErrorApiResponse } from 'src/services/entitySaver'
import snackbarStore from 'src/stores/snackbar'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import MaskInput from '../../../components/Formik/Forms/MaskInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import SimpleDialog from '../../../components/SimpleDialog'
import scrollToError from '../../../utils/scrollToError'
import {
  forceSaveOrChange,
  grupoNegociadorRepository
} from '../hooks/grupoNegociadorRepository'
import { useGrupoNegociador } from '../hooks/useGrupoNegociador'
import {
  GrupoNegociadorDetail,
  GrupoNegociadorDto,
  GrupoNegociadorFormData
} from '../types'
import { schema } from '../validation'
import { AvisoInput } from './AvisoInput'
import PessoasInput from './PessoasInput'
import { buildSubmitData } from '../utils/buildSubmitData'

const initialValues: GrupoNegociadorFormData = {
  nome: '',
  raizCnpj: '',
  pessoas: [],
  deletedPessoas: [],
  avisos: []
}

export const GrupoNegociadorForm = () => {
  const [grupoNegociadorState] = useGrupoNegociador()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const canSave = useCanSave(grupoNegociadorState, 'Grupos Negociadores')

  const [isModalOpen, setIsModalOpen] = useState(false)

  async function forceSaveFunction(grupoNegociador: GrupoNegociadorFormData) {
    setLoading(true)

    const data: GrupoNegociadorDto = buildSubmitData(
      grupoNegociador,
      grupoNegociadorState
    )

    if (!!grupoNegociador.id) {
      await forceSaveOrChange(data, grupoNegociador.id)
    } else {
      await forceSaveOrChange(data)
    }

    setTimeout(() => {
      setLoading(false)
      snackbarStore.setMessage('Alteração realizada com sucesso!')
    }, 1000)
  }

  async function handleSubmit(grupoNegociador: GrupoNegociadorFormData) {
    setLoading(true)

    const data: GrupoNegociadorDto = buildSubmitData(
      grupoNegociador,
      grupoNegociadorState
    )

    await EntitySaver(() => grupoNegociadorRepository.save(data) as any, {
      feedbackError: 'Erro ao salvar grupo negociador',
      onSuccess: () => handleGoBack(),
      onError: err => {
        const error = err as ErrorApiResponse
        const messageResponseError =
          'Os usuários que você deseja adicionar neste grupo negociador já fazem parte de um grupo negociador.'
        if (!!error.message && error.message === messageResponseError) {
          setIsModalOpen(true)
        }
      }
    })
    setLoading(false)
  }

  function handleGoBack() {
    const newPath = window.location.pathname.split('/')
    newPath.pop()
    history.replace(newPath.join('/'))
  }

  function formatData(data: GrupoNegociadorDetail): GrupoNegociadorFormData {
    return {
      id: data.id,
      nome: data.nome,
      raizCnpj: data.raizCnpj || '',
      pessoas: data.Pessoas.map(pessoa => formatLabelPerson(pessoa)),
      deletedPessoas: [],
      avisos: data.Avisos.map(aviso => ({
        id: aviso.id,
        descricao: aviso.descricao,
        cor: aviso.cor
      }))
    }
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={
        grupoNegociadorState.tag === 'with-data'
          ? formatData(grupoNegociadorState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="nome" label="Nome" />
              </Grid>
              <Grid item xs={12}>
                <MaskInput
                  name="raizCnpj"
                  label="Raiz CNPJ (Opcional)"
                  mask="99.999.999"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Pessoas</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <PessoasInput name="pessoas" isGrupoNegociador />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5">Avisos</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <AvisoInput name="avisos" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={2}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <SimpleDialog
            title={'Você adicionará pessoas em um novo grupo negociador'}
            message={`Se você clicar em "CONFIRMAR" Você ira mudar pessoas de um grupo negociador para ${
              !!values.nome
                ? 'o grupo negociador ' + values.nome
                : `esse grupo em questão`
            }.`}
            open={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
            buttonLabel="CANCELAR"
            primaryAction={async () => {
              forceSaveFunction(values)
            }}
            primaryActionButtonLabel="CONFIRMAR"
          />
        </Form>
      )}
    </Formik>
  )
}
