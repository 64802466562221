import {
  Checkbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormGroup,
  FormGroupProps,
  FormHelperText
} from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'

export interface CheckboxInputProps
  extends Omit<CheckboxProps, 'name' | 'checked'> {
  name: string
  label?: string
  formControlLabelProps?: FormControlLabelProps
  formGroupProps?: FormGroupProps
  formControlProps?: FormControlProps
}

export const CheckboxInput = ({
  name,
  label,
  formControlLabelProps,
  formGroupProps,
  formControlProps,
  ...props
}: CheckboxInputProps) => {
  const [field, meta, helpers] = useField(name)

  function handleOnChange(
    value: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) {
    props.onChange?.(value, checked)

    helpers.setValue(checked)
  }

  if (label) {
    return (
      <FormControl {...formControlProps} error={meta.touched && !!meta.error}>
        <FormGroup {...formGroupProps}>
          <FormControlLabel
            {...formControlLabelProps}
            control={
              <Checkbox
                {...props}
                checked={field.value === true}
                onChange={handleOnChange}
              />
            }
            label={label}
          />
        </FormGroup>
        {meta.touched && meta.error && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}
      </FormControl>
    )
  }
  return (
    <Checkbox
      {...props}
      checked={field.value === true}
      onChange={handleOnChange}
    />
  )
}
