import { Checkbox, FormControlLabel } from '@material-ui/core'
import { useField } from 'formik'

interface SelectInputProps {
  label: string
  name: string
}

function SimpleCheckbox(props: SelectInputProps) {
  const [field] = useField(props)
  const { label, name } = props

  return (
    <FormControlLabel
      control={
        <Checkbox checked={field.value} color="primary" {...field} {...props} />
      }
      label={label}
    />
  )
}

export default SimpleCheckbox
