import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useAppBar } from '../../../hooks'
import { formatDate } from '../../../utils/formatters'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useCotacao } from '../hooks/useCotacao'
import { CotacaoForm } from './CotacaoForm'

export function CotacaoMasterDetail() {
  const [cotacaoNaoFechadaState, cotacaoNaoFechadaActions] = useCotacao()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      cotacaoNaoFechadaState,
      l => l.entity.id,
      l =>
        `${l.entity.cliente}, ${l.entity.produto} - ${formatDate(
          l.entity.dataCotacao
        )}`
    )
  ])

  useFetchAndReset(
    cotacaoNaoFechadaActions.fetch,
    cotacaoNaoFechadaActions.reset
  )

  return handleStateErrorsToRender(cotacaoNaoFechadaState, <CotacaoForm />)
}
