import { format } from 'date-fns'
import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import {
  DatePickerFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import StatusBadge from './StatusBadge'
import { statusLote } from './types'
import { formatStatusFilter } from './utils/formatStatusFilter'

interface ILotesValidationColumnsProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const LotesValidationColumns = (
  _: ILotesValidationColumnsProps
): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props =>
        props.value !== null ? (
          <StatusBadge status={props.value} marginLeft="30%" />
        ) : (
          <></>
        ),
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: statusLote,
          formatter: value => {
            return formatStatusFilter([value || 'TODOS'])
          }
        })
    },
    { Header: 'Criado por', accessor: 'emailCriador' },
    { Header: 'Analista Responsável', accessor: 'analistaResponsavel' },
    {
      Header: 'Data de Cadastro/Edição',
      accessor: 'updatedAt',
      Cell: props => (
        <div>{format(new Date(props.value), 'dd/MM/yyyy HH:mm:ss')}</div>
      ),
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    { Header: 'Cliente', accessor: 'cliente' },
    { Header: 'Origem', accessor: 'origem' },
    { Header: 'Destino', accessor: 'destino', sort: false },
    { Header: 'Produto', accessor: 'produto' }
  ]
}
