import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useFormikContext } from 'formik'
import NumericInput from '../../../components/Formik/Forms/NumericInput'
import { PureAsyncSearchInput } from '../../../components/Formik/Forms/PureAsyncSearchInput'
import SelectInput from '../../../components/Formik/Forms/SelectInput'
import { useLote } from '../hooks/useLote'
import { especiesOptions, tiposLote } from '../types'
import { isTipoContrato } from '../utils'

function changeLabel(tipoLote: string) {
  switch (tipoLote) {
    case 'VIAGEM':
      return 'Quantidade caminhões'
    case 'ABERTO':
      return ''
    default:
      return 'Quantidade (kg/lt)'
  }
}

export const ProdutoQuantidade = () => {
  const [loteState] = useLote()
  const { values } = useFormikContext<{
    tipoContratacao: string
    Contrato: {
      agrupadorProdutosId: string
    }
    tipoLote: string
  }>()

  const isUpdate = loteState.tag === 'with-data'

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Produto e Quantidade</Typography>
      </Grid>
      {isTipoContrato(values.tipoContratacao) ? (
        <Grid item xs={12} sm={3}>
          <PureAsyncSearchInput
            name="produtoId"
            label="Produto"
            url={`/agrupadores-produtos/${values.Contrato?.agrupadorProdutosId}/produtos`}
            labelField="nome"
            paramSearch="nome"
            disabled={!values.Contrato?.agrupadorProdutosId}
          />
        </Grid>
      ) : (
        <Grid item xs={12} sm={3}>
          <PureAsyncSearchInput
            name="produtoId"
            label="Produto"
            url="/produtos"
            labelField="nome"
            paramSearch="nome"
          />
        </Grid>
      )}
      <Grid item xs={12} sm={3}>
        <SelectInput name="especie" label="Espécie" options={especiesOptions} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SelectInput
          name="tipoLote"
          label="Tipo de Lote"
          options={tiposLote}
          disabled={isUpdate}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="quantidadeTotal"
          label={changeLabel(values.tipoLote)}
          precision={0}
          disabled={values.tipoLote === 'ABERTO'}
        />
      </Grid>
    </Grid>
  )
}
