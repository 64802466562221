import { TipoContratacao } from 'src/modules/lotes/types'
import { ProgramacaoFormDataAdapter } from '../adapters/ProgramacaoFormDataAdapter'
import { ProgramacaoDetail } from '../types'
import { ProgramacaoFormData } from '../types/formData'

export const formatData = (data: ProgramacaoDetail): ProgramacaoFormData => {
  const adapter = new ProgramacaoFormDataAdapter()
  const tipoContratacaoLotes = data.Lotes[0].tipoContratacao

  if (tipoContratacaoLotes === TipoContratacao.SPOT) {
    return adapter.fromLotesSpot(data)
  }

  return adapter.fromLotesContrato(data)
}
