import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid } from '@material-ui/core'
import { Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import useCanSave from 'src/hooks/useCanSave'
import { FormWrap } from 'src/packages/g10-components/FormWrap'
import { EntitySaver } from 'src/services/entitySaver'
import { ProgramacaoDtoAdapter } from '../adapters/ProgramacaoDtoAdapter'
import { ModalDesejaImprimirAutorizacao } from '../components/ModalDesejaImprimirAutorizacao'
import { programacoesRepository } from '../hooks/programacaoRepository'
import { useProgramacao } from '../hooks/useProgramacao'
import selecaoLotesStore from '../store/selecao-lotes-store'
import programacoesStore from '../store/store'
import { ProgramacaoFormData } from '../types/formData'
import { formatData } from '../util/formatData'
import { initialValues } from '../util/initialValues'
import { buildProgramacaoYupValidation } from '../validation/programacaoYupValidation'
import { schema } from '../validation/schema'
import { CadastroProgramacao } from './CadastroProgramacao'
import { InformacoesDaProgramacao } from './InformacoesDaProgramacao'

export const ProgramacaoForm = observer(() => {
  const [programacaoState] = useProgramacao()
  const canSave = useCanSave(programacaoState, 'Programações')
  const isUpdate = programacaoState.tag === 'with-data'
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const { save } = useEntitySaver<any>(async data => {
    const response = await programacoesRepository.save(data)
    return response
  })

  async function handleSubmit(values: ProgramacaoFormData) {
    const adapter = new ProgramacaoDtoAdapter()
    const adaptedData = adapter.adapt(values, isUpdate)
    setLoading(true)

    await EntitySaver(() => save(adaptedData) as any, {
      onSuccess: () => {
        setIsModalOpen(true)
        setLoading(false)
      },
      onError: () => {
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      },
      feedbackError: 'Erro ao salvar a Programação'
    })
  }

  useEffect(() => {
    selecaoLotesStore.lotes.resetList()
    selecaoLotesStore.resetOldSelection()
    programacoesStore.resetYupContext()
    programacoesStore.resetHasToReevaluateResumoValores()
  }, [])

  return (
    <Formik
      enableReinitialize
      validate={buildProgramacaoYupValidation(schema, {
        ...programacoesStore.yupContext,
        programacaoState
      })}
      initialValues={
        programacaoState.tag === 'with-data'
          ? formatData(programacaoState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, errors, submitCount, isSubmitting, values }) => (
        <FormWrap
          handleSubmit={handleSubmit}
          errors={errors}
          loading={loading}
          submitCount={submitCount}
          disableSubmit={
            isSubmitting ||
            loading ||
            !canSave ||
            programacoesStore.fetchingComposicao ||
            programacoesStore.hasToReevaluateResumoValores(values) ||
            isUpdate
          }
          disableBack={isSubmitting || loading}
        >
          <Grid container spacing={2}>
            {programacaoState.tag === 'with-data' && (
              <ModalDesejaImprimirAutorizacao
                open={isModalOpen}
                lotes={programacaoState.entity.Lotes}
              />
            )}
            <Grid item xs={12}>
              <InformacoesDaProgramacao />
            </Grid>
            <Grid item xs={12}>
              <CadastroProgramacao />
            </Grid>
          </Grid>
        </FormWrap>
      )}
    </Formik>
  )
})
