import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Formik } from 'formik'
import { unidadeNegocioStore } from 'src/modules/unidades-negocio/stores'
import SelectInput from '../../components/Formik/Forms/SelectInput'
import { alterarStatus } from '../../modules/unidades-negocio/hooks/unidadeNegocioRepository'
import { AlterarStatusData } from '../../modules/unidades-negocio/types'
import snackbarStore from '../../stores/snackbar'

interface UnidadesNegocioDialogProps {
  open: boolean
  handleClose: () => void
  primaryAction?: () => void
  status: string
  optionsStatus: string[]
  id: string
}

const initialValues: AlterarStatusData = {
  newStatus: ''
}

function UnidadesNegocioDialog({
  open,
  handleClose,
  primaryAction,
  status,
  optionsStatus,
  id
}: UnidadesNegocioDialogProps) {
  const handlePrimaryAction = () => {
    handleClose()
    primaryAction && primaryAction()
  }

  function getOptions(optionsStatus: string[]) {
    const options = optionsStatus.map((value: string) => {
      return { label: value, value }
    })
    return options
  }

  const handleSubmit = async (data: AlterarStatusData) => {
    const res = await alterarStatus(id, data)
    if (res.ok) {
      snackbarStore.setMessage('Status alterado com sucesso!')
      unidadeNegocioStore.unidadeNegocio.populate()
    } else {
      snackbarStore.setMessage(
        res.data?.message ?? 'Error ao alterar status do lote'
      )
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Editar Status</DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            values
          }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SelectInput
                    name="newStatus"
                    label="Selecionar status"
                    options={getOptions(optionsStatus)}
                  />
                </Grid>
                <Grid container justifyContent="flex-end">
                  <DialogActions>
                    <Button onClick={handleClose} color="default" autoFocus>
                      Voltar
                    </Button>
                    <Button
                      onClick={handlePrimaryAction}
                      disabled={!!!values.newStatus || isSubmitting}
                      color="primary"
                      type="submit"
                      autoFocus
                    >
                      Confirmar
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default UnidadesNegocioDialog
