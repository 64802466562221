import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useAbility } from '../login'
import { CidadesFilter, CidadesQueryFilter } from './CidadesFilter'
import { CidadesTable } from './CidadesTable'
import { cidadesRepository } from './hooks/cidadesRepository'
import { cidadesStore } from './stores'

const Cidades = () => {
  const { initialValues } = useQueryParams<CidadesQueryFilter>()

  const ability = useAbility()

  async function handleDelete(id: string) {
    const response = await cidadesRepository.delete(id)
    cidadesStore.cidades.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    cidadesStore.cidades.setPage(Number(initialValues.page) || 1)
    cidadesStore.cidades.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  const authorization = getAuthorizations(ability, 'Cidades')

  return (
    <>
      <CidadesFilter />
      <CidadesTable onDelete={handleDelete} authorization={authorization} />
    </>
  )
}

export default Cidades
