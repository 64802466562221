import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useFormikContext } from 'formik'
import { ChangeEvent } from 'react'
import TextInput from 'src/components/Formik/Forms/TextInput'
import ContratoInput from '../../../components/ContratoInput'
import { PureAsyncSearchInput } from '../../../components/Formik/Forms/PureAsyncSearchInput'
import SelectInput from '../../../components/Formik/Forms/SelectInput'
import SwitchInput from '../../../components/SwitchInput'
import {
  LotesDetail,
  LotesFormData,
  TipoContratacao,
  tiposContratacao
} from '../types'
import { isTipoContrato } from '../utils'
import { FetchState } from '@elentari/core/types'

interface Props {
  handleChange: {
    (e: ChangeEvent<any>): void
    <T = string | ChangeEvent<any>>(field: T): T extends ChangeEvent<any>
      ? void
      : (e: string | ChangeEvent<any>) => void
  }
  loteState: FetchState<LotesDetail>
}

export const DadosGerais = ({ handleChange, loteState }: Props) => {
  const { values, setFieldValue } = useFormikContext<LotesFormData>()

  const handleClickSegundaPerna = () => {
    setFieldValue('pernas', [values.pernas[0]])
  }

  const handleChangeTipoContratacao = (e: ChangeEvent<any>) => {
    if (isTipoContrato(e.target.value)) {
      setFieldValue('pedagio', false)
      setFieldValue('icms', false)
    }

    handleChange(e)
  }

  const clearContratoFields = () => {
    setFieldValue('contrato', null)
    setFieldValue('tabela', null)
    setFieldValue('Tabela', '')
    setFieldValue('Contrato', null)
  }

  const hasProgramacao =
    loteState.tag === 'with-data'
      ? loteState.entity.Programacoes!.length > 0
      : false

  const isLoteContrato =
    loteState.tag === 'with-data'
      ? loteState.entity.tipoContratacao === TipoContratacao.CONTRATO
      : false

  const disableGrupoNegociador = isLoteContrato && hasProgramacao

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Dados Gerais</Typography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextInput name="descricao" label="Descrição (opcional)" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <SelectInput
          name="tipoContratacao"
          label="Tipo de Contratação"
          disabled={loteState.tag === 'with-data'}
          options={tiposContratacao}
          onChange={(e: any) => {
            handleChangeTipoContratacao(e)
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PureAsyncSearchInput
          name="centroCustoId"
          label="Centro de Custo"
          url="centros-custo/ativos"
          labelField="descricao"
          paramSearch="descricao"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <PureAsyncSearchInput
          name="grupoNegociadorId"
          label="Cliente Contratante"
          url="/grupos-negociadores"
          labelField="nome"
          paramSearch="nome"
          handleOnChange={clearContratoFields}
          disabled={disableGrupoNegociador}
          allowSearchWhenDisabled
        />
      </Grid>
      {isTipoContrato(values.tipoContratacao) ? (
        <>
          <Grid item xs={12}>
            <Typography variant="h5">Dados do Contrato</Typography>
          </Grid>
          <Grid item xs={12}>
            <ContratoInput />
          </Grid>
        </>
      ) : (
        <Grid item xs={12}>
          <SwitchInput
            name="segundaPerna"
            label="Possui Troca de Nota Fiscal"
            onClick={handleClickSegundaPerna}
          />
        </Grid>
      )}
    </Grid>
  )
}
