import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { GrupoNegociadorForm } from './GrupoNegociadorForm'
import { useGrupoNegociador } from '../hooks/useGrupoNegociador'

export function GrupoNegociadorMasterDetail() {
  const [grupoNegociadorState, grupoNegociadorActions] = useGrupoNegociador()
  const [, appBarActions] = useAppBar()
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      grupoNegociadorState,
      u => u.entity.id,
      u => u.entity.nome
    )
  ])
  useFetchAndReset(grupoNegociadorActions.fetch, grupoNegociadorActions.reset)
  return handleStateErrorsToRender(
    grupoNegociadorState,
    <GrupoNegociadorForm />
  )
}
