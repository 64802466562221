import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import * as dateFns from 'date-fns'
import * as yup from 'yup'
import { SubmitValues } from '../../hooks/types'
import yupValidation from '../../utils/yupValidation'

export type PaisQueryFilter = {
  [key: string]: any
}

const schema = yup.object().shape({
  nome: yup.string()
})

export const PaisesFilter = () => {
  const { clear, push, initialValues } = useQueryParams<PaisQueryFilter>()
  const handleSubmitFilter = (values: SubmitValues & { page: number }) => {
    if (values.createdAt) {
      const dateFormatted = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )

      return push({
        ...values,
        createdAt: {
          gt: dateFormatted.toISOString()
        }
      })
    }
    push({
      ...values,
      createdAt: undefined
    })
  }

  const format = (initialValues: PaisQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }
  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container>
        <Grid item xs>
          <TextField fullWidth name="nome" label="Nome" />
        </Grid>
      </Grid>
    </Filter>
  )
}
