import userStore from 'src/stores/user'

const useIsAdmin = () => {
  const user = userStore.data
  if (user) {
    return user.rolesInSystem.includes('ADMIN')
  } else {
    return false
  }
}

export { useIsAdmin }
