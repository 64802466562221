import { Button, LinearProgress, Typography } from '@material-ui/core'
import { History } from '@material-ui/icons'
import Grid from '@material-ui/core/Grid'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { UserByScopeAndAbilityInput } from 'src/components/Formik/Forms/UserByScopeAndAbility'
import { isCellPhone } from 'src/components/TelefoneInput'
import { BlackDisabledMaskInput } from 'src/utils/BlackDisabledMaskInput'
import { BlackDisabledTextInput } from 'src/utils/BlackDisabledTextInput'
import DateInput from '../../../components/DateInput'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import MaskInput from '../../../components/Formik/Forms/MaskInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import TimeInput from '../../../components/TimeInput'
import { DadosLoteContrato } from '../components/DadosLoteContrato'
import { CPFMotoristaListener } from '../components/listeners/CPFMotoristaListener'
import { LotesListener } from '../components/listeners/LotesListener'
import { TipoPagamentoListener } from '../components/listeners/TipoPagamentoListener'
import { useProgramacao } from '../hooks/useProgramacao'
import programacoesStore from '../store/store'
import { cartaoList, tipoPagamentoOptions } from '../types'
import { ProgramacaoFormData } from '../types/formData'
import { initialValues } from '../util/initialValues'
import { ModalHistoricoProgramacoes } from '../components/ModalHistoricoProgramacoes'

import historicoProgramacoesStore from '../store/historico-programacoes-store'

export const CadastroProgramacao = observer(() => {
  const [programacaoState] = useProgramacao()
  const { values, setFieldValue } = useFormikContext<ProgramacaoFormData>()
  const isEditing = programacaoState.tag === 'with-data'

  const onChangeCentroCusto = () => {
    setFieldValue('embarcador', initialValues.embarcador)
  }

  const handleOpenModalHistorico = () => {
    historicoProgramacoesStore.setCpfMotorista(values.cpfMotorista)
    historicoProgramacoesStore.openModalHistorico()
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5">
          {isEditing ? 'Edição' : 'Cadastro'} de Programação
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <CPFMotoristaListener />
        <TipoPagamentoListener />
        <LotesListener />

        {historicoProgramacoesStore.modalHistorico && (
          <ModalHistoricoProgramacoes />
        )}

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {programacoesStore.fetchingComposicao && <LinearProgress />}
          </Grid>
          <Grid item xs={12} md={6}>
            <AsyncSearchInput
              name="centroCustoId"
              label="Centros de Custo"
              url="/centros-custo/ativos"
              labelField="descricao"
              handleOnChange={onChangeCentroCusto}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <UserByScopeAndAbilityInput
              name="embarcador"
              label="Embarcador"
              action="create"
              subject="Programações"
              lotacoes={values.centroCustoId && [values.centroCustoId.value]}
              labelField="name"
              disabled={isEditing}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextInput name="nomeResponsavel" label="Nome do Responsável" />
          </Grid>

          <Grid item xs={12} md={6}>
            <MaskInput
              name="telefoneResponsavel"
              label="Telefone do Responsável"
              mask={
                values.telefoneResponsavel &&
                isCellPhone(values.telefoneResponsavel)
                  ? '(99) 9 9999-9999'
                  : '(99) 9999-9999'
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <DateInput label="Data Carregamento" name="dataCarregamento" />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TimeInput label="Hora Carregamento" name="horaCarregamento" />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <DateInput
              label="Data Descarregamento"
              name="dataDescarregamento"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TimeInput
              label="Hora Descarregamento"
              name="horaDescarregamento"
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <MaskInput
              name="cpfMotorista"
              label="CPF"
              mask="999.999.999-99"
              disabled={isEditing}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <BlackDisabledTextInput
              name="motorista"
              label="Motorista"
              disabled={isEditing || programacoesStore.composicaoImportada}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <BlackDisabledMaskInput
              name="telefoneMotorista"
              label="Telefone (Opcional)"
              mask={
                values.telefoneMotorista &&
                isCellPhone(values.telefoneMotorista)
                  ? '(99) 9 9999-9999'
                  : '(99) 9999-9999'
              }
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <BlackDisabledMaskInput
              name="placaPrincipal"
              label="Placa Principal"
              mask="aaa9*99"
              disabled={isEditing || programacoesStore.composicaoImportada}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <BlackDisabledMaskInput
              name="placa_1"
              label="Placa Carreta 1 (Opcional)"
              mask="aaa9*99"
              disabled={isEditing || programacoesStore.composicaoImportada}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <BlackDisabledMaskInput
              name="placa_2"
              label="Placa Carreta 2 (Opcional)"
              mask="aaa9*99"
              disabled={isEditing || programacoesStore.composicaoImportada}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <BlackDisabledMaskInput
              name="placa_3"
              label="Placa Carreta 3 (Opcional)"
              mask="aaa9*99"
              disabled={isEditing || programacoesStore.composicaoImportada}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextInput
              name="capacidadeCargaVeiculo"
              label="Capacidade de Carga"
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextInput name="observacoes" label="Observação (opcional)" />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <SelectInput
              name="tipoPagamento"
              label="Tipo de Pagamento (opcional)"
              options={tipoPagamentoOptions}
              clearable
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <TextInput
              name="numeroCartao"
              label="Número do Cartão"
              disabled={!cartaoList.includes(values.tipoPagamento ?? '')}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Button
              title="Histórico do Motorista"
              variant="outlined"
              startIcon={<History />}
              disabled={values.cpfMotorista === ''}
              onClick={handleOpenModalHistorico}
            >
              Histórico do Motorista
            </Button>
          </Grid>

          <Grid item xs={12}>
            <DadosLoteContrato />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
})
