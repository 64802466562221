import { Grid } from '@material-ui/core'
import { ConsultaField } from 'src/components/ConsultaField'
import { LotesDetail } from '../types'

interface Props {
  lote: LotesDetail
}

export const InfoResumoQuantidadePrevista = ({ lote }: Props) => {
  return (
    <Grid>
      {lote.DestinoLote.map((destino, index) => {
        const quantidade = lote.LoteToProgramacao?.filter(
          ltp => destino.id === ltp.destinoLoteId
        ).reduce((acc, value) => acc + value.quantidadePrevista, 0)

        return (
          <Grid container spacing={4} key={index}>
            <Grid item xs={6}>
              <ConsultaField
                title="Local de Entrega"
                subtitle={`${
                  destino.localDescarregamento &&
                  destino.localDescarregamento + ','
                } ${destino.Cidade.nome} - ${destino.Cidade.Estado.sigla}`}
                subtitleVariant="body2"
              />
            </Grid>

            <Grid item xs={6}>
              <ConsultaField
                title="Quantidade"
                subtitle={quantidade}
                subtitleVariant="body2"
              />
            </Grid>
          </Grid>
        )
      })}
    </Grid>
  )
}
