import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik, FormikHelpers } from 'formik'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import DateInput from 'src/components/DateInput'
import DropzoneInput from 'src/components/DropzoneInput'
import FilesList from 'src/components/FilesList'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { useDefaultGoBack } from 'src/hooks/useDefaultGoBack'
import { MessagesYup } from 'src/modules/messages'
import { EntitySaver } from 'src/services/entitySaver'
import snackbarStore from 'src/stores/snackbar'
import { UPLOAD_ERROR_MESSAGE, uploadAnexos } from 'src/utils/anexo'
import * as yup from 'yup'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { contratosRepository } from '../hooks/contratosRepository'
import { useContrato } from '../hooks/useContrato'
import { ContratoDetail, ContratoFormData, ContratoSave } from '../types/types'

const initialValues: any = {
  grupoNegociadorId: '',
  descricao: '',
  dataInicio: new Date(),
  dataTermino: new Date(),
  agrupadorProdutosId: '',
  cadencia: '',
  observacoes: '',
  files: []
}

export const ContratoForm = () => {
  const [contratoState, actions] = useContrato()
  const [loading, setLoading] = useState<boolean>(false)
  const clearDropzoneRef = useRef(false)
  const canSave = useCanSave(contratoState, 'Contratos')
  const goBack = useDefaultGoBack()

  useEffect(() => {
    contratoState.tag === 'with-data' && actions.fetch(contratoState.entity.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const schema = yup.object().shape({
    grupoNegociadorId: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    agrupadorProdutosId: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    descricao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    dataInicio: yup
      .date()
      .max(yup.ref('dataTermino'), MessagesYup.MENSAGEM_DATA_INICIO_INVALIDA)
      .test(
        'dataTermino',
        'Selecione uma data válida!',
        date => !(Math.abs(moment().diff(moment(date), 'years')) >= 100)
      )
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    dataTermino: yup
      .date()
      .test(
        'dataTermino',
        'Selecione uma data válida!',
        date => !(Math.abs(moment().diff(moment(date), 'years')) >= 100)
      )
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    files: yup.array().nullable().typeError(MessagesYup.MENSAGEM_ERRO)
  })

  const disableInput =
    (contratoState.tag === 'with-data' &&
      contratoState.entity.status === 'CANCELADO') ||
    (contratoState.tag === 'with-data' &&
      contratoState.entity.status === 'ENCERRADO')

  const possuiLoteVinculado =
    contratoState.tag === 'with-data' &&
    !contratoState.entity.Tabelas.every(tabela => tabela._count.Lotes === 0)

  const { save } = useEntitySaver<ContratoSave>(async data => {
    const response = await contratosRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    if (response.ok) {
      clearDropzoneRef.current = !clearDropzoneRef.current
      contratoState.tag === 'with-data' && actions.fetch(response.data!.id!)
    }

    return response
  })

  const format = (data: ContratoDetail): ContratoFormData => {
    return {
      agrupadorProdutosId: {
        name: data.AgrupadorProdutos.nome,
        value: data.AgrupadorProdutos.id
      },
      dataInicio: data.dataInicio,
      dataTermino: data.dataTermino,
      descricao: data.descricao,
      files: [],
      grupoNegociadorId: {
        name: data.GrupoNegociador.nome,
        value: data.GrupoNegociador.id
      },
      cadencia: data.cadencia,
      id: data.id,
      observacoes: data.observacoes
    }
  }

  const handleSubmit = async (
    data: ContratoFormData,
    formikHelpers: FormikHelpers<any>
  ) => {
    const anexos = await uploadAnexos(data.files ?? [])

    if (!anexos) {
      setLoading(false)
      return snackbarStore.setMessage(UPLOAD_ERROR_MESSAGE)
    }

    if (contratoState.tag === 'with-data') {
      setLoading(true)

      const contratosFormatData = {
        id: data.id,
        descricao: data.descricao,
        agrupadorProdutosId: data.agrupadorProdutosId.value,
        grupoNegociadorId: data.grupoNegociadorId.value,
        observacoes: data.observacoes,
        dataInicio: data.dataInicio,
        dataTermino: data.dataTermino,
        cadencia: data.cadencia,
        anexos
      }

      await EntitySaver(
        () => contratosRepository.save(contratosFormatData) as any,
        {
          feedbackError: 'Erro ao salvar o contrato',
          onSuccess: () => {
            formikHelpers.setFieldValue('tabelas', [])
            handleGoBack()
          }
        }
      )

      setLoading(false)
    } else {
      setLoading(true)

      await save({
        id: data.id,
        descricao: data.descricao,
        agrupadorProdutosId: data.agrupadorProdutosId.value,
        grupoNegociadorId: data.grupoNegociadorId.value,
        observacoes: data.observacoes,
        dataInicio: data.dataInicio,
        dataTermino: data.dataTermino,
        cadencia: data.cadencia,
        anexos
      })
    }
  }

  const handleGoBack = () => {
    goBack()
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        contratoState.tag === 'with-data'
          ? format(contratoState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, values, submitCount }) => (
        <Form>
          <Paper noTopBorderRadius>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <AsyncSearchInput
                  name="grupoNegociadorId"
                  label="Cliente Contratante"
                  url="/grupos-negociadores"
                  labelField="nome"
                  disabled={disableInput || possuiLoteVinculado}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="descricao"
                  label="Descrição Contrato"
                  disabled={disableInput}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <DateInput
                  label="Data Início Retirada"
                  name="dataInicio"
                  disabled={disableInput}
                />
              </Grid>

              <Grid item xs={12} sm={3}>
                <DateInput
                  label="Data Limite Retirada"
                  name="dataTermino"
                  disabled={disableInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <AsyncSearchInput
                  name="agrupadorProdutosId"
                  label="Agrupador de Produtos"
                  url="agrupadores-produtos"
                  labelField="nome"
                  disabled={disableInput || possuiLoteVinculado}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="cadencia"
                  label="Cadência para Retirada (opcional)"
                  disabled={disableInput}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  name="observacoes"
                  label="Observações (opcional)"
                  disabled={disableInput}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <DropzoneInput
                  key={Number(clearDropzoneRef.current)}
                  name="files"
                  dropzoneTitle="Evidências da Negociação (Anexos)"
                  disabled={disableInput}
                />
              </Grid>

              {contratoState.tag === 'with-data' && (
                <Grid item xs={12} sm={12}>
                  <FilesList anexos={contratoState.entity.Anexos || []} />
                </Grid>
              )}
            </Grid>

            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12} sm={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={
                          isSubmitting || loading || !canSave || disableInput
                        }
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
