import { IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { GridRenderCellParams } from '@mui/x-data-grid'
import contratosStore from '../store/store'

export function DeleteButtonTabelaPedagio(params: GridRenderCellParams) {
  function handleDelete() {
    contratosStore.removeLinhaTabelaPedagio(params.id as string)
  }

  return (
    <>
      {contratosStore.tabelaPedagio.rows.length >= 1 && (
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </>
  )
}
