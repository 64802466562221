import { ModalidadeTransportador } from '../types'
import {
  CalcularValoresProgramacaoDto,
  PernaDadosComplementaresProgramacaoDto
} from '../types/dto'
import { LoteProgramacaoFormData } from '../types/formData'

export class CalcularValoresProgramacaoDtoAdapter {
  private adaptPernas(
    lote: LoteProgramacaoFormData
  ): PernaDadosComplementaresProgramacaoDto[] {
    return lote.pernas.map((perna, index) => ({
      ordem: index + 1,
      cidadeOrigemId:
        index > 0
          ? lote.pernas[index - 1].destinos[0].cidadeDestino.value
          : perna.cidadeOrigem.value,
      destinos: perna.destinos.map(destino => ({
        cidadeDestinoId: destino.cidadeDestino.value
      })),
      valores: {
        freteEmpresa: perna.freteEmpresa || 0,
        valorPedagio: perna.valorPedagio || 0,
        aliquotaIcms: perna.aliquotaIcms || 0,
        freteGrupo: perna.freteGrupoDiferente ? perna.freteGrupo : undefined,
        freteSubcontratado: perna.freteSubcontratado || 0
      }
    }))
  }

  adapt(
    lote: LoteProgramacaoFormData,
    modalidadeTransportador: ModalidadeTransportador
  ): CalcularValoresProgramacaoDto {
    return {
      modalidadeTransportador,
      possuiTrocaNotaFiscal: lote.possuiTrocaNotaFiscal,
      pernas: this.adaptPernas(lote)
    }
  }
}
