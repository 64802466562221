import { Grid, Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { useFormikContext } from 'formik'
import TipoNegociacaoEmpresaSelectInput from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import {
  ModalidadeTransportador,
  modalidadeTransportadorOptions
} from '../types'
import { ProgramacaoFormData } from '../types/formData'
import { ModalidadeTransportadorListener } from './listeners/ModalidadeTransportadorListener'

const modalidadesToShowTipoNegociacao: ModalidadeTransportador[] = [
  ModalidadeTransportador.AGREGADO,
  ModalidadeTransportador.TERCEIRO_PJ,
  ModalidadeTransportador.TERCEIRO_PF
]

export const DadosComplementares = () => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const hasToShowTipoNegociacaoSubcontratado =
    modalidadesToShowTipoNegociacao.includes(
      formik.values.modalidadeTransportador
    )

  return (
    <Grid container spacing={2}>
      <ModalidadeTransportadorListener />

      <Grid item xs={12}>
        <Typography variant="h5">Dados Complementares</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={11}>
            <SelectInput
              name="modalidadeTransportador"
              label="Modalidade do Transportador"
              options={modalidadeTransportadorOptions}
            />
          </Grid>

          <Grid item>
            <Tooltip
              title={
                <Typography variant="body1" gutterBottom>
                  Deverá ser selecionada a modalidade do veículo contratado para
                  efetuar este carregamento
                </Typography>
              }
              aria-label="Deverá ser selecionada a modalidade do veículo contratado para efetuar este carregamento"
            >
              <InfoIcon />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {hasToShowTipoNegociacaoSubcontratado && (
        <Grid item xs={12} md={6}>
          <TipoNegociacaoEmpresaSelectInput
            name="tipoNegociacaoSubcontratado"
            label="Tipo Negociação Subcontratado"
          />
        </Grid>
      )}
    </Grid>
  )
}
