import { Grid, InputAdornment } from '@material-ui/core'
import NumericInput from 'src/components/Formik/Forms/NumericInput'

export const AdicionalEntregaForm = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <NumericInput
          name="valorAdicional"
          label="Adicional de Entrega"
          inputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <NumericInput
          precision={0}
          name="franquiaEntregas"
          label="Franquia de Entrega"
        />
      </Grid>
    </Grid>
  )
}
