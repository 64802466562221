import { Box, Typography } from '@material-ui/core'

export function TabPanel(props: {
  [x: string]: any
  children: any
  value: any
  index: any
}) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} paddingTop={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}
