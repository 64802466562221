import { ModalidadeTransportador } from '../types'

export const modalidadeIsSubcontratado = (
  modalidadeTransportador: ModalidadeTransportador
) => {
  return [
    ModalidadeTransportador.AGREGADO,
    ModalidadeTransportador.TERCEIRO_PF,
    ModalidadeTransportador.TERCEIRO_PJ
  ].includes(modalidadeTransportador)
}
