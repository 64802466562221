import { Button, CircularProgress, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useEffect } from 'react'
import DateInput from 'src/components/DateInput'
import { PureAsyncSearchInput } from 'src/components/Formik/Forms/PureAsyncSearchInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { Paper } from 'src/components/Paper'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { CidadesDetail } from '../cidades/types'
import mapaProgramacoesStore from './store'
import { City, MapQueryDto } from './types'
import yupValidation from 'src/utils/yupValidation'
import { schema } from './validation/schema'

const initialValues: MapQueryDto = {
  visualizacao: 'ORIGEM',
  cidadeOrigem: null,
  cidadeDestino: null,
  dataCarregamento: new Date(),
  dataDescarregamento: null
}

const filterOptions = [
  { label: 'Origem', value: 'ORIGEM' },
  { label: 'Destino', value: 'DESTINO' }
]

export default function MapFilter() {
  const handleSubmit = async (data: MapQueryDto) => {
    mapaProgramacoesStore.setTypeFilter(data.visualizacao)
    const res = await apiSauceInstance.get<City[], { message: string }>(
      'mapas/mapa-calor-caminhoes-teia',
      {
        visualizacao: data.visualizacao,
        cidadeOrigem: data.cidadeOrigem?.value,
        cidadeDestino: data.cidadeDestino?.value,
        dataCarregamento: data.dataCarregamento,
        dataDescarregamento: data.dataDescarregamento
      }
    )

    if (res.ok) {
      mapaProgramacoesStore.setData(res.data!)
    } else {
      mapaProgramacoesStore.setData([])
      snackbarStore.setMessage(
        res.data?.message ?? 'Falha ao recuperar dados do mapa'
      )
    }
  }

  const handleReset = async () => {
    await handleSubmit(initialValues)
  }

  const joinStateAndCity = (data: { node: CidadesDetail } | CidadesDetail) => {
    const entity = 'node' in data ? data.node : data

    return {
      name: `${entity.nome} - ${entity.Estado.sigla}`,
      value: entity.id
    }
  }

  useEffect(() => {
    handleSubmit(initialValues)
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onReset={handleReset}
      validate={yupValidation(schema)}
    >
      {({ isSubmitting }) => (
        <Paper>
          <Form>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4} md={2}>
                <SelectInput
                  name="visualizacao"
                  label="Visualização"
                  options={filterOptions}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <PureAsyncSearchInput
                  name="cidadeOrigem"
                  label="Cidade Origem"
                  url="/cidades"
                  labelFormat={joinStateAndCity}
                  paramSearch="nome"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <PureAsyncSearchInput
                  name="cidadeDestino"
                  label="Cidade Destino"
                  url="/cidades"
                  labelFormat={joinStateAndCity}
                  paramSearch="nome"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <DateInput name="dataCarregamento" label="Data Carregamento" />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <DateInput
                  name="dataDescarregamento"
                  label="Data Descarregamento"
                />
              </Grid>
              <Grid item xs={12} sm={4} md={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={6}>
                    <Button
                      fullWidth
                      type="reset"
                      variant="outlined"
                      disabled={isSubmitting}
                    >
                      Limpar
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Button
                      data-testid="salvar"
                      fullWidth
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        'Buscar'
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        </Paper>
      )}
    </Formik>
  )
}
