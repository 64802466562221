import { CidadesDetail } from '../cidades/types'
import { EstadosDetail } from '../estados/types'

export interface Local {
  id?: string
  descricao: string
  estadoId: string
  cidadeId: string
  status: string
  nextPossibleStatus: string[]
  isStatusChangeable: boolean
}

export interface LocalDetail {
  id: string
  descricao: string
  codigo: number
  estadoId: string
  cidadeId: string
  Cidades: CidadesDetail
  Estados: EstadosDetail
  status: string
  nextPossibleStatus: string[]
  isStatusChangeable: boolean
}

export interface LocalDetailSave {}

export interface LocalDetailFormData {
  descricao: string
  estadoId: { name: string; value: string }
  cidadeId: { name: string; value: string }
}

export const Status = {
  ATIVO: 'Ativo',
  INATIVO: 'Inativo'
}

export type StatusLocal = 'ATIVO' | 'INATIVO' | ''

export const statusLocalOptions = [
  {
    value: 'ATIVO',
    label: Status.ATIVO
  },
  {
    value: 'INATIVO',
    label: Status.INATIVO
  }
]

export interface AlterarStatusData {
  newStatus: StatusLocal
}
