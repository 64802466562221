import Grid from '@material-ui/core/Grid'
import { Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import MaskInput from 'src/components/Formik/Forms/MaskInput'
import { CustomAction } from 'src/components/FormWrap'
import { isCellPhone } from 'src/components/TelefoneInput'
import useCanSave from 'src/hooks/useCanSave'
import { EntitySaver } from 'src/services/entitySaver'
import snackbarStore from 'src/stores/snackbar'
import { validateCNPJ } from 'src/utils/validators/cnpj'
import * as yup from 'yup'
import { FormWrap, Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import {
  alterarStatus,
  centroCustoRepository
} from '../hooks/centroCustoRepository'
import { useCentroCusto } from '../hooks/useCentroCusto'
import {
  CentroCustoDetail,
  StatusCentroCusto,
  StatusCentroCustoEnum,
  VerifyCentroCustoUsed
} from '../types'

const optionsSchema = yup
  .object()
  .nullable()
  .shape({
    name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

const schema = yup.object().shape({
  descricao: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  unidadeNegocioId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  telefone: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  endereco: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  cnpj: yup
    .string()
    .trim()
    .test('cnpj-validation', MessagesYup.INVALID_CNPJ, value =>
      validateCNPJ(value || '')
    )
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  nomeExibicao: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  inscricaoEstadual: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  descricao: '',
  unidadeNegocioId: '',
  telefone: '',
  endereco: '',
  cnpj: '',
  nomeExibicao: '',
  inscricaoEstadual: ''
}

const verifyIfUsed = ({
  Cotacoes,
  Lote,
  NegociacoesNaoFechadas,
  Programacao
}: VerifyCentroCustoUsed) => {
  return (
    Cotacoes > 0 || Lote > 0 || NegociacoesNaoFechadas > 0 || Programacao > 0
  )
}

export const CentroCustoForm = () => {
  const [centroCustoState] = useCentroCusto()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const canSave = useCanSave(centroCustoState, 'Centros de Custo')
  const [checkIfUsed, setCheckIfUsed] = useState<boolean>(false)

  useEffect(() => {
    if (
      centroCustoState.tag === 'with-data' &&
      centroCustoState.entity._count
    ) {
      setCheckIfUsed(verifyIfUsed(centroCustoState.entity._count))
    }
  }, [centroCustoState.tag]) // eslint-disable-line

  const handleGoBack = () => {
    history.goBack()
  }

  async function handleSubmit(centroCusto: any) {
    setLoading(true)
    const formatCentroCusto = {
      id: centroCusto.id,
      descricao: centroCusto.descricao,
      unidadeNegocioId: centroCusto.unidadeNegocioId.value,
      cnpj: centroCusto.cnpj,
      nomeExibicao: centroCusto.nomeExibicao,
      inscricaoEstadual: centroCusto.inscricaoEstadual,
      telefone: centroCusto.telefone,
      endereco: centroCusto.endereco
    }

    await EntitySaver(
      () => centroCustoRepository.save(formatCentroCusto) as any,
      {
        onSuccess: () => handleGoBack(),
        feedbackError: 'Erro ao salvar o centro de custo'
      }
    )

    setLoading(false)
  }

  const formatData = (data: CentroCustoDetail) => {
    const dataTratado = {
      id: data.id,
      descricao: data.descricao,
      unidadeNegocioId: data.unidadeNegocioId,
      cnpj: data.cnpj,
      endereco: data.endereco,
      nomeExibicao: data.nomeExibicao,
      inscricaoEstadual: data.inscricaoEstadual,
      telefone: data.telefone
    } as CentroCustoDetail

    return dataTratado
  }

  async function handleAlterarStatus(id: string, newStatus: StatusCentroCusto) {
    const res = await alterarStatus(id, newStatus)

    if (res.ok) {
      snackbarStore.setMessage('Status alterado com sucesso!')
      history.goBack()
    } else {
      snackbarStore.setMessage(
        res.data?.message ?? 'Error ao alterar status do Centro de Custo.'
      )
    }
  }

  function renderArrayCustomActions(): CustomAction[] {
    if (centroCustoState.tag === 'with-data') {
      const { id, status } = centroCustoState.entity

      if (status === 'ATIVO') {
        return [
          {
            label: 'Inativar',
            onClick: () =>
              handleAlterarStatus(id, StatusCentroCustoEnum.INATIVO),
            variant: 'outlined',
            color: 'primary'
          }
        ]
      }

      if (status === 'INATIVO') {
        return [
          {
            label: 'Ativar',
            onClick: () => handleAlterarStatus(id, StatusCentroCustoEnum.ATIVO),
            variant: 'outlined',
            color: 'primary'
          }
        ]
      }
    }

    return []
  }

  const unidadeNegocioUrl =
    centroCustoState.tag === 'with-data'
      ? '/unidades-negocio'
      : '/unidades-negocio?status=ATIVO'

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        centroCustoState.tag === 'with-data'
          ? formatData(centroCustoState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, values, errors, submitCount }) => (
        <Paper>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={isSubmitting || loading || !canSave}
            disableBack={isSubmitting || loading}
            loading={loading}
            customActions={renderArrayCustomActions()}
            onClickButtonSave={() => scrollToError(errors)}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextInput
                  name="descricao"
                  label="Descrição"
                  disabled={checkIfUsed}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AsyncSearchInput
                  name="unidadeNegocioId"
                  label="Unidade de Negócio"
                  disabled={checkIfUsed}
                  allowSearchWhenDisabled
                  url={unidadeNegocioUrl}
                  labelField="descricao"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput name="nomeExibicao" label="Nome de Exibição" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MaskInput name="cnpj" label="CNPJ" mask="99.999.999/9999-99" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput name="endereco" label="Endereço" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <MaskInput
                  name="telefone"
                  label="Telefone"
                  mask={
                    values.telefone && isCellPhone(values.telefone)
                      ? '(99) 9 9999-9999'
                      : '(99) 9999-9999'
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextInput
                  name="inscricaoEstadual"
                  label="Inscrição Estadual"
                />
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
