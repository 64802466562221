import { useField } from 'formik'
import InputCoordinate, { InputCoordinateProps } from '../../InputCoordinate'

interface CoordinateInputProps extends InputCoordinateProps {
  name: string
}

function CoordinateInput({ name, ...props }: CoordinateInputProps) {
  const [field, meta, helpers] = useField(name)

  function handleOnChange(value: React.ChangeEvent<HTMLInputElement>) {
    helpers.setValue(value.target.value)
  }

  return (
    <InputCoordinate
      {...field}
      {...props}
      fullWidth
      variant="outlined"
      color="primary"
      handleOnChange={handleOnChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched ? meta.error : undefined}
    />
  )
}

export default CoordinateInput
