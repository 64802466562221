import { usePessoa } from '../hooks/usePessoa'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { PessoasForm } from './PessoasForm'

export function PessoaMasterDetail() {
  const [pessoaState, pessoaActions] = usePessoa()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      pessoaState,
      c => c.entity.id,
      c => c.entity.nome || c.entity.nomeFantasia!
    )
  ])

  useFetchAndReset(pessoaActions.fetch, pessoaActions.reset)
  return handleStateErrorsToRender(pessoaState, <PessoasForm />)
}
