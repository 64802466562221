import {
  Grid,
  InputBaseComponentProps,
  InputLabelProps,
  LinearProgress,
  makeStyles,
  TextField,
  CircularProgress
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useField, useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { apiSauceInstance } from '../../../services/api'

export type ReasonType =
  | 'create-option'
  | 'select-option'
  | 'remove-option'
  | 'blur'
  | 'clear'

interface Value {
  name: string
  id: string
  [key: string]: any
}

export interface LabelFormatValue {
  value: string
  name: string
}

interface LabelFormatProps {
  altValue?: string
  endAdornment?: React.ReactNode
  url: string
  name: string
  label: string
  filters?: Record<string, any>
  disabled?: boolean
  placeholder?: string
  fullWidth?: boolean
  InputProps?: InputBaseComponentProps
  InputLabelProps?: InputLabelProps
  allowSearchWhenDisabled?: boolean
  isConsulting?: boolean
  limit?: number
  paramSearch: string
  labelFormat: (value: any) => LabelFormatValue
  handleOnChange?: (value: any, reason: ReasonType) => void
  labelField?: never
}

interface LabelFieldProps {
  altValue?: string
  endAdornment?: React.ReactNode
  url: string
  name: string
  label: string
  filters?: Record<string, any>
  disabled?: boolean
  placeholder?: string
  fullWidth?: boolean
  labelField: string
  InputProps?: InputBaseComponentProps
  InputLabelProps?: InputLabelProps
  allowSearchWhenDisabled?: boolean
  isConsulting?: boolean
  limit?: number
  paramSearch: string
  handleOnChange?: (value: any, reason: ReasonType) => void
  labelFormat?: never
}

export type PureAsyncSearchInputProps = LabelFormatProps | LabelFieldProps

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.action.disabled
      }
    }
  }
}))

export const PureAsyncSearchInput: React.FC<PureAsyncSearchInputProps> =
  props => {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState<Value[]>([])
    const [initialLoading, setInitialLoading] = useState(false)
    const [field, meta, helper] = useField(props.name)
    const formik = useFormikContext()
    const [loading, setLoading] = useState(false)
    const value = typeof field.value === 'string' ? null : field.value

    const classes = useStyles()

    function getError(error?: string | { name: string; value: string }) {
      if (!error) {
        return ''
      }

      if (typeof error === 'string') {
        return error
      } else {
        return error?.value
      }
    }

    async function handleSearchOneFromApi(idFromEntity: string): Promise<void> {
      if (props.disabled && !props.allowSearchWhenDisabled) {
        return
      }
      setInitialLoading(true)

      const response = await apiSauceInstance.get<any>(
        `${props.url}/${idFromEntity}`,
        props.filters
      )

      const data = response.data
      setInitialLoading(false)

      if (props.labelFormat) {
        return formik.setFieldValue(props.name, props.labelFormat(data))
      } else {
        return formik.setFieldValue(props.name, {
          name: data[props.labelField],
          value: data.id
        })
      }
    }

    async function handleSearch(search?: string): Promise<void> {
      setLoading(true)

      const response = await apiSauceInstance.get<any>(props.url, {
        [props.paramSearch]: search || undefined,
        limit: props.limit ?? 10,
        ...props.filters
      })
      setLoading(false)

      setOptions(
        response.data?.edges?.map((currentValue: any) => {
          if (props.labelFormat) {
            return props.labelFormat(currentValue)
          } else {
            const name = currentValue.node[props.labelField]

            return {
              name,
              value: currentValue.node.id
            }
          }
        }) ?? []
      )
    }

    async function handleOnChange(e: any, value: any, reason: ReasonType) {
      if (!!props.handleOnChange) props.handleOnChange(value, reason)
      return helper.setValue(value)
    }

    useEffect(() => {
      if (!open) {
        setOptions([])
      }
    }, [open])

    useEffect(() => {
      if (typeof field.value === 'string' && field.value) {
        handleSearchOneFromApi(field.value)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [field.value, props.disabled, props.filters])

    return (
      <>
        <Autocomplete
          {...props}
          open={open}
          disabled={props.disabled}
          onOpen={() => {
            setOpen(true)
            !props.disabled && handleSearch()
          }}
          onClose={() => setOpen(false)}
          onChange={(e: any, value: any, reason: ReasonType) =>
            handleOnChange(e, value, reason)
          }
          onInputChange={debounce((_e, value) => {
            console.error(_e)
            return !props.disabled && handleSearch(value)
          }, 700)}
          onBlur={field.onBlur}
          getOptionSelected={(option, value) => option.name === value?.name}
          getOptionLabel={option => option.name}
          options={options}
          loading={loading}
          fullWidth={props.fullWidth}
          value={value}
          clearText="Limpar"
          closeText="Fechar"
          loadingText="Carregando..."
          noOptionsText="Sem opções"
          openText="Abrir"
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderInput={params => (
            <TextField
              {...params}
              error={meta.touched && Boolean(getError(meta.error))}
              helperText={meta.touched && getError(meta.error)}
              label={props.label}
              name={props.name}
              variant="outlined"
              placeholder={props.placeholder}
              className={classes.input}
              InputLabelProps={{
                ...props.InputLabelProps,
                disabled: props.disabled ? !props.isConsulting : false
              }}
              InputProps={{
                ...params.InputProps,
                disabled: props.disabled ? !props.isConsulting : false,
                endAdornment: (
                  <>
                    {open && loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        {initialLoading && (
          <Grid item xs={12}>
            <LinearProgress />
          </Grid>
        )}
      </>
    )
  }
