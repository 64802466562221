import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { AppBar, Grid, Tab, Tabs } from '@material-ui/core'
import { GridColDef } from '@mui/x-data-grid'
import { Formik } from 'formik'
import { SetStateAction, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { FormWrap, Paper } from 'src/components'
import TipoNegociacaoEmpresaSelectInput from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import TipoPedagioSelectInput from 'src/components/Formik/Forms/Inputs/TipoPedagioSelectInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import SwitchInput from 'src/components/SwitchInput'
import { TabPanel } from 'src/components/TabPanel'
import useCanSave from 'src/hooks/useCanSave'
import { MessagesYup } from 'src/modules/messages'
import snackbarStore from 'src/stores/snackbar'
import scrollToError from 'src/utils/scrollToError'
import * as yup from 'yup'
import { tabelaSubRepository } from '../hooks/tabela/tabelaSubRepository'
import { useTabela } from '../hooks/tabela/useTabela'
import { useContrato } from '../hooks/useContrato'
import contratoStore from '../store/store'
import {
  TabelaContratoType,
  TabelaDetail,
  TabelaFormData
} from '../types/tabelaTypes'
import { UseParamsProps } from '../types/types'
import { AdicionalEntregaForm } from './AdicionalEntregaForm'
import TabelaFreteForm from './TabelaFreteForm'
import TabelaPedagioForm from './TabelaPedagioForm'

const schema = yup.object().shape({
  descricao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoNegociacaoEmpresa: yup
    .string()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  pedagio: yup.boolean(),
  tipoPedagio: yup.string().when('pedagio', {
    is: true,
    then: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  })
})

const initialValues: any = {
  descricao: '',
  tipoNegociacaoEmpresa: '',
  icms: false,
  pedagio: false,
  tipoPedagio: '',
  adicionalEntrega: false,
  valorAdicional: null,
  franquiaEntregas: null,
  tabelaFrete: {},
  tabelaPedagio: {}
}

export const TabelaForm = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [tabValue, setTabValue] = useState<number>(0)
  const [tabelaState, tabelaActions] = useTabela()
  const [contratoState, contratoActions] = useContrato()

  const params = useParams<UseParamsProps>()
  const canSave = useCanSave(contratoState, 'Contratos')

  const { save } = useEntitySaver<TabelaFormData>(async data => {
    const response = await tabelaSubRepository.save(params.id, data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  useEffect(() => {
    tabelaActions.reset()
    contratoStore.clearTabelas()
    if (params?.childId && params?.childId !== 'new') {
      tabelaActions.fetch(params.id, params?.childId)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id, params.childId])

  useEffect(() => {
    contratoStore.setInitialValuesTabelaFrete()
    contratoStore.setInitialValuesTabelaPedagio()

    contratoActions.fetch(params.id)

    return () => {
      tabelaActions.reset()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setInitialValuesTabelas()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelaState.tag])

  const setInitialValuesTabelas = () => {
    if (tabelaState.tag === 'with-data' && params?.childId !== 'new') {
      const data: TabelaDetail = tabelaState.entity
      contratoStore.clearTabelas()
      contratoStore.setInitialValuesTabelaFrete(data.TabelaFrete)

      if (data.TabelaPedagio?.TarifasPedagio?.length) {
        contratoStore.setInitialValuesTabelaPedagio(data.TabelaPedagio)
      } else {
        contratoStore.updateTabelaPedagioWithTabelaFreteColumns()
      }
    }
  }

  const handleSubmit = async (data: TabelaFormData) => {
    try {
      const tabelaFrete = createTabelaDto(
        contratoStore.tabelaFrete,
        'Tabela Frete'
      )
      const tabelaPedagio = createTabelaDto(
        contratoStore.tabelaPedagio,
        'Tabela Pedágio'
      )

      const tabela: TabelaFormData = {
        ...data,
        tipoPedagio: data.tipoPedagio || null,
        tabelaFrete,
        tabelaPedagio: data.pedagio ? tabelaPedagio : undefined
      }

      setLoading(true)
      const retorno = await save(tabela)
      setLoading(false)

      if (retorno.ok) {
        history.goBack()
      }
    } catch (err: unknown) {
      const message = String(err).replace('Error:', '').trim()
      snackbarStore.setMessage(message)
    }
  }

  const createTabelaDto = (
    tabela: TabelaContratoType,
    tableName: 'Tabela Frete' | 'Tabela Pedágio'
  ) => {
    const capacidades = tabela.columns
      .filter(
        column => !['actions', 'kmInicial', 'kmFinal'].includes(column.field)
      )
      .map((column: GridColDef) => ({ capacidade: Number(column.field) }))

    const faixasKm = tabela.rows.map(row => {
      const kmInicial = Number(row.kmInicial)
      const kmFinal = Number(row.kmFinal)

      return {
        kmInicial: Number.isInteger(kmInicial) ? kmInicial : NaN,
        kmFinal: Number.isInteger(kmFinal) ? kmFinal : NaN
      }
    })

    faixasKm.forEach(({ kmFinal, kmInicial }, indice: number) => {
      const errorMessage = (field: string) =>
        `O campo ${field} da linha ${
          indice + 1
        } da tabela ${tableName} deve ser um número inteiro`

      if (isNaN(kmFinal)) {
        throw new Error(errorMessage('KM Inicial'))
      }

      if (isNaN(kmInicial)) {
        throw new Error(errorMessage('KM Final'))
      }
    })

    const valores: number[][] = []
    tabela.rows.forEach((row: Record<string, string>) => {
      const current: number[] = []
      capacidades.forEach(c => {
        current.push(Number(row[c.capacidade]?.replace(',', '.')) || 0)
      })

      valores.push(current)
    })

    return {
      capacidades: capacidades,
      faixasKm: faixasKm,
      valores: valores
    }
  }

  const a11yProps = (index: number) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  })

  const handleChangeTab = (event: any, newValue: SetStateAction<number>) => {
    setTabValue(newValue)
  }

  const switchPedagioChanged = (
    event: any,
    setFieldValue: (field: string, value: any) => void
  ) => {
    setFieldValue('tipoPedagio', '')
    if (event?.target?.value === 'true' && tabValue === 1) {
      setTabValue(0)
      return
    }

    // Usuário marcou o switch de pedágio, então vamos criar as colunas como na tabela frete
    if (event?.target?.value === 'false' && tabelaState.tag === 'with-data') {
      contratoStore.updateTabelaPedagioWithTabelaFreteColumns()
    }
  }

  const switchAdicionalEntregaChanged = (event?: any) => {
    if (event?.target?.value === 'true' && tabValue === 2) {
      setTabValue(0)
    }
  }

  const formatData = (data: TabelaDetail) => {
    const formData: TabelaFormData = {
      id: data.id,
      descricao: data.descricao,
      tipoNegociacaoEmpresa: data.tipoNegociacaoEmpresa,
      icms: data.icms,
      pedagio: data.pedagio,
      tipoPedagio: data.tipoPedagio?.toString() || '',
      adicionalEntrega: data.adicionalEntrega,
      valorAdicional: data.TabelaFrete.valorAdicional,
      franquiaEntregas: data.TabelaFrete.franquiaEntregas
    }

    return formData
  }

  function canUpdateTabelaContrato(): boolean {
    return (
      contratoState.tag === 'with-data' &&
      contratoState.entity.status === 'ATIVO'
    )
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={
        tabelaState.tag === 'with-data'
          ? formatData(tabelaState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, handleSubmit, errors, setFieldValue }) => (
        <Paper noTopBorderRadius>
          <FormWrap
            handleSubmit={handleSubmit}
            disableSubmit={
              isSubmitting || loading || !canSave || !canUpdateTabelaContrato()
            }
            disableBack={isSubmitting || loading}
            loading={loading}
            onClickButtonSave={() => scrollToError(errors)}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item md={6} xs={12}>
                <TextInput name="descricao" label="Descrição da Tabela" />
              </Grid>
              <Grid item md={2} xs={4}>
                <SwitchInput name="icms" label="Frete + ICMS" />
              </Grid>
              <Grid item md={2} xs={4}>
                <SwitchInput
                  name="pedagio"
                  label="Frete + Pedágio"
                  onClick={event => switchPedagioChanged(event, setFieldValue)}
                />
              </Grid>
              <Grid item md={2} xs={4}>
                <SwitchInput
                  name="adicionalEntrega"
                  label="Adicional de Entrega"
                  onClick={switchAdicionalEntregaChanged}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TipoNegociacaoEmpresaSelectInput name="tipoNegociacaoEmpresa" />
              </Grid>
              <Grid item md={6} xs={12}>
                <TipoPedagioSelectInput
                  name="tipoPedagio"
                  label="Tipo de Pedágio"
                  disabled={!values.pedagio}
                />
              </Grid>
              <Grid item xs={12}>
                <AppBar position="static">
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="tabela tabs"
                  >
                    <Tab label="Tabela de Frete" {...a11yProps(0)} />
                    <Tab
                      label="Tabela de Pedágio"
                      {...a11yProps(1)}
                      disabled={!values.pedagio}
                    />
                    <Tab
                      label="Adicional de Entrega"
                      {...a11yProps(2)}
                      disabled={!values.adicionalEntrega}
                    />
                  </Tabs>
                </AppBar>
                <Paper noTopBorderRadius>
                  <TabPanel value={tabValue} index={0}>
                    <TabelaFreteForm />
                  </TabPanel>
                  <TabPanel value={tabValue} index={1}>
                    <TabelaPedagioForm />
                  </TabPanel>
                  <TabPanel value={tabValue} index={2}>
                    <AdicionalEntregaForm />
                  </TabPanel>
                </Paper>
              </Grid>
            </Grid>
          </FormWrap>
        </Paper>
      )}
    </Formik>
  )
}
