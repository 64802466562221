import { Box, makeStyles } from '@material-ui/core'
import { Observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import ReactMapGL, {
  Layer,
  LayerProps,
  MapLoadEvent,
  MapRef,
  Source,
  SourceProps
} from 'react-map-gl'
import config from 'src/config'
import CitiesMarkers from './CitiesMarkers'
import CityDrawer from './CityDrawer'
import MapFilter from './MapFilter'
import mapaProgramacoesStore from './store'
import { ProgramacaoMap } from './types'

import mapboxgl from 'mapbox-gl'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

const maxClustersZoom = 5

const useStyles = makeStyles(theme => ({
  mapFilter: {
    position: 'absolute',
    top: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
    left: theme.spacing(2),
    right: theme.spacing(2)
  }
}))

export default function MapaProgramacoes() {
  const styles = useStyles()

  const mapRef = useRef<MapRef>(null)
  const [viewport, setViewport] = useState({
    latitude: -23.42421809764707,
    longitude: -51.938689199920844,
    zoom: 5
  })

  const clustersLayers: LayerProps[] = [
    {
      id: 'clusters',
      type: 'circle',
      source: 'programacoes',
      filter: ['has', 'programacoes'],
      paint: {
        'circle-color': [
          'step',
          ['get', 'programacoes'],
          '#51bbd6',
          100,
          '#f1f075',
          750,
          '#f28cb1'
        ],
        'circle-radius': ['step', ['get', 'programacoes'], 20, 100, 30, 750, 40]
      }
    },
    {
      id: 'cluster-count',
      type: 'symbol',
      source: 'programacoes',
      filter: ['has', 'programacoes'],
      layout: {
        'text-field': '{programacoes}',
        'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        'text-size': 12
      },
      paint: {}
    }
  ]

  function getLinhasMapaRotas() {
    const features = mapaProgramacoesStore.data.flatMap(city =>
      city.programacoes.map((programacao: ProgramacaoMap) => ({
        type: 'Feature',
        properties: city,
        geometry: {
          type: 'LineString',
          coordinates: [
            [Number(city.longitude), Number(city.latitude)],
            [Number(programacao.longitude), Number(programacao.latitude)]
          ]
        }
      }))
    )

    return {
      type: 'FeatureCollection',
      features
    } as SourceProps['data']
  }

  function getClustersMapaRotas() {
    const features = mapaProgramacoesStore.data.flatMap(city => ({
      type: 'Feature',
      properties: {
        programacoes: city.programacoes.length
      },
      geometry: {
        type: 'Point',
        coordinates: [Number(city.longitude), Number(city.latitude)]
      }
    }))

    return {
      type: 'FeatureCollection',
      features
    } as SourceProps['data']
  }

  function onMapLoad(e: MapLoadEvent) {
    const map = e.target
    map.setLayoutProperty('country-label', 'text-field', ['get', 'name_pt'])
  }

  return (
    <>
      <CityDrawer />
      <Box
        width="calc(100% + 48px)"
        height="calc(100vh - 64px)"
        mt={-3}
        ml={-3}
      >
        <ReactMapGL
          {...viewport}
          ref={mapRef}
          width="100%"
          height="100%"
          mapboxApiAccessToken={config.mapboxToken}
          mapStyle="mapbox://styles/mapbox/streets-v11"
          onViewportChange={(viewport: any) => setViewport(viewport)}
          onClick={(e: any) => console.log(e.lngLat)}
          onLoad={onMapLoad}
        >
          <Observer>
            {() => (
              <>
                {viewport.zoom > maxClustersZoom ? (
                  <>
                    <Source type="geojson" data={getLinhasMapaRotas()}>
                      <Layer {...mapaProgramacoesStore.linesLayer} />
                    </Source>

                    <CitiesMarkers />
                  </>
                ) : (
                  <Source
                    cluster
                    clusterMaxZoom={maxClustersZoom}
                    clusterProperties={{
                      programacoes: ['+', ['get', 'programacoes']]
                    }}
                    type="geojson"
                    data={getClustersMapaRotas()}
                  >
                    {clustersLayers.map(layer => {
                      return <Layer {...layer} key={layer.id} />
                    })}
                  </Source>
                )}
              </>
            )}
          </Observer>
        </ReactMapGL>
      </Box>
      <div className={styles.mapFilter}>
        <MapFilter />
      </div>
    </>
  )
}
