import { useCallback, useEffect, useState } from 'react'
import { apiSauceInstance } from 'src/services/api'
import { Configuracoes } from '../types'

const fetchConfiguracaoByName = (name: string) => {
  return apiSauceInstance.get<Configuracoes, ApiErrorResponse>(
    `/configuracoes/by-name/${name}`
  )
}

export const useConfiguracaoByName = (name: string) => {
  const [configuracao, setConfiguracao] = useState<Configuracoes | null>(null)
  const [loading, setLoading] = useState(false)

  const getConfig = useCallback(() => {
    setLoading(true)
    fetchConfiguracaoByName(name).then(response => {
      if (response.ok && response.data) {
        setConfiguracao(response.data)
      } else {
        setConfiguracao(null)
      }
      setLoading(false)
    })
  }, [name])

  useEffect(() => {
    if (configuracao === null) {
      getConfig()
    }
  }, [configuracao, getConfig])

  return { valor: configuracao?.valor, loading }
}
