import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress, InputAdornment } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import * as yup from 'yup'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { unidadeNegocioRepository } from '../hooks/unidadeNegocioRepository'
import { useUnidadeNegocio } from '../hooks/useUnidadeNegocio'
import { UnidadeNegocio } from '../types'

const schema = yup.object().shape({
  descricao: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  margemEsperada: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues: any = {
  descricao: '',
  margemEsperada: '',
  CentroCusto: [{ id: '' }]
}

export const UnidadeNegocioForm = () => {
  const history = useHistory()
  const [unidadeNegocioState] = useUnidadeNegocio()
  const [loading, setLoading] = useState<boolean>(false)
  const canSave = useCanSave(unidadeNegocioState, 'Unidades de Negócio')

  const { saveWithoutRedirect } = useEntitySaver<UnidadeNegocio>(async data => {
    const response = await unidadeNegocioRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  async function handleSubmit(unidadeNegocio: UnidadeNegocio) {
    const { CentroCusto, ...rest } = unidadeNegocio

    setLoading(true)
    const response = await saveWithoutRedirect({
      ...rest
    })

    if (response.ok) {
      history.goBack()
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        unidadeNegocioState.tag === 'with-data'
          ? unidadeNegocioState.entity
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <TextInput
                  name="descricao"
                  label="Descrição"
                  disabled={
                    unidadeNegocioState.tag === 'with-data' &&
                    unidadeNegocioState.entity.CentroCusto &&
                    !!unidadeNegocioState.entity.CentroCusto[0]
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <NumericInput
                  name="margemEsperada"
                  label="Margem esperada"
                  maxValue={100}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">%</InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
