import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { MessagesYup } from 'src/modules/messages'
import { EntitySaver } from 'src/services/entitySaver'
import snackbarStore from 'src/stores/snackbar'
import * as yup from 'yup'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { locaisRepository } from '../hooks/locaisRepository'
import { useLocal } from '../hooks/useLocal'
import { LocalDetail, LocalDetailFormData } from '../types'

const optionsSchema = yup
  .object()
  .nullable()
  .shape({
    name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

const schema = yup.object().shape({
  descricao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  estadoId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  cidadeId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  descricao: '',
  estadoId: null,
  cidadeId: null
}

export const LocalForm = () => {
  const [localState] = useLocal()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const canSave = useCanSave(localState, 'Local')

  async function handleSubmit(local: any) {
    setLoading(true)

    const formatLocal = {
      id: local.id,
      descricao: local.descricao,
      estadoId: local.estadoId.value,
      cidadeId: local.cidadeId.value,
      status: local.status
    } as any

    await EntitySaver(() => locaisRepository.save(formatLocal) as any, {
      onSuccess: () => handleGoBack(),
      feedbackError: 'Erro ao salvar local'
    })

    setLoading(false)
  }

  const handleGoBack = () => {
    history.goBack()
  }

  const formatData = (data: LocalDetail) => {
    const dataTratado = {
      id: data.id,
      descricao: data.descricao,
      estadoId: data.estadoId as any,
      cidadeId: data.cidadeId as any
    }

    return dataTratado as LocalDetailFormData
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        localState.tag === 'with-data'
          ? formatData(localState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values, errors, submitCount, setFieldValue }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="descricao" label="Descrição Local" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <AsyncSearchInput
                  name="estadoId"
                  label="Estado"
                  url="/estados/minimal"
                  labelField="nome"
                  joinStateAndUf
                  handleOnChange={() => {
                    setFieldValue('cidadeId', null)
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <AsyncSearchInput
                  name="cidadeId"
                  label="Cidade"
                  url={`/estados/${values.estadoId?.value}/cidades`}
                  labelField="nome"
                  disabled={!values.estadoId?.value}
                />
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
