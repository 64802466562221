import { IconButton, Tooltip } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { Link } from 'react-router-dom'
import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import {
  DatePickerFilter,
  MaskFilter,
  NumericFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import { EditarStatusRecord } from 'src/components/EditarStatusRecord'
import { editLink } from 'src/components/EditLinkTable'
import When from 'src/components/When'
import { CancelRecord } from '../../components'
import {
  categoriaTransportadorOptions,
  statusProgramacao,
  tipoPagamentoOptions
} from './types'
import StatusBadge from './StatusBadge'

interface IProgramacaoColumnsProps {
  onCancel: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const ProgramacaoColumns = ({
  onCancel,
  authorization
}: IProgramacaoColumnsProps): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup,
          precision: 0
        })
    },
    {
      Header: 'Código do Lote',
      accessor: 'codigoLote',
      sort: false,
      defaultClick: false,
      group: false,
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup,
          precision: 0
        }),
      Cell: props => {
        const data = props.row.original as any
        return data ? (
          editLink({
            text: data.Lotes.length === 1 ? data.codigoLote : 'M',
            isActive: data.Lotes.length === 1,
            link: `/lotes/${data.Lotes[0]?.id}?view=true`,
            unalterableReason: 'Múltiplos lotes'
          })
        ) : (
          <></>
        )
      }
    },
    { Header: 'Centro de Custo', accessor: 'centroCusto' },
    {
      Header: 'Data de Carregamento',
      accessor: 'dataHoraCarregamento',
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    { Header: 'Placa Principal', accessor: 'placaPrincipal' },
    { Header: 'Placa 1', accessor: 'placa_1', show: false },
    { Header: 'Placa 2', accessor: 'placa_2', show: false },
    { Header: 'Placa 3', accessor: 'placa_3', show: false },
    {
      Header: 'CPF',
      accessor: 'cpfMotorista',
      Filter: props =>
        MaskFilter({
          column: props.column as HeaderGroup<{}>,
          mask: '999.999.999-99'
        })
    },
    { Header: 'Motorista', accessor: 'motorista', show: false },
    { Header: 'Cliente', accessor: 'cliente', sort: false },
    { Header: 'Origem', accessor: 'origem', sort: false },
    { Header: 'Destino', accessor: 'destino', sort: false },
    { Header: 'Produto', accessor: 'produto', sort: false, search: false },
    {
      Header: 'Tipo Pagamento',
      accessor: 'tipoPagamento',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: tipoPagamentoOptions
        })
    },
    {
      Header: 'Categoria Transportador',
      accessor: 'categoriaTransportador',
      show: false,
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: categoriaTransportadorOptions
        })
    },
    {
      Header: 'Status de Programação',
      accessor: 'status',
      Cell: props =>
        props.value !== null ? (
          <StatusBadge status={props.value} marginLeft="30%" />
        ) : (
          <></>
        ),
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: statusProgramacao
        })
    },
    {
      Header: 'Quantidade',
      accessor: 'quantidade',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup,
          precision: 0
        })
    },
    {
      Header: 'Observação',
      accessor: 'observacao',
      show: false
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <EditarStatusRecord
                  isStatusChangeable={props.row.original.isStatusChangeable}
                  status={props.row.original.status}
                  id={props.row.original.id}
                  optionsStatus={props.row.original.nextPossibleStatus}
                  isProgramacao
                />
              </When>
              <Tooltip
                title="Consultar registro"
                aria-label="Consultar registro"
              >
                <IconButton
                  component={Link}
                  to={`programacoes/${props.row.original.id}?view=true`}
                >
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>

              <When value={authorization?.canUpdate} equals>
                <CancelRecord
                  tooltipTitle="Cancelar programação"
                  disabled={!props.row.original.isCancellable}
                  onCancel={() => onCancel(props.row.original.id)}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
