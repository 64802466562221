import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { modalidadesRepository } from './hooks/modalidadesRepository'
import { ModalidadeQueryFilter, ModalidadesFilter } from './ModalidadesFilter'
import { ModalidadesTable } from './ModalidadesTable'
import { modalidadesStore } from './stores'

const Modalidades = () => {
  const { initialValues } = useQueryParams<ModalidadeQueryFilter>()

  const ability = useAbility()

  async function handleDelete(id: string) {
    const response = await modalidadesRepository.delete(id)
    modalidadesStore.modalidades.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }
  const authorization = getAuthorizations(ability, 'Modalidades')

  useEffect(() => {
    modalidadesStore.modalidades.setPage(Number(initialValues.page) || 1)
    modalidadesStore.modalidades.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  return (
    <>
      <ModalidadesFilter />
      <ModalidadesTable onDelete={handleDelete} authorization={authorization} />
    </>
  )
}

export default Modalidades
