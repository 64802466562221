import { FormControlLabel, Switch } from '@material-ui/core'
import { useField } from 'formik'

interface SwitchInputProps {
  name: string
  label: string
  disabled?: boolean
  style?: object
  onClick?: (event: any) => void
}

function SwitchInput(props: SwitchInputProps) {
  const [field] = useField(props.name)

  return (
    <FormControlLabel
      {...field}
      {...props}
      control={<Switch checked={field.value} />}
      label={props.label}
    />
  )
}

export default SwitchInput
