import { makeAutoObservable, observable } from 'mobx'
import ApisauceDataStore from '../../../packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from '../../../services/api'
import { TabelaIcms } from '../types'

class TabelaIcmsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  tabela = observable.object<{
    rows: TabelaIcms[]
    columns: string[]
  }>({
    rows: [],
    columns: []
  })

  saveArray = observable.array<{
    id: string
    aliquota: number
  }>([])

  getRowsByEstadosDestino(estado: string) {
    const rowsByestados = this.tabela.rows.filter(
      row => row.estadoOrigem === estado
    )

    return rowsByestados.filter(
      row => ![row.estadoOrigem, row.estadoDestino].includes('EX')
    )
  }

  getColumns() {
    return this.tabela.columns.filter(col => col !== 'EX')
  }

  getRowsByEstadosWithEX(estado: string) {
    const rowsByestados = this.tabela.rows.filter(
      row => row.estadoOrigem === estado
    )

    return rowsByestados.find(row => [row.estadoDestino].includes('EX'))
  }

  getRowsByEX() {
    const findEXEnd = this.tabela.rows.find(
      row => row.estadoDestino === 'EX' && row.estadoOrigem === 'EX'
    )
    const exRows = this.tabela.rows
      .filter(row => row.estadoOrigem === 'EX')
      .filter(row => row.estadoDestino !== 'EX')
    return [...exRows, findEXEnd]
  }

  addTabela(rows: TabelaIcms[], columns: string[]) {
    this.tabela.rows = rows
    this.tabela.columns = columns
  }

  addSaveArray(id: string, aliquota: number) {
    const searchId = this.saveArray.find(item => item.id === id)
    if (searchId) {
      searchId.aliquota = aliquota
    } else {
      this.saveArray.push({ id, aliquota })
    }
  }

  clearSaveArray() {
    this.saveArray.clear()
  }

  resetTabela() {
    this.tabela.rows = []
    this.tabela.columns = []
  }

  tabelaIcms = new ApisauceDataStore<TabelaIcms>(apiSauceInstance, {
    useNodes: true,
    path: '/tabela-icms',
    resultsField: 'edges'
  })
}

const tabelaIcmsStore = new TabelaIcmsStore()

export default tabelaIcmsStore
