export type PessoasQueryFilter = {
  [key: string]: any
}

export interface Descricao {
  origin: string
  name: string
  codigo: number
  nomeProduto: string
  localCarregamento: string
  cidadeOrigem: string
  ufEstadoOrigem: string
  localDestino: string
  cidadeDestino: string
  localEntrega: string
  ufEstadoDestino: string
  status: {
    transito: number
    fila: number
    carregado: number
  }
}

export interface Page {
  headerName: string
  list: Descricao[]
  total: {
    transito: number
    fila: number
    carregado: number
  }
}

export interface Relatorio {
  tipoEmissao: 'CLIENTE' | 'CC' | ''
  dataEmissao: Date
  pages: Page[]
}

export interface RelatorioDetail extends Relatorio {}

export const tiposRelatorio = [
  { value: 'CLIENTE', label: 'Cliente' },
  { value: 'CC', label: 'Centro de Custo' }
]
