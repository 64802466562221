import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core'

interface Props {
  open: boolean
  primaryAction: () => void
  handleClose: () => void
}

function LimparInformacoes({ open, handleClose, primaryAction }: Props) {
  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogContent>
        <Typography component={Box} textAlign="center" variant="h6">
          Deseja remover todas as informações inseridas na tabela?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          data-testid="salvar"
          fullWidth
          type="button"
          variant="outlined"
          onClick={handleClose}
        >
          Não
        </Button>
        <Button
          data-testid="salvar"
          fullWidth
          type="button"
          variant="contained"
          color="primary"
          onClick={primaryAction}
        >
          Sim
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { LimparInformacoes }
