import { Button, CircularProgress, Grid } from '@material-ui/core'
import { useState } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { saveData } from '../hooks/tabelaIcmsRepository'
import tabelaIcmsStore from '../store'
import { loadDataICMS } from '../util'

export const ActionsIcms = () => {
  const [isLoading, setIsLoading] = useState(false)

  const handleCancel = () => {
    tabelaIcmsStore.resetTabela()
    tabelaIcmsStore.clearSaveArray()
    loadDataICMS()
  }

  const handleSubmitData = async () => {
    setIsLoading(true)
    try {
      await saveData(tabelaIcmsStore.saveArray)
      snackbarStore.setMessage('Sucesso, dados atualizados da tabela de ICMS')
      tabelaIcmsStore.clearSaveArray()
    } catch (error) {
      snackbarStore.setMessage('Erro ao tentar atualizar dados da tabela ICMS')
    }

    setIsLoading(false)
  }

  return (
    <Grid justify="flex-end" container spacing={2}>
      <Grid item style={{ width: 160 }}>
        <Button
          fullWidth
          type="button"
          variant="outlined"
          disabled={isLoading}
          onClick={handleCancel}
        >
          Cancelar
        </Button>
      </Grid>

      <Grid item style={{ width: 160 }}>
        <Button
          data-testid="salvar"
          fullWidth
          type="submit"
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={() => handleSubmitData()}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Salvar'
          )}
        </Button>
      </Grid>
    </Grid>
  )
}
