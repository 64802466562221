import { makeStyles } from '@material-ui/core/styles'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab'
import React from 'react'
import When from './When'

const useStyles = makeStyles(theme => ({
  speedDial: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4),
    zIndex: 90
  }
}))

type Action = {
  icon: JSX.Element
  name: string
  action: () => void
}

type Props = {
  authorization?: { canCreate: boolean }
  actions: Action[]
}

export const SpeedDials = ({ authorization, actions }: Props) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <When value={authorization && authorization.canCreate} equals>
      <SpeedDial
        ariaLabel="SpeedDial"
        className={classes.speedDial}
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={action.action}
          />
        ))}
      </SpeedDial>
    </When>
  )
}
