import { Anexo } from 'src/utils/anexo'
import {
  getLabelStatusRelatorioInterno,
  getLabelTipoRelatorioInterno
} from '../utils/enumFormatter'
import { RelatorioInternoLoteFormData } from './lote/formData'
import { RelatorioInternoProgramacaoFormData } from './programacao/formData'

export interface RelatorioInterno {
  id: string
  description: string
  type: TipoRelatorioInterno
  status: StatusRelatorioInterno
  owner: string
  anexoId?: string
  createdAt: Date
  finishedAt: Date

  File?: Anexo
}

export enum TipoRelatorioInterno {
  LOTE = 'LOTE',
  PROGRAMACAO = 'PROGRAMACAO'
}

export enum StatusRelatorioInterno {
  PENDENTE = 'PENDENTE',
  CONCLUIDO = 'CONCLUIDO',
  ERRO = 'ERRO'
}

export const tipoRelatorioInternoOptions = [
  {
    value: TipoRelatorioInterno.LOTE,
    label: getLabelTipoRelatorioInterno(TipoRelatorioInterno.LOTE)
  },
  {
    value: TipoRelatorioInterno.PROGRAMACAO,
    label: getLabelTipoRelatorioInterno(TipoRelatorioInterno.PROGRAMACAO)
  }
]

export const statusRelatorioInternoOptions = [
  {
    value: StatusRelatorioInterno.PENDENTE,
    label: getLabelStatusRelatorioInterno(StatusRelatorioInterno.PENDENTE)
  },
  {
    value: StatusRelatorioInterno.CONCLUIDO,
    label: getLabelStatusRelatorioInterno(StatusRelatorioInterno.CONCLUIDO)
  },
  {
    value: StatusRelatorioInterno.ERRO,
    label: getLabelStatusRelatorioInterno(StatusRelatorioInterno.ERRO)
  }
]

export type RelatorioInternoQueryFilter = { [key: string]: any }

export type RelatorioInternoFormData =
  | RelatorioInternoLoteFormData
  | RelatorioInternoProgramacaoFormData

export * from './lote/dto'
export * from './lote/formData'

export * from './programacao/dto'
export * from './programacao/formData'
