import { MuiEvent } from '@mui/x-data-grid'
import { KeyboardEvent } from 'react'

export function handleKeyCodes(e: MuiEvent<KeyboardEvent<Element>>) {
  if (
    e.ctrlKey ||
    e.key === 'Backspace' ||
    e.key === 'Delete' ||
    e.key === 'Enter' ||
    e.key === 'Tab' ||
    e.key === 'ArrowLeft' ||
    e.key === 'ArrowRight' ||
    e.key === 'Home' ||
    e.key === 'End'
  )
    return

  if (!e.key.match(/^[0-9|,.]$/)) {
    e.preventDefault()
  }
}
