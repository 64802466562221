import { useFormikContext } from 'formik'
import { useCallback, useEffect } from 'react'
import { ModalidadeTransportador } from '../../types'
import {
  LoteProgramacaoFormData,
  ProgramacaoFormData
} from '../../types/formData'
import {
  getValorFreteAcordadoAgregadoPjPf,
  getValorFreteAcordadoGrupo
} from '../../util/calcularFreteAcordado'

interface Props {
  lote: LoteProgramacaoFormData
}

export const ValorFreteAcordadoListener = ({ lote }: Props) => {
  const formik = useFormikContext<ProgramacaoFormData>()

  const setValorFreteAcordadoIntoForm = useCallback(
    (valorFreteAcordado: number) => {
      formik.setFieldValue(
        `lotes.${lote.id}.valorFreteAcordado`,
        valorFreteAcordado
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lote]
  )

  useEffect(() => {
    const modalidadeTransportador = formik.values.modalidadeTransportador

    switch (modalidadeTransportador) {
      case ModalidadeTransportador.FROTA:
        setValorFreteAcordadoIntoForm(0)
        break

      case ModalidadeTransportador.GRUPO: {
        const valorFreteAcordado = getValorFreteAcordadoGrupo(lote)
        setValorFreteAcordadoIntoForm(valorFreteAcordado)
        break
      }

      default:
        const valorFreteAcordado = getValorFreteAcordadoAgregadoPjPf(
          lote,
          modalidadeTransportador
        )
        setValorFreteAcordadoIntoForm(valorFreteAcordado)
    }
  }, [
    formik.values.modalidadeTransportador,
    lote,
    setValorFreteAcordadoIntoForm
  ])

  return null
}
