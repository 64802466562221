import { usePais } from '../hooks/usePais'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { PaisForm } from './PaisForm'

export function PaisMasterDetail() {
  const [paisState, paisActions] = usePais()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      paisState,
      e => e.entity.id,
      e => e.entity.nome
    )
  ])

  useFetchAndReset(paisActions.fetch, paisActions.reset)
  return handleStateErrorsToRender(paisState, <PaisForm />)
}
