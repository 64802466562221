import { isAfter } from 'date-fns'
import * as yup from 'yup'
import { MessagesYup } from '../../messages'

const optionsSchema = yup
  .object()
  .nullable()
  .shape({
    name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

const schema = yup.object().shape({
  centroCustoId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  cliente: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataCotacao: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  estadoOrigemId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  origemId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  estadoDestinoId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  destinoId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  produto: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoNegociacao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  freteCotacao: yup
    .number()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .optional(),
  valorFreteFechado: yup
    .number()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .nullable()
    .optional(),
  hasPedagio: yup.boolean().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoPedagio: yup.string().nullable().optional(),
  valorPedagio: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .optional(),
  icms: yup.boolean().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  quantidade: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
  observacoes: yup.string().optional().nullable(),
  emails: yup.array().of(
    yup.object().shape({
      email: yup.string().email(MessagesYup.MENSAGEM_EMAIL).nullable()
    })
  ),
  dataEmbarqueInicio: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .test(
      'dataEmbarqueInicio',
      MessagesYup.MENSAGEM_DATA_FUTURA_FIELD('Data Embarque Término'),
      function (value) {
        if (value && this.parent.dataEmbarqueTermino) {
          const inputDateFormatted = new Date(
            value.getUTCFullYear(),
            value.getUTCMonth(),
            value.getUTCDate()
          )

          const dataEmbarqueTermino = this.parent.dataEmbarqueTermino
          const dataEmbarqueTerminoFormatted = new Date(
            dataEmbarqueTermino.getUTCFullYear(),
            dataEmbarqueTermino.getUTCMonth(),
            dataEmbarqueTermino.getUTCDate()
          )

          return !isAfter(inputDateFormatted, dataEmbarqueTerminoFormatted)
        }

        return true
      }
    )
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataEmbarqueTermino: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .nullable(),
  lancadoPor: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  files: yup.array(yup.object().shape({ id: yup.string() })).optional()
})

export { schema }
