import { Button, Input, InputAdornment, makeStyles } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useState } from 'react'

const useStyles = makeStyles({
  invisibleUploadButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    borderRadius: 0,
    opacity: 0
  },
  link: {
    color: 'primary'
  }
})

interface Props {
  changeFile: (file: File | null) => void
}

export function UploadXlsx({ changeFile }: Props) {
  const [fileState, setFileState] = useState<File | null>(null)
  const classes = useStyles()

  return (
    <label htmlFor="upload-file">
      <input
        hidden
        id="upload-file"
        name="upload-file"
        type="file"
        accept=".xlsx"
        onChange={event => {
          if (event.target.files?.length) {
            setFileState(event.target.files[0])
            changeFile(event.target.files[0])
          } else {
            setFileState(null)
          }
        }}
      />
      <Input
        fullWidth
        readOnly
        value={fileState ? fileState.name : undefined}
        placeholder="Selecionar planilha (.xlsx)"
        endAdornment={
          <InputAdornment position="end">
            <AttachFileIcon />
          </InputAdornment>
        }
        startAdornment={
          <label htmlFor="upload-file">
            <Button
              component="span"
              className={classes.invisibleUploadButton}
            />
          </label>
        }
      />
    </label>
  )
}
