import { makeAutoObservable } from 'mobx'
import ApiSauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { CotacaoDetail } from '../types'

class CotacoesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  cotacoes = new ApiSauceDataStore<CotacaoDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/cotacoes',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
const cotacoesStore = new CotacoesStore()
export default cotacoesStore
