import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { format } from 'date-fns'
import { observer } from 'mobx-react-lite'
import { ConsultaField } from 'src/components/ConsultaField'
import logsStore from './store'
import { Log, Mensagem, tiposProcessos } from './types'
import { LogCustomHeader } from './views/CustomHeaderLog'
import DataView from './views/DataView'
import UpdateView from './views/Update'

export function getLogInfo(log?: string | Mensagem) {
  if (!log) {
    return <Typography>{''}</Typography>
  }

  if (typeof log === 'string') {
    return <Typography style={{ wordBreak: 'break-all' }}>{log}</Typography>
  }

  return Object.entries(log).map(([key, value], index) => {
    if (key === 'payload') {
      return (
        <div key={index} style={{ marginBottom: 20 }}>
          <Typography style={{ color: 'gray' }} variant="caption">
            {key}
          </Typography>
          <pre style={{ lineBreak: 'anywhere', whiteSpace: 'pre-wrap' }}>
            {JSON.stringify(JSON.parse(value), null, 2)}
          </pre>
        </div>
      )
    }

    return (
      <div key={index} style={{ marginBottom: 20 }}>
        <ConsultaField title={key} subtitle={value} />
      </div>
    )
  })
}

function LogsList() {
  if (logsStore.logs.list.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <Typography variant="body1">Nenhum registro encontrado</Typography>
      </Box>
    )
  }

  return (
    <>
      {(logsStore.logs.list as Log[]).map(log => {
        const date = format(new Date(log.createdAt), 'dd/MM/yyyy')
        const time = format(new Date(log.createdAt), 'HH:mm')

        switch (log.processo) {
          case 'CADASTRO':
          case 'EXCLUSAO':
            if (!log.dados) {
              return null
            } else {
              return (
                <Accordion key={log.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      {date} - {time} - Processo:{' '}
                      <b>{tiposProcessos[log.processo]}</b> - Usuário:{' '}
                      <b>{log.usuario}</b> - Modelo: <b>{log.modelo}</b>
                      <LogCustomHeader customHeaders={log.customHeaders} />
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2}>
                      <DataView data={log.dados} />
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            }
          case 'ATUALIZACAO':
            if (!log.diferencas) {
              return null
            } else {
              return (
                <Accordion key={log.id}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>
                      {date} - {time} - Processo:{' '}
                      <b>{tiposProcessos[log.processo]}</b> - Usuário:{' '}
                      <b>{log.usuario}</b> - Modelo: <b>{log.modelo}</b>
                      <LogCustomHeader customHeaders={log.customHeaders} />
                    </Typography>
                  </AccordionSummary>

                  <AccordionDetails>
                    <Grid container spacing={2}>
                      {log.diferencas.map((diferenca, index, diferencas) => (
                        <Grid item xs={12} key={diferenca.campo}>
                          <UpdateView
                            field={diferenca.campo}
                            oldValue={diferenca.dadoAntigo}
                            newValue={diferenca.dadoNovo}
                            isLast={index === diferencas.length - 1}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              )
            }
          case 'OUTRO':
            return (
              <Accordion key={log.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {date} - {time} - Processo:{' '}
                    <b>{tiposProcessos[log.processo]}</b> - Usuário:{' '}
                    <b>{log.usuario}</b>
                    <LogCustomHeader customHeaders={log.customHeaders} />
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Box display="flex" flexDirection="column">
                    {getLogInfo(log.mensagem)}
                  </Box>
                </AccordionDetails>
              </Accordion>
            )

          default:
            return null
        }
      })}
    </>
  )
}

export default observer(LogsList)
