import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useAbility } from '../login'
import { CotacoesFilter } from './CotacoesFilter'
import { CotacoesTable } from './CotacoesTable'
import { cotacoesRepository } from './hooks/cotacoesRepository'
import cotacoesStore from './store/cotacoes'

export type CotacoesQueryFilter = {
  [key: string]: any
}

export const Cotacoes = () => {
  const ability = useAbility()
  const { initialValues } = useQueryParams<CotacoesQueryFilter>()
  const authorization = getAuthorizations(ability, 'Cotações')

  async function handleDelete(id: string) {
    const response = await cotacoesRepository.delete(id)
    cotacoesStore.cotacoes.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    cotacoesStore.cotacoes.setPage(Number(initialValues.page) || 1)
    cotacoesStore.cotacoes.setFilters(initialValues)
  }, [initialValues])

  return (
    <>
      <CotacoesFilter />
      <CotacoesTable onDelete={handleDelete} authorization={authorization} />
    </>
  )
}
