import { useQueryParams } from '@elentari/core'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useProgramacao } from '../hooks/useProgramacao'
import ProgramacoesView from './ProgramacoesView'
import { ProgramacaoForm } from './ProgramacaoForm'

export function ProgramacoesMasterDetail() {
  const [programacaoState, programacaoActions] = useProgramacao()
  const [, appBarActions] = useAppBar()
  const {
    initialValues: { view }
  } = useQueryParams<{ view: boolean }>()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      programacaoState,
      u => u.entity.id,
      u =>
        view
          ? `Consultar Programação #${u.entity.codigo}`
          : `Programação #${u.entity.codigo}`
    )
  ])
  useFetchAndReset(programacaoActions.fetch, programacaoActions.reset)
  return handleStateErrorsToRender(
    programacaoState,
    view ? <ProgramacoesView /> : <ProgramacaoForm />
  )
}
