import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { ContratosFilter } from './ContratosFilter'
import { ContratosTable } from './ContratosTable'
import { contratosRepository } from './hooks/contratosRepository'
import contratosStore from './store/store'
import { ContratosQueryFilter } from './types/types'

const ContratosList = () => {
  const { initialValues } = useQueryParams<ContratosQueryFilter>()
  const ability = useAbility()

  async function handleDelete(id: string) {
    const response = await contratosRepository.delete(id)
    contratosStore.contratos.populate()

    if (response.ok) {
      snackbarStore.setMessage('Cancelado com sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    contratosStore.contratos.setPage(Number(initialValues.page) || 1)
    contratosStore.contratos.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  const authorization = getAuthorizations(ability, 'Contratos')

  return (
    <>
      <ContratosFilter />
      <ContratosTable onDelete={handleDelete} authorization={authorization} />
    </>
  )
}

export default ContratosList
