import { Grid, Typography } from '@material-ui/core'
import { Alert, Color } from '@material-ui/lab'
import {
  AvisoDetail,
  CorAviso
} from 'src/modules/grupos-negociadores/types/aviso'

export interface Props {
  avisos: AvisoDetail[]
}

function mapCorAviso(cor: CorAviso) {
  const mapper: Record<CorAviso, Color> = {
    [CorAviso.VERDE]: 'success',
    [CorAviso.AMARELO]: 'warning',
    [CorAviso.VERMELHO]: 'error'
  }

  return mapper[cor]
}

export const AvisosGrupoNegociador = ({ avisos }: Props) => {
  const verdes = avisos.filter(aviso => aviso.cor === CorAviso.VERDE)
  const amarelos = avisos.filter(aviso => aviso.cor === CorAviso.AMARELO)
  const vermelhos = avisos.filter(aviso => aviso.cor === CorAviso.VERMELHO)

  const buildAvisoItems = (
    verdes: AvisoDetail[],
    amarelos: AvisoDetail[],
    vermelhos: AvisoDetail[]
  ) => {
    const maxLength = Math.max(verdes.length, amarelos.length, vermelhos.length)

    if (maxLength === 0) {
      return [
        buildAviso(null, 'Nenhuma classificação verde', 0),
        buildAviso(null, 'Nenhuma classificação amarela', 0),
        buildAviso(null, 'Nenhuma classificação vermelha', 0)
      ]
    }

    const array: JSX.Element[] = []

    for (let index = 0; index < maxLength; index++) {
      const verde = verdes[index] ?? null
      const amarelo = amarelos[index] ?? null
      const vermelho = vermelhos[index] ?? null

      array.push(buildAviso(verde, 'Nenhuma classificação verde', index))
      array.push(buildAviso(amarelo, 'Nenhuma classificação amarela', index))
      array.push(buildAviso(vermelho, 'Nenhuma classificação vermelha', index))
    }

    return array
  }

  const buildAviso = (
    aviso: Nullable<AvisoDetail>,
    emptyString: string,
    index: number
  ) => {
    return (
      <Grid item xs={4} key={index}>
        {aviso === null ? (
          index === 0 ? (
            <Typography style={{ textAlign: 'center' }}>
              {emptyString}
            </Typography>
          ) : (
            <></>
          )
        ) : (
          <Alert
            style={{ minHeight: '3rem', alignItems: 'center' }}
            variant="filled"
            severity={mapCorAviso(aviso.cor)}
          >
            {aviso.descricao}
          </Alert>
        )}
      </Grid>
    )
  }

  return (
    <Grid container spacing={2} alignItems="center">
      {buildAvisoItems(verdes, amarelos, vermelhos)}
    </Grid>
  )
}
