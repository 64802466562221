import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import { SelectFilterGeneric } from 'src/components/ColumnsFiltersTable'
import When from 'src/components/When'
import { DeleteRecord } from '../../components'
import { especificoOptions, tiposCarga, unidades } from './types'

interface IProdutosColumnProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const ProdutosColumns = ({
  onDelete,
  authorization
}: IProdutosColumnProps): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo'
    },
    {
      Header: 'Nome',
      accessor: 'nome'
    },
    {
      Header: 'Agrupador Produto',
      accessor: 'agrupadorProduto'
    },
    {
      Header: 'Código NCM',
      accessor: 'codigoNCM'
    },
    {
      Header: 'Unidade',
      accessor: 'unidade',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: unidades
        })
    },
    {
      Header: 'Tipo de Carga',
      accessor: 'tipoCarga',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: tiposCarga
        })
    },
    {
      Header: 'Específico',
      accessor: 'especifico',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: especificoOptions,
          uppercase: false
        })
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <DeleteRecord
                  onDelete={() => onDelete(props.row.original.id)}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
