import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import { GetApp } from '@material-ui/icons/'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import snackbarStore from 'src/stores/snackbar'
import { xlsxDownload } from 'src/utils/downloaders'
import { formatUsername } from 'src/utils/formatUsername'
import { formatDateTime } from 'src/utils/formatters/formatDate'
import { formatProdutoLote } from 'src/utils/formatters/formatProdutoLote'
import { formatValorLote } from 'src/utils/formatters/formatValorLote'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { formatDestinoLote } from '../../utils/formatters/formatDestinoLote'
import { LotesValidationColumns } from './LotesValidationColumns'
import { useLotesXLSX } from './hooks/useLoteXLSX'
import { lotesStore } from './stores/lotes'
import { LoteList } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  isValidation?: boolean
}

export const LotesTableValidation = observer(
  ({ onDelete, authorization, isValidation }: Props) => {
    const { pathname } = useLocation()

    const KEY_TABLE = resourceTabela.VALIDAR_LOTES
    useConfigTable(KEY_TABLE)
    const tableState = tableStore.getState(KEY_TABLE)
    const isTableReady =
      !lotesStore.lotes.isLoading && tableState && lotesStore.lotes.list.length

    const columns: Array<Column & IColumn> = useMemo(
      () => LotesValidationColumns({ onDelete, authorization }),
      [onDelete, authorization]
    )

    const rows = lotesStore.lotes.list.map(({ ...lote }: LoteList) => {
      return {
        ...lote,
        emailCriador: formatUsername(lote.emailCriador),
        createdAt: formatDateTime(new Date(lote.updatedAt ?? lote.createdAt)),
        cliente: lote.Cliente?.nome || 'Não Informado',
        emailResponsavel: formatUsername(lote.analistaResponsavel),
        origem: `${lote.Origem.cidade} / ${lote.Origem.siglaEstado}`,
        destino: formatDestinoLote(lote.Destino),
        produto: formatProdutoLote(lote.Produto),
        freteEmpresa: formatValorLote(lote.freteEmpresa),
        freteSubcontratadoPJ: formatValorLote(lote.freteSubcontratadoPJ),
        freteSubcontratadoPF: formatValorLote(lote.freteSubcontratadoPF)
      }
    })

    const { getXLSXData, format } = useLotesXLSX({
      ...lotesStore.lotes.filters,
      limit: lotesStore.lotes.totalCount
    })

    const downloadXLSX = async () => {
      const response = await getXLSXData()
      if (response.ok) {
        xlsxDownload(format(response.data!), 'Lotes')
      } else {
        snackbarStore.setMessage(
          response.data?.message ??
            'Ocorreu um erro ao solicitar os dados para download'
        )
      }
    }

    const actions = [
      () => (
        <Tooltip title="Download XLSX" aria-label="Download XLSX">
          <IconButton aria-label="download" onClick={() => downloadXLSX()}>
            <GetApp />
          </IconButton>
        </Tooltip>
      )
    ]

    const sortMapper = new Map([
      ['centroCusto', 'CentroCusto.descricao'],
      ['cliente', 'GrupoNegociador.nome'],
      ['origem', 'Origem.nome'],
      ['produto', 'Produto.nome']
    ])

    return (
      <>
        <Spacer y={4} />
        <Paper>
          <TableContainer
            loading={lotesStore.lotes.isLoading}
            totalCount={lotesStore.lotes.totalCount}
          >
            {isTableReady ? (
              <BaseTable
                keyTable={KEY_TABLE}
                columns={columns}
                actionsTable={actions}
                hasConditionClickRow={false}
                linkWithRedirectClickRow={pathname}
                addedLinkClickRow="?view=true"
                data={rows}
                sortMapper={sortMapper}
              />
            ) : (
              <NoResultsTable loading={lotesStore.lotes.isLoading} />
            )}
          </TableContainer>
        </Paper>
      </>
    )
  }
)
