import { Box, Button, Divider, Grid, Typography } from '@material-ui/core'
import { useHistory } from 'react-router'
import { ConsultaField } from 'src/components/ConsultaField'
import { formatDate } from 'src/utils/formatters'
import { LoteContrato } from '../types/types'

interface Props {
  lotes: LoteContrato[]
}

const LotesList = ({ lotes }: Props) => {
  const history = useHistory()

  function handleGoBack() {
    history.goBack()
  }

  return (
    <Box display="flex" flexDirection="column" padding={1}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        style={{ marginBottom: 16 }}
      >
        <Typography variant="h6" style={{ width: 550 }}>
          Lista de Lotes do Contrato
        </Typography>
      </Box>
      <Grid>
        {lotes &&
          lotes.map((lote, index) => (
            <Grid container spacing={4} key={index}>
              <Grid item xs={3}>
                <ConsultaField
                  title="Codigo do Lote"
                  subtitle={lote.codigo || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={3}>
                <ConsultaField
                  title="Status"
                  subtitle={lote.status || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={3}>
                <ConsultaField
                  title="Cliente"
                  subtitle={lote.grupoNegociadorNome || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={3}>
                <ConsultaField
                  title="Produto"
                  subtitle={lote.nomeProduto || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={3}>
                <ConsultaField
                  title="Data Inicio Retirada"
                  subtitle={formatDate(lote.dataInicio) || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={3}>
                <ConsultaField
                  title="Data Fim Retirada"
                  subtitle={formatDate(lote.dataTermino) || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={3}>
                <ConsultaField
                  title="Tabela"
                  subtitle={lote.descricaoTabela || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={3}>
                <ConsultaField
                  title="Quantidade Programada Lote"
                  subtitle={lote.quantidadeRetirada || ''}
                  subtitleVariant="body2"
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
            </Grid>
          ))}
      </Grid>
      <Grid
        justifyContent="flex-end"
        item
        container
        spacing={2}
        style={{ marginTop: 8 }}
      >
        <Grid item style={{ width: 160 }}>
          <Button
            fullWidth
            type="button"
            variant="outlined"
            onClick={handleGoBack}
          >
            Voltar
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LotesList
