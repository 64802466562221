import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from '../../../services/api'
import { paginationParams } from '../../../utils/paginationQueryParams'
import {
  AlterarStatusData,
  Contrato,
  ContratoDetail,
  ContratoSave,
  LoteContrato,
  StatusContrato
} from '../types/types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, ...rest } = params

  return {
    ...paginationParams(rest),
    limit: limit || '10'
  }
}

export const contratosRepository = makeRepository<
  EdgesPage<Contrato>,
  ContratoSave,
  ContratoDetail,
  string
>({ resource: 'contratos', mapQueryParams }, apiSauceInstance)

export const alterarStatus = async (id: string, data: AlterarStatusData) => {
  return apiSauceInstance.patch<
    { status: StatusContrato },
    { message: string; error: any }
  >(`/contratos/${id}/alterar-status/`, data)
}

export const getLotesByContratoId = async (contratoId: string) => {
  return apiSauceInstance.get<LoteContrato[], { message: string; error: any }>(
    `/contratos/${contratoId}/lotes`
  )
}
