import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import {
  TipoNegociacao,
  tiposNegociacao
} from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import {
  TiposPedagio,
  tiposPedagio
} from 'src/components/Formik/Forms/Inputs/TipoPedagioSelectInput'
import { TabelaDetail } from 'src/modules/contratos/types/tabelaTypes'
import { apiSauceInstance } from 'src/services/api'
import { SimularFreteContratoForm } from '../../types/simularFrete'

export const ContratoListener = () => {
  const formik = useFormikContext<SimularFreteContratoForm>()

  const tabelaId = formik.values.tabelaId

  function formatTipoNegociacaoEmpresaValue(tipoNegociacao: TipoNegociacao) {
    return (
      tiposNegociacao.find(item => item.value === tipoNegociacao)?.label ||
      'N/A'
    )
  }

  function formatTipoPedagioValue(tipoPedagio: TiposPedagio | null) {
    if (!tipoPedagio) {
      return 'N/A'
    }

    return tiposPedagio.find(item => item.value === tipoPedagio)?.label || `N/A`
  }

  async function getTabela(tabelaId: string) {
    const tabela = await apiSauceInstance.get<TabelaDetail>(
      `/contratos/tabelas/${tabelaId}`
    )

    if (tabela.ok) {
      formik.setValues({
        ...formik.values,
        codigo: tabela.data!.Contrato.codigo,
        descricaoContrato: tabela.data!.Contrato.descricao,
        grupoNegociador: tabela.data!.Contrato.GrupoNegociador.nome,
        agrupadorProduto: tabela.data!.Contrato.AgrupadorProdutos.nome,
        descricaoTabela: tabela.data!.descricao || '',
        contratoId: tabela.data!.contratoId || '',
        tipoNegociacao: formatTipoNegociacaoEmpresaValue(
          tabela.data!.tipoNegociacaoEmpresa
        ),
        tipoPedagio: tabela.data!.pedagio
          ? formatTipoPedagioValue(tabela.data!.tipoPedagio)
          : '',
        frete: tabela.data!.icms ? 'Sim' : 'Não',
        adicionalEntrega: tabela.data!.adicionalEntrega ? 'Sim' : 'Não',
        franquiaEntrega: tabela.data!.TabelaFrete.franquiaEntregas ?? 'N/A'
      })
    }
  }

  useEffect(() => {
    if (tabelaId) {
      getTabela(tabelaId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabelaId])

  return null
}
