import { LoteList, TipoContratacao, TiposLote } from 'src/modules/lotes/types'
import { ModalidadeTransportador, ProgramacaoDetail } from '../types'
import {
  DestinoPernaFormData,
  LoteProgramacaoFormData,
  ProgramacaoFormData
} from '../types/formData'

type ProgramacaoFormDataSpotOmitLotes = Omit<
  ProgramacaoFormData,
  | 'lotes'
  | 'tipoContratacaoLotesSelecionados'
  | 'modalidadeTransportador'
  | 'tipoNegociacaoSubcontratado'
>

export class ProgramacaoFormDataAdapter {
  private adaptFormData({
    embarcador,
    CentroCusto,
    ...data
  }: ProgramacaoDetail): ProgramacaoFormDataSpotOmitLotes {
    return {
      id: data.id,
      capacidadeCargaVeiculo: data.capacidadeCargaVeiculo,
      dataCarregamento: new Date(data.dataCarregamento),
      dataDescarregamento: new Date(data.dataDescarregamento),
      horaCarregamento: new Date(data.horaCarregamento),
      horaDescarregamento: new Date(data.horaDescarregamento),
      nomeResponsavel: data.nomeResponsavel || '',
      telefoneResponsavel: data.telefoneResponsavel || '',
      motorista: data.motorista,
      cpfMotorista: data.cpfMotorista,
      telefoneMotorista: data.telefoneMotorista || '',
      placaPrincipal: data.placaPrincipal,
      placa_1: data.placa_1 || '',
      placa_2: data.placa_2 || '',
      placa_3: data.placa_3 || '',
      observacoes: data.observacoes || '',
      tipoPagamento: data.tipoPagamento || '',
      numeroCartao: data.numeroCartao || '',
      centroCustoId: {
        value: CentroCusto.id,
        name: CentroCusto.descricao
      },
      embarcador: {
        value: embarcador,
        name: embarcador
      },
      tipoLotesSelecionados: this.getTipoLotesSelecionados(data.Lotes),
      grupoNegociadorLotesSelecionados:
        this.getGrupoNegociadorLotesSelecionados(data.Lotes)
    }
  }

  private getTipoLotesSelecionados(data: LoteList[]) {
    return data[0].tipoLote as TiposLote
  }

  private getGrupoNegociadorLotesSelecionados(data: LoteList[]) {
    const grupoNegociador = data[0].Cliente

    return {
      id: grupoNegociador.id,
      descricao: grupoNegociador.nome
    }
  }

  private reduceLotesSpot(
    data: ProgramacaoDetail
  ): Record<string, LoteProgramacaoFormData> {
    return data.Lotes.reduce(
      (acc, currLote): Record<string, LoteProgramacaoFormData> => {
        const loteToProgramacao = data.LoteToProgramacao.filter(
          ltp => ltp.loteId === currLote.id
        )

        return {
          ...acc,
          [currLote.id]: {
            ...currLote,
            quantidadePrevista: loteToProgramacao.reduce(
              (acc, curr) => acc + curr.quantidadePrevista,
              0
            ),
            valorFreteAcordado: loteToProgramacao.reduce(
              (acc, curr) => acc + curr.valorFreteAcordado,
              0
            )
          }
        }
      },
      {}
    )
  }

  private reduceLotesContrato(
    data: ProgramacaoDetail
  ): Record<string, LoteProgramacaoFormData> {
    return data.Lotes.reduce(
      (acc, curr): Record<string, LoteProgramacaoFormData> => {
        const loteToProgramacao = data.LoteToProgramacao.filter(
          ltp => ltp.loteId === curr.id
        )
        const dadoComplementar = data.DadosComplementares.find(
          dadoComplementar => dadoComplementar.loteId === curr.id
        )

        return {
          ...acc,
          [curr.id]: {
            ...curr,
            quantidadePrevista: loteToProgramacao.reduce(
              (acc, curr) => acc + curr.quantidadePrevista,
              0
            ),
            valorFreteAcordado: loteToProgramacao.reduce(
              (acc, curr) => acc + curr.valorFreteAcordado,
              0
            ),
            possuiTrocaNotaFiscal:
              dadoComplementar?.possuiTrocaNotaFiscal ?? false,
            multiplasEntregas: dadoComplementar?.multiplasEntregas ?? false,
            pernas: dadoComplementar?.Pernas.map(perna => ({
              localCarregamento: perna.localOrigem || '',
              estadoOrigem: {
                name: perna.CidadeOrigem.Estado.nome,
                value: perna.CidadeOrigem.Estado.id
              },
              cidadeOrigem: {
                name: perna.CidadeOrigem.nome,
                value: perna.CidadeOrigem.id
              },
              destinos: perna.Destinos.map(
                (destino): DestinoPernaFormData => ({
                  localEntrega: destino.localDescarregamento || '',
                  estadoDestino: {
                    name: destino.CidadeDestino.Estado.nome,
                    value: destino.CidadeDestino.Estado.id
                  },
                  cidadeDestino: {
                    name: destino.CidadeDestino.nome,
                    value: destino.CidadeDestino.id
                  },
                  quantidade: destino.quantidade || 0
                })
              ),
              freteEmpresa: perna.Valores.freteEmpresa,
              valorPedagio: perna.Valores.valorPedagio,
              aliquotaIcms: perna.Valores.aliquotaIcms,
              freteGrupoDiferente: perna.Valores.freteGrupo !== null,
              freteGrupo: perna.Valores.freteGrupo,
              freteSubcontratado: perna.Valores.freteSubcontratado
            })) ?? [
              {
                localCarregamento: curr.localCarregamento || '',
                estadoOrigem: '' as any,
                cidadeOrigem: '' as any,
                destinos: [
                  {
                    localEntrega: '',
                    estadoDestino: '' as any,
                    cidadeDestino: '' as any,
                    quantidade: '' as any
                  }
                ],
                freteEmpresa: '' as any,
                valorPedagio: '' as any,
                aliquotaIcms: '' as any,
                freteGrupoDiferente: false,
                freteGrupo: '' as any,
                freteSubcontratado: '' as any
              }
            ],
            produtoId: dadoComplementar
              ? {
                  name: dadoComplementar.Produto.nome,
                  value: dadoComplementar.Produto.id
                }
              : ('' as any),
            resumoValores: dadoComplementar
              ? {
                  freteEmpresa: dadoComplementar.Valores.freteEmpresa,
                  valorPedagio: dadoComplementar.Valores.valorPedagio,
                  aliquotaIcmsReal: dadoComplementar.Valores.aliquotaIcms,
                  freteGrupoDiferente:
                    dadoComplementar.Valores.freteGrupo !== null,
                  freteGrupo: dadoComplementar.Valores.freteGrupo,
                  freteSubcontratadoPf:
                    data.modalidadeTransportador ===
                    ModalidadeTransportador.TERCEIRO_PF
                      ? dadoComplementar.Valores.freteSubcontratado
                      : 0,
                  freteSubcontratadoPj:
                    data.modalidadeTransportador ===
                    ModalidadeTransportador.TERCEIRO_PJ
                      ? dadoComplementar.Valores.freteSubcontratado
                      : 0,
                  freteAgregado: dadoComplementar.Valores.freteAgregado || 0,
                  margemGrupo: dadoComplementar.Valores.margemGrupo
                }
              : {
                  freteEmpresa: 0,
                  freteAgregado: 0,
                  freteSubcontratadoPj: 0,
                  freteSubcontratadoPf: 0,
                  margemGrupo: 0,
                  freteGrupo: 0,
                  valorPedagio: 0,
                  aliquotaIcmsReal: 0
                }
          }
        }
      },
      {}
    )
  }

  fromLotesSpot(data: ProgramacaoDetail) {
    const commonData = this.adaptFormData(data)

    return {
      ...commonData,
      tipoContratacaoLotesSelecionados: TipoContratacao.SPOT,

      lotes: this.reduceLotesSpot(data)
    } as ProgramacaoFormData
  }

  fromLotesContrato(data: ProgramacaoDetail) {
    const commonData = this.adaptFormData(data)

    return {
      ...commonData,
      tipoContratacaoLotesSelecionados: TipoContratacao.CONTRATO,
      lotes: this.reduceLotesContrato(data),
      modalidadeTransportador: data.modalidadeTransportador,
      tipoNegociacaoSubcontratado: data.tipoNegociacaoSubcontratado
    } as ProgramacaoFormData
  }
}
