import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { produtosRepository } from './hooks/produtoRepository'
import { ProdutoFilter, ProdutosQueryFilter } from './ProdutoFilter'
import { ProdutoTable } from './ProdutoTable'
import { produtosStore } from './stores'

const Produtos = () => {
  const ability = useAbility()
  const { initialValues } = useQueryParams<ProdutosQueryFilter>()

  async function handleDelete(id: string) {
    const response = await produtosRepository.delete(id)
    produtosStore.produtos.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  const authorization = getAuthorizations(ability, 'Produtos')

  useEffect(() => {
    produtosStore.produtos.setPage(Number(initialValues.page) || 1)
    produtosStore.produtos.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  return (
    <>
      <ProdutoFilter />
      <ProdutoTable authorization={authorization} onDelete={handleDelete} />
    </>
  )
}

export default Produtos
