import { Divider, Grid } from '@material-ui/core'
import { ConsultaField } from 'src/components/ConsultaField'
import { useLote } from '../hooks/useLote'
import ResumoQuantidadePrevista from './ResumoQuantidadePrevista'
import ViagensList from './ViagensList'

export const TabViagens = () => {
  const [loteState] = useLote()

  const subtitleSaldo = () => {
    if (loteState.tag === 'with-data') {
      if (loteState.entity.tipoLote === 'ABERTO') {
        return '-'
      }

      return loteState.entity.saldo
    }

    return 'N/A'
  }

  return loteState.tag === 'with-data' ? (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <ConsultaField
          title="QUANTIDADE TOTAL"
          subtitle={loteState.entity.quantidadeTotal || 'N/A'}
        />
      </Grid>

      <Grid item xs={4}>
        <ConsultaField
          title="QUANTIDADE PROGRAMADA"
          subtitle={loteState.entity.quantidadeRetirada || 'N/A'}
        />
      </Grid>

      <Grid item xs={4}>
        <ConsultaField title="SALDO" subtitle={subtitleSaldo()} />
      </Grid>

      {loteState.entity.LoteToProgramacao ? (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <ViagensList viagens={loteState.entity.LoteToProgramacao} />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {loteState.entity.tipoContratacao === 'CONTRATO' && (
            <Grid item xs={12}>
              <ResumoQuantidadePrevista lote={loteState.entity} />
            </Grid>
          )}
        </>
      ) : null}
    </Grid>
  ) : null
}
