import { Button, Grid } from '@material-ui/core'
import { useState } from 'react'
import { StatusLote } from 'src/modules/lotes/types'
import { returnStatusColor } from './Validation/StatusColors'
import ValidationConfirmDialog from './ValidationConfirmDialog'

type Props = {
  title: string
  message?: string
  status: StatusLote
  id: string
  isProgramacao?: boolean
  liberadaGestor?: boolean
}

export function ValidationRecord({ title, status, id, liberadaGestor }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Grid item style={{ width: 160 }}>
        <Button
          fullWidth
          type="button"
          variant="outlined"
          onClick={() => setIsModalOpen(true)}
          style={{
            backgroundColor: returnStatusColor(title).color
          }}
        >
          {title}
        </Button>
      </Grid>

      <ValidationConfirmDialog
        id={id}
        handleClose={() => setIsModalOpen(false)}
        liberadaGestor={liberadaGestor}
        open={isModalOpen}
        status={status}
      />
    </>
  )
}
