import { apiSauceInstance } from '../../../services/api'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'
import { Pessoa, PessoasDetail } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params

  return {
    ...paginationParams(rest),
    sort
  }
}

export const pessoasRepository = makeRepository<
  EdgesPage<PessoasDetail>,
  Pessoa,
  PessoasDetail,
  string
>({ resource: 'pessoas', mapQueryParams }, apiSauceInstance)
