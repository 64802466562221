import { makeUseFetchOne } from '@elentari/core/hooks/makeUseFetchOne'
import { GrupoNegociadorDetail } from '../types'
import { grupoNegociadorRepository } from './grupoNegociadorRepository'

export const useGrupoNegociador = makeUseFetchOne<
  GrupoNegociadorDetail,
  string
>({
  fetch: grupoNegociadorRepository.fetchOne
})
