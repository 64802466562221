import Filter from '@elentari/components-eve/components/Filter'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import * as dateFns from 'date-fns'
import { Observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import FFMaskedInput from '../../components/Forms/FFMaskedInput'
import RFFAutocomplete from '../../components/Forms/RFFAutocomplete'
import When from '../../components/When'
import yupValidation from '../../utils/yupValidation'
import { menuPropsMultipleSelect } from '../options'
import { paisesRepository } from '../paises/hooks/paisesRepository'
import pessoasStore from './store/store'
import { defaultTipo, PessoasQueryFilter, tiposPessoa } from './types'

const schema = yup.object().shape({})

const TipoListener = () => {
  const form = useForm()
  const formState = useFormState()

  useEffect(() => {
    const tipo = formState.values.tipo

    if (!tipo) {
      form.change('tipo', defaultTipo)
    }

    pessoasStore.setPessoaTipo(tipo || defaultTipo)
  }, [formState.values.tipo]) //eslint-disable-line

  return null
}

const PessoasFilter = () => {
  const { push, initialValues } = useQueryParams<PessoasQueryFilter>()
  const [pais, setPais] = useState<{ id: string; name: string }[]>([])
  const history = useHistory()

  const handleSubmitFilter = (
    values: Record<string, any> & { page: number }
  ) => {
    if (values.createdAt) {
      const dateFormatted = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )

      return push({
        ...values,
        createdAt: {
          gt: dateFormatted.toISOString()
        }
      })
    }

    push({
      ...values,
      createdAt: undefined
    })
  }

  const format = (initialValues: PessoasQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }
  const loadPais = async () => {
    const response = await paisesRepository.fetch({ limit: '999' })
    if (response.ok) {
      setPais(
        response.data?.edges.map(p => ({
          id: p.node.id,
          name: p.node.nome
        })) ?? []
      )
    }
  }
  const handleClear = () => {
    setTimeout(() => {
      history.push(window.location.pathname + '?tipo=PF')
    }, 1000)
  }

  useEffect(() => {
    loadPais()
  }, [])

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={handleClear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <TipoListener />
      <Grid container spacing={2}>
        <Observer>
          {() => (
            <>
              <Grid item xs={12} sm={pessoasStore.pessoaTipo === 'PF' ? 3 : 4}>
                <TextField
                  fullWidth
                  name="codigo"
                  label="Código"
                  type="number"
                />
              </Grid>
              <Grid item xs={12} sm={pessoasStore.pessoaTipo === 'PF' ? 3 : 4}>
                <Select
                  fullWidth
                  name="tipo"
                  label="Tipo"
                  items={tiposPessoa}
                  MenuProps={menuPropsMultipleSelect}
                />
              </Grid>
            </>
          )}
        </Observer>
        <Observer>
          {() => (
            <When value={pessoasStore.pessoaTipo === 'PF'} equals>
              <Grid item xs={12} sm={3}>
                <TextField fullWidth name="nome" label="Nome" />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FFMaskedInput mask="999.999.999-99" name="cpf" label="CPF" />
              </Grid>
            </When>
          )}
        </Observer>
        <Observer>
          {() => (
            <When value={pessoasStore.pessoaTipo === 'PJ'} equals>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name="nomeFantasia"
                  label="Nome Fantasia"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField fullWidth name="razaoSocial" label="Razão social" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <FFMaskedInput
                  mask="99.999.999/9999-99"
                  name="cnpj"
                  label="CNPJ"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name="inscricaoEstadual"
                  label="Inscrição Estadual"
                />
              </Grid>
            </When>
          )}
        </Observer>
        <Observer>
          {() => (
            <When value={pessoasStore.pessoaTipo === 'PE'} equals>
              <Grid item xs={12} sm={4}>
                <TextField fullWidth name="nome" label="Nome" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField fullWidth name="documento" label="Documento" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  name="tipoDocumento"
                  label="Tipo Documento"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <RFFAutocomplete name="paisId" label="País" options={pais} />
              </Grid>
            </When>
          )}
        </Observer>
      </Grid>
    </Filter>
  )
}

export default PessoasFilter
