export interface LogsQueryFilter {
  dataInicial?: string
  dataFinal?: string
  modelo?: string
  modeloId?: string
  consulta?: string
  page?: string
}

export interface LogsFilter {
  dataInicial?: Date
  dataFinal?: Date
  modelo?: { name: string; value: string }
  consulta?: string
}

export interface Mensagem {
  [key: string]: any
}

export interface CustomHeaderLog {
  key: string
  value: string
}

export enum ProcessoEnum {
  CADASTRO = 'CADASTRO',
  ATUALIZACAO = 'ATUALIZACAO',
  EXCLUSAO = 'EXCLUSAO',
  OUTRO = 'OUTRO'
}

export interface Log {
  id: string
  processo: ProcessoEnum
  usuario: string

  /**
   * Date no back e string nas requests
   */
  createdAt: Date | string

  /**
   * Somente no tipo ATUALIZACAO
   */
  diferencas?: { campo: string; dadoAntigo: string; dadoNovo: string }[]
  /**
   * Somente no tipo CADASTRO e EXCLUSAO
   */
  dados?: Record<string, string>
  /**
   * Soemnte nos 3 tipos específicos
   */
  modeloId?: string
  modelo?: string

  /**
   * Somente no tipo OUTRO
   */
  mensagem?: string | Mensagem

  customHeaders?: Array<CustomHeaderLog>
}

export const tiposProcessos = {
  CADASTRO: 'CADASTRO',
  ATUALIZACAO: 'ATUALIZAÇÃO',
  EXCLUSAO: 'EXCLUSÃO',
  OUTRO: 'OUTRO'
}
