import { Divider, Grid, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import { BlackDisabledTextInput } from 'src/utils/BlackDisabledTextInput'
import { ContratoListener } from './ContratoListener'

interface Props {
  handleReset: () => void
}

export const DadosContrato = ({ handleReset }: Props) => {
  const formik = useFormikContext<any>()

  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  return (
    <>
      <ContratoListener />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Dados do Contrato</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AsyncSearchInput
            name="contratoId"
            label="Contrato"
            url="/contratos"
            filters={{
              status: 'ATIVO'
            }}
            labelField="descricao"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <AsyncSearchInput
            name="tabelaId"
            label="Descrição da Tabela"
            url={
              formik.values.contratoId &&
              `contratos/${formik.values.contratoId.value}/descricoes-tabelas`
            }
            labelField="descricao"
            disabled={!!!formik.values.contratoId}
          />
        </Grid>

        {!!formik.values.contratoId && (
          <>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="codigo"
                label="ID Contrato"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="descricaoContrato"
                label="Descrição do Contrato"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="grupoNegociador"
                label="Grupo Negociador"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="agrupadorProduto"
                label="Agrupador de Produto"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="tipoNegociacao"
                label="Tipo Negociação Empresa"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="tipoPedagio"
                label="Tipo de Pedágio"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="frete"
                label="Frete + ICMS"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="adicionalEntrega"
                label="Adicional de Entrega"
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <BlackDisabledTextInput
                name="franquiaEntrega"
                label="Franquia de Entrega"
                disabled
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  )
}
