import { IconButton } from '@material-ui/core'
import { SendOutlined } from '@material-ui/icons'
import { Fragment, useState } from 'react'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import { useLote } from '../hooks/useLote'
import { DivulgarFreteDialog } from './DivulgarFrete'

interface DivulgarLoteProps {
  loteId: string
  canDivulgarLote: boolean
}

export function DivulgarLote({ loteId, canDivulgarLote }: DivulgarLoteProps) {
  const [isModalDivulgacao, setIsModalDivulgacao] = useState(false)
  const [loteState, actions] = useLote()

  const handleModalDivulgacao = () => {
    setIsModalDivulgacao(prev => !prev)
  }

  return (
    <Fragment>
      <ConditionalTooltip
        title="Não é possivel divulgar este lote."
        enabled={!canDivulgarLote}
      >
        <IconButton
          onClick={() => {
            handleModalDivulgacao()
            actions.fetch(loteId)
          }}
          disabled={!canDivulgarLote}
        >
          <SendOutlined />
        </IconButton>
      </ConditionalTooltip>
      {loteState.tag === 'with-data' && (
        <DivulgarFreteDialog
          modal={isModalDivulgacao}
          handleModal={handleModalDivulgacao}
          lote={loteState.entity}
        />
      )}
    </Fragment>
  )
}
