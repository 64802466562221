import { styled } from '@mui/material/styles'
import { observer } from 'mobx-react-lite'
import { Marker } from 'react-map-gl'
import mapaProgramacoesStore from './store'

const SIZE = 40
const HOVER_SIZE = SIZE + 10

const CustomMarker = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -(SIZE / 2),
  left: -(SIZE / 2),
  cursor: 'pointer',
  height: SIZE,
  width: SIZE,
  borderRadius: '50%',
  color: 'white',
  fontSize: 12,
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: theme.transitions.create(['width', 'height', 'top', 'left']),
  '&:hover': {
    height: HOVER_SIZE,
    width: HOVER_SIZE,
    top: -(HOVER_SIZE / 2),
    left: -(HOVER_SIZE / 2)
  }
}))

function getPriority(programacoes: number) {
  if (programacoes > 200) {
    return 10
  }

  if (programacoes > 150) {
    return 9
  }

  if (programacoes > 100) {
    return 8
  }

  return 0
}

function getMarkerColor(programacoes: number) {
  const colors = [
    'rgba(49, 178, 255, 0.9)',
    'rgba(25, 194, 46, 0.9)',
    'rgba(232, 180, 41, 0.9)',
    'rgba(231, 40, 40,  0.9)'
  ]

  if (programacoes > 200) {
    return colors[3]
  }

  if (programacoes > 150) {
    return colors[2]
  }

  if (programacoes > 100) {
    return colors[1]
  }

  return colors[0]
}

function CitiesMarkers() {
  let data = mapaProgramacoesStore.data

  function handleCityClick(city: any) {
    return () => {
      mapaProgramacoesStore.setDrawerOpen(true)

      setTimeout(() => {
        mapaProgramacoesStore.setSelectedCity(city)
      }, 200)
    }
  }

  return (
    <>
      {data!.map((city, index) => (
        <Marker
          key={`marker-${index}`}
          longitude={Number(city.longitude)}
          latitude={Number(city.latitude)}
        >
          <CustomMarker
            onMouseEnter={() => mapaProgramacoesStore.setSelectedCity(city)}
            onMouseLeave={() => mapaProgramacoesStore.setSelectedCity(null)}
            onClick={handleCityClick(city)}
            style={{
              background: getMarkerColor(city.programacoes!.length),
              zIndex: getPriority(city.programacoes!.length)
            }}
          >
            {city.programacoes.length}
          </CustomMarker>
        </Marker>
      ))}
    </>
  )
}

export default observer(CitiesMarkers)
