import { ApiResponse } from 'apisauce'
import qs from 'qs'

export type OrderType = 'ASC' | 'DESC'
export type OrderBy = Record<string, OrderType>

export type Loaded<T> = {
  tag: 'with-data'
  loading: boolean
  list: T[]
  page: number
  totalCount: number
  hasMore: boolean
}

export type Empty = { tag: 'empty'; loading: boolean }
export interface Error {
  tag: 'error'
  error: string
  status: number | undefined
  data: unknown
}

export type ListState<T> = Loaded<T> | Error | Empty

export type InfinitListActions<T> = {
  fetchMore: (fetcher: Fetch<T>) => (params: qs.ParsedQs) => Promise<void>
}

export interface EdgesPage<T> {
  totalCount: number
  edges: { node: T }[]
}

export type ListActions = {
  fetchPage: (params: qs.ParsedQs) => Promise<void>
  reset: () => void
}

export interface ApiQueryParams {
  entries: [string, string][]
}

export type FetchSubRepository<T, PARENT_ID> = (
  parentId: PARENT_ID,
  search: qs.ParsedQs
) => Promise<ApiResponse<T>>
export type Fetch<T> = (search: qs.ParsedQs) => Promise<ApiResponse<T>>
export type FetchOne<T, P> = (params: P) => Promise<ApiResponse<T>>

export interface Store<T> {
  setState: (state: T) => void
  state: T
}

export type StoreActions<T, A> = {
  [P in keyof A]: (store: Store<T>) => A[P]
}

export interface SubmitValues {
  createdAt?: string
  name?: string
  active?: string
  page?: number
}

export interface historyType {
  location: {
    pathname: string
    search: string
  }
}

export interface SpringPage<T> {
  _embedded: {
    content: T[]
  }
  page: {
    size: number
    totalElements: number
    totalPages: number
    number: number
  }
}

export type FetchWithData<T> = {
  tag: 'with-data'
  entity: T
}

export type FetchState<T> =
  | {
      tag: 'initial'
    }
  | {
      tag: 'empty-response'
    }
  | FetchWithData<T>
  | Error

export type UseList<T> = [ListState<T>, { fetchPage: () => void }]

type ContratoTabs = 'tabelas' | ''

type ContratoTab = {
  value: ContratoTabs
  label: string
}

export const contratosTabs: ContratoTab[] = [
  {
    value: '',
    label: 'Contrato'
  },
  { value: 'tabelas', label: 'Tabelas' }
]

export const mapContratoFragmentReplace = (
  fetchState: FetchState<unknown>,
  tabName: Exclude<ContratoTabs, ''>
): [string, string, string] => {
  const tab = contratosTabs.find(t => t.value === tabName)!

  return [tab.value, tab.label, fetchState.tag]
}
