import { LabelFormatValue } from 'src/components/Formik/Forms/PureAsyncSearchInput'
import { PessoasDetail } from '../types'

export const formatLabelPerson = (
  data: FormatLabel<PessoasDetail>
): LabelFormatValue => {
  const pessoa = 'node' in data ? data.node : data
  const { codigo, cpf, cnpj, documento, nome, razaoSocial, id } = pessoa

  const customDocumento = cpf || cnpj || documento
  const customNome = nome || razaoSocial

  return {
    name: `${codigo} - ${customDocumento} - ${customNome}`,
    value: id
  }
}
