import { observer } from 'mobx-react-lite'
import { Fragment, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { ProdutosColumns } from './ProdutoColumns'
import { produtosStore } from './stores'
import { ProdutoDetail, TiposCarga, Unidades } from './types'

interface Props {
  onDelete: (id: string) => void
  authorization: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const ProdutoTable = observer(({ onDelete, authorization }: Props) => {
  const { pathname } = useLocation()

  const KEY_TABLE = resourceTabela.PRODUTOS
  useConfigTable(KEY_TABLE)
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !produtosStore.produtos.isLoading &&
    tableState &&
    produtosStore.produtos.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => ProdutosColumns({ onDelete, authorization }),
    [onDelete, authorization]
  )

  const rows = produtosStore.produtos.list.map(
    ({ ...produto }: ProdutoDetail) => {
      return {
        ...produto,
        agrupadorProduto: produto.AgrupadorProdutos?.nome,
        unidade: Unidades[produto.unidade],
        tipoCarga: TiposCarga[produto.tipoCarga],
        especifico: produto.especifico ? 'Sim' : 'Não'
      }
    }
  )

  const sortMapper = new Map([['agrupadorProduto', 'AgrupadorProdutos.nome']])

  return (
    <Fragment>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={produtosStore.produtos.isLoading}
          totalCount={produtosStore.produtos.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow={false}
              linkWithRedirectClickRow={pathname}
              data={rows}
              sortMapper={sortMapper}
            />
          ) : (
            <NoResultsTable loading={produtosStore.produtos.isLoading} />
          )}
        </TableContainer>
      </Paper>

      <AddRecord path={pathname} authorization={authorization} />
    </Fragment>
  )
})
