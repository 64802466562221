import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import { ConfiguracaoesColumns } from './ConfiguracoesColumns'
import { configuracaoStore } from './stores'
import { ConfiguracoesDetail } from './types'

export const ConfiguracoesTable = observer(() => {
  const KEY_TABLE = resourceTabela.CONFIGURACOES
  useConfigTable(KEY_TABLE)
  const { pathname } = useLocation()
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !configuracaoStore.configuracao.isLoading &&
    tableState &&
    configuracaoStore.configuracao.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => ConfiguracaoesColumns(),
    []
  )

  const rows = configuracaoStore.configuracao.list.map(
    ({ ...config }: ConfiguracoesDetail) => {
      return {
        ...config
      }
    }
  )

  return (
    <Paper>
      <TableContainer
        loading={configuracaoStore.configuracao.isLoading}
        totalCount={configuracaoStore.configuracao.totalCount}
      >
        {isTableReady ? (
          <BaseTable
            size="medium"
            keyTable={KEY_TABLE}
            columns={columns}
            hasConditionClickRow={false}
            linkWithRedirectClickRow={pathname}
            data={rows}
          />
        ) : (
          <NoResultsTable loading={configuracaoStore.configuracao.isLoading} />
        )}
      </TableContainer>
    </Paper>
  )
})
