import { makeStyles, Chip } from '@material-ui/core'
import { statusColors, StatusLote } from './types'
import { formatStatusLote } from '../../utils/formatters/formatStatus'
import { indigo } from '@material-ui/core/colors'

interface SetBadgeColorProps {
  status: Exclude<StatusLote, ''>
  marginLeft: string
}

const useStyles = makeStyles(theme => ({
  customBadge: (props: SetBadgeColorProps) => {
    return {
      backgroundColor: statusColors[props.status] || indigo[50],
      width: 96,
      color: theme.palette.getContrastText(
        statusColors[props.status] || indigo[50]
      )
    }
  }
}))

function StatusBadge(props: SetBadgeColorProps) {
  const classes = useStyles(props)

  return (
    <Chip
      label={formatStatusLote(props.status)}
      size="small"
      variant="default"
      className={classes.customBadge}
    />
  )
}

export default StatusBadge
