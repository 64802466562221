import { useQueryParams } from '@elentari/core'
import { MenuItem, TextField } from '@material-ui/core'
import { Select } from '@material-ui/core/'
import * as dateFns from 'date-fns'
import { useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import ReactInputMask from 'react-input-mask'
import { HeaderGroup } from 'react-table'
import { useDebounce } from 'src/hooks/useDebounce'
import CurrencyField, {
  CurrencyFieldProps,
  HTMLNumericElement
} from './Formik/Forms/Inputs/CurrencyField'
import { handleKeyCodes, handlePasteIntegerOnly } from 'src/modules/options'

interface IPropsColumnFilter {
  column: HeaderGroup<any>
}

export interface DefaultColumnFilterProps extends IPropsColumnFilter {
  onlyNumber?: boolean
}

interface IPropsSelectColumnFilterSelect extends IPropsColumnFilter {
  options: Array<{ label: string; value: string }>
  uppercase?: boolean
  formatter?: (value: any) => any
}

interface IPropsMaksColumnFilter extends IPropsColumnFilter {
  mask: string | (string | RegExp)[]
}

interface IPropsNumericColumnFilter
  extends IPropsColumnFilter,
    CurrencyFieldProps {}

export function DefaultColumnFilter({
  column,
  onlyNumber
}: DefaultColumnFilterProps) {
  const queryParams = useQueryParams<{
    [key: string]: any
  }>()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toUpperCase()

    column.setFilter(value || undefined)
    queryParams.push({
      [column.id]: value || undefined
    })
  }

  return (
    <DebounceInput
      value={
        column.filterValue?.toUpperCase() ||
        queryParams.initialValues[column.id] ||
        ''
      }
      onChange={e => handleChange(e)}
      onKeyDown={e => (onlyNumber ? handleKeyCodes(e) : undefined)}
      onPaste={(e: React.ClipboardEvent<HTMLDivElement>) =>
        onlyNumber ? handlePasteIntegerOnly(e, 0) : undefined
      }
      placeholder="Pesquisar por..."
      debounceTimeout={1000}
      element={TextField}
    />
  )
}

export function SelectFilterGeneric({
  column,
  options,
  uppercase = true,
  formatter
}: Omit<IPropsSelectColumnFilterSelect, 'store'>) {
  const queryParams = useQueryParams<{
    [key: string]: any
  }>()

  const handleChange = (
    e: React.ChangeEvent<{
      name?: string | undefined
      value: unknown
    }>
  ) => {
    column.setFilter((e.target.value as string).toUpperCase() || undefined)

    if (formatter) {
      queryParams.push({ [column.id]: formatter(e.target.value) })
    } else {
      queryParams.push({
        [column.id]: uppercase
          ? (e.target.value as string).toUpperCase() || undefined
          : (e.target.value as string) || undefined
      })
    }
  }

  return (
    <Select
      fullWidth
      defaultValue={queryParams.initialValues[column.id]}
      value={column.filterValue}
      onChange={e => handleChange(e)}
    >
      <MenuItem value="">Todos</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  )
}

export function DatePickerFilter(props: IPropsColumnFilter) {
  const { column } = props
  const queryParams = useQueryParams<{
    [key: string]: any
  }>()
  const [dateFilterUrl, setDateFilterUrl] = useState<string>('')

  useEffect(() => {
    if (queryParams.initialValues[column.id]) {
      const date = new Date(queryParams.initialValues[column.id]).toUTCString()

      const dateFilterUrl = new Date(date)

      setDateFilterUrl(dateFilterUrl.toISOString().split('T')[0])
    }
  }, [])

  const handleChange = (data: any, value: string | null | undefined) => {
    column.setFilter(value || undefined)

    let dataValue = value ? new Date(value) : null

    if (dataValue) {
      const date = dateFns.parseISO(dataValue.toISOString().split('T')[0])
      queryParams.push({
        [column.id]: date.toUTCString()
      })
    }
  }

  return (
    <DebounceInput
      value={column.filterValue ?? dateFilterUrl}
      onChange={e => handleChange(e, e.target.value)}
      debounceTimeout={1000}
      type="date"
      maxLength={8}
      element={TextField}
    />
  )
}

export function MaskFilter({ column, mask }: IPropsMaksColumnFilter) {
  const queryParams = useQueryParams<{
    [key: string]: any
  }>()
  const [value, setValue] = useState<string | null>(null)

  const debouncedValue = useDebounce(value, 1000)

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (debouncedValue) {
      column.setFilter(debouncedValue)
      queryParams.push({
        [column.id]: debouncedValue
      })
    }
  }, [debouncedValue])

  return (
    <ReactInputMask
      maskChar=""
      value={value ?? queryParams.initialValues[column.id]}
      onChange={e => handleChange(e)}
      mask={mask}
      alwaysShowMask={false}
      placeholder="Pesquisar por..."
    >
      {(inputProps: any) => (
        <TextField {...inputProps} variant="standard" fullWidth value={value} />
      )}
    </ReactInputMask>
  )
}

export function NumericFilter({ column, ...props }: IPropsNumericColumnFilter) {
  const queryParams = useQueryParams<{
    [key: string]: any
  }>()
  const [value, setValue] = useState<number | null | ''>(null)

  const debouncedValue = useDebounce(value, 1000)

  const handleChange = (e: React.ChangeEvent<HTMLNumericElement>) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (debouncedValue !== null) {
      column.setFilter(debouncedValue)
      queryParams.push({
        [column.id]: debouncedValue
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue])

  return (
    <CurrencyField
      {...props}
      value={value ?? queryParams.initialValues[column.id]}
      onChange={e => handleChange(e)}
      placeholder="Pesquisar por..."
    />
  )
}
