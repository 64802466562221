import { Grid, Paper, Typography } from '@material-ui/core'
import { styled } from '@material-ui/core/styles'
import { Skeleton } from '@material-ui/lab'

interface InfoCardProps {
  title: string
  info: string
  loading?: boolean
}

const InfoPaper = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  padding: theme.spacing(2)
}))

export default function InfoCard({ title, info, loading }: InfoCardProps) {
  return (
    <InfoPaper>
      <Typography variant="subtitle1">{title}</Typography>
      {loading ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Skeleton animation="wave" height={32} />
          </Grid>
        </Grid>
      ) : (
        <Typography variant="h5">{info}</Typography>
      )}
    </InfoPaper>
  )
}
