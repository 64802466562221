import { useQueryParams } from '@elentari/core'
import { observer } from 'mobx-react-lite'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Column, HeaderGroup } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import {
  MaskFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { PessoasColumns } from './PessoasColumns'
import pessoasStore from './store/store'
import { PessoasDetail, PessoasQueryFilter, tiposPessoa } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

const PessoasTable = observer(({ onDelete, authorization }: Props) => {
  const { pathname } = useLocation()

  const KEY_TABLE = resourceTabela.PESSOAS
  useConfigTable(KEY_TABLE)
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !pessoasStore.pessoas.isLoading &&
    tableState &&
    pessoasStore.pessoas.list.length

  const { initialValues } = useQueryParams<PessoasQueryFilter>()
  const [columnsByTipo, setColumnsByTipo] = useState<IColumn[]>([])

  useEffect(() => {
    if (initialValues.tipo === 'PF') {
      setColumnsByTipo([
        {
          Header: 'Nome',
          accessor: 'nome'
        },
        {
          Header: 'Tipo',
          accessor: 'tipo',
          Filter: props =>
            SelectFilterGeneric({
              column: props.column as HeaderGroup<{}>,
              options: tiposPessoa
            })
        },
        {
          Header: 'CPF',
          accessor: 'cpf',
          Filter: props =>
            MaskFilter({
              column: props.column as HeaderGroup<{}>,
              mask: '999.999.999-99'
            })
        },
        {
          Header: 'Endereço',
          accessor: 'endereco',
          sort: false
        },
        {
          Header: 'Cidade/Estado',
          accessor: 'cidadeEstado',
          sort: false
        }
      ])
    } else if (initialValues.tipo === 'PJ') {
      setColumnsByTipo([
        {
          Header: 'Nome Fantasia',
          accessor: 'nomeFantasia'
        },
        {
          Header: 'Razão Social',
          accessor: 'razaoSocial'
        },
        {
          Header: 'Tipo',
          accessor: 'tipo',
          Filter: props =>
            SelectFilterGeneric({
              column: props.column as HeaderGroup<{}>,
              options: tiposPessoa
            })
        },
        {
          Header: 'CNPJ',
          accessor: 'cnpj'
        },
        {
          Header: 'Inscrição Estadual',
          accessor: 'inscricaoEstadual'
        },
        {
          Header: 'Endereço',
          accessor: 'endereco',
          sort: false
        },
        {
          Header: 'Cidade/Estado',
          accessor: 'cidadeEstado',
          sort: false
        }
      ])
    } else {
      setColumnsByTipo([
        {
          Header: 'Nome',
          accessor: 'nome'
        },
        {
          Header: 'Tipo',
          accessor: 'tipo',
          Filter: props =>
            SelectFilterGeneric({
              column: props.column as HeaderGroup<{}>,
              options: tiposPessoa
            })
        },
        {
          Header: 'Documento',
          accessor: 'documento'
        },
        {
          Header: 'Tipo Documento',
          accessor: 'tipoDocumento'
        },
        {
          Header: 'Endereço',
          accessor: 'endereco',
          sort: false
        },
        {
          Header: 'País',
          accessor: 'pais',
          sort: false
        }
      ])
    }
  }, [initialValues.tipo])

  const columns: Array<Column & IColumn> = useMemo(
    () => PessoasColumns({ columnsByTipo, onDelete, authorization }),
    [onDelete, authorization, columnsByTipo]
  )

  const rows = pessoasStore.pessoas.list.map(({ ...pessoa }: PessoasDetail) => {
    return {
      ...pessoa,
      endereco: `${pessoa.enderecos[0].logradouro}, ${pessoa.enderecos[0].numero}`,
      cidadeEstado: `${pessoa.enderecos[0].Cidade!.nome} / ${
        pessoa!.enderecos[0].Estado!.sigla
      }`,
      pais: pessoa.enderecos[0].Pais?.nome
    }
  })

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={pessoasStore.pessoas.isLoading}
          totalCount={pessoasStore.pessoas.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow={false}
              linkWithRedirectClickRow={pathname}
              data={rows}
            />
          ) : (
            <NoResultsTable loading={pessoasStore.pessoas.isLoading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={pathname} authorization={authorization} />
    </>
  )
})

export default PessoasTable
