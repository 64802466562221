import { equals } from 'ramda'
import snackbarStore from 'src/stores/snackbar'
import { read, utils } from 'xlsx'
import { Column, ColumnName, FileDataProps, Id, KeyName, Line } from '../types'
import { columnsCapacidadesNames, columnsFaixasNames } from '../utils'
import { validateTarifa } from 'src/modules/contratos/utils'

const linhaBase = 7

const maxValueInt = 999_999

function formatarNumero(numero: string | number) {
  if (typeof numero === 'string') {
    numero = parseFloat(numero)
  }

  if (isNaN(numero)) {
    return '0.00'
  }

  const casasDecimais = Math.max(
    2,
    Math.min(8, getNumeroDeCasasDecimais(numero))
  )
  const multiplicador = Math.pow(10, casasDecimais)
  const numeroFormatado = Math.floor(numero * multiplicador) / multiplicador

  return numeroFormatado.toFixed(casasDecimais)
}

function getNumeroDeCasasDecimais(numero: string | number) {
  const partes = numero?.toString().split('.')
  if (partes.length === 1) {
    return 0
  }
  return partes[1].length
}

async function readFile(file: File) {
  const fileBuffer = await file.arrayBuffer()
  const convertedFile = read(fileBuffer)

  return convertedFile
}

async function getValuesFile(file: File) {
  const convertedFile = await readFile(file)

  const workssheetName = convertedFile.SheetNames[0]
  const worksheet = convertedFile.Sheets[workssheetName]
  const values = utils.sheet_to_json(worksheet) as unknown[]

  return values
}

function verifyTemplate(fileData: any[]) {
  if (fileData.length < 4) {
    throw new Error(
      'Foi utilizado um template incorreto ou os dados não foram preenchidos corretamente.'
    )
  }

  const columnsCapacidadesValues = Object.values(fileData[1])
  if (!equals(columnsCapacidadesValues, columnsCapacidadesNames)) {
    throw new Error('As colunas de capacidades estão incorretas.')
  }

  const columnsFaixasKmValues = Object.values(fileData[3])
  if (!equals(columnsFaixasKmValues, columnsFaixasNames)) {
    throw new Error('As colunas de faixas de km ou tarifas estão incorretas.')
  }
  const capacidadesValues = Object.values(fileData[2])
  capacidadesValues.forEach(capacidade => {
    if (!Number.isInteger(capacidade)) {
      throw new Error(
        `A coluna de capacidade '${capacidade}' não é um número inteiro.`
      )
    }

    const duplicates = capacidadesValues.filter(c => c === capacidade)
    if (duplicates.length > 1) {
      throw Error(`A capacidade ${capacidade} está em duplicidade na planilha.`)
    }
  })
}

function verifyLines(columnsName: ColumnName[], lines: Line[]) {
  lines.forEach((line, lineIndex) => {
    const erroBase = `Erro na linha ${lineIndex + linhaBase}`

    const lineValues = Object.values(line)
    const valuesWithoutUndefined = lineValues.filter(item => item !== undefined)

    if (columnsName.length !== valuesWithoutUndefined.length) {
      throw Error(
        `${erroBase}, as linhas devem possuir a mesma quantidade de colunas.`
      )
    }

    const kmInicial = line['kmInicial']
    if (!Number.isInteger(kmInicial)) {
      throw Error(`${erroBase}, KM inicial deve ser um número inteiro.`)
    }

    if (kmInicial > maxValueInt) {
      throw Error(
        `${erroBase} KM inicial deve ser menor do que ${maxValueInt}.`
      )
    }

    const kmFinal = line['kmFinal']
    if (!Number.isInteger(kmFinal)) {
      throw Error(`${erroBase}, KM final deve ser um número inteiro.`)
    }

    if (kmFinal > maxValueInt) {
      throw Error(`${erroBase}, KM final deve ser menor do que ${maxValueInt}.`)
    }

    lineValues.forEach((lineValue, index) => {
      if (lineValue && isNaN(lineValue)) {
        throw new Error(`${erroBase}, algum valor informado não é um número.`)
      }

      if (!validateTarifa(lineValue?.toString().replaceAll('.', ','), false)) {
        throw new Error(
          `${erroBase}, a tarifa ${lineValue} não deve possuir mais do que 8 dígitos inteiros ou 8 dígitos decimais.`
        )
      }

      if (!index) return
      const lastLineValue = lineValues[index - 1]

      if (lineValue && lastLineValue === undefined) {
        throw new Error(
          `${erroBase}, os valores devem ser preenchidos na ordem.`
        )
      }
    })
  })
}

function validateCapacidades(capacidadesValues: number[]) {
  capacidadesValues.forEach(capacidade => {
    if (capacidade > maxValueInt) {
      throw new Error(
        `Capacidade ${capacidade} deve ser menor que ${maxValueInt}.`
      )
    }
  })
}

function validateCapacidadesTabelaPedagio(
  capacidadesValues: number[],
  capacidadesTabelaFrete: number[]
) {
  capacidadesValues.forEach(capacidade => {
    if (!capacidadesTabelaFrete.includes(capacidade)) {
      throw new Error(
        `Não existe capacidade ${capacidade} informada na tabela de frete.`
      )
    }
  })

  if (!equals(capacidadesValues, capacidadesTabelaFrete)) {
    throw new Error(
      'As capacidades passadas não são iguais ou não estão na mesma ordem que esta na tabela de frete.'
    )
  }
}

function createTable(
  fileData: FileDataProps,
  capacidadesTabelaFrete?: number[]
) {
  let columns: Column[] = [
    { id: 'kmInicial', name: 'KM Inicial', values: [] },
    { id: 'kmFinal', name: 'KM Final', values: [] }
  ]

  const capacidadesValues = Object.values(fileData[2]).map(capacidade =>
    Number(capacidade)
  )

  capacidadesValues.forEach((capacidade: any, index: number) => {
    const id = `tarifa${index + 1}`

    columns.push({
      id,
      name: capacidade,
      values: []
    })
  })

  validateCapacidades(capacidadesValues)
  if (capacidadesTabelaFrete?.length) {
    validateCapacidadesTabelaPedagio(capacidadesValues, capacidadesTabelaFrete)
  }

  const lines: Line[] = fileData.slice(4).map(item => {
    const hasFirstValue = item.__EMPTY !== undefined

    const key = (key: KeyName): KeyName => {
      if (hasFirstValue) return key

      if (key === '__EMPTY') {
        return '__EMPTY_1'
      }

      let [, , , number] = key.split('_')
      const index = Number(number)

      if (isNaN(index)) {
        throw new Error('Houve um problema, o último caracter não é um número.')
      }

      return `__EMPTY_${index + 1}` as KeyName
    }

    return {
      kmInicial: item[key('__EMPTY')],
      kmFinal: item[key('__EMPTY_1')],
      tarifa1: item[key('__EMPTY_2')],
      tarifa2: item[key('__EMPTY_3')],
      tarifa3: item[key('__EMPTY_4')],
      tarifa4: item[key('__EMPTY_5')],
      tarifa5: item[key('__EMPTY_6')],
      tarifa6: item[key('__EMPTY_7')],
      tarifa7: item[key('__EMPTY_8')],
      tarifa8: item[key('__EMPTY_9')],
      tarifa9: item[key('__EMPTY_10')],
      tarifa10: item[key('__EMPTY_11')]
    }
  })

  const columnNames = columns.map(column => column.name)
  verifyLines(columnNames, lines)

  const table = columns.map(column => {
    const key = column.id as Id

    const values = lines.map(line => {
      if (column.name !== 'KM Inicial' && column.name !== 'KM Final') {
        return formatarNumero(line[key])?.replace('.', ',')
      }

      return line[key]
    })

    return { ...column, values } as Column
  })

  return table
}

export async function createValuesInTable(
  fileXlsx: Nullable<File>,
  capacidadesTabelaFrete?: number[]
) {
  try {
    if (!fileXlsx) {
      throw new Error('Houve um problema ao enviar o arquivo.')
    }

    const fileData = (await getValuesFile(fileXlsx)) as FileDataProps
    verifyTemplate(fileData)

    const table = createTable(fileData, capacidadesTabelaFrete)
    return table
  } catch (err: unknown) {
    const error = err as { message: string }
    snackbarStore.setMessage(error.message)
  }
}
