import { makeAutoObservable } from 'mobx'
import ApiSauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { LocalDetail } from '../types'

class LocaisStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  locais = new ApiSauceDataStore<LocalDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/locais',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
const locaisStore = new LocaisStore()
export default locaisStore
