import { MouseEvent, useState } from 'react'
import { Button, Menu, MenuItem } from '@material-ui/core'
import { Sort } from '@material-ui/icons'

type Props = {
  sortName: () => void
  sortQuantity: () => void
  textSortName: string
  textSortQuantity: string
}

export const ButtonSort = ({
  sortName,
  sortQuantity,
  textSortName,
  textSortQuantity
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <Button aria-haspopup={true} id="basic-button" onClick={handleClick}>
        <Sort fontSize="small" htmlColor="#6E8192" />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button'
        }}
      >
        <MenuItem
          onClick={() => {
            sortName()
            handleClose()
          }}
        >
          {textSortName}
        </MenuItem>
        <MenuItem
          onClick={() => {
            sortQuantity()
            handleClose()
          }}
        >
          {textSortQuantity}
        </MenuItem>
      </Menu>
    </div>
  )
}
