import {
  Grid,
  InputAdornment,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { TableCellHead, TableContainer } from 'src/components'
import Spacer from 'src/components/Spacer'
import Theme from 'src/Theme'
import tabelaIcmsStore from '../store'
import { ActionsIcms } from './ActionsIcms'
import IcmsInput from './DefaultInput'

const useStyle = makeStyles(theme => ({
  text: {
    lineHeight: '1',
    letterSpacing: '11px'
  },
  textVertical: {
    transform: 'rotate(-90deg)',
    lineHeight: '1',
    letterSpacing: '11px',
    position: 'absolute',
    left: -157,
    top: 900
  }
}))

export const TabelaICMSComponente = observer(() => {
  const classes = useStyle()

  return (
    <>
      <Typography variant="h6">Tabela de Alíquota ICMS - Frete</Typography>

      <Typography className={classes.textVertical} variant="h6">
        ESTADO DE ORIGEM
      </Typography>

      <Spacer y={2} />
      <Typography className={classes.text} align="center" variant="h6">
        ESTADO DE DESTINO
      </Typography>
      <Grid
        container
        style={{
          width: '90%',
          margin: 'auto'
        }}
        spacing={4}
      >
        <TableContainer
          loading={tabelaIcmsStore.tabela.columns.length === 0}
          totalCount={tabelaIcmsStore.tabelaIcms.list.length}
          disabledPagination
        >
          {tabelaIcmsStore.tabela.columns.length !== 0 && (
            <Table size="small">
              <TableHead>
                <TableCellHead label="-"></TableCellHead>

                {tabelaIcmsStore.getColumns().map(column => (
                  <TableCellHead label={`${column}`}></TableCellHead>
                ))}
                <TableCellHead label="EX"></TableCellHead>
              </TableHead>

              <TableBody>
                {tabelaIcmsStore.getColumns().map(col => (
                  <TableRow>
                    <TableCell>
                      <strong>{col}</strong>
                    </TableCell>
                    {tabelaIcmsStore.getRowsByEstadosDestino(col).map(row => (
                      <TableCell
                        style={{
                          backgroundColor:
                            row.estadoDestino === row.estadoOrigem
                              ? '#C19AA6'
                              : 'transparent',
                          color:
                            row.estadoDestino === row.estadoOrigem
                              ? Theme.palette.primary.contrastText
                              : 'inherit'
                        }}
                      >
                        <IcmsInput
                          id={row.id}
                          aliquota={row.aliquota}
                          maxValue={100}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            )
                          }}
                        />
                      </TableCell>
                    ))}
                    {tabelaIcmsStore.getRowsByEstadosWithEX(col) && (
                      <TableCell>
                        <IcmsInput
                          id={
                            tabelaIcmsStore.getRowsByEstadosWithEX(col)?.id ||
                            ''
                          }
                          aliquota={
                            tabelaIcmsStore.getRowsByEstadosWithEX(col)
                              ?.aliquota || 0
                          }
                          maxValue={100}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="start">
                                %
                              </InputAdornment>
                            )
                          }}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                <>
                  {tabelaIcmsStore.getRowsByEX().length !== 0 && (
                    <TableRow>
                      {tabelaIcmsStore.getRowsByEX().map((row, index) => (
                        <>
                          {index === 0 && (
                            <TableCell>
                              <strong>EX</strong>
                            </TableCell>
                          )}
                          <TableCell
                            style={{
                              backgroundColor:
                                row?.estadoDestino === row?.estadoOrigem
                                  ? '#C19AA6'
                                  : 'transparent',
                              color:
                                row?.estadoDestino === row?.estadoOrigem
                                  ? Theme.palette.primary.contrastText
                                  : 'inherit'
                            }}
                          >
                            <IcmsInput
                              id={row?.id || ''}
                              aliquota={row?.aliquota || 0}
                              maxValue={100}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                )
                              }}
                            />
                          </TableCell>
                        </>
                      ))}
                    </TableRow>
                  )}
                </>
              </TableBody>
            </Table>
          )}
        </TableContainer>

        <Grid item xs={12}>
          <ActionsIcms />
        </Grid>
      </Grid>
    </>
  )
})
