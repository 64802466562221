import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import { Event } from '@material-ui/icons'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'
import negociacoesNfStore from './store/negociacoesNf'
import { handleKeyCodes, handlePasteIntegerOnly } from '../options'

type NegociacoesNaoFechadasQueryFilter = {
  [key: string]: any
}

const schema = yup.object().shape({
  dataNegociacao: yup.date().nullable().typeError(MessagesYup.INVALID_DATE)
})

export const NegociacoesNaoFechadasFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<NegociacoesNaoFechadasQueryFilter>()

  const handleSubmitFilter = (
    values: NegociacoesNaoFechadasQueryFilter & { page: number }
  ) => {
    negociacoesNfStore.negociacoesNf.resetList()
    const filters: Record<string, unknown> = {}

    Object.entries(values).forEach(([key, value]) => {
      if (value instanceof Date) {
        return (filters[key] = value.toUTCString())
      } else if (value && typeof value === 'object') {
        return (filters[key] = value.value)
      } else if (value) {
        filters[key] = value
      }
    })

    push(filters)
  }

  const format = (initialValues: NegociacoesNaoFechadasQueryFilter) => {
    return initialValues
  }

  const handleClear = () => {
    negociacoesNfStore.negociacoesNf.resetList()
    clear()
  }

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={handleClear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            name="codigo"
            label="Código"
            type="number"
            inputProps={{ min: 0 }}
            onKeyDown={handleKeyCodes}
            onPaste={e => handlePasteIntegerOnly(e, 0)}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <DatePicker
            fullWidth
            name="dataNegociacao"
            label="Data da Negociação"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RFFAsyncSearchInput
            name="centroCustoId"
            label="Centro de Custo"
            url="/centros-custo/ativos"
            labelField="descricao"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth type="text" name="cliente" label="Cliente" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth type="text" name="produto" label="Produto" />
        </Grid>

        <Grid item xs={12} sm={4}>
          <RFFAsyncSearchInput
            name="origemId"
            label="Origem"
            url="/cidades"
            labelField="nome"
            joinStateAndCity
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RFFAsyncSearchInput
            name="destinoId"
            label="Destino"
            url="/cidades"
            labelField="nome"
            joinStateAndCity
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
