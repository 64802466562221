import { CustomHeaderLog } from '../types'

interface Props {
  customHeaders?: CustomHeaderLog[]
}

export const LogCustomHeader = ({ customHeaders }: Props) => {
  return (
    <>
      {customHeaders?.map(({ key, value }) => (
        <>
          {' '}
          - {key}: <b>{value}</b>
        </>
      ))}
    </>
  )
}
