import { useState } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'

export type InputCoordinateProps = Omit<TextFieldProps, 'onChange'> & {
  value?: number | string
  handleOnChange?: (value: React.ChangeEvent<HTMLInputElement>) => void
}

const inputRegex = /^[0-9|.|-]$/
const functionKeysRegex = /^F\d{1,2}$/

function InputCoordinate(props: InputCoordinateProps) {
  const { value, handleOnChange, ...inputProps } = props
  const [lastLetterEvent, setLastLetterEvent] =
    useState<React.KeyboardEvent<HTMLInputElement>>()

  function isNumber(str: any) {
    return !isNaN(str)
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>): void {
    const allowedKeysWithModifier = ['a', 'c', 'v']
    const allowedKeys = [
      'Backspace',
      'Tab',
      'Delete',
      'Enter',
      'ArrowLeft',
      'ArrowRight',
      'End',
      'Home'
    ]

    if (e.key === ' ') {
      e.preventDefault()
    }

    setLastLetterEvent(e)

    if (allowedKeys.includes(e.key)) {
      return
    }

    if (allowedKeysWithModifier.includes(e.key) && e.ctrlKey) {
      return
    }

    if (e.key.match(functionKeysRegex)) {
      return
    }

    if (!e.key.match(inputRegex)) {
      e.preventDefault()
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>): void {
    const lastLetter = lastLetterEvent?.key
    const value = e.target.value
    const valueArray = value.split('')
    const oldValue = e.target.defaultValue
    const arrayPontuation = value.split('.')

    if (lastLetter === '-') {
      if (oldValue.includes('-')) {
        e.target.value = oldValue
      }

      if (value[0] !== '-') {
        e.target.value = oldValue
      }
    }

    if (lastLetter === '.') {
      const pontuationString = valueArray
        .filter(element => element === '.')
        .join('')

      if (!value.indexOf('.')) {
        e.target.value = oldValue
      }

      if (value.length === 1) {
        e.target.value = oldValue
      }

      if (pontuationString.length === 2) {
        e.target.value = oldValue
      }

      if (value.replace('-', '').indexOf('.') > 3) {
        e.target.value = oldValue
        return
      }
    }

    if (
      lastLetter !== '.' &&
      lastLetter !== '-' &&
      value.replace('-', '').length === 3 &&
      !valueArray.includes('.') &&
      lastLetter !== 'Backspace'
    ) {
      e.target.value = `${value}.`
      handleOnChange?.(e)
      return
    }

    if (
      lastLetter !== '.' &&
      value.replace('-', '').length === 4 &&
      !valueArray.includes('.') &&
      lastLetter !== 'Backspace' &&
      lastLetter !== 'Delete'
    ) {
      e.target.value = `${value.slice(0, -1)}.${lastLetter}`
      handleOnChange?.(e)
      return
    }

    if (
      valueArray.includes('.') &&
      arrayPontuation[0].replace('-', '')?.length === 4
    ) {
      e.target.value = oldValue
      return
    }

    if (
      valueArray.includes('.') &&
      arrayPontuation[arrayPontuation.length - 1].length === 7 &&
      lastLetter !== 'Backspace' &&
      lastLetter !== '-'
    ) {
      e.target.value = oldValue
      return
    }

    if (
      (arrayPontuation.length === 2,
      arrayPontuation[arrayPontuation.length - 1].length >= 7) &&
      lastLetter === '.'
    ) {
      const firstPosition = arrayPontuation[0]
      const lastPosition = arrayPontuation[1].substring(0, 6)

      e.target.value = `${firstPosition}.${lastPosition}`
      handleOnChange?.(e)
      return
    }

    if (
      !valueArray.includes('.') &&
      value.replace('-', '').length > 9 &&
      lastLetter !== 'Backspace' &&
      lastLetter !== '-' &&
      lastLetter !== '.'
    ) {
      e.target.value = oldValue
      return
    }

    if (lastLetterEvent?.code === 'KeyV') {
      const newValue = value.trim()
      e.target.value = newValue

      if (!isNumber(value)) {
        e.target.value = oldValue
        return
      }

      if (value.replace('-', '').replace('.', '').length > 9) {
        e.target.value = oldValue
        return
      }
    }
    handleOnChange?.(e)
  }

  return (
    <TextField
      {...inputProps}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      defaultValue={value}
      value={value}
    />
  )
}

export default InputCoordinate
