import { Grid, Typography } from '@material-ui/core'
import { ConsultaField } from 'src/components/ConsultaField'
import { formatCurrency } from 'src/utils/formatters'
import { SimularFreteContrato } from '../types'

interface Props {
  simulacaoResult: SimularFreteContrato
}

export const ValoresSimulacao = ({ simulacaoResult }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Valores da Simulação</Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <ConsultaField
              title="Valor de Frete"
              subtitle={formatCurrency(simulacaoResult.valorFrete)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Valor de ICMS"
              subtitle={formatCurrency(simulacaoResult.valorIcms)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Valor de Pedágio"
              subtitle={formatCurrency(simulacaoResult.valorPedagio)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Frete Líquido"
              subtitle={formatCurrency(simulacaoResult.freteLiquido)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Adicional de Entrega"
              subtitle={formatCurrency(simulacaoResult.adicionalEntrega)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Valor total do Frete"
              subtitle={formatCurrency(simulacaoResult.valorTotalFrete)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
