import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { ModalidadeDetail } from '../types'

class ModalidadesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  modalidades = new ApisauceDataStore<ModalidadeDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/modalidades',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
export const modalidadesStore = new ModalidadesStore()
export default ModalidadesStore
