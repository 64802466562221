import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { ProgramacaoFormData } from '../../types/formData'

interface Props {
  namePrefix: string
}

export const OrigemInput = ({ namePrefix }: Props) => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const [, loteId, , pernaIndex] = namePrefix.split('.')
  const origem = formik.values.lotes[loteId].Origem

  const queryEstado = {
    sigla: origem.siglaEstado
  }

  const queryCidade = {
    ids: [origem.id]
  }

  const handleChangeEstado = (field: string) => {
    formik.setFieldValue(field, '')
  }

  return (
    <>
      <Grid item xs={12} md={3}>
        <TextInput
          name={
            +pernaIndex > 0
              ? `lotes.${loteId}.pernas.${
                  +pernaIndex - 1
                }.destinos.0.localEntrega`
              : `${namePrefix}.localCarregamento`
          }
          label="Local de Carregamento"
          disabled={+pernaIndex > 0}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <AsyncSearchInput
          name={
            +pernaIndex > 0
              ? `lotes.${loteId}.pernas.${
                  +pernaIndex - 1
                }.destinos.0.estadoDestino`
              : `${namePrefix}.estadoOrigem`
          }
          label="Estado Origem"
          url="/estados/minimal"
          labelField="nome"
          filters={queryEstado}
          joinStateAndUf
          disabled={+pernaIndex > 0}
          handleOnChange={() =>
            handleChangeEstado(`${namePrefix}.cidadeOrigem`)
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AsyncSearchInput
          name={
            +pernaIndex > 0
              ? `lotes.${loteId}.pernas.${
                  +pernaIndex - 1
                }.destinos.0.cidadeDestino`
              : `${namePrefix}.cidadeOrigem`
          }
          label="Cidade Origem"
          url="/cidades"
          labelField="nome"
          filters={queryCidade}
          disabled={+pernaIndex > 0}
        />
      </Grid>
    </>
  )
}
