import { blue, green, grey, red, yellow } from '@material-ui/core/colors'
import { TipoNegociacao } from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import { CidadesDetail } from 'src/modules/cidades/types'
import { EstadosDetail } from 'src/modules/estados/types'
import { Produto } from 'src/modules/produtos/types'
import {
  LoteList,
  LoteToProgramacao,
  LotesDetail,
  TipoContratacao
} from '../../lotes/types'

export interface Programacao {
  id?: string
  dataHoraCarregamento: Date
  dataHoraDescarregamento: Date
  capacidadeCargaVeiculo: string
  observacoes?: string | null
  motorista: string
  cpfMotorista: string
  centroCustoId: string
  embarcador: string
  placaPrincipal: string
  placa_1: string
  placa_2: string
  placa_3: string
  lotes: LotesDetail[]
  tipoPagamento: TipoPagamento
  numeroCartao: string
}

export interface AlterarStatusData {
  newStatus: StatusProgramacao
}

export interface CentroCustoMinimal {
  id: string
  descricao: string
}
export interface ProgramacaoDetail {
  id: string
  codigo: number
  dataHoraCarregamento: Date
  dataHoraDescarregamento: Date
  dataCarregamento: Date
  dataDescarregamento: Date
  horaCarregamento: Date
  horaDescarregamento: Date
  quantidade?: number
  capacidadeCargaVeiculo: string
  observacoes?: string
  status: Exclude<StatusProgramacao, ''>
  pesoCarregado?: string
  motorista: string
  cpfMotorista: string
  telefoneMotorista: string
  placaPrincipal: string
  placa_1?: string
  placa_2?: string
  placa_3?: string
  emailCriador: string
  centroCustoId: string
  embarcador: string
  tipoPagamento: TipoPagamento
  numeroCartao: string
  nomeResponsavel: string
  telefoneResponsavel: string
  categoriaTransportador: CategoriaTransportador

  CentroCusto: CentroCustoMinimal
  Lotes: LoteList[]
  LoteToProgramacao: LoteToProgramacao[]
  nextPossibleStatus: string[]
  isEditable: boolean
  isStatusChangeable: boolean
  isCancellable: boolean

  DadosComplementares: DadosComplementares[]

  modalidadeTransportador: Nullable<ModalidadeTransportador>
  tipoNegociacaoSubcontratado: Nullable<TipoNegociacao>

  LoteProgramacaoSnapshot: LoteProgramacaoSnapshot[]
}

export interface LoteProgramacaoSnapshot {
  id: string
  loteId: string
  programacaoId: string
  tipoContratacao: TipoContratacao
  localCarregamento: string
  cidadeOrigem: string
  estadoOrigem: string
  siglaEstadoOrigem: string
  clienteNome: string
  produtoNome: string
  numeroPedido: string
  centroCustoNome: string
  centroCustoDescricao: string
  centroCustoEndereco: string
  centroCustoCnpj: string
  centroCustoInscricaoEstadual: string
  centroCustoTelefone: string

  LoteProgramacaoDestinoSnapshot: LoteProgramacaoDestinoSnapshot[]
  LoteProgramacaoPernaSnapshot: LoteProgramacaoPernaSnapshot[]
}

export interface LoteProgramacaoDestinoSnapshot {
  id: string
  localDescarregamento: string
  cidadeDestino: string
  estadoDestino: string
  siglaEstadoDestino: string
}

export interface LoteProgramacaoPernaSnapshot {
  id: string
  localCarregamento: string
  cidadeOrigem: string
  estadoOrigem: string
  siglaEstadoOrigem: string
}

export interface DadosComplementares {
  id: string
  programacaoId: string
  loteId: string
  produtoId: string
  possuiTrocaNotaFiscal: boolean
  multiplasEntregas: boolean
  localCarregamento: string
  cidadeOrigemId: string

  CidadeOrigem: CidadeWithIncludes
  Destinos: DestinoProgramacao[]
  Pernas: PernaDadoComplementar[]
  Produto: Produto & { id: string }
  Valores: ValorProgramacao
}

export interface CidadeWithIncludes extends Omit<CidadesDetail, 'Estado'> {
  Estado: Omit<EstadosDetail, 'Pais'>
}

export interface DestinoProgramacao {
  id: string
  localDescarregamento: string
  cidadeDestinoId: string
  pernaId: string
  dadoComplementarId: string
  quantidade: number
  CidadeDestino: CidadeWithIncludes
}

export interface PernaDadoComplementar {
  id: string
  dadoComplementarId: string
  localOrigem: string
  cidadeOrigemId: string

  CidadeOrigem: CidadeWithIncludes
  Valores: ValorProgramacao
  Destinos: DestinoProgramacao[]
}

export interface ValorProgramacao {
  id: string
  freteEmpresa: number
  valorPedagio: number
  aliquotaIcms: number
  freteSubcontratado: number
  freteGrupo: number
  freteAgregado: number
  margemGrupo: number
  dadoComplementarId: Nullable<string>
  pernaId: Nullable<string>
}

export type DestinoLoteDetail = {
  id?: string
  Cidade: {
    id?: string
    nome: string
    Estado: {
      sigla: string
    }
  }
}

export interface ResumoValoresLote {
  freteEmpresa: number
  freteAgregado: number
  freteSubcontratadoPj: number
  freteSubcontratadoPf: number
  margemGrupo: number
  freteGrupo: number
  valorPedagio: number
  aliquotaIcmsReal: number
}

export interface BuscarLotesForm {
  id?: string
  capacidadeCargaVeiculo: string
  centroCustoId: { name: string; value: string }
  grupoNegociadorId: { name: string; value: string }
  produtoId: { name: string; value: string }
  cpfMotorista: string
  dataCarregamento?: Date
  dataDescarregamento?: Date
  dataHoraCarregamento: Date
  dataHoraDescarregamento: Date
  horaCarregamentoCadastro?: Date
  horaDescarregamento?: Date
  codigo?: number
  numeroPedido?: string
  localCarregamento?: string
  localDescarregamento?: string

  motorista: string
  placaPrincipal: string
  placa_1?: string
  placa_2?: string
  placa_3?: string
  observacoes?: string
  estadoOrigemId: { name: string; value: string }
  estadoDestinoId: { name: string; value: string }
  origemId: { name: string; value: string }
  destinoId: { name: string; value: string }

  lotes: { id: string; quantidadePrevista: number }[]
}

export interface ProgramacaoList {
  id: string
  codigo: number
  dataHoraCarregamento: Date
  dataHoraDescarregamento: Date
  placaPrincipal: string
  placa_1?: string
  placa_2?: string
  placa_3?: string
  cpfMotorista: string
  tipoPagamento?: string
  motorista?: string
  status: Exclude<StatusProgramacao, ''>
  createdAt: Date
  updatedAt: Date
  quantidade: number
  CentroCusto: CentroCustoMinimal
  Lotes: LoteList[]
  nextPossibleStatus: Exclude<StatusProgramacao, ''>[]
  isEditable: boolean
  isStatusChangeable: boolean
  isCancellable: boolean
  DadosComplementares: DadosComplementares[]
  LoteProgramacaoSnapshot: LoteProgramacaoSnapshot[]
  categoriaTransportador: CategoriaTransportador
}

export type StatusProgramacao =
  | 'EM_TRANSITO'
  | 'FILA'
  | 'CARREGADO'
  | 'CANCELADO'
  | 'ATRASADO'
  | ''

export const statusProgramacao = [
  { value: 'EM_TRANSITO', label: 'EM TRÂNSITO' },
  { value: 'FILA', label: 'FILA' },
  { value: 'CARREGADO', label: 'CARREGADO' },
  { value: 'CANCELADO', label: 'CANCELADO' },
  { value: 'ATRASADO', label: 'ATRASADO' }
]

export type ProgramacoesQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

export const tipoPagamentoOptions = [
  {
    value: 'CARTAO_REPOM',
    label: 'Cartão Repom'
  },
  {
    value: 'CARTAO_PAGBEM',
    label: 'Cartão PagBem'
  },
  {
    value: 'CARTAO_TARGET',
    label: 'Cartão Target'
  },
  {
    value: 'SEM_PARAR',
    label: 'Sem Parar'
  },
  {
    value: 'MOVMAIS',
    label: 'MovMais'
  }
]

export const categoriaTransportadorOptions = [
  {
    value: 'TAC',
    label: 'TAC'
  },
  {
    value: 'CTC',
    label: 'CTC'
  },
  {
    value: 'ETC_EQUIPARADO',
    label: 'ETC EQUIPARADO'
  },
  {
    value: 'ETC_NAO_EQUIPARADO',
    label: 'ETC NÃO EQUIPARADO'
  },
  {
    value: 'FALHA_CONSULTA',
    label: 'FALHA CONSULTA'
  },
  {
    value: 'NAO_INFORMADO',
    label: 'NÃO INFORMADO'
  },
  {
    value: 'PROCESSANDO',
    label: 'PROCESSANDO'
  }
]

export enum CategoriaTransportador {
  TAC = 'TAC',
  CTC = 'CTC',
  ETC_EQUIPARADO = 'ETC_EQUIPARADO',
  ETC_NAO_EQUIPARADO = 'ETC_NAO_EQUIPARADO',
  FALHA_CONSULTA = 'FALHA_CONSULTA',
  NAO_INFORMADO = 'NAO_INFORMADO',
  PROCESSANDO = 'PROCESSANDO'
}

export enum ModalidadeTransportador {
  FROTA = 'FROTA',
  GRUPO = 'GRUPO',
  AGREGADO = 'AGREGADO',
  TERCEIRO_PJ = 'TERCEIRO_PJ',
  TERCEIRO_PF = 'TERCEIRO_PF'
}

export const modalidadeTransportadorOptions = [
  { value: ModalidadeTransportador.FROTA, label: 'Frota' },
  { value: ModalidadeTransportador.GRUPO, label: 'Grupo' },
  { value: ModalidadeTransportador.AGREGADO, label: 'Agregado' },
  { value: ModalidadeTransportador.TERCEIRO_PJ, label: 'Terceiro PJ' },
  { value: ModalidadeTransportador.TERCEIRO_PF, label: 'Terceiro PF' }
]

export type TipoPagamento =
  | 'CARTAO_REPOM'
  | 'CARTAO_PAGBEM'
  | 'CARTAO_TARGET'
  | 'SEM_PARAR'
  | 'MOVMAIS'

export const cartaoList = ['CARTAO_REPOM', 'CARTAO_PAGBEM', 'CARTAO_TARGET']

export const statusColors = {
  EM_TRANSITO: blue[300],
  FILA: yellow[300],
  CARREGADO: green[300],
  CANCELADO: grey[500],
  ATRASADO: red[500]
}
