import Filter from '@elentari/components-eve/components/Filter'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import Grid from '@material-ui/core/Grid'
import * as dateFns from 'date-fns'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import { statusUnidadesNegocio } from './types'

const schema = yup.object().shape({
  descricao: yup.string().optional(),
  status: yup.string().nullable().optional()
})

export type UnidadeNegocioQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

export const UnidadeNegocioFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<UnidadeNegocioQueryFilter>()

  const handleSubmitFilter = (values: Record<string, any>) => {
    if (!values.status) {
      delete values.status
      return push({
        ...values
      })
    }
    push({
      ...values
    })
  }

  const format = (initialValues: UnidadeNegocioQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField fullWidth name="codigo" label="Código" type="number" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField fullWidth name="descricao" label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Select
            fullWidth
            name="status"
            label="Status"
            items={statusUnidadesNegocio}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
