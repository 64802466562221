import TableChartIcon from '@material-ui/icons/TableChart'
import { SpeedDialIcon } from '@material-ui/lab'
import { observer } from 'mobx-react-lite'
import { Fragment, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { SpeedDials } from 'src/components/SpeedDials'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { formatCurrency, formatDate } from '../../utils/formatters'
import { LeiuturaPlanilhaCotacoesDialog } from './components/LeituraPlanilhaCotacoesDialog'
import { CotacoesColumns } from './CotacoesColumns'
import cotacoesStore from './store/cotacoes'
import { CotacaoDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  authorization: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const CotacoesTable = observer(({ onDelete, authorization }: Props) => {
  const history = useHistory()
  const location = useLocation()
  const [open, setOpen] = useState(false)

  const { pathname } = useLocation()
  const KEY_TABLE = resourceTabela.COTACAO
  useConfigTable(KEY_TABLE)

  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !cotacoesStore.cotacoes.isLoading &&
    tableState &&
    cotacoesStore.cotacoes.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => CotacoesColumns({ onDelete, authorization }),
    [onDelete, authorization]
  )

  const rows = cotacoesStore.cotacoes.list.map(
    ({ ...cotacao }: CotacaoDetail) => {
      return {
        ...cotacao,
        centroCusto: cotacao.CentroCusto?.descricao || 'Não Informado',
        freteCotacao:
          cotacao.freteCotacao && formatCurrency(cotacao.freteCotacao),
        dataCotacao: formatDate(new Date(cotacao.dataCotacao)),
        origem: `${cotacao?.Origem?.nome} / ${cotacao?.Origem?.Estado?.sigla}`,
        destino: `${cotacao?.Destino?.nome} / ${cotacao?.Destino?.Estado?.sigla}`
      }
    }
  )

  const actions = [
    {
      icon: <SpeedDialIcon />,
      name: 'Criar Cotação',
      action: () => {
        history.push(`${location.pathname}/new`)
      }
    },
    {
      icon: <TableChartIcon />,
      name: 'Leitura de Planilha',
      action: () => {
        setOpen(true)
      }
    }
  ]

  const sortMapper = new Map([
    ['centroCusto', 'CentroCusto.descricao'],
    ['origem', 'Origem.nome'],
    ['destino', 'Destino.nome']
  ])

  return (
    <Fragment>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={cotacoesStore.cotacoes.isLoading}
          totalCount={cotacoesStore.cotacoes.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow={false}
              linkWithRedirectClickRow={pathname}
              data={rows}
              sortMapper={sortMapper}
            />
          ) : (
            <NoResultsTable loading={cotacoesStore.cotacoes.isLoading} />
          )}
        </TableContainer>
      </Paper>
      <SpeedDials authorization={authorization} actions={actions} />
      <LeiuturaPlanilhaCotacoesDialog
        open={open}
        handleClose={() => setOpen(false)}
      />
    </Fragment>
  )
})
