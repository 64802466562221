export interface Configuracoes {
  id?: string
  chave: string
  valor: string
  tipo?: TipoConfiguracao | string
}

export interface ConfiguracoesDetail extends Configuracoes {
  id: string
  codigo: string
}

export type TipoConfiguracao =
  | 'INTEGER'
  | 'STRING'
  | 'FLOAT'
  | 'DATE'
  | 'BOOLEAN'

export const tiposConfiguracao = [
  { value: 'INTEGER', label: 'Inteiro' },
  { value: 'STRING', label: 'Texto' },
  { value: 'FLOAT', label: 'Numeral' },
  { value: 'DATE', label: 'Data' },
  { value: 'BOOLEAN', label: 'Booleano' }
]
