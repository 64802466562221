import { StatusLoteEnum } from 'src/modules/lotes/types'
import { CreateRelatorioLoteDto, RelatorioInternoLoteFormData } from '../types'
import { ICreateRelatorioAdapter } from './CreateRelatorioAdapter.interface'

export class CreateRelatorioLoteAdapter
  implements
    ICreateRelatorioAdapter<
      RelatorioInternoLoteFormData,
      CreateRelatorioLoteDto
    >
{
  adapt(values: RelatorioInternoLoteFormData): CreateRelatorioLoteDto {
    return {
      description: values.description || undefined,
      filters: {
        createdAtDe: values.filters.createdAtDe || undefined,
        createdAtAte: values.filters.createdAtAte || undefined,
        dataInicioDe: values.filters.dataInicioDe || undefined,
        dataInicioAte: values.filters.dataInicioAte || undefined,
        dataTerminoDe: values.filters.dataTerminoDe || undefined,
        dataTerminoAte: values.filters.dataTerminoAte || undefined,
        origemId: values.filters.origemId?.value,
        destinoId: values.filters.destinoId?.value,
        centroCustoId: values.filters.centroCustoId?.value,
        unidadeNegocioId: values.filters.unidadeNegocioId?.value,
        produtoId: values.filters.produtoId?.value,
        agrupadorProdutosId: values.filters.agrupadorProdutosId?.value,
        grupoNegociadorId: values.filters.grupoNegociadorId?.value,
        status: values.filters.status?.length
          ? values.filters.status.map(({ value }) => value as StatusLoteEnum)
          : undefined,
        emailCriador: values.filters.emailCriador || undefined,
        analistaResponsavel: values.filters.analistaResponsavel || undefined,
        tipoContratacao: values.filters.tipoContratacao || undefined,
        especie: values.filters.especie || undefined,
        descricao: values.filters.descricao || undefined
      },
      config: {
        codigo: values.config.codigo,
        descricao: values.config.descricao,
        operacaoKMM: values.config.operacaoKMM,
        status: values.config.status,
        numeroPedido: values.config.numeroPedido,
        freteEmpresa: values.config.freteEmpresa,
        freteSubcontratadoPJ: values.config.freteSubcontratadoPJ,
        freteSubcontratadoPF: values.config.freteSubcontratadoPF,
        quantidadeRetirada: values.config.quantidadeRetirada,
        quantidadeTotal: values.config.quantidadeTotal,
        dataInicio: values.config.dataInicio,
        dataTermino: values.config.dataTermino,
        localCarregamento: values.config.localCarregamento,
        tipoContratacao: values.config.tipoContratacao,
        especie: values.config.especie,
        emailCriador: values.config.emailCriador,
        analistaResponsavel: values.config.analistaResponsavel,
        centroCusto: values.config.centroCusto,
        grupoNegociador: values.config.grupoNegociador,
        origem: values.config.origem,
        destino: values.config.destino,
        produto: values.config.produto,
        previsaoMargem: values.config.previsaoMargem,
        saldo: values.config.saldo
      }
    }
  }

  getSubmitUrl(): string {
    return 'relatorios-internos/lote'
  }
}
