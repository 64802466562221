import { Tooltip } from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { startOfDay } from 'date-fns'
import { useField } from 'formik'
import React from 'react'
import { formatDateToTimeZone } from 'src/utils/formatters/formatDate'

export interface FormikInputProps {
  name: string
  label: string
}

type DatePickerProps = Omit<
  KeyboardDatePickerProps,
  'label' | 'name' | 'onChange' | 'value'
>

interface DateInputProps extends DatePickerProps, FormikInputProps {
  value?: string | Date
  onChange?: (date: MaterialUiPickersDate) => void
  iconTooltipText?: string
  receivesUTCDate?: boolean
}

const DateInput = ({
  inputVariant = 'outlined',
  format = 'dd/MM/yyyy',
  cancelLabel = 'Cancelar',
  iconTooltipText = 'Selecionar data',
  fullWidth = true,
  receivesUTCDate = false,
  ...props
}: DateInputProps) => {
  const [field, meta, helpers] = useField(props.name)

  function handleChange(date: MaterialUiPickersDate) {
    const dateValue = formatDate(date)
    helpers.setTouched(true)

    props.onChange?.(dateValue)
    helpers.setValue(dateValue)
  }

  function handleBlur(
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    helpers.setTouched(true)

    props.onBlur?.(e)
  }

  function formatDate(date: MaterialUiPickersDate) {
    try {
      if (!date) return null

      const dateObject = startOfDay(formatDateToTimeZone(date))
      if (receivesUTCDate) new Date(date)

      return dateObject
    } catch (error) {
      return date
    }
  }

  return (
    <KeyboardDatePicker
      {...field}
      {...props}
      format={format}
      fullWidth={fullWidth}
      cancelLabel={cancelLabel}
      inputVariant={inputVariant}
      value={field.value ? formatDate(field.value) : null}
      onChange={handleChange}
      onBlur={handleBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      keyboardIcon={
        <Tooltip title={iconTooltipText}>
          <EventIcon />
        </Tooltip>
      }
    />
  )
}

export default DateInput
