import { isValid } from 'date-fns'
import { isNil } from 'ramda'
import { formatDateTime } from 'src/utils/formatters/formatDate'

interface KeyToTranslate {
  from: string
  to: string
}

const formatValue = (value: string) => {
  if (isNil(value)) {
    return ''
  }

  const data = new Date(value)
  if (isValid(data)) {
    if (data.toISOString() === value) {
      return formatDateTime(new Date(value))
    }
  }

  if (typeof value === 'object') {
    return JSON.stringify(value)
  }

  return value
}

const dateFieldsToTranslate: KeyToTranslate[] = [
  {
    from: 'createdAt',
    to: 'Data de criação'
  },
  {
    from: 'updatedAt',
    to: 'Data de atualização'
  },
  {
    from: 'Última atualização',
    to: 'Última atualização'
  },
  {
    from: 'dataInicio',
    to: 'Data Início'
  },
  {
    from: 'dataTermino',
    to: 'Data Término'
  }
]

export function translateKey(key: string) {
  const field = dateFieldsToTranslate.find(field => field.from === key)
  if (field) {
    return field.to
  }

  return key
}

export function translateValue(key: string, value: string) {
  return formatValue(value)
}
