import { green, grey } from '@material-ui/core/colors'
import { GridColDef } from '@mui/x-data-grid'
import { TipoNegociacao } from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import { TiposPedagio } from 'src/components/Formik/Forms/Inputs/TipoPedagioSelectInput'
import { Contrato } from './types'

export interface TabelaFormData {
  id?: string
  descricao: string
  tipoNegociacaoEmpresa: string
  icms: boolean
  pedagio: boolean
  tipoPedagio: string | null
  adicionalEntrega?: boolean
  valorAdicional?: number
  franquiaEntregas?: number
  tabelaFrete?: {
    capacidades: {
      capacidade: number
    }[]
    faixasKm: FaixaKm[]
    valores: number[][]
  }
  tabelaPedagio?: {
    capacidades: {
      capacidade: number
    }[]
    faixasKm: FaixaKm[]
    valores: number[][]
  }
}

interface FaixaKm {
  kmInicial: Number
  kmFinal: Number
}

export interface TabelaDetail {
  TabelaFrete: TabelaFreteDetail
  TabelaPedagio?: TabelaPedagioDetail
  adicionalEntrega: boolean
  contratoId: string
  descricao: string
  icms: boolean
  id: string
  codigo: number
  status: string
  pedagio: boolean
  tipoNegociacaoEmpresa: TipoNegociacao
  tipoPedagio: TiposPedagio | null

  Contrato: Contrato
  _count: {
    Lotes: number
  }
}

export interface TabelaFreteDetail {
  FaixasKm: FaixaKmDetail[]
  TarifasFrete: TarifaFreteDetail[]
  franquiaEntregas: number | undefined
  tabelaId: string
  valorAdicional: number | undefined
}

export interface TabelaPedagioDetail {
  FaixasKm: FaixaKmPedagioDetail[]
  TarifasPedagio: TarifaFreteDetail[]
  faixaQuilometragem: boolean
  tabelaId: string
}

export interface FaixaKmPedagioDetail {
  TarifaFaixasCapacidadeVeiculoPedagio: TarifaFaixaCapacidadePedagioDetail[]
  kmInicial: string
  kmFinal: string
  tabelaFreteId: string
  tabelaPedagio: string | null
}

export interface FaixaKmDetail {
  TarifaFaixasCapacidadeVeiculo: TarifaFaixaCapacidadeVeiculoDetail[]
  kmInicial: string
  kmFinal: string
  tabelaFreteId: string | null
  tabelaPedagio: string | null
}

export interface TarifaFaixaCapacidadeVeiculoDetail {
  faixasKmId: string
  id: string
  tarifa: string
  tarifaFreteId: string
}

export interface TarifaFaixaCapacidadePedagioDetail {
  faixasKmId: string
  tarifa: string
  tarifaPedagioId: string
}

export interface TarifaFreteDetail {
  TarifaFaixasCapacidadeVeiculoFrete: TarifaFaixaCapacidadeVeiculoDetail[]
  capacidade: number
  tabelaFreteId: string | null
  tabelaPedagioId: string | null
}

export interface FormatTabelas {
  descricao: string
  tipoNegociacaoEmpresa: string
  adicionalEntrega: boolean
  pedagio: boolean
  tipoPedagio: string | undefined
  icms: boolean
  tabelaFreteDto: {
    faixaQuilometragem: boolean
    valorAdicional: number | undefined
    franquiaEntregas: number | undefined
    capacidadesDto: {
      capacidade: number
    }[]
    faixasKmDto: {
      kmInicial: string
      kmFinal: string
    }[]
    valores: number[][]
  }
  tabelaPedagioDto?: {
    faixaQuilometragem: boolean
    valorAdicional: number | undefined
    franquiaEntregas: number | undefined
    capacidadesDto: {
      capacidade: number
    }[]
    faixasKmDto: {
      kmInicial: string
      kmFinal: string
    }[]
    valores: number[][]
  }
}

export const statusColors = {
  INATIVO: grey[300],
  ATIVO: green[300]
}

export type TiposTabela = 'PEDAGIO' | 'FRETE'

export type TabelaContratoType = {
  rows: any[]
  columns: GridColDef[]
}

export type StatusTabelaContrato = 'ATIVO' | 'INATIVO'

export interface AlterarStatusTabelaContratoData {
  newStatus: StatusTabelaContrato
}
