import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useHistory } from 'react-router-dom'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import DateInput from '../../../components/DateInput'

interface Props {
  loading: boolean
}

export const DadosSimulacao = ({ loading }: Props) => {
  const formik = useFormikContext<any>()
  const contratoId = formik.values.contratoId?.value
  const tabelaId = formik.values.tabelaId?.value
  const history = useHistory()

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <>
      {contratoId && tabelaId && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5">Dados da Simulação</Typography>
            </Grid>
            <Grid item xs={12} sm={4}>
              <AsyncSearchInput
                name="centroCustoId"
                label="Centros de Custo"
                url="/centros-custo"
                labelField="descricao"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DateInput label="Data Simulação" name="dataSimulacao" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <AsyncSearchInput
                name="capacidadeVeiculo"
                label="Capacidade do Veículo"
                url={`/contratos/${contratoId}/tabelas/${tabelaId}/capacidades-veiculo`}
                labelField="capacidade"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumericInput
                name="distancia"
                label="Distância (KM)"
                precision={0}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumericInput
                name="qtdEixos"
                label="Qtd. eixos veículo"
                precision={0}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumericInput
                name="qtdEntregas"
                label="Quantidade de Entregas"
                precision={0}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumericInput
                name="aliquotaIcms"
                label="Alíquota ICMS (%)"
                precision={2}
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">%</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumericInput
                name="pedagio"
                label="Valor Pedágio"
                precision={2}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <NumericInput
                name="qtdVolumes"
                label="Qtd. Volumes"
                precision={0}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid
                justifyContent="flex-end"
                container
                spacing={2}
                style={{ marginBottom: 12 }}
              >
                <Grid item style={{ width: 160 }}>
                  <Button
                    fullWidth
                    disabled={loading}
                    type="reset"
                    color="primary"
                    variant="outlined"
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item style={{ width: 160 }}>
                  <Button
                    fullWidth
                    onClick={handleGoBack}
                    disabled={loading}
                    type="button"
                    color="primary"
                    variant="outlined"
                  >
                    Voltar
                  </Button>
                </Grid>
                <Grid item style={{ width: 160 }}>
                  <Button
                    fullWidth
                    type="submit"
                    color="primary"
                    variant="contained"
                  >
                    Calcular
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
