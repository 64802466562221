import { FetchState } from '@elentari/core/types'
import { TipoContratacao, TiposLote } from 'src/modules/lotes/types'
import { MessagesYup } from 'src/modules/messages'
import yupContextValidation from 'src/utils/yupContextValidation'
import * as yup from 'yup'
import { YupContext } from '../store/store'
import { ModalidadeTransportador, ProgramacaoDetail } from '../types'
import { ProgramacaoFormData } from '../types/formData'
import { getPreviousQuantidadePrevistaForLote } from '../util/getPreviousQuantidadePrevistaForLote'
import { modalidadeIsSubcontratado } from '../util/modalidadeIsSubcontratado'

const requiredAsyncAutocompleteInput: yup.AnyObjectSchema = yup
  .object()
  .shape({
    name: yup.string(),
    value: yup.string()
  })
  .nullable()

const testOrigemPerna = (schema: yup.AnyObjectSchema) => {
  return schema.test(
    'origem-perna',
    MessagesYup.MENSAGEM_OBRIGATORIO,
    function (value, context) {
      const indexPerna = Number(
        context.path.split('.').slice(-2)[0].slice(-2)[0]
      )

      if (indexPerna > 0) {
        return true
      }

      if (!value) {
        return this.createError({
          path: context.path,
          message: MessagesYup.MENSAGEM_OBRIGATORIO
        })
      }

      return true
    }
  )
}

const validateQuantidadePrevista = (schema: yup.NumberSchema) => {
  return schema.test(
    'max-quantidade-prevista',
    'Valor maior do que saldo disponível',
    (value, context) => {
      const programacaoState = context.options.context
        ?.programacaoState as FetchState<ProgramacaoDetail>
      const tipoLote = context.parent.tipoLote as TiposLote
      const saldo: number = context.parent.saldo ?? 0

      if (tipoLote === 'ABERTO') {
        return true
      }

      if (tipoLote === 'VIAGEM') {
        return saldo >= 1
      }

      if (programacaoState.tag === 'with-data') {
        const previousQuantidadePrevista = getPreviousQuantidadePrevistaForLote(
          programacaoState.entity,
          context.parent.id
        )

        return saldo >= (value ?? 0) - previousQuantidadePrevista
      }

      return saldo >= (value ?? 0)
    }
  )
}

const validateValorFreteAcordado = (schema: yup.NumberSchema) => {
  return schema.test(
    'validate-valor-frete-acordado',
    'Valor do frete acordado maior que valor PJ',
    (value, context) => {
      const freteSubcontratadoPJ =
        context.parent.freteSubcontratadoPJ || Infinity

      return (value || 0) <= freteSubcontratadoPJ
    }
  )
}

const validateQuantidadeLotes = (schema: yup.AnyObjectSchema) => {
  return schema.test({
    name: 'lote-empty',
    test: function (value) {
      if (Object.keys(value).length === 0) {
        return this.createError({
          path: 'quantidadeLotes',
          message: 'Ao menos um lote é requerido'
        })
      }

      return true
    },
    exclusive: true
  })
}

const buildLoteContratoSchema = (formData: ProgramacaoFormData): any => {
  if (formData.tipoContratacaoLotesSelecionados === TipoContratacao.CONTRATO) {
    return {
      possuiTrocaNotaFiscal: yup
        .boolean()
        .required(MessagesYup.MENSAGEM_OBRIGATORIO),
      multiplasEntregas: yup
        .boolean()
        .required(MessagesYup.MENSAGEM_OBRIGATORIO),
      produtoId: requiredAsyncAutocompleteInput.required(
        MessagesYup.MENSAGEM_OBRIGATORIO
      ),
      pernas: yup.array().of(
        yup.object().shape({
          localCarregamento: yup.string().nullable().optional(),
          estadoOrigem: testOrigemPerna(requiredAsyncAutocompleteInput),
          cidadeOrigem: testOrigemPerna(requiredAsyncAutocompleteInput),
          destinos: yup.array().of(
            yup.object().shape({
              localEntrega: yup.string().nullable().optional(),
              estadoDestino: requiredAsyncAutocompleteInput.required(
                MessagesYup.MENSAGEM_OBRIGATORIO
              ),
              cidadeDestino: requiredAsyncAutocompleteInput.required(
                MessagesYup.MENSAGEM_OBRIGATORIO
              ),
              quantidade: yup
                .number()
                .nullable()
                .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
                .test(
                  'gt0',
                  'Quantidade deve ser maior que 0',
                  function (value, context) {
                    const pernasLength = (context as any).from[2].value.pernas
                      .length
                    const currentPerna = Number(context.path.split('[')[1][0])

                    return pernasLength - 1 === currentPerna
                      ? value !== 0
                      : true
                  }
                )
            })
          ),
          valorPedagio: yup
            .number()
            .nullable()
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
            .required(MessagesYup.MENSAGEM_OBRIGATORIO),
          aliquotaIcms: yup
            .number()
            .nullable()
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
            .required(MessagesYup.MENSAGEM_OBRIGATORIO),
          freteEmpresa: yup
            .number()
            .nullable()
            .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .moreThan(0, 'Frete empresa deve ser maior que 0'),
          freteGrupoDiferente: yup
            .boolean()
            .nullable()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO),
          freteGrupo: yup.mixed().when('$modalidadeTransportador', {
            is: (modalidadeTransportador: ModalidadeTransportador) =>
              modalidadeTransportador === ModalidadeTransportador.GRUPO,
            then: yup.mixed().when('freteGrupoDiferente', {
              is: true,
              then: yup
                .number()
                .nullable()
                .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
                .required(MessagesYup.MENSAGEM_OBRIGATORIO)
                .moreThan(0, 'Frete grupo deve ser maior que 0'),
              otherwise: schema => schema.notRequired()
            }),
            otherwise: yup.string().nullable().notRequired()
          }),
          freteSubcontratado: yup.mixed().when('$modalidadeTransportador', {
            is: (modalidadeTransportador: ModalidadeTransportador) =>
              modalidadeIsSubcontratado(modalidadeTransportador),
            then: yup
              .number()
              .nullable()
              .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
              .required(MessagesYup.MENSAGEM_OBRIGATORIO)
              .moreThan(0, 'Frete grupo deve ser maior que 0'),
            otherwise: yup.string().nullable().notRequired()
          })
        })
      )
    }
  }

  return {}
}

const buildDadosComplementaresSchema = (formData: ProgramacaoFormData): any => {
  if (formData.tipoContratacaoLotesSelecionados === TipoContratacao.CONTRATO) {
    return {
      modalidadeTransportador: yup
        .string()
        .required(MessagesYup.MENSAGEM_OBRIGATORIO),
      tipoNegociacaoSubcontratado: yup
        .string()
        .when('$modalidadeTransportador', {
          is: (modalidadeTransportador: ModalidadeTransportador) =>
            modalidadeIsSubcontratado(modalidadeTransportador),
          then: schema => schema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
          otherwise: schema => schema.nullable().optional()
        })
    }
  }

  return {}
}

const buildLotesSchema = (formData: ProgramacaoFormData) => {
  return yup.object().shape({
    ...buildDadosComplementaresSchema(formData),
    lotes: validateQuantidadeLotes(
      yup.object().shape(
        Object.keys(formData.lotes).reduce(
          (acc, key) => ({
            ...acc,
            [key]: yup.object().shape({
              quantidadePrevista: validateQuantidadePrevista(
                yup
                  .number()
                  .moreThan(0, 'Deve ser maior que 0')
                  .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
                  .required(MessagesYup.MENSAGEM_OBRIGATORIO)
              ),
              valorFreteAcordado: validateValorFreteAcordado(
                yup.number().when('$modalidadeTransportador', {
                  is: (modalidadeTransportador: ModalidadeTransportador) =>
                    modalidadeTransportador === ModalidadeTransportador.FROTA,
                  then: schema =>
                    schema
                      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
                      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
                  otherwise: schema =>
                    schema
                      .moreThan(0, 'Deve ser maior que 0')
                      .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
                      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
                })
              ),
              ...buildLoteContratoSchema(formData)
            })
          }),
          {}
        )
      )
    )
  })
}

export const buildProgramacaoYupValidation = (
  baseSchema: yup.AnyObjectSchema,
  context: YupContext & { programacaoState: FetchState<ProgramacaoDetail> }
) => {
  const fullSchema = yup.lazy(formData => {
    return baseSchema.concat(buildLotesSchema(formData))
  })

  return yupContextValidation(fullSchema, context)
}
