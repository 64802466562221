import { FetchState } from '@elentari/core/types'
import {
  GrupoNegociadorDetail,
  GrupoNegociadorDto,
  GrupoNegociadorFormData
} from '../types'

export function buildSubmitData(
  formData: GrupoNegociadorFormData,
  state: FetchState<GrupoNegociadorDetail>
): GrupoNegociadorDto {
  return {
    id: formData.id,
    nome: formData.nome,
    raizCnpj: formData.raizCnpj || null,
    pessoas: formData.pessoas.map(pessoa => ({ id: pessoa.value })),
    avisos: formData.avisos.map(aviso => ({
      descricao: aviso.descricao,
      cor: aviso.cor
    })),
    deletedPessoas:
      state.tag === 'with-data'
        ? formData.deletedPessoas.filter(Boolean).map(pessoaId => ({
            id: pessoaId
          }))
        : undefined
  }
}
