import { useQueryParams } from '@elentari/core'
import {
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { FiCheckCircle, FiHexagon, FiTrash } from 'react-icons/fi'
import api from 'src/services/api'

interface IListagemProps {
  id: string
  descricaoTabela: string
  isDefault: boolean
}

interface IProps {
  keyTable: string
  onSubmitAction: () => void
}

const useStyles = makeStyles(theme => ({
  list: {
    height: '150px',
    overflow: 'auto'
  },
  firstIcon: {
    marginRight: theme.spacing(1)
  }
}))

export const ListagemConfiguracoes = ({ keyTable, onSubmitAction }: IProps) => {
  const [data, setData] = useState<IListagemProps[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { initialValues, push } = useQueryParams<{ limit?: string }>()
  const classes = useStyles()

  useEffect(() => {
    async function syncData() {
      setIsLoading(true)
      const response = await api.getListConfigTable(keyTable)

      if (response.ok && response.data) {
        setData(response.data)
      }
      setIsLoading(false)
    }

    syncData()
  }, [keyTable])

  const handleAlternateDefault = async (id: string) => {
    setIsLoading(true)
    const response = await api.alternateDefaultConfigTable(id, keyTable)
    if (response.ok) {
      onSubmitAction()
      push({
        ...initialValues,
        limit: response.data?.limit
      })
      window.location.reload()
    }
    setIsLoading(false)
  }

  const handleDelete = async (id: string) => {
    setIsLoading(true)
    const response = await api.deleteConfigTable(id)
    let deletedIsDefault = false
    if (response.ok) {
      data.splice(
        data.findIndex(item => {
          if (item.id === id && item.isDefault) {
            deletedIsDefault = true
          }

          return item.id === id
        }),
        1
      )
      if (data.length < 1 || deletedIsDefault) {
        window.location.reload()
      }
    }
    setIsLoading(false)
  }

  return (
    <List className={classes.list}>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {data.length ? (
            <>
              {data.map(item => {
                const labelId = `checkbox-list-label-${item.id}`
                return (
                  <ListItem key={item.id} role={undefined} dense button>
                    <ListItemText id={labelId} primary={item.descricaoTabela} />
                    <ListItemSecondaryAction>
                      <Tooltip
                        placement="top"
                        title="Alterar esta configuração como padrão?"
                      >
                        <IconButton
                          className={classes.firstIcon}
                          onClick={() => handleAlternateDefault(item.id)}
                          edge="end"
                          disabled={item.isDefault}
                          aria-label="comments"
                        >
                          {item.isDefault ? (
                            <FiCheckCircle size={18} />
                          ) : (
                            <FiHexagon size={18} />
                          )}
                        </IconButton>
                      </Tooltip>
                      <Tooltip
                        placement="top"
                        title="Remover esta configuração"
                      >
                        <IconButton
                          onClick={() => handleDelete(item.id)}
                          edge="end"
                          aria-label="comments"
                        >
                          <FiTrash size={18} />
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                )
              })}
            </>
          ) : (
            <Typography variant="body1" color="textSecondary">
              Nenhuma configuração encontrada
            </Typography>
          )}
        </>
      )}
    </List>
  )
}
