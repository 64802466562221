import { useQueryParams } from '@elentari/core'
import { Box, Grid, Typography } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import Spacer from 'src/components/Spacer'
import ControleLogsFilter from './ControleLogsFilter'
import LogsList from './LogsList'
import logsStore from './store'
import { LogsQueryFilter } from './types'

function ControleLogs() {
  const { push, initialValues } = useQueryParams<LogsQueryFilter>()

  function handlePageChange(event: React.ChangeEvent<unknown>, page: number) {
    push({ ...initialValues, page: String(page) })
  }

  useEffect(() => {
    if (Object.keys(initialValues).length === 0) {
      return
    }

    logsStore.logs.setPage(Number(initialValues.page))
    logsStore.logs.setFilters(initialValues as Record<string, unknown>)
  }, [initialValues])

  return (
    <>
      <ControleLogsFilter />

      <Spacer y={4} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            Registros Realizados:
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Box
            padding={1}
            style={{
              overflowY: 'auto',
              border: '1px solid #e0e0e0',
              borderRadius: 4
            }}
          >
            <LogsList />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={5} display="flex" justifyContent="center">
            <Observer>
              {() => (
                <Pagination
                  count={Math.ceil(
                    logsStore.logs.totalCount / logsStore.logs.limit
                  )}
                  page={logsStore.logs.page}
                  onChange={handlePageChange}
                />
              )}
            </Observer>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default ControleLogs
