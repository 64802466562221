import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import {
  TipoNegociacao,
  tiposNegociacao
} from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import {
  TiposPedagio,
  tiposPedagio
} from 'src/components/Formik/Forms/Inputs/TipoPedagioSelectInput'
import { useContrato } from 'src/modules/contratos/hooks/useContrato'
import snackbarStore from 'src/stores/snackbar'
import { SimularFreteContratoForm } from '../types'

export const ContratoListener = () => {
  const formik = useFormikContext<SimularFreteContratoForm>()

  const contratoId = formik.values.contratoId
  const tabelaId = formik.values.tabelaId

  const [state, actions] = useContrato()
  const [fetched, setFetched] = useState(false)

  function formatTipoNegociacaoEmpresaValue(tipoNegociacao: TipoNegociacao) {
    return (
      tiposNegociacao.find(item => item.value === tipoNegociacao)?.label ||
      'N/A'
    )
  }

  function formatTipoPedagioValue(tipoPedagio: TiposPedagio | null) {
    if (!tipoPedagio) {
      return 'N/A'
    }

    return tiposPedagio.find(item => item.value === tipoPedagio)?.label || `N/A`
  }

  async function getContrato() {
    await actions.fetch(contratoId?.value)
    setFetched(current => !current)
  }

  useEffect(() => {
    if (contratoId?.value) {
      getContrato()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contratoId])

  useEffect(() => {
    if (state.tag === 'error') {
      snackbarStore.setMessage('deu erro')
    }

    if (state.tag === 'with-data') {
      const tabela = state.entity.Tabelas.find(
        tabela => tabela.id === formik.values.tabelaId?.value
      )

      formik.setValues({
        ...formik.values,
        codigo: state.entity.codigo,
        descricaoContrato: state.entity.descricao,
        grupoNegociador: state.entity.GrupoNegociador.nome,
        agrupadorProduto: state.entity.AgrupadorProdutos.nome,
        tipoNegociacao: tabela
          ? formatTipoNegociacaoEmpresaValue(tabela.tipoNegociacaoEmpresa)
          : '',
        tipoPedagio: tabela?.pedagio
          ? formatTipoPedagioValue(tabela.tipoPedagio)
          : '',
        frete: tabela ? (tabela.icms ? 'Sim' : 'Não') : '',
        adicionalEntrega: tabela
          ? tabela.adicionalEntrega
            ? 'Sim'
            : 'Não'
          : '',
        franquiaEntrega: tabela
          ? tabela.TabelaFrete.franquiaEntregas ?? 'N/A'
          : ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tag, fetched, tabelaId])

  return null
}
