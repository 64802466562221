import { Grid } from '@material-ui/core'
import { ConfigAccordion } from '../ConfigAccordion'
import { DadosConfigCentroCusto } from '../DadosConfigCentroCusto'
import { DadosCidade } from '../DadosConfigCidade'
import { DadosGrupoNegociador } from '../DadosConfigGrupoNegociador'
import { DadosLote } from '../DadosConfigLote'
import { DadosProduto } from '../DadosConfigProduto'

export const LoteConfig = () => {
  const loteKeys = [
    'codigo',
    'descricao',
    'operacaoKMM',
    'status',
    'numeroPedido',
    'freteEmpresa',
    'freteSubcontratadoPJ',
    'freteSubcontratadoPF',
    'quantidadeRetirada',
    'quantidadeTotal',
    'dataInicio',
    'dataTermino',
    'tipoContratacao',
    'especie',
    'emailCriador',
    'analistaResponsavel',
    'saldo',
    'previsaoMargem'
  ]

  const centroCustoKeys = [
    'centroCusto.codigo',
    'centroCusto.descricao',
    'centroCusto.unidadeNegocioDescricao'
  ]

  const grupoNegociadorKeys = [
    'grupoNegociador.codigo',
    'grupoNegociador.nome',
    'grupoNegociador.raizCnpj'
  ]

  const origemKeys = [
    'localCarregamento',
    'origem.nome',
    'origem.estadoNome',
    'origem.estadoSigla'
  ]

  const destinoKeys = [
    'destino.localDescarregamento',
    'destino.nome',
    'destino.estadoNome',
    'destino.estadoSigla'
  ]

  const produtoKeys = [
    'produto.codigo',
    'produto.nome',
    'produto.codigoNcm',
    'produto.unidade',
    'produto.tipoCarga',
    'produto.agrupadorProdutosNome'
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ConfigAccordion keys={loteKeys} title="Dados do Lote">
          <DadosLote />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion
          keys={centroCustoKeys}
          title="Dados do Centro de Custo"
        >
          <DadosConfigCentroCusto />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion
          keys={grupoNegociadorKeys}
          title="Dados do Grupo Negociador"
        >
          <DadosGrupoNegociador />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion keys={origemKeys} title="Dados da Origem do Lote">
          <DadosCidade
            prefix="origem"
            localName="config.localCarregamento"
            localLabel="Local de Carregamento"
          />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion keys={destinoKeys} title="Dados do Destino do Lote">
          <DadosCidade
            prefix="destino"
            localName="config.destino.localDescarregamento"
            localLabel="Local de Descarregamento"
          />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion keys={produtoKeys} title="Dados do Produto">
          <DadosProduto />
        </ConfigAccordion>
      </Grid>
    </Grid>
  )
}
