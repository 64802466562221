import logoG10 from 'src/images/g10-transportes.svg'

export const CondicoesGeraisContratoTransporte = () => {
  return (
    <div style={{ margin: '5px' }}>
      <div
        style={{
          border: '1px solid black',
          padding: '0 10px 0 10px'
        }}
      >
        <div
          style={{ display: 'flex', justifyContent: 'center', margin: '40px' }}
        >
          <img src={logoG10} style={{ maxHeight: '40%' }} alt="Logo G10" />
        </div>

        <p style={{ textAlign: 'center' }}>
          <u>
            <strong>
              CONDIÇÕES GERAIS DO CONTRATO DE PRESTAÇÃO DE SERVIÇO DE TRANSPORTE
            </strong>
          </u>
        </p>

        <p style={{ textAlign: 'justify' }}>
          <strong>CONTRATANTE:</strong>G10 TRANSPORTES S.A, pessoa jurídica de
          direito privado, devidamente inscrita no CNPJ sob nº
          07.569.161/0001-40, com endereço à Rodovia PR 317, nº 4652, CEP:
          87035-510, na cidade de Maringá-PR.
        </p>

        <p style={{ textAlign: 'justify' }}>
          <strong>CONTRATADO:</strong>Transportador, também qualificado e
          devidamente identificado no DACTE/DOCUMENTO DE FRETE acima
          referenciado, denominado simplesmente como SUBCONTRATADO, tem entre si
          justas e acordadas as seguintes condições gerais:
        </p>

        <p style={{ textAlign: 'justify' }}>
          1º. O SUBCONTRATADO e seus prepostos devem OBRIGATORIAMENTE parar nos
          postos fiscais das Secretarias de Fazenda dos Estados por onde
          transitar, para apresentação do Conhecimento de Transporte Eletrônico
          ¿ CT-e e da Nota Fiscal e MDFe (podendo ser Nota Fiscal Eletrônica ¿
          NF-e ou DACTE e MDFe) para ser fiscalizado e apor carimbo nos
          documentos fiscais fazendários.
        </p>

        <p style={{ textAlign: 'justify' }}>
          2º. O SUBCONTRATADO e seus prepostos se responsabilizam desde já, por
          todos e quaisquer danos e/ou prejuízos causados a mercadoria confiada
          e a terceiros, decorrentes de seus atos ou omissões, inclusive
          pagamento de multas, infrações e indenizações, isentando a CONTRATANTE
          de quaisquer obrigações dessa natureza.
        </p>

        <p style={{ textAlign: 'justify' }}>
          3º. O SUBCONTRATADO e seus prepostos devem OBRIGATORIAMENTE informar a
          CONTRATANTE acerca do término da prestação de serviço de transportes
          no prazo de até 3 dias para fins de quitação da obrigação contratual e
          para fins de baixas de MDFe.
        </p>

        <p style={{ textAlign: 'justify' }}>
          4º. O SUBCONTRATADO e seus prepostos, estão cientes e concordam com
          todas as condições para o transporte ajustada neste DOCUMENTO DE FRETE
          e no DACTE, inclusive no que se refere aos valores de: fretes,
          pedágio,{' '}
          <strong>
            forma de pagamento livremente escolhida pelo subcontratado
          </strong>
          , regras de tempo de carga e/ou descarga, tarifa de indenização de
          tempo de espera e a tolerância sobre quebra, e para tanto:
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          I - O SUBCONTRATADO e seus prepostos declaram que concordam, sem
          qualquer ressalva, com o valor do frete justo e contratado descrito no
          campo respectivo, a tarifa e total do frete acima, de forma que foi
          acordado por livre negociação entre as partes, RENUNCIANDO
          expressamente a qualquer valor adicional, diferença ou indenização
          prevista em Lei ou regulamentos de preço de frete.
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          II - O SUBCONTRATADO e seus prepostos declaram expressamente, na
          condição de motorista subcontratado e/ou de preposto de ETC ou TAC
          subcontratado(a), conforme qualificações melhores descritas no DACTE
          respectivo a este contrato, estar ciente, e concordar sem ressalvas,
          que,{' '}
          <strong>
            em razão de vantagens no valor da tarifa do frete contratado e
            acordado entre as partes, no valor do frete contratado/acordado no
            presente CONTRATO DE FRETE/DACTE,{' '}
            <u>
              está contemplada a indenização por um tempo de espera até a data
              do agendamento para carga e descarga
            </u>{' '}
            constantes do DACTE, documento de agendamento, agendamento no portal
            do destinatário, APP ou documento/informações equivalentes;
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          III - O SUBCONTRATADO e seus prepostos declaram que que todas as
          regras específicas de embarque, agendamento combinado, regras em caso
          de estadia (tarifa, datas de agendamento, tempos de tolerâncias, etc),
          regras de PGR e seguros,{' '}
          <strong>
            incluindo as rotas por ele escolhidas, com ou sem pedágio e
            respectiva forma de pagamento quando houver pedágio na rota
            escolhida pelo subcontratado
          </strong>
          , e demais termos da subcontratação foram devidamente explicados e
          acordados previamente a emissão da presente autorização de
          carregamento, e que concordou com todas as especificidades do
          transporte subcontratado, comprometendo-se a cumpri-las fiel e
          integralmente;
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          IV - Concordam que a responsabilidade pelo pagamento/indenização de
          diárias/estadias, decorrentes da demora no embarque e desembarque da
          mercadoria transportada nunca será da transportadora subcontratante,
          posto que esta não é responsável pelo manuseio, embarque, estiva,
          carga ou descarga da mercadoria, de modo que eventual cobrança de
          estadia deverá ser direcionada a quem efetivamente deu causa a espera,
          sendo
          <strong>
            vedado ao SUBCONTRATADO e seus prepostos condicionar o
            descarregamento ao recebimento das despesas/indenizações decorrentes
            da demora na carga ou descarga, sob pena de multa cominatória por
            dia de atraso pré-fixada em 10% do valor do frete contratado por
            dia.
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          V - Concordam com todas as regras de tempo de espera e de estadia
          expressamente previstas no campo de observações do respectivo DACTE,
          as quais declaram ciência e concordância prévia, renunciando ao valor
          e regras previstas no § 5º do artigo 11 da Lei 11.442/2007.
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          VI - O SUBCONTRATADO e seus prepostos declaram que acordaram e
          receberam o pagamento do pedágio (se devido para a rota escolhida pelo
          transportador), de forma destacada e antecipada, conforme destacado e
          detalhado no DOCUMENTO DE FRETE acima referido, de acordo com rota
          definida e informada pelo transportador. O SUBCONTRATADO e seus
          prepostos declaram estar cientes e concordarem que nos casos em que
          escolherem e indicarem rota onde não haja incidência de pedágios, não
          haverá este pagamento.
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          VII - O SUBCONTRATADO e seus prepostos declaram estar cientes e de
          acordo com os descontos legais, fiscais e previdenciários estipulados
          no DOCUMENTO DE FRETE acima detalhado,{' '}
          <strong>
            e declaram estar de acordo com os descontos de ad valorem, taxa
            administrativa nominada como ¿outros descontos¿ (quando aplicável à
            operação) e/ou seguro de cargas, que são referentes a fração/rateio
            do Gerenciamento de Risco e dos seguros de cargas previstos em Lei
            (RCTR-C + RCF-DC).
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          VIII -{' '}
          <strong>
            Considerando o dever de responsabilidade objetiva inerente à
            prestação de serviços de transportes, e considerando a possibilidade
            legal de compensação (artigo 368 do Código Civil), fica previamente
            autorizado pelo Subcontratado a compensação de quaisquer valores
            pagos a maior, do mesmo modo que, fica autorizado o desconto e/ou
            compensação de quebras, de avarias, e todo e qualquer desconto
            relacionado a falha na prestação de serviço de transporte, podendo o
            desconto e/ou compensação ser realizado na presente contratação ou
            em qualquer outra existente ou futura havia entre as partes,
            independentemente de qualquer assinatura ou autorização adicional.
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          IX - O SUBCONTRATADO e seus prepostos obrigam-se a cumprir as regras
          de PGR e rastreamento/monitoramento previamente estipuladas para cada
          tipo de mercadoria embarcada, e obrigam-se a fornecer espelhamento de
          rastreamento ou de utilização do GPS de celular para rastreio das
          mercadorias quando assim for exigido.
        </p>

        <p style={{ textAlign: 'justify' }}>
          {' '}
          X - O SUBCONTRATADO e seus prepostos declaram que não se aplica ao
          presente contrato regra de frete retorno, posto que informa que a
          contratação é de carga lotação apenas para uma origem e destino.
          Eventual frete no destino para qualquer localidade será livremente
          negociado pelo SUBCONTRATADO segundo as condições do mercado local.
        </p>

        <p style={{ textAlign: 'justify' }}>
          5º SUBCONTRATADO e seus prepostos reconhecem que se obrigam
          solidariamente a todas as condições previstas no presente contrato.
        </p>

        <p style={{ textAlign: 'justify' }}>
          6º As partes reconhecem expressamente que o presente contrato se trata
          de subcontratação regida pela Lei 11.442/2007.
        </p>

        <p style={{ textAlign: 'justify' }}>
          7º
          <strong>
            {' '}
            O subcontratado e seus prepostos concordam expressamente que
            qualquer indenização derivada do presente contrato fica limitada ao
            valor do frete contratado, na forma do artigo 15 da Lei 11.442/2007.
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          <strong>
            8º A mera aceitação da ordem de carregamento, e respectiva
            apresentação para carregamento local de embarque, importa na
            concordância e aceite das condições e obrigações acima previstas e
            com o carregamento do veículo, o contrato e condições passam a ser
            líquidas, certas e exigíveis.
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          <strong>
            9º O SUBCONTRATADO e seus prepostos declaram estar cientes e
            concordarem que qualquer reclamação relativa a pagamento de frete,
            estadias e demais reivindicações estarão sujeitas ao prazo
            decadencial de 30 dias, contados da entrega da mercadoria.
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          <strong>
            10º O SUBCONTRATADO e seus prepostos declaram expressamente que as
            formas de pagamento do frete, pedágio e demais acessórios derivados
            da contratação foram por eles{' '}
            <u>
              livremente escolhidas de acordo com sua melhor conveniência e em
              seu benefício
            </u>
            , declarando ainda que quando escolherem forma de pagamento
            equivalente a título de crédito nominal ao SUBCONTRATADO ou ordem de
            pagamento ao portador, tais títulos lhes são entregues no momento de
            quitação do adiantamento do frete, como condição de início da
            viagem, e o saldo do frete é pago na forma escolhida, mediante as
            condições estabelecidas em cada operação, sendo de{' '}
            <u>
              exclusiva e intransferível responsabilidade a posse, destinação ou
              endosso desses títulos
            </u>
            , de forma que uma vez realizada a quitação do adiantamento e do
            saldo{' '}
            <u>
              nos valores constantes nos campos específicos das vias
              ADIANTAMENTO e SALDO vinculadas ao presente contrato de frete
            </u>
            , com as respectivas compensações bancárias do título de crédito ou
            do pagamento da ordem de pagamento ao portador, as obrigações
            relativas ao frete, pedágios ou qualquer acessório incluído nessas
            ordens de pagamento, estarão quitadas,{' '}
            <u>independentemente de recibo ou assinatura complementares.</u>
          </strong>
        </p>

        <p style={{ textAlign: 'justify' }}>
          11º As partes elegem o foro da cidade de Maringá-PR para qualquer
          discussão acerca do contrato de transporte, seja de cunho principal ou
          acessório
        </p>

        <div
          style={{
            display: 'flex',
            justifyContent: 'end',
            margin: '10px 0 10px 0'
          }}
        >
          <img src={logoG10} style={{ maxHeight: '20%' }} alt="Logo G10" />
        </div>
      </div>
    </div>
  )
}
