import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'

class LogsStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  logs = new ApisauceDataStore(apiSauceInstance, {
    useNodes: true,
    path: '/logs-auditoria/delta',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}

const logsStore = new LogsStore()
export default logsStore
