import { useQueryParams } from '@elentari/core'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useContrato } from '../hooks/useContrato'
import { useTabela } from '../hooks/tabela/useTabela'
import { mapContratoFragmentReplace } from '../utils/tabs'
import { ContratoTabs } from './ContratoTabs'
import { ContratoView } from './ContratoView'

export function ContratosMasterDetail() {
  const [contratoState, contratoActions] = useContrato()
  const [tabelaState] = useTabela()
  const [, appBarActions] = useAppBar()

  const {
    initialValues: { view }
  } = useQueryParams<{ view: boolean }>()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      contratoState,
      u => u.entity.id,
      u => u.entity.descricao
    ),
    mapContratoFragmentReplace(tabelaState, 'tabelas'),
    mapFragmentReplace(
      tabelaState,
      u => u.entity.id,
      u => u.entity.descricao
    )
  ])

  useFetchAndReset(contratoActions.fetch, contratoActions.reset)
  return handleStateErrorsToRender(
    contratoState,
    view ? <ContratoView /> : <ContratoTabs />
  )
}
