import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { ModalidadesForm } from './ModalidadesForm'
import { useModalidade } from '../hooks/useModalidade'

export function ModalidadesMasterDetail() {
  const [modalidadesState, modalidadesActions] = useModalidade()
  const [, appBarActions] = useAppBar()
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      modalidadesState,
      u => u.entity.id,
      u => u.entity.nome
    )
  ])
  useFetchAndReset(modalidadesActions.fetch, modalidadesActions.reset)
  return handleStateErrorsToRender(modalidadesState, <ModalidadesForm />)
}
