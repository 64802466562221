import { createTheme } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

export default createTheme({
  typography: {
    fontFamily: 'Gilroy, Roboto, Helvetica, Arial, sans-serif'
  },
  palette: {
    primary: {
      main: '#632336'
    },
    secondary: {
      main: '#983A54'
    },
    white: '#ffffff',
    grey: grey,
    text: {
      primary: '#3A3A3A',
      secondary: '#676767'
    }
  },
  overrides: {
    MuiTableCell: {
      body: {
        wordBreak: 'inherit'
      }
    }
  }
})
