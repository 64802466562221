import { useCidade } from '../hooks/useCidade'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { CidadesForm } from './CidadesForm'

export function CidadeMasterDetail() {
  const [cidadeState, cidadeActions] = useCidade()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      cidadeState,
      c => c.entity.id,
      c => c.entity.nome
    )
  ])

  useFetchAndReset(cidadeActions.fetch, cidadeActions.reset)
  return handleStateErrorsToRender(cidadeState, <CidadesForm />)
}
