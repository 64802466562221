import { Button, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { AutorizacaoCarregamento } from '../components/autorizacao-carregamento/AutorizacaoCarregamento'
import { LotesSelecionadosTable } from '../components/selecao-lotes/LotesSelecionadosTable'
import { useProgramacao } from '../hooks/useProgramacao'
import selecaoLotesStore from '../store/selecao-lotes-store'
import ModalSelecaoLotes from '../components/selecao-lotes/ModalSelecaoLotes'
import { useFormikContext } from 'formik'
import { ProgramacaoFormData } from '../types/formData'

export const InformacoesDaProgramacao = () => {
  const [programacaoState] = useProgramacao()
  const formik = useFormikContext<ProgramacaoFormData>()

  const handleClickAdicionarLote = () => {
    selecaoLotesStore.setOldSelection(formik.values.lotes)
    selecaoLotesStore.openSelecaoLotesModal()
  }

  return (
    <Grid container>
      <Grid item container justifyContent="space-between" spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h5">Informações da Programação</Typography>
        </Grid>
        <Grid
          justifyContent="flex-end"
          spacing={2}
          item
          container
          xs={12}
          md={6}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              style={{ borderRadius: 50, marginTop: 5 }}
              onClick={handleClickAdicionarLote}
              disabled={programacaoState.tag === 'with-data'}
            >
              Adicionar Lote
            </Button>
          </Grid>
          <Grid item>
            <AutorizacaoCarregamento
              lotes={Object.values(formik.values.lotes)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <LotesSelecionadosTable />
      </Grid>

      <ModalSelecaoLotes />
    </Grid>
  )
}
