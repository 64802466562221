import { makeAutoObservable } from 'mobx'
import { TabelaIcms } from 'src/modules/tabela-icms/types'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import {
  ModalidadeTransportador,
  ProgramacaoDetail,
  ProgramacaoList
} from '../types'
import { ProgramacaoFormData } from '../types/formData'

export interface YupContext {
  modalidadeTransportador?: ModalidadeTransportador
}

class ProgramacoesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  fetchingComposicao = false
  setFetchingComposicao(fetchingComposicao: boolean) {
    this.fetchingComposicao = fetchingComposicao
  }

  composicaoImportada = false
  setComposicaoImportada(composicaoImportada: boolean) {
    this.composicaoImportada = composicaoImportada
  }

  programacoes = new ApisauceDataStore<ProgramacaoList>(apiSauceInstance, {
    useNodes: true,
    path: '/programacoes',
    resultsField: 'edges',
    countField: 'totalCount'
  })

  getProgramacao(id: string) {
    return apiSauceInstance.get<ProgramacaoDetail>(`/programacoes/${id}`)
  }

  yupContext: YupContext = {}

  setYupContext(newContext: YupContext) {
    this.yupContext = {
      ...this.yupContext,
      ...newContext
    }
  }

  resetYupContext() {
    this.yupContext = {}
  }

  _hasToReevaluateResumoValores = false

  setHasToReevaluateResumoValores(hasReevaluatedResumoValores: boolean) {
    this._hasToReevaluateResumoValores = hasReevaluatedResumoValores
  }

  resetHasToReevaluateResumoValores() {
    this._hasToReevaluateResumoValores = true
  }

  hasToReevaluateResumoValores(formData: ProgramacaoFormData) {
    const lotes = Object.values(formData.lotes)
    const hasTrocaNotaFiscal = lotes.some(lote => lote.possuiTrocaNotaFiscal)

    return hasTrocaNotaFiscal && this._hasToReevaluateResumoValores
  }

  getIcms(estadoOrigem: string, estadoDestino: string) {
    return apiSauceInstance.get<TabelaIcms>('tabela-icms', {
      estadoOrigem,
      estadoDestino
    })
  }
}

const programacoesStore = new ProgramacoesStore()

export default programacoesStore
