import { observer } from 'mobx-react-lite'
import { Redirect, Route } from 'react-router-dom'
import userStore from 'src/stores/user'

interface PrivateRouteProps {
  loginPath: string
  path: string
  component?: any
  render?: any
}

const PrivateRoute = observer(
  ({
    component: Component,
    render,
    loginPath = '/login',
    ...props
  }: PrivateRouteProps) => {
    return userStore.data ? (
      <Route
        {...props}
        render={props => {
          return userStore.data ? (
            Component ? (
              <Component />
            ) : (
              render(props)
            )
          ) : (
            <Redirect to={loginPath} />
          )
        }}
      />
    ) : null
  }
)

export default PrivateRoute
