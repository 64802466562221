import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import MaskInput from 'src/components/Formik/Forms/MaskInput'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { EntitySaver } from 'src/services/entitySaver'
import snackbarStore from 'src/stores/snackbar'
import * as yup from 'yup'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { estadosRepository } from '../hooks/estadosRepository'
import { useEstado } from '../hooks/useEstado'
import { EstadosDetail } from '../types'

const optionsSchema = yup
  .object()
  .nullable()
  .shape({
    name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

const schema = yup.object().shape({
  nome: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  codigoIBGE: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  sigla: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  paisId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  nome: '',
  codigoIBGE: '',
  sigla: '',
  paisId: null
}

export const EstadosForm = () => {
  const [estadoState] = useEstado()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const canSave = useCanSave(estadoState, 'Estados')

  const handleSubmit = async (estado: any) => {
    setLoading(true)

    await EntitySaver(
      () =>
        estadosRepository.save({
          id: estado.id,
          nome: estado.nome,
          codigoIBGE: estado.codigoIBGE,
          sigla: estado.sigla,
          paisId: estado.paisId.value
        }) as any,
      {
        onSuccess: () => handleGoBack(),
        feedbackError: 'Erro ao salvar a estado'
      }
    )
    setLoading(false)
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        estadoState.tag === 'with-data' ? estadoState.entity : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextInput name="nome" label="Nome" />
              </Grid>
              <Grid item xs={12} md={3}>
                <MaskInput mask="99" name="codigoIBGE" label="Código IBGE" />
              </Grid>
              <Grid item xs={12} md={3}>
                <MaskInput mask="aa" name="sigla" label="Sigla" />
              </Grid>
              <Grid item xs={12} md={3}>
                <AsyncSearchInput
                  name="paisId"
                  label="País"
                  url="/pais"
                  labelField="nome"
                />
              </Grid>
            </Grid>

            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
