import DateFnsUtils from '@date-io/date-fns'
import useSnackbar from '@elentari/core/hooks/useSnackbar'
import { ThemeProvider } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ptBr from 'date-fns/locale/pt-BR'
import { observer } from 'mobx-react-lite'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { withScollBar } from './App.styles'
import Snackbar from './components/Snackbar'
import './font.css'
import AutorizacaoCarregamento from './modules/autorizacao-carregamento/AutorizacaoCarregamento'
import HomeNavigation from './modules/home/HomeNavigation'
import { Callback, Login, PrivateRoute } from './modules/login'
import AuthListener from './modules/login/AuthListener'
import snackbarStore from './stores/snackbar'
import theme from './Theme'
import { CondicoesGeraisContratoTransporte } from './modules/home/components/CondicoesGeraisContratoTransporte'

const App = () => {
  const [snackbarState, snackbaractions] = useSnackbar()

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider locale={ptBr} utils={DateFnsUtils}>
        <Router>
          <Switch>
            <Route path="/login" component={Login} />
            <Route
              path="/autorizacao-carregamento-validacao"
              component={AutorizacaoCarregamento}
            />
            <Route
              path="/cgct"
              render={() => <CondicoesGeraisContratoTransporte />}
            />
            <Route path="/callback" component={Callback} />
            <AuthListener>
              <PrivateRoute
                loginPath="/login"
                path="/"
                render={() => <HomeNavigation />}
              />
            </AuthListener>
          </Switch>
        </Router>
        <Snackbar
          message={snackbarState.message}
          onClose={() => snackbaractions.setMessage('')}
          classes={{}}
        />
        <NewSnackBar />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default withScollBar(App)

const NewSnackBar = observer(() => {
  return (
    <Snackbar
      message={snackbarStore.message}
      onClose={(event, reason) =>
        reason !== 'clickaway' && snackbarStore.setMessage('')
      }
      classes={{}}
    />
  )
})
