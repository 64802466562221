import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import { useCalcularResumoValores } from '../../hooks/useCalcularResumoValores'
import programacoesStore from '../../store/store'
import {
  LoteProgramacaoFormData,
  ProgramacaoFormData
} from '../../types/formData'

interface Props {
  lote: LoteProgramacaoFormData
}

export const ResumoValores = observer(({ lote }: Props) => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const { loading, calcularResumoValores } = useCalcularResumoValores()

  const onClickCalcular = () => {
    calcularResumoValores(lote).then(() =>
      programacoesStore.setHasToReevaluateResumoValores(false)
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10}>
        <Typography variant="h6">Resumo de Valores da Programação</Typography>
      </Grid>
      <Grid item xs={12} sm={2} style={{ width: 160 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Button
              fullWidth
              type="button"
              variant="outlined"
              color="primary"
              onClick={onClickCalcular}
              disabled={loading}
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'Calcular'
              )}
            </Button>
          </Grid>
          {programacoesStore.hasToReevaluateResumoValores(formik.values) && (
            <Typography variant="caption" color="error">
              É necessário calcular os valores
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.freteEmpresa`}
          label="Frete Empresa"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.freteAgregado`}
          label="Frete Agregado"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.freteSubcontratadoPj`}
          label="Frete Subcontratado PJ"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.freteSubcontratadoPf`}
          label="Frete Subcontratado PF"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.margemGrupo`}
          label="Margem Grupo"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.freteGrupo`}
          label="Frete Grupo"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.valorPedagio`}
          label="Valor do Pedágio"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name={`lotes.${lote.id}.resumoValores.aliquotaIcmsReal`}
          label="Alíquota ICMS Real"
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          disabled
        />
      </Grid>
    </Grid>
  )
})
