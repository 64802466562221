import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { TipoRelatorioInterno, tipoRelatorioInternoOptions } from '../types'
import { Grid, Typography } from '@material-ui/core'
import relatorioInternoStore from '../stores'
import { useFormikContext } from 'formik'

export const TipoRelatorioInput = () => {
  const formik = useFormikContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Tipo de Relatório</Typography>
      </Grid>

      <Grid item xs={3}>
        <SelectInput
          name="tipoRelatorio"
          label="Tipo de Relatório"
          options={tipoRelatorioInternoOptions}
          onChange={(e: { target: { value: TipoRelatorioInterno } }) => {
            formik.resetForm()
            relatorioInternoStore.setSelected(e.target.value)
          }}
        />
      </Grid>
    </Grid>
  )
}
