import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import NoResultsTable from 'src/components/NoResultsTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import Spacer from '../../components/Spacer'
import { LocaisColumns } from './LocaisColumns'
import locaisStore from './store/locaisStore'
import { LocalDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const LocaisTable = observer(({ onDelete, authorization }: Props) => {
  const { pathname } = useLocation()

  const KEY_TABLE = resourceTabela.LOCAIS
  useConfigTable(KEY_TABLE)
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !locaisStore.locais.isLoading &&
    tableState &&
    locaisStore.locais.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => LocaisColumns({ onDelete, authorization }),
    [onDelete, authorization]
  )

  const rows = locaisStore.locais.list.map(({ ...local }: LocalDetail) => {
    return {
      ...local,
      local: `${local?.Cidades.nome} / ${local?.Estados?.sigla} `
    }
  })

  const sortMapper = new Map([['local', 'Cidades.nome']])

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={locaisStore.locais.isLoading}
          totalCount={locaisStore.locais.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow={false}
              linkWithRedirectClickRow={pathname}
              data={rows}
              sortMapper={sortMapper}
            />
          ) : (
            <NoResultsTable loading={locaisStore.locais.isLoading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={pathname} authorization={authorization} />
    </>
  )
})
