import {
  FormHelperText,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { useFormikContext } from 'formik'
import { Fragment } from 'react'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import Spacer from 'src/components/Spacer'
import { TipoContratacao } from 'src/modules/lotes/types'
import { formatProdutoLote } from 'src/utils/formatters/formatProdutoLote'
import { formatSaldoLote } from 'src/utils/formatters/formatSaldoLote'
import { formatValorLote } from 'src/utils/formatters/formatValorLote'
import { TableContainer } from '../../../../components'
import NoResultsTable from '../../../../components/NoResultsTable'
import { TableCellHead } from '../../../../components/TableCellHead'
import { formatDate } from '../../../../utils/formatters'
import { useProgramacao } from '../../hooks/useProgramacao'
import { useSelecaoLotes } from '../../hooks/useSelecaoLotes'
import {
  LoteProgramacaoFormData,
  ProgramacaoFormData
} from '../../types/formData'
import { getPreviousQuantidadePrevistaForLote } from '../../util/getPreviousQuantidadePrevistaForLote'

export const LotesSelecionadosTable = () => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const { handleRemoveLote } = useSelecaoLotes()
  const [programacaoState] = useProgramacao()

  const countSelecionados = Object.keys(formik.values.lotes).length
  const hasLotesSelecionados = countSelecionados > 0
  const isTipoContratacaoContrato =
    formik.values.tipoContratacaoLotesSelecionados === TipoContratacao.CONTRATO

  const getQuantidadePrevistaForLote = (lote: LoteProgramacaoFormData) => {
    if (programacaoState.tag === 'with-data') {
      if (lote.tipoLote === 'VIAGEM') {
        return 0
      }

      const previousQuantidadePrevista = getPreviousQuantidadePrevistaForLote(
        programacaoState.entity,
        lote.id
      )

      return lote.quantidadePrevista - previousQuantidadePrevista
    }

    if (lote.tipoLote === 'VIAGEM') {
      return 1
    }

    return lote.quantidadePrevista
  }

  return (
    <TableContainer loading={false} totalCount={countSelecionados}>
      {hasLotesSelecionados ? (
        <Table>
          <TableHead>
            <TableCellHead label="" />
            <TableCellHead label="Código" />
            <TableCellHead label="Centro de Custo" />
            <TableCellHead label="Data Inicial de Retirada" />
            <TableCellHead label="Data Limite de Retirada" />
            <TableCellHead label="Grupo Negociador" />
            <TableCellHead label="Produto" />
            <TableCellHead label="Origem" />
            <TableCellHead label="Destino" />
            <TableCellHead label="Frete PJ" />
            <TableCellHead label="Frete PF" />
            <TableCellHead label="Quantidade Prevista" />
            <TableCellHead label="Valor Frete Acordado" />
          </TableHead>
          <TableBody>
            {Object.values(formik.values.lotes).map(lote => (
              <Fragment key={lote.id}>
                <TableRow>
                  <TableCell>
                    <IconButton
                      disabled={
                        programacaoState.tag === 'with-data' &&
                        countSelecionados === 1
                      }
                      onClick={() => handleRemoveLote(lote.id)}
                    >
                      <CloseOutlinedIcon />
                    </IconButton>
                  </TableCell>

                  <TableCell>{lote.codigo}</TableCell>
                  <TableCell>{lote.CentroCusto.descricao}</TableCell>
                  <TableCell>{formatDate(new Date(lote.dataInicio))}</TableCell>
                  <TableCell>
                    {formatDate(new Date(lote.dataTermino))}
                  </TableCell>
                  <TableCell>{lote.Cliente.nome}</TableCell>
                  <TableCell>{formatProdutoLote(lote.Produto)}</TableCell>
                  <TableCell>{`${lote.Origem.cidade} / ${lote.Origem.siglaEstado}`}</TableCell>
                  <TableCell>
                    {lote.Destino.length > 1
                      ? 'Diversos'
                      : lote.Destino[0]
                      ? `${lote.Destino[0].cidade} / ${lote.Destino[0].siglaEstado}`
                      : 'Não informado'}
                  </TableCell>
                  <TableCell>
                    {formatValorLote(lote.freteSubcontratadoPJ)}
                  </TableCell>
                  <TableCell>
                    {formatValorLote(lote.freteSubcontratadoPF)}
                  </TableCell>
                  <TableCell style={{ minWidth: '210px' }}>
                    <NumericInput
                      precision={0}
                      name={`lotes.${lote.id}.quantidadePrevista`}
                      label="Quantidade Prevista"
                      disabled={isTipoContratacaoContrato}
                    />
                    <Spacer y={1} />
                    Saldo:{' '}
                    {formatSaldoLote(lote, {
                      quantidadePrevista: getQuantidadePrevistaForLote(lote)
                    })}
                  </TableCell>
                  <TableCell style={{ minWidth: '210px' }}>
                    <NumericInput
                      thousandChar="."
                      decimalChar=","
                      variant="outlined"
                      name={`lotes.${lote.id}.valorFreteAcordado`}
                      label="Valor Frete Acordado"
                      disabled={
                        programacaoState.tag === 'with-data' ||
                        isTipoContratacaoContrato
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        )
                      }}
                    />
                    <Spacer y={3.5} />
                  </TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      ) : (
        <>
          <NoResultsTable loading={false} />
          {formik.errors.quantidadeLotes && !!formik.touched.lotes ? (
            <FormHelperText error>
              {formik.errors.quantidadeLotes}
            </FormHelperText>
          ) : null}
        </>
      )}
    </TableContainer>
  )
}
