import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { CentroCustoDetail } from '../types'

class CentrosCustoStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  centrosCusto = new ApisauceDataStore<CentroCustoDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/centros-custo',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
export const centrosCustoStore = new CentrosCustoStore()

export default centrosCustoStore
