import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { isSameDay } from 'date-fns'
import { useEffect, useState } from 'react'
import { CustomFilter, Filter } from 'src/components/CustomFilter'
import { formatDate } from 'src/utils/formatters'
import { formatStatusProgramacao } from 'src/utils/formatters/formatStatus'
import { formatValorLote } from 'src/utils/formatters/formatValorLote'
import { PrevisaoMargem } from '../components/PrevisaoMargem'
import { LoteToProgramacao } from '../types'

interface Props {
  viagens: LoteToProgramacao[]
}

interface Viagem {
  centroCusto: string
  embarcador: string
  dataHoraCarregamento: Date
  dataHoraDescarregamento: Date
  quantidadeLote?: number
  status: string
  placaPrincipal: string
  valorFreteAcordado: number
  previsaoMargem: number
  previsaoMargemColor: 'RED' | 'BLUE'
  freteEmpresa: number
}

const ViagensList = ({ viagens }: Props) => {
  const [listViagens, setListViagens] = useState<Viagem[]>([])
  const [filterQuery, setFilterQuery] = useState<Filter[]>([
    {
      name: 'dataHoraCarregamento',
      title: 'Data Carregamento',
      type: 'date',
      value: null
    },
    {
      name: 'dataHoraDescarregamento',
      title: 'Data Descarregamento',
      type: 'date',
      value: null
    },
    {
      name: 'centroCusto',
      title: 'Centro de Custo',
      type: 'string',
      value: null
    },
    {
      name: 'embarcador',
      title: 'Embarcador',
      type: 'string',
      value: null
    }
  ])

  const mapViagens = (): Viagem[] => {
    const mapViagens = new Map<string, Viagem>()
    viagens.forEach(viagem => {
      const quantidade =
        (mapViagens.get(viagem.programacaoId)?.quantidadeLote || 0) +
        viagem.quantidadePrevista

      mapViagens.set(viagem.programacaoId, {
        ...viagem.Programacao,
        centroCusto: viagem.Programacao.CentroCusto.descricao,
        valorFreteAcordado: viagem.valorFreteAcordado,
        quantidadeLote: quantidade,
        previsaoMargem: viagem.previsaoMargem,
        previsaoMargemColor: viagem.previsaoMargemColor,
        freteEmpresa: viagem.freteEmpresa
      })
    })

    return [...mapViagens.values()]
  }

  const filterViagens = (filter: Filter[]): Viagem[] => {
    let filteredViagens: Viagem[] = mapViagens()

    const dataCarregamento = filter.find(
      filterItem => filterItem.name === 'dataHoraCarregamento'
    )?.value

    if (dataCarregamento) {
      filteredViagens = filteredViagens.filter(viagem =>
        isSameDay(
          new Date(dataCarregamento),
          new Date(viagem.dataHoraCarregamento)
        )
      )
    }

    const dataDescarregamento = filter.find(
      filterItem => filterItem.name === 'dataHoraDescarregamento'
    )?.value
    if (dataDescarregamento) {
      filteredViagens = filteredViagens.filter(viagem =>
        isSameDay(
          new Date(dataDescarregamento),
          new Date(viagem.dataHoraDescarregamento)
        )
      )
    }

    const centroCusto = filter.find(
      filterItem => filterItem.name === 'centroCusto'
    )?.value
    if (centroCusto) {
      filteredViagens = filteredViagens.filter(viagem =>
        viagem.centroCusto
          .toUpperCase()
          .includes(centroCusto.toString().trim().toUpperCase())
      )
    }

    const embarcador = filter.find(
      filterItem => filterItem.name === 'embarcador'
    )?.value
    if (embarcador) {
      filteredViagens = filteredViagens.filter(viagem =>
        viagem.embarcador
          .toUpperCase()
          .includes(embarcador.toString().trim().toUpperCase())
      )
    }

    return filteredViagens
  }

  const handleFilterSubmit = (filter: Filter[]) => {
    setFilterQuery(filter)
    setListViagens(filterViagens(filter))
  }

  const handleRemoveFilter = (name: string) => {
    setFilterQuery(
      filterQuery.map(item => {
        if (item.name === name) {
          delete item.value
        }
        return item
      })
    )
    setListViagens(filterViagens(filterQuery))
  }

  const handleResetViagens = () => {
    setListViagens(mapViagens())
    setFilterQuery([
      {
        name: 'dataHoraCarregamento',
        title: 'Data Carregamento',
        type: 'date',
        value: null
      },
      {
        name: 'dataHoraDescarregamento',
        title: 'Data Descarregamento',
        type: 'date',
        value: null
      },
      {
        name: 'centroCusto',
        title: 'Centro de Custo',
        type: 'string',
        value: null
      },
      {
        name: 'embarcador',
        title: 'Embarcador',
        type: 'string',
        value: null
      }
    ])
  }

  useEffect(() => {
    setListViagens(mapViagens())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viagens])

  return (
    <Box display="flex" flexDirection="column" padding={1}>
      <Box display="flex" style={{ marginBottom: 16 }}>
        <Typography variant="h6" style={{ width: 550 }}>
          Lista de viagens programadas
        </Typography>
        <CustomFilter
          filters={filterQuery}
          onSubmit={handleFilterSubmit}
          onRemove={handleRemoveFilter}
          onReset={handleResetViagens}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Status</b>
              </TableCell>
              <TableCell>
                <b>Centro de Custo</b>
              </TableCell>
              <TableCell>
                <b>Embarcador</b>
              </TableCell>
              <TableCell>
                <b>Placa</b>
              </TableCell>
              <TableCell>
                <b>Quantidade</b>
              </TableCell>
              <TableCell>
                <b>Data Carregamento</b>
              </TableCell>
              <TableCell>
                <b>Data Descarregamento</b>
              </TableCell>
              <TableCell>
                <b>Previsão de Margem</b>
              </TableCell>
              <TableCell>
                <b>Frete Empresa</b>
              </TableCell>
              <TableCell>
                <b>Valor Acordado</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listViagens?.map((row, index) => (
              <TableRow key={index}>
                <TableCell component="th" scope="row">
                  {formatStatusProgramacao(row.status)}
                </TableCell>
                <TableCell>{row.centroCusto}</TableCell>
                <TableCell>{row.embarcador}</TableCell>
                <TableCell>{row.placaPrincipal}</TableCell>
                <TableCell align="center">{row.quantidadeLote}</TableCell>
                <TableCell align="center">
                  {formatDate(row.dataHoraCarregamento)}
                </TableCell>
                <TableCell align="center">
                  {formatDate(row.dataHoraDescarregamento)}
                </TableCell>
                <TableCell align="center">
                  <PrevisaoMargem
                    previsaoMargem={row.previsaoMargem}
                    color={row.previsaoMargemColor}
                  />
                </TableCell>
                <TableCell align="center">
                  {formatValorLote(row.freteEmpresa)}
                </TableCell>
                <TableCell align="center">
                  {formatValorLote(row.valorFreteAcordado)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default ViagensList
