import { Ability } from '@casl/ability'
import { makeAutoObservable } from 'mobx'
import { TokenData, UserProfile } from 'src/modules/login/types'
import { apiSauceInstance } from 'src/services/api'
import { AuthAbilities } from 'src/services/types'
import config from '../config'
import snackbarStore from './snackbar'

class UserStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  data: UserProfile | null = null
  setUser(user: UserProfile | null) {
    this.data = user
  }

  ability: Ability | null = null
  setAbility(ability: Ability | null) {
    this.ability = ability
  }

  getUsername() {
    return this.data?.username ?? ''
  }

  getTokens() {
    const accessToken = localStorage.getItem(config.localStorage.accessToken)
    const refreshToken = localStorage.getItem(config.localStorage.refreshToken)

    return { accessToken, refreshToken }
  }

  setTokens(accessToken: string, refreshToken: string) {
    localStorage.setItem(config.localStorage.accessToken, accessToken)
    localStorage.setItem(config.localStorage.refreshToken, refreshToken)
  }

  removeTokens() {
    localStorage.removeItem(config.localStorage.accessToken)
    localStorage.removeItem(config.localStorage.refreshToken)
  }

  async getAndValidateTokens() {
    const tokens = this.getTokens()

    if (!tokens.refreshToken) {
      let redirectRoute: string | undefined = window.location.pathname
      redirectRoute = redirectRoute !== '/' ? redirectRoute : undefined
      this.logout(redirectRoute)
      return
    }

    if (!tokens.accessToken) {
      return await this.refreshToken(tokens.refreshToken)
    }

    return this.verifyToken(tokens.accessToken, tokens.refreshToken)
  }

  async refreshToken(refreshToken: string) {
    const response = await apiSauceInstance.post<TokenData>('/auth/refresh', {
      refreshToken
    })

    if (response.ok && response.data) {
      this.setTokens(response.data.accessToken, response.data.refreshToken)

      await this.verifyToken(
        response.data.accessToken,
        response.data.refreshToken
      )

      return this.getTokens()
    } else {
      this.removeTokens()
      window.location.href = '/login'
    }
  }

  async verifyToken(accessToken: string, refreshToken: string) {
    const response = await apiSauceInstance.post<UserProfile>(
      '/auth/verify-token',
      {
        token: accessToken,
        clientId: config.clientId
      }
    )

    if (!response.ok) {
      return this.refreshToken(refreshToken)
    }

    this.setUser(response.data!)
  }

  async login(
    accessToken: string,
    refreshToken: string,
    redirectRoute?: string
  ) {
    this.setTokens(accessToken, refreshToken)

    window.location.href = redirectRoute || '/'
  }

  async checkAbilities() {
    try {
      if (this.ability) {
        return
      }

      const username = this.data?.username
      const roles = this.data?.rolesInSystem

      if (!username || !roles || !roles.length) {
        return
      }

      const isAdmin = (roles || []).some(role => role.toUpperCase() === 'ADMIN')

      if (isAdmin) {
        this.setAbility(new Ability([{ action: 'manage', subject: 'all' }]))
        return
      }

      const response = await apiSauceInstance.get<AuthAbilities[]>(
        `/auth/${username}/abilities`
      )

      if (!response.ok) {
        return this.setAbility(null)
      }

      if (response.data) {
        const abilities = [
          ...response.data,
          // injetando Localidades na mão, pois é um grupo virtual
          { inverted: false, action: 'manage', subject: 'Localidades' }
        ]

        this.setAbility(new Ability(abilities))
      }
    } catch (err) {
      this.setAbility(null)
    }
  }

  async logout(redirectRoute?: string) {
    try {
      const tokens = this.getTokens()

      if (tokens.refreshToken) {
        await apiSauceInstance.post('/auth/logout', {
          accessToken: tokens.accessToken || undefined,
          refreshToken: tokens.refreshToken || undefined
        })
      }

      this.setUser(null)
      this.setAbility(null)
      this.removeTokens()

      window.location.href = `/login${
        redirectRoute ? `?redirect_route=${redirectRoute}` : ''
      }`
    } catch (error) {
      snackbarStore.setMessage('Ocorreu um erro ao sair. Tente novamente.')
    }
  }

  async getNewTokens() {
    try {
      const response = await apiSauceInstance.get<TokenData>(
        '/auth/get-new-tokens'
      )
      if (response.ok && response.data) {
        const search = window.location.search
        const params = new URLSearchParams(search)
        const redirectRoute = params.get('redirect_route')

        this.setTokens(response.data.accessToken, response.data.refreshToken)

        window.location.href = redirectRoute || '/'
      }
    } catch (error) {
      this.removeTokens()
    }
  }

  can(action: string, subject: string) {
    if (!this.ability) {
      return false
    }

    return this.ability.can(action, subject)
  }
}

const userStore = new UserStore()
export default userStore
