import { apiSauceInstance } from '../../../services/api'
import { EstadosDetail } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapSortParams = (sort: any) => {
  const { pais, ...rest } = sort || {}

  return pais ? { Pais: { nome: pais }, ...rest } : { ...rest }
}

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort
      ? mapSortParams(sort)
      : {
          nome: 'asc'
        }
  }
}

export const estadosRepository = makeRepository<
  EdgesPage<EstadosDetail>,
  EstadosDetail,
  EstadosDetail,
  string
>({ resource: 'estados', mapQueryParams }, apiSauceInstance)
