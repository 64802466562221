import { makeStyles, Typography } from '@material-ui/core'
import { LotesDetail } from '../types'

const useStyle = makeStyles(theme => ({
  imageRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    background: '#4a001b',
    borderRadius: '10px',
    width: '500px',
    height: '800px'
  },
  headerImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '50px',
    background: '#fff',
    padding: '20px',
    borderRadius: '10px'
  },
  bodyImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginTop: '50px',
    padding: '20px',
    borderRadius: '10px'
  },
  textPrimary: {
    color: '#000',
    textAlign: 'center'
  },
  textSecondary: {
    color: '#fff',
    textAlign: 'center'
  },
  separator: {
    margin: 40
  },
  bannerImage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    background: '#fff',
    padding: '0 20px',
    borderRadius: '10px'
  },
  imageFooter: {
    position: 'relative',
    left: '30%',
    top: '10%',
    zIndex: 1
  },
  imageBackground: {
    position: 'relative',
    right: '25%',
    top: '5%',
    zIndex: 0
  },
  imgBackground: {
    opacity: 0.05
  }
}))

interface ImageProps {
  lote: LotesDetail
}

export const ImageToExport = ({ lote }: ImageProps) => {
  const classes = useStyle()

  const formatStringMoreLength = (value: string, length: number) => {
    return value.length > length ? value.substring(0, length) : value
  }

  const formatData = {
    ...lote,
    origemNome: `${lote.Origem.nome}-${lote.Origem.Estado.sigla}`,
    localCarregamentoNome:
      lote.localCarregamento &&
      formatStringMoreLength(lote.localCarregamento, 20),
    freteDivulgacao: Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(lote.freteSubcontratadoPJ)
  }

  return (
    <div className={classes.imageRoot}>
      <div className={classes.headerImage}>
        <Typography className={classes.textPrimary} variant="h4">
          Embarque Liberado!
        </Typography>
      </div>
      <div className={classes.bodyImage}>
        <Typography className={classes.textSecondary} variant="h4">
          {formatData.origemNome}
        </Typography>

        {formatData.localCarregamentoNome && (
          <Typography className={classes.textSecondary} variant="h5">
            "{formatData.localCarregamentoNome}"
          </Typography>
        )}

        <div className={classes.separator}>
          <Typography className={classes.textSecondary} variant="h5">
            X
          </Typography>
        </div>

        <Typography className={classes.textSecondary} variant="h4">
          {lote.DestinoLote.length > 1
            ? 'Diversos locais'
            : `${lote.DestinoLote[0].Cidade.nome} - ${lote.DestinoLote[0].Cidade.Estado.sigla}`}
        </Typography>

        <div className={classes.bannerImage}>
          <Typography className={classes.textPrimary} variant="h6">
            {lote.Produto.nome}
          </Typography>
        </div>
        <div className={classes.bannerImage}>
          <Typography className={classes.textPrimary} variant="h6">
            {formatData.freteDivulgacao}
          </Typography>
        </div>
        <div className={classes.imageFooter}>
          <img width={180} src="/g10-logo-white.png" alt="G10 Logo" />
        </div>
        <div className={classes.imageBackground}>
          <img
            className={classes.imgBackground}
            width={340}
            src="/g10-logo-white.png"
            alt="G10 Logo"
          />
        </div>
      </div>
    </div>
  )
}
