import { Button, Grid, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext
} from 'formik'
import { Fragment } from 'react'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import SwitchInput from 'src/components/SwitchInput'
import {
  LoteProgramacaoFormData,
  PernaLoteProgramacaoFormData,
  ProgramacaoFormData
} from '../../types/formData'
import { AliquotaIcmsListener } from '../listeners/AliquotaIcmsListener'
import { FreteGrupoDiferenteListener } from '../listeners/FreteGrupoDiferenteListener'
import { MultiplasEntregasListener } from '../listeners/MultiplasEntregasListener'
import { PossuiTrocaDeNotaFiscalListener } from '../listeners/PossuiTrocaDeNotaFiscalListener'
import { QuantidadePrevistaListener } from '../listeners/QuantidadePrevistaListener'
import { ValorFreteAcordadoListener } from '../listeners/ValorFreteAcordadoListener'
import { ColetaEntrega } from './ColetaEntrega'
import { ResumoValores } from './ResumoValores'

interface Props {
  lote: LoteProgramacaoFormData
}

export const InformacoesColetaEntregaLote = ({ lote }: Props) => {
  const pernasFieldName = `lotes.${lote.id}.pernas`
  const formik = useFormikContext<ProgramacaoFormData>()
  const [field] = useField<PernaLoteProgramacaoFormData[]>(pernasFieldName)

  const addPerna = (arrayHelpers: FieldArrayRenderProps) => {
    field.value[field.value.length - 1].destinos[0].quantidade = 0

    arrayHelpers.push({
      localCarregamento: '',
      estadoOrigem: '' as any,
      cidadeOrigem: '' as any,
      destinos: [
        {
          localEntrega: '',
          estadoDestino: '' as any,
          cidadeDestino: '' as any,
          quantidade: '' as any
        }
      ],
      freteEmpresa: '' as any,
      valorPedagio: '' as any,
      aliquotaIcms: '' as any,
      freteGrupoDiferente: false,
      freteGrupo: '' as any,
      freteSubcontratado: '' as any
    })
  }

  const removePerna = (arrayHelpers: FieldArrayRenderProps, index: number) => {
    arrayHelpers.remove(index)
  }

  const hasAlreadyMultiplasEntregas = formik.values.lotes[lote.id].pernas.some(
    perna => perna.destinos.length > 1
  )
  const possuiTrocaNotaFiscal =
    formik.values.lotes[lote.id].possuiTrocaNotaFiscal

  return (
    <Grid container spacing={2}>
      <QuantidadePrevistaListener lote={lote} />
      <ValorFreteAcordadoListener lote={lote} />
      <MultiplasEntregasListener loteId={lote.id} />
      <PossuiTrocaDeNotaFiscalListener loteId={lote.id} />

      <Grid item xs={12}>
        <Typography variant="h5">Lote {lote.codigo}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">Informações de Coleta e Entrega</Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <ConditionalTooltip
          enabled={!lote.permiteTrocarNota}
          title={
            <Typography variant="caption">
              Configuração do lote não permite troca de nota fiscal
            </Typography>
          }
        >
          <ConditionalTooltip
            enabled={possuiTrocaNotaFiscal && hasAlreadyMultiplasEntregas}
            title={
              <Typography variant="caption">
                Não é possível desabilitar a troca de nota fiscal, há mais de um
                destino na última perna
              </Typography>
            }
          >
            <ConditionalTooltip
              enabled={!possuiTrocaNotaFiscal && hasAlreadyMultiplasEntregas}
              title={
                <Typography variant="caption">
                  Não é possível habilitar a troca de nota fiscal, há mais de um
                  destino na primeira entrega
                </Typography>
              }
            >
              <SwitchInput
                name={`lotes.${lote.id}.possuiTrocaNotaFiscal`}
                label="Possui Troca de Nota Fiscal"
                disabled={
                  !lote.permiteTrocarNota || hasAlreadyMultiplasEntregas
                }
              />
            </ConditionalTooltip>
          </ConditionalTooltip>
        </ConditionalTooltip>
      </Grid>
      <Grid item xs={12} md={6}>
        <SwitchInput
          name={`lotes.${lote.id}.multiplasEntregas`}
          label="Múltiplas Entregas"
        />
      </Grid>
      <Grid item xs={12}>
        <FieldArray
          name={pernasFieldName}
          render={arrayHelpers => (
            <Grid container spacing={2}>
              {field.value.map((_, index) => (
                <Fragment key={index}>
                  <FreteGrupoDiferenteListener
                    loteId={lote.id}
                    pernaIndex={index}
                  />
                  <AliquotaIcmsListener loteId={lote.id} pernaIndex={index} />

                  <Grid item xs={12}>
                    <ColetaEntrega
                      namePrefix={`${pernasFieldName}.${index}`}
                      pernaIndex={
                        formik.values.lotes[lote.id].possuiTrocaNotaFiscal
                          ? index
                          : null
                      }
                      multiplasEntregas={
                        formik.values.lotes[lote.id].multiplasEntregas
                      }
                      isLastPerna={field.value.length === index + 1}
                      tabelaId={lote.tabelaId}
                      loteId={lote.id}
                    />
                  </Grid>
                  {formik.values.lotes[lote.id].possuiTrocaNotaFiscal &&
                  field.value.length > 1 ? (
                    <Grid container item xs={12} justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={() => removePerna(arrayHelpers, index)}
                        startIcon={<DeleteForeverIcon />}
                      >
                        Remover {index + 1}ª Perna
                      </Button>
                    </Grid>
                  ) : null}
                </Fragment>
              ))}

              {formik.values.lotes[lote.id].possuiTrocaNotaFiscal ? (
                <>
                  <Grid item xs={3}>
                    <ConditionalTooltip
                      enabled={
                        possuiTrocaNotaFiscal && hasAlreadyMultiplasEntregas
                      }
                      title={
                        <Typography variant="caption">
                          Não é possível adicionar mais pernas, há mais de um
                          destino na atual última perna
                        </Typography>
                      }
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        type="button"
                        onClick={() => addPerna(arrayHelpers)}
                        startIcon={<AddIcon />}
                        disabled={
                          possuiTrocaNotaFiscal && hasAlreadyMultiplasEntregas
                        }
                      >
                        Adicionar Perna
                      </Button>
                    </ConditionalTooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <ResumoValores lote={lote} />
                  </Grid>
                </>
              ) : null}
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  )
}
