import { Grid, Typography } from '@material-ui/core'
import { ColetaEntregaProps } from './types'

interface Props extends Pick<ColetaEntregaProps, 'pernaIndex'> {
  children: React.ReactElement
}

export const PernaTypography = ({ pernaIndex, children }: Props) => {
  return pernaIndex !== null ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6" color="primary">
          {pernaIndex + 1}ª Perna
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  ) : (
    children
  )
}
