import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import programacoesStore from '../../store/store'
import { ModalidadeTransportador } from '../../types'
import { ProgramacaoFormData } from '../../types/formData'

const modalidadesToClearTipoNegociacao = [
  ModalidadeTransportador.FROTA,
  ModalidadeTransportador.GRUPO
]

export const ModalidadeTransportadorListener = () => {
  const formik = useFormikContext<ProgramacaoFormData>()

  useEffect(() => {
    const hasToClearTipoNegociacao = modalidadesToClearTipoNegociacao.includes(
      formik.values.modalidadeTransportador
    )

    if (hasToClearTipoNegociacao) {
      formik.setFieldValue('tipoNegociacaoSubcontratado', '')
    }

    programacoesStore.setYupContext({
      modalidadeTransportador: formik.values.modalidadeTransportador
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.modalidadeTransportador])

  return null
}
