import snackbarStore from 'src/stores/snackbar'
import { LotesFormData } from '../types'

export function checkFrete(values: LotesFormData) {
  return () => {
    if ((values.freteAgregado ?? 0) > values.freteEmpresa) {
      snackbarStore.setMessage('Frete Agregado está maior que o Frete Empresa')
    }
  }
}

export function checkFreteGrupo(values: LotesFormData) {
  return () => {
    if ((values.freteGrupo ?? 0) > values.freteEmpresa) {
      snackbarStore.setMessage('Frete Grupo está maior que o Frete Empresa')
    }
  }
}

export function checkFretePF(values: LotesFormData) {
  return () => {
    if ((values.freteSubcontratadoPF ?? 0) > values.freteEmpresa) {
      snackbarStore.setMessage(
        'Frete Subcontratado PF está maior que o Frete Empresa'
      )
    }
  }
}

export function checkFretePJ(values: LotesFormData) {
  return () => {
    if ((values.freteSubcontratadoPJ ?? 0) > values.freteEmpresa) {
      snackbarStore.setMessage(
        'Frete Subcontratado PJ está maior que o Frete Empresa'
      )
    }
  }
}
