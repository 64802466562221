import { useQueryParams, useSnackbar } from '@elentari/core'
import { stringify } from 'qs'
import { useEffect } from 'react'
import { useHistory } from 'react-router'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { pessoasRepository } from './hooks/pessoasRepository'
import PessoasFilter from './PessoasFilter'
import PessoasTable from './PessoasTable'
import pessoasStore from './store/store'
import { defaultTipo, PessoasQueryFilter } from './types'

const Pessoas = () => {
  const ability = useAbility()
  const { initialValues } = useQueryParams<PessoasQueryFilter>()
  const history = useHistory()

  useEffect(() => {
    if (!initialValues.tipo) {
      history.replace(
        `${history.location.pathname}?${stringify({
          tipo: defaultTipo
        })}`
      )
    } else {
      pessoasStore.pessoas.setPage(Number(initialValues.page) || 1)
      pessoasStore.pessoas.setFilters(initialValues)
    }
  }, [initialValues]) // eslint-disable-line

  async function handleDelete(id: string) {
    const response = await pessoasRepository.delete(id)
    pessoasStore.pessoas.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  const authorization = getAuthorizations(ability, 'Pessoas')

  return (
    <>
      <PessoasFilter />
      <PessoasTable onDelete={handleDelete} authorization={authorization} />
    </>
  )
}

export default Pessoas
