const locales: ApexLocale[] = [
  {
    name: 'pt',
    options: {
      toolbar: {
        download: 'Baixar',
        exportToCSV: 'Exportar para CSV',
        exportToPNG: 'Exportar para PNG',
        exportToSVG: 'Exportar para SVG'
      }
    }
  }
]

export default locales
