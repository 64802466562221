import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { useLocal } from '../hooks/useLocal'
import { LocalForm } from './LocalForm'

export function LocalMasterDetail() {
  const [localState, localActions] = useLocal()
  const [, appBarActions] = useAppBar()
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      localState,
      u => u.entity.id,
      u => u.entity.descricao
    )
  ])
  useFetchAndReset(localActions.fetch, localActions.reset)
  return handleStateErrorsToRender(localState, <LocalForm />)
}
