import { Box } from '@material-ui/core'
import { StatusLoteEnum, statusLote } from '../types'
import StatusBadge from '../StatusBadge'

export const statusRenderTags = (options: typeof statusLote | string[]) =>
  options.map((option: any) => (
    <Box margin="1px">
      <StatusBadge
        status={
          Object.keys(StatusLoteEnum).includes(option as string)
            ? (option as StatusLoteEnum)
            : (option.value as StatusLoteEnum)
        }
        marginLeft="0%"
      />
    </Box>
  ))
