import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { UnidadeNegocioDetail } from '../types'

class UnidadeNegocioStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  unidadeNegocio = new ApisauceDataStore<UnidadeNegocioDetail>(
    apiSauceInstance,
    {
      useNodes: true,
      path: '/unidades-negocio',
      resultsField: 'edges',
      countField: 'totalCount'
    }
  )
}

export const unidadeNegocioStore = new UnidadeNegocioStore()
export default UnidadeNegocioStore
