import Filter from '@elentari/components-eve/components/Filter'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import Grid from '@material-ui/core/Grid'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import { statusCentroCusto } from './types'

const schema = yup.object().shape({})

export type CentroCustoQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

export const CentroCustoFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<CentroCustoQueryFilter>()
  const handleSubmitFilter = (
    values: Record<string, any> & { status?: Record<string, any>; page: number }
  ) => {
    if (!values.status) {
      return push({
        page: 1,
        codigo: values.codigo,
        descricao: values.descricao,
        unidadeNegocioId: values.unidadeNegocioId?.value
      })
    }

    push({
      page: 1,
      codigo: values.codigo,
      descricao: values.descricao,
      unidadeNegocioId: values.unidadeNegocioId?.value,
      status: values.status
    })
  }

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={initialValues}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField fullWidth name="codigo" label="Código" type="number" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth name="descricao" label="Descrição" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RFFAsyncSearchInput
            name="unidadeNegocioId"
            label="Unidade de Negócio"
            url="/unidades-negocio"
            labelField="descricao"
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <Select
            fullWidth
            name="status"
            label="Status"
            items={statusCentroCusto}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
