export const MessagesYup = {
  MENSAGEM_OBRIGATORIO: 'O campo em específico é obrigatório',
  MENSAGEM_CAMPO_STRING:
    'O campo em específico não aceita esses parâmetros em específico.',
  MENSAGEM_TIPO_NUMERICO: 'O campo em específico precisa ser numérico',
  MENSAGEM_NUMERO_INVALIDO: 'Número inválido',
  MENSAGEM_VALOR_MINIMO: 'O valor mínimo para este campo não pode ser negativo',
  MENSAGEM_VALOR_NEGATIVO: 'O valor mínimo não pode ser negativo',
  MENSAGEM_EMAIL: 'Formato inválido de e-mail',
  MENSAGEM_ERRO: 'O campo em específico é uma string, algo deu errado.',
  MENSAGEM_DATA_INVALIDA: 'Data inválida.',
  MENSAGEM_PADRAO_INVALIDO: 'Padrão inválido!',
  MENSAGEM_DATA_INICIO_INVALIDA:
    'A data de início não pode ser maior que a data final.',
  MENSAGEM_DATA_FUTURA_FIELD: (field: string) =>
    `Você não pode cadastrar uma data futura à ${field}.`,
  MENSAGEM_DATA_FUTURA_NECESSARIA: (field: string) =>
    `Você deve cadastrar uma data futura à ${field}`,
  MENSAGEM_DATA_FUTURA: 'Você não pode cadastrar uma data futura.',
  MENSAGEM_DATA_PASSADA: 'Você não pode cadastrar uma data passada.',
  MENSAGEM_VALOR_ZERO:
    'O valor mínimo para este campo deve ser maior que zero.',
  MENSAGEM_LIMTADA_ATE: 'O campo em específico contém limite de caracteres.',
  MENSAGEM_VALOR_MAXIMO_EXERCICIO:
    'O valor máximo para este campo deve ser menor que 9999.',
  MENSAGEM_VALOR_MINIMO_EXERCICIO:
    'O valor mínimo para este campo deve ser maior que 1900.',
  MESSAGEM_VALOR_CELULAR_MENOR:
    'O valor mínimo para este campo deve ser maior que os 11 dígitos',
  MESSAGEM_VALOR_CPF_INVALIDO: 'O CPF não possui um valor válido.',
  MENSAGEM_CPF_LIMTADA:
    'O campo CPF em específico contém limite de 11 caracteres.',
  MENSAGEM_CEP: 'O campo CEP é obrigatório',
  INVALID_DATE: 'Data inválida!',
  INVALID_RG: 'RG inválido!',
  MIN_PASS: 'A senha deve conter, no mínimo, 6 caracteres!',
  CPF_MIN: 'O CPF deve conter 11 caracteres!',
  INVALID_CNPJ: 'CNPJ inválido!',
  CNPJ_MIN: 'O CNPJ deve conter 14 caracteres!',
  TEL_MIN: 'O Telefone deve conter, no mínimo 10 dígitos',
  TEL_MAX: 'O Telefone deve conter, no máximo 11 dígitos',
  CEP_MIN: 'O CEP deve conter 9 caracteres!',
  RG_MIN: 'O RG deve conter 9 caracteres!',
  LOGIN_ERROR: 'Usuário ou senha inválidos.',
  PLACA_MIN: 'A placa deve conter no mínimo 7 dígitos',
  MENSAGEM_ALIQUOTA_MAX: 'Valor máximo para alíquota é 100%',
  LATITUDE_ERROR: 'A latitude deve esta entre -90 até 90',
  LONGITUDE_ERROR: 'A longitude deve esta entre -180 até 180',
  MENSAGEM_CAMPO_XLXS:
    'O campo em específico deve conter um arquivo com a extensão .xlxs'
}
