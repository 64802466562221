import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import TipoNegociacaoEmpresaSelectInput from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { EntitySaver } from 'src/services/entitySaver'
import * as yup from 'yup'
import { Paper } from '../../../components'
import DateInput from '../../../components/DateInput'
import FieldsPending from '../../../components/FieldsPending'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import NumericInput from '../../../components/Formik/Forms/NumericInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { negociacoesNaoFechadasRepository } from '../hooks/negociacoesNaoFechadasRepository'
import { useNegociacaoNaoFechada } from '../hooks/useNegociacaoNaoFechada'
import { NegociacaoNaoFechadaDetail } from '../types'
import { isEmpty, isNil } from 'ramda'

const optionsSchema = yup
  .object()
  .nullable()
  .shape({
    name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

const estadoSchema = yup
  .object()
  .nullable()
  .test(
    'estadoOrigemDestino',
    MessagesYup.MENSAGEM_OBRIGATORIO,
    val => !!val?.value
  )

const schema = yup.object().shape({
  centroCustoId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  cliente: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  produto: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  estadoOrigemId: estadoSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  estadoDestinoId: estadoSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  origemId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  destinoId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoNegociacao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  freteCotado: yup
    .number()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataNegociacao: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  freteVencedor: yup
    .number()
    .optional()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr)),
  observacao: yup.string().optional().nullable(),
  freteMotoristaConcorrente: yup
    .number()
    .optional()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
})

const initialValues = {
  cliente: '',
  centroCustoId: '',
  estadoOrigemId: { name: '', value: '' },
  estadoDestinoId: { name: '', value: '' },
  produto: '',
  origemId: '',
  destinoId: '',
  tipoNegociacao: '',
  freteCotado: null,
  dataNegociacao: null,
  freteVencedor: null,
  freteMotoristaConcorrente: null,
  observacao: ''
}

export const NegociacoesNaoFechadasForm = () => {
  const [loading, setLoading] = useState(false)
  const [negociacaoNaoFechadaState] = useNegociacaoNaoFechada()
  const history = useHistory()
  const canSave = useCanSave(
    negociacaoNaoFechadaState,
    'Negociações não fechadas'
  )

  const handleGoBack = () => {
    history.goBack()
  }

  const handleSubmit = async (data: any) => {
    const dataTratado = {
      id: data.id,
      centroCustoId: data.centroCustoId.value,
      cliente: data.cliente,
      produto: data.produto,
      origemId: data.origemId.value,
      destinoId: data.destinoId.value,
      tipoNegociacao: data.tipoNegociacao,
      freteCotado: data.freteCotado,
      dataNegociacao: data.dataNegociacao,
      observacao: data.observacao,
      freteVencedor:
        !isNil(data.freteVencedor) && data.freteVencedor !== ''
          ? data.freteVencedor
          : null,
      freteMotoristaConcorrente:
        !isNil(data.freteMotoristaConcorrente) &&
        data.freteMotoristaConcorrente !== ''
          ? data.freteMotoristaConcorrente
          : null
    }

    await EntitySaver(
      () => negociacoesNaoFechadasRepository.save(dataTratado) as any,
      {
        onSuccess: () => handleGoBack(),
        feedbackError: 'Erro ao salvar a negociacao não fechada'
      }
    )

    setLoading(true)
  }

  const formatData = (values: NegociacaoNaoFechadaDetail) => {
    return {
      ...values,
      freteCotado: Number(values.freteCotado),
      freteVencedor: !isNil(values.freteVencedor)
        ? Number(values.freteVencedor)
        : null,
      freteMotoristaConcorrente: !isNil(values.freteMotoristaConcorrente)
        ? Number(values.freteMotoristaConcorrente)
        : null,
      centroCustoId: {
        name: values.CentroCusto.descricao ?? '',
        value: values.centroCustoId
      },
      origemId: {
        name: values.Origem.nome ?? '',
        value: values.origemId
      },
      destinoId: {
        name: values.Destino.nome ?? '',
        value: values.destinoId
      },
      estadoOrigemId: {
        name: values.Origem.Estado.nome ?? '',
        value: values.Origem.Estado.id ?? ''
      },
      estadoDestinoId: {
        name: values.Destino.Estado.nome ?? '',
        value: values.Destino.Estado.id ?? ''
      }
    }
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        negociacaoNaoFechadaState.tag !== 'with-data'
          ? initialValues
          : formatData(negociacaoNaoFechadaState.entity)
      }
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={2}>
                <AsyncSearchInput
                  name="centroCustoId"
                  label="Centro de Custo"
                  url="/centros-custo/ativos"
                  labelField="descricao"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput name="cliente" label="Cliente" />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextInput name="produto" label="Produto" />
              </Grid>
              <Grid item xs={12} md={2}>
                <AsyncSearchInput
                  name="estadoOrigemId"
                  label="Estado Origem"
                  url="/estados/minimal"
                  labelField="nome"
                  joinStateAndUf
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AsyncSearchInput
                  name="origemId"
                  label="Cidade Origem"
                  url={`estados/${values.estadoOrigemId?.value}/cidades`}
                  labelField="nome"
                  disabled={!values.estadoOrigemId?.name}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <AsyncSearchInput
                  name="estadoDestinoId"
                  label="Estado Destino"
                  url="/estados/minimal"
                  labelField="nome"
                  joinStateAndUf
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <AsyncSearchInput
                  name="destinoId"
                  label="Cidade Destino"
                  url={`estados/${values.estadoDestinoId?.value}/cidades`}
                  labelField="nome"
                  disabled={!values.estadoDestinoId?.name}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TipoNegociacaoEmpresaSelectInput
                  name="tipoNegociacao"
                  label="Tipo"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <NumericInput
                  name="freteCotado"
                  label="Frete Cotado"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <NumericInput
                  name="freteVencedor"
                  label="Frete Vencedor"
                  InputProps={{
                    startAdornment: !isEmpty(values.freteVencedor) && (
                      <InputAdornment position="start">R$</InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <NumericInput
                  name="freteMotoristaConcorrente"
                  label="Frete Motorista Concorrente"
                  InputProps={{
                    startAdornment: !isEmpty(
                      values.freteMotoristaConcorrente
                    ) && <InputAdornment position="start">R$</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <DateInput label="Data Negociação" name="dataNegociacao" />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextInput name="observacao" label="Observação" />
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12} md={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
