import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { CidadesDetail } from '../types'

class CidadesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  cidades = new ApisauceDataStore<CidadesDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/cidades',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
export const cidadesStore = new CidadesStore()
export default CidadesStore
