import { apiSauceInstance } from '../../../services/api'
// import { NegociacoesPerdidasDetail } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'
import {
  NegociacaoNaoFechada,
  NegociacaoNaoFechadaDetail,
  NegociacaoNaoFechadaForm
} from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      dataNegociacao: 'asc'
    }
  }
}

export const negociacoesNaoFechadasRepository = makeRepository<
  EdgesPage<NegociacaoNaoFechada>,
  NegociacaoNaoFechadaForm,
  NegociacaoNaoFechadaDetail,
  string
>({ resource: 'negociacoes-nao-fechadas', mapQueryParams }, apiSauceInstance)
