import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { LoteList } from '../types'

class LotesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  icms: Nullable<boolean> = null

  setIcmsLote(icms: boolean) {
    this.icms = icms
  }

  lotes = new ApisauceDataStore<LoteList>(apiSauceInstance, {
    useNodes: true,
    path: '/lotes',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
export const lotesStore = new LotesStore()
export default LotesStore
