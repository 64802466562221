import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export default function FreteEmpresaListener() {
  const formik = useFormikContext<{ freteEmpresa: number }>()

  async function setFreteFrota() {
    const calc = 0.97 * formik.values.freteEmpresa
    calc && formik.setFieldValue('freteFrota', calc)
  }

  useEffect(() => {
    if (formik.values.freteEmpresa) {
      setFreteFrota()
    }
  }, [formik.values.freteEmpresa]) // eslint-disable-line

  return null
}
