import { IconButton, Tooltip } from '@material-ui/core'
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined'
import { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import {
  DatePickerFilter,
  DefaultColumnFilter,
  NumericFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import { EditarStatusRecord } from 'src/components/EditarStatusRecord'
import When from 'src/components/When'
import { CancelRecord } from '../../components'
import { DivulgarLote } from './components/DivulgarLote'
import { PrevisaoMargem } from './components/PrevisaoMargem'
import StatusBadge from './StatusBadge'
import {
  especiesOptions,
  LotesDetail,
  statusLote,
  tiposContratacao
} from './types'

interface ILotesColumnsProps {
  onCancel: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const LotesColumns = ({
  onCancel,
  authorization
}: ILotesColumnsProps): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo',
      Filter: props =>
        DefaultColumnFilter({
          column: props.column as HeaderGroup<{}>,
          onlyNumber: true
        })
    },
    {
      Header: 'Descrição',
      accessor: 'descricao'
    },
    { Header: 'Centro de Custo', accessor: 'centroCusto' },
    { Header: 'Operação KMM', accessor: 'operacaoKMM' },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: props =>
        props.value !== null ? (
          <StatusBadge status={props.value} marginLeft="30%" />
        ) : (
          <></>
        ),
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: statusLote
        })
    },
    { Header: 'Cliente', accessor: 'cliente' },
    { Header: 'Origem', accessor: 'origem' },
    { Header: 'Destino', accessor: 'destino', sort: false },
    { Header: 'Produto', accessor: 'produto' },
    {
      Header: 'Previsão de Margem',
      accessor: 'previsaoMargem',
      sort: false,
      search: false,
      Cell: props => {
        const row: any = props.row.original
        return (
          <PrevisaoMargem
            previsaoMargem={props.value}
            color={row?.previsaoMargemColor}
          />
        )
      }
    },
    { Header: 'Número do Pedido', accessor: 'numeroPedido', show: false },
    {
      Header: 'Frete Empresa',
      accessor: 'freteEmpresa',
      Filter: props =>
        NumericFilter({ column: props.column as HeaderGroup<{}> }),
      show: false
    },
    {
      Header: 'Frete Pessoa Jurídica',
      accessor: 'freteSubcontratadoPJ',
      Filter: props =>
        NumericFilter({ column: props.column as HeaderGroup<{}> }),
      show: false
    },
    {
      Header: 'Frete Pessoa Física',
      accessor: 'freteSubcontratadoPF',
      Filter: props =>
        NumericFilter({ column: props.column as HeaderGroup<{}> }),
      show: false
    },
    {
      Header: 'Quantidade Retirada',
      accessor: 'quantidadeRetirada',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup<{}>,
          precision: 0
        }),
      show: false
    },
    {
      Header: 'Quantidade Total',
      accessor: 'quantidadeTotal',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup<{}>,
          precision: 0
        }),
      show: false
    },
    {
      Header: 'Data Inicial de Retirada',
      accessor: 'dataCarregamento',
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Data Limite de Retirada',
      accessor: 'dataDescarregamento',
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Local de Carregamento',
      accessor: 'localCarregamento',
      show: false
    },
    {
      Header: 'Tipo de Contratação',
      accessor: 'tipoContratacao',
      show: false,
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: tiposContratacao
        })
    },
    {
      Header: 'Espécie',
      accessor: 'especie',
      show: false,
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: especiesOptions
        })
    },
    {
      Header: 'Criador',
      accessor: 'emailCriador',
      show: false
    },
    {
      Header: 'Responsável',
      accessor: 'analistaResponsavel',
      show: false
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: props => {
        const original = props.row.original as LotesDetail

        return (
          <div style={{ display: 'flex' }}>
            {original && (
              <Fragment>
                <DivulgarLote
                  loteId={original.id}
                  canDivulgarLote={original.canDivulgarLote}
                />
                <When value={authorization?.canUpdate} equals>
                  <EditarStatusRecord
                    isStatusChangeable={
                      original.isStatusChangeable &&
                      original.nextPossibleStatus.length > 0
                    }
                    status={original.status}
                    id={original.id}
                    optionsStatus={original.nextPossibleStatus}
                  />
                </When>
                <Tooltip
                  title="Consultar registro"
                  aria-label="Consultar registro"
                >
                  <IconButton
                    component={Link}
                    to={`lotes/${original.id}?view=true`}
                  >
                    <VisibilityOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <When value={authorization?.canUpdate} equals>
                  <CancelRecord
                    tooltipTitle="Cancelar lote"
                    disabled={!original.isCancellable}
                    onCancel={() => onCancel(original.id)}
                  />
                </When>
              </Fragment>
            )}
          </div>
        )
      }
    }
  ]
}
