import { Chip, makeStyles } from '@material-ui/core'
import { statusColors } from '../types/tabelaTypes'

interface SetBadgeColorProps {
  status: 'ATIVO' | 'INATIVO'
}

const useStyles = makeStyles(theme => ({
  customBadge: (props: SetBadgeColorProps) => {
    return {
      backgroundColor: statusColors[props.status],
      width: 96,
      color: theme.palette.getContrastText(statusColors[props.status])
    }
  }
}))

function TabelaContratoStatusBadge(props: SetBadgeColorProps) {
  const classes = useStyles(props)

  return (
    <Chip
      label={props.status}
      size="small"
      variant="default"
      className={classes.customBadge}
    />
  )
}

export default TabelaContratoStatusBadge
