import { Button, FormHelperText, Grid, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext
} from 'formik'
import EstadosAsyncSearchInput from '../modules/lotes/components/EstadosAsyncSearchInput'
import { LotesFormData } from '../modules/lotes/types'
import { PureAsyncSearchInput } from './Formik/Forms/PureAsyncSearchInput'
import TextInput from './Formik/Forms/TextInput'

interface DestinoInputProps {
  name: string
  hasMultipleDestinos?: boolean
}

export default function DestinoInput({ name }: DestinoInputProps) {
  const formik = useFormikContext<LotesFormData>()
  const [field, meta] = useField({ name })
  const hasDestino = !!field.value?.length

  function addDestino(arrayHelpers: FieldArrayRenderProps) {
    return () => {
      arrayHelpers.push({
        estadoDestinoId: '',
        destinoId: '',
        localEntrega: ''
      })
    }
  }

  function deleteDestino(index: number, arrayHelpers: FieldArrayRenderProps) {
    return () => {
      const destino = field.value[index]

      if (destino.id) {
        arrayHelpers.form.setFieldValue('deletedDestinos', [
          ...(arrayHelpers.form.values?.deletedDestinos || []),
          destino.id
        ])
      }

      arrayHelpers.remove(index)
    }
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {hasDestino &&
            field.value.map((destino: any, index: number) => {
              return (
                <Grid xs={12} item key={index}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={3}>
                      <TextInput
                        name={`${name}.${index}.localEntrega`}
                        label="Local de Entrega (opcional)"
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <EstadosAsyncSearchInput
                        name={`${name}.${index}.estadoDestinoId`}
                        label="Estado Destino"
                        url="/estados/minimal"
                        paramSearch="nome"
                        handleOnChange={() => {
                          formik.setFieldValue(
                            `${name}.${index}.destinoId`,
                            null
                          )
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={field.value.length > 1 ? 11 : 12}
                      sm={field.value.length > 1 ? 5 : 6}
                    >
                      <PureAsyncSearchInput
                        name={`${name}.${index}.destinoId`}
                        label="Cidade Destino"
                        url={`/estados/${destino.estadoDestinoId?.value}/cidades`}
                        labelField="nome"
                        paramSearch="nome"
                        disabled={!destino.estadoDestinoId?.value}
                      />
                    </Grid>
                    {field.value.length > 1 && (
                      <Grid item container xs={1} justifyContent="center">
                        <IconButton
                          onClick={deleteDestino(index, arrayHelpers)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )
            })}

          <Grid xs={12} item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={addDestino(arrayHelpers)}
              data-cy="add-lote"
            >
              Adicionar Destino
            </Button>

            <FormHelperText error>
              {!field.value?.length && meta.error}
            </FormHelperText>
          </Grid>
        </Grid>
      )}
    />
  )
}
