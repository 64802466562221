import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import * as dateFns from 'date-fns'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import locaisStore from './store/locaisStore'

type LocaisQueryFilter = {
  [key: string]: any
}

const schema = yup.object().shape({})

export const LocaisFilter = () => {
  const { clear, push, initialValues } = useQueryParams<LocaisQueryFilter>()

  const handleSubmitFilter = (values: Record<string, any>) => {
    push({
      ...values,
      cidadeId: values.cidadeId?.value
    })
  }

  const format = (initialValues: LocaisQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
  }

  const handleClear = () => {
    locaisStore.locais.resetList()
    clear()
  }

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={handleClear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth type="number" name="codigo" label="Código" />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField fullWidth type="text" name="descricao" label="Descrição" />
        </Grid>

        <Grid item xs={12} sm={4}>
          <RFFAsyncSearchInput
            name="cidadeId"
            label="Cidade / Estado"
            url="/cidades"
            labelField="nome"
            joinStateAndCity
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
