import { InputBase } from '@material-ui/core'
import { GridRenderEditCellParams } from '@mui/x-data-grid'

interface Props extends GridRenderEditCellParams {
  validator?: (value: string) => boolean
}

export function CustomEditComponent(props: Props) {
  const { id, field, api } = props

  const handleNewValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (props.validator && !props.validator(value)) {
      return
    }

    api.setEditCellValue({ id, field, value })
    api.commitCellChange({ id, field })
  }

  return (
    <InputBase
      onChange={handleNewValueChange}
      value={props.value}
      inputProps={{ style: { margin: 3 } }}
      autoFocus
    />
  )
}
