import { apiSauceInstance } from '../../../services/api'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ProdutoDetail, ProdutoSave } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, sort, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || { codigo: 'asc' },
    limit: limit || '10'
  }
}

export const produtosRepository = makeRepository<
  EdgesPage<ProdutoDetail>,
  ProdutoSave,
  ProdutoDetail,
  string
>({ resource: 'produtos', mapQueryParams }, apiSauceInstance)
