import { makeAutoObservable } from 'mobx'

class SnackbarStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }
  message = ''

  setMessage(message: string) {
    this.message = message
  }

  get open() {
    return !!this.message
  }
}

const snackbarStore = new SnackbarStore()

export default snackbarStore
