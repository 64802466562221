import { ChangeEvent, useState } from 'react'
import CurrencyField, {
  CurrencyFieldProps
} from 'src/components/Formik/Forms/Inputs/CurrencyField'
import tabelaIcmsStore from '../store'

interface InputProps extends CurrencyFieldProps {
  aliquota: number
  id: string
}

function IcmsInput(props: InputProps) {
  const [value, setValue] = useState(props.aliquota)

  const handleChange = (e: ChangeEvent<any>) => {
    const { value } = e.target
    setValue(Number(value))

    tabelaIcmsStore.addSaveArray(props.id, Number(value))
  }

  return (
    <CurrencyField
      {...props}
      name={props.id}
      style={{ width: '3.5rem' }}
      value={value}
      onChange={e => handleChange(e)}
      fullWidth
      variant="standard"
      color="primary"
    />
  )
}

export default IcmsInput
