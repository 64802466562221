import { apiSauceInstance } from '../../../services/api'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { Local, LocalDetail, AlterarStatusData, StatusLocal } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, ...rest } = params

  return {
    ...paginationParams(rest),
    limit: limit || '10'
  }
}

export const locaisRepository = makeRepository<
  EdgesPage<LocalDetail>,
  Local,
  LocalDetail,
  string
>({ resource: 'locais', mapQueryParams }, apiSauceInstance)

export const alterarStatus = async (id: string, data: AlterarStatusData) => {
  return apiSauceInstance.patch<
    { status: StatusLocal },
    { message: string; error: any }
  >(`/locais/${id}/alterar-status/`, data)
}
