import { Button, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import DateInput from 'src/components/Formik/Forms/DateInput'
import { mapIdsSelectOptions } from 'src/utils/mapIdsSelectOptions'
import * as yup from 'yup'
import AsyncSearchInput from '../../../../components/Formik/Forms/AsyncSearchInput'
import useDashboardStyles from '../dashboard.styles'
import AsyncMultipleSelect from '../filters/AsyncMultipleSelect'
import dashboardStore from './store'

const schema = yup.object().shape({
  dataInicial: yup.date().typeError('Data inválida').nullable(),
  dataFinal: yup.date().typeError('Data inválida').nullable()
})

function DashboardProgramacoesFilters() {
  const classes = useDashboardStyles()

  return (
    <Formik
      initialValues={dashboardStore.filters}
      onSubmit={dashboardStore.setFilters}
      validationSchema={schema}
      enableReinitialize
    >
      {({ values, resetForm }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={6}>
              <AsyncSearchInput
                label="Unidades de Negócio"
                name="unidadeNegocio"
                url="/unidades-negocio/restrict"
                labelField="descricao"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AsyncMultipleSelect
                label="Centros de Custo"
                name="centrosCusto"
                url={
                  values.unidadeNegocio?.value
                    ? `/unidades-negocio/${values.unidadeNegocio?.value}/centros-custo`
                    : '/centros-custo'
                }
                labelField="descricao"
                filters={{ notIn: mapIdsSelectOptions(values.centrosCusto) }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AsyncMultipleSelect
                label="Clientes"
                name="clientes"
                url="/grupos-negociadores"
                labelField="nome"
                filters={{ notIn: mapIdsSelectOptions(values.clientes) }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <AsyncMultipleSelect
                label="Produtos"
                name="produtos"
                url="/produtos"
                labelField="nome"
                filters={{ notIn: mapIdsSelectOptions(values.produtos) }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AsyncMultipleSelect
                name="origens"
                label="Origens"
                url="/cidades"
                labelField="nome"
                joinStateAndCity
                filters={{ notIn: mapIdsSelectOptions(values.origens) }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <AsyncMultipleSelect
                name="destinos"
                label="Destinos"
                url="/cidades"
                labelField="nome"
                joinStateAndCity
                filters={{ notIn: mapIdsSelectOptions(values.destinos) }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <DateInput name="dataInicial" label="Data Inicial" />
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <DateInput name="dataFinal" label="Data Final" />
            </Grid>

            <Grid item xs={12}>
              <Grid justifyContent="flex-end" container spacing={2}>
                <Grid item style={{ width: 160 }}>
                  <Button
                    fullWidth
                    className={classes.filterAction}
                    variant="outlined"
                    onClick={() => {
                      resetForm()
                      dashboardStore.clearFilters()
                    }}
                  >
                    Limpar
                  </Button>
                </Grid>
                <Grid item style={{ width: 160 }}>
                  <Button
                    fullWidth
                    className={classes.filterAction}
                    type="submit"
                    color="primary"
                    variant="outlined"
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default observer(DashboardProgramacoesFilters)
