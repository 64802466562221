import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useLote } from '../hooks/useLote'
import { LotesFormData } from '../types'

export default function TipoContratacaoListener() {
  const [loteState] = useLote()
  const formik = useFormikContext<LotesFormData>()

  useEffect(() => {
    if (loteState.tag === 'with-data') return

    const tipoContratacaoAtual = formik.values.tipoContratacao
    formik.resetForm()
    formik.setFieldValue('tipoContratacao', tipoContratacaoAtual)
  }, [formik.values.tipoContratacao]) // eslint-disable-line

  return null
}
