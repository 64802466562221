import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid, Tooltip } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import {
  RelatorioInternoQueryFilter,
  statusRelatorioInternoOptions,
  tipoRelatorioInternoOptions
} from './types'

export const RelatorioInternoFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<RelatorioInternoQueryFilter>()

  const handleSubmitFilter = (values: Record<string, any>) => {
    push({
      ...values,
      createdAt:
        values.createdAt && values.createdAt instanceof Date
          ? values.createdAt.toUTCString()
          : values.createdAt || undefined,
      page: 1
    })
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={initialValues}
      onClear={clear}
      onSubmit={handleSubmitFilter}
    >
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <TextField fullWidth name="description" label="Descrição" />
        </Grid>

        <Grid item xs={3}>
          <Select
            fullWidth
            name="type"
            label="Tipo"
            items={tipoRelatorioInternoOptions}
          />
        </Grid>

        <Grid item xs={3}>
          <Select
            fullWidth
            name="status"
            label="Status"
            items={statusRelatorioInternoOptions}
          />
        </Grid>

        <Grid item xs={3}>
          <DatePicker
            fullWidth
            name="createdAt"
            label="Data de Criação"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
