import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useAbility } from '../login'
import { EstadosFilter, EstadosQueryFilter } from './EstadosFilter'
import { EstadosTable } from './EstadosTable'
import { estadosRepository } from './hooks/estadosRepository'
import { estadosStore } from './stores'

const Estados = () => {
  const { initialValues } = useQueryParams<EstadosQueryFilter>()

  const ability = useAbility()

  async function handleDelete(id: string) {
    const response = await estadosRepository.delete(id)
    estadosStore.estados.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  const authorizations = getAuthorizations(ability, 'Estados')

  useEffect(() => {
    estadosStore.estados.setPage(Number(initialValues.page) || 1)
    estadosStore.estados.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  return (
    <>
      <EstadosFilter />
      <EstadosTable authorization={authorizations} onDelete={handleDelete} />
    </>
  )
}

export default Estados
