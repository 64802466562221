export type TipoPessoa = 'PF' | 'PE' | 'PJ'
export type Sexo = 'MASCULINO' | 'FEMININO'
export type TipoEndereco = 'COMERCIAL' | 'RESIDENCIAL' | 'CORRESPONDENCIA'
export type AtividadeFiscal =
  | 'PRODUTOR_RURAL'
  | 'CONTRIBUINTE_COMERCIAL'
  | 'CONTRIBUINTE_TRANSPORTE'
  | 'CONTRIBUINTE_INDUSTRIA'
  | 'CONTRIBUINTE_COMUNICACAO'
  | 'CONTRIBUINTE_ENERGIA_ELETRICA'
  | 'NAO_CONTRIBUINTE'
  | 'NAO_CONTRIBUINTE_PF'
  | 'NAO_INFORMADO'

export type EstadoCivil =
  | 'SOLTEIRO'
  | 'CASADO'
  | 'DIVORCIADO'
  | 'VIUVO'
  | 'ESTAVEL'

export type RegimeTributario = 'NORMAL' | 'SIMPLES_NACIONAL'

export type Tributacao = 'REAL' | 'PRESUMIDO'

interface EmailPessoa {
  email: string
}

interface TelefonePessoa {
  telefone: string
}

export interface Pessoa {
  id?: string
  tipo: TipoPessoa
  pessoaId?: string
  emails: EmailPessoa[]
  grupoNegociadorId?: string
  telefones: TelefonePessoa[]
  modalidades: ModalidadesDetail[]
  Modalidades?: ModalidadesDetail[]
  dataNascimento?: Date
  nome?: string
  cpf?: string
  cnaePrincipal: string
  descricaoCnae: string
  razaoSocial?: string
  nomeFantasia?: string
  cnpj?: string
  documento?: string
  tipoDocumento?: string
  Enderecos?: EnderecosDetail[]
  enderecos: EnderecosDetail[]
  createdAt?: Date
  updatedAt?: Date
}
interface ModalidadesDetail {
  nome?: string
}
export interface EnderecosDetail {
  tipo: TipoEndereco
  inscricaoEstadual?: string
  atividadeFiscal?: AtividadeFiscal
  logradouro?: string
  cep?: string
  complemento?: string
  numero: string
  estadoId: any
  cidadeId: any
  paisId?: any
  Cidade?: {
    nome: string
  }
  Estado?: {
    nome: string
    sigla: string
  }
  Pais?: {
    nome: string
  }
}
interface ModalidadePessoa {
  modalidade: {
    name: string
    value: string
  }
}

export interface PessoaFormData {
  id?: string
  tipo: TipoPessoa
  modalidades: ModalidadePessoa[]
  Enderecos: EnderecosDetail[]
  enderecos: EnderecosDetail[]
  emails: EmailPessoa[]
  telefones: TelefonePessoa[]
  nome?: string
  cpf?: string
  razaoSocial?: string
  nomeFantasia?: string
  cnpj?: string
  inscricaoEstadual?: string
  tipoDocumento?: string
  estadoCivil?: EstadoCivil
  sexo?: Sexo
  dataNascimento?: Date
  cnaePrincipal: string
  descricaoCnae: string
  atividadeFiscal: AtividadeFiscal
  regimeTributario: RegimeTributario
  grupoNegociadorId: string
  tributacao: Tributacao
  documento?: string
}

export type PessoasQueryFilter = {
  [key: string]: any
}

export interface PessoasFilterProps {
  pessoaTipo: string
  setPessoaTipo: Function
}

export interface TipoPessoaListenerProps {
  setPessoaTipo: Function
}

export interface SubmitValues {
  createdAt?: Date
  name?: string
  active?: string
  tipo: string
}

export interface PessoasDetail extends Pessoa {
  id: string
  codigo: string
  inscricaoEstadual?: string
}

export const tiposPessoa = [
  { value: 'PF', label: 'Pessoa Física' },
  { value: 'PJ', label: 'Pessoa Jurídica' },
  { value: 'PE', label: 'Pessoa Estrangeira' }
]

export const sexo = [
  { value: 'MASCULINO', label: 'Masculino' },
  { value: 'FEMININO', label: 'Feminino' }
]

export const atividadeFiscal = [
  { value: 'PRODUTOR_RURAL', label: 'Produtor Rural' },
  { value: 'CONTRIBUINTE_COMERCIAL', label: 'Contribuinte - Comercial' },
  { value: 'CONTRIBUINTE_TRANSPORTE', label: 'Contribuinte - Transporte' },
  { value: 'CONTRIBUINTE_INDUSTRIA', label: 'Contribuinte - Indústria' },
  { value: 'CONTRIBUINTE_COMUNICACAO', label: 'Contribuinte - Comunicação' },
  {
    value: 'CONTRIBUINTE_ENERGIA_ELETRICA',
    label: 'Contribuinte - Energia Elétrica'
  },
  { value: 'NAO_CONTRIBUINTE', label: 'Não contribuinte' },
  { value: 'NAO_CONTRIBUINTE_PF', label: 'Não contribuinte - PF' },
  { value: 'NAO_INFORMADO', label: 'Não informado' }
]

export const estadoCivil = [
  { value: 'SOLTEIRO', label: 'Solteiro (a)' },
  { value: 'CASADO', label: 'Casado (a)' },
  { value: 'DIVORCIADO', label: 'Divorciado (a)' },
  { value: 'VIUVO', label: 'Viúvo (a)' },
  { value: 'ESTAVEL', label: 'União Estável' }
]

export const regimeTributario = [
  { value: 'REGIME_NORMAL', label: 'Regime Normal' },
  { value: 'SIMPLES_NACIONAL', label: 'Simples Nacional' }
]

export const tributacao = [
  { value: 'LUCRO_REAL', label: 'Lucro Real' },
  { value: 'LUCRO_PRESUMIDO', label: 'Lucro Presumido' }
]

export const defaultTipo = 'PF'

export function getNomePessoa(pessoa: Pessoa | PessoasDetail) {
  switch (pessoa.tipo) {
    case 'PE':
    case 'PF':
      return pessoa.nome!

    case 'PJ':
      return pessoa.nomeFantasia!
  }
}
