import { addDays, startOfToday } from 'date-fns'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import { isNil } from 'ramda'
import { useEffect } from 'react'
import selecaoLotesStore from '../../store/selecao-lotes-store'
import { LoteProgramacaoFormData, LotesProgramacao } from '../../types/formData'

interface FormType {
  lotes: LotesProgramacao
  dataCarregamento: Date
  dataDescarregamento: Date
  horaCarregamento: Date
  horaDescarregamento: Date
}

export const LotesListener = observer(() => {
  const formik = useFormikContext<FormType>()
  const {
    dataCarregamento,
    dataDescarregamento,
    horaCarregamento,
    horaDescarregamento,
    lotes
  } = formik.values

  function getLowestDataParaCarregar(
    lotesArray: LoteProgramacaoFormData[]
  ): number | null {
    const lotesFiltered = lotesArray.filter(
      lote => !isNil(lote.diasParaCarregar)
    )

    if (lotesFiltered.length === 0) return null

    const diasParaCarregarLotes = lotesFiltered.map(lote =>
      Number(lote.diasParaCarregar)
    )
    return Math.min(...diasParaCarregarLotes)
  }

  function getHighestDataParaDescarregar(
    lotesArray: LoteProgramacaoFormData[]
  ): number | null {
    const lotesFiltered = lotesArray.filter(
      lote => !isNil(lote.diasParaDescarregar)
    )

    if (lotesFiltered.length === 0) return null

    const diasParaDescarregarLotes = lotesFiltered.map(lote =>
      Number(lote.diasParaDescarregar)
    )
    return Math.max(...diasParaDescarregarLotes)
  }

  useEffect(() => {
    const lotesArray = Object.values(lotes)

    const modalIsOpen = selecaoLotesStore.selecaoLotesModal
    const anyLoteSelected = lotesArray.length > 0

    if (
      !modalIsOpen &&
      anyLoteSelected &&
      !dataCarregamento &&
      !dataDescarregamento
    ) {
      const lowestDataParaCarregar = getLowestDataParaCarregar(lotesArray)
      const highestDataParaDescarregar =
        getHighestDataParaDescarregar(lotesArray)

      formik.setValues({
        ...formik.values,
        dataCarregamento: !isNil(lowestDataParaCarregar)
          ? addDays(startOfToday(), lowestDataParaCarregar)
          : dataCarregamento,
        dataDescarregamento: !isNil(highestDataParaDescarregar)
          ? addDays(startOfToday(), highestDataParaDescarregar)
          : dataDescarregamento,
        horaCarregamento: !isNil(lowestDataParaCarregar)
          ? new Date()
          : horaCarregamento,
        horaDescarregamento: !isNil(highestDataParaDescarregar)
          ? new Date()
          : horaDescarregamento
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecaoLotesStore.selecaoLotesModal])

  return null
})
