import { useEstado } from '../hooks/useEstado'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { EstadosForm } from './EstadosForm'

export function EstadoMasterDetail() {
  const [estadoState, estadoActions] = useEstado()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      estadoState,
      e => e.entity.id,
      e => e.entity.nome
    )
  ])

  useFetchAndReset(estadoActions.fetch, estadoActions.reset)
  return handleStateErrorsToRender(estadoState, <EstadosForm />)
}
