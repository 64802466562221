import { apiSauceInstance } from '../../../services/api'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { Configuracoes, ConfiguracoesDetail } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, sort, ...rest } = params

  return {
    ...paginationParams(rest),
    limit: limit || '10',
    sort: sort || {
      chave: 'asc'
    }
  }
}

export const configuracoesRepository = makeRepository<
  EdgesPage<ConfiguracoesDetail>,
  Configuracoes,
  ConfiguracoesDetail,
  string
>({ resource: 'configuracoes', mapQueryParams }, apiSauceInstance)
