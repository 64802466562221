import { Box, Divider, Grid } from '@material-ui/core'
import { Fragment } from 'react'
import {
  translateKey,
  translateValue
} from 'src/modules/controle-logs/logTranslator'
import { UpdateViewProps } from 'src/modules/controle-logs/views/Update'
import { FieldLog } from './FieldLog'

export function UpdateView(props: UpdateViewProps) {
  const { field, oldValue: oldV, newValue: newV, isLast } = props

  const fieldValue = translateKey(field)
  const oldValue = translateValue(field, oldV)
  const newValue = translateValue(field, newV)

  return (
    <Fragment>
      <Grid container spacing={1}>
        <Box display="flex">
          <FieldLog
            field={fieldValue}
            oldValue={oldValue}
            newValue={newValue}
          />
        </Box>
      </Grid>
      {!isLast && <Divider style={{ marginTop: 16 }} />}
    </Fragment>
  )
}
