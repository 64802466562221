import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldArray, FieldArrayRenderProps, useField } from 'formik'
import TextInput from './Formik/Forms/TextInput'

interface EmailsInputProps {
  name: string
}

export default function EmailsInput({ name }: EmailsInputProps) {
  const [field, meta] = useField({ name })
  const hasEmails = !!field.value?.length

  function addEmail(arrayHelpers: FieldArrayRenderProps) {
    return () => {
      arrayHelpers.push({ email: '' })
    }
  }

  function deleteEmail(index: number, arrayHelpers: FieldArrayRenderProps) {
    return () => {
      const email = field.value[index]

      if (email.id) {
        arrayHelpers.form.setFieldValue('deletedEmails', [
          ...(arrayHelpers.form.values?.deletedEmails || []),
          email.id
        ])
      }

      arrayHelpers.remove(index)
    }
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {hasEmails &&
            field.value.map((email: unknown, index: number) => {
              return (
                <Grid xs={12} item key={index}>
                  <Box display="flex">
                    <TextInput
                      uppercase={false}
                      label={`E-mail ${index + 1}`}
                      name={`${name}.${index}.email`}
                      data-cy={`email-${index}`}
                      InputProps={{
                        endAdornment: (
                          <Tooltip title="Excluir" aria-label="Excluir">
                            <InputAdornment position="end">
                              <IconButton
                                onClick={deleteEmail(index, arrayHelpers)}
                                size="small"
                              >
                                <DeleteIcon />
                              </IconButton>
                            </InputAdornment>
                          </Tooltip>
                        )
                      }}
                    />
                  </Box>
                </Grid>
              )
            })}

          <Grid xs={12} item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={addEmail(arrayHelpers)}
              data-cy="add-email"
            >
              Adicionar E-mail
            </Button>

            <FormHelperText error>
              {!field.value?.length && meta.error}
            </FormHelperText>
          </Grid>
        </Grid>
      )}
    />
  )
}
