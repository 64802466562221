import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { unidadeNegocioRepository } from './hooks/unidadeNegocioRepository'
import { unidadeNegocioStore } from './stores'
import {
  UnidadeNegocioFilter,
  UnidadeNegocioQueryFilter
} from './UnidadeNegocioFilter'
import { UnidadeNegocioTable } from './UnidadeNegocioTable'

const UnidadesNegocio = () => {
  const { initialValues } = useQueryParams<UnidadeNegocioQueryFilter>()
  const ability = useAbility()

  async function handleDelete(id: string) {
    const response = await unidadeNegocioRepository.delete(id)

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }
  const authorization = getAuthorizations(ability, 'Unidades de Negócio')

  useEffect(() => {
    unidadeNegocioStore.unidadeNegocio.setPage(Number(initialValues.page) || 1)
    unidadeNegocioStore.unidadeNegocio.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  return (
    <>
      <UnidadeNegocioFilter />
      <UnidadeNegocioTable
        authorization={authorization}
        onDelete={handleDelete}
      />
    </>
  )
}

export default UnidadesNegocio
