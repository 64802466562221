import { LinearProgress } from '@material-ui/core'
import { Form, Formik, useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { ProgramacaoFormData } from '../../types/formData'
import {
  SimularFreteContrato,
  SimularFreteContratoForm,
  SimularFreteContratoSave
} from '../../types/simularFrete'
import { validarFreteSchema } from '../../validation/simularFrete'
import { DadosContrato } from './DadosContrato'
import { DadosSimulacao } from './DadosSimulacao'
import { ValoresSimulacao } from './ValoresSimulacao'

interface Props {
  tabelaId: string
  handleClickBack: () => void
  pernaIndex: Nullable<number>
  loteId: string
}

const initialValues: SimularFreteContratoForm = {
  contratoId: '',
  tabelaId: '',
  codigo: '',
  grupoNegociador: '',
  descricaoContrato: '',
  descricaoTabela: '',
  agrupadorProduto: '',
  tipoNegociacao: '',
  tipoPedagio: '',
  frete: '',
  adicionalEntrega: '',
  franquiaEntrega: '',

  centroCustoId: { name: '', value: '' },
  dataSimulacao: new Date(),
  capacidadeVeiculo: { name: '', value: '' },
  distancia: '',
  qtdEixos: '',
  qtdVolumes: '',
  qtdEntregas: '',
  aliquotaIcms: '',
  pedagio: '',

  freteEmpresa: '',
  valorPedagio: ''
}

export const SimularFreteForm = ({
  tabelaId,
  handleClickBack,
  pernaIndex,
  loteId
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [simulacaoResult, setSimulacaoResult] = useState<SimularFreteContrato>()
  const formik = useFormikContext<ProgramacaoFormData>()

  const fillOnProgramacao = (freteEmpresa: number, valorPedagio: number) => {
    formik.setFieldValue(
      `lotes.${loteId}.pernas.${pernaIndex ?? 0}.freteEmpresa`,
      freteEmpresa
    )
    formik.setFieldValue(
      `lotes.${loteId}.pernas.${pernaIndex ?? 0}.valorPedagio`,
      valorPedagio
    )
    handleClickBack()
  }

  async function simular(data: SimularFreteContratoSave) {
    const response = await apiSauceInstance.post<
      SimularFreteContrato,
      { message: string }
    >('/simular-frete-contrato', data)

    if (response.ok) {
      setSimulacaoResult(response.data)
    } else {
      snackbarStore.setMessage(
        response.data?.message ?? 'Ocorreu um erro ao calcular a simulação'
      )
    }

    setLoading(false)
  }

  const handleReset = () => {
    setSimulacaoResult(undefined)
  }

  useEffect(() => {
    return () => {
      setSimulacaoResult(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (data: SimularFreteContratoForm) => {
    setLoading(true)
    simular({
      tabelaId: data.tabelaId,
      contratoId: data.contratoId,
      dataSimulacao: data.dataSimulacao,
      capacidadeVeiculo: data.capacidadeVeiculo.value,
      aliquotaIcms: Number(data.aliquotaIcms) / 100,
      pedagio: data.pedagio || undefined,
      qtdEixos: data.qtdEixos || undefined,
      qtdEntregas: data.qtdEntregas || undefined,
      qtdVolumes: data.qtdVolumes || 0,
      distancia: data.distancia,
      centroCustoId: data.centroCustoId.value
    } as SimularFreteContratoSave)
  }

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validarFreteSchema}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <DadosContrato handleReset={handleReset} tabelaId={tabelaId} />
            <DadosSimulacao loading={loading} handleReset={handleReset} />
          </Form>
        )}
      </Formik>
      {loading && <LinearProgress />}
      {!!simulacaoResult && (
        <ValoresSimulacao
          simulacaoResult={simulacaoResult}
          handleClickBack={handleClickBack}
          fillOnProgramacao={fillOnProgramacao}
        />
      )}
    </>
  )
}
