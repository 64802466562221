import { TextField } from '@material-ui/core'
import { useField } from 'formik'
import { ChangeEvent } from 'react'
import InputMask from 'react-input-mask'

interface MaskInputProps {
  disabled?: boolean
  name: string
  label: string
  mask: string
  type?: string
  endAdornment?: React.ReactNode
  uppercase?: boolean
}

function MaskInput(props: MaskInputProps) {
  const [field, meta, helpers] = useField(props)
  const { label, mask, type, endAdornment, uppercase = true } = props

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value

    helpers.setValue(uppercase ? value.toUpperCase() : value)
  }

  return (
    <InputMask
      {...field}
      {...props}
      mask={mask}
      maskChar=""
      alwaysShowMask={false}
      onChange={handleChange}
    >
      {(inputProps: unknown) => (
        <TextField
          {...inputProps}
          label={label}
          type={type}
          variant="outlined"
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          fullWidth
          InputProps={{
            endAdornment,
            name: props.name,
            disabled: props.disabled
          }}
        />
      )}
    </InputMask>
  )
}

export default MaskInput
