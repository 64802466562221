import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useAbility } from '../login'
import { locaisRepository } from './hooks/locaisRepository'
import { LocaisFilter } from './LocaisFilter'
import { LocaisTable } from './LocaisTable'
import locaisStore from './store/locaisStore'

export type LocaisQueryFilter = {
  [key: string]: any
}

export const LocaisList = () => {
  const ability = useAbility()
  const { initialValues } = useQueryParams<LocaisQueryFilter>()
  const authorization = getAuthorizations(ability, 'Locais')

  async function handleDelete(id: string) {
    const response = await locaisRepository.delete(id)
    locaisStore.locais.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    locaisStore.locais.setPage(Number(initialValues.page) || 1)
    locaisStore.locais.setFilters(initialValues)
  }, [initialValues])

  useEffect(() => {
    return () => {
      locaisStore.locais.resetList()
    }
  }, [])

  return (
    <>
      <LocaisFilter />
      <LocaisTable onDelete={handleDelete} authorization={authorization} />
    </>
  )
}
