import { makeRepository } from '@elentari/core'
import { EdgesPage } from '@elentari/core/types'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from '../../../services/api'
import { paginationParams } from '../../../utils/paginationQueryParams'
import {
  AlterarStatusData,
  Cotacao,
  CotacaoDetail,
  CotacaoDetailForm,
  UpdateStatus
} from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || { cliente: 'asc' }
  }
}

export const cotacoesRepository = makeRepository<
  EdgesPage<Cotacao>,
  CotacaoDetailForm,
  CotacaoDetail,
  string
>({ resource: 'cotacoes', mapQueryParams }, apiSauceInstance)

export const alterarStatus = async (
  id: string,
  type: UpdateStatus,
  data: AlterarStatusData
) => {
  if (type === UpdateStatus.APROVAR) {
    return apiSauceInstance.patch<{ message: string; error: any }>(
      `/cotacoes/${id}/aprovar`,
      data
    )
  }

  return apiSauceInstance.patch<{ message: string; error: any }>(
    `/cotacoes/${id}/reprovar`,
    data
  )
}
