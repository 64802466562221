import { makeAutoObservable, observable } from 'mobx'

interface IStateStore {
  hasDefaultConfigDatabase?: boolean
  columnOrder: string[]
  hiddenColumns: string[]
  groupColumns: string[]
  isDefault?: boolean
  descricaoTabela?: string
  limit?: string
}

interface ITableStateStore {
  [key: string]: IStateStore
}

class TableStore {
  state = observable<ITableStateStore>({})

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  setDefaultConfigDatabase(key: string, bool: boolean) {
    if (Object.keys(this.state).includes(key)) {
      this.state[key].hasDefaultConfigDatabase = bool
    }
  }

  setState(key: string, state: IStateStore) {
    this.state[key] = state
  }

  getState(key: string) {
    return this.state[key]
  }

  getStateColumnOrder(key: string) {
    if (this.state[key] && this.state[key].columnOrder) {
      return this.state[key].columnOrder
    } else {
      return []
    }
  }

  setStateColumnOrder(key: string, columnOrder: string[]) {
    if (Object.keys(this.state).includes(key)) {
      this.state[key].columnOrder = columnOrder
    } else {
      this.state[key] = {
        columnOrder,
        hiddenColumns: [],
        groupColumns: []
      }
    }
  }

  getStateHiddenColumns(key: string) {
    if (this.state[key] && this.state[key].hiddenColumns) {
      return this.state[key].hiddenColumns
    } else {
      return []
    }
  }

  setStateHiddenColumns(key: string, hiddenColumns: string[]) {
    if (Object.keys(this.state).includes(key)) {
      this.state[key].hiddenColumns = hiddenColumns
    } else {
      this.state[key] = {
        hiddenColumns,
        columnOrder: [],
        groupColumns: []
      }
    }
  }
  setStateGroupColumns(key: string, groupColumns: string[]) {
    if (Object.keys(this.state).includes(key)) {
      this.state[key].groupColumns = groupColumns
    } else {
      this.state[key] = {
        groupColumns,
        columnOrder: [],
        hiddenColumns: []
      }
    }
  }
  getStateGroupColumns(key: string) {
    if (this.state[key] && this.state[key].groupColumns) {
      return this.state[key].groupColumns
    } else {
      return []
    }
  }
}

export const tableStore = new TableStore()
