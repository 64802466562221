import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { EntitySaver } from 'src/services/entitySaver'
import * as yup from 'yup'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import { MessagesYup } from '../../messages'
import { modalidadesRepository } from '../hooks/modalidadesRepository'
import { useModalidade } from '../hooks/useModalidade'
import { Modalidades } from '../types'

const schema = yup.object().shape({
  nome: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  nome: ''
}

export const ModalidadesForm = () => {
  const [modalidadeState] = useModalidade()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const canSave = useCanSave(modalidadeState, 'Modalidades')

  async function handleSubmit(modalidade: Modalidades) {
    setLoading(true)

    const formatModalidades = {
      id: modalidade.id,
      nome: modalidade.nome
    }

    await EntitySaver(
      () => modalidadesRepository.save(formatModalidades) as any,
      {
        onSuccess: () => handleGoBack(),
        feedbackError: 'Erro ao salvar a modalidade'
      }
    )

    setLoading(false)
  }

  function handleGoBack() {
    const newPath = window.location.pathname.split('/')
    newPath.pop()
    history.replace(newPath.join('/'))
  }

  return (
    <Formik
      enableReinitialize
      validationSchema={schema}
      initialValues={
        modalidadeState.tag === 'with-data'
          ? modalidadeState.entity
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="nome" label="Nome" />
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
