import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { defaultTipo, PessoasDetail } from '../types'

class PessoasStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  pessoaTipo = defaultTipo

  setPessoaTipo(pessoa: string) {
    this.pessoaTipo = pessoa
  }

  pessoas = new ApisauceDataStore<PessoasDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/pessoas',
    resultsField: 'edges'
  })
}

const pessoasStore = new PessoasStore()

export default pessoasStore
