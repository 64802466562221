import React from 'react'
import { IColumn } from 'src/components/BaseTable'
import When from 'src/components/When'
import { DeleteRecord } from '../../components'

interface IPaisesColumnProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const PaisesColumns = ({
  onDelete,
  authorization
}: IPaisesColumnProps): IColumn[] => {
  return [
    {
      Header: 'Nome',
      accessor: 'nome'
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <DeleteRecord
                  onDelete={() => onDelete(props.row.original.id)}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
