import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined'
import { useState } from 'react'
import CentrosCustoDialog from './Dialogs/CentrosCustoDialog'
import ContratosDialog from './Dialogs/ContratosDialog'
import LocalDialog from './Dialogs/LocaisDialog'
import LotesDialog from './Dialogs/LotesDialog'
import ProgramacaoDialog from './Dialogs/ProgramacoesDialog'
import TabelasContratoDialog from './Dialogs/TabelasContratoDialog'
import UnidadesNegocioDialog from './Dialogs/UnidadesNegocioDialog'

type Props = {
  title?: string
  message?: string
  status: any
  optionsStatus: string[]
  id: string
  childId?: string
  isStatusChangeable?: boolean
  isProgramacao?: boolean
  isUnidadeNegocio?: boolean
  isCentroCusto?: boolean
  isContratos?: boolean
  isLocais?: boolean
  isTabelasContrato?: boolean
  disabled?: boolean
}

export function EditarStatusRecord({
  status,
  optionsStatus,
  id,
  childId,
  isStatusChangeable,
  isProgramacao,
  isUnidadeNegocio,
  isCentroCusto,
  isContratos,
  isLocais,
  isTabelasContrato,
  disabled
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  function renderDialog() {
    if (isProgramacao) {
      return (
        <ProgramacaoDialog
          id={id}
          optionsStatus={optionsStatus}
          status={status}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )
    } else if (isUnidadeNegocio) {
      return (
        <UnidadesNegocioDialog
          id={id}
          optionsStatus={optionsStatus}
          status={status}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )
    } else if (isCentroCusto) {
      return (
        <CentrosCustoDialog
          id={id}
          optionsStatus={optionsStatus}
          status={status}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )
    } else if (isContratos) {
      return (
        <ContratosDialog
          id={id}
          optionsStatus={optionsStatus}
          status={status}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )
    } else if (isLocais) {
      return (
        <LocalDialog
          id={id}
          optionsStatus={optionsStatus}
          status={status}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )
    } else if (isTabelasContrato) {
      return (
        <TabelasContratoDialog
          contratoId={id}
          tabelaId={childId!}
          optionsStatus={optionsStatus}
          status={status}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )
    } else {
      return (
        <LotesDialog
          id={id}
          optionsStatus={optionsStatus}
          status={status}
          open={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
        />
      )
    }
  }

  return (
    <>
      <Tooltip title="Edição de Status" aria-label="Edição de Status">
        <IconButton
          disabled={!isStatusChangeable || disabled}
          onClick={() => setIsModalOpen(true)}
        >
          <AssignmentOutlined />
        </IconButton>
      </Tooltip>
      {renderDialog()}
    </>
  )
}
