import { statusProgramacao } from 'src/modules/programacoes/types'

export function formatStatusLote(status: string) {
  switch (status) {
    case 'STANDBY':
      return 'STANDBY'
    case 'ANALISE':
      return 'ANÁLISE'
    case 'REANALISE':
      return 'REANÁLISE'
    case 'PENDENTE':
      return 'PENDENTE'
    case 'REPROVADO':
      return 'REPROVADO'
    case 'ATIVO':
      return 'ATIVO'
    case 'CANCELADO':
      return 'CANCELADO'
    case 'FINALIZADO':
      return 'FINALIZADO'
    case 'SUSPENSO':
      return 'SUSPENSO'
    case 'VENCIDO':
      return 'VENCIDO'
    case 'ABERTO':
      return 'ABERTO'
    case 'REABERTO':
      return 'REABERTO'
    default:
      return status
  }
}

export function formatStatusProgramacao(status: string) {
  return statusProgramacao.find(s => s.value === status)?.label || 'N/A'
}
