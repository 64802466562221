import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import DateInput from 'src/components/DateInput'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import { SimularFreteContratoForm } from '../../types/simularFrete'

interface Props {
  loading: boolean
  handleReset: () => void
}

export const DadosSimulacao = ({ loading, handleReset }: Props) => {
  const formik = useFormikContext<SimularFreteContratoForm>()
  const contratoId = formik.values.contratoId
  const tabelaId = formik.values.tabelaId

  const clearSimulacao = () => {
    handleReset()
    formik.setValues({
      ...formik.values,
      centroCustoId: { name: '', value: '' },
      dataSimulacao: new Date(),
      capacidadeVeiculo: { name: '', value: '' },
      distancia: '',
      qtdEixos: '',
      qtdVolumes: '',
      qtdEntregas: '',
      aliquotaIcms: '',
      pedagio: ''
    })
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Dados da Simulação</Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <AsyncSearchInput
            name="centroCustoId"
            label="Centros de Custo"
            url="/centros-custo"
            labelField="descricao"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DateInput label="Data da Simulação" name="dataSimulacao" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <AsyncSearchInput
            name="capacidadeVeiculo"
            label="Capacidade do Veículo"
            url={`/contratos/${contratoId}/tabelas/${tabelaId}/capacidades-veiculo`}
            labelField="capacidade"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumericInput name="distancia" label="Distância (KM)" precision={0} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumericInput
            name="qtdEixos"
            label="Qtd. de Eixos do Veículo"
            precision={0}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumericInput
            name="qtdEntregas"
            label="Quantidade de Entregas"
            precision={0}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumericInput
            name="aliquotaIcms"
            label="Alíquota ICMS (%)"
            precision={2}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumericInput name="pedagio" label="Valor do Pedágio" precision={2} />
        </Grid>

        <Grid item xs={12} sm={4}>
          <NumericInput
            name="qtdVolumes"
            label="Qtd. de Volumes"
            precision={0}
          />
        </Grid>

        <Grid item xs={12}>
          <Grid
            justifyContent="flex-end"
            container
            spacing={2}
            style={{ marginBottom: 12 }}
          >
            <Grid item style={{ width: 160 }}>
              <Button
                fullWidth
                disabled={loading}
                color="primary"
                variant="outlined"
                onClick={clearSimulacao}
              >
                Limpar
              </Button>
            </Grid>
            <Grid item style={{ width: 160 }}>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
              >
                Calcular
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  )
}
