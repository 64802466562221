import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import {
  NumericFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import { EditarStatusRecord } from 'src/components/EditarStatusRecord'
import When from 'src/components/When'
import { DeleteRecord } from '../../components'
import { statusUnidadesNegocio } from './types'

interface IUnidadeNegocioColumnProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const UnidadeNegocioColumns = ({
  onDelete,
  authorization
}: IUnidadeNegocioColumnProps): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo'
    },
    {
      Header: 'Descrição',
      accessor: 'descricao'
    },
    {
      Header: 'Margem esperada',
      accessor: 'margemEsperada',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup<{}>,
          maxValue: 100
        })
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: statusUnidadesNegocio
        })
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <EditarStatusRecord
                  isStatusChangeable
                  status={props.row.original.status}
                  id={props.row.original.id}
                  optionsStatus={['ATIVO', 'INATIVO'].filter(
                    file => file !== props.row.original.status
                  )}
                  isUnidadeNegocio
                />
              </When>
              <When value={authorization?.canUpdate} equals>
                <DeleteRecord
                  onDelete={() => onDelete(props.row.original.id)}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
