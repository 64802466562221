import { Box, Button, Tab, Tabs, Typography } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { SetStateAction, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { ConsultaField } from 'src/components/ConsultaField'
import FilesList from 'src/components/FilesList'
import snackbarStore from 'src/stores/snackbar'
import { formatDate } from 'src/utils/formatters'
import { Paper } from '../../../components'
import { getLotesByContratoId } from '../hooks/contratosRepository'
import { useContrato } from '../hooks/useContrato'
import { LoteContrato } from '../types/types'
import LotesList from './ContratoLotesList'

interface Props {
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  isValidation?: boolean
}

const initialValues = {
  grupoNegociadorId: '',
  descricao: '',
  dataInicio: new Date(),
  dataTermino: new Date(),
  agrupadorProdutosId: '',
  cadencia: '',
  observacoes: '',
  files: []
}

function TabPanel(props: {
  [x: string]: any
  children: any
  value: any
  index: any
}) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`contrato-tabpanel-${index}`}
      aria-labelledby={`contrato-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} paddingTop={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

export const ContratoView = ({ authorization, isValidation }: Props) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [contratoState, contratoActions] = useContrato()
  const [lotesList, setLotesList] = useState<LoteContrato[]>([])
  const [value, setValue] = useState(0)

  useEffect(() => {
    contratoActions.fetch(id)
    getLotesByContratoId(id).then(response => {
      if (response.ok) {
        setLotesList(response.data!)
      } else {
        setLotesList([])
        snackbarStore.setMessage(
          response.data?.message ?? 'Ocorreu um erro ao listar lotes.'
        )
      }
    })
    return () => contratoActions.reset()
  }, [id, contratoActions])

  function a11yProps(index: number) {
    return {
      id: `contrato-tab-${index}`,
      'aria-controls': `contrato-tabpanel-${index}`
    }
  }

  function handleGoBack() {
    history.goBack()
  }

  const handleChangeTab = (event: any, newValue: SetStateAction<number>) => {
    setValue(newValue)
  }

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={
          contratoState.tag === 'with-data'
            ? contratoState.entity
            : initialValues
        }
        onSubmit={() => {}}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="contrato tabs"
              >
                <Tab label="Contrato" {...a11yProps(0)} />
                <Tab label="Lotes" {...a11yProps(1)} />
              </Tabs>
            </AppBar>
            <Paper noTopBorderRadius>
              {contratoState.tag === 'with-data' ? (
                <>
                  <TabPanel value={value} index={0}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                          Dados do Contrato
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <ConsultaField
                          title="Cliente Contratante"
                          subtitle={contratoState.entity.GrupoNegociador.nome}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ConsultaField
                          title="Descrição Contrato"
                          subtitle={contratoState.entity.descricao}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ConsultaField
                          title="Data Início Retirada"
                          subtitle={formatDate(contratoState.entity.dataInicio)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <ConsultaField
                          title="Data Limite Retirada"
                          subtitle={formatDate(
                            contratoState.entity.dataTermino
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ConsultaField
                          title="Agrupador de Produtos"
                          subtitle={contratoState.entity.AgrupadorProdutos.nome}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ConsultaField
                          title="Cadência para Retirada (opcional)"
                          subtitle={
                            contratoState.entity.cadencia || 'Sem Candência'
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <ConsultaField
                          title="Observações (opcional)"
                          subtitle={
                            contratoState.entity.observacoes ||
                            'Sem Observações'
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <FilesList anexos={contratoState.entity.Anexos || []} />
                    </Grid>
                    <Grid
                      justifyContent="flex-end"
                      item
                      container
                      spacing={2}
                      style={{ marginTop: 8 }}
                    >
                      <Grid item style={{ width: 160 }}>
                        <Button
                          fullWidth
                          type="button"
                          variant="outlined"
                          onClick={handleGoBack}
                          disabled={isSubmitting}
                        >
                          Voltar
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <LotesList lotes={lotesList} />
                  </TabPanel>
                </>
              ) : null}
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  )
}
