import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import * as dateFns from 'date-fns'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import RFFInputCoordinate from 'src/components/Forms/RFFInputCoordinate'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'

export type CidadesQueryFilter = {
  [key: string]: any
}

const regexCoordenada = /^-?[0-9][0-9]{0,2}\.[0-9][0-9]{0,5}?$/

const schema = yup.object().shape({
  nome: yup.string(),
  latitude: yup
    .string()
    .test('latitude', MessagesYup.LATITUDE_ERROR, function (value: any) {
      if (value) {
        const valueNumber = Number(value)
        return valueNumber >= -90 && valueNumber <= 90
      }

      return true
    })
    .matches(regexCoordenada, MessagesYup.MENSAGEM_PADRAO_INVALIDO),
  longitude: yup
    .string()
    .test('longitude', MessagesYup.LONGITUDE_ERROR, function (value: any) {
      if (value) {
        const valueNumber = Number(value)
        return valueNumber >= -180 && valueNumber <= 180
      }
      return true
    })
    .matches(regexCoordenada, MessagesYup.MENSAGEM_PADRAO_INVALIDO)
})

export const CidadesFilter = () => {
  const { clear, push, initialValues } = useQueryParams<CidadesQueryFilter>()

  const handleSubmitFilter = (values: Record<string, any>) => {
    clear()
    push({
      ...values,
      estadoId: values.estadoId?.value
    })
  }

  const format = (initialValues: CidadesQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField fullWidth name="nome" label="Nome" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <RFFInputCoordinate fullWidth name="latitude" label="Latitude" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <RFFInputCoordinate fullWidth name="longitude" label="Longitude" />
        </Grid>
        <Grid item xs={12} sm={3}>
          <RFFAsyncSearchInput
            name="estadoId"
            label="Estado"
            url="/estados/minimal"
            labelField="nome"
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
