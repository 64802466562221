import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import Grid from '@material-ui/core/Grid'
import * as dateFns from 'date-fns'
import * as yup from 'yup'
import { SubmitValues } from '../../hooks/types'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'

const schema = yup.object().shape({
  nome: yup.string().typeError(MessagesYup.MENSAGEM_CAMPO_STRING)
})

export type ModalidadeQueryFilter = {
  cretedAt?: { gt: string }
  [key: string]: any
}

export const ModalidadesFilter = () => {
  const { clear, push, initialValues } = useQueryParams<ModalidadeQueryFilter>()

  const handleSubmitFilter = (values: SubmitValues & { page: number }) => {
    if (values.createdAt) {
      const dateFormated = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )

      return push({
        ...values,
        createdAt: {
          gt: dateFormated.toISOString()
        }
      })
    }

    push({
      ...values,
      createdAt: undefined
    })
  }
  const format = (initialValues: ModalidadeQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField fullWidth name="codigo" label="Código" type="number" />
        </Grid>
        <Grid item xs={12} sm={10}>
          <TextField fullWidth name="nome" label="Nome" />
        </Grid>
      </Grid>
    </Filter>
  )
}
