import { useFormikContext } from 'formik'
import { useEffect } from 'react'

export default function TipoLoteListener() {
  const formik =
    useFormikContext<{ tipoLote: string; quantidadeTotal: string }>()

  async function setQuantidade() {
    formik.setFieldValue('quantidadeTotal', null)
  }

  useEffect(() => {
    if (formik.values.tipoLote === 'ABERTO') {
      setQuantidade()
    }
  }, [formik.values.tipoLote]) // eslint-disable-line

  return null
}
