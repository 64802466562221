export function formatCurrency(
  value: number,
  locale?: string,
  currency?: string
) {
  const formatter = new Intl.NumberFormat(locale ?? 'pt-BR', {
    style: 'currency',
    currency: currency ?? 'BRL'
  })

  return formatter.format(value)
}
