import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { AgrupadoresProdutosTable } from './AgrupadoresProdutosTable'
import {
  AgrupadorProdutosFilter,
  AgrupadorProdutosQueryFilter
} from './AgrupadorProdutoFilter'
import { agrupadoresProdutosRepository } from './hooks/agrupadoresProdutosRepository'
import { agrupadoresProdutosStore } from './stores'

const AgrupadoresProdutos = () => {
  const ability = useAbility()
  const { initialValues } = useQueryParams<AgrupadorProdutosQueryFilter>()

  async function handleDelete(id: string) {
    const response = await agrupadoresProdutosRepository.delete(id)
    agrupadoresProdutosStore.agrupadoresProdutos.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    agrupadoresProdutosStore.agrupadoresProdutos.setPage(
      Number(initialValues.page) || 1
    )
    agrupadoresProdutosStore.agrupadoresProdutos.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  const authorization = getAuthorizations(ability, 'Agrupadores de Produtos')

  return (
    <>
      <AgrupadorProdutosFilter />
      <AgrupadoresProdutosTable
        authorization={authorization}
        onDelete={handleDelete}
      />
    </>
  )
}

export default AgrupadoresProdutos
