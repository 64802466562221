import { Button, Grid, IconButton, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever'
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext
} from 'formik'
import { Fragment } from 'react'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { DestinoPernaFormData, ProgramacaoFormData } from '../../types/formData'
import { ColetaEntregaProps } from './types'

interface Props
  extends Omit<
    ColetaEntregaProps,
    'namePrefix' | 'contratoId' | 'tabelaId' | 'loteId'
  > {
  name: string
}

export const DestinosInput = ({
  name,
  multiplasEntregas,
  isLastPerna
}: Props) => {
  const [field] = useField<DestinoPernaFormData[]>(name)
  const formik = useFormikContext<ProgramacaoFormData>()
  const loteId = name.split('.')[1]
  const destinos = formik.values.lotes[loteId].Destino

  const addDestino = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push({
      localEntrega: '',
      estadoDestino: '' as any,
      cidadeDestino: '' as any,
      quantidade: '' as any
    })
  }

  const removeDestino = (
    arrayHelpers: FieldArrayRenderProps,
    index: number
  ) => {
    arrayHelpers.remove(index)
  }

  const queryEstados = {
    siglas: destinos.map(destino => destino.siglaEstado)
  }

  const buildQueryCidades = (destinoFormData: DestinoPernaFormData) => {
    const cidadesIds = destinos.map(destino => destino.cidadeId).filter(Boolean)

    return {
      estadoId: destinoFormData.estadoDestino?.value,
      ids: cidadesIds.length > 0 ? cidadesIds : undefined
    }
  }

  const handleChangeEstado = (field: string) => {
    formik.setFieldValue(field, '')
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {field.value.map((destinoFormData, index) => (
            <Fragment key={index}>
              <Grid item xs={12} md={3}>
                <TextInput
                  name={`${name}.${index}.localEntrega`}
                  label="Local de Entrega"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <AsyncSearchInput
                  name={`${name}.${index}.estadoDestino`}
                  label="Estado Destino"
                  url="/estados/minimal"
                  filters={queryEstados}
                  labelField="nome"
                  joinStateAndUf
                  handleOnChange={() =>
                    handleChangeEstado(`${name}.${index}.cidadeDestino`)
                  }
                />
              </Grid>
              <Grid item xs={12} md={isLastPerna ? 3 : 6}>
                <AsyncSearchInput
                  name={`${name}.${index}.cidadeDestino`}
                  label="Cidade Destino"
                  url="/cidades"
                  filters={buildQueryCidades(destinoFormData)}
                  labelField="nome"
                  disabled={!destinoFormData.estadoDestino?.value}
                />
              </Grid>
              {isLastPerna && (
                <Grid
                  item
                  xs={field.value.length > 1 ? 11 : 12}
                  md={field.value.length > 1 ? 2 : 3}
                >
                  <NumericInput
                    precision={0}
                    name={`${name}.${index}.quantidade`}
                    label="Quantidade"
                  />
                </Grid>
              )}
              {field.value.length > 1 ? (
                <Tooltip title="Remover destino" aria-label="Remover destino">
                  <Grid container item xs={1} justifyContent="center">
                    <IconButton
                      onClick={() => removeDestino(arrayHelpers, index)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  </Grid>
                </Tooltip>
              ) : null}
            </Fragment>
          ))}
          {multiplasEntregas && isLastPerna ? (
            <Grid item xs={3}>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => addDestino(arrayHelpers)}
                startIcon={<AddIcon />}
              >
                Adicionar Entrega
              </Button>
            </Grid>
          ) : null}
        </Grid>
      )}
    />
  )
}
