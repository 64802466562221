import { ProgramacaoDetail } from '../types'

export const getPreviousQuantidadePrevistaForLote = (
  entity: ProgramacaoDetail,
  loteId: string
) => {
  return (
    entity.LoteToProgramacao.find(l => l.loteId === loteId)
      ?.quantidadePrevista ?? 0
  )
}
