export enum resourceTabela {
  CIDADES = 'cidades',
  LOTES = 'lotes',
  PROGRAMACOES = 'programacoes',
  CENTROS_CUSTO = 'centros_custo',
  AGRUPADORES_PRODUTOS = 'agrupadores_produtos',
  CONFIGURACOES = 'configuracoes',
  CONTRATOS = 'contratos',
  COTACAO = 'cotacao',
  ESTADOS = 'estados',
  GRUPOS_NEGOCIADORES = 'grupos_negociadores',
  LOCAIS = 'locais',
  MODALIDADES = 'modalidades',
  NEGOCIACAO_NAO_FECHADA = 'negociacao_nao_fechada',
  PESSOAS = 'pessoas',
  PAISES = 'paises',
  PRODUTOS = 'produtos',
  UNIDADE_NEGOCIO = 'unidade_negocio',
  VALIDAR_LOTES = 'validar_lotes',
  RELATORIOS_INTERNOS = 'relatorios_internos'
}
