import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const IS_BUNDLED = process.env.NODE_ENV === 'production'
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || ''
const SENTRY_ALLOWED_ENVS = ['homolog', 'production']

if (IS_BUNDLED && SENTRY_ALLOWED_ENVS.includes(ENVIRONMENT)) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: ENVIRONMENT,
    tracesSampleRate: 1.0
  })
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
