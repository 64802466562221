import Filter from '@elentari/components-eve/components/Filter'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import Grid from '@material-ui/core/Grid'
import * as dateFns from 'date-fns'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import { menuPropsMultipleSelect } from '../options'
import { especificoOptions, tiposCarga, unidades } from './types'

const schema = yup.object().shape({})

export type ProdutosQueryFilter = {
  createdAt?: { gt: string }
  [key: string]: any
}

export const ProdutoFilter = () => {
  const { clear, push, initialValues } = useQueryParams<ProdutosQueryFilter>()

  const handleSubmitFilter = (values: Record<string, any>) => {
    const filters = values

    if (!values.unidade) {
      delete filters.unidade
    }
    if (!values.tipoCarga) {
      delete filters.tipoCarga
    }

    clear()
    push({
      ...filters,
      agrupadorProdutosId: filters.agrupadorProdutosId?.value
    })
  }

  const format = (initialValues: ProdutosQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField fullWidth type="number" name="codigo" label="Código" />
        </Grid>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            type="text"
            name="codigoNCM"
            label="Código NCM"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField fullWidth type="text" name="nome" label="Nome" />
        </Grid>
        <Grid item xs>
          <RFFAsyncSearchInput
            name="agrupadorProdutosId"
            label="Agrupador Produtos"
            url="/agrupadores-produtos"
            labelField="nome"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            fullWidth
            name="unidade"
            label="Unidade de Medida"
            items={unidades}
            MenuProps={menuPropsMultipleSelect}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            fullWidth
            name="tipoCarga"
            label="Tipo de Carga"
            items={tiposCarga}
            MenuProps={menuPropsMultipleSelect}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            fullWidth
            name="especifico"
            label="Específico"
            items={especificoOptions}
            MenuProps={menuPropsMultipleSelect}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
