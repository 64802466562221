export interface Produto {
  id?: string
  codigo: number
  nome: string
  unidade: Unidade
  tipoCarga: TipoCarga
  agrupadorProdutosId: string
  especifico: boolean
  codigoNCM: string
}

export interface ProdutoDetail extends Produto {
  id: string
  AgrupadorProdutos?: {
    nome: string
  }
}

export interface ProdutoSave {
  id?: string
  nome: string
  unidade: Unidade
  tipoCarga: TipoCarga
  agrupadorProdutosId: string
  especifico: boolean
  codigoNCM: string
}

export const Unidades = {
  KG: 'Quilograma (KG)',
  LT: 'Litros (LT)',
  UN: 'Unidade (UN)',
  FD: 'Fardos (FD)',
  BB: 'BigBag (BAG)',
  TON: 'Tonelada (TON)',
  SC: 'Sacas  (SC)'
}

export const TiposCarga = {
  GRANEL: 'Granel',
  CUBICA: 'Cúbica',
  FRIGORIFICA: 'Frigorífica',
  SECA: 'Seca',
  VIVA: 'Viva',
  INDIVISIVEL: 'Indivisível',
  PERIGOSA: 'Perigosa'
}

export type Unidade = 'KG' | 'LT' | 'UN' | 'FD' | 'BB' | 'TON' | 'SC'
export type TipoCarga =
  | 'GRANEL'
  | 'CUBICA'
  | 'FRIGORIFICA'
  | 'SECA'
  | 'VIVA'
  | 'INDIVISIVEL'
  | 'PERIGOSA'

export const unidades = Object.entries(Unidades).map(([key, value]) => {
  return { value: key, label: value }
})

export const tiposCarga = Object.entries(TiposCarga).map(([key, value]) => {
  return { value: key, label: value }
})

export const especificoOptions = [
  { value: 'true', label: 'Sim' },
  { value: 'false', label: 'Não' }
]
