import { Grid } from '@material-ui/core'
import { CheckboxInput } from 'src/packages/g10-components/CheckboxInput'

interface Props {
  prefix?: string
}

export const DadosProduto = (props: Props) => {
  const prefix = props.prefix ? `config.${props.prefix}` : 'config'

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.produto.codigo`} label="Código" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.produto.nome`} label="Nome" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.produto.codigoNcm`}
          label="Código NCM"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.produto.unidade`} label="Unidade" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.produto.tipoCarga`}
          label="Tipo Carga"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.produto.agrupadorProdutosNome`}
          label="Agrupador de Produtos"
        />
      </Grid>
    </Grid>
  )
}
