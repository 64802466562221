import { useFormikContext } from 'formik'
import { useCallback, useState } from 'react'
import { TotalNegociacao } from 'src/modules/lotes/types'
import snackbarStore from 'src/stores/snackbar'
import { CalcularValoresProgramacaoDtoAdapter } from '../adapters/CalcularValoresProgramacaoDtoAdapter'
import { LoteProgramacaoFormData, ProgramacaoFormData } from '../types/formData'
import { getTotalProgramacao } from './programacaoRepository'

export const useCalcularResumoValores = () => {
  const [loading, setLoading] = useState(false)
  const formik = useFormikContext<ProgramacaoFormData>()

  const setValuesIntoForm = useCallback(
    (resumoValoresPrefix: string, resumoValores: TotalNegociacao) => {
      formik.setFieldValue(
        `${resumoValoresPrefix}.freteEmpresa`,
        resumoValores.freteEmpresa
      )
      formik.setFieldValue(
        `${resumoValoresPrefix}.freteAgregado`,
        resumoValores.freteAgregado
      )
      formik.setFieldValue(
        `${resumoValoresPrefix}.freteSubcontratadoPj`,
        resumoValores.freteSubcontratadoPJ
      )
      formik.setFieldValue(
        `${resumoValoresPrefix}.freteSubcontratadoPf`,
        resumoValores.freteSubcontratadoPF
      )
      formik.setFieldValue(
        `${resumoValoresPrefix}.margemGrupo`,
        resumoValores.margemGrupo
      )
      formik.setFieldValue(
        `${resumoValoresPrefix}.freteGrupo`,
        resumoValores.freteGrupo
      )
      formik.setFieldValue(
        `${resumoValoresPrefix}.valorPedagio`,
        resumoValores.valorPedagio
      )
      formik.setFieldValue(
        `${resumoValoresPrefix}.aliquotaIcmsReal`,
        resumoValores.aliquotaICMS
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const calcularResumoValores = useCallback(
    async (lote: LoteProgramacaoFormData) => {
      const adapter = new CalcularValoresProgramacaoDtoAdapter()
      const modalidadeTransportador = formik.values.modalidadeTransportador
      const data = adapter.adapt(lote, modalidadeTransportador)

      setLoading(true)
      const response = await getTotalProgramacao(data)
      setLoading(false)

      if (!response.ok) {
        return snackbarStore.setMessage(
          response.data?.message ??
            'Erro ao obter resumo de valores da Programação'
        )
      }

      const resumoValoresPrefix = `lotes.${lote.id}.resumoValores`
      setValuesIntoForm(resumoValoresPrefix, response.data!)
    },
    [formik.values.modalidadeTransportador, setValuesIntoForm]
  )

  return {
    calcularResumoValores,
    loading
  }
}
