import { Divider, Grid, Tooltip, Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { translateKey, translateValue } from '../logTranslator'

export interface UpdateViewProps {
  field: string
  oldValue: string
  newValue: string
  isLast: boolean
}

export default function UpdateView(props: UpdateViewProps) {
  const { field, oldValue, newValue, isLast } = props

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography color="primary" style={{ fontWeight: 'bold' }}>
            {translateKey(field)}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Typography>{translateValue(field, oldValue)}</Typography>
            </Grid>

            <Grid
              item
              xs
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Tooltip title="Foi alterado para">
                <ArrowForwardIcon color="action" />
              </Tooltip>
            </Grid>

            <Grid
              item
              xs={5}
              style={{
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Typography>{translateValue(field, newValue)}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {!isLast && <Divider style={{ marginTop: 16 }} />}
    </>
  )
}
