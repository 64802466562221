import { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useField, useForm } from 'react-final-form'
import { apiSauceInstance } from '../../services/api'
import {
  Grid,
  InputBaseComponentProps,
  InputLabelProps,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import debounce from 'lodash/debounce'

interface Value {
  name: string
  id: string
  [key: string]: any
}
interface Props {
  altValue?: string
  endAdornment?: React.ReactNode
  url: string
  name: string
  label: string
  filters?: Record<string, any>
  disabled?: boolean
  placeholder?: string
  fullWidth?: boolean
  labelField: string
  InputProps?: InputBaseComponentProps
  InputLabelProps?: InputLabelProps
  searchDisabled?: boolean
  isConsulting?: boolean
  limit?: number
  joinStateAndCity?: boolean
}

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.action.disabled
      }
    }
  }
}))

const RFFAsyncSearchInput: React.FC<Props> = props => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Value[]>([])
  const [initialLoading, setInitialLoading] = useState(false)
  const { input, meta } = useField(props.name)
  const form = useForm()
  const [loading, setLoading] = useState(false)
  const value = typeof input.value === 'string' ? null : input.value
  const classes = useStyles()

  function getError(error?: string | { name: string; value: string }) {
    if (!error) {
      return ''
    }

    if (typeof error === 'string') {
      return error
    } else {
      return error?.value
    }
  }

  function formatNameJoinStateAndCity(cidade: any): string {
    if (!props.joinStateAndCity) return cidade[props.labelField]
    return `${cidade[props.labelField]} - ${cidade.Estado.sigla}`
  }

  async function handleSearchOneFromApi(idFromEntity: string): Promise<void> {
    setInitialLoading(true)
    const response = await apiSauceInstance.get<any>(
      `${props.url}/${idFromEntity}`
    )
    const data = response.data
    setInitialLoading(false)

    return form.getFieldState(props.name)?.change({
      name: formatNameJoinStateAndCity(data),
      value: data.id
    })
  }

  async function handleSearch(search?: string): Promise<void> {
    setLoading(true)
    const response = await apiSauceInstance.get<any>(props.url, {
      [props.labelField]: search,
      limit: props.limit ?? 10
    })
    setLoading(false)

    setOptions(
      response.data?.edges?.map((currentValue: any) => {
        return {
          name: formatNameJoinStateAndCity(currentValue.node),
          value: currentValue.node.id
        }
      }) ?? []
    )
  }

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  useEffect(() => {
    if (typeof input.value === 'string' && input.value) {
      if (props.disabled && props.searchDisabled) {
        handleSearchOneFromApi(input.value)
        return
      }
      !props.disabled && handleSearchOneFromApi(input.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value, props.disabled])

  return (
    <>
      <Autocomplete
        {...props}
        open={open}
        disabled={props.disabled}
        onOpen={() => {
          setOpen(true)
          !props.disabled && handleSearch()
        }}
        onClose={() => setOpen(false)}
        onChange={(e, value) => {
          return form.getFieldState(props.name)?.change(value)
        }}
        onInputChange={debounce(
          (_e, value) => !props.disabled && handleSearch(value),
          700
        )}
        onBlur={input.onBlur}
        getOptionSelected={(option, value) => option.name === value?.name}
        getOptionLabel={option => option.name}
        options={options}
        loading={loading}
        fullWidth={props.fullWidth}
        value={value}
        clearText="Limpar"
        closeText="Fechar"
        loadingText="Carregando..."
        noOptionsText="Sem opções"
        openText="Abrir"
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderInput={params => (
          <TextField
            {...params}
            error={meta.touched && Boolean(getError(meta.error))}
            helperText={meta.touched && getError(meta.error)}
            label={props.label}
            name={props.name}
            variant={'outlined'}
            placeholder={props.placeholder}
            className={classes.input}
            InputLabelProps={{
              ...props.InputLabelProps,
              disabled: props.disabled ? !props.isConsulting : false
            }}
            InputProps={{
              ...params.InputProps,
              disabled: props.disabled ? !props.isConsulting : false,
              endAdornment: (
                <>
                  {open && loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      {initialLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
    </>
  )
}

export default RFFAsyncSearchInput
