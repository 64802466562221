import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { EstadosDetail } from '../types'

class EstadosStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  estados = new ApisauceDataStore<EstadosDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/estados',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
export const estadosStore = new EstadosStore()
export default EstadosStore
