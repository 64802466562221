import MaterialAutocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import TextField from '@elentari/components-eve/components/TextField'
import { FilterOptionsState } from '@material-ui/lab/useAutocomplete'

import { Option } from '../../hooks/useAutoComplete'

interface Props {
  label: string
  options: Option[]
  allowNew?: boolean
  meta?: {
    touched?: any
    error?: any
  }
  clearText?: string
  closeText?: string
  loadingText?: string
  [k: string]: any
}

const filter = createFilterOptions()

const sanitize = (data: any) => {
  if (typeof data === 'object') {
    data = Object.values(data).join('')
  }
  return data
}
export function AutoComplete({
  label,
  options,
  allowNew = false,
  meta,
  clearText,
  closeText,
  loadingText,
  ...rest
}: Props) {
  const filterOptions = (
    options: Option[],
    params: FilterOptionsState<any>
  ) => {
    const filtered = filter(options, params)

    if (allowNew && params.inputValue !== '') {
      filtered.push({
        _new: true,
        _inputValue: params.inputValue,
        label: `Adicionar "${params.inputValue}"`
      })
    }

    return filtered as Option[]
  }

  return (
    <MaterialAutocomplete
      options={options}
      noOptionsText={'Sem opções'}
      clearText={clearText ?? 'Limpar'}
      closeText={closeText ?? 'Fechar'}
      loadingText={loadingText ?? 'Carregando...'}
      clearOnEscape
      filterOptions={filterOptions}
      renderInput={params => (
        <TextField
          {...params}
          label={label}
          helperText={meta?.touched && sanitize(meta?.error)}
          error={rest.error || (!!meta?.error && meta?.touched)}
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
      getOptionLabel={option => option.name}
      {...rest}
    />
  )
}
