import ListTile from './ListTile'
import userStore from 'src/stores/user'

const UserAvatar = () => {
  function getUserName() {
    if (userStore.data) {
      return userStore.data.name
    } else {
      return ''
    }
  }

  return <ListTile logout={userStore.logout} name={getUserName()} />
}

export default UserAvatar
