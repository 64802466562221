import React from 'react'

export function handlePasteIntegerOnly(
  e: React.ClipboardEvent<HTMLDivElement>,
  minValue?: number
) {
  const pastedValue = e.clipboardData.getData('text')
  const formattedPastedValue = pastedValue.replace(/\./g, '').replace(',', '.')

  const invalidValue =
    minValue !== undefined && Number(formattedPastedValue) < minValue

  if (
    formattedPastedValue.includes('.') ||
    isNaN(Number(formattedPastedValue)) ||
    invalidValue
  )
    e.preventDefault()
}
