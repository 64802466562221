import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useFormikContext } from 'formik'
import SwitchInput from 'src/components/SwitchInput'
import PernaInput from '../../../components/PernaInput'
import { OrigemDestinoContrato } from '../components/OrigemDestinoContrato'
import { LotesFormData, TipoContratacao } from '../types'

export const InformacoesColetaEntrega = () => {
  const { values } = useFormikContext<LotesFormData>()

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={values.tipoContratacao === TipoContratacao.CONTRATO ? 6 : 12}
      >
        <Typography variant="h5">Informações de Coleta e Entrega</Typography>
      </Grid>
      {values.tipoContratacao === TipoContratacao.CONTRATO && (
        <Grid item xs={12} sm={6}>
          <SwitchInput
            name="permiteTrocarNota"
            label="Permite Troca de Nota Fiscal"
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {values.tipoContratacao === TipoContratacao.CONTRATO ? (
          <OrigemDestinoContrato />
        ) : (
          <PernaInput name="pernas" />
        )}
      </Grid>
    </Grid>
  )
}
