import { useFormikContext } from 'formik'
import { useCallback, useMemo } from 'react'
import { LoteList, TipoContratacao, TiposLote } from 'src/modules/lotes/types'
import snackbarStore from 'src/stores/snackbar'
import { LoteProgramacaoFormData, ProgramacaoFormData } from '../types/formData'
import { reduceSelectedLotes } from '../util/reduceSelectedLotes'

type NullableLoteList = { [key in keyof LoteList]: LoteList[key] | null }

const LOTES_FIELD_NAME = 'lotes'

export const useSelecaoLotes = () => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const selectedLotes = useMemo(
    () => Object.values(formik.values.lotes),
    [formik.values.lotes]
  )

  const isLoteSelected = useCallback(
    (loteId: string) => {
      return selectedLotes.some(lote => lote.id === loteId)
    },
    [selectedLotes]
  )

  const addLoteMetadataToFormData = useCallback(
    ({
      tipoContratacao,
      tipoLote,
      Cliente
    }: Pick<NullableLoteList, 'tipoContratacao' | 'tipoLote' | 'Cliente'>) => {
      formik.setFieldValue('tipoContratacaoLotesSelecionados', tipoContratacao)
      formik.setFieldValue('tipoLotesSelecionados', tipoLote)
      formik.setFieldValue(
        'grupoNegociadorLotesSelecionados',
        Cliente && {
          id: Cliente.id,
          descricao: Cliente.nome
        }
      )
    },
    [formik]
  )

  const handleAddLote = useCallback(
    (lote: LoteList) => {
      const isTipoContratacaoValid = (tipoContratacao: TipoContratacao) => {
        if (!selectedLotes.length) {
          return true
        }

        return (
          formik.values.tipoContratacaoLotesSelecionados === tipoContratacao
        )
      }

      const isTipoLoteValid = (tipoLote: TiposLote) => {
        if (!selectedLotes.length) {
          return true
        }

        return formik.values.tipoLotesSelecionados === tipoLote
      }

      const isGrupoNegociadorValid = (grupoNegociadorId: string) => {
        if (!selectedLotes.length) {
          return true
        }

        return (
          formik.values.grupoNegociadorLotesSelecionados?.id ===
          grupoNegociadorId
        )
      }

      if (!isTipoContratacaoValid(lote.tipoContratacao as TipoContratacao)) {
        return snackbarStore.setMessage(
          'Não é possível selecionar Lotes com Tipo Contratação diferente.'
        )
      }

      if (!isTipoLoteValid(lote.tipoLote as TiposLote)) {
        return snackbarStore.setMessage(
          `Não é possível selecionar Lotes com Tipo Lote diferente.`
        )
      }

      if (!isGrupoNegociadorValid(lote.Cliente.id)) {
        return snackbarStore.setMessage(
          'Não é possível selecionar Lotes com Grupo Negociador diferente.'
        )
      }

      const hasSelectedLotes = selectedLotes.length > 0

      if (!hasSelectedLotes) {
        addLoteMetadataToFormData(lote)
      }

      const adaptedNewSelectedLote: Record<string, LoteProgramacaoFormData> = {
        [lote.id]: {
          ...lote,
          quantidadePrevista: 0,
          valorFreteAcordado: 0,
          possuiTrocaNotaFiscal: false,
          multiplasEntregas: false,
          pernas: [
            {
              localCarregamento: lote.localCarregamento || '',
              estadoOrigem: '' as any,
              cidadeOrigem: '' as any,
              destinos: [
                {
                  localEntrega: '',
                  estadoDestino: '' as any,
                  cidadeDestino: '' as any,
                  quantidade: '' as any
                }
              ],
              freteEmpresa: '' as any,
              valorPedagio: 0,
              aliquotaIcms: '' as any,
              freteGrupoDiferente: false,
              freteGrupo: '' as any,
              freteSubcontratado: '' as any
            }
          ],
          produtoId: '' as any,
          resumoValores: {
            freteEmpresa: 0,
            freteAgregado: 0,
            freteSubcontratadoPj: 0,
            freteSubcontratadoPf: 0,
            margemGrupo: 0,
            freteGrupo: 0,
            valorPedagio: 0,
            aliquotaIcmsReal: 0
          }
        }
      }

      formik.setFieldValue(LOTES_FIELD_NAME, {
        ...reduceSelectedLotes(selectedLotes),
        ...adaptedNewSelectedLote
      })
    },
    [addLoteMetadataToFormData, formik, selectedLotes]
  )

  const handleRemoveLote = (loteId: string) => {
    const newLotesSelecionados = selectedLotes.filter(
      lote => lote.id !== loteId
    )
    const reducedLotesSelecionados = reduceSelectedLotes(newLotesSelecionados)

    if (newLotesSelecionados.length === 0) {
      addLoteMetadataToFormData({
        tipoContratacao: null,
        tipoLote: null,
        Cliente: null
      })
    }

    formik.setFieldValue(LOTES_FIELD_NAME, reducedLotesSelecionados)
  }

  return {
    selectedLotes,
    handleAddLote,
    handleRemoveLote,
    isLoteSelected
  }
}
