import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import programacoesStore from '../../store/store'
import { ProgramacaoFormData } from '../../types/formData'

interface Props {
  loteId: string
  pernaIndex: number
}

export const FreteGrupoDiferenteListener = ({ loteId, pernaIndex }: Props) => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const dep = formik.values.lotes[loteId].pernas[pernaIndex].freteGrupoDiferente

  useEffect(() => {
    programacoesStore.setHasToReevaluateResumoValores(true)
  }, [dep])

  return null
}
