import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import NoResultsTable from 'src/components/NoResultsTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import Spacer from '../../components/Spacer'
import { EstadoColumns } from './EstadosColumns'
import { estadosStore } from './stores'
import { EstadosDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const EstadosTable = observer(({ onDelete, authorization }: Props) => {
  const { pathname } = useLocation()

  const KEY_TABLE = resourceTabela.ESTADOS
  useConfigTable(KEY_TABLE)
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !estadosStore.estados.isLoading &&
    tableState &&
    estadosStore.estados.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => EstadoColumns({ onDelete, authorization }),
    [onDelete, authorization]
  )

  const rows = estadosStore.estados.list.map(
    ({ ...estados }: EstadosDetail) => {
      return {
        ...estados,
        pais: estados?.Pais?.nome
      }
    }
  )

  const sortMapper = new Map([['pais', 'Pais.nome']])

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={estadosStore.estados.isLoading}
          totalCount={estadosStore.estados.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow={false}
              linkWithRedirectClickRow={pathname}
              data={rows}
              sortMapper={sortMapper}
            />
          ) : (
            <NoResultsTable loading={estadosStore.estados.isLoading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={pathname} authorization={authorization} />
    </>
  )
})
