import { IColumn } from 'src/components/BaseTable'

export const ConfiguracaoesColumns = (): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo'
    },
    {
      Header: 'Chave',
      accessor: 'chave'
    },
    {
      Header: 'Valor',
      accessor: 'valor'
    },
    {
      Header: 'Tipo',
      accessor: 'tipo'
    }
  ]
}
