import { Button, Grid, Typography } from '@material-ui/core'
import { ConsultaField } from 'src/components/ConsultaField'
import Spacer from 'src/components/Spacer'
import { useDefaultGoBack } from 'src/hooks/useDefaultGoBack'
import { TipoContratacao } from 'src/modules/lotes/types'
import { formatEnumFromDB } from 'src/utils/formatEnum'
import { formatUsername } from 'src/utils/formatUsername'
import { formatCurrency, formatDate, formatTime } from 'src/utils/formatters'
import { useProgramacao } from '../hooks/useProgramacao'
import { LoteProgramacaoSnapshot } from '../types'
import { AutorizacaoCarregamento } from './autorizacao-carregamento/AutorizacaoCarregamento'
import { formatCategoriaTransportador } from '../util/formatCategoriaTransportador'

export const ConsultaProgramacao = () => {
  const [programacaoState] = useProgramacao()
  const handleGoBack = useDefaultGoBack()

  const getDestino = (lote: LoteProgramacaoSnapshot) => {
    if (lote.LoteProgramacaoDestinoSnapshot.length === 0) {
      return 'Não informado'
    }

    if (lote.LoteProgramacaoDestinoSnapshot.length > 1) {
      return 'Múltiplos'
    }

    return `${lote.LoteProgramacaoDestinoSnapshot[0].cidadeDestino} / ${lote.LoteProgramacaoDestinoSnapshot[0].siglaEstadoDestino}`
  }

  return (
    <Grid item xs={12}>
      {programacaoState.tag === 'with-data' ? (
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Dados da Programação
            </Typography>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <AutorizacaoCarregamento lotes={programacaoState.entity.Lotes} />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="CENTRO DE CUSTO"
              subtitle={programacaoState.entity.CentroCusto?.descricao}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="EMBARCADOR"
              subtitle={
                programacaoState.entity.embarcador
                  ? formatUsername(programacaoState.entity.embarcador)
                  : 'N/A'
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="DATA DE CARREGAMENTO"
              subtitle={`${formatDate(
                programacaoState.entity.dataCarregamento
              )} ${formatTime(programacaoState.entity.horaCarregamento)}`}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="DATA DE DESCARREGAMENTO"
              subtitle={`${formatDate(
                programacaoState.entity.dataDescarregamento
              )} ${formatTime(programacaoState.entity.horaDescarregamento)}`}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="NOME DO RESPONSÁVEL"
              subtitle={programacaoState.entity.nomeResponsavel || 'N/A'}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="TELEFONE DO RESPONSÁVEL"
              subtitle={programacaoState.entity.telefoneResponsavel || 'N/A'}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="CRIADO POR"
              subtitle={
                programacaoState.entity.emailCriador
                  ? formatUsername(programacaoState.entity.emailCriador)
                  : 'N/A'
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="Categoria"
              subtitle={
                programacaoState.entity.categoriaTransportador
                  ? formatCategoriaTransportador(
                      programacaoState.entity.categoriaTransportador
                    )
                  : 'N/A'
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Dados dos Lotes
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {programacaoState.entity.LoteProgramacaoSnapshot.map(loteSnap => {
              const loteToProgramacao =
                programacaoState.entity.LoteToProgramacao.filter(
                  ltp => ltp.loteId === loteSnap.loteId
                )

              const lote = programacaoState.entity.Lotes.find(
                lote => lote.id === loteSnap.loteId
              )

              if (!lote) return null

              return (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      Lote {lote.codigo}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="CENTRO DE CUSTO"
                      subtitle={loteSnap.centroCustoDescricao}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="DATA INICIAL DE RETIRADA"
                      subtitle={formatDate(lote.dataInicio)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="DATA LIMITE DE RETIRADA"
                      subtitle={formatDate(lote.dataTermino)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="PRODUTO"
                      subtitle={loteSnap.produtoNome || 'Não Informado'}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="ORIGEM"
                      subtitle={`${loteSnap.cidadeOrigem} / ${loteSnap.siglaEstadoOrigem}`}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="DESTINO"
                      subtitle={getDestino(loteSnap)}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="QUANTIDADE PREVISTA"
                      subtitle={loteToProgramacao
                        .reduce((acc, curr) => acc + curr.quantidadePrevista, 0)
                        .toLocaleString('pt-br')}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <ConsultaField
                      title="VALOR FRETE ACORDADO"
                      subtitle={formatCurrency(
                        loteToProgramacao.reduce(
                          (acc, curr) => acc + curr.valorFreteAcordado,
                          0
                        )
                      )}
                    />
                  </Grid>
                  {loteSnap.tipoContratacao === TipoContratacao.SPOT ? (
                    <Grid item xs={12} md={3}>
                      <ConsultaField
                        title="FRETE EMPRESA"
                        subtitle={formatCurrency(
                          loteToProgramacao.reduce(
                            (acc, curr) => acc + curr.freteEmpresa,
                            0
                          )
                        )}
                      />
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <Spacer />
                  </Grid>
                </Grid>
              )
            })}
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Dados da Composição
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="MOTORISTA"
              subtitle={programacaoState.entity.motorista.toUpperCase()}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="CPF DO MOTORISTA"
              subtitle={programacaoState.entity.cpfMotorista}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="TELEFONE DO MOTORISTA"
              subtitle={programacaoState.entity.telefoneMotorista || 'N/A'}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="CAPACIDADE DE CARGA"
              subtitle={programacaoState.entity.capacidadeCargaVeiculo}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <ConsultaField
              title="PLACA PRINCIPAL"
              subtitle={programacaoState.entity.placaPrincipal}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="PLACA OPCIONAL (1)"
              subtitle={
                programacaoState.entity.placa_1
                  ? programacaoState.entity.placa_1
                  : 'N/A'
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="PLACA OPCIONAL (2)"
              subtitle={
                programacaoState.entity.placa_2
                  ? programacaoState.entity.placa_2
                  : 'N/A'
              }
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <ConsultaField
              title="PLACA OPCIONAL (3)"
              subtitle={
                programacaoState.entity.placa_3
                  ? programacaoState.entity.placa_3
                  : 'N/A'
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Dados Adicionais
            </Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <ConsultaField
              title="OBSERVAÇÕES"
              subtitle={
                programacaoState.entity.observacoes
                  ? programacaoState.entity.observacoes
                  : 'N/A'
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="TIPO DE PAGAMENTO"
              subtitle={
                programacaoState.entity.tipoPagamento
                  ? formatEnumFromDB(programacaoState.entity.tipoPagamento)
                  : 'N/A'
              }
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <ConsultaField
              title="NÚMERO DO CARTÃO"
              subtitle={programacaoState.entity.numeroCartao || 'N/A'}
            />
          </Grid>
        </Grid>
      ) : null}

      <Grid
        justifyContent="flex-end"
        item
        container
        spacing={4}
        style={{ marginTop: 8 }}
      >
        <Grid item xs={12}>
          <Grid justifyContent="flex-end" container spacing={2}>
            <Grid item style={{ width: 160 }}>
              <Button
                fullWidth
                type="button"
                variant="outlined"
                onClick={handleGoBack}
              >
                Voltar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
