import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Formik } from 'formik'
import { useLote } from 'src/modules/lotes/hooks/useLote'
import { alterarDados } from '../../modules/lotes/hooks/lotesRepository'
import {
  AlterarDadosDto,
  AlterarDadosFormData,
  prioridades,
  statusLote
} from '../../modules/lotes/types'
import SelectInput from '../Formik/Forms/SelectInput'
import { UserByScopeAndAbilityInput } from '../Formik/Forms/UserByScopeAndAbility'

interface AlterarDadosDialogProps {
  open: boolean
  handleClose: () => void
  id: string
  centroCustoId: {
    name: string
    value: string
  }
}

const initialValues: AlterarDadosFormData = {
  status: '',
  prioridade: null,
  analistaResponsavel: null
}

function AlterarDadosDialog({
  open,
  handleClose,
  centroCustoId,
  id
}: AlterarDadosDialogProps) {
  const [, loteActions] = useLote()

  const handlePrimaryAction = () => {
    handleClose()
  }

  const { saveWithoutRedirect } = useEntitySaver<AlterarDadosDto>(
    async data => {
      const response = await alterarDados(id, data)

      if (response.ok) {
        loteActions.fetch(id)
      }

      return response
    }
  )

  const handleSubmit = async (data: AlterarDadosFormData) => {
    const formattedData = {
      status: data.status || undefined,
      analistaResponsavel: data.analistaResponsavel?.value ?? undefined,
      prioridade: data.prioridade || undefined
    }

    await saveWithoutRedirect(formattedData)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Alterar Dados</DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <SelectInput
                    name="status"
                    label="Status do Lote"
                    options={statusLote}
                  />
                </Grid>

                <Grid item xs={12} sm={12}>
                  <SelectInput
                    name="prioridade"
                    label="Prioridade"
                    options={prioridades}
                  />
                </Grid>

                <Grid item xs={12} sm={12} alignItems="center">
                  <UserByScopeAndAbilityInput
                    name="analistaResponsavel"
                    label="Email Responsável"
                    action="read"
                    subject="Validação de Lotes"
                    lotacoes={centroCustoId && [centroCustoId.value]}
                    labelField="name"
                  />
                </Grid>

                <Grid container justifyContent="flex-end">
                  <DialogActions>
                    <Button onClick={handleClose} color="default" autoFocus>
                      Voltar
                    </Button>
                    <Button
                      onClick={handlePrimaryAction}
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      autoFocus
                    >
                      Confirmar
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default AlterarDadosDialog
