import { ApisauceInstance } from 'apisauce'
import { makeAutoObservable, observable, runInAction } from 'mobx'
import { stringify } from 'qs'
import * as R from 'ramda'

type EditVerb = 'put' | 'patch'

interface HttpClientOptions {
  path: `/${string}`
  limit?: number
  skip?: number
  countField?: string
  resultsField?: string
  useNodes?: boolean
  logging?: boolean
  editVerb?: EditVerb
}

interface ApiResponse<T> extends Record<string, unknown> {
  results: Array<Edge<T> | T>
}

interface Edge<T> {
  node: T
}

export default class ApisauceDataStore<T extends object> {
  constructor(api: ApisauceInstance, options: HttpClientOptions) {
    this._path = options.path
    this._api = api
    options.limit && (this._limit = options.limit)
    options.countField && (this._countField = options.countField)
    options.resultsField && (this._resultsField = options.resultsField)
    options.useNodes && (this._useNodes = options.useNodes)
    options.logging && (this._logging = options.logging)
    options.editVerb && (this._editVerb = options.editVerb)

    makeAutoObservable<ApisauceDataStore<T>, '_api'>(
      this,
      { _api: false },
      { autoBind: true }
    )
  }

  private _logging = false
  private _path = ''
  private _editVerb: EditVerb = 'put'
  private _api = {} as ApisauceInstance

  private _loading = false
  private _count = 0
  private _countField = 'totalCount'
  private _resultsField = 'results'
  private _useNodes = false
  private _page = 1
  private _limit = 10
  private _filters: Record<string, unknown> = {}

  private _entity: T | null = null
  private _list = observable<T>([])

  private log(...args: unknown[]) {
    this._logging && console.log('%c[Fetchx]', 'font-weight: bold;', ...args)
  }

  setLoading(bool: boolean) {
    this._loading = bool
  }

  setPage(page: number) {
    this._page = page
  }

  setFilters(filters: Record<string, unknown>) {
    this.log('Changing filters...')

    this._filters = filters
    this.populate()
  }

  resetEntity() {
    this.log('Resetting entity...')

    this._entity = null
  }

  resetList() {
    this.log('Resetting list...')

    this._list.clear()
  }

  async fetch() {
    this.log(`Fetching list at ${this._path}`)

    this.setLoading(true)
    const response = await this._api?.get(this._path)
    this.setLoading(false)

    if (response.ok) {
      return response.data
    }
  }

  async fetchEntity(id: string | number) {
    this.log('Fetching entity...')

    this.setLoading(true)
    const response = await this._api.get<T>(`${this._path}/${id}`)
    this.setLoading(false)

    if (response.ok) {
      runInAction(() => {
        this._entity = response.data!
      })
      return response.data
    }
  }

  async saveEntity(data: T & { id?: string | number }) {
    this.setLoading(true)

    if (data.hasOwnProperty('id')) {
      const url = `${this._path}/${data.id!}`
      const response = await this._api[this._editVerb](url, data)
      this.setLoading(false)

      return response.data
    } else {
      const response = await this._api.post(this._path, data)
      this.setLoading(false)

      return response.data
    }
  }

  async populate() {
    this.log('Fetching list and populating state...')

    const filters = R.reject(R.anyPass([R.isEmpty, R.isNil]))(this._filters)

    if (filters?.limit) {
      this._limit = Number(filters?.limit)
      delete filters?.limit
    } else {
      this._limit = 10
    }

    this.setLoading(true)

    const parsedFilters = stringify(filters)

    const response = await this._api.get<ApiResponse<T>>(
      `${this._path}?${parsedFilters}`,
      {
        limit: this._limit,
        skip: this._limit * (this._page - 1)
      }
    )
    this.setLoading(false)

    if (response.ok) {
      const totalCount = Number(response.data![this._countField])
      let results = response.data![
        this._resultsField
      ] as ApiResponse<T>['results']

      if (isNaN(totalCount)) {
        throw new Error("Count field doesn't exist or it's not a number!")
      }
      if (!Array.isArray(results)) {
        throw new Error('Results field must be an array!')
      }

      if (this._useNodes) {
        this.log('Formatting edges...')
        results = (results as Edge<T>[]).map(result => result.node)
      }

      runInAction(() => {
        this._list.replace(results as T[])
        this._count = totalCount
      })

      return response.data
    }
  }

  get entity() {
    return this._entity
  }

  get list() {
    return this._list
  }

  get filters() {
    return this._filters
  }

  get page() {
    return this._page
  }

  get totalCount() {
    return this._count
  }

  get isLoading() {
    return this._loading
  }

  get limit() {
    return this._limit
  }
}
