import {
  RelatorioInternoLoteFormData,
  RelatorioInternoProgramacaoFormData,
  TipoRelatorioInterno
} from '../types'

export const initialValuesLote: RelatorioInternoLoteFormData = {
  tipoRelatorio: TipoRelatorioInterno.LOTE,
  description: '',
  filters: {
    dataInicioDe: '' as any,
    dataInicioAte: '' as any,
    dataTerminoDe: '' as any,
    dataTerminoAte: '' as any,
    origemId: '' as any,
    destinoId: '' as any,
    centroCustoId: '' as any,
    unidadeNegocioId: '' as any,
    produtoId: '' as any,
    agrupadorProdutosId: '' as any,
    grupoNegociadorId: '' as any,
    status: [],
    emailCriador: '' as any,
    analistaResponsavel: '' as any,
    tipoContratacao: '' as any,
    especie: '' as any,
    descricao: '' as any
  },
  config: {
    codigo: true,
    descricao: true,
    operacaoKMM: true,
    status: true,
    numeroPedido: true,
    freteEmpresa: true,
    freteSubcontratadoPJ: true,
    freteSubcontratadoPF: true,
    quantidadeRetirada: true,
    quantidadeTotal: true,
    dataInicio: true,
    dataTermino: true,
    localCarregamento: false,
    tipoContratacao: true,
    especie: true,
    emailCriador: true,
    analistaResponsavel: true,
    saldo: true,
    previsaoMargem: true,
    centroCusto: {
      codigo: false,
      descricao: false,
      unidadeNegocioDescricao: false
    },
    grupoNegociador: {
      codigo: false,
      nome: false,
      raizCnpj: false
    },
    origem: {
      nome: false,
      estadoNome: false,
      estadoSigla: false
    },
    destino: {
      localDescarregamento: false,
      nome: false,
      estadoNome: false,
      estadoSigla: false
    },
    produto: {
      codigo: false,
      nome: false,
      codigoNcm: false,
      unidade: false,
      tipoCarga: false,
      agrupadorProdutosNome: false
    }
  }
}

export const initialValuesProgramacao: RelatorioInternoProgramacaoFormData = {
  tipoRelatorio: TipoRelatorioInterno.PROGRAMACAO,
  description: '',
  filters: {
    dataCarregamentoDe: '' as any,
    dataCarregamentoAte: '' as any,
    dataDescarregamentoDe: '' as any,
    dataDescarregamentoAte: '' as any,
    placaPrincipal: '' as any,
    grupoNegociadorId: '' as any,
    centroCustoId: '' as any,
    produtoId: '' as any,
    codigoLote: '' as any,
    cpfMotorista: '' as any,
    status: []
  },
  config: {
    codigo: true,
    dataHoraCarregamento: true,
    dataHoraDescarregamento: true,
    capacidadeCargaVeiculo: true,
    observacoes: true,
    status: true,
    nomeResponsavel: true,
    telefoneResponsavel: true,
    motorista: true,
    cpfMotorista: true,
    telefoneMotorista: true,
    placaPrincipal: true,
    placa_1: true,
    placa_2: true,
    placa_3: true,
    emailCriador: true,
    tipoPagamento: true,
    numeroCartao: true,
    embarcador: true,
    quantidadePrevista: true,
    valorFreteAcordado: true,
    categoriaTransportador: true,
    centroCusto: {
      codigo: false,
      descricao: false,
      unidadeNegocioDescricao: false
    },
    lote: {
      ...initialValuesLote.config,
      codigo: false,
      descricao: false,
      operacaoKMM: false,
      status: false,
      numeroPedido: false,
      freteEmpresa: false,
      freteSubcontratadoPJ: false,
      freteSubcontratadoPF: false,
      quantidadeRetirada: false,
      quantidadeTotal: false,
      dataInicio: false,
      dataTermino: false,
      localCarregamento: false,
      tipoContratacao: false,
      especie: false,
      emailCriador: false,
      analistaResponsavel: false,
      saldo: false,
      previsaoMargem: false
    }
  }
}
