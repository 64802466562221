import { makeAutoObservable, runInAction } from 'mobx'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { SelectOption } from '../home/dashboard/filters/AsyncMultipleSelect'
import { Relatorio } from './types'

export interface RelatoriosFilters {
  tipoEmissao: string
  clientes: SelectOption[]
  centrosCusto: SelectOption[]
  dataInicioCarregamento: Nullable<Date>
  dataFimCarregamento: Nullable<Date>
}

class RelatoriosStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  relatoriosResponse: Relatorio = {
    tipoEmissao: '',
    dataEmissao: new Date(),
    pages: []
  }

  buildFilters() {
    const filters: Record<string, unknown> = { ...this.filters }

    filters.centrosCusto = this.filters.centrosCusto.map(item => item.value)
    filters.clientes = this.filters.clientes.map(item => item.value)
    filters.dataInicioCarregamento =
      this.filters.dataInicioCarregamento?.toISOString()
    filters.dataFimCarregamento =
      this.filters.dataFimCarregamento?.toISOString()
    filters.tipoEmissao = this.filters.tipoEmissao

    return filters
  }

  async sync() {
    const filters = this.buildFilters()

    await this.getRelatorios(filters)
  }

  async getRelatorios(filters: Record<string, unknown>) {
    const response = await apiSauceInstance.get<Relatorio>(
      '/relatorios/carregamento',
      filters
    )

    runInAction(() => {
      if (response.ok && response.data) {
        const newRelatorios: Relatorio = {
          tipoEmissao: response.data.tipoEmissao,
          dataEmissao: response.data.dataEmissao,
          pages: response.data.pages
        }
        this.relatoriosResponse = newRelatorios
      } else {
        snackbarStore.setMessage(
          'Ocorreu um erro ao requisitar os dados. Tente novamente mais tarde.'
        )
      }
    })
  }

  filters: RelatoriosFilters = {
    tipoEmissao: 'CLIENTE',
    clientes: [],
    centrosCusto: [],
    dataInicioCarregamento: null,
    dataFimCarregamento: null
  }

  setFilters(filters: RelatoriosFilters) {
    this.filters = filters
    this.sync()
  }

  clearFilters() {
    this.filters = {
      tipoEmissao: 'CLIENTE',
      clientes: [],
      centrosCusto: [],
      dataInicioCarregamento: null,
      dataFimCarregamento: null
    }
  }

  clearResponse() {
    this.relatoriosResponse = {
      tipoEmissao: '',
      dataEmissao: new Date(),
      pages: []
    }
  }
  resetAll() {
    this.clearFilters()
    this.clearResponse()
  }
}

const relatoriosStore = new RelatoriosStore()
export default relatoriosStore
