import {
  BaseTextFieldProps,
  InputBaseComponentProps,
  InputLabelProps,
  InputProps,
  makeStyles,
  TextField
} from '@material-ui/core'
import { useField, useFormikContext } from 'formik'
import { SyntheticEvent, useRef, useState } from 'react'

const useStyles = makeStyles(theme => ({
  inputRemoveArrowsStyle: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      display: 'none'
    }
  }
}))

export interface TextInputProps extends BaseTextFieldProps {
  name: string
  label: string
  uppercase?: boolean
  removeAccentuation?: boolean
  hyperactive?: boolean
  removeArrows?: boolean
  inputProps?: InputBaseComponentProps
  InputProps?: InputProps
  InputLabelProps?: InputLabelProps
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

function TextInput({
  variant = 'outlined',
  uppercase = true,
  removeAccentuation = false,
  hyperactive = false,
  removeArrows = false,
  fullWidth = true,
  type = 'text',
  onChange,
  ...props
}: TextInputProps) {
  const [field, meta, helpers] = useField(props.name)
  const [selection, setSelection] = useState({
    start: 0,
    end: 0,
    isOnChange: false
  })
  const inputRef = useRef<HTMLInputElement>(null)
  const formik = useFormikContext()
  const classes = useStyles()

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = removeAccentuation
      ? e.target.value.normalize('NFD').replace(/\p{Diacritic}/gu, '')
      : e.target.value

    onChange?.(e)

    helpers.setValue(uppercase ? value.toUpperCase() : value)

    if (inputRef.current) {
      setSelection({
        start: inputRef.current.selectionStart || 0,
        end: inputRef.current.selectionEnd || 0,
        isOnChange: true
      })
    }

    if (hyperactive) {
      formik.submitForm()
    }
  }

  const handleSelection = (e: SyntheticEvent<HTMLDivElement, Event>) => {
    if (inputRef.current) {
      if (selection.isOnChange) {
        inputRef.current.selectionStart = selection.start
        inputRef.current.selectionEnd = selection.end

        setSelection(currentSelection => ({
          ...currentSelection,
          isOnChange: false
        }))
      } else {
        const start = inputRef.current.selectionStart || 0
        const end = inputRef.current.selectionEnd || 0

        setSelection({ start, end, isOnChange: false })
      }
    }
  }

  return (
    <TextField
      variant={variant}
      fullWidth={fullWidth}
      {...field}
      {...props}
      onChange={handleChange}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      inputProps={{
        ...props.inputProps,
        ref: inputRef
      }}
      type={removeArrows ? 'number' : type}
      InputProps={{
        ...props.InputProps,
        classes: {
          ...props.InputProps?.classes,
          input: removeArrows
            ? classes.inputRemoveArrowsStyle
            : props.InputProps?.classes?.input
        }
      }}
      onSelect={handleSelection}
    />
  )
}

export default TextInput
