import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
  Typography
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { UploadXlsxInput } from 'src/components/Formik/Forms/UploadXlsxInput'
import Spacer from 'src/components/Spacer'
import yupValidation from 'src/utils/yupValidation'
import { LeituraPlanilhaDialogForm, Props } from './types'
import { schema } from './validation'

export * from './types'

const useStyles = makeStyles({
  invisibleUploadButton: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    borderRadius: 0,
    opacity: 0
  },
  link: {
    color: 'primary'
  }
})

const initialValues: LeituraPlanilhaDialogForm = {
  fileXlsx: null
}

export function LeituraPlanilhaDialog({
  isOpen,
  handleClose,
  disabled,
  handleSubmit,
  title,
  href,
  titlePrimaryButton
}: Props) {
  const classes = useStyles()

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      aria-describedby="form-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>
        {title ?? 'Leitura de planilha para criação de registros'}
      </DialogTitle>
      <DialogContent>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validate={yupValidation(schema)}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Grid container xs={12}>
                <Grid item xs={12}>
                  <UploadXlsxInput name="fileXlsx" />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">
                    Baixe sua planilha template clicando{' '}
                    <Link href={href} className={classes.link}>
                      aqui
                    </Link>
                  </Typography>
                </Grid>
                <Grid xs={12}>
                  <Spacer y={2} />
                </Grid>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      data-testid="salvar"
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={() => handleClose()}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <Grid item style={{ width: 200 }}>
                    <Button
                      data-testid="salvar"
                      fullWidth
                      type="submit"
                      disabled={isSubmitting || disabled}
                      variant="contained"
                      color="primary"
                    >
                      {isSubmitting || disabled ? (
                        <CircularProgress color="inherit" size={24} />
                      ) : (
                        titlePrimaryButton ?? `Criar`
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
