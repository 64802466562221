import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { formatEnumFromDB } from 'src/utils/formatEnum'
import { formatDate } from 'src/utils/formatters'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { formatStatusProgramacao } from '../../utils/formatters/formatStatus'
import { ProgramacaoColumns } from './ProgramacaoColumns'
import programacoesStore from './store/store'
import { ProgramacaoList, categoriaTransportadorOptions } from './types'
import { formatCategoriaTransportador } from './util/formatCategoriaTransportador'
import { formatDateToTimeZone } from 'src/utils/formatters/formatDate'

interface Props {
  onCancel: (id: string) => void
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const ProgramacaoTable = observer(
  ({ onCancel, authorization }: Props) => {
    const { pathname } = useLocation()
    const KEY_TABLE = resourceTabela.PROGRAMACOES
    const tableState = tableStore.getState(KEY_TABLE)

    const isTableReady =
      !programacoesStore.programacoes.isLoading &&
      tableState &&
      programacoesStore.programacoes.list.length

    useConfigTable(KEY_TABLE)

    const columns: Array<Column & IColumn> = useMemo(
      () => ProgramacaoColumns({ onCancel, authorization }),
      [onCancel, authorization]
    )

    const data = programacoesStore.programacoes.list.map(
      ({ ...programacao }: ProgramacaoList) => {
        return {
          ...programacao,
          produto:
            programacao.LoteProgramacaoSnapshot.length > 1
              ? 'Diversos'
              : programacao.LoteProgramacaoSnapshot[0]?.produtoNome,
          centroCusto: programacao.CentroCusto.descricao,
          codigoLote:
            programacao.Lotes.length === 1 ? programacao.Lotes[0].codigo : 'M',
          dataHoraCarregamento: formatDate(
            formatDateToTimeZone(programacao.dataHoraCarregamento)
          ),
          cliente: programacao.Lotes[0]?.Cliente?.nome,
          origem:
            programacao.LoteProgramacaoSnapshot.length === 1
              ? `${programacao.LoteProgramacaoSnapshot[0]?.cidadeOrigem}/${programacao.LoteProgramacaoSnapshot[0]?.siglaEstadoOrigem}`
              : 'Diversos',
          destino:
            programacao.LoteProgramacaoSnapshot.length === 1 &&
            programacao.LoteProgramacaoSnapshot[0]
              ?.LoteProgramacaoDestinoSnapshot.length === 1
              ? `${programacao.LoteProgramacaoSnapshot[0]?.LoteProgramacaoDestinoSnapshot[0]?.cidadeDestino}/
            ${programacao.LoteProgramacaoSnapshot[0]?.LoteProgramacaoDestinoSnapshot[0]?.siglaEstadoDestino}`
              : 'Diversos',
          tipoPagamento: formatEnumFromDB(programacao?.tipoPagamento),
          categoriaTransportador: formatCategoriaTransportador(
            programacao.categoriaTransportador
          )
        }
      }
    )

    const sortMapper = new Map([['centroCusto', 'CentroCusto.descricao']])

    return (
      <>
        <Spacer y={4} />
        <Paper>
          <TableContainer
            loading={programacoesStore.programacoes.isLoading}
            totalCount={programacoesStore.programacoes.totalCount}
          >
            {isTableReady ? (
              <BaseTable
                keyTable={KEY_TABLE}
                columns={columns}
                linkWithRedirectClickRow={pathname}
                data={data}
                sortMapper={sortMapper}
              />
            ) : (
              <NoResultsTable
                loading={programacoesStore.programacoes.isLoading}
              />
            )}
          </TableContainer>
        </Paper>

        <AddRecord path={pathname} authorization={authorization} />
      </>
    )
  }
)
