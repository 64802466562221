import { Button, Grid, IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldArray, FieldArrayRenderProps, useField } from 'formik'
import { formatLabelPerson } from 'src/modules/pessoas/utils'
import { PureAsyncSearchInput } from '../../../components/Formik/Forms/PureAsyncSearchInput'

interface PessoasInputProps {
  name: string
  isGrupoNegociador?: boolean
}

export default function PessoasInput({
  name,
  isGrupoNegociador
}: PessoasInputProps) {
  const [field] = useField<AsyncSearchInputProps[]>({ name })

  function addPessoa(arrayHelpers: FieldArrayRenderProps) {
    return () => {
      arrayHelpers.push({ name: '', value: '' })
    }
  }

  function deletePessoa(index: number, arrayHelpers: FieldArrayRenderProps) {
    return () => {
      const pessoa = field.value[index]

      if (pessoa) {
        arrayHelpers.form.setFieldValue('deletedPessoas', [
          ...(arrayHelpers.form.values?.deletedPessoas || []),
          pessoa.value
        ])
      }

      arrayHelpers.remove(index)
    }
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {field.value.map((_, index: number) => {
            return (
              <Grid xs={12} item key={index}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs>
                    <PureAsyncSearchInput
                      name={`${name}.${index}`}
                      label={`Pessoa ${index + 1}`}
                      url={
                        isGrupoNegociador
                          ? `/pessoas?modalidade=Cliente&withoutGrupoNegociador=true`
                          : `/pessoas`
                      }
                      labelFormat={formatLabelPerson}
                      paramSearch="nomeDocumento"
                    />
                  </Grid>
                  <Grid item>
                    <IconButton onClick={deletePessoa(index, arrayHelpers)}>
                      <Tooltip title={`Deletar ${index + 1}ª pessoa`}>
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

          <Grid xs={12} item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={addPessoa(arrayHelpers)}
              data-cy="add-pessoa"
            >
              Adicionar Pessoa
            </Button>
          </Grid>
        </Grid>
      )}
    />
  )
}
