import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Button, Drawer, Grid, makeStyles } from '@material-ui/core'
import { useMemo, useState } from 'react'
import { SelectOption } from 'src/components/Formik/Forms/SelectInput'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import { RFFMultipleAutocomplete } from 'src/components/RFFMultipleAutocomplete'
import When from 'src/components/When'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'
import { LotesFilterAdvanced } from './LotesFilterAdvanced'
import StatusBadge from './StatusBadge'
import { lotesStore } from './stores/lotes'
import { LotesQueryFilter, StatusLoteEnum, statusLote } from './types'
import { formatStatusFilter } from './utils/formatStatusFilter'
import { statusRenderTags } from './utils/statusRenderTags'
import { handleKeyCodes, handlePasteIntegerOnly } from '../options'

interface Props {
  isValidation?: boolean
}

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    height: theme.spacing(4),
    borderRadius: theme.spacing(4)
  }
}))

const format = (initialValues: LotesQueryFilter, isValidation: boolean) => {
  if (isValidation) {
    return {
      ...initialValues,
      status:
        initialValues.status?.map((status: StatusLoteEnum) =>
          statusLote.find(s => s.value === status)
        ) ?? []
    }
  }

  return initialValues
}

const schema = yup.object().shape({
  dataTerminoDe: yup.date().nullable().typeError(MessagesYup.INVALID_DATE),
  dataTerminoAte: yup.date().nullable().typeError(MessagesYup.INVALID_DATE),
  dataInicioDe: yup.date().nullable().typeError(MessagesYup.INVALID_DATE),
  dataInicioAte: yup.date().nullable().typeError(MessagesYup.INVALID_DATE)
})

export const LotesFilter = ({ isValidation = false }: Props) => {
  const classes = useStyles()
  const [drawer, setDrawer] = useState(false)
  const {
    clear,
    push,
    initialValues: queryInitialValues
  } = useQueryParams<LotesQueryFilter>()

  const handleClear = () => {
    lotesStore.lotes.resetList()
    clear()
  }

  const handleSubmitFilter = (values: LotesQueryFilter) => {
    lotesStore.lotes.resetList()

    push({
      grupoNegociadorId: values.grupoNegociadorId?.value,
      codigo: values.codigo || undefined,
      centroCustoId: values.centroCustoId?.value,
      operacaoKMM: values.operacaoKMM || undefined,
      numeroPedido: values.numeroPedido,
      emailCriador: values.emailCriador,
      analistaResponsavel: values.analistaResponsavel,
      origemId: values.origemId?.value,
      destinoId: values.destinoId?.value,
      dataInicioDe:
        values.dataInicioDe && values.dataInicioDe instanceof Date
          ? values.dataInicioDe.toUTCString()
          : values.dataInicioDe || undefined,
      dataInicioAte:
        values.dataInicioAte && values.dataInicioAte instanceof Date
          ? values.dataInicioAte.toUTCString()
          : values.dataInicioAte || undefined,
      dataTerminoDe:
        values.dataTerminoDe && values.dataTerminoDe instanceof Date
          ? values.dataTerminoDe.toUTCString()
          : values.dataTerminoDe || undefined,
      dataTerminoAte:
        values.dataTerminoAte && values.dataTerminoAte instanceof Date
          ? values.dataTerminoAte.toUTCString()
          : values.dataTerminoAte || undefined,
      status: isValidation
        ? formatStatusFilter(
            values.status?.map(
              (status: SelectOption<string>) => status.value
            ) ?? []
          )
        : values.status || undefined,
      page: 1
    })
  }

  const initialValues = useMemo(() => {
    return format(queryInitialValues, isValidation)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryInitialValues), isValidation])

  const customActions = [
    <Grid item xs={4} sm={2}>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        fullWidth
        onClick={() => setDrawer(true)}
      >
        <strong>Busca avançada</strong>
      </Button>
    </Grid>
  ]

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={initialValues}
      onClear={handleClear}
      onSubmit={handleSubmitFilter}
      validate={yupValidation(schema)}
      customActions={customActions}
    >
      <Grid container spacing={2}>
        <When value={isValidation} equals>
          <Grid item xs={12} sm={6}>
            <RFFMultipleAutocomplete
              name="status"
              label="Status"
              options={[{ label: 'TODOS', value: 'TODOS' }, ...statusLote]}
              limitTags={4}
              renderTags={statusRenderTags}
              renderOption={params => (
                <StatusBadge
                  status={params.value as StatusLoteEnum}
                  marginLeft="0%"
                />
              )}
            />
          </Grid>
        </When>

        <Grid item xs={12} sm={isValidation ? 6 : 4}>
          <TextField
            fullWidth
            name="codigo"
            label="Código"
            type="number"
            inputProps={{ min: 0 }}
            onKeyDown={handleKeyCodes}
            onPaste={e => handlePasteIntegerOnly(e, 0)}
          />
        </Grid>
        <Grid item xs={12} sm={isValidation ? 6 : 4}>
          <RFFAsyncSearchInput
            name="centroCustoId"
            label="Centro de Custo"
            url="/centros-custo/ativos"
            labelField="descricao"
          />
        </Grid>

        <Grid item xs={12} sm={isValidation ? 6 : 4}>
          <RFFAsyncSearchInput
            name="grupoNegociadorId"
            label="Cliente"
            url="/grupos-negociadores"
            labelField="nome"
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <Drawer anchor="right" open={drawer} onClose={() => setDrawer(false)}>
            <LotesFilterAdvanced
              handleSubmit={handleSubmitFilter}
              closeDrawer={() => setDrawer(false)}
              isValidation={isValidation}
            />
          </Drawer>
        </Grid>
      </Grid>
    </Filter>
  )
}
