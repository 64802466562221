import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { LotesFormData } from 'src/modules/lotes/types'
import { formatLabelTabelasContrato } from 'src/modules/lotes/utils/formatLabelTabelasContrato'
import { BlackDisabledTextInput } from 'src/utils/BlackDisabledTextInput'
import { useContrato } from '../modules/contratos/hooks/useContrato'
import { Contrato } from '../modules/contratos/types/types'
import { useLote } from '../modules/lotes/hooks/useLote'
import {
  TipoNegociacao,
  tiposNegociacao
} from './Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import {
  LabelFormatValue,
  PureAsyncSearchInput
} from './Formik/Forms/PureAsyncSearchInput'

export default function ContratoInput() {
  const formik = useFormikContext<LotesFormData>()
  const [contratoState, contratoActions] = useContrato()
  const [loteState] = useLote()

  useEffect(() => {
    if (formik.values.contrato) {
      contratoActions.reset()
      contratoActions.fetch(formik.values.contrato?.value)
    }
  }, [formik.values.contrato])

  useEffect(() => {
    return () => {
      if (contratoState.tag === 'with-data') {
        contratoActions.reset()
      }
    }
  }, [contratoState, contratoState.tag])

  useEffect(() => {
    if (contratoState.tag === 'with-data') {
      formik.setFieldValue('Contrato', contratoState.entity)
    }
  }, [contratoState.tag === 'with-data' && contratoState.entity])

  useEffect(() => {
    if (formik.values.tabela?.value && contratoState.tag === 'with-data') {
      formik.setFieldValue(
        'Tabela',
        contratoState.entity.Tabelas.find(
          tabela => tabela.id === formik.values.tabela?.value
        )
      )
    }
  }, [formik.values.tabela])

  useEffect(() => {
    formik.setFieldValue('Contrato', null)
  }, [])

  const formatLabelContrato = (
    data: { node: Contrato } | Contrato
  ): LabelFormatValue => {
    if ('node' in data) {
      const { codigo, GrupoNegociador, descricao, AgrupadorProdutos } =
        data.node

      return {
        name: `${codigo} - ${GrupoNegociador.nome} - ${descricao} - ${AgrupadorProdutos.nome}`,
        value: data.node.id
      }
    }
    const { codigo, GrupoNegociador, descricao, AgrupadorProdutos } = data

    return {
      name: `${codigo} - ${GrupoNegociador.nome} - ${descricao} - ${AgrupadorProdutos.nome}`,
      value: data.id
    }
  }

  const hasProgramacao =
    loteState.tag === 'with-data'
      ? loteState.entity.Programacoes!.length > 0
      : false

  const getDescricaoTipoNegociacaoEmpresa = (
    tipoNegociacao: TipoNegociacao | undefined
  ) =>
    tipoNegociacao
      ? tiposNegociacao.find(tipo => tipo.value === tipoNegociacao)?.label
      : ''

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <PureAsyncSearchInput
          name="contrato"
          label="Contrato"
          url="/contratos"
          filters={{
            grupoNegociadorId: formik.values.grupoNegociadorId?.value,
            status: 'ATIVO'
          }}
          labelFormat={formatLabelContrato}
          paramSearch="codigoGrupoNegociadorDescricaoAgrupador"
          handleOnChange={() => {
            formik.setFieldValue('tabela', null)
            formik.setFieldValue('Tabela', '')
            formik.setFieldValue('Contrato', null)
          }}
          disabled={hasProgramacao || !formik.values.grupoNegociadorId}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PureAsyncSearchInput
          name="tabela"
          label="Descrição da tabela"
          disabled={!!!formik.values.Contrato || hasProgramacao}
          url={`/contratos/${formik.values.contrato?.value}/tabelas`}
          paramSearch="codigoDescricao"
          labelFormat={formatLabelTabelasContrato}
          filters={{ status: 'ATIVO' }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <BlackDisabledTextInput
          name="idContrato"
          label="ID Contrato"
          value={formik.values.Contrato?.codigo ?? ''}
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <BlackDisabledTextInput
          name="descricaoContrato"
          label="Descrição Contrato"
          value={formik.values.Contrato?.descricao ?? ''}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BlackDisabledTextInput
          name="agrupadorProduto"
          label="Agrupador produto"
          value={formik.values.Contrato?.AgrupadorProdutos?.nome ?? ''}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BlackDisabledTextInput
          name="tipoNegociacaoEmpresa"
          label="Tipo Negociação empresa"
          value={getDescricaoTipoNegociacaoEmpresa(
            formik.values.Tabela?.tipoNegociacaoEmpresa
          )}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <BlackDisabledTextInput
          name="icms"
          label="Adicionar ICMS"
          value={formik.values.Tabela?.icms ? 'SIM' : 'NÃO'}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <BlackDisabledTextInput
          name="pedagio"
          label="Tipo pedágio"
          value={
            formik.values.Tabela?.pedagio
              ? formik.values.Tabela?.tipoPedagio?.replace('_', ' ')
              : 'NÃO APLICÁVEL'
          }
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BlackDisabledTextInput
          name="cadenciaRetirada"
          label="Cadencia de Retirada"
          value={formik.values.Contrato?.cadencia ?? ''}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <BlackDisabledTextInput
          name="observacao"
          label="Observação"
          value={formik.values.Contrato?.observacoes ?? ''}
          disabled
        />
      </Grid>
    </Grid>
  )
}
