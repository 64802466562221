import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { programacoesRepository } from './hooks/programacaoRepository'
import ProgramacaoFilter from './ProgramacoesFilter'
import programacoesStore from './store/store'
import { ProgramacaoTable } from './ProgramacaoTable'
import { useQueryParams } from '@elentari/core'
import { ProgramacoesQueryFilter } from './types'
import { useEffect } from 'react'

const Programacoes = () => {
  const ability = useAbility()
  const authorization = getAuthorizations(ability, 'Programações')
  const { initialValues } = useQueryParams<ProgramacoesQueryFilter>()

  const handleCancel = async (id: string) => {
    const response = await programacoesRepository.delete(id)

    if (response.ok) {
      snackbarStore.setMessage('Programação cancelada com sucesso')
      programacoesStore.programacoes.populate()

      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    programacoesStore.programacoes.setPage(Number(initialValues.page) || 1)
    programacoesStore.programacoes.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  useEffect(() => {
    return () => {
      programacoesStore.programacoes.resetList()
    }
  }, [])

  return (
    <>
      <ProgramacaoFilter />
      <ProgramacaoTable onCancel={handleCancel} authorization={authorization} />
    </>
  )
}

export default Programacoes
