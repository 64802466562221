import {
  CircularProgress,
  IconButton,
  Menu,
  Switch,
  Tooltip,
  Typography
} from '@material-ui/core'
import {
  ArrowDownward,
  ArrowUpward,
  FilterList,
  SearchOutlined
} from '@material-ui/icons'
import { useState } from 'react'
import { RiSettingsFill } from 'react-icons/ri'
import { ColumnInstance } from 'react-table'
import { TableModalSave } from './TableModalSave'

interface IProps<T extends object> {
  handleToggleHidden: (column: string) => void
  setColumns: (index: number, step: number) => void
  allColumns: ColumnInstance<T>[]
  loading: boolean
  keyTable: string
  isSearch: boolean
  handleSearch: () => void
}

export const ActionsTable = <T extends object>({
  handleToggleHidden,
  allColumns,
  setColumns,
  loading,
  keyTable,
  handleSearch
}: IProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [openModal, setOpenModal] = useState(false)
  const open = Boolean(anchorEl)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Tooltip title="Pesquisar" aria-label="Pesquisar">
        <IconButton onClick={() => handleSearch()}>
          <SearchOutlined />
        </IconButton>
      </Tooltip>
      <TableModalSave
        keyTable={keyTable}
        open={openModal}
        setOpen={setOpenModal}
      />
      <Tooltip
        title="Salvar formatação da tabela"
        aria-label="Salvar formatação da tabela"
      >
        <IconButton onClick={() => setOpenModal(true)}>
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            <RiSettingsFill size={20} />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Filtrar Colunas" aria-label="Filtrar Colunas">
        <IconButton onClick={handleClick}>
          <FilterList />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: '0 20px'
          }
        }}
      >
        {allColumns
          .filter((column: any) => column.selectable !== false)
          .map((column, index) => (
            <div key={column.id}>
              {
                <Typography>
                  <Switch
                    type="checkbox"
                    onClick={() => handleToggleHidden(column.id)}
                    {...column.getToggleHiddenProps()}
                  />
                  <IconButton
                    disabled={index === 0}
                    onClick={() => {
                      setColumns(index, -1)
                    }}
                  >
                    <ArrowUpward fontSize="small" />
                  </IconButton>
                  <IconButton
                    disabled={index === allColumns.length - 2}
                    onClick={() => {
                      setColumns(index, 1)
                    }}
                  >
                    <ArrowDownward fontSize="small" />
                  </IconButton>
                  {column.Header}
                </Typography>
              }
            </div>
          ))}
      </Menu>
    </>
  )
}
