function scrollToError(errors: any) {
  const mainContainer = document.querySelector('main')
  const firstError = Object.keys(errors)[0]

  if (firstError) {
    const inputWithError = document.getElementsByName(firstError)[0]

    if (inputWithError && mainContainer) {
      const inputBottom = inputWithError.getBoundingClientRect().bottom

      mainContainer.scrollTo({
        top: inputWithError.offsetTop + inputBottom * 5,
        behavior: 'smooth'
      })
    }
  }
}

export default scrollToError
