import { CategoriaTransportador, categoriaTransportadorOptions } from '../types'

export function formatCategoriaTransportador(
  categoriaTransportador: CategoriaTransportador
) {
  return (
    categoriaTransportadorOptions.find(
      option => option.value === categoriaTransportador
    )?.label ?? '-'
  )
}
