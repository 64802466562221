import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { alterarStatus } from 'src/modules/contratos/hooks/contratosRepository'
import contratosStore from 'src/modules/contratos/store/store'
import { AlterarStatusData } from 'src/modules/contratos/types/types'
import snackbarStore from 'src/stores/snackbar'
import SelectInput from '../Formik/Forms/SelectInput'

interface ContratosDialogProps {
  open: boolean
  handleClose: () => void
  primaryAction?: () => void
  status: string
  optionsStatus: string[]
  id: string
}

const initialValues: AlterarStatusData = {
  newStatus: ''
}

function ContratosDialog({
  open,
  handleClose,
  primaryAction,
  status,
  optionsStatus,
  id
}: ContratosDialogProps) {
  const handlePrimaryAction = () => {
    handleClose()
    primaryAction && primaryAction()
  }

  function getOptions(optionsStatus: string[]) {
    const options = optionsStatus.map((value: string) => {
      return { label: value, value }
    })
    return options
  }

  const handleSubmit = async (data: AlterarStatusData) => {
    const res = await alterarStatus(id, data)
    if (res.ok) {
      snackbarStore.setMessage('Status alterado com sucesso!')
      contratosStore.contratos.populate()
    } else {
      snackbarStore.setMessage(
        res.data?.message ?? 'Error ao alterar status do contrato'
      )
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Editar Status</DialogTitle>
      <DialogContent>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SelectInput
                    name="newStatus"
                    label="Selecionar Status"
                    options={getOptions(optionsStatus)}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="flex-end">
                <DialogActions>
                  <Button onClick={handleClose} color="default" autoFocus>
                    Voltar
                  </Button>
                  <Button
                    onClick={handlePrimaryAction}
                    disabled={!!!values.newStatus || isSubmitting}
                    color="primary"
                    type="submit"
                    autoFocus
                  >
                    Confirmar
                  </Button>
                </DialogActions>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default ContratosDialog
