import { Box, Grid } from '@material-ui/core'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import DateInput from 'src/components/DateInput'
import { MultipleAutocompleteInput } from 'src/components/MultipleAutocompleteInput'
import {
  StatusProgramacao,
  statusProgramacao
} from 'src/modules/programacoes/types'
import StatusBadge from 'src/modules/programacoes/StatusBadge'
import MaskInput from 'src/components/Formik/Forms/MaskInput'
import TextInput from 'src/components/Formik/Forms/TextInput'

export const ProgramacaoFilters = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Carregamento De"
          name="filters.dataCarregamentoDe"
        />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Carregamento Até"
          name="filters.dataCarregamentoAte"
        />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Descarregamento De"
          name="filters.dataDescarregamentoDe"
        />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Descarregamento Até"
          name="filters.dataDescarregamentoAte"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <DateInput label="Data de Criação De" name="filters.createdAtDe" />
      </Grid>

      <Grid item xs={12} md={6}>
        <DateInput label="Data de Criação Até" name="filters.createdAtAte" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AsyncSearchInput
          name="filters.origemId"
          label="Cidade Origem"
          url="/cidades"
          labelField="nome"
          joinStateAndCity
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AsyncSearchInput
          name="filters.destinoId"
          label="Cidade Destino"
          url="/cidades"
          labelField="nome"
          joinStateAndCity
        />
      </Grid>

      <Grid item xs={12} md={3}>
        <MaskInput
          name="filters.placaPrincipal"
          label="Placa Principal"
          mask="aaa9*99"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.grupoNegociadorId"
          label="Cliente Contratante"
          url="/grupos-negociadores"
          labelField="nome"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.centroCustoId"
          label="Centro de Custo"
          url="centros-custo/ativos"
          labelField="descricao"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.produtoId"
          label="Produto"
          url="/produtos"
          labelField="nome"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextInput
          type="number"
          name="filters.codigoLote"
          label="Código do Lote"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <MaskInput
          name="filters.cpfMotorista"
          label="CPF Motorista"
          mask="999.999.999-99"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MultipleAutocompleteInput
          name="filters.status"
          label="Status"
          options={statusProgramacao}
          limitTags={4}
          renderTags={options =>
            options.map((option: any) => (
              <Box margin="1px">
                <StatusBadge status={option.value} marginLeft="0%" />
              </Box>
            ))
          }
          renderOption={params => (
            <StatusBadge
              status={params.value as Exclude<StatusProgramacao, ''>}
              marginLeft="0%"
            />
          )}
        />
      </Grid>
    </Grid>
  )
}
