import { Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

interface Props {
  title: string
  subtitle: string | number | false | undefined
  titleVariant?: Variant
  subtitleVariant?: Variant
}

interface PropsNode {
  title: string
  node: React.ReactNode
  titleVariant?: Variant
}

export const ConsultaField = ({
  title,
  subtitle,
  titleVariant,
  subtitleVariant
}: Props) => {
  return (
    <>
      <Typography style={{ color: 'gray' }} variant={titleVariant ?? 'caption'}>
        {title}
      </Typography>
      <Typography variant={subtitleVariant ?? 'body1'}>
        {subtitle ?? ''}
      </Typography>
    </>
  )
}

export const ConsultaFieldNode = ({ title, node, titleVariant }: PropsNode) => {
  return (
    <>
      <Typography style={{ color: 'gray' }} variant={titleVariant ?? 'caption'}>
        {title}
      </Typography>
      {node}
    </>
  )
}
