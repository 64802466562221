import { CidadeMinimal, DestinoMinimal } from '../../modules/lotes/types'

export function formatDestinoLote(destinoLote: CidadeMinimal[]): string {
  if (destinoLote.length > 1) {
    return `Diversos`
  }

  if (destinoLote.length === 0) {
    return 'Não Informado'
  }

  return `${destinoLote[0].cidade} / ${destinoLote[0].siglaEstado}`
}

export function formatLocalDescarregamento(
  destinoLote: DestinoMinimal[]
): string {
  if (!destinoLote || destinoLote.length === 0) {
    return 'Não Informado'
  }

  if (destinoLote.length > 1) {
    return `Diversos`
  }

  return destinoLote[0].localDescarregamento || 'Não Informado'
}
