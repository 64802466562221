import { FetchState } from '@elentari/core/types'
import { useConfiguracaoByName } from 'src/modules/configuracoes/hooks/useConfiguracaoByName'
import { useReadAbility } from 'src/modules/login'
import { LoteList } from 'src/modules/lotes/types'
import { AcaoLogAuditoria, createLog } from 'src/utils/createLog'
import { ProgramacaoDetail } from '../types'
import { LoteProgramacaoFormData } from '../types/formData'

export const useAutorizacaoCarregamento = () => {
  const hasPrintAuthorization = useReadAbility(
    'Impressão de Autorização de Carregamento'
  )

  const createAutorizacaoCarregamentoLog = (
    programacaoState: FetchState<ProgramacaoDetail>,
    lotes: LoteProgramacaoFormData[] | LoteList[],
    usuario: string
  ) => {
    if (programacaoState.tag === 'with-data') {
      const modeloId = programacaoState.entity.id
      const codigoProgramacao = programacaoState.entity.codigo
      const codigosLotes = lotes.map(lote => lote.codigo).sort((a, b) => a - b)

      createLog({
        acao: AcaoLogAuditoria.OTHER,
        modelo: 'Programações',
        modeloId,
        payload: {
          value: `Impressão de Autorização de Carregamento da Programação ${codigoProgramacao}, Lote (s) ${codigosLotes.join(
            ', '
          )}`
        },
        usuario
      })
    }
  }

  const { valor: configJudridico, loading: loadingConfigJudridico } =
    useConfiguracaoByName('TEXTO_JURIDICO_AUTORIZACAO')
  const { valor: configEstadias, loading: loadingConfigEstadias } =
    useConfiguracaoByName('TEXTO_ESTADIAS_AUTORIZACAO')
  const { valor: configValidade, loading: loadingConfigValidade } =
    useConfiguracaoByName('HORAS_VALIDADE_AUTORIZACAO')

  return {
    hasPrintAuthorization,
    configJudridico,
    configEstadias,
    configValidade,
    loadingConfigs:
      loadingConfigJudridico || loadingConfigEstadias || loadingConfigValidade,
    createAutorizacaoCarregamentoLog
  }
}
