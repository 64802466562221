import { useQueryParams, useSnackbar } from '@elentari/core'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { tableStore } from 'src/stores/TableStore'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { useAbility } from '../login'
import { lotesRepository } from './hooks/lotesRepository'
import { LotesFilter } from './LotesFilter'
import { LotesTable } from './LotesTable'
import { LotesValidation } from './LotesValidation'
import { lotesStore } from './stores/lotes'
import { LotesQueryFilter } from './types'
import When from 'src/components/When'

export const LotesList = observer(() => {
  const [, snackbarActions] = useSnackbar()
  const ability = useAbility()
  const { initialValues } = useQueryParams<LotesQueryFilter>()
  const location = useLocation()
  const isValidation = location.pathname.includes('lotes-validacao')
  const authorization = getAuthorizations(ability, 'Lotes')

  async function handleCancel(id: string) {
    const response = await lotesRepository.delete(id)
    lotesStore.lotes.populate()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Lote cancelado com sucesso',
        severity: 'success'
      })
      return
    }

    const message = (response.data as unknown as any).message
    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  useEffect(() => {
    lotesStore.lotes.setPage(Number(initialValues.page) || 1)

    const limit =
      initialValues.limit ?? tableStore.getState(resourceTabela.LOTES)?.limit

    lotesStore.lotes.setFilters({
      ...initialValues,
      limit
    })
  }, [initialValues, tableStore.getState(resourceTabela.LOTES)?.limit]) // eslint-disable-line

  useEffect(() => {
    return () => {
      lotesStore.lotes.resetList()
    }
  }, [])

  return (
    <>
      <LotesFilter isValidation={isValidation} />
      <When value={isValidation} equals>
        <LotesValidation />
      </When>
      <When value={!isValidation} equals>
        <LotesTable onCancel={handleCancel} authorization={authorization} />
      </When>
    </>
  )
})
