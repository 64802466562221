import { InputBaseComponentProps, InputLabelProps } from '@material-ui/core'
import {
  LabelFormatValue,
  PureAsyncSearchInput
} from '../../../components/Formik/Forms/PureAsyncSearchInput'
import { EstadosDetail } from '../../estados/types'

interface Props {
  altValue?: string
  endAdornment?: React.ReactNode
  url: string
  name: string
  label: string
  filters?: Record<string, any>
  disabled?: boolean
  placeholder?: string
  fullWidth?: boolean
  InputProps?: InputBaseComponentProps
  InputLabelProps?: InputLabelProps
  allowSearchWhenDisabled?: boolean
  isConsulting?: boolean
  limit?: number
  paramSearch: string
  handleOnChange?: (value: any) => void
  labelField?: never
}

const PracasPedagioAsyncSearchInput = (props: Props) => {
  const labelFormat = (
    data: { node: EstadosDetail } | EstadosDetail
  ): LabelFormatValue => {
    if ('node' in data) {
      const { nome, sigla } = data.node

      return {
        name: `${nome} - ${sigla}`,
        value: data.node.id
      }
    }
    const { nome, sigla } = data

    return {
      name: `${nome} - ${sigla}`,
      value: data.id
    }
  }

  return <PureAsyncSearchInput {...props} labelFormat={labelFormat} />
}

export default PracasPedagioAsyncSearchInput
