import Filter from '@elentari/components-eve/components/Filter'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import * as dateFns from 'date-fns'
import { useEffect, useState } from 'react'
import * as yup from 'yup'
import RFFAutocomplete from '../../components/Forms/RFFAutocomplete'
import { SubmitValues } from '../../hooks/types'
import yupValidation from '../../utils/yupValidation'
import { paisesRepository } from '../paises/hooks/paisesRepository'

export type EstadosQueryFilter = {
  [key: string]: any
}

const schema = yup.object().shape({
  nome: yup.string(),
  sigla: yup.string(),
  paisId: yup.string()
})

export const EstadosFilter = () => {
  const { clear, push, initialValues } = useQueryParams<EstadosQueryFilter>()
  const [paises, setPaises] = useState<{ id: string; name: string }[]>([])

  const loadPaises = async () => {
    const response = await paisesRepository.fetch({ limit: '999' })
    if (response.ok) {
      setPaises(
        response.data?.edges.map(pais => ({
          id: pais.node.id,
          name: pais.node.nome
        })) ?? []
      )
    }
  }

  const handleSubmitFilter = (values: SubmitValues & { page: number }) => {
    clear()
    if (values.createdAt) {
      const dateFormatted = dateFns.parse(
        String(values.createdAt),
        'dd/MM/yyyy',
        new Date()
      )

      return push({
        ...values,
        createdAt: {
          gt: dateFormatted.toISOString()
        }
      })
    }
    push({
      ...values,
      createdAt: undefined
    })
  }

  const format = (initialValues: EstadosQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  useEffect(() => {
    loadPaises()
  }, [])

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField fullWidth name="nome" label="Nome" />
        </Grid>
        <Grid item xs>
          <TextField fullWidth name="sigla" label="Sigla" />
        </Grid>
        <Grid item xs={12} lg={4}>
          <RFFAutocomplete label="País" name="paisId" options={paises} />
        </Grid>
      </Grid>
    </Filter>
  )
}
