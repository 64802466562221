import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import CurrencyField from 'src/components/Formik/Forms/Inputs/CurrencyField'
import contratosStore from '../store/store'

function ModalAdicionarColuna() {
  const [value, setValue] = useState(0)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    if (!contratosStore.adicionarColunaDialogOpened) {
      setValue(0)
      setErrorMessage('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contratosStore.adicionarColunaDialogOpened])

  const addColuna = (value: number) => {
    if (contratosStore.hasCapacidade(value.toString())) {
      setErrorMessage('Capacidade já existe na tabela.')
      return
    }

    contratosStore.addCapacidade(value.toString())
    contratosStore.closeAdicionarColunaDialog()
  }

  return (
    <Dialog
      maxWidth="md"
      open={contratosStore.adicionarColunaDialogOpened}
      onClose={contratosStore.closeAdicionarColunaDialog}
    >
      <DialogTitle>Adicionar Capacidade</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CurrencyField
              thousandChar="."
              decimalChar=","
              precision={0}
              fullWidth
              variant="standard"
              value={value}
              onChange={event => setValue(Number(event.target.value) ?? 0)}
              maxValue={999_999}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid justifyContent="flex-end" container spacing={2}>
              <Grid item style={{ width: 160 }}>
                <Button
                  fullWidth
                  type="button"
                  variant="outlined"
                  onClick={contratosStore.closeAdicionarColunaDialog}
                >
                  Voltar
                </Button>
              </Grid>
              <Grid item style={{ width: 160 }}>
                <Button
                  data-testid="salvar"
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => addColuna(value)}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default observer(ModalAdicionarColuna)
