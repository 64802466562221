import { useCallback, useEffect } from 'react'
import api from 'src/services/api'
import { tableStore } from 'src/stores/TableStore'

interface IResponseLoadDataTabela {
  hasDefaultConfigDatabase: boolean
  columnOrder: string[]
  hiddenColumns: string[]
  groupColumns: string[]
  descricaoTabela: string
  isDefault: boolean
  limit?: string
}

export const useConfigTable = (key: string) => {
  const fetchApiConfigTable = useCallback(() => {
    api.getConfigTabela(key).then(response => {
      if (response.ok && response.data) {
        const parsedData: IResponseLoadDataTabela = JSON.parse(
          response.data.configTabela
        )

        tableStore.setState(key, {
          hasDefaultConfigDatabase: parsedData.hasDefaultConfigDatabase,
          columnOrder: parsedData.columnOrder,
          hiddenColumns: parsedData.hiddenColumns,
          descricaoTabela: response.data.descricaoTabela,
          isDefault: response.data.isDefault,
          groupColumns: parsedData.groupColumns,
          limit: parsedData.limit ?? '10'
        })
      } else {
        tableStore.setStateColumnOrder(key, [])
        tableStore.setStateHiddenColumns(key, [])
      }
    })
  }, [key])

  useEffect(() => {
    fetchApiConfigTable()
  }, [fetchApiConfigTable])

  return null
}
