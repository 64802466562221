import { format } from 'date-fns'
import { makeAutoObservable, runInAction } from 'mobx'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { formatNumber } from 'src/utils/formatters'
import { SelectOption } from '../filters/AsyncMultipleSelect'

interface FilterAsyncInput {
  name: string
  value: string
}

interface FiltersOptions {
  centrosCusto: SelectOption[]
  clientes: SelectOption[]
  origens: SelectOption[]
  destinos: SelectOption[]
  produtos: SelectOption[]
}

export interface DashboardFilters {
  unidadeNegocio: FilterAsyncInput | null
  centrosCusto: SelectOption[]
  dataInicial: Date | null
  dataFinal: Date | null
  clientes: SelectOption[]
  origens: SelectOption[]
  destinos: SelectOption[]
  produtos: SelectOption[]
}

interface CaminhoesInfo {
  programados: number
  carregados: number
  carregamento: number
  transito: number
  toneladas: number
}

interface CaminhaoCentroCusto {
  descricao: string
  qtd: number
}

interface CaminhaoCliente {
  nome: string
  qtd: number
}

class DashboardProgramacoesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  dadosGraficoCaminhoesCliente: CaminhaoCliente[] = []
  dadosGraficoCaminhoesCentroCusto: CaminhaoCentroCusto[] = []

  isFiltersOpen = false
  isLoading = false
  filtersOptions: FiltersOptions = {
    centrosCusto: [],
    clientes: [],
    origens: [],
    destinos: [],
    produtos: []
  }

  filters: DashboardFilters = {
    unidadeNegocio: null,
    centrosCusto: [],
    dataInicial: new Date(),
    dataFinal: new Date(),
    clientes: [],
    origens: [],
    destinos: [],
    produtos: []
  }
  private caminhoesInfo: CaminhoesInfo = {
    programados: 0,
    carregados: 0,
    carregamento: 0,
    transito: 0,
    toneladas: 0
  }

  openFilters() {
    this.isFiltersOpen = true
  }
  closeFilters() {
    this.isFiltersOpen = false
  }
  setLoading(bool: boolean) {
    this.isLoading = bool
  }
  get loading() {
    return this.isLoading
  }
  setFilters(filters: DashboardFilters) {
    this.filters = filters
    this.sync()
  }
  clearFilters() {
    this.filters = {
      unidadeNegocio: null,
      centrosCusto: [],
      dataInicial: new Date(),
      dataFinal: new Date(),
      clientes: [],
      origens: [],
      destinos: [],
      produtos: []
    }
    this.sync()
  }
  buildFilters() {
    const filters: Record<string, unknown> = { ...this.filters }
    filters.unidadeNegocio = this.filters.unidadeNegocio?.value
    filters.centrosCusto = this.filters.centrosCusto.map(item => item.value)
    filters.clientes = this.filters.clientes.map(item => item.value)
    filters.origens = this.filters.origens.map(item => item.value)
    filters.destinos = this.filters.destinos.map(item => item.value)
    filters.produtos = this.filters.produtos.map(item => item.value)

    if (filters.dataInicial) {
      filters.dataInicial = this.filters.dataInicial
        ? format(this.filters.dataInicial, 'yyyy-MM-dd')
        : undefined
    }
    if (filters.dataFinal) {
      filters.dataFinal = this.filters.dataFinal
        ? format(this.filters.dataFinal, 'yyyy-MM-dd')
        : undefined
    }

    return filters
  }

  async sync() {
    const filters = this.buildFilters()

    Promise.all([
      this.updateGraficoCaminhoesCliente(filters),
      this.updateGraficoCaminhoesCentroCusto(filters),
      this.updateCaminhoesInfo(filters)
    ]).then(([caminhaoCliente, caminhaoCentroCusto, infos]) => {
      if (caminhaoCliente && caminhaoCentroCusto && infos)
        this.setLoading(false)

      runInAction(() => {
        this.dadosGraficoCaminhoesCliente = caminhaoCliente
        this.dadosGraficoCaminhoesCentroCusto = caminhaoCentroCusto

        this.caminhoesInfo.programados = infos?.programados || 0
        this.caminhoesInfo.carregados = infos?.carregados || 0
        this.caminhoesInfo.carregamento = infos?.carregamento || 0
        this.caminhoesInfo.transito = infos?.transito || 0
        this.caminhoesInfo.toneladas = infos?.toneladas || 0
      })
    })
  }

  async updateGraficoCaminhoesCliente(filters: Record<string, unknown>) {
    this.setLoading(true)
    const response = await apiSauceInstance.get<CaminhaoCliente[]>(
      '/dashboard/caminhoes-cliente',
      filters
    )

    if (response.ok && response.data) {
      return response.data
    } else {
      snackbarStore.setMessage(
        'Ocorreu um erro ao requisitar os dados. Tente novamente mais tarde.'
      )
      return []
    }
  }

  async updateGraficoCaminhoesCentroCusto(filters: Record<string, unknown>) {
    this.setLoading(true)

    const response = await apiSauceInstance.get<CaminhaoCentroCusto[]>(
      '/dashboard/caminhoes-centro-custo',
      filters
    )

    if (response.ok && response.data) {
      return response.data
    } else {
      snackbarStore.setMessage(
        'Ocorreu um erro ao requisitar os dados. Tente novamente mais tarde.'
      )
      return []
    }
  }

  async updateCaminhoesInfo(filters: Record<string, unknown>) {
    this.setLoading(true)

    const response = await apiSauceInstance.get<CaminhoesInfo>(
      '/dashboard/caminhoes-info',
      filters
    )
    if (response.ok && response.data) {
      return {
        programados: response.data.programados,
        carregados: response.data.carregados,
        transito: response.data.transito,
        toneladas: response.data.toneladas,
        carregamento: response.data.carregamento
      }
    } else {
      snackbarStore.setMessage(
        'Ocorreu um erro ao requisitar os dados. Tente novamente mais tarde.'
      )
      return null
    }
  }

  sortDataCaminhoesClientePerName(isAscending: boolean) {
    let dataChart = this.dadosGraficoCaminhoesCliente.sort((a, b) => {
      if (a.nome > b.nome) return -1
      if (a.nome < b.nome) return 1
      return 0
    })

    if (!isAscending) {
      dataChart.reverse()
    }

    this.graficoCaminhoesPorCliente.categories = dataChart.map(d => d.nome)
    this.graficoCaminhoesPorCliente.series = [
      { name: 'Caminhões', data: dataChart.map(d => d.qtd) }
    ]
  }

  sortDataCaminhoesClientePerQtd(isAscending: boolean) {
    let dataChart = this.dadosGraficoCaminhoesCliente.sort((a, b) => {
      if (a.qtd > b.qtd) return -1
      if (a.qtd < b.qtd) return 1
      return 0
    })

    if (!isAscending) {
      dataChart.reverse()
    }

    this.graficoCaminhoesPorCliente.categories = dataChart.map(d => d.nome)
    this.graficoCaminhoesPorCliente.series = [
      { name: 'Caminhões', data: dataChart.map(d => d.qtd) }
    ]
  }

  sortDataCaminhoesCentroCustoPerDescription(isAscending: boolean) {
    let dataChart = this.dadosGraficoCaminhoesCentroCusto.sort((a, b) => {
      if (a.descricao > b.descricao) return -1
      if (a.descricao < b.descricao) return 1
      return 0
    })

    if (!isAscending) {
      dataChart.reverse()
    }

    this.graficoCaminhoesPorCentroCusto.categories = dataChart.map(
      d => d.descricao
    )
    this.graficoCaminhoesPorCentroCusto.series = [
      { name: 'Caminhões', data: dataChart.map(d => d.qtd) }
    ]
  }

  sortDataCaminhoesCentroCustoPerQtd(isAscending: boolean) {
    let dataChart = this.dadosGraficoCaminhoesCentroCusto.sort((a, b) => {
      if (a.qtd > b.qtd) return -1
      if (a.qtd < b.qtd) return 1
      return 0
    })

    if (!isAscending) {
      dataChart.reverse()
    }

    this.graficoCaminhoesPorCentroCusto.categories = dataChart.map(
      d => d.descricao
    )
    this.graficoCaminhoesPorCentroCusto.series = [
      { name: 'Caminhões', data: dataChart.map(d => d.qtd) }
    ]
  }

  get graficoCaminhoesPorCliente() {
    return {
      categories: this.dadosGraficoCaminhoesCliente.map(d => d.nome),
      series: [
        {
          name: 'Caminhões',
          data: this.dadosGraficoCaminhoesCliente.map(d => d.qtd)
        }
      ]
    }
  }

  get graficoCaminhoesPorCentroCusto() {
    return {
      categories: this.dadosGraficoCaminhoesCentroCusto.map(d => d.descricao),
      series: [
        {
          name: 'Caminhões',
          data: this.dadosGraficoCaminhoesCentroCusto.map(d => d.qtd)
        }
      ]
    }
  }

  get qtdCaminhoesProgramados() {
    return formatNumber(this.caminhoesInfo.programados)
  }

  get qtdCaminhoesCarregados() {
    return formatNumber(this.caminhoesInfo.carregados)
  }

  get qtdCaminhoesCarregamento() {
    return formatNumber(this.caminhoesInfo.carregamento)
  }

  get qtdCaminhoesTransito() {
    return formatNumber(this.caminhoesInfo.transito)
  }

  get projecaoToneladas() {
    return formatNumber(this.caminhoesInfo.toneladas, 1)
  }
}

const dashboardProgramacoesStore = new DashboardProgramacoesStore()
export default dashboardProgramacoesStore
