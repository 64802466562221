import { Box, Grid, Tooltip, Typography } from '@material-ui/core'
import { IoArrowForwardCircleOutline } from 'react-icons/io5'

interface Props {
  field: string
  oldValue: string
  newValue: string
}

export function FieldLog({ field, oldValue, newValue }: Props) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      justifyItems="center"
    >
      {oldValue ? (
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Typography>
              {field}: <b>{oldValue}</b>
            </Typography>
          </Grid>
          <Grid item component={Box} display="flex">
            <Tooltip title="Foi alterado para">
              <IoArrowForwardCircleOutline />
            </Tooltip>
          </Grid>
          <Grid item>
            <Typography>
              <b>{newValue}</b>
            </Typography>
          </Grid>
        </Grid>
      ) : (
        <Typography style={{ wordBreak: 'break-all' }}>
          {field}:{'  '}
          <b>{newValue}</b>
        </Typography>
      )}
    </Box>
  )
}
