import {
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  makeStyles,
  Switch,
  Typography
} from '@material-ui/core'
import { Formik } from 'formik'
import { Fragment, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import DateInput from 'src/components/DateInput'
import DropzoneInput from 'src/components/DropzoneInput'
import FilesList from 'src/components/FilesList'
import TipoPedagioSelectInput from 'src/components/Formik/Forms/Inputs/TipoPedagioSelectInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import { UserByScopeAndAbilityInput } from 'src/components/Formik/Forms/UserByScopeAndAbility'
import SimpleDialog from 'src/components/SimpleDialog'
import useCanSave from 'src/hooks/useCanSave'
import { useDefaultGoBack } from 'src/hooks/useDefaultGoBack'
import { MessagesYup } from 'src/modules/messages'
import { FormWrap } from 'src/packages/g10-components/FormWrap'
import { EntitySaver } from 'src/services/entitySaver'
import snackbarStore from 'src/stores/snackbar'
import { UPLOAD_ERROR_MESSAGE, uploadAnexos } from 'src/utils/anexo'
import * as yup from 'yup'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import NumericInput from '../../../components/Formik/Forms/NumericInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import yupValidation from '../../../utils/yupValidation'
import {
  AprovacaoDialog,
  AprovacaoFormValues
} from '../components/AprovacaoDialog'
import { alterarStatus, cotacoesRepository } from '../hooks/cotacoesRepository'
import { useCotacao } from '../hooks/useCotacao'
import {
  AlterarStatusData,
  CotacaoDetail,
  CotacaoDetailForm,
  EmailsCotacaoForm,
  StatusCotacao,
  tiposNegociacaoCotacao,
  UpdateStatus
} from '../types'
import { schema } from '../validation'
import EmailInput from './EmailInput'

const useStyles = makeStyles(theme => ({
  adornoDisabled: {
    color: theme.palette.action.disabled
  }
}))

const initialValues = {
  centroCustoId: '',
  cliente: '',
  dataCotacao: null,
  estadoOrigemId: null,
  origemId: '',
  estadoDestinoId: null,
  destinoId: '',
  produto: '',
  tipoNegociacao: '',
  freteCotacao: null,
  valorFreteFechado: null,
  hasPedagio: false,
  tipoPedagio: '',
  valorPedagio: '',
  icms: false,
  quantidade: '',
  observacoes: '',
  emails: [{ email: '' }],
  files: [],
  dataEmbarqueInicio: null,
  dataEmbarqueTermino: null,
  lancadoPor: null
}

export const CotacaoForm = () => {
  const [cotacaoState, cotacaoActions] = useCotacao()
  const { id } = useParams<{ id: string }>()
  const [loading, setLoading] = useState(false)
  const [isModalApproveOpen, setIsModalApproveOpen] = useState(false)
  const [isModalDisapproveOpen, setIsModalDisapproveOpen] = useState(false)
  const [isModalRegisterLoteOpen, setIsModalRegisterLoteOpen] = useState(false)
  const history = useHistory()
  const canSave = useCanSave(cotacaoState, 'Cotações')
  const clearDropzoneRef = useRef(false)
  const goBack = useDefaultGoBack()

  const classes = useStyles()

  const handleSubmit = async (data: any) => {
    const dataTratado: Omit<CotacaoDetailForm, 'anexos'> = {
      id: data.id,
      centroCustoId: data.centroCustoId.value,
      cliente: data.cliente,
      dataCotacao: data.dataCotacao,
      origemId: data.origemId.value,
      destinoId: data.destinoId.value,
      produto: data.produto,
      tipoNegociacao: data.tipoNegociacao,
      freteCotacao: data.freteCotacao || null,
      valorFreteFechado: data.valorFreteFechado || null,
      hasPedagio: data.hasPedagio,
      tipoPedagio: data.hasPedagio ? data.tipoPedagio : null,
      valorPedagio: data.hasPedagio ? data.valorPedagio : null,
      icms: data.icms,
      quantidade: data.quantidade || null,
      observacoes: data.observacoes || null,
      emails: data.emails
        ? data.emails.filter((email: EmailsCotacaoForm) => email.email)
        : null,
      dataEmbarqueInicio: data.dataEmbarqueInicio,
      dataEmbarqueTermino: data.dataEmbarqueTermino,
      lancadoPor: data.lancadoPor?.name
    }

    setLoading(true)
    setIsModalApproveOpen(false)

    const anexos = await uploadAnexos(data.files)

    if (!anexos) {
      setLoading(false)
      return snackbarStore.setMessage(UPLOAD_ERROR_MESSAGE)
    }

    await EntitySaver(
      () =>
        cotacoesRepository.save({
          ...dataTratado,
          anexos
        }) as any,
      {
        feedbackError: 'Erro ao salvar cotação',
        onSuccess: () => goBack()
      }
    )
    setLoading(false)
  }

  const handleSubmitUpdateStatus = async (
    type: UpdateStatus,
    onSuccess: () => void,
    data: AprovacaoFormValues
  ) => {
    const dataTratado: AlterarStatusData = {
      valorFreteFechado: data?.valorFreteFechado ?? undefined
    }

    const res = await alterarStatus(id, type, dataTratado)

    if (res.ok) {
      cotacaoState.tag === 'with-data' && (await cotacaoActions.fetch(id))
      onSuccess()
      snackbarStore.setMessage('Status alterado com sucesso!')

      return
    }

    snackbarStore.setMessage(
      res.data?.message || 'Erro ao alterar status da Cotação'
    )
  }

  const handleCreateLoteFromCotacao = () => {
    history.push({
      pathname: '/lotes/new',
      state: {
        id
      }
    })
  }

  const formatData = (values: CotacaoDetail) => {
    const dataTratado = {
      ...values,
      centroCustoId: {
        value: values.centroCustoId,
        name: values.CentroCusto.descricao
      },
      emails: values.EmailsCotacao.map(email => ({ email: email.email })),
      freteCotacao: Number(values.freteCotacao),
      valorFreteFechado: Number(values.valorFreteFechado),
      valorPedagio: values.valorPedagio && Number(values.valorPedagio),
      quantidade: values.quantidade ? Number(values.quantidade) : '',
      origemId: {
        name: values.Origem.nome,
        value: values.origemId
      },
      destinoId: {
        name: values.Destino.nome,
        value: values.destinoId
      },
      estadoOrigemId: {
        name: values.Origem.Estado.nome ?? '',
        value: values.Origem.Estado.id ?? ''
      },
      estadoDestinoId: {
        name: values.Destino.Estado.nome ?? '',
        value: values.Destino.Estado.id ?? ''
      },
      lancadoPor: {
        value: values.lancadoPor,
        name: values.lancadoPor
      },
      files: []
    }

    return dataTratado
  }

  const handleHasPedagioChange = (setFieldValue: any) => {
    setFieldValue('valorPedagio', null)
    setFieldValue('tipoPedagio', '')
  }

  const handleDisaproveCotacao = () => {
    setIsModalDisapproveOpen(true)
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        cotacaoState.tag === 'with-data'
          ? formatData(cotacaoState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({
        values,
        isSubmitting,
        handleSubmit,
        handleChange,
        setFieldValue,
        errors,
        submitCount
      }) => (
        <FormWrap
          handleSubmit={handleSubmit}
          disableSubmit={
            (cotacaoState.tag === 'with-data' &&
              cotacaoState.entity.status !== 'PENDENTE') ||
            isSubmitting ||
            loading ||
            !canSave
          }
          disableBack={isSubmitting || loading}
          loading={loading}
          errors={errors}
          submitCount={submitCount}
          customActions={
            cotacaoState.tag === 'with-data' &&
            cotacaoState.entity.status === 'PENDENTE'
              ? [
                  {
                    label: 'Reprovar',
                    onClick: handleDisaproveCotacao,
                    disabled: isSubmitting || loading,
                    variant: 'outlined',
                    color: 'secondary'
                  },
                  {
                    label: 'Aprovar',
                    onClick: () => setIsModalApproveOpen(true),
                    disabled: isSubmitting || loading,
                    variant: 'outlined'
                  }
                ]
              : undefined
          }
        >
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <AsyncSearchInput
                name="centroCustoId"
                label="Centro de Custo"
                url="/centros-custo/ativos"
                labelField="descricao"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="cliente" label="Cliente" />
            </Grid>
            <Grid item xs={12} md={3}>
              <DateInput label="Data Cotação" name="dataCotacao" />
            </Grid>
            <Grid item xs={12} md={3}>
              <AsyncSearchInput
                name="estadoOrigemId"
                label="Estado Origem"
                url="/estados/minimal"
                labelField="nome"
                joinStateAndUf
                handleOnChange={() => {
                  setFieldValue('origemId', null)
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AsyncSearchInput
                name="origemId"
                label="Cidade Origem"
                url={`estados/${values.estadoOrigemId?.value}/cidades`}
                labelField="nome"
                disabled={!values.estadoOrigemId?.value}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AsyncSearchInput
                name="estadoDestinoId"
                label="Estado Destino"
                url="/estados/minimal"
                labelField="nome"
                joinStateAndUf
                handleOnChange={() => {
                  setFieldValue('destinoId', null)
                }}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <AsyncSearchInput
                name="destinoId"
                label="Cidade Destino"
                url={`estados/${values.estadoDestinoId?.value}/cidades`}
                labelField="nome"
                disabled={!values.estadoDestinoId?.value}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput name="produto" label="Produto" />
            </Grid>
            <Grid item xs={12} md={2}>
              <SelectInput
                name="tipoNegociacao"
                label="Tipo"
                options={tiposNegociacaoCotacao}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumericInput
                name="freteCotacao"
                label="Frete Cotado"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <NumericInput
                name="valorFreteFechado"
                label="Valor Frete Fechado"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControlLabel
                checked={values.hasPedagio}
                name="hasPedagio"
                control={<Switch />}
                label="Frete + Pedágio"
                onChange={e => {
                  handleChange(e)
                  handleHasPedagioChange(setFieldValue)
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TipoPedagioSelectInput
                name="tipoPedagio"
                label="Tipo Pedágio"
                disabled={!values.hasPedagio}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <NumericInput
                name="valorPedagio"
                label="Valor Pedágio"
                inputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                        className={
                          !values.hasPedagio ? classes.adornoDisabled : ''
                        }
                      >
                        R$
                      </Typography>
                    </InputAdornment>
                  )
                }}
                disabled={!values.hasPedagio}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControlLabel
                checked={values.icms}
                name="icms"
                control={<Switch />}
                label="Frete + ICMS"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <EmailInput name="emails" />
            </Grid>
            <Grid item xs={12} md={6}>
              <NumericInput name="quantidade" label="Quantidade (kg/lt)" />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextInput
                name="observacoes"
                label="Observações da Cotação"
                multiline
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput
                name="dataEmbarqueInicio"
                label="Data Embarque Início"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DateInput
                name="dataEmbarqueTermino"
                label="Data Embarque Término"
              />
            </Grid>
            <Grid item xs={12}>
              <UserByScopeAndAbilityInput
                name="lancadoPor"
                label="Lançado Por"
                action="create"
                subject="Cotações"
                labelField="name"
              />
            </Grid>
            <Grid item xs={12}>
              <DropzoneInput
                key={Number(clearDropzoneRef.current)}
                name="files"
                dropzoneTitle="Evidências da Cotação (Anexos)"
              />
            </Grid>
            {cotacaoState.tag === 'with-data' && (
              <Fragment>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12}>
                  <FilesList anexos={cotacaoState.entity.Anexos} />
                </Grid>
              </Fragment>
            )}
          </Grid>
          <AprovacaoDialog
            title="Aprovar Cotação"
            message={`O Status será alterado para ${StatusCotacao.APROVADO}. Confirma a operação?`}
            open={isModalApproveOpen}
            handleClose={() => {
              setIsModalApproveOpen(false)
            }}
            primaryAction={async values => {
              handleSubmitUpdateStatus(
                UpdateStatus.APROVAR,
                () => {
                  setIsModalApproveOpen(false)
                  setIsModalRegisterLoteOpen(true)
                },
                values
              )
            }}
            primaryActionButtonLabel="Confirmar"
            closeActionButtonLabel="Cancelar"
            initialValues={{ valorFreteFechado: values.valorFreteFechado! }}
            yupSchema={yup.object().shape({
              valorFreteFechado: yup
                .number()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .nullable()
                .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            })}
          />
          <AprovacaoDialog
            open={isModalDisapproveOpen}
            handleClose={() => setIsModalDisapproveOpen(false)}
            primaryAction={async values => {
              handleSubmitUpdateStatus(
                UpdateStatus.REPROVAR,
                () => setIsModalDisapproveOpen(false),
                values
              )
            }}
            title="Incluir Frete Fechado"
            primaryActionButtonLabel="Confirmar"
            message={`O Status será alterado para ${StatusCotacao.REPROVADO}. Confirma a operação?`}
            closeActionButtonLabel="Cancelar"
            initialValues={{ valorFreteFechado: values.valorFreteFechado! }}
            yupSchema={yup.object().shape({
              valorFreteFechado: yup
                .number()
                .transform((curr, orig) => (orig === '' ? null : curr))
                .nullable()
                .optional()
            })}
          />
          <SimpleDialog
            message={'Você deseja cadastrar lote nesse momento?'}
            open={isModalRegisterLoteOpen}
            buttonLabel="NÃO"
            handleClose={() => setIsModalRegisterLoteOpen(false)}
            primaryAction={() => handleCreateLoteFromCotacao()}
            title="Cadastrar Lote"
            primaryActionButtonLabel="SIM"
          />
        </FormWrap>
      )}
    </Formik>
  )
}
