import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { ProgramacaoFormData } from '../../types/formData'

interface Props {
  loteId: string
}

export const MultiplasEntregasListener = ({ loteId }: Props) => {
  const formik = useFormikContext<ProgramacaoFormData>()

  useEffect(() => {
    if (formik.values.lotes[loteId].multiplasEntregas === false) {
      const lastPernaIndex = formik.values.lotes[loteId].pernas.length - 1
      const newDestinos = formik.values.lotes[loteId].pernas[
        lastPernaIndex
      ].destinos.slice(0, 1)

      formik.setFieldValue(
        `lotes.${loteId}.pernas.${lastPernaIndex}.destinos`,
        newDestinos
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.lotes[loteId].multiplasEntregas, loteId])

  return null
}
