import {
  Box,
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext
} from 'formik'
import MaskInput from './Formik/Forms/MaskInput'

interface TelefonesInputProps {
  name: string
}

export function isCellPhone(number: string): boolean {
  if (number) {
    const phoneDigits = number.replace(/[^\d]+/g, '')
    return phoneDigits[2] === '9'
  }

  return false
}

export default function TelefonesInput({ name }: TelefonesInputProps) {
  const [field, meta] = useField({ name })
  const hasTelefones = !!field.value?.length
  const formik = useFormikContext<{
    tipo: string
  }>()

  function addTelefone(arrayHelpers: FieldArrayRenderProps) {
    return () => {
      arrayHelpers.push({ telefone: '' })
    }
  }

  function deleteTelefone(index: number, arrayHelpers: FieldArrayRenderProps) {
    return () => {
      const telefone = field.value[index]

      if (telefone.id) {
        arrayHelpers.form.setFieldValue('deletedTelefones', [
          ...(arrayHelpers.form.values?.deletedTelefones || []),
          telefone.id
        ])
      }

      arrayHelpers.remove(index)
    }
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {hasTelefones &&
            field.value.map((obj: { telefone: string }, index: number) => {
              return (
                <Grid xs={12} item key={index}>
                  <Box display="flex">
                    <MaskInput
                      name={`${name}.${index}.telefone`}
                      label={`Telefone ${index + 1}`}
                      mask={
                        formik.values.tipo === 'PE'
                          ? '+9999999999999999999999'
                          : isCellPhone(obj.telefone)
                          ? '(99) 9 9999-9999'
                          : '(99) 9999-9999'
                      }
                      endAdornment={
                        <Tooltip title="Excluir" aria-label="Excluir">
                          <InputAdornment position="end">
                            <IconButton
                              onClick={deleteTelefone(index, arrayHelpers)}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      }
                    />
                  </Box>
                </Grid>
              )
            })}

          <Grid xs={12} item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={addTelefone(arrayHelpers)}
              data-cy="add-telefonef"
            >
              Adicionar Telefone
            </Button>

            <FormHelperText error>
              {!field.value?.length && meta.error}
            </FormHelperText>
          </Grid>
        </Grid>
      )}
    />
  )
}
