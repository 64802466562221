import * as XLSX from 'xlsx'

export const xlsxDownload = <T>(list: T[], name: string) => {
  const workSheet = XLSX.utils.json_to_sheet(list)
  const workBook = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(workBook, workSheet, name)

  //Buffer
  XLSX.write(workBook, { bookType: 'xlsx', type: 'buffer' })

  //Binary string
  XLSX.write(workBook, { bookType: 'xlsx', type: 'binary' })

  //Download
  XLSX.writeFile(workBook, `${name}.xlsx`)
}
