import { Grid } from '@material-ui/core'
import { CheckboxInput } from 'src/packages/g10-components/CheckboxInput'

interface Props {
  prefix?: string
}

export const DadosGrupoNegociador = (props: Props) => {
  const prefix = props.prefix ? `config.${props.prefix}` : 'config'

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.grupoNegociador.codigo`}
          label="Código"
        />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`${prefix}.grupoNegociador.nome`} label="Nome" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`${prefix}.grupoNegociador.raizCnpj`}
          label="Raiz CNPJ"
        />
      </Grid>
    </Grid>
  )
}
