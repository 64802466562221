import {
  Grid,
  InputBaseComponentProps,
  InputLabelProps,
  LinearProgress,
  makeStyles
} from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useField, useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { apiSauceInstance } from '../../../services/api'

interface Value {
  name: string
  sigla: string
  id: string
  [key: string]: any
}

interface Props {
  altValue?: string
  endAdornment?: React.ReactNode
  url: string
  name: string
  label: string
  filters?: Record<string, any>
  disabled?: boolean
  placeholder?: string
  fullWidth?: boolean
  labelField: string
  InputProps?: InputBaseComponentProps
  InputLabelProps?: InputLabelProps
  allowSearchWhenDisabled?: boolean
  isConsulting?: boolean
  limit?: number
  joinStateAndCity?: boolean
  joinStateAndUf?: boolean
  joinInfoContrato?: boolean
  handleOnChange?: (value: any) => void
}

const useStyles = makeStyles(theme => ({
  input: {
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.action.disabled
      }
    }
  }
}))

const AsyncSearchInput: React.FC<Props> = props => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<Value[]>([])
  const [initialLoading, setInitialLoading] = useState(false)
  const [field, meta, helper] = useField(props.name)
  const formik = useFormikContext()
  const [loading, setLoading] = useState(false)
  const value = typeof field.value === 'string' ? null : field.value

  const classes = useStyles()
  function getError(error?: string | { name: string; value: string }) {
    if (!error) {
      return ''
    }

    if (typeof error === 'string') {
      return error
    } else {
      return error?.value
    }
  }

  async function handleSearchOneFromApi(idFromEntity: string): Promise<void> {
    if (props.disabled && !props.allowSearchWhenDisabled) {
      return
    }

    setInitialLoading(true)

    const response = await apiSauceInstance.get<any>(
      `${props.url}/${idFromEntity}`,
      props.filters
    )
    const data = response.data
    setInitialLoading(false)
    return formik.setFieldValue(props.name, {
      name: data[props.labelField],
      value: data.id
    })
  }

  async function handleSearch(search?: string): Promise<void> {
    setLoading(true)
    const response = await apiSauceInstance.get<any>(props.url, {
      [props.labelField]: search,
      limit: props.limit ?? 10,
      ...props.filters
    })
    setLoading(false)

    setOptions(
      response.data?.edges?.map((currentValue: any) => {
        let name
        if (props.joinStateAndCity) {
          name = `${currentValue.node[props.labelField]} - ${
            currentValue.node.Estado.sigla
          }`
        } else if (props.joinStateAndUf) {
          name = `${currentValue.node[props.labelField]} - ${
            currentValue.node.sigla
          }`
        } else if (props.joinInfoContrato) {
          name = `${currentValue.node.codigo} - ${currentValue.node.GrupoNegociador.nome} - ${currentValue.node.descricao} - ${currentValue.node.AgrupadorProdutos.nome}`
        } else {
          name = currentValue.node[props.labelField]
        }

        return {
          name,
          sigla: currentValue.node.sigla,
          value: currentValue.node.id
        }
      }) ?? []
    )
  }

  async function handleOnChange(e: any, value: any) {
    if (!!props.handleOnChange) props.handleOnChange(value)
    return helper.setValue(value)
  }

  useEffect(() => {
    if (!open) {
      setOptions([])
    }
  }, [open])

  useEffect(() => {
    if (typeof field.value === 'string' && field.value) {
      handleSearchOneFromApi(field.value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, props.disabled, props.filters])

  return (
    <>
      <Autocomplete
        {...props}
        open={open}
        disabled={props.disabled}
        onOpen={() => {
          setOpen(true)
          !props.disabled && handleSearch()
        }}
        onClose={() => setOpen(false)}
        onChange={(e: any, value: any) => handleOnChange(e, value)}
        onInputChange={debounce(
          (_e, value) => !props.disabled && handleSearch(value),
          700
        )}
        onBlur={field.onBlur}
        getOptionSelected={(option, value) => option.name === value?.name}
        getOptionLabel={option => option.name}
        options={options}
        loading={loading}
        fullWidth={props.fullWidth}
        value={value}
        clearText="Limpar"
        closeText="Fechar"
        loadingText="Carregando..."
        noOptionsText="Sem opções"
        openText="Abrir"
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        renderInput={params => (
          <TextField
            {...params}
            error={meta.touched && Boolean(getError(meta.error))}
            helperText={meta.touched && getError(meta.error)}
            label={props.label}
            name={props.name}
            variant="outlined"
            placeholder={props.placeholder}
            className={classes.input}
            InputLabelProps={{
              ...props.InputLabelProps,
              disabled: props.disabled ? !props.isConsulting : false
            }}
            InputProps={{
              ...params.InputProps,
              disabled: props.disabled ? !props.isConsulting : false,
              endAdornment: (
                <>
                  {open && loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              )
            }}
          />
        )}
      />
      {initialLoading && (
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      )}
    </>
  )
}

export default AsyncSearchInput
