import { CapacidadesColumnsName, FaixasKmColumnsName } from '../types'

export const columnsCapacidadesNames: CapacidadesColumnsName[] = [
  'Capacidade 1',
  'Capacidade 2',
  'Capacidade 3',
  'Capacidade 4',
  'Capacidade 5',
  'Capacidade 6',
  'Capacidade 7',
  'Capacidade 8',
  'Capacidade 9',
  'Capacidade 10'
]

export const columnsFaixasNames: FaixasKmColumnsName[] = [
  'KM Inicial',
  'KM Final',
  'Tarifa 1',
  'Tarifa 2',
  'Tarifa 3',
  'Tarifa 4',
  'Tarifa 5',
  'Tarifa 6',
  'Tarifa 7',
  'Tarifa 8',
  'Tarifa 9',
  'Tarifa 10'
]
