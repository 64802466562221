export const StatusColors = {
  ABERTO: '#ededed',
  ABERTO_DARK: '#9e9e9e',
  REABERTO: '#e3e3e3',
  REABERTO_DARK: '#aeaeae',
  ANALISE: '#fff176',
  ANALISE_DARK: '#fbc02d',
  REANALISE: '#ffb74d',
  REANALISE_DARK: '#f57c00',
  PENDENTE: '#e57373',
  PENDENTE_DARK: '#e53935',
  LIBERADA: '#81c784',
  LIBERADA_DARK: '#388e3c',
  LIBERADAGESTOR: '#64b5f6',
  LIBERADAGESTOR_DARK: '#1976d2',
  REPROVADA: '#9fa8da',
  REPROVADA_DARK: '#3949ab',
  CANCELADA: '#bdbdbd',
  CANCELADA_DARK: '#616161'
}

export function returnStatusColor(status: string) {
  switch (status) {
    case 'ANALISAR':
      return { color: StatusColors.ANALISE, hover: StatusColors.ANALISE_DARK }
    case 'REANALISAR':
      return {
        color: StatusColors.REANALISE,
        hover: StatusColors.REANALISE_DARK
      }
    case 'LIBERAR':
      return { color: StatusColors.LIBERADA, hover: StatusColors.LIBERADA_DARK }
    case 'CANCELAR':
      return {
        color: StatusColors.CANCELADA,
        hover: StatusColors.CANCELADA_DARK
      }
    case 'FINALIZADO':
      return { color: StatusColors.LIBERADA, hover: StatusColors.LIBERADA_DARK }
    case 'PENDENTE':
      return { color: StatusColors.PENDENTE, hover: StatusColors.PENDENTE_DARK }
    case 'REPROVAR':
      return {
        color: StatusColors.REPROVADA,
        hover: StatusColors.REPROVADA_DARK
      }
    case 'LIB. GESTOR':
      return {
        color: StatusColors.LIBERADAGESTOR,
        hover: StatusColors.LIBERADAGESTOR_DARK
      }
    default:
      return {
        color: StatusColors.ABERTO,
        hover: StatusColors.ABERTO_DARK
      }
  }
}
