import { makeStyles, TablePagination } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { tableStore } from 'src/stores/TableStore'

const useStyles = makeStyles(theme => ({
  root: {
    '& > div p + div + p': {
      display: 'none'
    }
  },
  none: {
    display: 'none'
  },
  pagination: {
    position: 'absolute',
    left: 0
  }
}))

export function PaginationCustom({
  count,
  rowsPerPageOptions,
  keyTable
}: {
  count: number
  rowsPerPageOptions: number[]
  keyTable?: string
}) {
  const classes = useStyles()
  const history = useHistory()
  const { location } = history
  const params = new URLSearchParams(history.location.search)
  const limit =
    keyTable && Number(tableStore.getState(keyTable)?.limit)
      ? Number(tableStore.getState(keyTable)?.limit)
      : 10
  const rowsPerPage = Number(params.get('limit')) || limit

  const pageCount = Math.ceil(count / rowsPerPage)
  const page = Number(params.get('page')) || 1

  const handleRowsPerPageChange = (event: any) => {
    const countLines = parseInt(event.target.value, 10)
    const searchParams = new URLSearchParams(location.search)
    searchParams.set('limit', String(countLines))

    history.push(`${location.pathname}?${searchParams.toString()}`)
  }

  return (
    <TablePagination
      className={classes.pagination}
      rowsPerPageOptions={rowsPerPageOptions}
      component="div"
      count={pageCount}
      rowsPerPage={rowsPerPage}
      labelRowsPerPage="Linhas por página"
      page={page}
      onPageChange={() => {}}
      onRowsPerPageChange={handleRowsPerPageChange}
      nextIconButtonProps={{
        hidden: true,
        disabled: true
      }}
      backIconButtonProps={{
        hidden: true,
        disabled: true
      }}
      classes={{
        root: classes.root,
        actions: classes.none
      }}
    />
  )
}
