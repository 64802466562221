import { observer } from 'mobx-react-lite'
import { Paper } from 'src/components'
import { ConsultaProgramacao } from '../components/ConsultaProgramacao'

export const ProgramacoesView = observer(() => {
  return (
    <Paper>
      <ConsultaProgramacao />
    </Paper>
  )
})

export default ProgramacoesView
