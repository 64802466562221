import { Grid, Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

interface Props {
  title: string
  rows?: number
}

export function ChartSkeleton({ title, rows }: Props) {
  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography align="center" variant="subtitle1">
          {title}
        </Typography>
      </Grid>
      {Array.from({ length: rows || 10 }).map((_, index) => (
        <Grid item xs={index % 2 !== 0 ? 10 : 2} key={index}>
          <Skeleton variant="text" />
        </Grid>
      ))}
    </Grid>
  )
}
