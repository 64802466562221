import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useSnackbar } from '@elentari/core'

export default function CNAEListener() {
  const formik =
    useFormikContext<{ cnaePrincipal: string; descricaoCnae: string }>()
  const cnaeAPI = axios.create({
    baseURL: 'https://servicodados.ibge.gov.br/api/v2/cnae/subclasses/'
  })
  const [, snackbarActions] = useSnackbar()
  const [hasBeenChanged, setHasBeenChanged] = useState(false)

  async function fetchCNAE(cnae: string) {
    try {
      const response = await cnaeAPI.get(`${cnae}`)
      const data = response.data
      const newValueField = Array.isArray(data)
        ? data[0].descricao
        : data.descricao

      formik.setFieldValue('descricaoCnae', newValueField)
    } catch (err) {
      snackbarActions.setMessage('A descrição do CNAE não foi encontrada')
      formik.setFieldValue('descricaoCnae', '')
    }
  }

  useEffect(() => {
    if (
      formik.values.cnaePrincipal !== formik.initialValues.cnaePrincipal ||
      hasBeenChanged
    ) {
      setHasBeenChanged(true)

      if (formik.values.cnaePrincipal?.length === 10) {
        const replace = formik.values.cnaePrincipal.replace(/[^0-9]/g, '')
        fetchCNAE(replace)
      }
    }
  }, [formik.values.cnaePrincipal]) // eslint-disable-line

  return null
}
