import { Button, Tooltip } from '@material-ui/core'
import { LocalShipping } from '@material-ui/icons'
import { useState } from 'react'
import { useProgramacao } from '../../hooks/useProgramacao'
import { SimularFreteModal } from '../simular-frete/SimularFreteModal'

interface Props {
  tabelaId?: string
  pernaIndex: Nullable<number>
  loteId: string
}

export const SimularFreteButton = ({ tabelaId, pernaIndex, loteId }: Props) => {
  const [programacaoState] = useProgramacao()
  const isUpdate = programacaoState.tag === 'with-data'
  const [isModalOpen, setIsModalOpen] = useState(false)

  const onClick = () => {
    setIsModalOpen(true)
  }

  const handleClose = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Tooltip title="Simular Frete" aria-label="Simular Frete">
        <Button
          variant="contained"
          color="primary"
          style={{ borderRadius: 50 }}
          disabled={isUpdate}
          onClick={onClick}
        >
          <LocalShipping />
        </Button>
      </Tooltip>
      <SimularFreteModal
        isOpen={isModalOpen}
        handleClickBack={handleClose}
        tabelaId={tabelaId || ''}
        pernaIndex={pernaIndex}
        loteId={loteId}
      />
    </>
  )
}
