import React from 'react'
import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import { SelectFilterGeneric } from 'src/components/ColumnsFiltersTable'
import { EditarStatusRecord } from 'src/components/EditarStatusRecord'
import When from 'src/components/When'
import { DeleteRecord } from '../../components'
import { statusLocalOptions } from './types'

interface ILocaisColumnProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const LocaisColumns = ({
  onDelete,
  authorization
}: ILocaisColumnProps): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo'
    },
    {
      Header: 'Descrição Local',
      accessor: 'descricao'
    },
    {
      Header: 'Cidade / Estado',
      accessor: 'local'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: statusLocalOptions
        })
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <EditarStatusRecord
                  isStatusChangeable={props.row.original.isStatusChangeable}
                  status={props.row.original.status}
                  id={props.row.original.id}
                  optionsStatus={props.row.original.nextPossibleStatus}
                  isLocais
                />
              </When>
              <When value={authorization?.canUpdate} equals>
                <DeleteRecord
                  onDelete={() => onDelete(props.row.original.id)}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
