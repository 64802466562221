import { makeAutoObservable } from 'mobx'
import { LayerProps } from 'react-map-gl'
import { City } from './types'

export type TypeFilter = 'ORIGEM' | 'DESTINO'

class MapaProgramacoesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  selectedCity: City | null = null
  setSelectedCity(city: City | null) {
    this.selectedCity = city
  }

  typeFilter: TypeFilter = 'ORIGEM'
  setTypeFilter(type: TypeFilter) {
    this.typeFilter = type
  }

  isDrawerOpen = false
  setDrawerOpen(isOpen: boolean) {
    this.isDrawerOpen = isOpen
  }

  data: City[] = []
  setData(data: City[]) {
    this.data = data
  }

  get linesLayer() {
    return {
      id: `lines`,
      type: 'line',
      source: 'programacoes',
      paint: {
        'line-width': 1,
        'line-color': 'green'
      },
      filter: [
        'case',
        ['!', ['to-boolean', ['literal', this.selectedCity]]],
        true,
        ['==', ['get', 'id'], this.selectedCity?.id ?? null],
        true,
        false
      ]
    } as LayerProps
  }
}

const mapaProgramacoesStore = new MapaProgramacoesStore()
export default mapaProgramacoesStore
