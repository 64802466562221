import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { UnidadeNegocioForm } from './UnidadeNegocioForm'
import { useUnidadeNegocio } from '../hooks/useUnidadeNegocio'

export function UnidadeNegocioMasterDetail() {
  const [unidadeNegocioState, unidadeNegocioActions] = useUnidadeNegocio()
  const [, appBarActions] = useAppBar()
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      unidadeNegocioState,
      u => u.entity.id,
      u => u.entity.descricao
    )
  ])
  useFetchAndReset(unidadeNegocioActions.fetch, unidadeNegocioActions.reset)
  return handleStateErrorsToRender(unidadeNegocioState, <UnidadeNegocioForm />)
}
