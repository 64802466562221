import { Button, DialogActions } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import SearchIcon from '@material-ui/icons/Search'
import { Form, Formik } from 'formik'
import { parse } from 'qs'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import TextInput from 'src/components/Formik/Forms/TextInput'
import * as yup from 'yup'
import DateInput from 'src/components/DateInput'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import { MessagesYup } from '../../../messages'
import selecaoLotesStore from '../../store/selecao-lotes-store'
import { BuscarLotesForm } from '../../types'

const schema = yup.object().shape({
  centroCustoId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .default(undefined),
  dataCarregamento: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA),
  grupoNegociadorId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .default(undefined),
  produtoId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .default(undefined),
  estadoOrigemId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .default(undefined),
  estadoDestinoId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .default(undefined),
  origemId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .default(undefined),
  destinoId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .default(undefined),
  codigo: yup.number().nullable().default(undefined),
  numeroPedido: yup.string().nullable().default(undefined),
  localCarregamento: yup.string().nullable().default(undefined),
  localDescarregamento: yup.string().nullable().default(undefined)
})

const initialValues: any = {
  centroCustoId: '',
  dataCarregamento: null,
  grupoNegociadorId: '',
  produtoId: '',
  estadoOrigemId: '',
  estadoDestinoId: '',
  origemId: '',
  destinoId: '',
  codigo: null,
  numeroPedido: '',
  localCarregamento: '',
  localDescarregamento: ''
}

export const BuscarLotes = () => {
  const location = useLocation()

  async function handleSubmit(programacao: BuscarLotesForm) {
    const dataTratado = {
      centroCustoId: programacao.centroCustoId.value ?? null,
      dataCarregamento: programacao.dataCarregamento ?? null,
      grupoNegociadorId: programacao.grupoNegociadorId.value ?? null,
      produtoId: programacao.produtoId.value ?? null,
      estadoOrigemId: programacao.estadoOrigemId.value ?? null,
      estadoDestinoId: programacao.estadoDestinoId.value ?? null,
      origemId: programacao.origemId.value ?? null,
      destinoId: programacao.destinoId.value ?? null,
      codigo: programacao.codigo ?? null,
      numeroPedido: programacao.numeroPedido ?? null,
      status: [
        'ATIVO',
        'ABERTO',
        'REABERTO',
        'PENDENTE',
        'ANALISE',
        'REANALISE'
      ],
      localCarregamento: programacao.localCarregamento ?? null,
      localDescarregamento: programacao.localDescarregamento ?? null
    }

    selecaoLotesStore.lotes.setFilters(dataTratado as any)
  }

  useEffect(() => {
    const param = parse(location.search.substring(1))
    const filtro = {
      status: [
        'ATIVO',
        'ABERTO',
        'REABERTO',
        'PENDENTE',
        'ANALISE',
        'REANALISE'
      ]
    }

    selecaoLotesStore.lotes.setPage(Number(param?.page ?? 1))
    selecaoLotesStore.lotes.setFilters(filtro)
  }, [location])

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <TextInput name="numeroPedido" label="Número do Pedido" />
              </Grid>

              <Grid item xs={12} md={3}>
                <TextInput name="codigo" label="Código" type="number" />
              </Grid>

              <Grid item xs={12} md={3}>
                <AsyncSearchInput
                  name="centroCustoId"
                  label="Centros de Custo"
                  url="/centros-custo/ativos"
                  labelField="descricao"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <DateInput label="Data Carregamento" name="dataCarregamento" />
              </Grid>

              <Grid item xs={12} md={3}>
                <AsyncSearchInput
                  name="grupoNegociadorId"
                  label="Grupo Negociador"
                  url="/grupos-negociadores"
                  labelField="nome"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <AsyncSearchInput
                  name="produtoId"
                  label="Produto"
                  url="/produtos"
                  labelField="nome"
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <AsyncSearchInput
                  name="origemId"
                  label="Origem"
                  url="/cidades"
                  labelField="nome"
                  joinStateAndCity
                />
              </Grid>

              <Grid item xs={12} md={3}>
                <AsyncSearchInput
                  name="destinoId"
                  label="Destino"
                  url="/cidades"
                  labelField="nome"
                  joinStateAndCity
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name="localCarregamento"
                  label="Local de Carregamento"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name="localDescarregamento"
                  label="Local de Descarregamento"
                />
              </Grid>

              <Grid justifyContent="flex-end" item container spacing={2}>
                <DialogActions>
                  <Button
                    fullWidth
                    type="submit"
                    variant="outlined"
                    color="primary"
                    endIcon={<SearchIcon />}
                    style={{ borderRadius: 50 }}
                  >
                    Pesquisar Lote
                  </Button>
                </DialogActions>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  )
}
