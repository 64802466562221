import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs, stringify } from 'qs'
import { TotalNegociacao } from 'src/modules/lotes/types'
import { apiSauceInstance } from '../../../services/api'
import { paginationParams } from '../../../utils/paginationQueryParams'
import {
  AlterarStatusData,
  ProgramacaoDetail,
  ProgramacaoList,
  StatusProgramacao
} from '../types'
import { CalcularValoresProgramacaoDto, ProgramacaoDto } from '../types/dto'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, ...rest } = params

  return {
    ...paginationParams(rest),
    limit: limit || '10'
  }
}

export const programacoesRepository = makeRepository<
  EdgesPage<ProgramacaoList>,
  ProgramacaoDto,
  ProgramacaoDetail,
  string
>({ resource: 'programacoes', mapQueryParams }, apiSauceInstance)

export const alterarStatus = async (id: string, data: AlterarStatusData) => {
  return apiSauceInstance.patch<
    { status: StatusProgramacao },
    { message: string; error: any }
  >(`/programacoes/${id}/alterar-status/`, data)
}

export const getComposicaoKmmByCPF = (cpf: string) => {
  return apiSauceInstance.get(`/kmm/composicao-cpf/${cpf}`)
}

export const getTotalProgramacao = (data: CalcularValoresProgramacaoDto) => {
  return apiSauceInstance.get<TotalNegociacao, ApiErrorResponse>(
    '/programacoes/total-programacao',
    data,
    { paramsSerializer: stringify }
  )
}
