import { Ability } from '@casl/ability'
import { Link, makeStyles, useMediaQuery } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { Switch } from 'react-router-dom'
import userStore from 'src/stores/user'
import AppWrap from '../../components/AppWrap'
import { Item } from '../../components/AppWrap/MenuItems'
import { RouteByMenu } from '../../components/RouterByMenu'
import logo from '../../images/g10-logo.png'
import menuItems from './MenuItems'

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: '100%',
    filter: 'brightness(100)'
  },
  logoOpen: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 128,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  logoClose: {
    width: 40,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))
const canRead = (ability?: Ability) => (m: Item) => ability?.can('read', m.name)
const filterMenuItemByAbility = (ability: Ability | undefined, items: Item[]) =>
  items
    .map(m => {
      const copy = { ...m }

      if (copy.group) {
        copy.items = copy.items.filter(canRead(ability))
      }
      return copy
    })
    .filter(canRead(ability))

const HomeNavigation = observer(() => {
  const matches = useMediaQuery('(max-width:900px)')
  const [userMenuItems, setUserMenuItems] = useState<Item[]>([])
  const [open, setOpen] = useState(localStorage.toggleDrawer === '1')
  const classes = useStyles()
  const onToggleDrawer = (status: string) => {
    setOpen(status === 'open')
  }

  useEffect(() => {
    if (userStore.ability) {
      setUserMenuItems(filterMenuItemByAbility(userStore.ability, menuItems))
    }
  }, [userStore.ability, setUserMenuItems]) // eslint-disable-line

  useEffect(() => {
    if (matches === true) {
      setOpen(false)
    }
  }, [matches])

  return (
    <AppWrap
      logo={
        <div className={open ? classes.logoOpen : classes.logoClose}>
          <Link href="/">
            <img src={logo} alt="G10 - Comercial" className={classes.logo} />
          </Link>
        </div>
      }
      menuItems={userMenuItems}
      isPositionButtonMenuDrawer={false}
      onToggleDrawer={onToggleDrawer}
      classes={null}
    >
      <div style={{ height: '100%' }}>
        <Switch>
          <RouteByMenu menuItems={userMenuItems}></RouteByMenu>
        </Switch>
      </div>
    </AppWrap>
  )
})

export default HomeNavigation
