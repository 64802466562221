import { TipoContratacao } from 'src/modules/lotes/types'
import { combineFullDateWithTime } from 'src/utils/formatters/formatDate'
import { ModalidadeTransportador } from '../types'
import {
  PernaDadosComplementaresProgramacaoDto,
  ProgramacaoDto
} from '../types/dto'
import { LoteProgramacaoFormData, ProgramacaoFormData } from '../types/formData'
import { modalidadeIsSubcontratado } from '../util/modalidadeIsSubcontratado'

export class ProgramacaoDtoAdapter {
  private adaptCommon(values: ProgramacaoFormData, isUpdate: boolean) {
    if (isUpdate) {
      return {
        id: values.id!,
        dataHoraCarregamento: combineFullDateWithTime(
          new Date(values.dataCarregamento),
          new Date(values.horaCarregamento)
        ),
        dataHoraDescarregamento: combineFullDateWithTime(
          new Date(values.dataDescarregamento),
          new Date(values.horaDescarregamento)
        ),
        capacidadeCargaVeiculo: values.capacidadeCargaVeiculo,
        observacoes: values.observacoes || null,
        nomeResponsavel: values.nomeResponsavel || null,
        telefoneResponsavel: values.telefoneResponsavel || null,
        telefoneMotorista: values.telefoneMotorista || null,
        centroCustoId: values.centroCustoId.value,
        tipoPagamento: values.tipoPagamento || null,
        numeroCartao: values.numeroCartao || null
      }
    }

    return {
      dataHoraCarregamento: combineFullDateWithTime(
        values.dataCarregamento,
        values.horaCarregamento
      ),
      dataHoraDescarregamento: combineFullDateWithTime(
        values.dataDescarregamento,
        values.horaDescarregamento
      ),
      capacidadeCargaVeiculo: values.capacidadeCargaVeiculo,
      observacoes: values.observacoes || undefined,
      nomeResponsavel: values.nomeResponsavel || undefined,
      telefoneResponsavel: values.telefoneResponsavel || undefined,
      motorista: values.motorista,
      cpfMotorista: values.cpfMotorista,
      telefoneMotorista: values.telefoneMotorista || undefined,
      centroCustoId: values.centroCustoId.value,
      embarcador: values.embarcador?.value,
      placaPrincipal: values.placaPrincipal,
      placa_1: values.placa_1,
      placa_2: values.placa_2,
      placa_3: values.placa_3,
      tipoPagamento: values.tipoPagamento || undefined,
      numeroCartao: values.numeroCartao || undefined
    }
  }

  private adaptLotesSpot(lotes: LoteProgramacaoFormData[]) {
    return {
      lotes: lotes.map(lote => ({
        id: lote.id,
        quantidadePrevista: lote.quantidadePrevista,
        valorFreteAcordado: lote.valorFreteAcordado,
        produtoId: lote.Produto?.id
      }))
    }
  }

  private adaptLotesContrato(values: ProgramacaoFormData, isUpdate: boolean) {
    const lotesArray = Object.values(values.lotes)
    const empty = isUpdate ? null : undefined

    return {
      ...this.adaptLotesSpot(lotesArray),
      modalidadeTransportador: values.modalidadeTransportador,
      tipoNegociacaoSubcontratado: values.tipoNegociacaoSubcontratado || empty,
      dadosComplementares: lotesArray.map(lote => ({
        loteId: lote.id,
        possuiTrocaNotaFiscal: lote.possuiTrocaNotaFiscal,
        multiplasEntregas: lote.multiplasEntregas,
        produtoId: lote.produtoId.value,
        pernas: lote.pernas.map(
          (perna, index): PernaDadosComplementaresProgramacaoDto => ({
            ordem: index + 1,
            localOrigem:
              index > 0
                ? lote.pernas[index - 1].destinos[0].localEntrega || empty
                : perna.localCarregamento || empty,
            cidadeOrigemId:
              index > 0
                ? lote.pernas[index - 1].destinos[0].cidadeDestino.value
                : perna.cidadeOrigem.value,
            destinos: perna.destinos.map(destino => ({
              cidadeDestinoId: destino.cidadeDestino.value,
              localDescarregamento: destino.localEntrega || empty,
              quantidade: destino.quantidade || null
            })),
            valores: {
              freteEmpresa: perna.freteEmpresa,
              valorPedagio: perna.valorPedagio,
              aliquotaIcms: perna.aliquotaIcms,
              freteGrupo:
                values.modalidadeTransportador ===
                  ModalidadeTransportador.GRUPO && perna.freteGrupoDiferente
                  ? perna.freteGrupo
                  : undefined,
              freteSubcontratado: modalidadeIsSubcontratado(
                values.modalidadeTransportador
              )
                ? perna.freteSubcontratado
                : undefined
            }
          })
        )
      }))
    }
  }

  adapt(values: ProgramacaoFormData, isUpdate: boolean): ProgramacaoDto {
    const commonData = this.adaptCommon(values, isUpdate)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    if (values.tipoContratacaoLotesSelecionados === TipoContratacao.CONTRATO) {
      const adaptedLotesContrato = this.adaptLotesContrato(values, isUpdate)

      return {
        ...commonData,
        ...adaptedLotesContrato,
        timeZone
      }
    }

    const lotesArray = Object.values(values.lotes)
    const adaptedLotesSpot = this.adaptLotesSpot(lotesArray)

    return {
      ...commonData,
      ...adaptedLotesSpot,
      timeZone
    }
  }
}
