import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { lotesStore } from '../stores/lotes'
import { LotesFormData } from '../types'

export function IcmsListener() {
  const formik = useFormikContext<LotesFormData>()

  useEffect(() => {
    lotesStore.setIcmsLote(formik.values.icms)
  }, [formik.values.icms])

  return null
}
