import qs from 'qs'

export const paginationParams = (params: qs.ParsedQs): qs.ParsedQs => {
  const { page = '1', size = '10', limit, ...rest } = params
  return {
    ...rest,
    skip: String((+page - 1) * +size),
    limit: limit ?? size,
    page: undefined
  }
}
