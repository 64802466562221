import { FetchState } from '@elentari/core/types'
import { useMemo } from 'react'
import { useAbility } from 'src/modules/login'
import { canSave, getAuthorizations } from 'src/utils/crudAuthorization'

const useCanSave = (state: FetchState<any>, module: string) => {
  const ability = useAbility()
  const authorization = getAuthorizations(ability, module)

  return useMemo(() => canSave(state, authorization), [state, authorization])
}

export default useCanSave
