import { useEffect } from 'react'
import { Backdrop, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import userStore from 'src/stores/user'

const useStyles = makeStyles(themes => ({
  backdrop: {
    zIndex: themes.zIndex.drawer + 1,
    color: '#fff'
  },
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: themes.palette.primary.main
  },
  message: {
    marginTop: 40,
    marginBottom: 40
  }
}))

const Callback = observer(() => {
  const classes = useStyles()

  useEffect(() => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const token = params.get('token')
    const refresh_token = params.get('refresh_token')
    const redirectRoute = params.get('redirect_route') ?? undefined

    if (token && refresh_token) {
      userStore.login(token, refresh_token, redirectRoute)
    }
  }, [])

  return (
    <Backdrop invisible className={classes.backdrop} open={true}>
      <CircularProgress />
    </Backdrop>
  )
})

export default Callback
