import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid'
import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { formatedValuesGrid, getOnlyDigits } from 'src/utils/utils'
import { Column } from '../components/LeituraPlanilhaFretePedagioDialog/types'
import { DeleteButtonTabelaFrete } from '../master-detail/DeleteButtonTabelaFrete'
import { DeleteButtonTabelaPedagio } from '../master-detail/DeleteButtonTabelaPedagio'
import {
  TabelaContratoType,
  TabelaDetail,
  TabelaFreteDetail,
  TabelaPedagioDetail,
  TarifaFreteDetail
} from '../types/tabelaTypes'
import { ContratoDetail } from '../types/types'
import { CustomEditComponent } from '../master-detail/CustomEditComponents'
import { validateTarifa } from '../utils'
import { validateFaixa } from '../utils/validateFaixa'
import { Tooltip, Typography } from '@material-ui/core'

class ContratosStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  contratoId = ''
  setContratoId(id: string) {
    this.contratoId = id
  }

  tabelaFrete: TabelaContratoType = {
    rows: [],
    columns: []
  }

  tabelaPedagio: TabelaContratoType = {
    rows: [],
    columns: []
  }

  contratos = new ApisauceDataStore<ContratoDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/contratos',
    resultsField: 'edges'
  })

  clearTabelas() {
    this.tabelaFrete.rows = []
    this.tabelaFrete.columns = []
    this.clearTabelaPedagio()
  }

  clearTabelaPedagio() {
    this.tabelaPedagio.rows = []
    this.tabelaPedagio.columns = []
  }

  clearRowsTabelaPedagio() {
    this.tabelaPedagio.rows = []
  }

  createManyColumns(table?: Column[], capacidadesTabelaFrete?: number[]) {
    if (!table) return

    const isTabelaPedagio = capacidadesTabelaFrete?.length
    const rows: Record<string, string>[] = []

    table.forEach(column => {
      let columnName = column.id
      if (column.name !== 'KM Inicial' && column.name !== 'KM Final') {
        columnName = column.name?.toString()

        if (!isTabelaPedagio) {
          this.addCapacidade(column.name?.toString())
        }
      }

      column.values.forEach((value, index) => {
        const rowAlreadyExists = rows.find(
          row => row.index === index.toString()
        )

        if (rowAlreadyExists) {
          rowAlreadyExists[columnName] = value?.toString()
          return
        }

        const row: Record<string, string> = {}
        row.id = (this.tabelaFrete.rows.length + (rows.length + 1)).toString()
        row.index = index?.toString()
        row[column.id] = value?.toString()
        rows.push(row)
      })
    })

    if (isTabelaPedagio) {
      return this.tabelaPedagio.rows.push(...rows)
    }
    this.tabelaFrete.rows.push(...rows)
  }

  addFaixaKmTabelaFreteInitialValue(kmInicial: string, kmFinal: string) {
    if (!this.tabelaFrete) {
      return
    }

    const novaTabela = { ...this.tabelaFrete }

    const linha = this.createLinhaFaixaKm(
      novaTabela.columns,
      novaTabela.rows.length + 1
    )

    linha['kmInicial'] = kmInicial
    linha['kmFinal'] = kmFinal

    novaTabela.rows.push(linha)

    this.tabelaFrete = novaTabela
  }

  addFaixaKmTabelaFrete() {
    if (!this.tabelaFrete) {
      return
    }

    const novaTabela = { ...this.tabelaFrete }

    const linha = this.createLinhaFaixaKm(
      novaTabela.columns,
      novaTabela.rows.length + 1
    )

    novaTabela.rows.push(linha)

    this.tabelaFrete = novaTabela
  }

  addFaixaKmTabelaPedagio() {
    if (!this.tabelaPedagio) {
      return
    }

    const novaTabela = { ...this.tabelaPedagio }

    const linha = this.createLinhaFaixaKm(
      novaTabela.columns,
      novaTabela.rows.length + 1
    )

    novaTabela.rows.push(linha)

    this.tabelaPedagio = novaTabela
  }

  private createLinhaFaixaKm(colunas: any[], qtdadeLinhas: number) {
    const linha: Record<string, string> = {
      id: (qtdadeLinhas + 1).toString()
    }

    colunas.forEach(coluna => {
      linha[coluna.field] = ''
    })

    return linha
  }

  hasCapacidade(valor: string) {
    return this.tabelaFrete.columns.findIndex(c => c.field === valor) > 0
  }

  addCapacidade(valor: string) {
    this.addCapacidadeTabelaFrete(valor)
    this.addCapacidadeTabelaPedagio(valor)
  }

  private addCapacidadeTabelaFrete(valor: string) {
    if (!this.tabelaFrete) {
      return
    }

    this.tabelaFrete = this.criaTabelaComColuna(valor, this.tabelaFrete)
  }

  private addCapacidadeTabelaPedagio(valor: string) {
    if (!this.tabelaPedagio) {
      return
    }

    this.tabelaPedagio = this.criaTabelaComColuna(valor, this.tabelaPedagio)
  }

  private criaTabelaComColuna(valor: string, tabela: TabelaContratoType) {
    const novaTabela = { ...tabela }
    novaTabela.columns.push({
      field: valor,
      headerName: valor,
      sortable: false,
      filterable: false,
      disableColumnMenu: false,
      disableReorder: true,
      disableExport: true,
      width: 150,
      editable: true,
      headerAlign: 'left',
      align: 'left',
      renderEditCell: props => (
        <CustomEditComponent validator={validateTarifa} {...props} />
      ),
      renderCell: props => (
        <Tooltip title="Tarifa/valor" placement="bottom-start">
          <Typography>{props.formattedValue}</Typography>
        </Tooltip>
      ),
      valueFormatter: (params: GridValueFormatterParams) =>
        formatedValuesGrid(params)
    })

    const colunaActions = novaTabela.columns[novaTabela.columns.length - 2]

    novaTabela.columns[novaTabela.columns.length - 2] =
      novaTabela.columns[novaTabela.columns.length - 1]
    novaTabela.columns[novaTabela.columns.length - 1] = colunaActions

    return novaTabela
  }

  capacidadeEmEdicao: string = ''
  updateCapacidade(novaCapacidade: string) {
    const idx = this.tabelaFrete.columns.findIndex(
      column => column.field === this.capacidadeEmEdicao
    )

    if (idx) {
      const prevCapacidade = this.tabelaFrete.columns[idx].field

      this.tabelaFrete.columns[idx].field = novaCapacidade
      this.tabelaFrete.columns[idx].headerName = novaCapacidade
      this.tabelaFrete.rows.forEach(row => {
        row[novaCapacidade] = row[prevCapacidade]
      })

      this.tabelaPedagio.columns[idx].field = novaCapacidade
      this.tabelaPedagio.columns[idx].headerName = novaCapacidade
      this.tabelaPedagio.rows.forEach(row => {
        row[novaCapacidade] = row[prevCapacidade]
      })
    }
  }

  removeCapacidade(valor: string) {
    this.removeCapacidadeTabelaFrete(valor)
    this.removeCapacidadeTabelaPedagio(valor)
  }

  private removeCapacidadeTabelaFrete(valor: string) {
    if (!this.tabelaFrete) {
      return
    }

    this.tabelaFrete = this.criaNovaTabelaRemovendoColuna(
      valor,
      this.tabelaFrete
    )
  }

  private removeCapacidadeTabelaPedagio(valor: string) {
    if (!this.tabelaPedagio) {
      return
    }

    this.tabelaPedagio = this.criaNovaTabelaRemovendoColuna(
      valor,
      this.tabelaPedagio
    )
  }

  private criaNovaTabelaRemovendoColuna(
    valor: string,
    tabela: TabelaContratoType
  ) {
    const indexColumn = tabela.columns.findIndex(
      column => column.headerName === valor
    )

    if (!indexColumn) {
      return { ...tabela }
    }

    const novaTabela = { ...tabela }
    novaTabela.columns.splice(indexColumn, 1)
    novaTabela.rows.map(row => {
      delete row[valor]
      return row
    })

    return novaTabela
  }

  removeLinhaTabelaPedagio(rowId: string) {
    if (this.tabelaPedagio !== null) {
      let newRows = [...this.tabelaPedagio.rows]
      newRows = newRows.filter(row => row.id !== rowId)
      this.tabelaPedagio.rows = newRows
    }
  }

  removeLinhaTabelaFrete(rowId: string) {
    if (this.tabelaFrete !== null) {
      let newRows = [...this.tabelaFrete.rows]
      newRows = newRows.filter(row => row.id !== rowId)
      this.tabelaFrete.rows = newRows
    }
  }

  setValorTabelaFrete(linha: string, coluna: string, valor: string) {
    const selectedRow = this.tabelaFrete?.rows.find(row => row.id === linha)
    selectedRow[coluna] = valor
  }

  setValorTabelaPedagio(linha: string, coluna: string, valor: string) {
    const selectedRow = this.tabelaPedagio?.rows.find(row => row.id === linha)
    selectedRow[coluna] = valor
  }

  // Abre/fecha modal de adicionar coluna.
  adicionarColunaDialogOpened: boolean = false
  openAdicionarColunaDialog() {
    this.adicionarColunaDialogOpened = true
  }
  closeAdicionarColunaDialog() {
    this.adicionarColunaDialogOpened = false
  }

  // Abre/fecha modal de editar coluna.
  editarColunaDialogOpened: boolean = false
  openEditarColunaDialog() {
    this.editarColunaDialogOpened = true
  }
  closeEditarColunaDialog() {
    this.editarColunaDialogOpened = false
  }

  // Cria os valores iniciais da tabela frete
  // Se tiver tabela, configura as colunas
  // Se não tiver tabela, cria a lista de campos iniciais (km inicial, final e ações)
  setInitialValuesTabelaFrete(tabela?: TabelaFreteDetail) {
    if (!this.tabelaFrete) {
      return
    }

    this.tabelaFrete.columns.push(
      {
        flex: 1,
        field: 'kmInicial',
        headerName: 'Faixa KM Inicial',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        width: 175,
        headerAlign: 'left',
        align: 'left',
        renderEditCell: props => (
          <CustomEditComponent validator={validateFaixa} {...props} />
        ),
        valueParser: (value, _) => getOnlyDigits(value?.toString() ?? '')
      },
      {
        flex: 1,
        field: 'kmFinal',
        headerName: 'Faixa KM Final',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        width: 175,
        headerAlign: 'left',
        align: 'left',
        renderEditCell: props => (
          <CustomEditComponent validator={validateFaixa} {...props} />
        ),
        valueParser: (value, _) => getOnlyDigits(value?.toString() ?? '')
      }
    )

    const colunas = this.createColumnsTabela(tabela?.TarifasFrete)
    this.tabelaFrete.columns.push(...colunas)

    tabela?.FaixasKm.forEach(faixa => {
      const row: Record<string, string> = {}
      row.id = (this.tabelaFrete.rows.length + 1).toString()
      row['kmInicial'] = faixa.kmInicial
      row['kmFinal'] = faixa.kmFinal

      this.tabelaFrete.columns.forEach((coluna, index) => {
        if (coluna.field !== 'kmInicial' && coluna.field !== 'kmFinal') {
          row[coluna.field] = faixa.TarifaFaixasCapacidadeVeiculo[
            index - 2
          ].tarifa
            .toString()
            .replace('.', ',')
        }
      })

      this.tabelaFrete.rows.push(row)
    })

    this.tabelaFrete.columns.push({
      field: 'actions',
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      headerName: 'Ações',
      width: 100,
      renderCell: DeleteButtonTabelaFrete
    })
  }

  setInitialValuesTabelaPedagio(tabela?: TabelaPedagioDetail) {
    if (!this.tabelaPedagio) {
      return
    }

    this.tabelaPedagio.columns.push(
      {
        flex: 1,
        field: 'kmInicial',
        headerName: 'Faixa KM Inicial',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        width: 175,
        headerAlign: 'left',
        align: 'left',
        renderEditCell: props => (
          <CustomEditComponent validator={validateFaixa} {...props} />
        ),
        valueParser: (value, _) => getOnlyDigits(value?.toString() ?? '')
      },
      {
        flex: 1,
        field: 'kmFinal',
        headerName: 'Faixa KM Final',
        editable: true,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableReorder: true,
        disableExport: true,
        width: 175,
        headerAlign: 'left',
        align: 'left',
        renderEditCell: props => (
          <CustomEditComponent validator={validateFaixa} {...props} />
        ),
        valueParser: (value, _) => getOnlyDigits(value?.toString() ?? '')
      }
    )

    const colunasFromTarifas = this.createColumnsTabela(tabela?.TarifasPedagio)
    this.tabelaPedagio.columns.push(...colunasFromTarifas)

    tabela?.FaixasKm.forEach(faixa => {
      const row: Record<string, string> = {}
      row.id = (this.tabelaPedagio.rows.length + 1).toString()
      row['kmInicial'] = faixa.kmInicial
      row['kmFinal'] = faixa.kmFinal

      this.tabelaPedagio.columns.forEach((coluna, index) => {
        if (coluna.field !== 'kmInicial' && coluna.field !== 'kmFinal') {
          row[coluna.field] = faixa.TarifaFaixasCapacidadeVeiculoPedagio[
            index - 2
          ].tarifa
            .toString()
            .replace('.', ',')
        }
      })

      this.tabelaPedagio.rows.push(row)
    })

    this.tabelaPedagio.columns.push({
      field: 'actions',
      editable: false,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableReorder: true,
      disableExport: true,
      headerName: 'Ações',
      width: 100,
      renderCell: DeleteButtonTabelaPedagio
    })
  }

  private createColumnsTabela = (tarifas?: TarifaFreteDetail[]) => {
    const columns: GridColDef[] = []

    tarifas?.forEach(tarifa =>
      columns.push({
        field: tarifa.capacidade.toString(),
        headerName: tarifa.capacidade.toString(),
        sortable: false,
        filterable: false,
        disableColumnMenu: false,
        disableReorder: true,
        disableExport: true,
        width: 150,
        editable: true,
        headerAlign: 'left',
        align: 'left',
        renderEditCell: props => (
          <CustomEditComponent validator={validateTarifa} {...props} />
        ),
        renderCell: props => (
          <Tooltip title="Tarifa/valor" placement="bottom-start">
            <Typography>{props.formattedValue}</Typography>
          </Tooltip>
        ),
        valueFormatter: (params: GridValueFormatterParams) =>
          formatedValuesGrid(params)
      })
    )

    return columns
  }

  // Caso na edição, onde é marcado que tabela terá tabela pedágio
  updateTabelaPedagioWithTabelaFreteColumns() {
    this.clearTabelaPedagio()
    this.setInitialValuesTabelaPedagio()
    this.tabelaFrete.columns
      .filter(
        column =>
          column.field !== 'kmInicial' &&
          column.field !== 'kmFinal' &&
          column.field !== 'actions'
      )
      .forEach(column => {
        this.addCapacidadeTabelaPedagio(column.field)
      })
  }

  get tabelas() {
    return new ApisauceDataStore<TabelaDetail>(apiSauceInstance, {
      useNodes: true,
      path: `/contratos/${this.contratoId}/tabelas`,
      resultsField: 'edges'
    })
  }
}

const contratosStore = new ContratosStore()

export default contratosStore
