import { isNil } from 'ramda'
import { MessagesYup } from 'src/modules/messages'
import * as yup from 'yup'

export const schema = yup.object().shape({
  nome: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  raizCnpj: yup
    .string()
    .nullable()
    .trim()
    .test('raiz-cnpj-validation', MessagesYup.INVALID_CNPJ, value => {
      return isNil(value) || value?.length === 10
    }),
  pessoas: yup.array().of(
    yup.object().shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
  ),
  avisos: yup.array().of(
    yup.object().shape({
      descricao: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      cor: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
  )
})
