import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import { startOfDay } from 'date-fns'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import * as yup from 'yup'
import { Paper } from '../../../components'
import DateInput from '../../../components/DateInput'
import EmailInput from '../../../components/EmailInput'
import EnderecoInput from '../../../components/EnderecoInput'
import FieldsPending from '../../../components/FieldsPending'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import MaskInput from '../../../components/Formik/Forms/MaskInput'
import SelectInput from '../../../components/Formik/Forms/SelectInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import ModalidadesInput from '../../../components/ModalidadesInput'
import TelefoneInput from '../../../components/TelefoneInput'
import scrollToError from '../../../utils/scrollToError'
import { validateCNPJ } from '../../../utils/validators/cnpj'
import { validateCPF } from '../../../utils/validators/cpf'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { pessoasRepository } from '../hooks/pessoasRepository'
import { usePessoa } from '../hooks/usePessoa'
import {
  atividadeFiscal,
  defaultTipo,
  estadoCivil,
  Pessoa,
  PessoaFormData,
  regimeTributario,
  sexo,
  tiposPessoa,
  tributacao
} from '../types'
import CNAEListener from './CNAEListener'

const initialValues: any = {
  tipo: defaultTipo,
  nome: '',
  razaoSocial: '',
  nomeFantasia: '',
  cpf: '',
  cnpj: '',
  inscricaoEstadual: '',
  atividadeFiscal: '',
  documento: '',
  sexo: '',
  estadoCivil: '',
  dataNascimento: null,
  cnaePrincipal: '',
  descricaoCnae: '',
  regimeTributario: '',
  tributacao: '',
  tipoDocumento: '',
  modalidades: [{ modalidade: '' }],
  emails: [{ email: '' }],
  telefones: [{ telefone: '' }],
  grupoNegociadorId: '',
  enderecos: [
    {
      tipo: '',
      paisId: '',
      cep: '',
      logradouro: '',
      bairro: '',
      numero: '',
      complemento: '',
      estadoId: '',
      cidadeId: '',
      inscricaoEstadual: '',
      atividadeFiscal: ''
    }
  ]
}

export const PessoasForm = () => {
  const history = useHistory()
  const [pessoaState] = usePessoa()
  const [loading, setLoading] = useState(false)
  const [tipoPessoa, setTipoPessoa] = useState('PF')
  const canSave = useCanSave(pessoaState, 'Pessoas')

  const parseDateString = (value: Date, originalValue: string) => {
    return originalValue ? startOfDay(new Date(originalValue)) : null
  }

  const schema = yup.object().shape({
    tipo: yup.string(),
    nome: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PE' || tipo === 'PF'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    sexo: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PF'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    estadoCivil: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PF'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    cpf: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PF'
      },
      then: yup
        .string()
        .trim()
        .min(14, MessagesYup.CPF_MIN)
        .test(
          'cpf-validation',
          MessagesYup.MESSAGEM_VALOR_CPF_INVALIDO,
          value => validateCPF(value || '')
        )
        .required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    dataNascimento: yup.date().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PF'
      },
      then: yup
        .date()
        .transform(parseDateString)
        .max(new Date(), 'Você não pode cadastrar uma data futura!')
        .nullable()
        .required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.date().nullable()
    }),
    modalidades: yup
      .array()
      .of(
        yup.object().shape({
          modalidade: yup
            .object()
            .shape({
              name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
              value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
            })
            .nullable()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
      )
      .min(1, 'O cliente deve conter alguma modalidade.')
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    enderecos: yup
      .array()
      .of(
        yup.object().shape({
          tipo: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
          cep: yup.string().when('tipo', {
            is: () => tipoPessoa === 'PF' || tipoPessoa === 'PJ',
            then: yup
              .string()
              .trim()
              .min(9, MessagesYup.CEP_MIN)
              .required(MessagesYup.MENSAGEM_OBRIGATORIO),
            otherwise: yup.string().nullable()
          }),
          logradouro: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
          bairro: yup.string().when('tipo', {
            is: () => tipoPessoa === 'PF' || tipoPessoa === 'PJ',
            then: yup
              .string()
              .trim()
              .required(MessagesYup.MENSAGEM_OBRIGATORIO),
            otherwise: yup.string().nullable()
          }),
          numero: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
          complemento: yup.string(),
          estadoId: yup
            .object()
            .shape({
              name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
              value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
            })
            .nullable()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO),
          cidadeId: yup
            .object()
            .shape({
              name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
              value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
            })
            .nullable()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO),
          inscricaoEstadual: yup.string().when('tipo', {
            is: () => tipoPessoa === 'PF',
            then: yup
              .string()
              .nullable()
              .matches(/^([0-9]*|ISENTO)$/, {
                message: 'Devem ser digitados números ou a palavra "ISENTO"'
              })
              .required(MessagesYup.MENSAGEM_OBRIGATORIO),
            otherwise: yup.string().nullable()
          }),

          atividadeFiscal: yup.string().when('tipo', {
            is: () => {
              return tipoPessoa === 'PF'
            },
            then: yup
              .string()
              .nullable()
              .required(MessagesYup.MENSAGEM_OBRIGATORIO),
            otherwise: yup.string().nullable()
          }),
          paisId: yup.object().when('tipo', {
            is: () => tipoPessoa === 'PE',
            then: yup
              .object()
              .shape({
                name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
                value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
              })
              .nullable()
              .required(MessagesYup.MENSAGEM_OBRIGATORIO),
            otherwise: yup.object().nullable()
          })
        })
      )
      .min(1, 'O cliente deve conter algum endereço.')
      .required('O cliente deve conter algum endereço.'),
    emails: yup
      .array()
      .of(
        yup.object().shape({
          email: yup
            .string()
            .email(MessagesYup.MENSAGEM_EMAIL)
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
      )
      .min(1, 'O cliente deve conter algum e-mail.')
      .required('O cliente deve conter algum e-mail.'),
    telefones: yup.array().when('tipo', {
      is: () => tipoPessoa === 'PE',
      then: yup
        .array()
        .of(
          yup.object().shape({
            telefone: yup
              .string()
              .min(11, MessagesYup.TEL_MIN)
              .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          })
        )
        .min(1, 'O cliente deve conter algum telefone.')
        .required('O cliente deve conter algum telefone.'),
      otherwise: yup
        .array()
        .of(
          yup.object().shape({
            telefone: yup
              .string()
              .min(14, MessagesYup.TEL_MIN)
              .max(16, MessagesYup.TEL_MAX)
              .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          })
        )
        .min(1, 'O cliente deve conter algum telefone.')
        .required('O cliente deve conter algum telefone.')
    }),
    cnpj: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup
        .string()
        .trim()
        .test('cnpj-validation', MessagesYup.INVALID_CNPJ, value =>
          validateCNPJ(value || '')
        )
        .required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),

    razaoSocial: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    inscricaoEstadual: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup
        .string()
        .matches(/^([0-9]*|ISENTO)$/, {
          message: 'Devem ser digitados números ou a palavra "ISENTO"'
        })
        .required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    atividadeFiscal: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    cnaePrincipal: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    descricaoCnae: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    regimeTributario: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    tributacao: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PJ'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    documento: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PE'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    tipoDocumento: yup.string().when('tipo', {
      is: (tipo: string) => {
        return tipo === 'PE'
      },
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
    nomeFantasia: yup.string().when('tipo', {
      is: (nomeFantasia: string) =>
        nomeFantasia === 'PE' || nomeFantasia === 'PJ',
      then: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    })
  })

  const { saveWithoutRedirect } = useEntitySaver<Pessoa>(async data => {
    const response = await pessoasRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })
  const handleSubmit = async (data: PessoaFormData) => {
    const dataTratado = {
      id: data.id ?? undefined,
      tipo: data.tipo,
      atividadeFiscal: data.atividadeFiscal || null,
      cpf: data.cpf || null,
      cnpj: data.cnpj || null,
      inscricaoEstadual: data.inscricaoEstadual || null,
      modalidades: data.modalidades.map(modalidade => ({
        id: modalidade.modalidade.value
      })),
      enderecos: data.enderecos.map(end => ({
        ...end,
        cidadeId: end.cidadeId.value ? end.cidadeId.value : end.cidadeId,
        estadoId: end.estadoId.value,
        paisId: end.paisId ? end.paisId.value : null,
        atividadeFiscal: end.atividadeFiscal || null,
        inscricaoEstadual: end.inscricaoEstadual || null,
        createdAt: undefined,
        updatedAt: undefined,
        pessoaId: undefined
      })),
      emails: data.emails.map(em => ({
        ...em,
        pessoaId: undefined,
        createdAt: undefined,
        updatedAt: undefined
      })),
      telefones: data.telefones.map(tel => ({
        ...tel,
        pessoaId: undefined,
        createdAt: undefined,
        updatedAt: undefined
      })),
      dataNascimento: data.dataNascimento || null,
      tipoDocumento: data.tipoDocumento || null,
      nomeFantasia: data.nomeFantasia || null,
      documento: data.documento || null,
      razaoSocial: data.razaoSocial || null,
      nome: data.nome || null,
      sexo: data.sexo || null,
      estadoCivil: data.estadoCivil || null,
      cnaePrincipal: data.cnaePrincipal || null,
      descricaoCnae: data.descricaoCnae || null,
      regimeTributario: data.regimeTributario || null,
      tributacao: data.tributacao || null,
      createdAt: undefined,
      updatedAt: undefined,
      Modalidades: undefined,
      Enderecos: undefined
    } as Pessoa

    setLoading(true)

    const response = await saveWithoutRedirect(dataTratado)

    if (response.ok) {
      history.goBack()
    }
  }

  function formatData(values: any) {
    delete values.nomeDocumento

    if (values.tipo === 'PJ') {
      delete values.nome
    }

    return {
      ...values,
      modalidades: values.Modalidades.map((mod: any) => {
        return {
          modalidade: { name: mod.nome, value: mod.id }
        }
      })
    }
  }

  useEffect(() => {
    pessoaState.tag === 'with-data' && setTipoPessoa(pessoaState.entity.tipo)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pessoaState.tag])

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        pessoaState.tag === 'with-data'
          ? formatData(pessoaState.entity)
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        isSubmitting,
        handleChange,
        resetForm,
        submitCount
      }) => (
        <Form>
          <CNAEListener />
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  onChange={(e: any) => {
                    resetForm()
                    setTipoPessoa(e.target.value)
                    handleChange(e)
                  }}
                  name="tipo"
                  label="Tipo"
                  options={tiposPessoa}
                  disabled={pessoaState.tag === 'with-data'}
                />
              </Grid>

              {values.tipo === 'PE' && (
                <>
                  <Grid item xs={12} sm={4}>
                    <TextInput name="documento" label="Número Documento" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput name="tipoDocumento" label="Tipo Documento" />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInput name="nome" label="Nome" />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextInput name="nomeFantasia" label="Nome Fantasia" />
                  </Grid>
                </>
              )}

              {values.tipo === 'PF' && (
                <>
                  <Grid item xs={12} sm={8}>
                    <TextInput name="nome" label="Nome" />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SelectInput
                      onChange={(e: any) => {
                        handleChange(e)
                      }}
                      name="sexo"
                      label="Sexo"
                      options={sexo}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <SelectInput
                      onChange={(e: any) => {
                        handleChange(e)
                      }}
                      name="estadoCivil"
                      label="Estado Civil"
                      options={estadoCivil}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <MaskInput name="cpf" label="CPF" mask="999.999.999-99" />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <DateInput
                      label="Data de Nascimento"
                      name="dataNascimento"
                    />
                  </Grid>
                </>
              )}
              {values.tipo === 'PJ' && (
                <>
                  <Grid item xs={12} sm={4}>
                    <MaskInput
                      name="cnpj"
                      label="CNPJ"
                      mask="99.999.999/9999-99"
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      name="inscricaoEstadual"
                      label="Inscrição Estadual"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput name="razaoSocial" label="Razão Social" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput name="nomeFantasia" label="Nome Fantasia" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MaskInput
                      name="cnaePrincipal"
                      label="CNAE Principal"
                      mask="99.99-9/99"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextInput name="descricaoCnae" label="Descrição CNAE" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      onChange={(e: any) => {
                        handleChange(e)
                      }}
                      name="atividadeFiscal"
                      label="Atividade Fiscal"
                      options={atividadeFiscal}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      onChange={(e: any) => {
                        handleChange(e)
                      }}
                      name="regimeTributario"
                      label="Regime Tributário"
                      options={regimeTributario}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <SelectInput
                      onChange={(e: any) => {
                        handleChange(e)
                      }}
                      name="tributacao"
                      label="Tributação"
                      options={tributacao}
                    />
                  </Grid>
                </>
              )}
              {pessoaState.tag === 'with-data' &&
                !!pessoaState.entity.grupoNegociadorId && (
                  <Grid item xs={12} sm>
                    <AsyncSearchInput
                      name="grupoNegociadorId"
                      label="Grupo Negociador"
                      url="/grupos-negociadores"
                      labelField="nome"
                      disabled
                      allowSearchWhenDisabled
                    />
                  </Grid>
                )}
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5">Modalidades</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <ModalidadesInput name="modalidades" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5">Endereço</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <EnderecoInput name="enderecos" />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5">E-mails</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <EmailInput name="emails" />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12}>
                    <Typography variant="h5">Telefones</Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TelefoneInput name="telefones" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12} sm={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={history.goBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
