import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import * as yup from 'yup'
import { Paper } from '../../../components'
import TextInput from '../../../components/Formik/Forms/TextInput'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { paisesRepository } from '../hooks/paisesRepository'
import { usePais } from '../hooks/usePais'
import { PaisFormData, SavePaisesInput } from '../types'
import FieldsPending from '../../../components/FieldsPending'
import scrollToError from '../../../utils/scrollToError'

const schema = yup.object().shape({
  nome: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  codigoBACEN: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  nome: '',
  codigoBACEN: ''
}

export const PaisForm = () => {
  const [paisState] = usePais()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const canSave = useCanSave(paisState, 'Países')

  const { saveWithoutRedirect } = useEntitySaver<SavePaisesInput>(
    async data => {
      const response = await paisesRepository.save(data)

      setTimeout(() => {
        setLoading(false)
      }, 1000)

      return response
    }
  )

  const handleSubmit = async (pais: PaisFormData) => {
    setLoading(true)
    const response = await saveWithoutRedirect(pais)

    if (response.ok) {
      history.goBack()
    }
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        paisState.tag === 'with-data' ? paisState.entity : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextInput name="nome" label="Nome" />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput name="codigoBACEN" label="Código BACEN" />
              </Grid>
            </Grid>

            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
