import { makeAutoObservable } from 'mobx'
import { LoteList } from 'src/modules/lotes/types'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { LoteProgramacaoFormData } from '../types/formData'

class SelecaoLotesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  selecaoLotesModal = false
  openSelecaoLotesModal() {
    this.selecaoLotesModal = true
  }

  closeSelecaoLotesModal() {
    this.selecaoLotesModal = false
  }

  lotes = new ApisauceDataStore<LoteList>(apiSauceInstance, {
    useNodes: true,
    path: '/lotes',
    resultsField: 'edges',
    countField: 'totalCount'
  })

  oldSelection: Record<string, LoteProgramacaoFormData> = {}
  setOldSelection(lotes: Record<string, LoteProgramacaoFormData>) {
    this.oldSelection = lotes
  }

  resetOldSelection() {
    this.oldSelection = {}
  }
}

const selecaoLotesStore = new SelecaoLotesStore()

export default selecaoLotesStore
