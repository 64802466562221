import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useProgramacao } from '../../hooks/useProgramacao'
import programacoesStore from '../../store/store'
import { DestinoPernaFormData, ProgramacaoFormData } from '../../types/formData'

interface Props {
  loteId: string
  pernaIndex: number
}

export const AliquotaIcmsListener = ({ loteId, pernaIndex }: Props) => {
  const [programacao] = useProgramacao()
  const formik = useFormikContext<ProgramacaoFormData>()
  const deps = [
    pernaIndex
      ? formik.values.lotes[loteId].pernas[pernaIndex - 1].destinos
      : formik.values.lotes[loteId].pernas[pernaIndex].estadoOrigem,
    formik.values.lotes[loteId].pernas[pernaIndex].destinos
  ]

  useEffect(() => {
    const fetchIcms = async (
      estadoOrigem: string,
      destinos: DestinoPernaFormData[]
    ) => {
      if (!estadoOrigem || !destinos[0]?.estadoDestino) {
        return
      }

      let highestAliquota = 0

      for (const destino of destinos) {
        if (!destino.estadoDestino.name) {
          continue
        }
        const ufOrigem = estadoOrigem.split(' - ')[1]
        const ufDestino = destino.estadoDestino.name.split(' - ')[1]

        const icms = await programacoesStore.getIcms(ufOrigem, ufDestino)
        if (icms.data) {
          highestAliquota = Math.max(highestAliquota, icms.data.aliquota)
        }
      }

      formik.setFieldValue(
        `lotes.${loteId}.pernas.${pernaIndex}.aliquotaIcms`,
        highestAliquota
      )
    }

    if (programacao.tag !== 'with-data') {
      fetchIcms(
        pernaIndex
          ? formik.values.lotes[loteId].pernas[pernaIndex - 1].destinos[0]
              ?.estadoDestino?.name
          : formik.values.lotes[loteId].pernas[pernaIndex].estadoOrigem?.name,
        formik.values.lotes[loteId].pernas[pernaIndex].destinos
      )
    }
  }, deps)

  return null
}
