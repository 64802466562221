import { EdgesPage } from '@elentari/core/types'
import { useEffect, useState } from 'react'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { ComentarioLote } from '../types'

export const useComentariosLote = (id: string) => {
  const [comentarios, setComentarios] = useState<ComentarioLote[]>([])
  const [loading, setLoading] = useState(false)

  const getComentarios = async (id: string) => {
    setLoading(true)

    const response = await apiSauceInstance.get<
      EdgesPage<ComentarioLote>,
      { message: string }
    >(`comentarios?loteId=${id}`)

    if (response.ok) {
      setComentarios(response.data!.edges.map(edge => edge.node))
    } else {
      setComentarios([])
      snackbarStore.setMessage(
        response.data?.message ??
          'Não foi possível recuperar os comentários do lote'
      )
    }

    setLoading(false)
  }

  const reload = () => {
    getComentarios(id)
  }

  useEffect(() => {
    getComentarios(id)
  }, [id])

  return {
    comentarios,
    loading,
    getComentarios,
    reload
  }
}
