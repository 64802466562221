import { Field } from 'react-final-form'
import { Option } from '../../hooks/useAutoComplete'
import { AutoComplete } from './AutoComplete'

export default function RFFAutocomplete<T extends Option>({
  name,
  label,
  options,
  defaultValue,
  onChangeHandle,
  disabled,
  clearText,
  closeText,
  loadingText
}: {
  name: string
  label: string
  options: T[]
  defaultValue?: string
  onChangeHandle?: any
  disabled?: boolean
  clearText?: string
  closeText?: string
  loadingText?: string
}) {
  return (
    <Field name={name} defaultValue={defaultValue}>
      {({ input, meta }) => (
        <AutoComplete
          label={label}
          meta={meta}
          onChange={(e: any, value: any) => {
            onChangeHandle && onChangeHandle(value?.id)
            input.onChange(value?.id)
          }}
          options={options}
          value={
            options.find(u => u.id === input.value) ?? {
              name: ''
            }
          }
          clearText={clearText}
          closeText={closeText}
          loadingText={loadingText}
          disabled={disabled}
        />
      )}
    </Field>
  )
}
