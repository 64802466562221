import { ProgramacaoFormData } from '../types/formData'

export const initialValues: ProgramacaoFormData = {
  centroCustoId: '' as any,
  embarcador: '' as any,
  dataCarregamento: '' as any,
  dataDescarregamento: '' as any,
  placaPrincipal: '',
  placa_1: '',
  placa_2: '',
  placa_3: '',
  nomeResponsavel: '',
  telefoneResponsavel: '',
  motorista: '',
  cpfMotorista: '',
  telefoneMotorista: '',
  capacidadeCargaVeiculo: '',
  horaCarregamento: '' as any,
  horaDescarregamento: '' as any,
  observacoes: '',
  tipoPagamento: '' as any,
  numeroCartao: '',
  lotes: {},
  tipoContratacaoLotesSelecionados: null,
  tipoLotesSelecionados: null,
  grupoNegociadorLotesSelecionados: null,
  modalidadeTransportador: '' as any,
  tipoNegociacaoSubcontratado: '' as any
}
