import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import SwitchInput from '../../../components/SwitchInput'

export const Integracoes = () => {
  return (
    <>
      <Grid item xs={12} sm={12}>
        <Grid container direction="row" alignItems="center">
          <Grid item xs={12} sm={3}>
            <Typography
              variant="body1"
              color="inherit"
              style={{ marginLeft: 4 }}
            >
              Integrar Apps:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SwitchInput
              name="carguero"
              label="Carguero"
              disabled
              style={{ paddingLeft: 12 }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SwitchInput
              name="freto"
              label="Freto"
              disabled
              style={{ paddingLeft: 12 }}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <SwitchInput
              name="trato"
              label="Trato"
              disabled
              style={{ paddingLeft: 12 }}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
