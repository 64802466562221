import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import CoordinateInput from 'src/components/Formik/Forms/CoordinateInput'
import MaskInput from 'src/components/Formik/Forms/MaskInput'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { EntitySaver } from 'src/services/entitySaver'
import snackbarStore from 'src/stores/snackbar'
import * as yup from 'yup'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { cidadesRepository } from '../hooks/cidadesRepository'
import { useCidade } from '../hooks/useCidade'

const regexCoordenada = /^-?[0-9][0-9]{0,2}\.[0-9][0-9]{0,5}?$/

const optionsSchema = yup
  .object()
  .nullable()
  .shape({
    name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
    value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
  })

const schema = yup.object().shape({
  nome: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  codigoIBGE: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  latitude: yup
    .string()
    .test('longitude', MessagesYup.LATITUDE_ERROR, function (value: any) {
      if (value) {
        const valueNumber = Number(value)
        return valueNumber >= -90 && valueNumber <= 90
      }

      return true
    })
    .matches(regexCoordenada, MessagesYup.MENSAGEM_PADRAO_INVALIDO)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  longitude: yup
    .string()
    .test('longitude', MessagesYup.LONGITUDE_ERROR, function (value: any) {
      if (value) {
        const valueNumber = Number(value)
        return valueNumber >= -180 && valueNumber <= 180
      }
      return true
    })
    .matches(regexCoordenada, MessagesYup.MENSAGEM_PADRAO_INVALIDO)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  estadoId: optionsSchema.required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  nome: '',
  codigoIBGE: '',
  latitude: '',
  longitude: '',
  estadoId: null
}

export const CidadesForm = () => {
  const [cidadeState] = useCidade()
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const canSave = useCanSave(cidadeState, 'Cidades')

  const handleSubmit = async (data: any) => {
    setLoading(true)
    const formatCidades = {
      id: data.id,
      nome: data.nome,
      codigoIBGE: data.codigoIBGE,
      latitude: data.latitude,
      longitude: data.longitude,
      estadoId: data.estadoId.value
    }

    await EntitySaver(() => cidadesRepository.save(formatCidades) as any, {
      onSuccess: () => handleGoBack(),
      feedbackError: 'Erro ao salvar a cidade'
    })
    setLoading(false)
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        cidadeState.tag === 'with-data' ? cidadeState.entity : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <TextInput name="nome" label="Nome" />
              </Grid>
              <Grid item md={3} xs={12}>
                <CoordinateInput name="latitude" label="Latitude" />
              </Grid>
              <Grid item md={3} xs={12}>
                <CoordinateInput name="longitude" label="Longitude" />
              </Grid>
              <Grid item md={6} xs={12}>
                <MaskInput
                  mask="9999999"
                  name="codigoIBGE"
                  label="Código IBGE"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <AsyncSearchInput
                  name="estadoId"
                  label="Estado"
                  url="/estados/minimal"
                  labelField="nome"
                  joinStateAndUf
                />
              </Grid>
            </Grid>

            <FieldsPending errors={errors} submitCount={submitCount} />

            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
