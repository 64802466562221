import { Divider, Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { TipoContratacao } from 'src/modules/lotes/types'
import { ProgramacaoFormData } from '../types/formData'
import { DadosComplementares } from './DadosComplementares'
import { DadosPorLote } from './DadosPorLote'

export const DadosLoteContrato = () => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const hasLotesContrato =
    formik.values.tipoContratacaoLotesSelecionados === TipoContratacao.CONTRATO

  return hasLotesContrato ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <DadosComplementares />
      </Grid>
      <Grid item xs={12}>
        <DadosPorLote />
      </Grid>
    </Grid>
  ) : null
}
