import { makeStyles } from '@material-ui/core/styles'

const useDashboardStyles = makeStyles(theme => ({
  infos: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr'
    }
  },
  dashboardProgramacoes: {
    display: 'grid',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(2, 1fr)'
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr'
    }
  },
  dashboardFinanceiro: {
    display: 'grid',
    gap: theme.spacing(2),

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)'
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr'
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr'
    }
  },
  filterAction: {
    textTransform: 'none',
    height: theme.spacing(4),
    fontWeight: 'bold',
    fontSize: 14,
    borderRadius: 100,
    boxShadow: 'none',
    '&.MuiButton-contained': {
      '&:hover': {
        boxShadow: 'none'
      }
    }
  }
}))

export default useDashboardStyles
