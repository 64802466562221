import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { HistoricoProgramacaoMotorista } from '../types/historico-programacoes'

class HistoricoProgramacoesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  modalHistorico = false

  openModalHistorico() {
    this.modalHistorico = true
  }

  closeModalHistorico() {
    this.modalHistorico = false
  }

  cpfMotorista: string = ''
  setCpfMotorista(cpf: string) {
    this.cpfMotorista = cpf
  }

  get historicoProgramacoes() {
    return new ApisauceDataStore<HistoricoProgramacaoMotorista>(
      apiSauceInstance,
      {
        useNodes: true,
        path: `/programacoes/historico-motorista/${this.cpfMotorista}`,
        resultsField: 'edges',
        countField: 'totalCount'
      }
    )
  }
}

const historicoProgramacoesStore = new HistoricoProgramacoesStore()

export default historicoProgramacoesStore
