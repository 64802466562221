import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { apiSauceInstance } from 'src/services/api'
import axios from 'axios'
import userStore from 'src/stores/user'
import { LoadingPage } from 'src/components/LoadingPage'

const AuthListener = observer(({ children }: any) => {
  async function load() {
    apiSauceInstance.axiosInstance.interceptors.request.use(config => {
      config.headers.token = userStore.getTokens().accessToken
      return config
    })

    await userStore.getAndValidateTokens()

    apiSauceInstance.axiosInstance.interceptors.response.use(
      config => {
        return config
      },
      async error => {
        if (error.response.status === 498 || error.response.status === 401) {
          try {
            const refreshToken = userStore.getTokens().refreshToken || ''
            const newTokens = await userStore.refreshToken(refreshToken)

            const newRequest = await axios({
              ...error.config,
              headers: {
                ...error.config.headers,
                token: newTokens!.accessToken
              }
            })

            return newRequest
          } catch (err) {
            const redirectRoute = window.location.pathname
            window.location.href = `/login${
              redirectRoute ? `?redirect_route=${redirectRoute}` : ''
            }`

            return Promise.reject(error)
          }
        }

        return Promise.reject(error)
      }
    )

    return userStore.checkAbilities()
  }

  useEffect(() => {
    if (window.location.pathname !== '/login') {
      load()
    }
  }, [])

  return userStore.data ? children : <LoadingPage open={true} />
})

export default AuthListener
