import { useEntitySaver } from '@elentari/core/hooks/useEntitySaver'
import { Button, CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import * as yup from 'yup'
import { Paper } from '../../../components'
import { PureAsyncSearchInput } from '../../../components/Formik/Forms/PureAsyncSearchInput'
import SelectInput from '../../../components/Formik/Forms/SelectInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { produtosRepository } from '../hooks/produtoRepository'
import { useProduto } from '../hooks/useProduto'
import { especificoOptions, ProdutoSave, tiposCarga, unidades } from '../types'
import FieldsPending from '../../../components/FieldsPending'
import scrollToError from '../../../utils/scrollToError'
import { EntitySaver } from 'src/services/entitySaver'

const optionsSchema = yup.object().nullable().shape({
  name: yup.string(),
  value: yup.string()
})

const schema = yup.object().shape({
  agrupadorProdutosId: optionsSchema.nullable().optional(),
  nome: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  unidade: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoCarga: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
  especifico: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  codigoNCM: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  nome: '',
  unidade: '',
  tipoCarga: '',
  agrupadorProdutosId: '',
  especifico: false,
  codigoNCM: ''
}

export const ProdutoForm = () => {
  const [unidadeNegocioState] = useProduto()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const canSave = useCanSave(unidadeNegocioState, 'Produtos')

  const { saveWithoutRedirect } = useEntitySaver<ProdutoSave>(async data => {
    const response = await produtosRepository.save(data)
    setTimeout(() => {
      setLoading(false)
    }, 1000)

    return response
  })

  const handleSubmit = async (produto: any) => {
    setLoading(true)

    const formatProduto = {
      id: produto.id,
      nome: produto.nome.trim(),
      tipoCarga: produto.tipoCarga,
      unidade: produto.unidade,
      agrupadorProdutosId: produto.agrupadorProdutosId?.value ?? null,
      especifico: Boolean(produto.especifico),
      codigoNCM: produto.codigoNCM
    }

    await EntitySaver(() => produtosRepository.save(formatProduto) as any, {
      onSuccess: () => {
        setLoading(false)
        handleGoBack()
      },
      feedbackError: 'Erro ao salvar produto'
    })
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={
        unidadeNegocioState.tag === 'with-data'
          ? unidadeNegocioState.entity
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <TextInput name="nome" label="Nome" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <PureAsyncSearchInput
                  name="agrupadorProdutosId"
                  label="Agrupador de Produtos"
                  url="agrupadores-produtos"
                  labelField="nome"
                  paramSearch="nome"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextInput name="codigoNCM" label="Código NCM" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  name="unidade"
                  label="Unidade de Medida"
                  options={unidades}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  name="tipoCarga"
                  label="Tipo de Carga"
                  options={tiposCarga}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  name="especifico"
                  label="Específico"
                  options={especificoOptions}
                />
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12} sm={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
