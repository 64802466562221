import { CircularProgress, IconButton, Tooltip } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { xlsxDownload } from 'src/utils/downloaders'
import { formatProdutoLote } from 'src/utils/formatters/formatProdutoLote'
import { formatValorLote } from 'src/utils/formatters/formatValorLote'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import snackbarStore from '../../stores/snackbar'
import { formatDate } from '../../utils/formatters'
import { formatDestinoLote } from '../../utils/formatters/formatDestinoLote'
import { useLotesXLSX } from './hooks/useLoteXLSX'
import { LotesColumns } from './lotesColumns'
import { lotesStore } from './stores/lotes'
import { especiesOptions, LoteList } from './types'

interface Props {
  onCancel: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  isValidation?: boolean
}

export const LotesTable = observer(
  ({ onCancel, authorization, isValidation }: Props) => {
    const KEY_TABLE = resourceTabela.LOTES
    useConfigTable(KEY_TABLE)
    const tableState = tableStore.getState(KEY_TABLE)
    const isTableReady =
      !lotesStore.lotes.isLoading &&
      tableState &&
      !isValidation &&
      lotesStore.lotes.list.length

    const [isLoading, setIsLoading] = React.useState(false)
    const { pathname } = useLocation()
    const { getXLSXData, format } = useLotesXLSX({
      ...lotesStore.lotes.filters,
      limit: lotesStore.lotes.totalCount
    })

    const downloadXLSX = async () => {
      setIsLoading(true)
      const response = await getXLSXData()
      if (response.ok) {
        xlsxDownload(format(response.data!), 'Lotes')
      } else {
        snackbarStore.setMessage(
          response.data?.message ??
            'Ocorreu um erro ao solicitar os dados para download'
        )
      }
      setIsLoading(false)
    }

    const actionsTable = [
      () => (
        <Tooltip title="Download XLSX" aria-label="Download XLSX">
          <IconButton
            disabled={isLoading}
            aria-label="download"
            onClick={() => downloadXLSX()}
          >
            {isLoading ? <CircularProgress size={24} /> : <GetApp />}
          </IconButton>
        </Tooltip>
      )
    ]

    const columns: Array<Column & IColumn> = useMemo(
      () => LotesColumns({ onCancel, authorization }),
      [onCancel, authorization]
    )

    const data = lotesStore.lotes.list.map(({ ...lote }: LoteList) => {
      return {
        ...lote,
        descricao: lote.descricao ? lote.descricao : '-',
        centroCusto: lote.CentroCusto.descricao,
        cliente: lote.Cliente.nome,
        origem: `${lote.Origem.cidade} / ${lote.Origem.siglaEstado}`,
        destino: formatDestinoLote(lote.Destino),
        produto: formatProdutoLote(lote.Produto),
        dataCarregamento: formatDate(new Date(lote.dataInicio)),
        dataDescarregamento: formatDate(new Date(lote.dataTermino)),
        freteEmpresa: formatValorLote(lote.freteEmpresa),
        freteSubcontratadoPJ: formatValorLote(lote.freteSubcontratadoPJ),
        freteSubcontratadoPF: formatValorLote(lote.freteSubcontratadoPF),
        numeroPedido: lote.numeroPedido || '-',
        operacaoKMM: lote.operacaoKMMformated,
        especie:
          especiesOptions.find(especie => especie.value === lote.especie)
            ?.label ?? '-'
      }
    })

    const sortMapper = new Map([
      ['dataCarregamento', 'dataInicio'],
      ['centroCusto', 'CentroCusto.descricao'],
      ['cliente', 'GrupoNegociador.nome'],
      ['origem', 'Origem.nome'],
      ['produto', 'Produto.nome']
    ])

    return (
      <>
        <Spacer y={4} />
        <Paper>
          <TableContainer
            loading={lotesStore.lotes.isLoading}
            totalCount={lotesStore.lotes.totalCount}
            addendPagination
            keyTable={resourceTabela.LOTES}
          >
            {isTableReady ? (
              <BaseTable
                keyTable={KEY_TABLE}
                columns={columns}
                linkWithRedirectClickRow="lotes"
                actionsTable={actionsTable}
                data={data}
                sortMapper={sortMapper}
              />
            ) : (
              <NoResultsTable loading={lotesStore.lotes.isLoading} />
            )}
          </TableContainer>
          <AddRecord path={pathname} authorization={authorization} />
        </Paper>
      </>
    )
  }
)
