import { StatusRelatorioInterno, TipoRelatorioInterno } from '../types'

export function getLabelTipoRelatorioInterno(tipo: TipoRelatorioInterno) {
  const mapper: Record<TipoRelatorioInterno, string> = {
    LOTE: 'LOTE',
    PROGRAMACAO: 'PROGRAMAÇÃO'
  }

  return mapper[tipo]
}

export function getLabelStatusRelatorioInterno(status: StatusRelatorioInterno) {
  const mapper: Record<StatusRelatorioInterno, string> = {
    PENDENTE: 'PROCESSANDO',
    CONCLUIDO: 'CONCLUÍDO',
    ERRO: 'ERRO'
  }

  return mapper[status]
}
