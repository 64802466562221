import {
  Badge,
  Box,
  Button,
  ClickAwayListener,
  makeStyles,
  Tab,
  Tabs,
  Typography
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import { Assignment, Close, MoreVert, SendOutlined } from '@material-ui/icons'
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import { Fragment, SetStateAction, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useLocation } from 'react-use'
import AlterarDadosDialog from 'src/components/Dialogs/AlterarDadosDialog'
import SimpleDialog from 'src/components/SimpleDialog'
import { returnStatusColor } from 'src/components/Validation/StatusColors'
import { ValidationRecord } from 'src/components/ValidationRecord'
import When from 'src/components/When'
import { useReadAbility } from 'src/modules/login/'
import snackbarStore from 'src/stores/snackbar'
import { openInNewTab } from 'src/utils/openInNewTab'
import { Paper } from '../../../components'
import { DivulgarFreteDialog } from '../components/DivulgarFrete'
import { alterarStatus, excluirSaldo } from '../hooks/lotesRepository'
import { useIsAdmin } from '../hooks/useIsAdmin'
import { useLote } from '../hooks/useLote'
import { formatDataFromExistingLote } from './formatFunctions'
import FreteEmpresaListener from './FreteEmpresaListener'
import { TabLote } from './TabLote'
import { TabViagens } from './TabViagens'
import { AlteracoesComentarios } from '../components/AlteracoesComentarios'

interface Props {
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
  isValidation?: boolean
}

const useStyles = makeStyles(theme => ({
  speedDial: {
    position: 'fixed',
    right: theme.spacing(4),
    bottom: theme.spacing(4)
  },
  speedDialWarningIcon: {
    color: '#FF9000'
  }
}))

function TabPanel(props: {
  [x: string]: any
  children: any
  value: any
  index: any
}) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} paddingTop={0}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

const initialValues: any = {
  centroCustoId: '',
  tipoContratacao: '',
  grupoNegociadorId: '',
  estadoOrigemId: null,
  origemId: null,
  produtoId: '',
  tipoLote: '',
  quantidadeTotal: '',
  quantidadeProgramada: '',
  saldo: false,
  segundaPerna: false,
  tipoNegociacaoEmpresa: '',
  freteEmpresa: 0,
  pedagio: false,
  tiposPedagio: '',
  valorPedagio: 0,
  icms: false,
  destinos: [],
  pernas: [],
  tipoNegociacaoSubcontratado: '',
  freteAgregado: 0,
  freteGrupo: 0,
  freteSubcontratadoPJ: 0,
  freteSubcontratadoPF: 0,
  numeroPedido: '',
  dataInicio: new Date(),
  dataTermino: new Date(),
  cadencia: '',
  operacaoKMM: '',
  observacao: '',
  localCarregamento: '',
  files: []
}

export const LotesView = ({ isValidation }: Props) => {
  const history = useHistory()
  const { id } = useParams<{ id: string }>()
  const [loteState, loteActions] = useLote()
  const isAdmin = useIsAdmin()
  const [isModalDeleteBalanceOpen, setIsModalDeleteBalanceOpen] =
    useState(false)
  const [
    isModalBalanceAlreadyRemovedOpen,
    setIsModalBalanceAlreadyRemovedOpen
  ] = useState(false)
  const [isModalChangeStatusOpen, setIsModalChangeStatusOpen] = useState(false)
  const [updateBalance, setUpdateBalance] = useState(false)
  const [value, setValue] = useState(0)
  const [openSpeedDial, setOpenSpeedDial] = useState(false)
  const classes = useStyles()
  const [isModalDivulgacao, setIsModalDivulgacao] = useState(false)

  let isStandby = false
  let isAnalise = false
  let isReanalise = false
  let isPendente = false
  let isAtivo = false
  let canDivulgarLote = false
  let loteStatus = 'ANALISE'

  if (loteState.tag === 'with-data') {
    isStandby = loteState.entity.status === 'STANDBY'
    isAnalise = loteState.entity.status === 'ANALISE'
    isReanalise = loteState.entity.status === 'REANALISE'
    isPendente = loteState.entity.status === 'PENDENTE'
    isAtivo = loteState.entity.status === 'ATIVO'
    canDivulgarLote = loteState.entity.canDivulgarLote
    loteStatus = loteState.entity.status
  }

  const hasWarning =
    loteStatus &&
    (loteStatus === 'PENDENTE' ||
      loteStatus === 'CANCELADO' ||
      loteStatus === 'REPROVADO')

  const validateAuthorization =
    useReadAbility('Validação de Lotes') &&
    isValidation &&
    (isAnalise || isReanalise)

  function handleGoBack() {
    history.goBack()
  }

  const handleDeleteBalance = (values: any) => {
    if (values.saldoExcluido) {
      setIsModalBalanceAlreadyRemovedOpen(true)
    } else {
      setIsModalDeleteBalanceOpen(true)
    }
  }

  const handleSubmitUpdateStatus = async (id: string) => {
    const res = await excluirSaldo(id)

    if (res.ok) {
      snackbarStore.setMessage('Saldo excluído com sucesso!')
      setUpdateBalance(!updateBalance)
    } else {
      snackbarStore.setMessage(res.data?.message ?? 'Error ao excluir saldo!')
    }
  }

  const handleOpenSpeedDial = () => {
    setOpenSpeedDial(prev => !prev)
  }

  const handleModalDivulgacao = () => {
    setIsModalDivulgacao(prev => !prev)
  }

  const updateStandbyStatusToAberto = async (id: string) => {
    const res = await alterarStatus(id, { newStatus: 'ABERTO' })

    if (res.ok) {
      snackbarStore.setMessage('Status alterado com sucesso!')
      history.goBack()
    } else {
      snackbarStore.setMessage(
        res.data?.message ?? 'Error ao alterar status do lote'
      )
    }
  }

  useEffect(() => {
    loteActions.fetch(id)
  }, [id, loteActions, updateBalance])

  const handleChangeTab = (event: any, newValue: SetStateAction<number>) => {
    setValue(newValue)
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`
    }
  }

  return (
    <Fragment>
      <Formik
        enableReinitialize
        initialValues={
          loteState.tag === 'with-data'
            ? formatDataFromExistingLote(loteState.entity)
            : initialValues
        }
        onSubmit={() => {}}
      >
        {({ values, isSubmitting }) => (
          <Fragment>
            <FreteEmpresaListener />
            <AppBar position="static">
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="simple tabs example"
              >
                <Tab label="Lote" {...a11yProps(0)} />
                <Tab label="Viagens" {...a11yProps(1)} />
                <Tab label="ALTERAÇÕES E COMENTÁRIOS " {...a11yProps(2)} />
              </Tabs>
            </AppBar>
            <Paper noTopBorderRadius>
              {loteState.tag === 'with-data' ? (
                <Fragment>
                  <Form>
                    <DivulgarFreteDialog
                      modal={isModalDivulgacao}
                      handleModal={handleModalDivulgacao}
                      lote={loteState.entity}
                    />
                    <TabPanel value={value} index={0}>
                      <TabLote />
                      <Grid
                        justifyContent="flex-end"
                        item
                        container
                        spacing={2}
                        style={{ marginTop: 8 }}
                      >
                        <Grid item style={{ width: 160 }}>
                          <Button
                            fullWidth
                            type="button"
                            variant="outlined"
                            onClick={handleGoBack}
                            disabled={isSubmitting}
                          >
                            Voltar
                          </Button>
                        </Grid>
                        <When value={isStandby && !isValidation} equals>
                          <Grid item>
                            <Button
                              fullWidth
                              type="button"
                              variant="outlined"
                              onClick={() => updateStandbyStatusToAberto(id)}
                              style={{
                                backgroundColor:
                                  returnStatusColor(`STANDBY`).color
                              }}
                            >
                              SOLICITAR ANÁLISE
                            </Button>
                          </Grid>
                        </When>
                        <When
                          value={(isAtivo || isPendente) && !isValidation}
                          equals
                        >
                          <ValidationRecord
                            status="REABERTO"
                            id={id}
                            title="REANALISE"
                          />
                        </When>
                        <When value={isAdmin} equals>
                          <Grid item style={{ width: 160 }}>
                            <Button
                              fullWidth
                              type="button"
                              variant="contained"
                              color="primary"
                              onClick={() => setIsModalChangeStatusOpen(true)}
                              disabled={isSubmitting}
                            >
                              Alterar Dados
                            </Button>
                          </Grid>
                        </When>
                        <When value={validateAuthorization} equals>
                          <ValidationRecord
                            status="CANCELADO"
                            id={id}
                            title="CANCELAR"
                          />
                          <ValidationRecord
                            status="REPROVADO"
                            id={id}
                            title="REPROVAR"
                          />
                          <ValidationRecord
                            status="PENDENTE"
                            id={id}
                            title="PENDENTE"
                          />
                          <ValidationRecord
                            liberadaGestor
                            status="ATIVO"
                            id={id}
                            title="LIB. GESTOR"
                          />
                          <ValidationRecord
                            status="ATIVO"
                            id={id}
                            title="LIBERAR"
                          />
                        </When>
                      </Grid>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <TabViagens />
                      <Grid
                        justifyContent="flex-end"
                        item
                        container
                        spacing={2}
                        style={{ marginTop: 8 }}
                      >
                        <Grid item xs={12}>
                          <Grid justifyContent="flex-end" container spacing={2}>
                            <Grid item style={{ width: 160 }}>
                              <Button
                                fullWidth
                                type="button"
                                variant="outlined"
                                disabled={
                                  loteState.entity.status !== 'FINALIZADO'
                                }
                                onClick={() => handleDeleteBalance(values)}
                                color="primary"
                              >
                                Excluir Saldo
                              </Button>
                            </Grid>
                            <Grid item style={{ width: 160 }}>
                              <Button
                                fullWidth
                                type="button"
                                variant="outlined"
                                onClick={handleGoBack}
                              >
                                Voltar
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                        <When value={validateAuthorization} equals>
                          <ValidationRecord
                            status="CANCELADO"
                            id={id}
                            title="CANCELAR"
                          />
                          <ValidationRecord
                            status="REPROVADO"
                            id={id}
                            title="REPROVAR"
                          />
                          <ValidationRecord
                            status="PENDENTE"
                            id={id}
                            title="PENDENTE"
                          />
                          <ValidationRecord
                            liberadaGestor
                            status="ATIVO"
                            id={id}
                            title="LIB. GESTOR"
                          />
                          <ValidationRecord
                            status="ATIVO"
                            id={id}
                            title="LIBERAR"
                          />
                        </When>
                      </Grid>
                    </TabPanel>
                  </Form>
                  <TabPanel value={value} index={2}>
                    <AlteracoesComentarios />
                    <Grid
                      justifyContent="flex-end"
                      item
                      container
                      spacing={2}
                      style={{ marginTop: 8 }}
                    >
                      <Grid item style={{ width: 160 }}>
                        <Button
                          fullWidth
                          type="button"
                          variant="outlined"
                          onClick={handleGoBack}
                          disabled={isSubmitting}
                        >
                          Voltar
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </Fragment>
              ) : null}
            </Paper>
            <SimpleDialog
              message="O saldo já foi zerado."
              open={isModalBalanceAlreadyRemovedOpen}
              buttonLabel="OK"
              handleClose={() => setIsModalBalanceAlreadyRemovedOpen(false)}
              title="Excluir saldo!"
              primaryActionButtonLabel="OK"
            />
            <SimpleDialog
              message="Você deseja excluir o saldo desse lote?"
              open={isModalDeleteBalanceOpen}
              buttonLabel="VOLTAR"
              handleClose={() => setIsModalDeleteBalanceOpen(false)}
              primaryAction={() => handleSubmitUpdateStatus(values.id)}
              title="Excluir saldo!"
              primaryActionButtonLabel="CONFIRMAR"
            />
            <AlterarDadosDialog
              handleClose={() => setIsModalChangeStatusOpen(false)}
              open={isModalChangeStatusOpen}
              id={id}
              centroCustoId={values.centroCustoId}
            />
          </Fragment>
        )}
      </Formik>
      {canDivulgarLote ? (
        <ClickAwayListener onClickAway={() => setOpenSpeedDial(false)}>
          <SpeedDial
            ariaLabel="SpeedDial"
            className={classes.speedDial}
            onClick={handleOpenSpeedDial}
            icon={
              <SpeedDialIcon
                icon={<MoreVert titleAccess="Fechar" />}
                openIcon={<Close titleAccess="Opções" />}
              />
            }
            open={openSpeedDial}
          >
            {hasWarning && (
              <Badge
                color="primary"
                badgeContent="!"
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                className={classes.speedDial}
              >
                <SpeedDialAction
                  key="null"
                  tooltipTitle="null"
                  onClick={() => {}}
                />
              </Badge>
            )}
            <SpeedDialAction
              key="Divulgação de Frete"
              icon={<SendOutlined />}
              tooltipTitle="Divulgação de Frete"
              onClick={handleModalDivulgacao}
            />
          </SpeedDial>
        </ClickAwayListener>
      ) : null}
    </Fragment>
  )
}
