import { useQueryParams } from '@elentari/core'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useLote } from '../hooks/useLote'
import { LotesForm } from './LotesForm'
import { LotesView } from './LotesView'
import { useLocation } from 'react-router-dom'

export function LoteMasterDetail() {
  const [loteState, loteActions] = useLote()
  const [, appBarActions] = useAppBar()

  const location = useLocation()

  const isValidation = location.pathname.includes('lotes-validacao')
  const {
    initialValues: { view }
  } = useQueryParams<{ view: boolean }>()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      loteState,
      l => l.entity.id,
      l =>
        view ? `Consultar Lote #${l.entity.codigo}` : `Lote #${l.entity.codigo}`
    )
  ])

  useFetchAndReset(loteActions.fetch, loteActions.reset)
  return handleStateErrorsToRender(
    loteState,
    view ? <LotesView isValidation={isValidation} /> : <LotesForm />
  )
}
