import { CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import { ConsultaField } from 'src/components/ConsultaField'
import { StatusColors } from 'src/components/Validation/StatusColors'
import When from 'src/components/When'
import { useDivulgarLoteCarguero } from '../hooks/useDivulgarLoteCarguero'
import { useIsCargueroHabilitado } from '../hooks/useIsCargueroHabilitado'
import { useLote } from '../hooks/useLote'
import { IntegracaoButton } from './IntegracaoButton'

export const IntegracaoCarguero = () => {
  const [loteState] = useLote()
  const { isCargueroHabilitado } = useIsCargueroHabilitado()
  const {
    loadingDivulgacao,
    loadingFinalizacao,
    divulgarCarguero,
    finalizarLote,
    loteDivulgado
  } = useDivulgarLoteCarguero()

  const isTipoContrato =
    loteState.tag === 'with-data' &&
    loteState.entity.tipoContratacao === 'CONTRATO'

  return loteState.tag === 'with-data' ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Carguero
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <When value={!loteDivulgado} equals>
            <Grid item xs={12}>
              <ConditionalTooltip
                enabled={!isCargueroHabilitado}
                title="A integração com o Cargueiro está desabilitada"
              >
                <IntegracaoButton
                  type="button"
                  variant="outlined"
                  onClick={() => divulgarCarguero(loteState.entity.id)}
                  style={{
                    backgroundColor: StatusColors.LIBERADA
                  }}
                  disabled={
                    loadingDivulgacao || !isCargueroHabilitado || isTipoContrato
                  }
                >
                  {loadingDivulgacao ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    'DIVULGAR'
                  )}
                </IntegracaoButton>
              </ConditionalTooltip>
            </Grid>
          </When>

          <When value={loteDivulgado} equals>
            <Grid item xs={12}>
              <ConsultaField
                title="CÓDIGO LOTE TRANSPORTADOR"
                subtitle={loteState.entity.codigoCarguero}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                placement="bottom"
                title={
                  <Typography variant="caption">
                    Para finalizar a divulgação, entre na plataforma do Carguero
                  </Typography>
                }
              >
                <span>
                  <IntegracaoButton
                    type="button"
                    variant="outlined"
                    onClick={() => finalizarLote(loteState.entity.id)}
                    style={{
                      backgroundColor: StatusColors.PENDENTE
                    }}
                    disabled
                  >
                    {loadingFinalizacao ? (
                      <CircularProgress color="inherit" size={24} />
                    ) : (
                      'FINALIZAR'
                    )}
                  </IntegracaoButton>
                </span>
              </Tooltip>
            </Grid>
          </When>
        </Grid>
      </Grid>
    </Grid>
  ) : null
}
