import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs, stringify } from 'qs'
import { AgrupadorProdutos } from 'src/modules/agrupador-produtos/types'
import { apiSauceInstance } from '../../../services/api'
import { paginationParams } from '../../../utils/paginationQueryParams'
import {
  AlterarDadosDto,
  AlterarStatusData,
  GetNegociacaoDto,
  LoteList,
  LotesDetail,
  LotesFormData,
  SaveLotesInput,
  StatusLote,
  TotalNegociacao
} from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      createdAt: 'asc'
    }
  }
}

export const lotesRepository = makeRepository<
  EdgesPage<LoteList>,
  SaveLotesInput,
  LotesDetail,
  string
>({ resource: 'lotes', mapQueryParams }, apiSauceInstance)

export const alterarStatus = async (id: string, data: AlterarStatusData) => {
  return apiSauceInstance.patch<
    { status: StatusLote },
    { message: string; error: any }
  >(`/lotes/${id}/alterar-status/`, data)
}

export const alterarDados = async (id: string, data: AlterarDadosDto) => {
  return apiSauceInstance.patch<
    { status: StatusLote },
    { message: string; error: any }
  >(`/lotes/${id}/alterar-dados/`, data)
}

export const puxarProxima = async (codigo: number) => {
  return apiSauceInstance.get<EdgesPage<LotesDetail>>(`lotes?codigo=${codigo}`)
}

export const excluirSaldo = async (id: string) => {
  return apiSauceInstance.patch<{ message: string; error: any }>(
    `/lotes/${id}/excluir-saldo`
  )
}

export const fetchMessageLoteDivulgarWhatsapp = (loteId: string) => {
  return apiSauceInstance.get<{ message: string }>(
    `/lotes/${loteId}/divulgar-whatsapp`
  )
}

export const enviarOfertaFreto = async (loteId: string) => {
  return apiSauceInstance.patch<{ message: string; error: any }>(
    `/lotes/${loteId}/criar-oferta-freto`
  )
}

export const inativarOfertaFreto = async (loteId: string) => {
  return apiSauceInstance.patch<{ message: string; error: any }>(
    `/lotes/${loteId}/inativar-oferta-freto`
  )
}

export const isFretoHabilitado = async () => {
  return apiSauceInstance.get<{ result: boolean }>(`/lotes/is-freto-habilitado`)
}

export const isCargueroHabilitado = async () => {
  return apiSauceInstance.get<{ result: boolean }>(
    `/lotes/is-carguero-habilitado`
  )
}

export const divulgarCarguero = async (loteId: string) => {
  return apiSauceInstance.patch<
    {
      codigo: string
      ofertaId: string
    },
    { message: string; error: any }
  >(`/lotes/${loteId}/criar-oferta-carguero`)
}

export const suspenderOfertaCarguero = (loteId: string) => {
  return apiSauceInstance.patch(`/lotes/${loteId}/suspender-oferta-carguero`)
}

export const isTratoHabilitado = async () => {
  return apiSauceInstance.get<{ result: boolean }>(`/lotes/is-trato-habilitado`)
}

export const enviarOfertaTrato = async (loteId: string) => {
  return apiSauceInstance.patch<{ message: string; error: any }>(
    `/lotes/${loteId}/criar-oferta-trato`
  )
}

export const suspenderOfertaTrato = async (loteId: string) => {
  return apiSauceInstance.patch<{ message: string; error: any }>(
    `/lotes/${loteId}/suspender-oferta-trato`
  )
}

export const getAliquotaICMS = async (
  estadoOrigem: string,
  estadoDestino: string
) => {
  return apiSauceInstance.get<{ aliquota: number }>(`/tabela-icms`, {
    estadoOrigem,
    estadoDestino
  })
}

export const getTotalNegociacao = async (data: LotesFormData) => {
  const query: GetNegociacaoDto = {
    pernas: data.pernas.map((p: any) => ({
      aliquotaICMS: p.aliquotaICMS || undefined,
      freteAgregado: p.freteAgregado || undefined,
      freteEmpresa: p.freteEmpresa || undefined,
      freteGrupo: p.freteGrupo || undefined,
      freteSubcontratadoPF: p.freteSubcontratadoPF || undefined,
      freteSubcontratadoPJ: p.freteSubcontratadoPJ || undefined,
      valorPedagio: p.valorPedagio || undefined
    })),
    icms: data.icms
  }

  return apiSauceInstance.get<TotalNegociacao>(
    `/lotes/total-negociacao?${stringify(query)}`
  )
}

export const getAgrupadorProdutoFromLote = async (loteId: string) => {
  return apiSauceInstance.get<AgrupadorProdutos>(
    `/lotes/${loteId}/agrupador-produto`
  )
}

export const hasToChangeStatus = async (data: any) => {
  return apiSauceInstance.put<boolean, ApiErrorResponse>(
    `/lotes/${data.id}/has-sensible-changes`,
    data
  )
}
