import { useMemo } from 'react'
import AsyncSearchInput from './AsyncSearchInput'
export type Action = 'create' | 'read' | 'update' | 'delete' | 'manage'

export type LabelField = 'name' | 'username'

interface Props {
  name: string
  label: string
  subject: string
  action: Action
  inverted?: boolean
  moduleName?: string
  lotacoes?: string[]
  labelField?: LabelField
  disabled?: boolean
}

export const UserByScopeAndAbilityInput = ({
  name,
  label,
  subject,
  action,
  inverted = false,
  moduleName = 'Comercial',
  labelField = 'name',
  lotacoes = undefined,
  disabled = false
}: Props) => {
  const lotacoesLength = Array.isArray(lotacoes)
    ? lotacoes.filter(Boolean).length
    : 0

  const filter = useMemo(() => {
    return lotacoes && lotacoesLength
      ? {
          ability: { subject, action, inverted },
          moduleName,
          scopes: lotacoes
        }
      : {
          ability: { subject, action, inverted },
          moduleName
        }
  }, [action, inverted, lotacoes, lotacoesLength, moduleName, subject])

  return (
    <AsyncSearchInput
      name={name}
      label={label}
      url={`/users/by-scope-and-ability`}
      filters={filter}
      labelField={labelField}
      disabled={disabled}
    />
  )
}
