import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import LinkMD from '@material-ui/core/Link'
import { observer } from 'mobx-react-lite'
import qs from 'qs'
import { useEffect, useMemo } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { AddRecord, Paper, TableCellHead, TableContainer } from 'src/components'
import { EditarStatusRecord } from 'src/components/EditarStatusRecord'
import { TiposNegociacaoEnum } from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import NoResultsTable from 'src/components/NoResultsTable'
import When from 'src/components/When'
import { useAbility } from 'src/modules/login'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useContrato } from '../hooks/useContrato'
import contratosStore from '../store/store'
import { UseParamsProps } from '../types/types'
import TabelaContratoStatusBadge from './TabelaContratoStatusBadge'

export const TabelaTable = observer(() => {
  const [contratoState] = useContrato()

  const { id: contratoId } = useParams<UseParamsProps>()
  const ability = useAbility()
  const history = useHistory()
  const location = useLocation()

  const initialValues = useMemo(() => {
    return qs.parse(history.location.search.substring(1))
  }, [history.location.search])

  useEffect((): void => {
    contratosStore.setContratoId(contratoId)

    contratosStore.tabelas.setPage(Number(initialValues.page) || 1)

    contratosStore.tabelas.setFilters(initialValues)
  }, [initialValues, contratoId])

  const authorization = getAuthorizations(ability, 'Contratos')
  const diabledAddRecord =
    contratoState.tag === 'with-data' && contratoState.entity.status === 'ATIVO'

  function canUpdateTabelaContrato(): boolean {
    return (
      contratoState.tag === 'with-data' &&
      contratoState.entity.status !== 'ATIVO'
    )
  }

  return (
    <>
      <Paper noTopBorderRadius>
        <TableContainer
          loading={contratosStore.tabelas.isLoading}
          totalCount={contratosStore.tabelas.totalCount}
        >
          {contratosStore.tabelas.list.length ? (
            <Table>
              <TableHead>
                <TableCellHead label="Código" sort="codigo" />
                <TableCellHead label="Descrição" sort="descricao" />
                <TableCellHead label="Status" sort="status" />
                <TableCellHead
                  label="Tipo de Negociação"
                  sort="tipoNegociacao"
                />
                <When value={authorization?.canUpdate} equals>
                  <TableCell />
                </When>
              </TableHead>
              <TableBody>
                {contratosStore.tabelas.list.map(tabela => (
                  <TableRow hover key={tabela.id}>
                    <TableCell>
                      <LinkMD
                        component={Link}
                        to={`/contratos/${contratoId}/tabelas/${tabela.id}`}
                      >
                        {tabela.codigo}
                      </LinkMD>
                    </TableCell>
                    <TableCell>
                      <LinkMD
                        component={Link}
                        to={`/contratos/${contratoId}/tabelas/${tabela.id}`}
                      >
                        {tabela.descricao}
                      </LinkMD>
                    </TableCell>
                    <TableCell>
                      <TabelaContratoStatusBadge
                        status={tabela.status === 'ATIVO' ? 'ATIVO' : 'INATIVO'}
                      />
                    </TableCell>
                    <TableCell>
                      {TiposNegociacaoEnum[tabela.tipoNegociacaoEmpresa]}
                    </TableCell>
                    <TableCell>
                      <When value={authorization?.canDelete} equals>
                        <EditarStatusRecord
                          disabled={canUpdateTabelaContrato()}
                          status="ATIVO"
                          isTabelasContrato
                          id={contratoId}
                          childId={tabela.id}
                          isStatusChangeable
                          optionsStatus={['ATIVO', 'INATIVO']}
                        />
                      </When>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={contratosStore.tabelas.isLoading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord
        path={location.pathname}
        authorization={authorization}
        disabled={!diabledAddRecord}
      />
    </>
  )
})
