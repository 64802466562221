import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { TableCellHead, TableContainer } from 'src/components'
import NoResultsTable from 'src/components/NoResultsTable'
import { LoteList } from 'src/modules/lotes/types'
import { formatDate } from 'src/utils/formatters'
import {
  formatDestinoLote,
  formatLocalDescarregamento
} from 'src/utils/formatters/formatDestinoLote'
import { formatProdutoLote } from 'src/utils/formatters/formatProdutoLote'
import { useSelecaoLotes } from '../../hooks/useSelecaoLotes'
import selecaoLotesStore from '../../store/selecao-lotes-store'
import { ProgramacaoFormData } from '../../types/formData'
import { BuscarLotes } from './BuscarLotes'

function ModalSelecaoLotes() {
  const { handleAddLote, isLoteSelected, handleRemoveLote } = useSelecaoLotes()
  const formik = useFormikContext<ProgramacaoFormData>()

  const handleClickBack = () => {
    formik.setFieldValue('lotes', selecaoLotesStore.oldSelection)
    formik.setFieldTouched('lotes', false)
    selecaoLotesStore.closeSelecaoLotesModal()
  }

  const handleSelectLote = (
    event: React.ChangeEvent<HTMLInputElement>,
    lote: LoteList
  ) => {
    const checked = event.target.checked

    if (!checked) {
      return handleRemoveLote(lote.id)
    }

    handleAddLote(lote)
  }

  return (
    <Dialog
      maxWidth="xl"
      open={selecaoLotesStore.selecaoLotesModal}
      onClose={handleClickBack}
    >
      <DialogTitle>Selecionar lotes</DialogTitle>
      <DialogContent>
        <BuscarLotes />

        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Typography variant="h5">Selecionar Lotes</Typography>
          </Grid>
        </Grid>

        <TableContainer
          loading={selecaoLotesStore.lotes.isLoading}
          totalCount={selecaoLotesStore.lotes.totalCount}
        >
          {selecaoLotesStore.lotes.list.length ? (
            <Table>
              <TableHead>
                <TableCellHead label="" />
                <TableCellHead label="Código" />
                <TableCellHead label="Tipo Contratação" />
                <TableCellHead label="Centro de Custo" />
                <TableCellHead label="Data Inicial de Retirada" />
                <TableCellHead label="Data Limite de Retirada" />
                <TableCellHead label="Grupo Negociador" />
                <TableCellHead label="Produto" />
                <TableCellHead label="Local de Carregamento" />
                <TableCellHead label="Origem" />
                <TableCellHead label="Local de Descarregamento" />
                <TableCellHead label="Destino" />
                <TableCellHead label="Saldo" />
              </TableHead>
              <TableBody>
                {selecaoLotesStore.lotes.list.map(lote => (
                  <TableRow hover key={lote.id}>
                    <TableCell>
                      <Checkbox
                        checked={isLoteSelected(lote.id)}
                        onChange={e => handleSelectLote(e, lote)}
                      />
                    </TableCell>
                    <TableCell>{lote.codigo}</TableCell>
                    <TableCell>{lote.tipoContratacao}</TableCell>
                    <TableCell>{lote.CentroCusto.descricao}</TableCell>
                    <TableCell>
                      {formatDate(new Date(lote.dataInicio))}
                    </TableCell>
                    <TableCell>
                      {formatDate(new Date(lote.dataTermino))}
                    </TableCell>
                    <TableCell>{lote.Cliente.nome}</TableCell>
                    <TableCell>{formatProdutoLote(lote.Produto)}</TableCell>
                    <TableCell>{lote.localCarregamento}</TableCell>
                    <TableCell>{`${lote.Origem.cidade} / ${lote.Origem.siglaEstado}`}</TableCell>
                    <TableCell>
                      {formatLocalDescarregamento(lote.Destino)}
                    </TableCell>
                    <TableCell>{formatDestinoLote(lote.Destino)}</TableCell>
                    <TableCell>
                      {lote.tipoLote === 'ABERTO' ? '-' : lote.saldo}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <NoResultsTable loading={selecaoLotesStore.lotes.isLoading} />
          )}
        </TableContainer>
      </DialogContent>

      <DialogActions style={{ padding: 16 }}>
        <Button variant="outlined" onClick={handleClickBack} color="primary">
          Cancelar
        </Button>
        <Button
          variant="contained"
          onClick={selecaoLotesStore.closeSelecaoLotesModal}
          color="primary"
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(ModalSelecaoLotes)
