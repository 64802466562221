import { ApiResponse } from 'apisauce'
import snackbarStore from 'src/stores/snackbar'

interface AsyncNotifyOptions<T> {
  feedbackSuccess?: string
  feedbackError: string
  onSuccess?: (data: T | undefined) => void
  onError?: (err: unknown) => void
}

export interface ErrorApiResponse {
  error: string
  message: string
  statusCode: number
}

export async function EntitySaver<T>(
  func: () => Promise<ApiResponse<T, ErrorApiResponse>>,
  options: AsyncNotifyOptions<T>
) {
  const response = await func()

  if (!response.ok) {
    options.onError && options.onError(response.data)
    snackbarStore.setMessage(response.data?.message || options.feedbackError)
  } else {
    options.onSuccess && options.onSuccess(response.data)
    snackbarStore.setMessage(options.feedbackSuccess ?? 'Salvo com sucesso!')
  }
}
