import {
  Checkbox,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography
} from '@material-ui/core'
import Info from '@material-ui/icons/InfoOutlined'
import { isObject, useFormikContext } from 'formik'
import { useMemo } from 'react'
import TextInput from 'src/components/Formik/Forms/TextInput'
import {
  LoteConfigFormData,
  ProgramacaoConfigFormData,
  RelatorioInternoFormData
} from '../types'
import { configSwitch, filterSwitch } from '../utils/switchers'

function countAllConfigs(
  config: LoteConfigFormData | ProgramacaoConfigFormData
) {
  let count = 0

  for (const value of Object.values(config)) {
    if (isObject(value)) {
      count += countAllConfigs(value)
    } else {
      count++
    }
  }

  return count
}

function countSelectedConfigs(
  config: LoteConfigFormData | ProgramacaoConfigFormData
) {
  let count = 0

  for (const value of Object.values(config)) {
    if (isObject(value)) {
      count += countSelectedConfigs(value)
    } else {
      count += Number(value)
    }
  }

  return count
}

export const ConfigRelatorioInterno = () => {
  const formik = useFormikContext<RelatorioInternoFormData>()
  const FilterInput = filterSwitch[formik.values.tipoRelatorio]
  const ConfigInput = configSwitch[formik.values.tipoRelatorio]

  const checkAll = (
    config: LoteConfigFormData | ProgramacaoConfigFormData,
    prefix: string,
    checked: boolean
  ) => {
    for (const [key, value] of Object.entries(config)) {
      const newPrefix = prefix ? `${prefix}.${key}` : key

      if (isObject(value)) {
        checkAll(value, newPrefix, checked)
      } else {
        formik.setFieldValue(`config.${newPrefix}`, checked)
      }
    }
  }

  const selectedCount = countSelectedConfigs(formik.values.config)
  const allCount = useMemo(
    () => countAllConfigs(formik.values.config),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik.values.tipoRelatorio]
  )

  const selected = selectedCount === allCount

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2} item xs={12}>
        <Grid item xs={12}>
          <Typography variant="h5">Filtros</Typography>
        </Grid>
        <Grid item xs={12}>
          <FilterInput />
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h5">
            Configuração da Planilha ({selectedCount}/{allCount} selecionados){' '}
          </Typography>
        </Grid>

        <Grid container justifyContent="flex-end" item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selected}
                onClick={e => {
                  e.stopPropagation()
                }}
                onChange={(_, checked) => {
                  checkAll(formik.values.config, '', checked)
                }}
                color="primary"
              />
            }
            label="Selecionar todos"
          />
        </Grid>

        <Grid item xs={12}>
          <ConfigInput />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5">Descrição</Typography>
        </Grid>
        <Grid item xs={3}>
          <TextInput
            name="description"
            label="Descrição (opcional)"
            InputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    <Typography variant="body2">
                      A descrição será usada como nome do arquivo final. Caso
                      não haja um valor, um nome padrão será configurado
                    </Typography>
                  }
                >
                  <Info />
                </Tooltip>
              )
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
