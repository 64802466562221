import { FormHelperText } from '@material-ui/core'
import { useField, Field } from 'react-final-form'
import InputCoordinate, { InputCoordinateProps } from '../InputCoordinate'

interface RFFInputCoordinateProps extends InputCoordinateProps {
  name: string
}

function RFFInputCoordinate({ name, ...rest }: RFFInputCoordinateProps) {
  const { meta } = useField(name)

  const showError =
    ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) &&
    meta.touched

  return (
    <Field name={name}>
      {({ input, meta, mask, label, ...props }) => (
        <>
          <InputCoordinate
            {...props}
            {...rest}
            name={input.name}
            onBlur={input.onBlur}
            value={input.value}
            error={showError}
            fullWidth
            variant="outlined"
            color="primary"
            handleOnChange={input.onChange}
          />

          {showError ? (
            <FormHelperText error={showError}>{meta.error}</FormHelperText>
          ) : null}
        </>
      )}
    </Field>
  )
}

export default RFFInputCoordinate
