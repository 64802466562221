import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { AgrupadorProdutosForm } from './AgrupadorProdutosForm'
import { useAgrupadorProdutos } from '../hooks/useAgrupadorProdutos'

export function AgrupadorProdutosMasterDetail() {
  const [centroCustoState, centroCustoActions] = useAgrupadorProdutos()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      centroCustoState,
      u => u.entity.id,
      u => u.entity.nome
    )
  ])

  useFetchAndReset(centroCustoActions.fetch, centroCustoActions.reset)
  return handleStateErrorsToRender(centroCustoState, <AgrupadorProdutosForm />)
}
