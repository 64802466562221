import { EdgesPage } from '@elentari/core/types'
import { makeSubRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from 'src/services/api'
import { paginationParams } from 'src/utils/paginationQueryParams'
import {
  AlterarStatusTabelaContratoData,
  StatusTabelaContrato,
  TabelaDetail,
  TabelaFormData
} from '../../types/tabelaTypes'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params
  return {
    ...paginationParams(rest),
    sort: sort || {
      createdAt: 'desc'
    }
  }
}

export const tabelaSubRepository = makeSubRepository<
  EdgesPage<TabelaDetail>,
  TabelaFormData,
  TabelaDetail,
  string,
  string
>({ resource: 'contratos/:id/tabelas', mapQueryParams }, apiSauceInstance)

export const alterarStatus = async (
  contratoId: string,
  tabelaId: string,
  data: AlterarStatusTabelaContratoData
) => {
  return apiSauceInstance.patch<
    { status: StatusTabelaContrato },
    { message: string; error: any }
  >(`/contratos/${contratoId}/tabelas/${tabelaId}/alterar-status`, data)
}
