import { InputAdornment } from '@material-ui/core'
import NumericInput from 'src/components/Formik/Forms/NumericInput'

interface Props {
  name: string
  label?: string
}

export const AliquotaIcmsInput = ({ name, label = 'Alíquota ICMS' }: Props) => {
  return (
    <NumericInput
      name={name}
      label={label}
      maxValue={100}
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>
      }}
    />
  )
}
