import {
  Divider,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
  Link
} from '@material-ui/core'
import { SvgIconComponent } from '@material-ui/icons'
import Person from '@material-ui/icons/Person'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import PhoneIcon from '@material-ui/icons/Phone'
import { withStyles } from '@material-ui/styles'
import { observer } from 'mobx-react-lite'
import mapaProgramacoesStore from './store'
import { City } from './types'

import { PersonOutlineRounded } from '@material-ui/icons'

const IconAndText = ({
  tooltipTitle,
  Icon,
  text,
  isPhone = false
}: {
  Icon: SvgIconComponent
  tooltipTitle: string
  text?: string
  isPhone?: boolean
}) => {
  function handleWhatsapp() {
    const phone = text?.replace(/\D/g, '')
    window.open(`https://api.whatsapp.com/send?phone=55${phone}`, '_blank')
  }

  return (
    <Tooltip
      placement="left"
      arrow
      title={<Typography variant="body2">{tooltipTitle}</Typography>}
      aria-label={tooltipTitle}
    >
      <Grid container spacing={2}>
        <Grid item xs="auto">
          <Icon fontSize="small" />
        </Grid>
        <Grid item xs="auto">
          {isPhone && text ? (
            <Link onClick={handleWhatsapp} style={{ cursor: 'pointer' }}>
              {text ?? '-'}
            </Link>
          ) : (
            <Typography variant="body2">{text ?? '-'}</Typography>
          )}
        </Grid>
      </Grid>
    </Tooltip>
  )
}

const RotatedDoubleArrow = withStyles({
  root: {
    transform: 'rotate(180deg)'
  }
})(DoubleArrowIcon)

function CityDrawer() {
  let programacoes: City['programacoes'] = []
  let CidadeIcon: SvgIconComponent
  let cidadeMessage: string

  if (mapaProgramacoesStore.typeFilter === 'ORIGEM') {
    CidadeIcon = DoubleArrowIcon
    cidadeMessage = 'Indo para'
  }
  if (mapaProgramacoesStore.typeFilter === 'DESTINO') {
    CidadeIcon = RotatedDoubleArrow as SvgIconComponent
    cidadeMessage = 'Vindo de'
  }

  if (mapaProgramacoesStore.selectedCity) {
    programacoes = mapaProgramacoesStore.selectedCity.programacoes
  }

  return (
    <Drawer
      anchor="right"
      open={mapaProgramacoesStore.isDrawerOpen}
      onClose={() => mapaProgramacoesStore.setDrawerOpen(false)}
    >
      <List>
        {programacoes?.map((programacao, index) => (
          <>
            <ListItem key={programacao + '-' + index}>
              <ListItemText
                primary={
                  <>
                    <IconAndText
                      Icon={PersonOutlineRounded}
                      tooltipTitle="Responsável da Frota"
                      text={programacao.nomeResponsavel}
                    />
                    <IconAndText
                      Icon={PhoneIcon}
                      tooltipTitle="Telefone responsável"
                      text={programacao.telefoneResponsavel}
                      isPhone
                    />
                  </>
                }
                secondary={
                  <>
                    <IconAndText
                      Icon={Person}
                      tooltipTitle="Nome do motorista"
                      text={programacao.motorista}
                    />
                    <IconAndText
                      Icon={PhoneIcon}
                      tooltipTitle="Telefone para contato"
                      text={programacao.telefoneMotorista}
                      isPhone
                    />
                    <IconAndText
                      Icon={LocalShippingIcon}
                      tooltipTitle="Placa principal do veículo"
                      text={programacao.placaPrincipal}
                    />
                    <IconAndText
                      Icon={CidadeIcon}
                      tooltipTitle={`${cidadeMessage} ${programacao.cidade} `}
                      text={programacao.cidade}
                    />
                  </>
                }
              />
            </ListItem>

            {index !== programacoes.length - 1 && (
              <Divider variant="middle" component="li" />
            )}
          </>
        ))}
      </List>
    </Drawer>
  )
}

export default observer(CityDrawer)
