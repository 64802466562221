import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import { Form } from 'formik'
import { useHistory, useLocation } from 'react-router-dom'
import { Paper } from '../../components'
import { FormikErrors, FormikFormProps } from 'formik'
import { MouseEvent, ReactNode } from 'react'
import FieldsPending from 'src/components/FieldsPending'

export interface CustomAction {
  label: string
  onClick: (e: MouseEvent<HTMLButtonElement>) => Promise<void> | void
  classes?: string
  disabled?: boolean
  hidden?: boolean
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'inherit' | 'default' | 'primary' | 'secondary'
}

export type FormWrapProps = {
  children: ReactNode
  handleSubmit: any
  disableBack?: boolean
  loading?: boolean
  disableSubmit?: boolean
  hideConfirmButton?: boolean
  hideGoBack?: boolean
  nameButton?: string
  customActions?: CustomAction[]
  handleGoBack?: () => void
  onClickButtonSave?: () => void
  errors: FormikErrors<any>
  submitCount: any
  noTopBorderRadius?: boolean
} & FormikFormProps

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      '& > * + *': {
        marginLeft: theme.spacing(2)
      }
    }
  })
)

export const FormWrap = ({
  children,
  handleSubmit,
  nameButton,
  disableSubmit = false,
  disableBack = false,
  loading = false,
  hideConfirmButton = false,
  hideGoBack = false,
  customActions = [],
  handleGoBack,
  onClickButtonSave,
  errors,
  submitCount,
  noTopBorderRadius,
  ...rest
}: FormWrapProps) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  function defaultHandleGoBack() {
    const newLocation = location.pathname
      .split('/')
      .filter(Boolean)
      .slice(0, -1)
      .join('/')

    history.push(`/${newLocation}`)
  }

  return (
    <Paper noTopBorderRadius={noTopBorderRadius}>
      <Form onSubmit={handleSubmit} noValidate {...rest}>
        <Grid container spacing={2} direction="column">
          <Grid item xs={12}>
            {children}
          </Grid>
          <FieldsPending errors={errors} submitCount={submitCount} />
          <Grid item xs={12}>
            <Grid justify="flex-end" container spacing={2}>
              {!hideGoBack && (
                <Grid item style={{ width: 160 }}>
                  <Button
                    fullWidth
                    type="button"
                    variant="outlined"
                    onClick={handleGoBack ?? defaultHandleGoBack}
                    disabled={disableBack}
                  >
                    Voltar
                  </Button>
                </Grid>
              )}
              {customActions.map(
                action =>
                  !action.hidden && (
                    <Grid item style={{ width: 160 }}>
                      <Button
                        fullWidth
                        type="button"
                        variant={action.variant ?? 'contained'}
                        color={action.color ?? 'primary'}
                        onClick={action.onClick}
                        disabled={action.disabled}
                        className={action.classes}
                      >
                        {action.label}
                      </Button>
                    </Grid>
                  )
              )}
              {!hideConfirmButton && (
                <Grid item style={{ width: 160 }}>
                  {loading ? (
                    <Button
                      data-testid="salvar"
                      fullWidth
                      disabled={true}
                      variant="contained"
                      color="primary"
                    >
                      <div className={classes.root}>
                        <CircularProgress color="inherit" size={24} />
                      </div>
                    </Button>
                  ) : (
                    <Button
                      data-testid="salvar"
                      fullWidth
                      type="submit"
                      disabled={disableSubmit}
                      variant="contained"
                      color="primary"
                      onClick={onClickButtonSave}
                    >
                      {nameButton ?? 'Salvar'}
                    </Button>
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Form>
    </Paper>
  )
}
