import {
  CircularProgress,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import { useState } from 'react'
import { downloadFile } from 'src/components/FilesList'
import { Anexo } from 'src/utils/anexo'
import { StatusRelatorioInterno } from '../types'

interface Props {
  file: Nullable<Anexo>
  status: StatusRelatorioInterno
}

export const DownloadFile = ({ file, status }: Props) => {
  const [loading, setLoading] = useState(false)

  const getDisabledReason = (status: StatusRelatorioInterno) => {
    if (status === StatusRelatorioInterno.ERRO) {
      return 'Houve um erro durante a geração do relatório.'
    }

    return 'Download ainda não disponível.'
  }

  return file ? (
    <Tooltip
      title={
        <Typography variant="body2">Fazer download da planilha</Typography>
      }
    >
      <IconButton
        disabled={loading}
        onClick={() => downloadFile(file, setLoading)}
      >
        {loading ? <CircularProgress size={24} /> : <CloudDownloadIcon />}
      </IconButton>
    </Tooltip>
  ) : (
    <Tooltip
      title={
        <Typography variant="body2">{getDisabledReason(status)}</Typography>
      }
    >
      <span>
        <IconButton disabled>
          <CloudDownloadIcon />
        </IconButton>
      </span>
    </Tooltip>
  )
}
