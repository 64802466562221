import { Chip, makeStyles } from '@material-ui/core'
import { formatStatusProgramacao } from '../../utils/formatters/formatStatus'
import { StatusProgramacao, statusColors } from './types'

interface SetBadgeColorProps {
  status: Exclude<StatusProgramacao, ''>
  marginLeft: string
}

const useStyles = makeStyles(theme => ({
  customBadge: (props: SetBadgeColorProps) => {
    return {
      backgroundColor: statusColors[props.status],
      width: 96,
      color: theme.palette.getContrastText(statusColors[props.status])
    }
  }
}))

function StatusBadge(props: SetBadgeColorProps) {
  const classes = useStyles(props)

  return (
    <Chip
      label={formatStatusProgramacao(props.status)}
      size="small"
      variant="default"
      className={classes.customBadge}
    />
  )
}

export default StatusBadge
