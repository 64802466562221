import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import BlockIcon from '@material-ui/icons/Block'
import { useState } from 'react'
import CancelWithWarningDialog from './CancelWithWarningDialog'

type Props = {
  onDelete: () => void
  title: string
  message: string
  warningText: string
  disabled?: boolean
}

export function CancelWithWarningRecord({
  onDelete,
  title,
  message,
  warningText,
  disabled = false
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Tooltip title={title} aria-label={title}>
        <IconButton onClick={() => setIsModalOpen(true)} disabled={disabled}>
          <BlockIcon />
        </IconButton>
      </Tooltip>
      <CancelWithWarningDialog
        open={isModalOpen}
        buttonLabel="Cancelar"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onDelete}
        title={title}
        message={message}
        warningText={warningText}
        primaryActionButtonLabel="Confirmar"
      />
    </>
  )
}
