import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { RelatorioInternoColumns } from './RelatorioInternoColumns'
import relatorioInternoStore from './stores'
import {
  getLabelStatusRelatorioInterno,
  getLabelTipoRelatorioInterno
} from './utils/enumFormatter'

interface Props {
  authorization: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const RelatorioInternoTable = observer(({ authorization }: Props) => {
  const { pathname } = useLocation()

  const KEY_TABLE = resourceTabela.RELATORIOS_INTERNOS
  useConfigTable(KEY_TABLE)
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !relatorioInternoStore.relatorios.isLoading &&
    tableState &&
    relatorioInternoStore.relatorios.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => RelatorioInternoColumns(),
    []
  )

  const rows = relatorioInternoStore.relatorios.list.map(entity => {
    return {
      ...entity,
      type: getLabelTipoRelatorioInterno(entity.type),
      status: getLabelStatusRelatorioInterno(entity.status)
    }
  })

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={relatorioInternoStore.relatorios.isLoading}
          totalCount={relatorioInternoStore.relatorios.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow
              linkWithRedirectClickRow={pathname}
              data={rows}
            />
          ) : (
            <NoResultsTable
              loading={relatorioInternoStore.relatorios.isLoading}
            />
          )}
        </TableContainer>
      </Paper>

      <AddRecord path={pathname} authorization={authorization} />
    </>
  )
})
