import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { ModalidadesColumns } from './ModalidadesColumns'
import { modalidadesStore } from './stores'
import { ModalidadeDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const ModalidadesTable = observer(
  ({ onDelete, authorization }: Props) => {
    const { pathname } = useLocation()

    const KEY_TABLE = resourceTabela.MODALIDADES
    useConfigTable(KEY_TABLE)
    const tableState = tableStore.getState(KEY_TABLE)
    const isTableReady =
      !modalidadesStore.modalidades.isLoading &&
      tableState &&
      modalidadesStore.modalidades.list.length

    const columns: Array<Column & IColumn> = useMemo(
      () => ModalidadesColumns({ onDelete, authorization }),
      [onDelete, authorization]
    )

    const rows = modalidadesStore.modalidades.list.map(
      ({ ...modalidade }: ModalidadeDetail) => {
        return {
          ...modalidade
        }
      }
    )

    return (
      <>
        <Spacer y={4} />
        <Paper>
          <TableContainer
            loading={modalidadesStore.modalidades.isLoading}
            totalCount={modalidadesStore.modalidades.totalCount}
          >
            {isTableReady ? (
              <BaseTable
                keyTable={KEY_TABLE}
                columns={columns}
                hasConditionClickRow={false}
                linkWithRedirectClickRow={pathname}
                data={rows}
              />
            ) : (
              <NoResultsTable
                loading={modalidadesStore.modalidades.isLoading}
              />
            )}
          </TableContainer>
        </Paper>
        <AddRecord path={pathname} authorization={authorization} />
      </>
    )
  }
)
