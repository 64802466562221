import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { RelatorioInterno, TipoRelatorioInterno } from '../types'

class RelatorioInternoStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  selected: TipoRelatorioInterno = TipoRelatorioInterno.LOTE

  setSelected(type: TipoRelatorioInterno) {
    this.selected = type
  }

  relatorios = new ApisauceDataStore<RelatorioInterno>(apiSauceInstance, {
    useNodes: true,
    path: '/relatorios-internos',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}

const relatorioInternoStore = new RelatorioInternoStore()
export default relatorioInternoStore
