import {
  blue,
  brown,
  green,
  grey,
  indigo,
  orange,
  purple,
  red,
  yellow
} from '@material-ui/core/colors'
import { TipoNegociacao } from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import { SelectOption } from 'src/components/Formik/Forms/SelectInput'
import { Anexo, CreatedAnexo } from 'src/utils/anexo'
import { TabelaDetail } from '../contratos/types/tabelaTypes'
import { ContratoDetail } from '../contratos/types/types'
import { AvisoDetail } from '../grupos-negociadores/types/aviso'
import { ProgramacaoDetail } from '../programacoes/types'

export interface LotesFormData {
  createdAt: string
  updatedAt: string
  id?: string
  descricao?: string
  tipoContratacao: TipoContratacao
  Contrato?: ContratoDetail
  Tabela?: TabelaDetail
  contrato?: AsyncSearchInputProps
  tabela?: AsyncSearchInputProps
  grupoNegociadorId: AsyncSearchInputProps
  tipoLote: TiposLote
  quantidadeTotal: number
  saldo: boolean
  operacaoKMM: number
  segundaPerna?: boolean
  permiteTrocarNota?: boolean
  tipoNegociacaoEmpresa: TipoNegociacao
  freteEmpresa: string
  pedagio: boolean
  icms: boolean
  produtoId: AsyncSearchInputProps
  pernas: PernaFormData[]
  destinos: DestinoFormData[]
  deletedDestinos?: string[]
  tipoNegociacaoSubcontratado: TipoNegociacao
  valorGrupo?: boolean
  freteAgregado: string
  freteGrupo: string
  freteSubcontratadoPJ: string
  freteSubcontratadoPF: string
  numeroPedido: string
  dataInicio: Date
  dataTermino: Date
  cadencia: string
  fileName: string
  observacao: string
  localCarregamento: string
  tipoPedagio: string
  valorPedagio: number
  aliquotaICMS: number
  emailResponsavel: AsyncSearchInputProps
  centroCustoId: AsyncSearchInputProps
  estadoOrigemId: AsyncSearchInputProps
  origemId: AsyncSearchInputProps
  files: File[]
  anexosCotacao: File[]
  prioridade?: number
  status?: Exclude<StatusLote, ''>
  margemGrupo: string
  diasParaCarregar?: number
  diasParaDescarregar?: number
  especie?: EspecieEnum
  novaDataTermino: string
  reabrir: boolean
}

export type Perna = {
  CidadeOrigem: {
    id: string
    nome: string
    Estado: {
      id: string
      nome: string
      sigla: string
    }
  }
  cidadeOrigemId: string
  localCarregamento: string
  CidadeDestino: {
    id: string
    nome: string
    Estado: {
      id: string
      nome: string
      sigla: string
    }
  }
  cidadeDestinoId: string
  localEntrega: string
  createdAt: string
  updatedAt: string
  id: string
  loteId: string
  freteEmpresa: number
  freteAgregado: number
  freteSubcontratadoPJ: number
  freteSubcontratadoPF: number
  freteGrupo: number
  operacaoKMM: number
  valorPedagio: number
  aliquotaICMS: number
  ordem: number
}

export type PernaFormData = {
  aliquotaICMS: number
  cidadeOrigemPerna: AsyncSearchInputProps
  cidadeDestinoPerna: AsyncSearchInputProps
  estadoOrigemPerna: {
    name: string
    sigla: string
    value: string
  }
  estadoDestinoPerna: {
    name: string
    sigla: string
    value: string
  }
  freteAgregado: number
  freteEmpresa: number
  freteGrupo: number
  operacaoKMM: number
  freteSubcontratadoPF: number
  freteSubcontratadoPJ: number
  localCarregamento: string
  localEntrega: string
  valorGrupo: boolean
  valorPedagio: number
}

export type DestinoFormData = {
  estadoDestinoId: {
    value: string
    name: string
  }
  destinoId: {
    value: string
    name: string
  }
  localEntrega?: string
  id?: string
}

export type DestinoDetail = {
  cidadeId: string
  loteId: string
  localDescarregamento?: string
  Cidade: {
    id: string
    nome: string
    Estado: {
      id: string
      nome: string
      sigla: string
    }
  }
  id: string
}
export interface LotesDetail {
  numeroPedido: string
  id: string
  codigo: number
  descricao?: string
  produtoId: string
  tipoContratacao: string
  Tabela?: TabelaDetail
  Pernas: Perna[]
  tipoPedagio: string
  valorPedagio: number
  aliquotaICMS: number
  motivo: string
  margemGrupo: number
  Produto: {
    nome: string
    id: string
  }
  nomeCliente: string
  CentroCusto: {
    UnidadeNegocio: {
      codigo: number
      descricao: string
      id: string
      status: string
    }
    descricao: string
    id: string
    telefone: string
    endereco: string
    cnpj: string
    nomeExibicao: string
    inscricaoEstadual: string
  }
  GrupoNegociador?: {
    id: string
    nome: string
    Avisos: AvisoDetail[]
  }
  LoteToProgramacao?: LoteToProgramacao[]
  quantidadeRetirada: number
  localCarregamento?: string
  status: Exclude<StatusLote, ''>
  centroCustoId: string
  clienteId: string
  segundaPerna?: boolean
  permiteTrocarNota?: boolean
  origemId: string
  freteEmpresa: number
  freteMotorista: number
  cadencia: string
  dataInicio: Date
  dataTermino: Date
  quantidadeTotal: number
  saldoExcluido: boolean
  saldo: number
  tipoNegociacaoEmpresa?: string
  pedagio: boolean
  icms: boolean
  previsaoMargem: number
  previsaoMargemColor: 'RED' | 'BLUE'
  tipoNegociacaoSubcontratado: string
  freteSubcontratadoPF: number
  freteSubcontratadoPJ: number
  freteAgregado: number
  freteGrupo: number
  operacaoKMM: number
  emailCriador?: string
  analistaResponsavel: string
  emailResponsavel: string
  prioridade: number
  Programacoes?: ProgramacaoDetail[]

  Origem: {
    nome: string
    estadoId: string
    Estado: {
      id: string
      nome: string
      sigla: string
    }
    id: string
  }
  DestinoLote: DestinoDetail[]
  createdAt: string
  updatedAt: string
  estadoOrigem: string
  tipoLote: TiposLote
  tiposContratacao: TipoContratacao
  tiposNegociacao: TipoNegociacao
  fileName: string
  nextPossibleStatus: string[]
  canDivulgarLote: boolean
  isEditable: boolean
  isStatusChangeable: boolean
  isCancellable: boolean
  Anexos: Anexo[]
  observacao?: string
  diasParaCarregar: Nullable<number>
  diasParaDescarregar: Nullable<number>
  especie?: EspecieEnum

  statusOfertaFreto: StatusOfertaFreto
  mensagemEnvioFreto: string
  idOfertaFreto: number
  idOfertaCarguero: string
  codigoCarguero: string

  statusOfertaTrato: StatusOfertaTrato
  idOfertaTrato: number
  ultimoEnvioTrato: Date
}

export interface AlterarStatusData {
  newStatus: StatusLote
  novaDataTermino?: Date
  motivo?: string
  liberadaGestor?: boolean
}

export interface ValidationDialogFormData {
  motivo: string
}

export interface AlterarDadosFormData {
  status: StatusLote
  prioridade: Prioridades
  analistaResponsavel: AsyncSearchInputProps | null
}

export interface AlterarDadosDto {
  status?: StatusLote
  prioridade?: Prioridades
  analistaResponsavel?: string
}

export interface LoteToProgramacao {
  loteId: string
  destinoLoteId: string
  Programacao: {
    CentroCusto: {
      descricao: string
    }
    embarcador: string
    dataHoraCarregamento: Date
    dataHoraDescarregamento: Date
    placaPrincipal: string
    quantidade: number
    status: string
  }
  freteEmpresa: number
  programacaoId: string
  quantidadePrevista: number
  valorFreteAcordado: number
  previsaoMargem: number
  previsaoMargemColor: 'RED' | 'BLUE'
}

export interface LocationData {
  id: string
}

export interface LoteList {
  id: string
  codigo: number
  segundaPerna: boolean
  descricao?: string
  tipoContratacao: string
  tipoLote: string
  operacaoKMM?: number
  operacaoKMMformated?: string
  status: Exclude<StatusLote, ''>
  dataInicio: Date
  dataTermino: Date
  emailCriador?: string
  analistaResponsavel?: string
  localCarregamento?: string
  numeroPedido?: string
  createdAt: Date
  updatedAt: Date
  quantidadeTotal: number
  quantidadeRetirada: number
  freteEmpresa: number
  freteSubcontratadoPJ: number
  freteSubcontratadoPF: number
  nextPossibleStatus: StatusLote[]
  isEditable: boolean
  isStatusChangeable: boolean
  isCancellable: boolean
  saldo: number
  Origem: CidadeMinimal
  Destino: DestinoMinimal[]
  Produto?: ProdutoMinimal
  Cliente: ClienteMinimal
  CentroCusto: CentroCustoLote
  LoteToProgramacao: LoteToProgramacao[]
  Pernas: Perna[]
  permiteTrocarNota: boolean
  tabelaId?: string
  diasParaCarregar?: number
  diasParaDescarregar?: number
  especie?: EspecieEnum
}

export interface CidadeMinimal {
  id: string
  cidade: string
  estado: string
  siglaEstado: string
}

export interface DestinoMinimal extends CidadeMinimal {
  localDescarregamento?: string
  cidadeId: string
}

export interface ProdutoMinimal {
  id: string
  nome: string
}

export interface ClienteMinimal {
  id: string
  nome: string
}

export interface CentroCustoLote {
  id: string
  codigo: number
  descricao: string
  unidadeNegocioId: string
  nomeExibicao: string
  endereco: string
  cnpj: string
  inscricaoEstadual: string
  telefone: string
}

export interface XLSXData {
  id: string
  codigo: number
  dataInicio: Date
  dataTermino: Date
  localCarregamento?: string
  tipoLote: string
  tipoNegociacaoEmpresa: string
  tipoNegociacaoSubcontratado: string
  numeroPedido?: string
  operacaoKMM?: number
  status: Exclude<StatusLote, ''>
  cliente: string
  centroCusto: string
  unidadeNegocio: string
  Origem: CidadeMinimal
  Destino: CidadeMinimal[]
  Produto?: ProdutoMinimal
  quantidadeTotal: number
  freteEmpresa: number
  freteAgregado: number
  freteGrupo: number
  freteSubcontratadoPJ: number
  freteSubcontratadoPF: number
}

export interface ComentarioLote {
  id: string
  codigo: number
  comentario: string
  emailResponsavel: string
  loteId: string
  Anexos: Anexo[]
  createdAt: Date
  updatedAt: Date
}

export interface SaveLotesInput extends LotesFormData {
  anexos: CreatedAnexo[]
}

export interface OptionalProps {
  dataReabertura?: string
  reabrir?: boolean
}

export type StatusLote =
  | 'STANDBY'
  | 'ANALISE'
  | 'REANALISE'
  | 'PENDENTE'
  | 'REPROVADO'
  | 'ATIVO'
  | 'CANCELADO'
  | 'FINALIZADO'
  | 'SUSPENSO'
  | 'VENCIDO'
  | 'ABERTO'
  | 'REABERTO'
  | ''

export const statusLote = [
  { value: 'STANDBY', label: 'STANDBY' },
  { value: 'ABERTO', label: 'ABERTO' },
  { value: 'REABERTO', label: 'REABERTO' },
  { value: 'ANALISE', label: 'ANÁLISE' },
  { value: 'REANALISE', label: 'REANÁLISE' },
  { value: 'PENDENTE', label: 'PENDENTE' },
  { value: 'REPROVADO', label: 'REPROVADO' },
  { value: 'ATIVO', label: 'ATIVO' },
  { value: 'CANCELADO', label: 'CANCELADO' },
  { value: 'FINALIZADO', label: 'FINALIZADO' },
  { value: 'SUSPENSO', label: 'SUSPENSO' },
  { value: 'VENCIDO', label: 'VENCIDO' }
]

export enum StatusLoteEnum {
  STANDBY = 'STANDBY',
  ANALISE = 'ANALISE',
  REANALISE = 'REANALISE',
  PENDENTE = 'PENDENTE',
  REPROVADO = 'REPROVADO',
  ATIVO = 'ATIVO',
  CANCELADO = 'CANCELADO',
  FINALIZADO = 'FINALIZADO',
  SUSPENSO = 'SUSPENSO',
  VENCIDO = 'VENCIDO',
  ABERTO = 'ABERTO',
  REABERTO = 'REABERTO'
}

export enum TipoContratacao {
  CONTRATO = 'CONTRATO',
  SPOT = 'SPOT'
}

export type TiposLote = 'QUANTIDADE' | 'VIAGEM' | 'ABERTO'

export const tiposContratacao = [
  { value: 'CONTRATO', label: 'Contrato' },
  { value: 'SPOT', label: 'Spot' }
]

export const tiposLote = [
  { value: 'QUANTIDADE', label: 'Lote por quantidade' },
  { value: 'VIAGEM', label: 'Lote por viagens' },
  { value: 'ABERTO', label: 'Lote Aberto' }
]

export type Prioridades = 1 | 2 | 3 | 4 | null

export type StatusOfertaFreto = 'ERRO' | 'ATIVA' | 'INATIVA' | null

export type StatusOfertaTrato = 'PUBLISHED' | 'SUSPENDED' | 'FINISHED' | null

export const prioridades = [
  { label: '1. Baixa', value: 1 },
  { label: '2. Normal', value: 2 },
  { label: '3. Alta', value: 3 },
  { label: '4. Urgente', value: 4 }
]

export enum EspecieEnum {
  PALLET = 'PALLET',
  BIG_BAG = 'BIG_BAG',
  SACO = 'SACO',
  GRANEL = 'GRANEL',
  LIQUIDO = 'LIQUIDO',
  OUTRO = 'OUTRO'
}

export const especiesOptions: SelectOption<EspecieEnum>[] = [
  { label: 'Pallet', value: EspecieEnum.PALLET },
  { label: 'Big Bag', value: EspecieEnum.BIG_BAG },
  { label: 'Saco', value: EspecieEnum.SACO },
  { label: 'Granel', value: EspecieEnum.GRANEL },
  { label: 'Líquido', value: EspecieEnum.LIQUIDO },
  { label: 'Outro', value: EspecieEnum.OUTRO }
]

export const statusColors = {
  STANDBY: grey[100],
  ANALISE: yellow[300],
  REANALISE: orange[300],
  PENDENTE: red[300],
  REPROVADO: indigo[200],
  ATIVO: green[300],
  CANCELADO: grey[500],
  FINALIZADO: blue[300],
  SUSPENSO: purple[200],
  VENCIDO: brown[200],
  ABERTO: grey[200],
  REABERTO: '#e3e3e3'
}

export const statusFormatted: Partial<Record<StatusLote, string>> = {
  ANALISE: 'ANÁLISE',
  REANALISE: 'REANÁLISE'
}

export interface TotalNegociacao {
  freteEmpresa: string
  freteAgregado: string
  freteSubcontratadoPJ: string
  freteSubcontratadoPF: string
  margemGrupo: string
  freteGrupo: string
  valorPedagio: number
  aliquotaICMS: number
}

export interface PernaNegociacao {
  freteEmpresa: number
  freteAgregado: number
  freteSubcontratadoPJ: number
  freteSubcontratadoPF: number
  freteGrupo: number
  valorPedagio: number
  aliquotaICMS: number
}

export interface GetNegociacaoDto {
  pernas: PernaNegociacao[]
  icms: boolean
}

export type LotesQueryFilter = {
  [key: string]: any
}
