import { apiSauceInstance } from '../../../services/api'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { paginationParams } from '../../../utils/paginationQueryParams'
import {
  AgrupadorProdutos,
  AgrupadorProdutosDetail,
  IAgrupadorProdutosForm
} from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, ...rest } = params

  return {
    ...paginationParams(rest),
    limit: limit || '10'
  }
}

export const agrupadoresProdutosRepository = makeRepository<
  EdgesPage<AgrupadorProdutos>,
  IAgrupadorProdutosForm,
  AgrupadorProdutosDetail,
  string
>({ resource: 'agrupadores-produtos', mapQueryParams }, apiSauceInstance)
