import { useHistory, useLocation } from 'react-router-dom'

function useDefaultGoBack() {
  const history = useHistory()
  const location = useLocation()

  function goBack() {
    const newLocation = location.pathname
      .split('/')
      .filter(Boolean)
      .slice(0, -1)
      .join('/')

    history.push(`/${newLocation}`)
  }

  return goBack
}

export { useDefaultGoBack }
