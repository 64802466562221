import { apiSauceInstance } from '../../../services/api'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { TabelaIcms, TabelaIcmsDetail, TabelaIcmsPartial } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, ...rest } = params

  return {
    ...paginationParams(rest)
  }
}

export const tabelaIcmsRepository = makeRepository<
  EdgesPage<TabelaIcmsDetail>,
  TabelaIcms,
  TabelaIcmsDetail,
  string
>({ resource: 'tabela-icms', mapQueryParams }, apiSauceInstance)

export const saveData = async (data: TabelaIcmsPartial[]) => {
  return apiSauceInstance.put<TabelaIcmsDetail[]>('/tabela-icms', data)
}
