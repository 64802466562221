import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Event } from '@material-ui/icons'
import * as dateFns from 'date-fns'
import FFMaskedInput from 'src/components/Forms/FFMaskedInput'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import { MessagesYup } from '../messages'
import { handleKeyCodes } from '../options'
import programacoesStore from './store/store'
import { ProgramacoesQueryFilter, statusProgramacao } from './types'
import { isValid } from 'date-fns'

const schema = yup.object().shape({
  placaPrincipal: yup.string().trim(),
  motorista: yup.string().trim(),
  cpfMotorista: yup.string().trim(),
  dataHoraCarregamento: yup
    .date()
    .nullable()
    .typeError(MessagesYup.INVALID_DATE),
  quantidadePrevista: yup
    .number()
    .positive(MessagesYup.MENSAGEM_VALOR_MINIMO)
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
})

const ProgramacaoFilter = () => {
  const { clear, push, initialValues } =
    useQueryParams<ProgramacoesQueryFilter>()

  const handleSubmitFilter = (
    values: ProgramacoesQueryFilter & { page: number }
  ) => {
    programacoesStore.programacoes.resetList()
    const dataHoraCarregamento = values.dataHoraCarregamento
      ? new Date(values.dataHoraCarregamento)
      : undefined

    clear()
    push({
      centroCustoId: values.centroCustoId?.value,
      dataHoraCarregamento: isValid(dataHoraCarregamento)
        ? dataHoraCarregamento?.toUTCString()
        : undefined,
      codigo: values.codigo,
      grupoNegociadorId: values.grupoNegociadorId?.value,
      cpfMotorista: values.cpfMotorista,
      status: values.status ?? undefined,
      placaPrincipal: values.placaPrincipal,
      produtoId: values.produtoId?.value,
      codigoLote: values.codigoLote
    })
  }

  const format = (initialValues: ProgramacoesQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{
        clear: 'Limpar',
        find: 'Buscar'
      }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values =>
        handleSubmitFilter({
          ...values,
          page: 1
        })
      }
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <RFFAsyncSearchInput
            label="Centro de Custo"
            name="centroCustoId"
            url="centros-custo/ativos"
            labelField="descricao"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <DatePicker
            fullWidth
            format="dd/MM/yyyy"
            name="dataHoraCarregamento"
            label="Data Carregamento"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            inputProps={{ min: 0 }}
            onKeyDown={handleKeyCodes}
            name="codigo"
            label="Código"
            type="number"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <RFFAsyncSearchInput
            name="grupoNegociadorId"
            label="Cliente"
            url="/grupos-negociadores"
            labelField="nome"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <FFMaskedInput
            mask="999.999.999-99"
            name="cpfMotorista"
            label="CPF"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField fullWidth name="placaPrincipal" label="Placa Principal" />
        </Grid>
        <Grid item xs={12} md={4}>
          <RFFAsyncSearchInput
            name="produtoId"
            label="Produto"
            url="/produtos"
            labelField="nome"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            fullWidth
            name="codigoLote"
            label="Código do Lote"
            type="number"
            inputProps={{ min: 0 }}
            onKeyDown={handleKeyCodes}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <Select
            fullWidth
            name="status"
            label="Status"
            items={statusProgramacao}
          />
        </Grid>
      </Grid>
    </Filter>
  )
}

export default ProgramacaoFilter
