import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { CentroCustoForm } from './CentroCustoForm'
import { useCentroCusto } from '../hooks/useCentroCusto'

export function CentroCustoMasterDetail() {
  const [centroCustoState, centroCustoActions] = useCentroCusto()
  const [, appBarActions] = useAppBar()
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      centroCustoState,
      u => u.entity.id,
      u => u.entity.descricao
    )
  ])
  useFetchAndReset(centroCustoActions.fetch, centroCustoActions.reset)
  return handleStateErrorsToRender(centroCustoState, <CentroCustoForm />)
}
