import { useQueryParams } from '@elentari/core'
import { Button, CircularProgress } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { useDefaultGoBack } from 'src/hooks/useDefaultGoBack'
import { EntitySaver } from 'src/services/entitySaver'
import {
  CreatedAnexo,
  UPLOAD_ERROR_MESSAGE,
  uploadAnexos
} from 'src/utils/anexo'
import yupContextValidation from 'src/utils/yupContextValidation'
import * as yup from 'yup'
import { Paper } from '../../../components'
import DropzoneInput from '../../../components/DropzoneInput'
import FieldsPending from '../../../components/FieldsPending'
import FilesList from '../../../components/FilesList'
import SimpleDialog from '../../../components/SimpleDialog'
import snackbarStore from '../../../stores/snackbar'
import scrollToError from '../../../utils/scrollToError'
import { useCotacao } from '../../cotacoes/hooks/useCotacao'
import { lotesRepository } from '../hooks/lotesRepository'
import { useLote } from '../hooks/useLote'
import { IcmsListener } from '../listeners/IcmsListener'
import { lotesStore } from '../stores/lotes'
import {
  LocationData,
  LotesFormData,
  StatusLote,
  StatusLoteEnum
} from '../types'
import { isTipoContrato } from '../utils'
import { DadosGerais } from './DadosGerais'
import { EnderecoLotesListener } from './EnderecoLotesListener'
import FreteEmpresaListener from './FreteEmpresaListener'
import { InformacoesAdicionais } from './InformacoesAdicionais'
import { InformacoesColetaEntrega } from './InformacoesColetaEntrega'
import { Integracoes } from './Integracoes'
import { NegociacaoComercial } from './NegociacaoComercial'
import { ProdutoQuantidade } from './ProdutoQuantidade'
import TipoContratacaoListener from './TipoContratacaoListener'
import TipoLoteListener from './TipoLoteListener'
import { TotalNegociacao } from './TotalNegociacao'
import {
  formatDataFromCotacao,
  formatDataFromExistingLote,
  formattedData,
  hasToChangeStatus
} from './formatFunctions'
import { initialValues, objectSchema } from './lotes.schema'

export const LotesForm = observer(() => {
  const location = useLocation()
  const query = useQueryParams<{
    status?: string
    dataReabertura?: string
    reabrir?: boolean
  }>()
  const state = location.state as LocationData
  const [loteState] = useLote()
  const [cotacaoState, cotacaoActions] = useCotacao()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCalculated, setIsCalculated] = useState(false)
  const [loading, setLoading] = useState(false)
  const canSave = useCanSave(loteState, 'Lotes')
  const clearDropzoneRef = useRef(false)
  const goBack = useDefaultGoBack()

  useEffect(() => {
    if (state) {
      cotacaoActions.fetch(state.id)
    }
  }, [cotacaoActions, state])

  useEffect(() => {
    return () => {
      cotacaoActions.reset()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (data: LotesFormData) => {
    try {
      setLoading(true)

      const anexos = await saveFiles(data.files)

      const [spot, contrato] = formattedData(data)

      if (
        loteState.tag === 'with-data' &&
        (await hasToChangeStatus(
          spot ? { ...spot, anexos } : { ...contrato, anexos }
        ))
      ) {
        setIsModalOpen(true)
        setLoading(false)
      } else {
        await saveForm({ ...data, anexos: anexos! })
      }
    } catch (err) {
      treatingError(err)
    }
  }

  const saveForm = async ({
    anexos: receveidAnexos,
    ...data
  }: LotesFormData & { anexos?: CreatedAnexo[] }) => {
    try {
      const { status, dataReabertura, reabrir } = query.initialValues
      if (status && dataReabertura && reabrir) {
        data.novaDataTermino = dataReabertura
        data.status = status as Exclude<StatusLote, ''>
        data.reabrir = Boolean(reabrir)
      }

      let anexos: CreatedAnexo[]

      if (receveidAnexos?.length) {
        anexos = receveidAnexos
      } else {
        anexos = (await saveFiles(data.files))!
      }

      const [spot, contrato] = formattedData(data)

      const dto = {
        ...(spot ?? contrato),
        anexos
      }

      await EntitySaver(() => lotesRepository.save(dto as any) as any, {
        onSuccess: () => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)

          goBack()
        },
        onError: () => {
          setTimeout(() => {
            setLoading(false)
          }, 1000)
        },
        feedbackError: 'Erro ao salvar o Lote.'
      })
    } catch (err) {
      treatingError(err)
    }
  }

  const saveFiles = async (files: File[]) => {
    const anexos = await uploadAnexos(files)
    if (!anexos) {
      setLoading(false)
      throw new Error(UPLOAD_ERROR_MESSAGE)
    }

    return anexos
  }

  const treatingError = async (err: unknown) => {
    setLoading(false)
    const error = err as { message: string }

    snackbarStore.setMessage(error?.message ?? 'Erro ao salvar o Lote.')
  }

  const handleGoBack = () => {
    if (
      loteState.tag === 'with-data' &&
      loteState.entity.status === StatusLoteEnum.VENCIDO
    ) {
      snackbarStore.setMessage('Reabertura do lote não realizada!')
    }
    goBack()
  }
  const schema = yup.object().shape(objectSchema)

  return (
    <Formik
      enableReinitialize
      validate={yupContextValidation(schema, {
        quantidadeRetirada:
          loteState.tag === 'with-data' && loteState.entity.quantidadeRetirada,
        icms: lotesStore.icms
      })}
      initialValues={
        state && cotacaoState.tag === 'with-data'
          ? formatDataFromCotacao(cotacaoState.entity)
          : loteState.tag === 'with-data'
          ? formatDataFromExistingLote(loteState.entity, {
              reabrir: query.initialValues.reabrir,
              dataReabertura: query.initialValues.dataReabertura
            })
          : initialValues
      }
      onSubmit={handleSubmit}
    >
      {({ values, isSubmitting, handleChange, errors, submitCount }) => (
        <Form>
          <FreteEmpresaListener />
          <EnderecoLotesListener />
          <TipoLoteListener />
          <IcmsListener />
          <TipoContratacaoListener />
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <DadosGerais
                  handleChange={handleChange}
                  loteState={loteState}
                />
              </Grid>
              {!isTipoContrato(values.tipoContratacao) && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <NegociacaoComercial />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InformacoesColetaEntrega />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              {!isTipoContrato(values.tipoContratacao) && (
                <>
                  <Grid item xs={12} sm={12}>
                    <TotalNegociacao setIsCalculated={setIsCalculated} />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12}>
                <ProdutoQuantidade />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              <Grid item xs={12} sm={12}>
                <InformacoesAdicionais />
              </Grid>
              <Grid item xs={12} sm={12}>
                <Divider />
              </Grid>
              {!isTipoContrato(values.tipoContratacao) && <Integracoes />}

              {loteState.tag !== 'with-data' &&
                cotacaoState.tag === 'with-data' && (
                  <>
                    <Grid item xs={12} sm={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <FilesList
                        title="Arquivos adicionados na Cotação"
                        anexos={cotacaoState.entity.Anexos}
                      />
                    </Grid>
                  </>
                )}

              <Grid item xs={12} sm={12}>
                <DropzoneInput
                  key={Number(clearDropzoneRef.current)}
                  name="files"
                  dropzoneTitle="Evidências da Negociação (Anexos)"
                />
              </Grid>

              {loteState.tag === 'with-data' && (
                <>
                  <Grid item xs={12} sm={12}>
                    <Divider />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <FilesList anexos={loteState.entity.Anexos} />
                  </Grid>
                </>
              )}
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12} sm={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <ConditionalTooltip
                        enabled={
                          !isCalculated &&
                          loteState.tag !== 'with-data' &&
                          !isTipoContrato(values.tipoContratacao)
                        }
                        title="É necessário calcular o valor total da negociação antes de salvar"
                        aria-label="É necessário calcular o valor total da negociação antes de salvar"
                      >
                        <Button
                          data-testid="salvar"
                          fullWidth
                          type="submit"
                          variant="contained"
                          color="primary"
                          onClick={() => scrollToError(errors)}
                          disabled={
                            isSubmitting ||
                            loading ||
                            !canSave ||
                            (!isCalculated &&
                              loteState.tag !== 'with-data' &&
                              !isTipoContrato(values.tipoContratacao))
                          }
                        >
                          {isSubmitting || loading ? (
                            <CircularProgress color="inherit" size={24} />
                          ) : (
                            'Salvar'
                          )}
                        </Button>
                      </ConditionalTooltip>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
          <SimpleDialog
            message='O Status será alterado para "REABERTO"'
            open={isModalOpen}
            buttonLabel="VOLTAR"
            handleClose={() => setIsModalOpen(false)}
            primaryAction={() => {
              setLoading(true)
              saveForm(values)
            }}
            title="Você está alterando dados sensíveis, deseja continuar?"
            primaryActionButtonLabel="CONFIRMAR"
          />
        </Form>
      )}
    </Formik>
  )
})
