import { EdgesPage } from '@elentari/core/types'
import { useEffect, useState } from 'react'
import { Log } from 'src/modules/controle-logs/types'
import { useReadAbility } from 'src/modules/login'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'

export const useLogsLote = (id: string) => {
  const [logs, setLogs] = useState<Log[]>([])
  const [loading, setLoading] = useState(false)
  const canReadLogs = useReadAbility('Controle de Logs')

  async function getLogs(id: string) {
    setLoading(true)

    if (!canReadLogs) {
      snackbarStore.setMessage('Você não pode ter acesso aos logs.')
      setLoading(false)
      setLogs([])
      return
    }

    const response = await apiSauceInstance.get<
      EdgesPage<Log>,
      { message: string }
    >(`lotes/${id}/logs?limit=5`)

    if (response.ok) {
      setLogs(response.data!.edges.map(edge => edge.node))
    } else {
      setLogs([])
      snackbarStore.setMessage(
        response.data?.message ?? 'Não foi possível recuperar os logs do lote'
      )
    }

    setLoading(false)
  }

  function reload() {
    getLogs(id)
  }

  useEffect(() => {
    getLogs(id)
  }, [id])

  return {
    logs,
    loading,
    getLogs,
    reload,
    canReadLogs
  }
}
