import { MessagesYup } from 'src/modules/messages'
import * as yup from 'yup'

const schema = yup.object().shape({
  fileXlsx: yup
    .object()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .typeError(MessagesYup.MENSAGEM_CAMPO_XLXS)
})

export { schema }
