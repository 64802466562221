import { Grid } from '@material-ui/core'
import { CheckboxInput } from 'src/packages/g10-components/CheckboxInput'

export const DadosConfigCentroCusto = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CheckboxInput name="config.centroCusto.codigo" label="Código" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name="config.centroCusto.descricao" label="Descrição" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name="config.centroCusto.unidadeNegocioDescricao"
          label="Unidade de Negócio"
        />
      </Grid>
    </Grid>
  )
}
