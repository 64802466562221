import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import {
  DatePickerFilter,
  DefaultColumnFilter,
  NumericFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import {
  tiposNegociacao,
  TiposNegociacaoEnum
} from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import When from 'src/components/When'
import { DeleteRecord } from '../../components'
import { statusCotacaoOptions, TipoNegociacaoCotacao } from './types'

interface ICotacoesColumnProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const CotacoesColumns = ({
  onDelete,
  authorization
}: ICotacoesColumnProps): IColumn[] => {
  const getTipoNegociacaoLabel = (tipo: TipoNegociacaoCotacao) =>
    TiposNegociacaoEnum[tipo]

  return [
    {
      Header: 'Código',
      accessor: 'codigo',
      Filter: props =>
        DefaultColumnFilter({
          column: props.column as HeaderGroup<{}>,
          onlyNumber: true
        })
    },
    {
      Header: 'Cliente',
      accessor: 'cliente'
    },

    {
      Header: 'Valor Frete',
      accessor: 'freteCotacao',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Centro Custo',
      accessor: 'centroCusto'
    },
    {
      Header: 'Data Cotação',
      accessor: 'dataCotacao',
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Origem',
      accessor: 'origem'
    },
    {
      Header: 'Destino',
      accessor: 'destino',
      show: false
    },
    {
      Header: 'Produto',
      accessor: 'produto'
    },
    {
      Header: 'Tipo Negociação',
      accessor: 'tipoNegociacao',
      Cell: ({ value }: any) => <>{getTipoNegociacaoLabel(value)}</>,
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: tiposNegociacao
        })
    },
    {
      Header: 'Status Cotação',
      accessor: 'status',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,

          options: statusCotacaoOptions.map(status => ({
            value: status.id,
            label: status.name
          }))
        })
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <DeleteRecord
                  onDelete={() => onDelete(props.row.original.id)}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
