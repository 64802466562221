import { stringify } from 'qs'
import { apiSauceInstance } from 'src/services/api'
import { formatDate } from 'src/utils/formatters'
import { formatDestinoLote } from 'src/utils/formatters/formatDestinoLote'
import { XLSXData } from '../types'

interface Props {
  limit?: number
  grupoNegociadorId?: string
  centroCustoId?: string
  operacaoKMM?: string
  codigo?: string
  produtoId?: string
  origemId?: string
  destinoId?: string
  numeroPedido?: string
  dataInicio?: string
  dataTermino?: string
  status?: string
  createdAt?: string
}

export const useLotesXLSX = (props: Props) => {
  const query = stringify({ ...props, limit: props.limit || 10 })

  function getXLSXData() {
    return apiSauceInstance.get<XLSXData[], { message: string }>(
      `lotes/xlsx-data?${query}`
    )
  }

  function format(data: XLSXData[]) {
    return data.map(lote => ({
      CentroCusto: lote.centroCusto,
      UnidadeNegocio: lote.unidadeNegocio,
      OperacaoKMM: lote.operacaoKMM,
      Status: lote.status,
      Cliente: lote.cliente,
      Origem: lote.Origem.cidade + '/' + lote.Origem.siglaEstado,
      Destino: formatDestinoLote(lote.Destino),
      DataInicialRetirada: formatDate(lote.dataInicio),
      DataLimiteRetirada: formatDate(lote.dataTermino),
      LocalCarregamento: lote.localCarregamento || 'N/A',
      Produto: lote.Produto?.nome || 'N/A',
      TipoLote: lote.tipoLote,
      Quantidade: lote.quantidadeTotal,
      TipoNegociacaoEmpresa: lote.tipoNegociacaoEmpresa,
      FreteEmpresa: lote.freteEmpresa,
      TipoNegociacaoSubcontratado: lote.tipoNegociacaoSubcontratado,
      FreteAgregado: lote.freteAgregado,
      FreteGrupo: lote.freteGrupo,
      FreteSubcontratadoPJ: lote.freteSubcontratadoPJ,
      FreteSubcontratadoPF: lote.freteSubcontratadoPF,
      NumeroPedido: lote.numeroPedido,
      Codigo: lote.codigo
    }))
  }

  return {
    getXLSXData,
    format
  }
}
