import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid, Tooltip } from '@material-ui/core'
import { Event } from '@material-ui/icons'
import * as dateFns from 'date-fns'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import { SubmitValues } from '../../hooks/types'
import { status } from './types/types'
import * as yup from 'yup'
import { MessagesYup } from '../messages'
import yupValidation from '../../utils/yupValidation'
import contratosStore from './store/store'
import { handleKeyCodes, handlePasteIntegerOnly } from '../options'

type ContratosQueryFilter = {
  [key: string]: any
}

const schema = yup.object().shape({
  dataInicio: yup.date().nullable().typeError(MessagesYup.INVALID_DATE),
  dataTermino: yup.date().nullable().typeError(MessagesYup.INVALID_DATE)
})

export const ContratosFilter = () => {
  const { clear, push, initialValues } = useQueryParams<ContratosQueryFilter>()

  const handleSubmitFilter = (
    values: SubmitValues & {
      page: number
      [k: string]: any
    }
  ) => {
    contratosStore.contratos.resetList()
    if (values.dataInicio) {
      values.dataInicio = new Date(values.dataInicio).toISOString()
    }

    if (values.dataTermino) {
      values.dataTermino = new Date(values.dataTermino).toISOString()
    }

    push({
      ...values,
      page: 1,
      codigo: values.codigo,
      grupoNegociadorId: values.grupoNegociadorId?.value,
      agrupadorProdutosId: values.agrupadorProdutosId?.value,
      status: values.status || undefined,
      createdAt: undefined
    })
  }

  const format = (initialValues: ContratosQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }

    return initialValues
  }

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={clear}
      validate={yupValidation(schema)}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <RFFAsyncSearchInput
            name="grupoNegociadorId"
            label="Cliente"
            url="/grupos-negociadores"
            labelField="nome"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            name="codigo"
            label="Código"
            type="number"
            inputProps={{ min: 0 }}
            onKeyDown={handleKeyCodes}
            onPaste={e => handlePasteIntegerOnly(e, 0)}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Select fullWidth name="status" label="Status" items={status} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RFFAsyncSearchInput
            name="agrupadorProdutosId"
            label="Agrupador Produtos"
            url="/agrupadores-produtos"
            labelField="nome"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePicker
            fullWidth
            name="dataInicio"
            label="Data Inicial"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <DatePicker
            fullWidth
            name="dataTermino"
            label="Data Final"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
