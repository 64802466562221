import { Box } from '@material-ui/core'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { Fragment, JSXElementConstructor, useCallback } from 'react'
import { handleKeyCodes } from 'src/modules/options'

interface FooterProps {
  rowCount: Nullable<number>
}

const TabelaContratoFooter = ({ rowCount }: FooterProps) =>
  rowCount ? (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 1 }}>
      {rowCount} registro(s)
    </Box>
  ) : (
    <Fragment />
  )

const TabelaContratoNoRowsOverlay = () => <></>

type Props = {
  rows: any[]
  columns: GridColDef[]
  setCellValue: (rowId: string, columnId: string, columnValue: string) => void
  columnMenuComponent?: JSXElementConstructor<any>
  disableColumnMenu?: boolean
}

const TabelaContratoDataGrid = ({
  rows,
  columns,
  setCellValue,
  columnMenuComponent,
  disableColumnMenu = false
}: Props) => {
  const handleEditCell = useCallback(model => {
    const row = Object.entries(model)[0]

    if (row) {
      const rowId = row[0]
      const columns = row[1] as Record<string, { value: string }>
      const columnId = Object.keys(columns)[0]
      const columnValue = Object.values(columns)[0].value

      setCellValue(rowId, columnId, columnValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DataGrid
      rows={toJS(rows)}
      columns={toJS(columns)}
      disableSelectionOnClick
      autoHeight
      disableColumnMenu={disableColumnMenu}
      onCellKeyDown={(_, e) => handleKeyCodes(e)}
      onEditRowsModelChange={handleEditCell}
      components={{
        ColumnMenu: columnMenuComponent,
        Footer: TabelaContratoFooter,
        NoRowsOverlay: TabelaContratoNoRowsOverlay
      }}
      componentsProps={{
        footer: { rowCount: rows.length }
      }}
    />
  )
}

export default observer(TabelaContratoDataGrid)
