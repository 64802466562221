import { Ability, AbilityBuilder, AbilityClass } from '@casl/ability'
import userStore from 'src/stores/user'
import { User } from './types'

type Actions = 'manage' | 'create' | 'read' | 'update' | 'delete'

type Roles = 'Admin' | 'User' | string

export type TypeAppAbility = Ability<[Actions, string]>
export const AppAbility = Ability as AbilityClass<TypeAppAbility>

export const abilityFor = (user: User): Ability => {
  return new AppAbility(
    defineRulesFor(user.rolesInSystem ? user.rolesInSystem : []),
    {
      // @ts-ignore
      detectSubjectType: object => object!.type
    }
  )
}

function defineRulesFor(roles: Roles[]) {
  const { can, rules } = new AbilityBuilder(AppAbility)

  if (roles?.includes('ADMIN')) {
    can(['manage'], 'all')
  }

  return rules
}

export function useAbility() {
  return userStore.ability ?? undefined
}

export const useReadAbility = (subject: string) => {
  const ability = useAbility()
  return ability?.can('read', subject) ?? false
}
