import { useEffect, useState } from 'react'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'

export const useFilaValidacao = () => {
  const [count, setCount] = useState(0)

  const fetchCount = () => {
    apiSauceInstance
      .get<number>('lotes/validacao/count')
      .then(response => {
        setCount(Number(response.data!))
      })
      .catch(() => setCount(0))
  }

  useEffect(() => {
    fetchCount()

    const interval = setInterval(fetchCount, 60_000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const nextFromQueue = async () => {
    const response = await apiSauceInstance.get<
      { id: string },
      { message: string }
    >('lotes/validacao/next')

    if (response.ok) {
      return response.data!
    }

    snackbarStore.setMessage(response.data?.message ?? 'Não há lotes na fila')
  }

  return {
    count,
    nextFromQueue
  }
}
