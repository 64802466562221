import { ModalidadeTransportador } from '../types'
import { LoteProgramacaoFormData } from '../types/formData'

export const calculateValorFreteGrupo = (
  aliquotaIcms: number,
  freteEmpresa: number
) => {
  return (1 - (0.03 + aliquotaIcms / 100)) * freteEmpresa
}

export const getValorFreteAcordadoGrupo = (lote: LoteProgramacaoFormData) => {
  if (lote.possuiTrocaNotaFiscal) {
    return lote.resumoValores.freteGrupo
  }

  if (lote.pernas[0].freteGrupoDiferente) {
    return lote.pernas[0].freteGrupo
  }

  if (!lote.pernas[0].freteGrupoDiferente) {
    const perna = lote.pernas[0]

    return calculateValorFreteGrupo(perna.aliquotaIcms, perna.freteEmpresa)
  }

  return 0
}

export const getValorFreteAcordadoAgregadoPjPf = (
  lote: LoteProgramacaoFormData,
  modalidadeTransportador: ModalidadeTransportador
) => {
  if (!lote.possuiTrocaNotaFiscal) {
    return lote.pernas[0].freteSubcontratado
  }

  if (modalidadeTransportador === ModalidadeTransportador.AGREGADO) {
    return lote.resumoValores.freteAgregado
  }

  if (modalidadeTransportador === ModalidadeTransportador.TERCEIRO_PF) {
    return lote.resumoValores.freteSubcontratadoPf
  }

  if (modalidadeTransportador === ModalidadeTransportador.TERCEIRO_PJ) {
    return lote.resumoValores.freteSubcontratadoPj
  }

  return 0
}
