import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { useProduto } from '../hooks/useProduto'
import { ProdutoForm } from './ProdutoForm'

export function ProdutoMasterDetail() {
  const [produtoState, produtoActions] = useProduto()
  const [, appBarActions] = useAppBar()
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      produtoState,
      u => u.entity.id,
      u => `#${u.entity.codigo} - ${u.entity.nome}`
    )
  ])

  useFetchAndReset(produtoActions.fetch, produtoActions.reset)
  return handleStateErrorsToRender(produtoState, <ProdutoForm />)
}
