import { Box, Grid, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'

import logoG10 from '../../images/g10-transportes.svg'
import { formatStatusProgramacao } from '../../utils/formatters/formatStatus'
import { validacaoCarregamento } from './hooks/validacaoCarregamento'

import { Programacao, ValuesProps } from './types'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    background: '#f1f1f1',
    padding: '0 10%'
  },
  containerLogo: {
    display: 'flex',
    justifyContent: 'center'
  },
  main: {
    background: theme.palette.white,
    width: '100%',
    borderRadius: '1rem',
    flexDirection: 'column',
    padding: 'clamp(1rem, 5vw, 3rem)',
    boxShadow: '0px 4px 20px rgb(0 0 0 / 10%)'
  },
  title: {
    color: theme.palette.primary.main,
    fontSize: 'clamp(18px, 2.5vw, 25px)',
    textAlign: 'center'
  },

  itemsContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 'clamp(.5rem, 1vw, 1.5rem)'
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'center',
    padding: 'clamp(.4rem, 1.5vw, 1rem)',
    textAlign: 'center'
  },
  label: {
    fontSize: 'clamp(15px, 2vw, 19px)',
    color: theme.palette.text.primary
  },
  value: {
    fontSize: 'clamp(13px, 2vw, 17px)',
    color: theme.palette.text.secondary
  }
}))

const AutorizacaoCarregamento = () => {
  const classes = useStyles()

  const theme = useTheme()

  const isMd = theme.breakpoints.down('md')

  const [values, setValues] = useState<ValuesProps>({
    codigo: 'Carregando...',
    localCarregamento: 'Carregando...',
    data: 'Carregando...',
    validade: 'Carregando...',
    motorista: 'Carregando...',
    veiculo: 'Carregando...',
    produto: 'Carregando...',
    status: 'Carregando...',
    isOverdue: false
  })

  async function getProgramacao() {
    const urlSplit = window.location.pathname.split('/')
    const id = urlSplit[urlSplit.length - 1]

    const programacao = (await (
      await validacaoCarregamento(id)
    ).data) as Programacao

    if (programacao.statusCode === 404) {
      setValues({
        codigo: 'Falha ao obter!',
        localCarregamento: 'Falha ao obter!',
        data: 'Falha ao obter!',
        validade: 'Falha ao obter!',
        motorista: 'Falha ao obter!',
        veiculo: 'Falha ao obter!',
        produto: 'Falha ao obter!',
        status: 'Falha ao obter!',
        isOverdue: values.isOverdue
      })
      return
    }

    if (!!programacao) {
      const loteLocalCarregamento = programacao.lote.localCarregamento || ''
      const loteOrigemNome = programacao.lote.origemNome
      const loteOrigemEstado = programacao.lote.origemEstado

      const localCarregamento = `${loteLocalCarregamento} ${loteOrigemNome}, ${loteOrigemEstado}`

      const data = `${new Date(
        programacao.dataHoraCarregamento
      ).toLocaleDateString('pt-br')} ${new Date(
        programacao.dataHoraCarregamento
      ).toLocaleTimeString('pt-br')}`

      const validade = `${new Date(programacao.validade).toLocaleDateString(
        'pt-br'
      )} ${new Date(programacao.validade).toLocaleTimeString('pt-br')}`

      const placaPrincipal = programacao.placas.placaPrincipal
      const placa1 = programacao.placas.placa_1
      const placa2 = programacao.placas.placa_2
      const placa3 = programacao.placas.placa_3

      const motorista = `CPF: ${programacao.cpfMotorista} | NOME: ${programacao.nomeMotorista}`
      const veiculo = `${placaPrincipal} ${placa1 ? ` -> ${placa1}` : ''} ${
        placa2 ? ` -> ${placa2}` : ''
      } ${placa3 ? ` -> ${placa3}` : ''}`
      const produto = programacao.lote.produto
      const status = programacao.status
      const isOverdue = new Date(programacao.validade) < new Date()

      setValues({
        codigo: programacao.codigo,
        localCarregamento,
        data,
        validade,
        motorista,
        veiculo,
        produto,
        status,
        isOverdue
      })
    }
  }

  useEffect(() => {
    getProgramacao()
  }, [])

  return (
    <Box height="100vh" width="100vw">
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.containerLogo}>
          <img
            src={logoG10}
            alt="G10 Logo"
            style={{ minWidth: '10rem', width: '25%', paddingBottom: '1rem' }}
          />
        </Grid>
        <Grid
          container
          item
          className={classes.main}
          style={{
            marginBottom:
              isMd && (values?.status === 'CANCELADO' || values?.isOverdue)
                ? 'clamp(15rem, 49%, 20rem)'
                : '15%'
          }}
        >
          <Grid item>
            <Typography className={classes.title}>
              Autorização de carregamento
            </Typography>
          </Grid>

          <Grid container item className={classes.itemsContainer}>
            <Grid item className={classes.itemContainer} md={5} xs={12}>
              <Typography className={classes.label}>Status</Typography>
              <Typography
                className={classes.value}
                style={
                  values?.status === 'CANCELADO' || values?.isOverdue
                    ? { color: '#983A54' }
                    : {}
                }
              >
                {values?.isOverdue
                  ? 'AUTORIZAÇÃO DE CARREGAMENTO VENCIDA'
                  : formatStatusProgramacao(values?.status)}
              </Typography>
            </Grid>
            <Grid item className={classes.itemContainer} md={5} xs={12}>
              <Typography className={classes.label}>Numeração</Typography>
              <Typography className={classes.value}>
                {values?.codigo}
              </Typography>
            </Grid>

            {!(values?.status === 'CANCELADO' || values?.isOverdue) && (
              <>
                <Grid item className={classes.itemContainer} md={5} xs={12}>
                  <Typography className={classes.label}>
                    Local carregamento
                  </Typography>
                  <Typography className={classes.value}>
                    {values?.localCarregamento}
                  </Typography>
                </Grid>
                <Grid item className={classes.itemContainer} md={5} xs={12}>
                  <Typography className={classes.label}>Data</Typography>
                  <Typography className={classes.value}>
                    {values?.data}
                  </Typography>
                </Grid>
                <Grid item className={classes.itemContainer} md={5} xs={12}>
                  <Typography className={classes.label}>Validade</Typography>
                  <Typography className={classes.value}>
                    {values?.validade}
                  </Typography>
                </Grid>
                <Grid item className={classes.itemContainer} md={5} xs={12}>
                  <Typography className={classes.label}>
                    Veiculo (todas as placas)
                  </Typography>
                  <Typography className={classes.value}>
                    {values?.veiculo}
                  </Typography>
                </Grid>
                <Grid item className={classes.itemContainer} md={5} xs={12}>
                  <Typography className={classes.label}>Produto</Typography>
                  <Typography className={classes.value}>
                    {values?.produto}
                  </Typography>
                </Grid>
                <Grid item className={classes.itemContainer} md={5} xs={12}>
                  <Typography className={classes.label}>Motorista</Typography>
                  <Typography className={classes.value}>
                    {values?.motorista}
                  </Typography>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default AutorizacaoCarregamento
