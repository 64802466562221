import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core'
import { useFormikContext } from 'formik'
import { Dispatch, SetStateAction, useState } from 'react'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import snackbarStore from 'src/stores/snackbar'
import { getTotalNegociacao } from '../hooks/lotesRepository'
import { LotesFormData } from '../types'
import {
  checkFrete,
  checkFreteGrupo,
  checkFretePF,
  checkFretePJ
} from './validateFunctions'

export const TotalNegociacao = ({
  setIsCalculated
}: {
  setIsCalculated: Dispatch<SetStateAction<boolean>>
}) => {
  const { values, isSubmitting, setValues } = useFormikContext<LotesFormData>()
  const [loading, setLoading] = useState(false)

  const handleCalcular = async () => {
    setLoading(true)
    const totalNegociacao = await getTotalNegociacao(values)
    setLoading(false)

    if (!totalNegociacao.ok) {
      snackbarStore.setMessage('Erro ao calcular o total da negociação')
      return
    }

    setValues({
      ...values,
      ...totalNegociacao.data
    })

    setIsCalculated(true)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={10}>
        <Typography variant="h5">Total da Negociação</Typography>
      </Grid>
      <Grid item xs={12} sm={2} style={{ width: 160 }}>
        <Button
          fullWidth
          type="button"
          variant="outlined"
          color="primary"
          onClick={handleCalcular}
          disabled={isSubmitting || loading}
        >
          {loading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Calcular'
          )}
        </Button>
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="freteEmpresa"
          label="Frete Empresa"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="freteAgregado"
          label="Frete Agregado"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          onBlur={checkFrete(values)}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="freteSubcontratadoPJ"
          label="Frete Subcontratado PJ"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          onBlur={checkFretePJ(values)}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="freteSubcontratadoPF"
          label="Frete Subcontratado PF"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          onBlur={checkFretePF(values)}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="margemGrupo"
          label="Margem Grupo (%)"
          inputProps={{
            startAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="freteGrupo"
          label="Frete Grupo"
          InputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          onBlur={checkFreteGrupo(values)}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="valorPedagio"
          label="Valor do Pedágio"
          inputProps={{
            startAdornment: <InputAdornment position="start">R$</InputAdornment>
          }}
          disabled
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <NumericInput
          name="aliquotaICMS"
          label="Alíquota ICMS (%)"
          inputProps={{
            startAdornment: <InputAdornment position="end">%</InputAdornment>
          }}
          disabled
        />
      </Grid>
    </Grid>
  )
}
