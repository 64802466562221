import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { ContratoColumns } from './ContratoColumns'
import contratosStore from './store/store'
import { ContratoDetail } from './types/types'

interface Props {
  onDelete: (id: string) => void
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const ContratosTable = observer(({ onDelete, authorization }: Props) => {
  const { pathname } = useLocation()

  const KEY_TABLE = resourceTabela.CONTRATOS
  useConfigTable(KEY_TABLE)
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !contratosStore.contratos.isLoading &&
    tableState &&
    contratosStore.contratos.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => ContratoColumns({ onDelete, authorization }),
    [onDelete, authorization]
  )

  const rows = contratosStore.contratos.list.map(
    ({ ...contratos }: ContratoDetail) => {
      return {
        ...contratos,
        agrupadorProdutos: contratos.AgrupadorProdutos.nome,
        cliente: contratos.GrupoNegociador.nome
      }
    }
  )

  const sortMapper = new Map([
    ['cliente', 'GrupoNegociador.nome'],
    ['agrupadorProdutos', 'AgrupadorProdutos.nome']
  ])

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={contratosStore.contratos.isLoading}
          totalCount={contratosStore.contratos.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow={false}
              linkWithRedirectClickRow={pathname}
              data={rows}
              sortMapper={sortMapper}
            />
          ) : (
            <NoResultsTable loading={contratosStore.contratos.isLoading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={pathname} authorization={authorization} />
    </>
  )
})
