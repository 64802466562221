import { IconButton, Tooltip } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print'
import { observer } from 'mobx-react-lite'
import { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import When from 'src/components/When'
import { LoteList } from 'src/modules/lotes/types'
import userStore from 'src/stores/user'
import { useAutorizacaoCarregamento } from '../../hooks/useAutorizacaoCarregamento'
import { useProgramacao } from '../../hooks/useProgramacao'
import { LoteProgramacaoFormData } from '../../types/formData'
import AutorizacaoCarregamentoPrint from './AutorizacaoCarregamentoPrint'

interface Props {
  lotes: LoteProgramacaoFormData[] | LoteList[]
}

export const AutorizacaoCarregamento = observer(({ lotes }: Props) => {
  const [programacaoState] = useProgramacao()
  const printRef = useRef(null)
  const {
    hasPrintAuthorization,
    configEstadias,
    configJudridico,
    configValidade,
    loadingConfigs,
    createAutorizacaoCarregamentoLog
  } = useAutorizacaoCarregamento()

  const handleCreateAutorizacaoCarregamentoLog = () => {
    createAutorizacaoCarregamentoLog(
      programacaoState,
      lotes,
      userStore.getUsername()
    )
  }

  return programacaoState.tag === 'with-data' ? (
    <When value={hasPrintAuthorization} equals>
      <ReactToPrint
        documentTitle={`Autorização de Carregamento - Programação #${programacaoState.entity.codigo}`}
        trigger={() => (
          <Tooltip
            placement="top"
            title="Imprimir Autorização de Carregamento"
            aria-label="Imprimir Autorização de Carregamento"
          >
            <IconButton aria-label="Imprimir" disabled={loadingConfigs}>
              <PrintIcon />
            </IconButton>
          </Tooltip>
        )}
        onAfterPrint={handleCreateAutorizacaoCarregamentoLog}
        content={() => printRef.current}
      />
      {hasPrintAuthorization && (
        <div hidden>
          <div ref={printRef}>
            <AutorizacaoCarregamentoPrint
              programacao={programacaoState.entity}
              configJudridico={configJudridico}
              configEstadias={configEstadias}
              configValidade={configValidade}
            />
          </div>
        </div>
      )}
    </When>
  ) : null
})
