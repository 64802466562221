import { makeAutoObservable } from 'mobx'
import ApiSauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { NegociacaoNaoFechadaDetail } from '../types'

class NegociacoesNfStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  negociacoesNf = new ApiSauceDataStore<NegociacaoNaoFechadaDetail>(
    apiSauceInstance,
    {
      useNodes: true,
      path: '/negociacoes-nao-fechadas',
      resultsField: 'edges',
      countField: 'totalCount'
    }
  )
}

const negociacoesNfStore = new NegociacoesNfStore()
export default negociacoesNfStore
