import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useFormikContext } from 'formik'
import TipoNegociacaoEmpresaSelectInput from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import TipoPedagioSelectInput from 'src/components/Formik/Forms/Inputs/TipoPedagioSelectInput'
import SwitchInput from '../../../components/SwitchInput'
import { isTipoContrato } from '../utils'

export const NegociacaoComercial = () => {
  const { values } = useFormikContext<{
    tipoContratacao: string
  }>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Negociação Comercial</Typography>
      </Grid>
      {!isTipoContrato(values.tipoContratacao) && (
        <Grid item xs={12} sm={6}>
          <TipoNegociacaoEmpresaSelectInput name="tipoNegociacaoEmpresa" />
        </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <TipoNegociacaoEmpresaSelectInput
          name="tipoNegociacaoSubcontratado"
          label="Tipo Negociação Subcontratado"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TipoPedagioSelectInput
          name="tipoPedagio"
          label="Tipo de Pedágio"
          clearable
        />
      </Grid>
      {!isTipoContrato(values.tipoContratacao) && (
        <>
          <Grid item xs={6} sm={3}>
            <SwitchInput name="pedagio" label="Frete + Pedágio" />
          </Grid>

          <Grid item xs={6} sm={3}>
            <SwitchInput name="icms" label="Frete + ICMS" />
          </Grid>
        </>
      )}
    </Grid>
  )
}
