import { Grid, Typography, withStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import React from 'react'
import Spacer from 'src/components/Spacer'
import { TableCellHead } from '../../../components/TableCellHead'
import logoG10 from '../../../images/g10-transportes.svg'
import { Page } from '../types'

interface HeaderProps {
  headerName: string
  dataEmissao: Date
}

interface BodyProps {
  relatorioData: Page
  tipoEmissao: string
  isPrint?: boolean
}

interface RelatorioPrintProps {
  relatorio: Page
  tipoEmissao: string
  dataEmissao: Date
  isPrint?: boolean
}

const useStyles = makeStyles(theme => ({
  center: {
    textAlign: 'center'
  },
  tableCellHeadListaLotes: {
    minWidth: '6rem'
  },
  tableCellHeadStatus: {
    width: '1rem',
    fontSize: 13
  }
}))

const BlackTextTypography = withStyles({
  root: {
    color: '#000000'
  }
})(Typography)

const RelatorioHeader = ({ headerName, dataEmissao }: HeaderProps) => {
  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{
        borderColor: '#632336',
        border: 'solid .5rem #632336',
        borderLeftWidth: '0rem',
        borderRightWidth: '0rem',
        fontWeight: 'bold',
        paddingTop: '1rem',
        paddingBottom: '1rem'
      }}
    >
      <Grid container item xs={6}>
        <Grid xs={12}>
          <BlackTextTypography
            variant="h6"
            align="center"
            style={{
              backgroundColor: grey[200],
              fontSize: 18
            }}
          >
            {headerName}
          </BlackTextTypography>
        </Grid>

        <Grid
          container
          xs={12}
          style={{
            marginTop: '.5rem',
            display: 'flex',
            justifyContent: 'space-between'
          }}
        >
          <BlackTextTypography
            variant="h6"
            align="center"
            style={{
              backgroundColor: grey[200],
              width: '49%',
              fontSize: 18
            }}
          >
            {new Date(dataEmissao).toLocaleDateString('pt-br')}
          </BlackTextTypography>
          <BlackTextTypography
            variant="h6"
            align="center"
            style={{
              backgroundColor: grey[200],
              width: '49%',
              fontSize: 18
            }}
          >
            {new Date(dataEmissao).toLocaleTimeString('pt-br')}
          </BlackTextTypography>
        </Grid>
      </Grid>

      <Grid item xs={6}>
        <img
          alt="logo G10"
          src={logoG10}
          style={{
            paddingLeft: '20%',
            width: '60%',
            height: 'auto'
          }}
        />
      </Grid>
    </Grid>
  )
}

const RelatorioBody = ({ relatorioData, tipoEmissao, isPrint }: BodyProps) => {
  const classes = useStyles()

  function getDestino(row: any) {
    if (!row.cidadeDestino) {
      return 'Não Informado'
    } else {
      if (row.cidadeDestino === 'Diversos') {
        return row.cidadeDestino
      } else {
        return `${row.cidadeDestino}/${row.ufEstadoDestino} ${
          row.localDestino ? `- ${row.localDestino}` : ''
        }`
      }
    }
  }

  return (
    <>
      <Spacer y={2} />
      <Grid
        container
        spacing={1}
        xs={12}
        style={{
          marginTop: '.5rem',
          fontWeight: 'bold'
        }}
      >
        <Grid item xs={12}>
          {!!relatorioData.list.length ? (
            <Table
              style={{ backgroundColor: grey[200], borderRadius: 5 }}
              size="small"
            >
              <TableHead>
                <TableRow>
                  <TableCellHead
                    label="Lista de lotes"
                    className={classes.tableCellHeadListaLotes}
                  />
                  <TableCell />
                  <TableCellHead
                    label="Trânsito"
                    className={[classes.center, classes.tableCellHeadStatus]}
                  />
                  <TableCellHead
                    label="Fila"
                    className={[classes.center, classes.tableCellHeadStatus]}
                  />
                  <TableCellHead
                    label="Carregada"
                    className={[classes.center, classes.tableCellHeadStatus]}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {relatorioData.list.map((row, key) => (
                  <TableRow key={key}>
                    <TableCell>{row.name}</TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: 12.5 }}>
                      {row.codigo} - {row.nomeProduto} <br />
                      {row.localCarregamento && `${row.localCarregamento} - `}
                      {row.cidadeOrigem}/{row.ufEstadoOrigem} x{' '}
                      {getDestino(row)}
                    </TableCell>

                    <TableCell style={{ textAlign: 'center', fontSize: 12.5 }}>
                      {row.status.transito}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: 12.5 }}>
                      {row.status.fila}
                    </TableCell>
                    <TableCell style={{ textAlign: 'center', fontSize: 12.5 }}>
                      {row.status.carregado}
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell />
                  <TableCell style={{ textAlign: 'end', fontSize: 13 }}>
                    Total:{' '}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 12.5
                    }}
                  >
                    {relatorioData.total.transito}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 12.5
                    }}
                  >
                    {relatorioData.total.fila}
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 12.5
                    }}
                  >
                    {relatorioData.total.carregado}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          ) : (
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: grey[200],
                borderRadius: 5,
                height: '3rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography>
                Não há nenhum dado nesse{' '}
                {tipoEmissao === 'CLIENTE' ? 'Cliente' : 'Centro de custo'}
              </Typography>
            </Grid>
          )}
        </Grid>
        <Grid item xs={8} />
      </Grid>
    </>
  )
}

const RelatorioPrint = ({
  relatorio,
  tipoEmissao,
  dataEmissao,
  isPrint
}: RelatorioPrintProps) => {
  return (
    <div style={{ margin: 30 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <RelatorioHeader
            headerName={relatorio.headerName}
            dataEmissao={dataEmissao}
          />
        </Grid>
        <Grid item xs={12}>
          <RelatorioBody
            relatorioData={relatorio}
            tipoEmissao={tipoEmissao}
            isPrint={isPrint}
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default RelatorioPrint
