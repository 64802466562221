import { isAfter, isSameDay, startOfDay } from 'date-fns'
import { MessagesYup } from 'src/modules/messages'
import * as yup from 'yup'
import { StatusLoteEnum } from '../types'

const schemaLotesDialog = yup.object().shape({
  novaDataTermino: yup.date().when(['$oldStatus', 'newStatus'], {
    is: (oldStatus: string, newStatus: string) =>
      newStatus === StatusLoteEnum.REABERTO &&
      oldStatus === StatusLoteEnum.VENCIDO,
    then: yup
      .date()
      .test({
        message: 'Nova data de retirada não pode ser anterior à data atual.',
        test: dataTermino =>
          dataTermino
            ? isAfter(startOfDay(dataTermino), startOfDay(new Date())) ||
              isSameDay(dataTermino, new Date())
            : true
      })
      .required(MessagesYup.MENSAGEM_OBRIGATORIO)
      .typeError(MessagesYup.INVALID_DATE),
    otherwise: yup.date()
  })
})

export { schemaLotesDialog }
