import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Event, Info } from '@material-ui/icons'
import { useFormState } from 'react-final-form'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import When from 'src/components/When'
import useWidth from 'src/hooks/useWidth'
import { statusLote } from './types'

type LotesQueryFilter = {
  [key: string]: any
}

const boxSize = {
  xs: '100vw',
  sm: '100vw',
  md: '60vw',
  lg: '45vw',
  xl: '40vw'
}

interface Props {
  handleSubmit: (values: LotesQueryFilter) => void
  closeDrawer: () => void
  isValidation: boolean
}

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    height: theme.spacing(4),
    borderRadius: theme.spacing(4),
    fontWeight: 'bold'
  }
}))

export const LotesFilterAdvanced = ({
  closeDrawer,
  isValidation,
  handleSubmit
}: Props) => {
  const { values } = useFormState()
  const classes = useStyles()
  const width = useWidth()

  return (
    <Box maxWidth={boxSize[width]} padding="10px">
      <Grid container spacing={2}>
        <When value={!isValidation} equals>
          <Grid item xs={12}>
            <Select fullWidth name="status" label="Status" items={statusLote} />
          </Grid>
        </When>

        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="numeroPedido" label="N° Pedido" />
        </Grid>

        <Grid item xs={12} sm={5}>
          <TextField fullWidth name="operacaoKMM" label="Op. KMM" />
        </Grid>
        <Grid item xs={12} sm={1}>
          <ConditionalTooltip
            enabled
            title={
              <Typography variant="subtitle1">
                Para Pesquisar por mais de uma Operação KMM utilizar o operador
                Pipe '|' ou a Barra Vertical '/'
              </Typography>
            }
            arrow
            placement="top"
          >
            <Box display="flex" justifyContent="center">
              <IconButton disabled>
                <Info />
              </IconButton>
            </Box>
          </ConditionalTooltip>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="emailCriador" label="Criador" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="analistaResponsavel" label="Responsável" />
        </Grid>

        <Grid item xs={12} sm={6}>
          <RFFAsyncSearchInput
            name="origemId"
            label="Origem"
            url="/cidades"
            labelField="nome"
            joinStateAndCity
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <RFFAsyncSearchInput
            name="destinoId"
            label="Destino"
            url="/cidades"
            labelField="nome"
            joinStateAndCity
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            name="dataInicioDe"
            label="Data Inicial de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            name="dataInicioAte"
            label="Data Inicial até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            name="dataTerminoDe"
            label="Data Limite de"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            name="dataTerminoAte"
            label="Data Limite até"
            keyboardIcon={
              <Tooltip title="Selecionar data">
                <Event />
              </Tooltip>
            }
          />
        </Grid>

        <Grid item xs={12}>
          <Grid justifyContent="flex-end" container spacing={1}>
            <Grid item style={{ width: 160 }}>
              <Button
                className={classes.button}
                fullWidth
                type="button"
                variant="outlined"
                onClick={closeDrawer}
              >
                Fechar
              </Button>
            </Grid>
            <Grid item style={{ width: 160 }}>
              <Button
                className={classes.button}
                fullWidth
                type="submit"
                variant="outlined"
                color="primary"
                onClick={() => {
                  handleSubmit(values)
                  closeDrawer()
                }}
              >
                Buscar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
