import { Box, Grid } from '@material-ui/core'
import { ConsultaField } from 'src/components/ConsultaField'
import { translateKey, translateValue } from '../logTranslator'

export interface CreateViewProps {
  data: Record<string, string>
}

export default function DataView(props: CreateViewProps) {
  const { data } = props

  // TODO: arrumar exibição de objetos e arrays
  const fields = Object.entries(data).map(([key, value]) => {
    const translatedKey = translateKey(key)
    const translatedValue = translateValue(key, value)
    return (
      <Grid item xs={6} key={translatedKey}>
        <ConsultaField title={translatedKey} subtitle={translatedValue} />
      </Grid>
    )
  })

  return (
    <Box borderRadius={6} p={2} width="100%" overflow="auto">
      <Grid container spacing={2}>
        {fields}
      </Grid>
    </Box>
  )
}
