import { useAppBar } from '../../../hooks'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import {
  mapFragmentReplace,
  useReplaceFragments
} from '@elentari/core/hooks/useReplaceFragmets'
import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import { ConfiguracoesForm } from './ConfiguracoesForm'
import { useConfiguracao } from '../hooks/useConfiguracao'

export function ConfiguracoesMasterDetail() {
  const [configuracoesState, configuracoesActions] = useConfiguracao()
  const [, appBarActions] = useAppBar()
  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      configuracoesState,
      u => u.entity.id,
      u => u.entity.chave
    )
  ])
  useFetchAndReset(configuracoesActions.fetch, configuracoesActions.reset)
  return handleStateErrorsToRender(configuracoesState, <ConfiguracoesForm />)
}
