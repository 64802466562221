import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import DateInput from 'src/components/DateInput'
import AsyncSearchInput from 'src/components/Formik/Forms/AsyncSearchInput'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { MultipleAutocompleteInput } from 'src/components/MultipleAutocompleteInput'
import StatusBadge from 'src/modules/lotes/StatusBadge'
import {
  StatusLoteEnum,
  especiesOptions,
  statusLote,
  tiposContratacao
} from 'src/modules/lotes/types'
import { statusRenderTags } from 'src/modules/lotes/utils/statusRenderTags'
import { StatusCentroCustoEnum } from 'src/modules/centros-custo/types'

export const LoteFilters = () => {
  const { values, setFieldValue } = useFormikContext<{
    filters: {
      unidadeNegocioId: AsyncSearchInputProps
    }
  }>()

  const unidadeNegocioId = values.filters.unidadeNegocioId

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <TextInput name="filters.descricao" label="Descrição" />
      </Grid>

      <Grid item xs={12} sm={3}>
        <SelectInput
          name="filters.tipoContratacao"
          label="Tipo de Contratação"
          options={tiposContratacao}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.unidadeNegocioId"
          label="Unidade de Negócio"
          url="unidades-negocio/restrict"
          labelField="descricao"
          handleOnChange={() => {
            setFieldValue('filters.centroCustoId', '')
          }}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.centroCustoId"
          label="Centro de Custo"
          labelField="descricao"
          url={
            unidadeNegocioId?.value
              ? `/unidades-negocio/${unidadeNegocioId?.value}/centros-custo`
              : '/centros-custo/ativos'
          }
          filters={{
            statusCentroCusto: unidadeNegocioId?.value
              ? StatusCentroCustoEnum.ATIVO
              : undefined
          }}
        />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Início Retirada De"
          name="filters.dataInicioDe"
        />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Início Retirada Até"
          name="filters.dataInicioAte"
        />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Término Retirada De"
          name="filters.dataTerminoDe"
        />
      </Grid>

      <Grid item xs={12} md={3} sm={6}>
        <DateInput
          label="Data Término Retirada Até"
          name="filters.dataTerminoAte"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput label="Data de Criação De" name="filters.createdAtDe" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <DateInput label="Data de Criação Até" name="filters.createdAtAte" />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AsyncSearchInput
          name="filters.origemId"
          label="Cidade Origem"
          url="/cidades"
          labelField="nome"
          joinStateAndCity
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <AsyncSearchInput
          name="filters.destinoId"
          label="Cidade Destino"
          url="/cidades"
          labelField="nome"
          joinStateAndCity
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.agrupadorProdutosId"
          label="Agrupador de Produto"
          url="/agrupadores-produtos"
          labelField="nome"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.produtoId"
          label="Produto"
          url="/produtos"
          labelField="nome"
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <SelectInput
          name="filters.especie"
          label="Espécie"
          options={especiesOptions}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <AsyncSearchInput
          name="filters.grupoNegociadorId"
          label="Cliente Contratante"
          url="/grupos-negociadores"
          labelField="nome"
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <MultipleAutocompleteInput
          name="filters.status"
          label="Status"
          options={statusLote}
          limitTags={4}
          renderTags={statusRenderTags}
          renderOption={params => (
            <StatusBadge
              status={params.value as StatusLoteEnum}
              marginLeft="0%"
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextInput name="filters.emailCriador" label="Criador" />
      </Grid>

      <Grid item xs={12} sm={3}>
        <TextInput
          name="filters.analistaResponsavel"
          label="Analista Responsável"
        />
      </Grid>
    </Grid>
  )
}
