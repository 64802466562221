import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography
} from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import CurrencyField from 'src/components/Formik/Forms/Inputs/CurrencyField'
import contratoStore from '../store/store'

function EditarColunaDialog() {
  const [newValue, setNewValue] = useState<number>(0)
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    setNewValue(Number(contratoStore.capacidadeEmEdicao))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contratoStore.capacidadeEmEdicao])

  function updateColuna(newValue: number) {
    const newValueStr = newValue.toString()
    if (
      contratoStore.hasCapacidade(newValueStr) &&
      contratoStore.capacidadeEmEdicao !== newValueStr
    ) {
      setErrorMessage('Capacidade já existe na tabela.')
      return
    }

    if (contratoStore.capacidadeEmEdicao !== newValueStr) {
      contratoStore.updateCapacidade(newValueStr)
    }

    contratoStore.closeEditarColunaDialog()
  }

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={contratoStore.editarColunaDialogOpened}
      onClose={contratoStore.closeAdicionarColunaDialog}
    >
      <DialogTitle>Editar Capacidade</DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CurrencyField
              thousandChar="."
              decimalChar=","
              precision={0}
              fullWidth
              variant="standard"
              value={newValue}
              onChange={event => setNewValue(Number(event.target.value) ?? 0)}
              maxValue={999_999}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color="error">{errorMessage}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid justifyContent="flex-end" container spacing={2}>
              <Grid item style={{ width: 160 }}>
                <Button
                  fullWidth
                  type="button"
                  variant="outlined"
                  onClick={contratoStore.closeEditarColunaDialog}
                >
                  Voltar
                </Button>
              </Grid>

              <Grid item style={{ width: 160 }}>
                <Button
                  data-testid="salvar"
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  onClick={() => updateColuna(newValue)}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default observer(EditarColunaDialog)
