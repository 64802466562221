import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { MessagesYup } from 'src/modules/messages'
import api from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { tableStore } from 'src/stores/TableStore'
import yupValidation from 'src/utils/yupValidation'
import * as yup from 'yup'
import SimpleCheckbox from './Formik/Forms/ChekboxInput'
import TextInput from './Formik/Forms/TextInput'
import { ListagemConfiguracoes } from './ListagemConfiguracoes'

interface IProps {
  keyTable: string
  open: boolean
  setOpen: (open: boolean) => void
}

const initialValues = {
  descricaoTabela: '',
  isDefault: false
}

const schema = yup.object().shape({
  descricaoTabela: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

export function TableModalSave({ open, setOpen, keyTable }: IProps) {
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  let [storeInitialValues, setStoreInitialValues] = useState<{
    descricaoTabela: string
    isDefault: boolean
  }>({
    descricaoTabela: '',
    isDefault: false
  })
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    const config = tableStore.getState(keyTable)

    if (config) {
      setStoreInitialValues({
        descricaoTabela: config.descricaoTabela || '',
        isDefault: config.isDefault || false
      })
    } else {
      setStoreInitialValues(initialValues)
    }
  }, [keyTable, tableStore.state[keyTable].descricaoTabela])

  const handleSave = async (values: {
    descricaoTabela: string
    isDefault: boolean
  }) => {
    setIsLoading(true)
    const searchParams = new URLSearchParams(history.location.search)
    const limit = searchParams.get('limit') ?? '10'

    tableStore.setDefaultConfigDatabase(keyTable, true)
    const configTabela = JSON.stringify({
      ...tableStore.getState(keyTable),
      limit
    })
    const response = await api.saveConfigTabela({
      configTabela,
      key: keyTable,
      descricaoTabela: values.descricaoTabela.trim(),
      isDefault: values.isDefault
    })
    if (response.ok) {
      snackbarStore.setMessage('Configuração salva com sucesso!')
    } else {
      snackbarStore.setMessage(
        (response.data as any).message || 'Erro ao salvar configuração!'
      )
    }
    if (tableStore.getState(keyTable)) {
      tableStore.setState(keyTable, {
        ...tableStore.getState(keyTable),
        descricaoTabela: values.descricaoTabela,
        isDefault: values.isDefault
      })
    }
    setIsLoading(false)
    handleClose()
  }

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Configuração da Listagem</DialogTitle>
        <DialogContent>
          <br />
          <Formik
            enableReinitialize
            validate={yupValidation(schema)}
            initialValues={
              storeInitialValues ? storeInitialValues : initialValues
            }
            onSubmit={values => handleSave(values)}
          >
            {({ isSubmitting }) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextInput
                      disabled={isSubmitting}
                      name="descricaoTabela"
                      label="Nome para configuração atual da listagem"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleCheckbox
                      label="Salvar como configuração padrão"
                      name="isDefault"
                    />
                  </Grid>
                </Grid>
                <DialogActions>
                  <Button
                    disabled={isLoading}
                    variant="outlined"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                  <Button variant="contained" type="submit" color="primary">
                    {isLoading ? <CircularProgress size={24} /> : 'Salvar'}
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Configurações salvas</Typography>
            </Grid>
            <Grid item xs={12}>
              <ListagemConfiguracoes
                keyTable={keyTable}
                onSubmitAction={handleClose}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  )
}
