import {
  CrudRoute,
  CrudTabs,
  useTabsNavigation
} from '@elentari/components-eve/components/CrudTabs'
import { contratosTabs } from '../utils/tabs'
import { ContratoForm } from './ContratoForm'
import { TabelaForm } from './TabelaForm'
import { TabelaTable } from './TabelaTable'

export const ContratoTabs = () => {
  const hook = useTabsNavigation({
    mainPath: 'contratos',
    tabs: contratosTabs
  })

  return (
    <CrudTabs {...hook}>
      <CrudRoute render={() => <ContratoForm />} />
      <CrudRoute name="tabelas" render={() => <TabelaTable />} />
      <CrudRoute name="tabelas" isForm render={() => <TabelaForm />} />
    </CrudTabs>
  )
}
