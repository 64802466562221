import fp from 'lodash/fp'
import { AnyObjectSchema } from 'yup'

const unflatObject = fp.flow([
  fp.toPairs,
  fp.reduce((result, [field, value]: any) => fp.set(field, value, result), {})
])

const yupValidation = (schema: AnyObjectSchema) => async (data: any) => {
  try {
    const trimmedData = trim(data)
    await schema.validate(trimmedData, { abortEarly: false })
    return {}
  } catch (error: any) {
    const errors = error.inner.reduce(
      (formError: any, innerError: any) => ({
        ...formError,
        [innerError.path]: innerError.message
      }),
      {}
    )

    return unflatObject(errors)
  }
}

const trim = (data: any) => {
  const stringifiedData = JSON.stringify(data)
  return JSON.parse(stringifiedData, (_key, value) => {
    return typeof value === 'string' ? value.trim() : value
  })
}

export default yupValidation
