import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useAbility } from '../login'
import { negociacoesNaoFechadasRepository } from './hooks/negociacoesNaoFechadasRepository'
import { NegociacoesNaoFechadasFilter } from './NegociacoesNaoFechadasFilter'
import { NegociacoesNaoFechadasTable } from './NegociacoesNaoFechadasTable'
import negociacoesNfStore from './store/negociacoesNf'

export type NegociacoesNfQueryFilter = {
  [key: string]: any
}

const NegociacoesNaoFechadasList = () => {
  const ability = useAbility()
  const authorization = getAuthorizations(ability, 'Negociações não fechadas')
  const { initialValues } = useQueryParams<NegociacoesNfQueryFilter>()

  async function handleDelete(id: string) {
    const response = await negociacoesNaoFechadasRepository.delete(id)
    negociacoesNfStore.negociacoesNf.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    negociacoesNfStore.negociacoesNf.setPage(Number(initialValues.page) || 1)
    negociacoesNfStore.negociacoesNf.setFilters(initialValues)
  }, [initialValues])

  useEffect(() => {
    return () => {
      negociacoesNfStore.negociacoesNf.resetList()
    }
  }, [])

  return (
    <>
      <NegociacoesNaoFechadasFilter />
      <NegociacoesNaoFechadasTable
        onDelete={handleDelete}
        authorization={authorization}
      />
    </>
  )
}

export default NegociacoesNaoFechadasList
