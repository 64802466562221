import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useState } from 'react'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import { ConsultaField } from 'src/components/ConsultaField'
import { StatusColors } from 'src/components/Validation/StatusColors'
import When from 'src/components/When'
import snackbarStore from 'src/stores/snackbar'
import {
  enviarOfertaFreto,
  inativarOfertaFreto
} from '../hooks/lotesRepository'
import { useIsFretoHabilitado } from '../hooks/useIsFretoHabilitado'
import { useLote } from '../hooks/useLote'
import { IntegracaoButton } from './IntegracaoButton'

export function IntegracaoFreto() {
  const [loteState, actions] = useLote()
  const { isFretoHabilitado } = useIsFretoHabilitado()

  const [loading, setLoading] = useState<boolean>(false)

  const isTipoContrato =
    loteState.tag === 'with-data' &&
    loteState.entity.tipoContratacao === 'CONTRATO'

  const inativarOferta = async () => {
    if (loteState.tag === 'with-data') {
      setLoading(true)

      const response = await inativarOfertaFreto(loteState.entity.id)
      if (response && !response.ok) {
        snackbarStore.setMessage(
          response.data?.message || 'Não foi possível inativar oferta no Freto.'
        )
      }

      setLoading(false)
      actions.fetch(loteState.entity.id)
    }
  }

  const enviarOferta = async () => {
    if (loteState.tag === 'with-data') {
      setLoading(true)

      const response = await enviarOfertaFreto(loteState.entity.id)
      if (response && !response.ok) {
        snackbarStore.setMessage(
          response.data?.message || 'Não foi possível enviar oferta ao Freto.'
        )
      }

      setLoading(false)
      actions.fetch(loteState.entity.id)
    }
  }

  return loteState.tag === 'with-data' ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Freto
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <When
            value={
              loteState.entity.statusOfertaFreto === 'ERRO' ||
              loteState.entity.statusOfertaFreto === 'INATIVA' ||
              !loteState.entity.statusOfertaFreto
            }
            equals
          >
            <Grid item xs={12}>
              <ConditionalTooltip
                enabled={!isFretoHabilitado}
                title="A integração com o Freto está desabilitada"
              >
                <IntegracaoButton
                  type="button"
                  variant="outlined"
                  onClick={() => enviarOferta()}
                  style={{
                    backgroundColor: StatusColors.LIBERADA
                  }}
                  disabled={loading || isTipoContrato || !isFretoHabilitado}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    'DIVULGAR'
                  )}
                </IntegracaoButton>
              </ConditionalTooltip>
            </Grid>
          </When>

          <When value={!!loteState.entity.idOfertaFreto} equals>
            <Grid item xs={12}>
              <ConsultaField
                title="ID DA OFERTA"
                subtitle={`${loteState.entity.idOfertaFreto} ${
                  loteState.entity.statusOfertaFreto === 'ATIVA'
                    ? ''
                    : `(${loteState.entity.statusOfertaFreto})`
                }`}
              />
            </Grid>
            <Grid item xs={12}>
              <ConsultaField
                title="MENSAGEM DO FRETO"
                subtitle={loteState.entity.mensagemEnvioFreto}
              />
            </Grid>
          </When>

          <When value={loteState.entity.statusOfertaFreto === 'ATIVA'} equals>
            <Grid item xs={12}>
              <ConditionalTooltip
                enabled={!isFretoHabilitado}
                title="A integração com o Freto está desabilitada"
              >
                <IntegracaoButton
                  type="button"
                  variant="outlined"
                  onClick={() => inativarOferta()}
                  style={{
                    backgroundColor: StatusColors.PENDENTE,
                    marginTop: '3px'
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    'FINALIZAR'
                  )}
                </IntegracaoButton>
              </ConditionalTooltip>
            </Grid>
          </When>

          <When value={!!loteState.entity.idOfertaFreto} equals>
            <Grid item xs={12}>
              <ConsultaField
                title="ID DA OFERTA"
                subtitle={`${loteState.entity.idOfertaFreto} ${
                  loteState.entity.statusOfertaFreto === 'ATIVA'
                    ? ''
                    : `(${loteState.entity.statusOfertaFreto})`
                }`}
              />
            </Grid>
            <Grid item xs={12}>
              <ConsultaField
                title="MENSAGEM DO FRETO"
                subtitle={loteState.entity.mensagemEnvioFreto}
              />
            </Grid>
          </When>
        </Grid>
      </Grid>
    </Grid>
  ) : null
}
