import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { ProgramacaoFormData } from '../../types/formData'

interface Props {
  loteId: string
}

export const PossuiTrocaDeNotaFiscalListener = ({ loteId }: Props) => {
  const formik = useFormikContext<ProgramacaoFormData>()

  useEffect(() => {
    if (formik.values.lotes[loteId].possuiTrocaNotaFiscal === false) {
      const newPernas = formik.values.lotes[loteId].pernas.slice(0, 1)

      formik.setFieldValue(`lotes.${loteId}.pernas`, newPernas)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.lotes[loteId].possuiTrocaNotaFiscal, loteId])

  return null
}
