import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { ParsedQs } from 'qs'
import { apiSauceInstance } from '../../../services/api'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { GrupoNegociadorDetail, GrupoNegociadorDto } from '../types'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { limit, ...rest } = params

  return {
    ...paginationParams(rest),
    limit: limit || '10'
  }
}

export const grupoNegociadorRepository = makeRepository<
  EdgesPage<GrupoNegociadorDetail>,
  GrupoNegociadorDto,
  GrupoNegociadorDetail,
  string
>({ resource: 'grupos-negociadores', mapQueryParams }, apiSauceInstance)

export const forceSaveOrChange = (data: any, id?: string) => {
  if (!!id) {
    return apiSauceInstance.put(`grupos-negociadores/forceChange/${id}`, data)
  } else {
    return apiSauceInstance.post('grupos-negociadores/forceSave', data)
  }
}
