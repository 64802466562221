import { useFetchAndReset } from '@elentari/core/hooks/useFetchAndReset'
import {
  useReplaceFragments,
  mapFragmentReplace
} from '@elentari/core/hooks/useReplaceFragmets'
import { useAppBar } from '../../../hooks'
import { formatDate } from '../../../utils/formatters'
import { handleStateErrorsToRender } from '../../../utils/handleFetchStateError'
import { useNegociacaoNaoFechada } from '../hooks/useNegociacaoNaoFechada'
import { NegociacoesNaoFechadasForm } from './NegociacoesNaoFechadasForm'

export function NegociacaoNaoFechadaMasterDetail() {
  const [negociacaoNaoFechadaState, negociacaoPerdidaActions] =
    useNegociacaoNaoFechada()
  const [, appBarActions] = useAppBar()

  useReplaceFragments(appBarActions.replaceFragment, [
    mapFragmentReplace(
      negociacaoNaoFechadaState,
      l => l.entity.id,
      l => `${l.entity.produto} - ${formatDate(l.entity.dataNegociacao)}`
    )
  ])

  useFetchAndReset(
    negociacaoPerdidaActions.fetch,
    negociacaoPerdidaActions.reset
  )
  return handleStateErrorsToRender(
    negociacaoNaoFechadaState,
    <NegociacoesNaoFechadasForm />
  )
}
