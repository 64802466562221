import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { cartaoList, TipoPagamento } from '../../types'

interface FormType {
  tipoPagamento: TipoPagamento
}

export const TipoPagamentoListener = () => {
  const formik = useFormikContext<FormType>()

  useEffect(() => {
    if (!cartaoList.includes(formik.values.tipoPagamento)) {
      formik.setFieldValue('numeroCartao', '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.tipoPagamento])

  return null
}
