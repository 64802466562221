import { useSnackbar } from '@elentari/core'
import { EdgesPage } from '@elentari/core/types'
import axios from 'axios'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { apiSauceInstance } from '../../../services/api'
import { CidadesDetail } from '../../cidades/types'
import { EstadosDetail } from '../../estados/types'
import { EnderecosDetail } from '../types'

interface CepListenerProps {
  cep: string
  name: string
  index: any
  setCepLoading: (param: boolean) => void
}

interface AsyncInputOption {
  name: string
  value: string
}

export default function CEPListener({
  cep,
  name,
  index,
  setCepLoading
}: CepListenerProps) {
  const [, snackbarActions] = useSnackbar()
  const formik = useFormikContext<{ Enderecos: EnderecosDetail[] }>()
  const viaCEP = axios.create({
    baseURL: 'https://viacep.com.br/ws/'
  })
  const [hasBeenChanged, setHasBeenChanged] = useState(false)

  async function getEstado(
    uf: string | undefined
  ): Promise<AsyncInputOption | null> {
    const estadoResponse = await apiSauceInstance.get<EdgesPage<EstadosDetail>>(
      '/estados',
      {
        sigla: uf
      }
    )

    if (estadoResponse.ok && estadoResponse.data?.edges[0]?.node) {
      return {
        name: estadoResponse.data?.edges[0]?.node.nome,
        value: estadoResponse.data?.edges[0]?.node.id
      }
    } else {
      snackbarActions.setMessage(
        `O estado "${uf}" não foi encontrado no banco de dados.`
      )
      return null
    }
  }

  async function getCidade(
    localidade: string | undefined
  ): Promise<AsyncInputOption | null> {
    const cidadeResponse = await apiSauceInstance.get<EdgesPage<CidadesDetail>>(
      '/cidades',
      {
        nome: localidade
      }
    )

    if (cidadeResponse.ok && cidadeResponse.data?.edges[0]?.node) {
      return {
        name: cidadeResponse.data?.edges[0]?.node.nome,
        value: cidadeResponse.data?.edges[0]?.node.id
      }
    } else {
      snackbarActions.setMessage(
        `A cidade "${localidade}" não foi encontrada no banco de dados.`
      )
      return null
    }
  }

  async function fetchCEP(cep: string) {
    try {
      setCepLoading(true)
      const response = await viaCEP.get(`${cep}/json`)

      if (response.data.erro) {
        throw new Error()
      }

      const formikValues = formik.getFieldProps(`${name}.${index}`).value
      const estado = await getEstado(response.data.uf)
      const cidade = await getCidade(response.data.localidade)

      formik.setFieldValue(`${name}.${index}`, {
        ...formikValues,
        cep,
        logradouro: response.data.logradouro || formikValues.logradouro,
        bairro: response.data.bairro || formikValues.bairro,
        complemento: response.data.complemento || formikValues.complemento,
        estadoId: estado,
        cidadeId: cidade
      })

      setCepLoading(false)
    } catch (err) {
      setCepLoading(false)

      snackbarActions.setMessage('O CEP não foi encontrado')
    }
  }

  useEffect(() => {
    const initialCep = formik.initialValues.Enderecos?.[index]?.cep ?? ''

    if (cep !== initialCep || hasBeenChanged) {
      setHasBeenChanged(true)

      if (cep?.length === 9) {
        fetchCEP(cep)
      }
    }
  }, [cep]) // eslint-disable-line

  return null
}
