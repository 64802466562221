import Filter from '@elentari/components-eve/components/Filter'
import DatePicker from '@elentari/components-eve/final-form/DatePicker'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import yupValidation from 'src/utils/yupValidation'
import * as yup from 'yup'
import { LogsFilter, LogsQueryFilter } from './types'
import { useEffect } from 'react'

export default function ControleLogsFilter() {
  const { clear, push, initialValues } = useQueryParams<LogsQueryFilter>()
  const schema = yup.object().shape({})

  function handleSubmitFilter(
    values: LogsFilter & {
      page: number
      [k: string]: any
    }
  ) {
    push({
      ...values,
      modelo: values.modelo?.value,
      page: String(values.page),
      dataInicial:
        values.dataInicial && values.dataInicial instanceof Date
          ? values.dataInicial.toUTCString()
          : values.dataInicial,
      dataFinal:
        values.dataFinal && values.dataFinal instanceof Date
          ? values.dataFinal.toUTCString()
          : values.dataFinal
    })
  }

  function format(initialValues: LogsQueryFilter) {
    return initialValues
  }

  useEffect(() => {
    const modeloId = initialValues?.modeloId

    if (modeloId) {
      handleSubmitFilter({ modeloId, page: 1 })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={3}>
        <Grid item xs={6} sm={3}>
          <DatePicker fullWidth name="dataInicial" label="Data Inicial" />
        </Grid>
        <Grid item xs={6} sm={3}>
          <DatePicker fullWidth name="dataFinal" label="Data Final" />
        </Grid>
        <Grid item xs={6} sm={3}>
          <RFFAsyncSearchInput
            name="modelo"
            label="Modelo"
            url="/logs-auditoria/modelos"
            labelField="nome"
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TextField fullWidth name="codigo" label="Código" type="number" />
        </Grid>
        <Grid item xs={12}>
          <TextField name="modeloId" label="ID do Modelo" fullWidth />
        </Grid>
      </Grid>
    </Filter>
  )
}
