import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { AgrupadorProdutosDetail } from '../types'

class AgrupadoresProdutosStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  agrupadoresProdutos = new ApisauceDataStore<AgrupadorProdutosDetail>(
    apiSauceInstance,
    {
      useNodes: true,
      path: '/agrupadores-produtos',
      resultsField: 'edges',
      countField: 'totalCount'
    }
  )
}
export const agrupadoresProdutosStore = new AgrupadoresProdutosStore()
export default AgrupadoresProdutosStore
