import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { format } from 'date-fns'
import { getLogInfo } from 'src/modules/controle-logs/LogsList'
import {
  Log,
  ProcessoEnum,
  tiposProcessos
} from 'src/modules/controle-logs/types'
import { UpdateView } from './UpdateView'
import { LogCustomHeader } from 'src/modules/controle-logs/views/CustomHeaderLog'

interface Props {
  log: Log
}

const ISLOGARRAY = /[a-z|A-Z]+.[0-9]+./

export function ItemLogList({ log }: Props) {
  switch (log.processo) {
    case ProcessoEnum.CADASTRO:
      if (!log.dados) {
        return null
      }

      return (
        <Typography style={{ fontWeight: 'bold' }}>Criação do lote</Typography>
      )
    case ProcessoEnum.ATUALIZACAO:
      if (!log.diferencas) {
        return null
      }

      const diferencas = log.diferencas.filter(
        diferenca =>
          !ISLOGARRAY.test(diferenca.campo) ||
          diferenca.campo.toLowerCase().includes('perna')
      )

      return (
        <Grid container spacing={2}>
          {diferencas.map((diferenca, index, diferencas) => (
            <Grid item xs={12} key={diferenca.campo}>
              <UpdateView
                field={diferenca.campo}
                oldValue={diferenca.dadoAntigo}
                newValue={diferenca.dadoNovo}
                isLast={index === diferencas.length - 1}
              />
            </Grid>
          ))}
        </Grid>
      )
    case ProcessoEnum.OUTRO:
      return (
        <Box display="flex" flexDirection="column">
          {getLogInfo(log.mensagem)}
        </Box>
      )
    default:
      return null
  }
}

export function LogContainer({ log }: Props) {
  const date = format(new Date(log.createdAt), 'dd/MM/yyyy')
  const time = format(new Date(log.createdAt), 'HH:mm')

  return (
    <Accordion key={log.id} style={{ backgroundColor: grey[200] }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h5" color="secondary">
              {tiposProcessos[log.processo]}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ wordBreak: 'break-all' }}>
              {log.usuario}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>
              {date} - {time}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <LogCustomHeader customHeaders={log.customHeaders} />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid item xs={12}>
          <ItemLogList log={log} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}
