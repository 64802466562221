import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import {
  DatePickerFilter,
  SelectFilterGeneric
} from 'src/components/ColumnsFiltersTable'
import { DownloadFile } from './components/DownloadFile'
import {
  statusRelatorioInternoOptions,
  tipoRelatorioInternoOptions
} from './types'
import { format, parseISO } from 'date-fns'

export const RelatorioInternoColumns = (): IColumn[] => {
  return [
    { Header: 'Id', accessor: 'id', show: false },
    {
      Header: 'Descrição',
      accessor: 'description'
    },
    {
      Header: 'Tipo',
      accessor: 'type',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: tipoRelatorioInternoOptions,
          uppercase: false
        })
    },
    {
      Header: 'Status',
      accessor: 'status',
      Filter: props =>
        SelectFilterGeneric({
          column: props.column as HeaderGroup<{}>,
          options: statusRelatorioInternoOptions,
          uppercase: false
        })
    },
    {
      Header: 'Data de Criação',
      accessor: 'createdAt',
      Cell: props => {
        return <>{format(parseISO(props.value), 'dd/MM/yyyy HH:mm:ss')}</>
      },
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Data de Finalização',
      accessor: 'finishedAt',
      show: false,
      Cell: props => {
        return (
          props.value && (
            <>{format(parseISO(props.value), 'dd/MM/yyyy HH:mm:ss')}</>
          )
        )
      },
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    { Header: 'Criador', accessor: 'owner', show: false },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      search: false,
      group: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => {
        return (
          props?.row?.original && (
            <DownloadFile
              file={props.row.original.File}
              status={props.row.original.status}
            />
          )
        )
      }
    }
  ]
}
