import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { estadosRepository } from 'src/modules/estados/hooks/estadosRepository'
import snackbarStore from 'src/stores/snackbar'
import { getAliquotaICMS } from '../hooks/lotesRepository'
import { LotesFormData } from '../types'

interface Props {
  index: number
}

export const EstadoOrigemDestinoListener = ({ index }: Props) => {
  const formik = useFormikContext<LotesFormData>()

  const estadoOrigemPerna =
    index > 0
      ? formik.values.pernas[index - 1].estadoDestinoPerna
      : formik.values.pernas[index].estadoOrigemPerna

  const estadoDestinoPerna = formik.values.pernas[index].estadoDestinoPerna

  const getSigla = async (id: string) => {
    const estado = await estadosRepository.fetchOne(id)

    if (estado.ok) {
      return estado.data?.sigla
    }

    snackbarStore.setMessage(
      'Ocorreu um erro ao retornar sigla do estado informado.'
    )
  }

  const setAliquotaICMS = async () => {
    const estadoOrigemSigla = await getSigla(estadoOrigemPerna.value)
    const estadoDestinoSigla = await getSigla(estadoDestinoPerna.value)

    if (estadoOrigemSigla && estadoDestinoSigla) {
      const response = await getAliquotaICMS(
        estadoOrigemSigla,
        estadoDestinoSigla
      )

      if (response.ok) {
        formik.setFieldValue(
          `pernas[${index}].aliquotaICMS`,
          Number(response.data?.aliquota)
        )
      } else {
        snackbarStore.setMessage(
          'Ocorreu um erro ao retornar valor da alíquota do ICMS para o estado de origem e destino informados.'
        )
      }
    }
  }

  useEffect(() => {
    if (
      estadoOrigemPerna?.value &&
      estadoDestinoPerna?.value &&
      formik.values.tipoContratacao === 'SPOT'
    ) {
      setAliquotaICMS()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoOrigemPerna, estadoDestinoPerna])

  return null
}
