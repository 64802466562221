import tabelaIcmsStore from '../store'
import { TabelaIcms } from '../types'

export async function loadDataICMS() {
  await tabelaIcmsStore.tabelaIcms.populate()

  if (tabelaIcmsStore.tabelaIcms.list.length !== 0) {
    const rows: Set<TabelaIcms> = new Set()
    const column: Set<string> = new Set()

    tabelaIcmsStore.tabelaIcms.list.forEach(item => {
      rows.add(item)
      column.add(item.estadoDestino)
    })
    const finishedRows: TabelaIcms[] = Array.from(rows)
    const finishedColumn: string[] = Array.from(column)

    tabelaIcmsStore.addTabela(finishedRows, finishedColumn)
  }
}
