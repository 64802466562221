import SelectInput from '../SelectInput'

export type TipoNegociacao =
  | 'TONELADA'
  | 'QUILOMETRO_RODADO'
  | 'RS_POR_VIAGEM'
  | 'RS_POR_QTDADE_VOLUMES'
  | 'METRO_CUBICO'

export const tiposNegociacao = [
  { value: 'TONELADA', label: 'R$ por tonelada' },
  { value: 'QUILOMETRO_RODADO', label: 'R$ por quilômetro rodado' },
  { value: 'METRO_CUBICO', label: 'R$ por Metro Cúbico' },
  { value: 'RS_POR_VIAGEM', label: 'R$ por Viagem' },
  { value: 'RS_POR_QTDADE_VOLUMES', label: 'R$ por Quantidade de Volumes' }
]

export enum TiposNegociacaoEnum {
  TONELADA = 'R$ por tonelada',
  QUILOMETRO_RODADO = 'R$ por quilômetro rodado',
  METRO_CUBICO = 'R$ por Metro Cúbico',
  RS_POR_VIAGEM = 'R$ por Viagem',
  RS_POR_QTDADE_VOLUMES = 'R$ por Quantidade de Volumes',
  COTACAO_LIVRE = 'Cotação Livre',
  COTACAO_DINAMICA = 'Cotação Dinâmica'
}

interface Props {
  name: string
  label?: string
}

const TipoNegociacaoEmpresaSelectInput = ({ name, label }: Props) => {
  return (
    <SelectInput
      name={name}
      label={label || 'Tipo Negociação Empresa'}
      options={tiposNegociacao}
    />
  )
}

export default TipoNegociacaoEmpresaSelectInput
