import { Grid } from '@material-ui/core'
import { CheckboxInput } from 'src/packages/g10-components/CheckboxInput'

interface DadosCidadeProps {
  prefix: string
  localName: string
  localLabel: string
}

export const DadosCidade = ({
  prefix,
  localName,
  localLabel
}: DadosCidadeProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CheckboxInput name={localName} label={localLabel} />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`config.${prefix}.nome`} label="Cidade" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput name={`config.${prefix}.estadoNome`} label="Estado" />
      </Grid>
      <Grid item xs={3}>
        <CheckboxInput
          name={`config.${prefix}.estadoSigla`}
          label="Sigla do Estado"
        />
      </Grid>
    </Grid>
  )
}
