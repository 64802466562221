import { LoteProgramacaoFormData } from '../types/formData'

export function reduceSelectedLotes(
  lotesSelecionados: LoteProgramacaoFormData[]
): Record<string, LoteProgramacaoFormData> {
  return lotesSelecionados.reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: curr
    }),
    {}
  )
}
