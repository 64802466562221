import {
  Button,
  CircularProgress,
  Grid,
  Theme,
  Typography,
  makeStyles
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import { Fragment, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import DropzoneInput from 'src/components/DropzoneInput'
import FilesList from 'src/components/FilesList'
import TextInput from 'src/components/Formik/Forms/TextInput'
import Spacer from 'src/components/Spacer'
import { useComentariosLote } from 'src/modules/lotes/hooks/useComentariosLote'
import { MessagesYup } from 'src/modules/messages'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { UPLOAD_ERROR_MESSAGE, uploadAnexos } from 'src/utils/anexo'
import { formatDateTime } from 'src/utils/formatters/formatDate'
import yupValidation from 'src/utils/yupValidation'
import * as yup from 'yup'
import { useLogsLote } from '../../hooks/useLogsLote'
import { LogContainer } from './components'

const schema = yup.object().shape({
  comentario: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  Anexos: yup.array().nullable().typeError(MessagesYup.MENSAGEM_ERRO)
})

const styles = makeStyles((theme: Theme) => ({
  usuario: {
    marginRight: theme.spacing(),
    fontWeight: 'bold',
    wordBreak: 'break-all'
  },
  body: {
    width: '100%'
  },
  comentarioContainer: {
    height: '35vh',
    marginBottom: theme.spacing(1)
  },
  comentarioListContainer: {
    maxHeight: '35vh',
    overflow: 'auto'
  },
  comentario: {
    backgroundColor: theme.palette.grey['200'],
    margin: '8px 0',
    borderRadius: 5,
    padding: theme.spacing(2)
  },
  logsContainer: {
    height: '64vh',
    overflow: 'hidden scroll',
    marginBottom: '.5rem'
  }
}))

const initialValues = {
  comentario: '',
  Anexos: []
}

function AlteracoesComentarios() {
  const { id } = useParams<{ id: string }>()
  const [submitting, setSubmitting] = useState(false)
  const clearDropzoneRef = useRef(false)
  const classes = styles()

  const { comentarios, reload } = useComentariosLote(id)
  const { logs, canReadLogs, loading } = useLogsLote(id)

  const handleSubmit = async (data: any, { resetForm }: any) => {
    setSubmitting(true)

    const anexos = await uploadAnexos(data.Anexos ?? [])

    if (!anexos) {
      setSubmitting(false)
      return snackbarStore.setMessage(UPLOAD_ERROR_MESSAGE)
    }

    const response = await apiSauceInstance.post('/comentarios', {
      loteId: id,
      Anexos: anexos,
      comentario: data.comentario
    })

    if (response.ok) {
      snackbarStore.setMessage('Comentário feito com sucesso.')
      reload()
      resetForm(initialValues)
      clearDropzoneRef.current = true
    } else {
      snackbarStore.setMessage('Erro para comentar.')
    }
    setSubmitting(false)
    return (clearDropzoneRef.current = false)
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">Alterações Realizadas</Typography>
        <Spacer y={1} />
        {logs.length ? (
          <Grid container>
            <Grid item xs={12} className={classes.logsContainer}>
              <Grid container>
                <Grid item xs={12}>
                  {logs.map(log => (
                    <Grid item key={log.id} xs={12}>
                      <LogContainer log={log} />
                      <Spacer y={2} />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                fullWidth
                target="_blank"
                href={`../controle-logs?modeloId=${id}`}
              >
                <Typography>
                  <b>Ver Mais</b>
                </Typography>
              </Button>
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            {canReadLogs ? (
              <Fragment>
                {loading ? (
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress />
                  </Grid>
                ) : (
                  <Typography variant="subtitle1">
                    Esse lote não possui Logs, realize uma atualização para
                    criar o registro.
                  </Typography>
                )}
              </Fragment>
            ) : (
              <Typography variant="subtitle1">
                Você não pode visualizar os logs.
              </Typography>
            )}
          </Fragment>
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h4">Comentários</Typography>
        <Grid container>
          <Grid container className={classes.comentarioContainer}>
            {comentarios.length ? (
              <Grid container className={classes.comentarioListContainer}>
                <Grid item xs={12}>
                  {comentarios.map(comentario => (
                    <Grid
                      key={comentario.id}
                      item
                      className={classes.body}
                      xs={12}
                    >
                      <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        className={classes.comentario}
                      >
                        <Grid item sm={10} xs={6}>
                          <Typography
                            variant="body1"
                            className={classes.usuario}
                          >
                            {comentario.emailResponsavel}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} xs={6}>
                          <Typography
                            variant="body2"
                            display="inline"
                            gutterBottom
                            align="right"
                          >
                            {formatDateTime(comentario.createdAt)}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography
                            variant="body1"
                            gutterBottom
                            style={{ overflowWrap: 'break-word', marginTop: 8 }}
                          >
                            {comentario.comentario}
                          </Typography>
                        </Grid>
                        {!!comentario.Anexos.length && (
                          <FilesList
                            title="Anexos"
                            anexos={comentario.Anexos || []}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="subtitle1">
                  Nenhum comentário existente nesse chat!
                </Typography>
              </Grid>
            )}
          </Grid>
          <Spacer y={4} />
          <Grid item xs={12} md={12} xl={12}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validate={yupValidation(schema)}
              enableReinitialize
            >
              {({ isSubmitting, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <Grid item container spacing={2}>
                    <Grid item xs={12}>
                      <TextInput
                        name="comentario"
                        label="Adicionar Comentário"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DropzoneInput
                        key={Number(clearDropzoneRef.current)}
                        name="Anexos"
                        dropzoneTitle="Anexos para o comentário"
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    justifyContent="flex-end"
                    item
                    container
                    spacing={2}
                    style={{ marginTop: 8 }}
                  >
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="comentar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                      >
                        {isSubmitting || submitting ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Comentar'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export { AlteracoesComentarios }
