import { Box, CircularProgress, Grid, Typography } from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import { useState } from 'react'
import { apiSauceInstance } from 'src/services/api'
import snackbarStore from 'src/stores/snackbar'
import { Anexo } from 'src/utils/anexo'
import { formatDateTime } from 'src/utils/formatters/formatDate'

interface Props {
  anexos: Anexo[]
  title?: string
}

interface FileProps {
  anexo: Anexo
}

export const downloadFile = (
  anexo: Anexo,
  setLoading: (value: boolean) => void
) => {
  const url = `uploads/anexo/${anexo.id}`

  setLoading(true)

  apiSauceInstance
    .get<BlobPart>(url, {}, { responseType: 'blob' })
    .then(response => {
      setLoading(false)

      if (response.ok) {
        const url = URL.createObjectURL(
          new Blob([response.data!], { type: anexo.mimeType })
        )
        const fileLink = document.createElement('a')

        fileLink.href = url
        fileLink.download = anexo.originalName
        fileLink.click()
      } else {
        snackbarStore.setMessage('Erro ao baixar arquivo')
      }
    })
}

export const File = ({ anexo }: FileProps) => {
  const [loading, setLoading] = useState(false)

  return (
    <div
      style={{
        display: 'flex',
        cursor: 'pointer',
        width: '100%'
      }}
      onClick={() => downloadFile(anexo, setLoading)}
    >
      <Grid container spacing={2} style={{ marginTop: 3 }}>
        <Grid item xs={12} style={{ display: 'flex' }}>
          {loading ? <CircularProgress size={20} /> : <AttachFileIcon />}
          <Typography variant="body2" align="left" style={{ marginLeft: 3 }}>
            {anexo.originalName} - {formatDateTime(anexo.createdAt)}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

const FilesList = ({ title, anexos }: Props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">
          {title ?? 'Arquivos previamente adicionados'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {anexos.map(anexo => (
          <File anexo={anexo} key={anexo.id} />
        ))}
      </Grid>
    </Grid>
  )
}

export default FilesList
