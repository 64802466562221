export const validateTarifa = (value: string, validateDecimalPlaces = true) => {
  if (!value) {
    return true
  }

  const valorNumerico = parseFloat(value)

  const valueArr = value.split('')

  if (valueArr.filter(item => item === ',').length > 1) {
    return false
  }

  if (valueArr.filter(item => item === '.').length > 0) {
    return false
  }

  if (isNaN(valorNumerico) || valorNumerico < 0 || valorNumerico >= 100000000) {
    return false
  }

  const [digitosAntesDoPonto, casasDecimais] = value.split(',')

  if (digitosAntesDoPonto?.length > 8) {
    return false
  }

  if (validateDecimalPlaces && casasDecimais?.length > 8) {
    return false
  }

  return true
}
