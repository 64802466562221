import { withStyles } from '@material-ui/core'
import MaskInput from 'src/components/Formik/Forms/MaskInput'

export const BlackDisabledMaskInput = withStyles({
  root: {
    '& label.MuiFormLabel-root.Mui-disabled': {
      opacity: 1,
      color: '#676767'
    },
    '& input.MuiInputBase-input.Mui-disabled': {
      opacity: 1,
      color: '#676767'
    }
  }
})(MaskInput)
