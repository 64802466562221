import { Tooltip } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { useField } from 'formik'

interface TimeInputProps {
  name: string
  label: string
  disabled?: any
}

function TimeInput(props: TimeInputProps) {
  const [field, meta, helpers] = useField(props)

  function handleChange(date: any) {
    helpers.setValue(date)
  }

  return (
    <KeyboardTimePicker
      {...props}
      {...field}
      ampm={false}
      value={field.value ? new Date(field.value) : null}
      onChange={handleChange}
      inputVariant="outlined"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      keyboardIcon={
        <Tooltip title="Selecionar hora">
          <AccessTime />
        </Tooltip>
      }
      fullWidth
      cancelLabel="Cancelar"
    />
  )
}

export default TimeInput
