import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableContainerMD from '@material-ui/core/TableContainer'
import { PaginationCustom } from './PaginationCustom'
import { ReactNode } from 'react'
import Pagination from './Pagination'
import Spacer from './Spacer'
import When from './When'

export const TableContainer = ({
  children,
  loading,
  totalCount,
  addendPagination = false,
  rowsPerPageOptions = [10, 25, 50, 100],
  disabledPagination = false,
  keyTable
}: {
  children: ReactNode
  totalCount: number
  loading: boolean
  addendPagination?: boolean
  rowsPerPageOptions?: number[]
  disabledPagination?: boolean
  keyTable?: string
}) => {
  return (
    <TableContainerMD>
      <When value={loading} equals>
        <LinearProgress />
      </When>
      {children}
      <Spacer y={2} />
      <Grid container justifyContent="center">
        {!disabledPagination && (
          <Grid item>
            {!disabledPagination && (
              <Pagination count={totalCount} keyTable={keyTable} />
            )}
          </Grid>
        )}
        <Grid item>
          {addendPagination && (
            <PaginationCustom
              count={totalCount}
              rowsPerPageOptions={rowsPerPageOptions}
              keyTable={keyTable}
            />
          )}
        </Grid>
      </Grid>
      <Spacer y={2} />
    </TableContainerMD>
  )
}
