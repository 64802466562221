import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { CentrosCustoColumns } from './CentrosCustoColumns'
import centrosCustoStore from './store'
import { CentroCustoDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const CentrosCustoTable = observer(
  ({ onDelete, authorization }: Props) => {
    const KEY_TABLE = resourceTabela.CENTROS_CUSTO
    useConfigTable(KEY_TABLE)
    const tableState = tableStore.getState(KEY_TABLE)
    const isTableReady =
      !centrosCustoStore.centrosCusto.isLoading &&
      tableState &&
      centrosCustoStore.centrosCusto.list.length

    const { pathname } = useLocation()

    const columns: Array<Column & IColumn> = useMemo(
      () => CentrosCustoColumns({ onDelete, authorization }),
      [onDelete, authorization]
    )

    const rows = centrosCustoStore.centrosCusto.list.map(
      ({ ...centros }: CentroCustoDetail) => {
        return {
          ...centros,
          unidadeNegocio: centros.UnidadeNegocio?.descricao
        }
      }
    )

    const sortMapper = new Map([['unidadeNegocio', 'UnidadeNegocio.descricao']])

    return (
      <>
        <Spacer y={4} />
        <Paper>
          <TableContainer
            loading={centrosCustoStore.centrosCusto.isLoading}
            totalCount={centrosCustoStore.centrosCusto.totalCount}
          >
            {isTableReady ? (
              <BaseTable
                keyTable={KEY_TABLE}
                columns={columns}
                hasConditionClickRow={false}
                linkWithRedirectClickRow={pathname}
                data={rows}
                sortMapper={sortMapper}
              />
            ) : (
              <NoResultsTable
                loading={centrosCustoStore.centrosCusto.isLoading}
              />
            )}
          </TableContainer>
        </Paper>

        <AddRecord path={pathname} authorization={authorization} />
      </>
    )
  }
)
