import { useFormikContext } from 'formik'
import useUpdateEffect from 'src/hooks/useUpdateEffect'

interface Props {
  name: string
}

export function EnderecoLotesListener() {
  const { values, setFieldValue } = useFormikContext<{
    estadoOrigemId: Props
    estadoDestinoId: Props
  }>()

  function handleClearInput({ name }: Props) {
    setFieldValue(name, null)
  }

  useUpdateEffect(() => {
    if (typeof values.estadoOrigemId === 'string') {
      return
    }
    handleClearInput({ name: 'origemId' })
  }, [values.estadoOrigemId])

  useUpdateEffect(() => {
    if (typeof values.estadoDestinoId === 'string') {
      return
    }
    handleClearInput({ name: 'destinoId' })
  }, [values.estadoDestinoId])

  return null
}
