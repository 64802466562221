import { isBefore } from 'date-fns'
import { MessagesYup } from 'src/modules/messages'
import { combineDateWithTime } from 'src/utils/formatters/formatDate'
import * as yup from 'yup'
import { validateCPF } from '../../../utils/validators/cpf'

const isDataDescarregamentoValid = (
  dataCarregamento?: Nullable<Date>,
  horaCarregamento?: Nullable<Date>,
  dataDescarregamento?: Nullable<Date>,
  horaDescarregamento?: Nullable<Date>
) => {
  if (
    dataCarregamento &&
    horaCarregamento &&
    dataDescarregamento &&
    horaDescarregamento
  ) {
    const dataHoraCarregamento = combineDateWithTime(
      dataCarregamento,
      horaCarregamento
    )
    const dataHoraDescarregamento = combineDateWithTime(
      dataDescarregamento,
      horaDescarregamento
    )

    return isBefore(dataHoraCarregamento, dataHoraDescarregamento)
  }

  return true
}

export const schema = yup.object().shape({
  centroCustoId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  embarcador: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().nullable(),
      value: yup.string().nullable()
    })
    .optional(),
  dataCarregamento: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataDescarregamento: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .test({
      name: 'min-data-descarregamento',
      test: (value, context) =>
        isDataDescarregamentoValid(
          context.parent.dataCarregamento,
          context.parent.horaCarregamento,
          value,
          context.parent.horaDescarregamento
        ),
      message: MessagesYup.MENSAGEM_DATA_FUTURA_NECESSARIA(
        'data de carregamento'
      )
    }),
  horaCarregamento: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  horaDescarregamento: yup
    .date()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)
    .test({
      name: 'min-hora-descarregamento',
      test: (value, context) =>
        isDataDescarregamentoValid(
          context.parent.dataCarregamento,
          context.parent.horaCarregamento,
          context.parent.dataDescarregamento,
          value
        ),
      message: MessagesYup.MENSAGEM_DATA_FUTURA_NECESSARIA(
        'data de carregamento'
      )
    }),
  placaPrincipal: yup
    .string()
    .trim()
    .min(7, MessagesYup.PLACA_MIN)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  placa_1: yup
    .string()
    .test('placa_1', MessagesYup.PLACA_MIN, (value: any) =>
      value ? value.length === 7 : true
    )
    .trim()
    .optional(),
  placa_2: yup
    .string()
    .test('placa_2', MessagesYup.PLACA_MIN, (value: any) =>
      value ? value.length === 7 : true
    )
    .trim()
    .optional(),
  placa_3: yup
    .string()
    .test('placa_3', MessagesYup.PLACA_MIN, (value: any) =>
      value ? value.length === 7 : true
    )
    .trim()
    .optional(),
  motorista: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  cpfMotorista: yup
    .string()
    .trim()
    .min(14, MessagesYup.CPF_MIN)
    .test('cpf-validation', MessagesYup.MESSAGEM_VALOR_CPF_INVALIDO, value =>
      validateCPF(value || '')
    )
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  capacidadeCargaVeiculo: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  numeroCartao: yup.string().trim().nullable()
})
