import { TiposPedagio } from 'src/components/Formik/Forms/Inputs/TipoPedagioSelectInput'
import { Anexo, CreatedAnexo } from 'src/utils/anexo'
import { CentroCusto } from '../centros-custo/types'
import { CidadesDetail } from '../cidades/types'
import { EstadosDetail } from '../estados/types'

export type TipoNegociacaoCotacao =
  | 'TONELADA'
  | 'QUILOMETRO_RODADO'
  | 'RS_POR_VIAGEM'
  | 'RS_POR_QTDADE_VOLUMES'
  | 'METRO_CUBICO'
  | 'COTACAO_LIVRE'
  | 'COTACAO_DINAMICA'

export const tiposNegociacaoCotacao = [
  { value: 'TONELADA', label: 'R$ por tonelada' },
  { value: 'QUILOMETRO_RODADO', label: 'R$ por quilômetro rodado' },
  { value: 'METRO_CUBICO', label: 'R$ por Metro Cúbico' },
  { value: 'RS_POR_VIAGEM', label: 'R$ por Viagem' },
  { value: 'RS_POR_QTDADE_VOLUMES', label: 'R$ por Quantidade de Volumes' },
  { value: 'COTACAO_LIVRE', label: 'Cotação Livre' },
  { value: 'COTACAO_DINAMICA', label: 'Cotação Dinâmica' }
]

export interface EmailsCotacao {
  id: string
  email: string
  cotacaoId: string
}

export interface EmailsCotacaoForm {
  email: string
}

export interface Cotacao {
  id: string
  centroCustoId: string
  cliente: string
  dataCotacao: Date
  estadoOrigemId?: string
  origemId?: string
  estadoDestinoId?: string
  destinoId?: string
  produtoId: string
  tipoNegociacao: TipoNegociacaoCotacao
  freteCotacao: number
  hasPedagio: boolean
  tipoPedagio?: TiposPedagio
  valorPedagio?: number
  icms: boolean
  quantidade?: number
  observacoes?: string
  status: StatusCotacao
  valorFreteFechado?: number
  EmailsCotacao: EmailsCotacao[]
  CentroCusto: CentroCusto
  dataEmbarqueInicio: Date
  dataEmbarqueTermino?: Date
  lancadoPor: string
}

export interface CotacaoDetail extends Cotacao {
  Origem: CidadesDetail & { Estado: EstadosDetail }
  Destino: CidadesDetail & { Estado: EstadosDetail }
  produto: string
  codigo: string
  Anexos: Anexo[]
}

export interface CotacaoDetailForm {
  id?: string
  centroCustoId: string
  cliente: string
  dataCotacao: Date
  origemId: string
  destinoId: string
  produto: string
  tipoNegociacao: TipoNegociacaoCotacao
  freteCotacao: number
  hasPedagio: boolean
  tipoPedagio?: TiposPedagio
  valorPedagio?: number
  icms: boolean
  quantidade?: number
  observacoes?: string
  valorFreteFechado?: number
  emails: EmailsCotacaoForm[]
  anexos: CreatedAnexo[]
  dataEmbarqueInicio: Date
  dataEmbarqueTermino?: Date
  lancadoPor: Nullable<AsyncSearchInputProps>
}

export interface AlterarStatusData {
  valorFreteFechado?: number
}

export interface CotacaoDetailFormData {}

export enum StatusCotacao {
  PENDENTE = 'PENDENTE',
  APROVADO = 'APROVADO',
  REPROVADO = 'REPROVADO',
  EXPIRADO = 'EXPIRADO'
}

export const statusCotacao = {
  PENDENTE: 'PENDENTE',
  APROVADO: 'APROVADO',
  REPROVADO: 'REPROVADO',
  EXPIRADO: 'EXPIRADO'
}

export const statusCotacaoOptions = Object.entries(statusCotacao).map(
  ([key, value]) => {
    return { id: key, name: value }
  }
)

export enum UpdateStatus {
  APROVAR = 'APROVAR',
  REPROVAR = 'REPROVAR'
}
