import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import {
  LoteProgramacaoFormData,
  ProgramacaoFormData
} from '../../types/formData'

interface Props {
  lote: LoteProgramacaoFormData
}

export const QuantidadePrevistaListener = ({ lote }: Props) => {
  const formik = useFormikContext<ProgramacaoFormData>()

  useEffect(() => {
    const destinos = lote.pernas.flatMap(perna => perna.destinos)
    const quantidadePrevista = destinos.reduce(
      (acc, currDestino) => acc + Number(currDestino.quantidade),
      0
    )

    formik.setFieldValue(
      `lotes.${lote.id}.quantidadePrevista`,
      quantidadePrevista
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lote])

  return null
}
