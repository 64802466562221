import { Redirect } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import { Box, Container, Grid, Typography } from '@material-ui/core'
import config from '../../config'
import userStore from 'src/stores/user'
import { observer } from 'mobx-react-lite'
import { LoadingPage } from 'src/components/LoadingPage'
import { useEffect, useState } from 'react'

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  divider: {
    height: 60,
    marginInline: 16,
    background: 'rgb(255 255 255 / 15%)'
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: theme.palette.getContrastText('#263238')
  },
  login: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  button: {
    width: 200
  },
  presentation: {
    marginLeft: theme.spacing(2)
  },
  page: {
    backgroundImage: 'url(/blob.svg)',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  }
}))

const Login = () => {
  const [loading, setLoading] = useState(true)
  const classes = useStyles()

  async function handleLogin() {
    const ssoUrlLogin = config.ssoUrlLogin
    const comercialUrl = config.comercialUrl
    const clientId = config.clientId
    const search = window.location.search
    const params = new URLSearchParams(search)
    const redirectRoute = params.get('redirect_route')

    const url = `${ssoUrlLogin}?url=${comercialUrl}&clientId=${clientId}${
      redirectRoute ? `&redirect_route=${redirectRoute}` : ''
    }`

    window.location.assign(url)
  }

  const tokens = userStore.getTokens()

  const getNewTokens = async () => {
    await userStore.getNewTokens()
    setLoading(false)
  }

  useEffect(() => {
    getNewTokens()
  }, [])

  if (loading) {
    return <LoadingPage open={true} />
  }

  if (tokens.accessToken || tokens.refreshToken) {
    return <Redirect to="/" />
  }

  return (
    <Box
      bgcolor="#263238"
      height="100vh"
      width="100vw"
      className={classes.page}
    >
      <Container className={classes.container}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} className={classes.login}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <img src="/g10-logo-white.png" alt="G10 Logo" />
            </Box>

            <Typography variant="h4" className={classes.title}>
              Comercial
            </Typography>

            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleLogin}
            >
              Entrar
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <img
              src="/presentation.svg"
              alt="Apresentação Comercial"
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default observer(Login)
