import { MongoQuery, Ability, AbilityTuple, Subject } from '@casl/ability'

import { AnyObject } from '@casl/ability/dist/types/types'
import { FetchState } from '@elentari/core/types'

export const getAuthorizations = (
  ability:
    | Ability<AbilityTuple<string, Subject>, MongoQuery<AnyObject>>
    | undefined,
  name: string
) => ({
  canDelete: !!ability?.can('delete', name),
  canUpdate: !!ability?.can('update', name),
  canCreate: !!ability?.can('create', name),
  canRead: !!ability?.can('read', name)
})

export const canSave = (
  state: FetchState<any>,
  authorization: {
    canCreate: boolean
    canUpdate: boolean
    canDelete: boolean
  }
) => {
  return state.tag === 'with-data'
    ? authorization.canUpdate
    : authorization.canCreate
}
