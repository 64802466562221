import { makeUseFetchOne } from '@elentari/core/hooks/makeUseFetchOne'
import { AgrupadorProdutosDetail } from '../types'
import { agrupadoresProdutosRepository } from './agrupadoresProdutosRepository'

export const useAgrupadorProdutos = makeUseFetchOne<
  AgrupadorProdutosDetail,
  string
>({
  fetch: agrupadoresProdutosRepository.fetchOne
})
