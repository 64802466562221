import { useState } from 'react'
import {
  LeituraPlanilhaDialog,
  LeituraPlanilhaDialogForm
} from 'src/components/LeituraPlanilhaDialog'
import { PlanilhaProps } from './types'
import { createValuesInTable } from './functions'
import contratoStore from '../../store/store'

function LeituraPlanilhaFretePedagioDialog({
  open,
  capacidadesTabelaFrete,
  handleClose
}: PlanilhaProps) {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async ({ fileXlsx }: LeituraPlanilhaDialogForm) => {
    setLoading(true)
    const table = await createValuesInTable(fileXlsx, capacidadesTabelaFrete)
    contratoStore.createManyColumns(table, capacidadesTabelaFrete)
    setLoading(false)
    handleClose()
  }

  return (
    <LeituraPlanilhaDialog
      isOpen={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      disabled={loading}
      title="Leitura de planilha para criação de tabela"
      href="/template-contrato.xlsx"
      titlePrimaryButton="Criar Tabela"
    />
  )
}

export { LeituraPlanilhaFretePedagioDialog }
