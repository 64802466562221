import { apiSauceInstance } from '../../../services/api'
import { CidadesDetail, SaveCidadesInput } from '../types'
import { EdgesPage } from '@elentari/core/types'
import { makeRepository } from '@elentari/core/utils/makeRepository'
import { paginationParams } from '../../../utils/paginationQueryParams'
import { ParsedQs } from 'qs'

const mapQueryParams = (params: ParsedQs): ParsedQs => {
  const { sort, ...rest } = params

  return {
    ...paginationParams(rest),
    sort: sort || {
      nome: 'asc'
    }
  }
}

export const cidadesRepository = makeRepository<
  EdgesPage<CidadesDetail>,
  SaveCidadesInput,
  CidadesDetail,
  string
>({ resource: 'cidades', mapQueryParams }, apiSauceInstance)
