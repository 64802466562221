import { Button, Grid, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { ConsultaField } from 'src/components/ConsultaField'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import { formatCurrency } from 'src/utils/formatters'
import {
  SimularFreteContrato,
  SimularFreteContratoForm
} from '../../types/simularFrete'

interface Props {
  simulacaoResult: SimularFreteContrato
  handleClickBack: () => void
  fillOnProgramacao: (freteEmpresa: number, valorPedagio: number) => void
}

export const ValoresSimulacao = ({
  simulacaoResult,
  handleClickBack,
  fillOnProgramacao
}: Props) => {
  const formik = useFormikContext<SimularFreteContratoForm>()

  const freteEmpresa =
    simulacaoResult.freteLiquido - simulacaoResult.valorPedagio
  const valorPedagio = simulacaoResult.valorPedagio

  useEffect(() => {
    formik.setFieldValue('freteEmpresa', freteEmpresa)
    formik.setFieldValue('valorPedagio', valorPedagio)
  }, [freteEmpresa, valorPedagio])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Valores da Simulação</Typography>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={6}>
            <ConsultaField
              title="Valor de Frete"
              subtitle={formatCurrency(simulacaoResult.valorFrete)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Valor de ICMS"
              subtitle={formatCurrency(simulacaoResult.valorIcms)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Valor de Pedágio"
              subtitle={formatCurrency(simulacaoResult.valorPedagio)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Frete Líquido"
              subtitle={formatCurrency(simulacaoResult.freteLiquido)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Adicional de Entrega"
              subtitle={formatCurrency(simulacaoResult.adicionalEntrega)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="Valor total do Frete"
              subtitle={formatCurrency(simulacaoResult.valorTotalFrete)}
              titleVariant="h6"
              subtitleVariant="body1"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <Typography variant="body1" align="right">
              Frete Líquido - Pedágio →
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <NumericInput
              name="freteEmpresa"
              label="Frete Empresa"
              precision={2}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1" align="right">
              Pedágio →
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <NumericInput
              name="valorPedagio"
              label="Valor do Pedágio"
              precision={2}
              disabled
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          justifyContent="flex-end"
          container
          spacing={2}
          style={{ marginBottom: 12 }}
        >
          <Grid item style={{ width: 160 }}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={handleClickBack}
            >
              Fechar
            </Button>
          </Grid>
          <Grid item style={{ width: 280 }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => fillOnProgramacao(freteEmpresa, valorPedagio)}
            >
              Preencher na Programação
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
