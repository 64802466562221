import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  Typography
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext
} from 'formik'
import { EstadoOrigemDestinoListener } from 'src/modules/lotes/master-detail/EstadoOrigemDestinoListener'
import {
  checkFrete,
  checkFreteGrupo,
  checkFretePF,
  checkFretePJ
} from 'src/modules/lotes/master-detail/validateFunctions'
import { LotesFormData } from 'src/modules/lotes/types'
import { AliquotaIcmsInput } from 'src/components/AliquotaIcmsInput'
import AsyncSearchInput from './Formik/Forms/AsyncSearchInput'
import NumericInput from './Formik/Forms/NumericInput'
import TextInput from './Formik/Forms/TextInput'
import SwitchInput from './SwitchInput'

interface RolesInputProps {
  name: string
}

export interface IPernaInput {
  localCarregamento: string
  estadoOrigemPerna: { name: string; value: string }
  cidadeOrigemPerna: { name: string; value: string }
  localEntrega: string
  estadoDestinoPerna: { name: string; value: string }
  cidadeDestinoPerna: { name: string; value: string }
  freteEmpresa: number
  freteAgregado: number
  freteSubcontratadoPJ: number
  freteSubcontratadoPF: number
  valorGrupo: boolean
  freteGrupo: number
  operacaoKMM: number
  valorPedagio: number
  aliquotaICMS: number
}

export default function PernaInput({ name }: RolesInputProps) {
  const [field, meta] = useField({ name })
  const { values, setFieldValue } = useFormikContext<LotesFormData>()

  function addPerna(arrayHelpers: FieldArrayRenderProps) {
    arrayHelpers.push({
      localCarregamento: '',
      estadoOrigemPerna: { name: '', value: '' },
      cidadeOrigemPerna: { name: '', value: '' },
      localEntrega: '',
      estadoDestinoPerna: { name: '', value: '' },
      cidadeDestinoPerna: { name: '', value: '' },
      freteEmpresa: 0,
      freteAgregado: 0,
      freteSubcontratadoPJ: 0,
      freteSubcontratadoPF: 0,
      valorGrupo: false,
      freteGrupo: 0,
      operacaoKMM: 0,
      valorPedagio: 0,
      aliquotaICMS: 0
    })
  }

  function deletePerna(index: number, arrayHelpers: FieldArrayRenderProps) {
    arrayHelpers.remove(index)
  }

  function clearCidade(name: string) {
    setFieldValue(name, {
      name: '',
      value: ''
    })
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <FieldArray
          name={name}
          render={arrayHelpers => (
            <Grid container spacing={2}>
              {field.value.map((perna: any, index: number) => {
                return (
                  <>
                    <Grid xs={12} item key={index}>
                      <Grid container alignItems="center" spacing={2}>
                        {values.segundaPerna && (
                          <Grid item xs={index ? 11 : 12}>
                            <Typography variant="h6">
                              {index + 1}ª Perna
                            </Typography>
                          </Grid>
                        )}
                        {index > 0 && (
                          <>
                            <EstadoOrigemDestinoListener index={index} />
                            <Grid container item xs={1} justifyContent="center">
                              <IconButton
                                onClick={() => deletePerna(index, arrayHelpers)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextInput
                                name={`${name}.${index - 1}.localEntrega`}
                                label="Local de Carregamento (opcional)"
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <AsyncSearchInput
                                label="Estado Origem"
                                name={`${name}.${index - 1}.estadoDestinoPerna`}
                                url="/estados/minimal"
                                labelField="nome"
                                joinStateAndUf
                                disabled
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <AsyncSearchInput
                                name={`${name}.${index - 1}.cidadeDestinoPerna`}
                                label="Cidade Origem"
                                url={`/estados/${perna.estadoOrigemPerna?.value}/cidades`}
                                labelField="nome"
                                disabled
                              />
                            </Grid>
                          </>
                        )}
                        {!index && (
                          <>
                            <EstadoOrigemDestinoListener index={0} />
                            <Grid item xs={12} sm={3}>
                              <TextInput
                                name={`${name}.${index}.localCarregamento`}
                                label="Local de Carregamento (opcional)"
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <AsyncSearchInput
                                label="Estado Origem"
                                name={`${name}.${index}.estadoOrigemPerna`}
                                url="/estados/minimal"
                                labelField="nome"
                                joinStateAndUf
                                handleOnChange={() =>
                                  clearCidade(
                                    `${name}.${index}.cidadeOrigemPerna`
                                  )
                                }
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <AsyncSearchInput
                                name={`${name}.${index}.cidadeOrigemPerna`}
                                label="Cidade Origem"
                                url={`/estados/${perna.estadoOrigemPerna?.value}/cidades`}
                                labelField="nome"
                                disabled={!perna.estadoOrigemPerna?.value}
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12} sm={3}>
                          <TextInput
                            name={`${name}.${index}.localEntrega`}
                            label="Local de Entrega (opcional)"
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <AsyncSearchInput
                            label="Estado Destino"
                            name={`${name}.${index}.estadoDestinoPerna`}
                            url="/estados/minimal"
                            labelField="nome"
                            joinStateAndUf
                            handleOnChange={() =>
                              clearCidade(`${name}.${index}.cidadeDestinoPerna`)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <AsyncSearchInput
                            name={`${name}.${index}.cidadeDestinoPerna`}
                            label="Cidade Destino"
                            url={`/estados/${perna.estadoDestinoPerna?.value}/cidades`}
                            labelField="nome"
                            disabled={!perna.estadoDestinoPerna?.value}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <NumericInput
                            name={`${name}.${index}.freteEmpresa`}
                            label="Frete Empresa"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <NumericInput
                            name={`${name}.${index}.freteAgregado`}
                            label="Frete Agregado"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              )
                            }}
                            onBlur={checkFrete(values)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <NumericInput
                            name={`${name}.${index}.freteSubcontratadoPJ`}
                            label="Frete Subcontratado PJ"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              )
                            }}
                            onBlur={checkFretePJ(values)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <NumericInput
                            name={`${name}.${index}.freteSubcontratadoPF`}
                            label="Frete Subcontratado PF"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  R$
                                </InputAdornment>
                              )
                            }}
                            onBlur={checkFretePF(values)}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <SwitchInput
                            name={`${name}.${index}.valorGrupo`}
                            label="Frete Grupo Diferente 3%"
                          />
                        </Grid>
                        {perna.valorGrupo ? (
                          <Grid item xs={12} sm={3}>
                            <NumericInput
                              name={`${name}.${index}.freteGrupo`}
                              label="Frete Grupo"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                              onBlur={checkFreteGrupo(values)}
                            />
                          </Grid>
                        ) : undefined}
                        <Grid container item spacing={2} xs>
                          <Grid item xs>
                            <NumericInput
                              name={`${name}.${index}.operacaoKMM`}
                              label="Operação KMM (opcional)"
                              precision={0}
                            />
                          </Grid>
                          <Grid item xs>
                            <NumericInput
                              name={`${name}.${index}.valorPedagio`}
                              label="Valor do Pedágio"
                              inputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    R$
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <AliquotaIcmsInput
                            name={`${name}.${index}.aliquotaICMS`}
                            label="Alíquota ICMS"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )
              })}

              {values.segundaPerna ? (
                <Grid xs={12} item>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="button"
                    onClick={() => addPerna(arrayHelpers)}
                    data-cy="add-perfil"
                  >
                    Adicionar Perna
                  </Button>

                  <FormHelperText error>
                    {!field.value?.length && meta.error}
                  </FormHelperText>
                </Grid>
              ) : null}
            </Grid>
          )}
        />
      </Grid>
    </Grid>
  )
}
