import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { ConfiguracoesDetail } from '../types'

class ConfiguracaoStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  configuracao = new ApisauceDataStore<ConfiguracoesDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/configuracoes',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}
export const configuracaoStore = new ConfiguracaoStore()
export default ConfiguracaoStore
