import { EdgesPage } from '@elentari/core/types'
import { ApiResponse, create } from 'apisauce'
import qs from 'qs'
import config from '../config'
import { UserProfile } from '../modules/login/types'
import { AuthAbilities, IConfigTabela } from './types'

export const apiSauceInstance = create({
  baseURL: config.baseURL,
  withCredentials: true
})

const realApi = {
  setToken: (token: string) => apiSauceInstance.setHeader('token', `${token}`),
  ping: () => apiSauceInstance.get('/me'),
  getAbilities: (username: string) =>
    apiSauceInstance.get<AuthAbilities[]>(`/auth/${username}/abilities`),
  login: () => {
    return {}
  },

  logout: (token: string, refresh_token: string) => {
    return apiSauceInstance.post('/auth/logout', {
      accessToken: token,
      refreshToken: refresh_token
    })
  },

  refreshToken: (refresh_token: string) => {
    return apiSauceInstance.post('/auth/refresh', {
      refresh_token
    })
  },

  saveConfigTabela: ({
    key,
    configTabela,
    descricaoTabela,
    isDefault
  }: IConfigTabela) => {
    return apiSauceInstance.post('/configuracao-tabela', {
      key,
      descricaoTabela,
      configTabela,
      isDefault
    })
  },

  getConfigTabela: (key: string) => {
    return apiSauceInstance.get<{
      configTabela: string
      descricaoTabela: string
      isDefault: boolean
    }>(`/configuracao-tabela/${key}`)
  },

  getListConfigTable(key: string) {
    return apiSauceInstance.get<
      {
        id: string
        descricaoTabela: string
        isDefault: boolean
      }[]
    >(`/configuracao-tabela/${key}/all`)
  },

  alternateDefaultConfigTable(id: string, key: string) {
    return apiSauceInstance.patch<{
      ok: boolean
      limit: string
      message: string
    }>('/configuracao-tabela/alternate-default', {
      id,
      key
    })
  },

  deleteConfigTable(id: string) {
    return apiSauceInstance.delete(`/configuracao-tabela/${id}`)
  },

  getProfile: async (): Promise<ApiResponse<UserProfile>> => {
    return apiSauceInstance.get(`/auth/profile`)
  },
  verifyToken: async (token: string): Promise<ApiResponse<UserProfile>> => {
    return apiSauceInstance.post(`/auth/verify-token`, {
      token,
      clientId: config.clientId
    })
  },
  getUsers: async (
    params: qs.ParsedQs
  ): Promise<ApiResponse<EdgesPage<any>>> => {
    return apiSauceInstance.get(`/users?${qs.stringify(params)}`)
  },
  getUser: async (id: string): Promise<ApiResponse<any>> => {
    return apiSauceInstance.get(`/users/${id}`)
  },
  saveUser: async (user: any): Promise<ApiResponse<any>> => {
    if (user.id) {
      return apiSauceInstance.patch(`/users/${user.id}`, user)
    }
    return apiSauceInstance.post(`/users`, user)
  },
  deleteUser: async (id: string): Promise<ApiResponse<any>> => {
    return apiSauceInstance.delete(`/users/${id}`)
  }
}

const api = /*
  process.env.NODE_ENV === 'development' && config.useFixture
    ? fixture
    :  */ realApi

export default api
