import { Box, Grid, Paper } from '@material-ui/core'
import { useEffect } from 'react'
import ChartCard from '../ChartCard'
import useDashboardStyles from '../dashboard.styles'
import GraficoCaminhoesPorCentroCusto from './GraficoCaminhoesPorCentroCusto'
import GraficoCaminhoesPorCliente from './GraficoCaminhoesPorCliente'
import InfoCard from '../InfoCard'
import DashboardProgramacoesFilters from './Filters'
import dashboardStore from './store'
import { Observer } from 'mobx-react-lite'
import { useAppBar } from 'src/hooks'

export default function DashboardProgramacoes() {
  const classes = useDashboardStyles()
  const [, appBarActions] = useAppBar()

  useEffect(() => {
    appBarActions.setTitle('Dashboard Programações')
    dashboardStore.clearFilters()
  }, []) // eslint-disable-line

  return (
    <>
      <Paper>
        <Box p={2} mb={2}>
          <DashboardProgramacoesFilters />
        </Box>
      </Paper>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Observer>
            {() => (
              <InfoCard
                title="Caminhões Programados"
                info={dashboardStore.qtdCaminhoesProgramados}
                loading={dashboardStore.loading}
              />
            )}
          </Observer>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Observer>
            {() => (
              <InfoCard
                title="Caminhões Carregados"
                info={dashboardStore.qtdCaminhoesCarregados}
                loading={dashboardStore.loading}
              />
            )}
          </Observer>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Observer>
            {() => (
              <InfoCard
                title="Caminhões em Carregamento"
                info={dashboardStore.qtdCaminhoesCarregamento}
                loading={dashboardStore.loading}
              />
            )}
          </Observer>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Observer>
            {() => (
              <InfoCard
                title="Caminhões em Trânsito"
                info={dashboardStore.qtdCaminhoesTransito}
                loading={dashboardStore.loading}
              />
            )}
          </Observer>
        </Grid>

        <Grid item xs={12} md={6}>
          <Observer>
            {() => (
              <InfoCard
                title="Projeção Toneladas"
                info={dashboardStore.projecaoToneladas}
                loading={dashboardStore.loading}
              />
            )}
          </Observer>
        </Grid>
      </Grid>

      <div className={classes.dashboardProgramacoes}>
        <ChartCard>
          <GraficoCaminhoesPorCliente />
        </ChartCard>
        <ChartCard>
          <GraficoCaminhoesPorCentroCusto />
        </ChartCard>
      </div>
    </>
  )
}
