const config = {
  baseURL: process.env.REACT_APP_API_URL ?? 'http://localhost:3030',
  useFixture: false,
  ssoUrlLogin: process.env.REACT_APP_SSO_URL_LOGIN,
  comercialUrl: process.env.REACT_APP_COMERCIAL_URL,
  clientId: process.env.REACT_APP_COMERCIAL_CLIENT_ID,
  ssoUrl: process.env.REACT_APP_SSO_URL,
  localStorage: {
    accessToken: '@comercial:token',
    refreshToken: '@comercial:refreshToken'
  },
  mapboxToken: process.env.REACT_APP_MAPBOX_TOKEN
}

export default config
