import { MessagesYup } from 'src/modules/messages'
import * as yup from 'yup'

export const validarFreteSchema = yup.object().shape({
  centroCustoId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  dataSimulacao: yup.date().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  capacidadeVeiculo: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  distancia: yup
    .number()
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  qtdEixos: yup
    .number()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
    .optional(),
  qtdVolumes: yup
    .number()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
    .optional(),
  qtdEntregas: yup
    .number()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
    .optional(),
  aliquotaIcms: yup
    .number()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
    .optional()
    .max(100, MessagesYup.MENSAGEM_ALIQUOTA_MAX),
  pedagio: yup
    .number()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO)
    .optional()
})
