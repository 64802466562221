import { useQueryParams, useSnackbar } from '@elentari/core'
import { Button, Grid, Typography } from '@material-ui/core'
import { Observer } from 'mobx-react-lite'
import { useEffect, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Paper } from 'src/components'
import Spacer from 'src/components/Spacer'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useAbility } from '../login'
import { LotesTableValidation } from './LotesTableValidation'
import { lotesRepository } from './hooks/lotesRepository'
import { useFilaValidacao } from './hooks/useFilaValidacao'
import { lotesStore } from './stores/lotes'
import { StatusLoteEnum } from './types'

export type LotesQueryFilter = {
  [key: string]: any
}

export const LotesValidation = () => {
  const [, snackbarActions] = useSnackbar()
  const ability = useAbility()
  const { initialValues, push } = useQueryParams<LotesQueryFilter>()
  const history = useHistory()
  const { count, nextFromQueue } = useFilaValidacao()

  const filterValues = useMemo((): LotesQueryFilter => {
    const toPush: LotesQueryFilter = {}

    if (!initialValues.sort) {
      toPush['sort'] = { updatedAt: 'desc' }
    }

    if (!initialValues.status) {
      toPush['status'] = [
        StatusLoteEnum.ANALISE,
        StatusLoteEnum.ABERTO,
        StatusLoteEnum.REABERTO,
        StatusLoteEnum.REANALISE
      ]
    }

    if (Object.keys(toPush).length > 0) {
      push(toPush)
    }

    return { ...toPush, ...initialValues }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues])

  useEffect(() => {
    lotesStore.lotes.setPage(Number(filterValues.page) || 1)
    lotesStore.lotes.setFilters(filterValues)
  }, [filterValues]) // eslint-disable-line

  useEffect(() => {
    return () => {
      lotesStore.lotes.resetList()
    }
  }, [])

  async function handleDelete(id: string) {
    const response = await lotesRepository.delete(id)
    lotesStore.lotes.populate()

    if (response.ok) {
      snackbarActions.setSnackBar({
        message: 'Deletado com sucesso',
        severity: 'success'
      })
      return
    }

    const message = (response.data as unknown as any).message

    snackbarActions.setSnackBar({
      message: message as string,
      severity: 'warning'
    })
  }

  async function handleNext() {
    const nextLote = await nextFromQueue()

    if (nextLote) {
      history.push(`lotes-validacao/${nextLote.id}?view=true`)
    }
  }

  const authorization = getAuthorizations(ability, 'Lotes')

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Observer>
              {() => (
                <Typography variant="body1">
                  Quantidade na fila: {count}
                </Typography>
              )}
            </Observer>
          </Grid>
          <Grid item xs={6}>
            <Grid item container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={handleNext}>
                PRÓXIMO
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <LotesTableValidation
        onDelete={handleDelete}
        authorization={authorization}
        isValidation
      />
    </>
  )
}
