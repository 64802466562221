import { Tooltip, TooltipProps } from '@material-ui/core'

interface Props extends TooltipProps {
  enabled: boolean
}

export const ConditionalTooltip = ({ enabled, children, ...rest }: Props) => {
  return enabled ? (
    <Tooltip {...rest}>
      <span>{children}</span>
    </Tooltip>
  ) : (
    children
  )
}
