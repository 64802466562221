import { Button, Grid, Typography } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import {
  LeituraPlanilhaFretePedagioDialog,
  LimparInformacoes
} from '../components'
import contratoStore from '../store/store'
import TabelaContratoDataGrid from './TabelaContratoDataGrid'
import { IMPORTAR_DADOS_TABELA_PEDAGIO_DISABLED } from '../components/LeituraPlanilhaFretePedagioDialog/Messages'

const TabelaPedagioForm = () => {
  const [openModalPlanilha, setOpenModalPlanilha] = useState(false)
  const [openModalClear, setOpenModalClear] = useState(false)

  const disabledImportarPlanilha = Boolean(
    contratoStore.tabelaPedagio.rows.length
  )

  const hasCapacidades = contratoStore.tabelaFrete.columns.length > 3

  const capacidadesTabelaFrete = contratoStore.tabelaFrete.columns
    .filter(item => Number(item.headerName))
    .map(item => Number(item.headerName))

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={6}>
        <Button
          fullWidth
          type="button"
          variant="outlined"
          onClick={() => contratoStore.addFaixaKmTabelaPedagio()}
        >
          Adicionar Faixa de KM
        </Button>
      </Grid>
      <Grid item xs>
        <Button
          fullWidth
          type="button"
          variant="outlined"
          onClick={() => setOpenModalClear(true)}
        >
          Limpar Informações
        </Button>
      </Grid>
      <Grid item xs>
        <ConditionalTooltip
          enabled={!hasCapacidades ? false : disabledImportarPlanilha}
          title={
            <Typography variant="subtitle1">
              {IMPORTAR_DADOS_TABELA_PEDAGIO_DISABLED}
            </Typography>
          }
        >
          <Button
            fullWidth
            type="button"
            variant="contained"
            color="primary"
            onClick={() => setOpenModalPlanilha(true)}
            disabled={!hasCapacidades || disabledImportarPlanilha}
          >
            Importar Planilha
          </Button>
        </ConditionalTooltip>
      </Grid>
      <Grid item xs={12}>
        <TabelaContratoDataGrid
          rows={contratoStore.tabelaPedagio.rows}
          columns={contratoStore.tabelaPedagio.columns}
          setCellValue={contratoStore.setValorTabelaPedagio}
          disableColumnMenu
        />
      </Grid>
      <LeituraPlanilhaFretePedagioDialog
        open={openModalPlanilha}
        capacidadesTabelaFrete={capacidadesTabelaFrete}
        handleClose={() => setOpenModalPlanilha(false)}
      />
      <LimparInformacoes
        handleClose={() => setOpenModalClear(false)}
        primaryAction={() => {
          contratoStore.clearRowsTabelaPedagio()
          setOpenModalClear(false)
        }}
        open={openModalClear}
      />
    </Grid>
  )
}

export default observer(TabelaPedagioForm)
