import { Button, CircularProgress, Grid } from '@material-ui/core'
import { Form, Formik } from 'formik'
import { useState } from 'react'
import { useHistory } from 'react-router'
import When from 'src/components/When'
import useCanSave from 'src/hooks/useCanSave'
import { EntitySaver } from 'src/services/entitySaver'
import * as yup from 'yup'
import { Paper } from '../../../components'
import FieldsPending from '../../../components/FieldsPending'
import TextInput from '../../../components/Formik/Forms/TextInput'
import scrollToError from '../../../utils/scrollToError'
import yupValidation from '../../../utils/yupValidation'
import { MessagesYup } from '../../messages'
import { agrupadoresProdutosRepository } from '../hooks/agrupadoresProdutosRepository'
import { useAgrupadorProdutos } from '../hooks/useAgrupadorProdutos'
import { IAgrupadorProdutosForm } from '../types'

const schema = yup.object().shape({
  nome: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO)
})

const initialValues = {
  nome: ''
}

export const AgrupadorProdutosForm = () => {
  const [state] = useAgrupadorProdutos()
  const [loading, setLoading] = useState<boolean>(false)
  const history = useHistory()
  const canSave = useCanSave(state, 'Agrupadores de Produtos')

  async function handleSubmit(agrupador: IAgrupadorProdutosForm) {
    setLoading(true)

    await EntitySaver(
      () =>
        agrupadoresProdutosRepository.save({
          id: agrupador.id,
          nome: agrupador.nome
        }) as any,
      {
        onSuccess: () => handleGoBack(),
        feedbackError: 'Erro ao salvar o agrupador de produtos'
      }
    )

    setLoading(false)
  }

  const handleGoBack = () => {
    history.goBack()
  }

  return (
    <Formik
      enableReinitialize
      validate={yupValidation(schema)}
      initialValues={state.tag === 'with-data' ? state.entity : initialValues}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, errors, submitCount }) => (
        <Form>
          <Paper>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextInput name="nome" label="Nome" />
              </Grid>
            </Grid>
            <FieldsPending errors={errors} submitCount={submitCount} />
            <Grid
              justifyContent="flex-end"
              item
              container
              spacing={4}
              style={{ marginTop: 8 }}
            >
              <Grid item xs={12}>
                <Grid justifyContent="flex-end" container spacing={2}>
                  <Grid item style={{ width: 160 }}>
                    <Button
                      fullWidth
                      type="button"
                      variant="outlined"
                      onClick={handleGoBack}
                      disabled={isSubmitting || loading}
                    >
                      Voltar
                    </Button>
                  </Grid>
                  <When value={canSave} equals>
                    <Grid item style={{ width: 160 }}>
                      <Button
                        data-testid="salvar"
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary"
                        onClick={() => scrollToError(errors)}
                        disabled={isSubmitting || loading || !canSave}
                      >
                        {isSubmitting || loading ? (
                          <CircularProgress color="inherit" size={24} />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                    </Grid>
                  </When>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Form>
      )}
    </Formik>
  )
}
