import { Typography } from '@material-ui/core'
import { useField } from 'formik'
import React from 'react'
import { UploadXlsx } from 'src/components/UploadXlsx'

interface Props {
  name: string
}

export function UploadXlsxInput({ ...props }: Props) {
  const [, meta, helpers] = useField(props)

  function handleChange(file: File | null) {
    helpers.setValue(file)
  }

  return (
    <React.Fragment>
      <UploadXlsx changeFile={handleChange} />
      <Typography variant="caption" style={{ color: 'red' }}>
        {meta.touched && meta.error}
      </Typography>
    </React.Fragment>
  )
}
