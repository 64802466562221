import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { formatCurrency, formatDate } from '../../utils/formatters/index'
import { NegociacaoNaoFechadasColumns } from './NegociacoesNaoFechadasColumns'
import negociacoesNfStore from './store/negociacoesNf'
import { NegociacaoNaoFechadaDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const NegociacoesNaoFechadasTable = observer(
  ({ onDelete, authorization }: Props) => {
    const { pathname } = useLocation()

    const KEY_TABLE = resourceTabela.NEGOCIACAO_NAO_FECHADA
    useConfigTable(KEY_TABLE)
    const tableState = tableStore.getState(KEY_TABLE)
    const isTableReady =
      !negociacoesNfStore.negociacoesNf.isLoading &&
      tableState &&
      negociacoesNfStore.negociacoesNf.list.length

    const columns: Array<Column & IColumn> = useMemo(
      () => NegociacaoNaoFechadasColumns({ onDelete, authorization }),
      [onDelete, authorization]
    )

    const rows = negociacoesNfStore.negociacoesNf.list.map(
      ({ ...negociacao }: NegociacaoNaoFechadaDetail) => {
        return {
          ...negociacao,
          produto: negociacao.produto || negociacao.Produto.nome,
          centroCusto: negociacao.CentroCusto.descricao,
          freteCotado: formatCurrency(negociacao.freteCotado),
          freteVencedor: negociacao.freteVencedor
            ? formatCurrency(negociacao.freteVencedor)
            : '-',
          dataNegociacao: formatDate(negociacao.dataNegociacao),
          origem: `${negociacao.Origem.nome} / ${negociacao.Origem.Estado.sigla}`,
          destino: `${negociacao.Destino.nome} / ${negociacao.Destino.Estado.sigla}`
        }
      }
    )

    const sortMapper = new Map([
      ['centroCusto', 'CentroCusto.descricao'],
      ['origem', 'Origem.nome'],
      ['destino', 'Destino.nome']
    ])

    return (
      <>
        <Spacer y={4} />
        <Paper>
          <TableContainer
            loading={negociacoesNfStore.negociacoesNf.isLoading}
            totalCount={negociacoesNfStore.negociacoesNf.totalCount}
          >
            {isTableReady ? (
              <BaseTable
                keyTable={KEY_TABLE}
                columns={columns}
                hasConditionClickRow={false}
                linkWithRedirectClickRow={pathname}
                data={rows}
                sortMapper={sortMapper}
              />
            ) : (
              <NoResultsTable
                loading={negociacoesNfStore.negociacoesNf.isLoading}
              />
            )}
          </TableContainer>
        </Paper>
        <AddRecord path={pathname} authorization={authorization} />
      </>
    )
  }
)
