import { useQueryParams } from '@elentari/core'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { getAuthorizations } from '../../utils/crudAuthorization'
import { useAbility } from '../login'
import { CentroCustoFilter, CentroCustoQueryFilter } from './CentrosCustoFilter'
import { CentrosCustoTable } from './CentrosCustoTable'
import { centroCustoRepository } from './hooks/centroCustoRepository'
import centrosCustoStore from './store'

const CentrosCusto = observer(() => {
  const ability = useAbility()
  const { initialValues } = useQueryParams<CentroCustoQueryFilter>()

  async function handleDelete(id: string) {
    const response = await centroCustoRepository.delete(id)
    centrosCustoStore.centrosCusto.populate()

    if (response.ok) {
      snackbarStore.setMessage('Deletado com Sucesso')
      return
    }

    const message = (response.data as unknown as any).message
    snackbarStore.setMessage(message as string)
  }

  useEffect(() => {
    centrosCustoStore.centrosCusto.setPage(Number(initialValues.page) || 1)
    centrosCustoStore.centrosCusto.setFilters(initialValues)
  }, [initialValues]) // eslint-disable-line

  const authorization = getAuthorizations(ability, 'Centros de Custo')

  return (
    <>
      <CentroCustoFilter />
      <CentrosCustoTable
        authorization={authorization}
        onDelete={handleDelete}
      />
    </>
  )
})

export default CentrosCusto
