import { TabelaDetail } from 'src/modules/contratos/types/tabelaTypes'
import { getDataInNode } from 'src/utils/getDataInNode'

export function formatLabelTabelasContrato(data: FormatLabel<TabelaDetail>) {
  const { codigo, descricao, id } = getDataInNode(data)

  return {
    name: `${codigo} - ${descricao}`,
    value: id
  }
}
