import { Anexo, CreatedAnexo } from 'src/utils/anexo'
import { TabelaDetail } from './tabelaTypes'

export interface Contrato {
  id: string
  codigo: string
  status: string
  descricao: string
  dataInicio: Date
  dataTermino: Date
  agrupadorProdutosId: string
  AgrupadorProdutos: {
    nome: string
    id: string
  }
  GrupoNegociador: {
    nome: string
    id: string
  }
  cadencia: string
  observacoes: string
  nextPossibleStatus: string[]
  isStatusChangeable: boolean
}

export interface LoteContrato {
  codigo: number
  status: string
  nomeProduto: string
  descricaoTabela: string
  grupoNegociadorNome: string
  dataInicio: Date
  dataTermino: Date
  quantidadeRetirada: number
}

export interface TabelasSave {
  descricao: string
  tipoNegociacaoEmpresa: string
  pedagio: boolean
  tipoPedagio?: string
  icms: boolean
  adicionalEntrega: boolean
  tabelaFreteDto: TabelaDto
  tabelaPedagioDto?: TabelaDto
}

export interface CapacidadeSave {
  capacidade: number
}

export interface FaixaKmSave {
  kmInicial: string
  kmFinal: string
}

export interface TabelaDto {
  faixaQuilometragem: boolean
  valorAdicional?: number
  franquiaEntregas?: number
  capacidadesDto: CapacidadeSave[]
  faixasKmDto: FaixaKmSave[]
  valores: number[][]
}

export interface ContratoSave {
  id?: string
  descricao: string
  dataInicio: Date
  dataTermino: Date
  agrupadorProdutosId: string
  grupoNegociadorId: string
  cadencia?: string
  observacoes?: string
  anexos: CreatedAnexo[]
}

export interface ContratoFormData {
  id?: string
  descricao: string
  dataInicio: Date
  dataTermino: Date
  agrupadorProdutosId: { name: string; value: string }
  grupoNegociadorId: { name: string; value: string }
  cadencia?: string
  observacoes?: string
  files: File[]
}

export interface ContratoDetail extends Contrato {
  Anexos?: Anexo[]
  Tabelas: TabelaDetail[]
}

export type ContratosQueryFilter = {
  [key: string]: any
}

export const Status = {
  ATIVO: 'Ativo',
  INATIVO: 'Inativo',
  CANCELADO: 'Cancelado',
  ENCERRADO: 'Encerrado'
}

export const status = Object.entries(Status).map(([key, value]) => {
  return { value: key, label: value }
})

export type StatusContrato =
  | 'ATIVO'
  | 'INATIVO'
  | 'CANCELADO'
  | 'ENCERRADO'
  | ''

export interface AlterarStatusData {
  newStatus: StatusContrato
}

export interface UseParamsProps {
  id: string
  childId: string
}
