import { Typography } from '@material-ui/core'
import { blue, red } from '@material-ui/core/colors'
import { withStyles } from '@material-ui/styles'
import When from 'src/components/When'

interface Props {
  previsaoMargem: number
  color?: 'RED' | 'BLUE'
}

const RedTypography = withStyles({
  root: {
    color: red[500]
  }
})(Typography)

const BlueTypography = withStyles({
  root: {
    color: blue[500]
  }
})(Typography)

export function PrevisaoMargem({ previsaoMargem, color }: Props) {
  const previsaoMargemFormatted = `${(previsaoMargem * 100).toFixed(2)}%`
  return (
    <When value={previsaoMargem !== null} equals>
      {!color ? (
        <Typography>{previsaoMargemFormatted}</Typography>
      ) : color === 'RED' ? (
        <RedTypography>{previsaoMargemFormatted}</RedTypography>
      ) : (
        <BlueTypography>{previsaoMargemFormatted}</BlueTypography>
      )}
    </When>
  )
}
