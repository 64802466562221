import { GridValueFormatterParams } from '@mui/x-data-grid'

export const getAlphanumericDigits = (string: string) => {
  return string ? string.replace(/\W/g, '') : ''
}

export const getOnlyDigits = (string: string) => {
  return string ? string.replace(/[^\d]/g, '') : ''
}

export const formatedValuesGrid = (params: GridValueFormatterParams) => {
  const value = Number(params.value?.toString().replace(',', '.'))

  if (!isNaN(value)) {
    return valorMonetario(value)
  } else {
    return valorMonetario(0)
  }
}

function valorMonetario(value: number) {
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  }).format(Number(value))
}
