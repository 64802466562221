import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Tooltip
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldArray, FieldArrayRenderProps, useField } from 'formik'
import AsyncSearchInput from './Formik/Forms/AsyncSearchInput'

interface ModalidadesInputProps {
  name: string
}

export default function ModalidadesInput({ name }: ModalidadesInputProps) {
  const [field, meta] = useField({ name })
  const hasModalidades = !!field.value?.length

  function addModalidade(arrayHelpers: FieldArrayRenderProps) {
    return () => {
      arrayHelpers.push({ modalidade: null })
    }
  }

  function deleteModalidade(
    index: number,
    arrayHelpers: FieldArrayRenderProps
  ) {
    return () => {
      const modalidade = field.value[index]

      if (modalidade?.id) {
        arrayHelpers.form.setFieldValue('deletedModalidades', [
          ...(arrayHelpers.form.values?.deletedModalidades || []),
          modalidade.id
        ])
      }

      arrayHelpers.remove(index)
    }
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {hasModalidades &&
            field.value.map((modalidade: unknown, index: number) => {
              return (
                <Grid xs={12} item key={index}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                      <AsyncSearchInput
                        name={`${name}.${index}.modalidade`}
                        label={`Modalidade ${index + 1}`}
                        url="/modalidades"
                        labelField="nome"
                        data-cy={`modalidade-${index}`}
                      />
                    </Grid>
                    <Grid item>
                      <Tooltip title="Excluir" aria-label="Excluir">
                        <IconButton
                          onClick={deleteModalidade(index, arrayHelpers)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

          <Grid xs={12} item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={addModalidade(arrayHelpers)}
              data-cy="add-pessoa"
            >
              Adicionar Modalidade
            </Button>

            <FormHelperText error>
              {!field.value?.length && meta.error}
            </FormHelperText>
          </Grid>
        </Grid>
      )}
    />
  )
}
