import SelectInput from '../SelectInput'

export const tiposPedagio = [
  { value: 'FIXO', label: 'Fixo' },
  { value: 'POR_EIXO', label: 'R$ por eixo' },
  { value: 'POR_TONELADA', label: 'R$ por tonelada' },
  { value: 'POR_QUILOMETRO', label: 'R$ por quilômetro rodado' },
  { value: 'POR_METRO_CUBICO', label: 'R$ por Metro Cúbico' },
  { value: 'INFORMADO_EMISSAO', label: 'Informado na Emissão' }
]

export type TiposPedagio =
  | 'FIXO'
  | 'POR_EIXO'
  | 'POR_TONELADA'
  | 'POR_QUILOMETRO'
  | 'POR_METRO_CUBICO'
  | 'INFORMADO_EMISSAO'

interface Props {
  name: string
  label?: string
  disabled?: boolean
  clearable?: boolean
}

const TipoPedagioSelectInput = ({
  name,
  label,
  disabled,
  clearable
}: Props) => {
  return (
    <SelectInput
      name={name}
      label={label || 'Tipo Pedágio'}
      options={tiposPedagio}
      disabled={disabled}
      clearable={clearable}
    />
  )
}

export default TipoPedagioSelectInput
