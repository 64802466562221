import { Tooltip, Typography } from '@material-ui/core'
import LinkMui from '@material-ui/core/Link'
import React from 'react'
import { Link } from 'react-router-dom'

interface IEditableLinkProps {
  isActive: boolean
  link: string
  text: string
  unalterableReason: string
}

export const editLink = (props: IEditableLinkProps) => {
  return props.isActive ? (
    <LinkMui component={Link} to={props.link}>
      {props.text}
    </LinkMui>
  ) : (
    <Tooltip placement="top" title={props.unalterableReason}>
      <Typography>{props.text}</Typography>
    </Tooltip>
  )
}
