import { Grid } from '@material-ui/core'
import { AliquotaIcmsInput } from 'src/components/AliquotaIcmsInput'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import { DestinosInput } from './DestinosInput'
import { OrigemInput } from './OrigemInput'
import { PernaTypography } from './PernaTypography'
import { SimularFreteButton } from './SimularFreteAdornment'
import { ColetaEntregaProps } from './types'

export const ColetaEntregaFrota = ({
  multiplasEntregas,
  pernaIndex,
  isLastPerna,
  namePrefix,
  tabelaId,
  loteId
}: ColetaEntregaProps) => {
  return (
    <PernaTypography pernaIndex={pernaIndex}>
      <Grid container spacing={2}>
        <OrigemInput namePrefix={namePrefix} />

        <Grid item xs={12}>
          <DestinosInput
            name={`${namePrefix}.destinos`}
            multiplasEntregas={multiplasEntregas}
            pernaIndex={pernaIndex}
            isLastPerna={isLastPerna}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid item xs={10}>
              <NumericInput
                name={`${namePrefix}.freteEmpresa`}
                label="Frete Empresa"
              />
            </Grid>
            <Grid item xs={2}>
              <SimularFreteButton
                tabelaId={tabelaId}
                pernaIndex={pernaIndex}
                loteId={loteId}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={3}>
          <NumericInput
            name={`${namePrefix}.valorPedagio`}
            label="Valor Pedágio"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <AliquotaIcmsInput name={`${namePrefix}.aliquotaIcms`} />
        </Grid>
      </Grid>
    </PernaTypography>
  )
}
