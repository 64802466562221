import { Divider, Grid, Typography } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { BlackDisabledTextInput } from 'src/utils/BlackDisabledTextInput'
import { SimularFreteContratoForm } from '../../types/simularFrete'
import { ContratoListener } from './ContratoListener'

interface Props {
  handleReset: () => void
  tabelaId: string
}

export const DadosContrato = ({ handleReset, tabelaId }: Props) => {
  const formik = useFormikContext<SimularFreteContratoForm>()

  useEffect(() => {
    handleReset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values])

  useEffect(() => {
    formik.setFieldValue('tabelaId', tabelaId)
  }, [])

  return (
    <>
      <ContratoListener />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Dados do Contrato</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <BlackDisabledTextInput
            name="descricaoContrato"
            label="Contrato"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <BlackDisabledTextInput
            name="descricaoTabela"
            label="Descrição da Tabela"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BlackDisabledTextInput
            name="agrupadorProduto"
            label="Agrupador de Produto"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BlackDisabledTextInput
            name="tipoNegociacao"
            label="Tipo Negociação Empresa"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BlackDisabledTextInput
            name="tipoPedagio"
            label="Tipo de Pedágio"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BlackDisabledTextInput name="frete" label="Frete + ICMS" disabled />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BlackDisabledTextInput
            name="adicionalEntrega"
            label="Adicional de Entrega"
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <BlackDisabledTextInput
            name="franquiaEntrega"
            label="Franquia de Entrega"
            disabled
          />
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>
      </Grid>
    </>
  )
}
