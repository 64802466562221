import Filter from '@elentari/components-eve/components/Filter'
import Select from '@elentari/components-eve/final-form/Select'
import TextField from '@elentari/components-eve/final-form/TextField'
import { useQueryParams } from '@elentari/core'
import { Grid } from '@material-ui/core'
import * as dateFns from 'date-fns'
import { tiposNegociacao } from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import RFFAsyncSearchInput from 'src/components/Forms/RFFAsyncSearchInput'
import RFFAutocomplete from 'src/components/Forms/RFFAutocomplete'
import * as yup from 'yup'
import yupValidation from '../../utils/yupValidation'
import {
  handleKeyCodes,
  handlePasteIntegerOnly,
  menuPropsMultipleSelect
} from '../options'
import { statusCotacaoOptions } from './types'

type CotacoesQueryFilter = {
  [key: string]: any
}

const schema = yup.object().shape({})

export const CotacoesFilter = () => {
  const { clear, push, initialValues } = useQueryParams<CotacoesQueryFilter>()

  const handleSubmitFilter = (values: Record<string, any>) => {
    push({
      ...values,
      origemId: values.origemId?.value,
      destinoId: values.destinoId?.value,
      centroCustoId: values.centroCustoId?.value
    })
  }

  const format = (initialValues: CotacoesQueryFilter) => {
    if (initialValues.createdAt) {
      return {
        ...initialValues,
        createdAt: dateFns.format(
          dateFns.parseISO(initialValues.createdAt.gt),
          'dd/MM/yyyy'
        )
      }
    }
    return initialValues
  }

  return (
    <Filter
      labels={{ clear: 'Limpar', find: 'Buscar' }}
      initialValues={format(initialValues)}
      onClear={clear}
      onSubmit={values => handleSubmitFilter({ ...values, page: 1 })}
      validate={yupValidation(schema)}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}>
          <TextField
            fullWidth
            name="codigo"
            label="Código"
            type="number"
            inputProps={{ min: 0 }}
            onKeyDown={handleKeyCodes}
            onPaste={e => handlePasteIntegerOnly(e, 0)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <RFFAsyncSearchInput
            name="centroCustoId"
            label="Centro de Custo"
            url="/centros-custo/ativos"
            labelField="descricao"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Select
            fullWidth
            label="Tipo Negociação"
            name="tipoNegociacao"
            items={tiposNegociacao}
            MenuProps={menuPropsMultipleSelect}
          />
        </Grid>
        <Grid item xs={12} sm={2}>
          <RFFAutocomplete
            label="Status"
            name="status"
            options={statusCotacaoOptions}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth type="text" name="produto" label="Produto" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField fullWidth name="cliente" label="Cliente" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RFFAsyncSearchInput
            name="origemId"
            label="Origem"
            url="/cidades"
            labelField="nome"
            joinStateAndCity
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <RFFAsyncSearchInput
            name="destinoId"
            label="Destino"
            url="/cidades"
            labelField="nome"
            joinStateAndCity
          />
        </Grid>
      </Grid>
    </Filter>
  )
}
