import {
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import {
  FieldArray,
  FieldArrayRenderProps,
  useField,
  useFormikContext
} from 'formik'
import { useEffect, useState } from 'react'
import { EstadosDetail } from '../modules/estados/types'
import { paisesRepository } from '../modules/paises/hooks/paisesRepository'
import CEPListener from '../modules/pessoas/master-detail/CEPListener'
import { atividadeFiscal } from '../modules/pessoas/types'
import MaskInput from './Formik/Forms/MaskInput'
import {
  LabelFormatValue,
  PureAsyncSearchInput
} from './Formik/Forms/PureAsyncSearchInput'
import SelectInput from './Formik/Forms/SelectInput'
import TextInput from './Formik/Forms/TextInput'

export type TipoEndereco = 'COMERCIAL' | 'RESIDENCIAL' | 'CORRESPONDENCIA'

export const tipoEndereco = [
  { value: 'COMERCIAL', label: 'Comercial' },
  { value: 'RESIDENCIAL', label: 'Residencial' },
  { value: 'CORRESPONDENCIA', label: 'Correspondência' }
]

interface EnderecosInputProps {
  name: string
}

interface Enderecos {
  paisId?: { name: string; value: string }
  estadoId: { name: string; value: string }
  logradouro: string
}

export default function EnderecoInput({ name }: EnderecosInputProps) {
  const [field, meta] = useField({ name })
  const [cepLoading, setCepLoading] = useState(false)
  const formik = useFormikContext<{
    tipo: string
    enderecos: Enderecos[]
  }>()
  const [brasilId, setBrasilId] = useState<string>()
  const hasEnderecos = !!field.value?.length

  function addEndereco(arrayHelpers: FieldArrayRenderProps) {
    return () => {
      arrayHelpers.push({
        tipo: '',
        cep: '',
        logradouro: '',
        bairro: '',
        complemento: '',
        numero: '',
        atividadeFiscal: '',
        inscricaoEstadual: '',
        estadoId: null,
        cidadeId: null
      })
    }
  }

  function deleteEndereco(index: number, arrayHelpers: FieldArrayRenderProps) {
    return () => {
      const pessoa = field.value[index]

      if (pessoa.id) {
        arrayHelpers.form.setFieldValue('deletedPessoas', [
          ...(arrayHelpers.form.values?.deletedPessoas || []),
          pessoa.id
        ])
      }

      arrayHelpers.remove(index)
    }
  }

  async function getBrasilId() {
    if (formik.values.tipo !== 'PE') {
      const res = await paisesRepository.fetch({ nome: 'BRASIL' })

      if (res.ok) {
        setBrasilId(res.data?.edges?.[0].node.id)
      }
    }
  }

  const formatLabelEstado = (
    data: { node: EstadosDetail } | EstadosDetail
  ): LabelFormatValue => {
    if ('node' in data) {
      const { nome, sigla } = data.node

      return {
        name: `${nome} - ${sigla}`,
        value: data.node.id
      }
    }
    const { nome, sigla } = data

    return {
      name: `${nome} - ${sigla}`,
      value: data.id
    }
  }

  useEffect(() => {
    getBrasilId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {hasEnderecos &&
            field.value.map((endereco: any, index: number) => {
              return (
                <Grid xs={12} item key={index}>
                  <CEPListener
                    cep={endereco.cep}
                    name={name}
                    index={index}
                    setCepLoading={setCepLoading}
                  />
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={12} sm={12} md={2}>
                      <SelectInput
                        name={`${name}.${index}.tipo`}
                        label="Tipo Endereço"
                        options={tipoEndereco}
                      />
                    </Grid>
                    {formik.values.tipo === 'PE' ? (
                      <Grid item xs={12} sm={12} md={3}>
                        <PureAsyncSearchInput
                          name={`${name}.${index}.paisId`}
                          label="País"
                          url="/pais"
                          labelField="nome"
                          paramSearch="nome"
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={12} md={3}>
                        <MaskInput
                          name={`${name}.${index}.cep`}
                          label="CEP"
                          mask="99999-999"
                          endAdornment={
                            cepLoading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null
                          }
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} sm={12} md={4}>
                      <TextInput
                        name={`${name}.${index}.logradouro`}
                        label="Logradouro"
                        InputLabelProps={
                          formik.values.enderecos[index].logradouro
                            ? { shrink: true }
                            : {}
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                      <TextInput
                        name={`${name}.${index}.bairro`}
                        label={
                          formik.values.tipo === 'PE'
                            ? 'Bairro (opcional)'
                            : 'Bairro'
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={formik.values.tipo === 'PF' ? 3 : 2}
                    >
                      <TextInput
                        name={`${name}.${index}.numero`}
                        label="Número"
                        type="text"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={formik.values.tipo === 'PF' ? 3 : 3}
                    >
                      <TextInput
                        name={`${name}.${index}.complemento`}
                        label="Complemento"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={formik.values.tipo === 'PF' ? 3 : 3}
                    >
                      <PureAsyncSearchInput
                        name={`${name}.${index}.estadoId`}
                        label="Estado"
                        url={`/pais/${
                          formik.values.enderecos[index].paisId?.value ??
                          brasilId
                        }/estados`}
                        labelFormat={formatLabelEstado}
                        paramSearch="nome"
                        disabled={
                          (formik.values.tipo === 'PE' &&
                            !formik.values.enderecos[index].paisId?.value) ||
                          !brasilId
                        }
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={formik.values.tipo === 'PF' ? 3 : 3}
                    >
                      <PureAsyncSearchInput
                        name={`${name}.${index}.cidadeId`}
                        label="Cidade"
                        url={`estados/${formik.values.enderecos[index].estadoId?.value}/cidades`}
                        labelField="nome"
                        paramSearch="nome"
                        disabled={
                          !formik.values.enderecos[index].estadoId?.value
                        }
                      />
                    </Grid>
                    {formik.values.tipo === 'PF' ? (
                      <>
                        <Grid item xs={12} sm={12} md={6}>
                          <TextInput
                            name={`${name}.${index}.inscricaoEstadual`}
                            label="Inscrição Estadual"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={5}>
                          <SelectInput
                            name={`${name}.${index}.atividadeFiscal`}
                            label="Atividade Fiscal"
                            options={
                              endereco.inscricaoEstadual.match('^[a-zA-Z]+$')
                                ? [atividadeFiscal[6]]
                                : atividadeFiscal
                            }
                          />
                        </Grid>
                      </>
                    ) : null}

                    <Grid
                      item
                      container
                      xs={12}
                      sm={12}
                      md={1}
                      justifyContent="center"
                    >
                      <IconButton onClick={deleteEndereco(index, arrayHelpers)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                </Grid>
              )
            })}

          <Grid xs={12} sm={12} md={12} item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={addEndereco(arrayHelpers)}
              data-cy="add-pessoa"
            >
              Adicionar Endereço
            </Button>

            <FormHelperText error>
              {!field.value?.length && meta.error}
            </FormHelperText>
          </Grid>
        </Grid>
      )}
    />
  )
}
