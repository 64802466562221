import { Grid, Typography } from '@material-ui/core'
import { useCallback, useEffect, useState } from 'react'
import { getAgrupadorProdutoFromLote } from 'src/modules/lotes/hooks/lotesRepository'
import AsyncSearchInput from '../../../components/Formik/Forms/AsyncSearchInput'
import { LoteProgramacaoFormData } from '../types/formData'

interface Props {
  lote: LoteProgramacaoFormData
}

export const Produto = ({ lote }: Props) => {
  const [agrupadorProdutoId, setAgrupadorProdutoId] = useState<
    string | undefined
  >()

  const getAgrupadorProdutoId = useCallback(async () => {
    const response = await getAgrupadorProdutoFromLote(lote.id)

    if (response.ok) {
      return setAgrupadorProdutoId(response.data?.id)
    }

    setAgrupadorProdutoId(undefined)
  }, [lote.id])

  const buildQuery = () => {
    if (lote.Produto) {
      return { id: lote.Produto.id }
    }

    return { agrupadorProdutosId: agrupadorProdutoId }
  }

  useEffect(() => {
    getAgrupadorProdutoId()
  }, [getAgrupadorProdutoId])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Produto</Typography>
      </Grid>
      <Grid item xs={6}>
        <AsyncSearchInput
          name={`lotes.${lote.id}.produtoId`}
          label="Produto"
          labelField="nome"
          url="/produtos"
          filters={buildQuery()}
        />
      </Grid>
    </Grid>
  )
}
