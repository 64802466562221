import { useSnackbar } from '@elentari/core'
import { useFormikContext } from 'formik'
import { useEffect, useState } from 'react'
import { getComposicaoKmmByCPF } from '../../hooks/programacaoRepository'
import programacoesStore from '../../store/store'

interface IMotorista {
  cpf: string
  nome: string
  telefone: string
}

interface IVeiculo {
  placa: string
  placaAntiga: string
}

interface IResponseComposicaoCPF {
  motorista: IMotorista
  veiculos: IVeiculo[]
}

export const CPFMotoristaListener = () => {
  const formik = useFormikContext<{ cpfMotorista: string }>()

  const [, snackbarActions] = useSnackbar()
  const [hasBeenChanged, setHasBeenChanged] = useState(false)

  function updateValues(
    motorista: string = '',
    telefoneMotorista: string = '',
    placaPrincipal: string = '',
    placa_1: string = '',
    placa_2: string = '',
    placa_3: string = ''
  ) {
    formik.setFieldValue('motorista', motorista)
    formik.setFieldValue('telefoneMotorista', telefoneMotorista)
    formik.setFieldValue('placaPrincipal', placaPrincipal)
    formik.setFieldValue('placa_1', placa_1)
    formik.setFieldValue('placa_2', placa_2)
    formik.setFieldValue('placa_3', placa_3)
  }

  async function fetchComposicaoByCPF(cpf: string) {
    programacoesStore.setFetchingComposicao(true)
    const response = await getComposicaoKmmByCPF(cpf)
    if (response.ok) {
      programacoesStore.setComposicaoImportada(true)
      const data = response.data as IResponseComposicaoCPF
      const placas = data.veiculos.map(({ placa }) => placa)
      updateValues(data.motorista.nome, data.motorista.telefone, ...placas)
    } else {
      programacoesStore.setComposicaoImportada(false)
      snackbarActions.setMessage(
        'Nenhuma composição encontrada com o CPF informado!'
      )
      updateValues()
    }
    programacoesStore.setFetchingComposicao(false)
  }

  useEffect(() => {
    if (
      formik.values.cpfMotorista !== formik.initialValues.cpfMotorista ||
      hasBeenChanged
    ) {
      setHasBeenChanged(true)

      if (formik.values.cpfMotorista?.length === 14) {
        const cpf = formik.values.cpfMotorista.replace(/[^0-9]/g, '')
        fetchComposicaoByCPF(cpf)
      }
    }
  }, [formik.values.cpfMotorista]) // eslint-disable-line

  return null
}
