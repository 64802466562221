import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  Typography
} from '@material-ui/core'
import { Form, Formik } from 'formik'
import NumericInput from 'src/components/Formik/Forms/NumericInput'
import * as yup from 'yup'

export interface AprovacaoFormValues {
  valorFreteFechado: number
}

interface Props {
  open: boolean
  title: string
  message: string
  handleClose: () => void
  primaryAction: (values: AprovacaoFormValues) => Promise<void>
  primaryActionButtonLabel: string
  closeActionButtonLabel: string
  yupSchema: yup.AnySchema
  initialValues: AprovacaoFormValues
}

export const AprovacaoDialog = ({
  yupSchema,
  initialValues,
  ...props
}: Props) => {
  return (
    <Dialog maxWidth="md" open={props.open}>
      <Formik
        enableReinitialize
        validationSchema={yupSchema}
        initialValues={{
          valorFreteFechado: initialValues.valorFreteFechado || ('' as any)
        }}
        onSubmit={props.primaryAction}
      >
        {({ isSubmitting, handleSubmit }) => {
          return (
            <Form onSubmit={handleSubmit} noValidate>
              <DialogTitle>{props.title}</DialogTitle>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Grid item xs={12}>
                      <NumericInput
                        name="valorFreteFechado"
                        label="Frete Fechado"
                        inputProps={{
                          startAdornment: (
                            <InputAdornment position="start">R$</InputAdornment>
                          )
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid container justifyContent="center" item xs={12}>
                    <Typography>{props.message}</Typography>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  disabled={isSubmitting}
                  onClick={props.handleClose}
                  variant="outlined"
                >
                  {props.closeActionButtonLabel}
                </Button>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  color="primary"
                  variant="contained"
                >
                  {isSubmitting ? (
                    <CircularProgress size={24} />
                  ) : (
                    props.primaryActionButtonLabel
                  )}
                </Button>
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}
