import { Box, Typography } from '@material-ui/core'
import { LotesDetail } from '../types'
import { InfoResumoQuantidadePrevista } from './InfoResumoQuantidadePrevista'

interface Props {
  lote: LotesDetail
}

const ResumoQuantidadePrevista = ({ lote }: Props) => {
  return (
    <Box display="flex" flexDirection="column" padding={1}>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        style={{ marginBottom: 16 }}
      >
        <Typography variant="h6" style={{ width: 550 }}>
          Resumo Quantidade Prevista por Entrega
        </Typography>
      </Box>
      <InfoResumoQuantidadePrevista lote={lote} />
    </Box>
  )
}

export default ResumoQuantidadePrevista
