import { makeUseFetchOne } from '@elentari/core'
import { negociacoesNaoFechadasRepository } from './negociacoesNaoFechadasRepository'
import { NegociacaoNaoFechadaDetail } from '../types'

export const useNegociacaoNaoFechada = makeUseFetchOne<
  NegociacaoNaoFechadaDetail,
  string
>({
  fetch: negociacoesNaoFechadasRepository.fetchOne
})
