import {
  isAfter,
  isBefore,
  isSameDay,
  startOfDay,
  startOfToday
} from 'date-fns'
import moment from 'moment'
import * as yup from 'yup'
import { MessagesYup } from '../../messages'
import { TipoContratacao } from '../types'
import { formatDateToTimeZone } from 'src/utils/formatters/formatDate'

const initialValues: any = {
  descricao: '',
  centroCustoId: '',
  tipoContratacao: 'SPOT',
  contrato: null,
  grupoNegociadorId: '',
  estadoOrigemId: null,
  origemId: null,
  destinos: [
    {
      estadoDestinoId: '',
      destinoId: '',
      localEntrega: ''
    }
  ],
  produtoId: '',
  tipoLote: '',
  quantidadeTotal: 0,
  segundaPerna: false,
  permiteTrocarNota: false,
  tipoNegociacaoEmpresa: '',
  freteEmpresa: 0,
  pedagio: false,
  tipoPedagio: '',
  valorPedagio: 0,
  aliquotaICMS: 0,
  icms: false,

  pernas: [
    {
      localCarregamento: '',
      estadoOrigemPerna: { name: '', value: '' },
      cidadeOrigemPerna: { name: '', value: '' },
      localEntrega: '',
      estadoDestinoPerna: { name: '', value: '' },
      cidadeDestinoPerna: { name: '', value: '' },
      freteEmpresa: 0,
      freteAgregado: 0,
      freteSubcontratadoPJ: 0,
      freteSubcontratadoPF: 0,
      valorGrupo: false,
      freteGrupo: 0,
      operacaoKMM: 0,
      valorPedagio: 0,
      aliquotaICMS: 0
    }
  ],

  tipoNegociacaoSubcontratado: '',
  freteAgregado: 0,
  freteGrupo: 0,
  freteSubcontratadoPJ: 0,
  freteSubcontratadoPF: 0,
  numeroPedido: '',
  dataInicio: formatDateToTimeZone(new Date()),
  dataTermino: formatDateToTimeZone(new Date()),
  cadencia: '',
  operacaoKMM: 0,
  observacao: '',
  localCarregamento: '',
  files: [],
  anexosCotacao: [],
  margemGrupo: 0,
  especie: ''
}

const requiredDateSchema = (schema: yup.DateSchema) =>
  schema
    .test(
      'dataTermino',
      'Selecione uma data válida!',
      date => !(Math.abs(moment().diff(moment(date), 'years')) >= 100)
    )
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .typeError(MessagesYup.MENSAGEM_DATA_INVALIDA)
    .required(MessagesYup.MENSAGEM_OBRIGATORIO)

const isQuantidadeGreaterThanQuantidadeRetirada = (
  value: Nullable<number> | undefined,
  context: yup.TestContext<Record<string, any>>
) => {
  // false quando state.tag !== 'with-data'
  const quantidadeRetirada = context.options.context?.quantidadeRetirada ?? 0

  if ((value ?? 0) < quantidadeRetirada) {
    return context.createError({
      message:
        // eslint-disable-next-line no-template-curly-in-string
        'A alteração da quantidade para esse valor faria o saldo do lote ficar negativo (valor mínimo para o campo: ${quantidadeRetirada})',
      params: { quantidadeRetirada }
    })
  }

  return true
}

const objectSchema = {
  descricao: yup
    .string()
    .trim()
    .optional()
    .nullable()
    .typeError(MessagesYup.MENSAGEM_CAMPO_STRING),
  centroCustoId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  tipoContratacao: yup
    .string()
    .trim()
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  grupoNegociadorId: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .required(MessagesYup.MENSAGEM_OBRIGATORIO),
  estadoOrigemId: yup.object().when('tipoContratacao', {
    is: (tipo: string) => {
      return tipo === TipoContratacao.CONTRATO
    },
    then: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    otherwise: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .optional()
  }),
  origemId: yup.object().when('tipoContratacao', {
    is: (tipo: string) => {
      return tipo === TipoContratacao.CONTRATO
    },
    then: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    otherwise: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .optional()
  }),
  destinos: yup.array().of(
    yup.object().shape({
      localEntrega: yup.string().nullable().optional(),
      estadoDestinoId: yup
        .object()
        .nullable()
        .shape({
          name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
          value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
        .optional(),
      destinoId: yup
        .object()
        .nullable()
        .shape({
          name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
          value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
        })
        .when('estadoDestinoId', {
          is: (estadoDestino: AsyncSearchInputType) => !!estadoDestino,
          then: schema => schema.required(MessagesYup.MENSAGEM_OBRIGATORIO),
          otherwise: schema => schema.notRequired()
        })
    })
  ),
  contrato: yup.object().when('tipoContratacao', {
    is: (tipo: string) => {
      return tipo === 'CONTRATO'
    },
    then: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    otherwise: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .optional()
  }),
  tabela: yup
    .object()
    .nullable()
    .shape({
      name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
    .when('tipoContratacao', {
      is: (tipo: string) => {
        return tipo === 'CONTRATO'
      },
      then: schema =>
        schema.nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: schema => schema.optional()
    }),
  produtoId: yup.object().when('tipoContratacao', {
    is: (tipo: string) => {
      return tipo === TipoContratacao.SPOT
    },
    then: yup
      .object()
      .nullable()
      .shape({
        name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
        value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
      .required(MessagesYup.MENSAGEM_OBRIGATORIO),
    otherwise: yup
      .object()
      .nullable()
      .shape({
        name: yup.string(),
        value: yup.string()
      })
      .optional()
  }),
  tipoLote: yup.string().trim().required(MessagesYup.MENSAGEM_OBRIGATORIO),
  especie: yup.string().trim().optional().nullable(),
  quantidadeTotal: yup
    .number()
    .nullable()
    .transform((curr, orig) => (orig === '' ? null : curr))
    .when('tipoLote', {
      is: 'QUANTIDADE',
      then: schema =>
        schema
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .test({
            name: 'is-quantidade-greater-than-quantidade-retirada',
            test: isQuantidadeGreaterThanQuantidadeRetirada
          })
    })
    .when('tipoLote', {
      is: 'VIAGEM',
      then: schema =>
        schema
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .test({
            name: 'is-quantidade-greater-than-quantidade-retirada',
            test: isQuantidadeGreaterThanQuantidadeRetirada
          })
    }),
  tipoNegociacaoEmpresa: yup
    .string()
    .trim()
    .when('tipoContratacao', {
      is: (tipo: string) => {
        return tipo === 'SPOT'
      },
      then: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable().optional()
    }),
  tipoNegociacaoSubcontratado: yup
    .string()
    .trim()
    .when('tipoContratacao', {
      is: (tipo: string) => {
        return tipo === 'SPOT'
      },
      then: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable().optional()
    }),
  tipoPedagio: yup
    .string()
    .trim()
    .when(['pedagio', 'tipoContratacao'], {
      is: (pedagio: boolean, tipoContratacao: string) =>
        pedagio && tipoContratacao === 'SPOT',
      then: yup.string().nullable().required(MessagesYup.MENSAGEM_OBRIGATORIO),
      otherwise: yup.string().nullable()
    }),
  numeroPedido: yup.string().trim().optional(),
  dataInicio: requiredDateSchema(
    yup.date().test({
      name: 'data-inicio-after-data-termino',
      message: 'Data início não pode ser posterior à data término.',
      test: (dataInicio, context) =>
        dataInicio && context.parent.dataTermino
          ? isBefore(
              startOfDay(dataInicio),
              startOfDay(context.parent.dataTermino)
            ) || isSameDay(dataInicio, context.parent.dataTermino)
          : true
    })
  ).when('tipoContratacao', {
    is: (tipo: string) => {
      return tipo === 'CONTRATO'
    },
    then: schema =>
      schema
        .test({
          name: 'data-inicio-after-inicio-contrato',
          message: 'Data não pode ser anterior à data inicial de contrato.',
          test: (dataInicio, context) => {
            if (context.parent.Contrato && dataInicio) {
              const dataInicioContrato = new Date(
                context.parent.Contrato.dataInicio
              )

              return (
                isAfter(
                  startOfDay(dataInicio),
                  startOfDay(dataInicioContrato)
                ) || isSameDay(dataInicio, dataInicioContrato)
              )
            }

            return true
          }
        })
        .test({
          name: 'data-inicio-before-termino-contrato',
          message: 'Data não pode ser posterior à data de término de contrato',
          test: (dataInicio, context) => {
            if (context.parent.Contrato && dataInicio) {
              const dataTerminoContrato = new Date(
                context.parent.Contrato.dataTermino
              )

              return (
                isBefore(
                  startOfDay(dataInicio),
                  startOfDay(dataTerminoContrato)
                ) || isSameDay(dataInicio, dataTerminoContrato)
              )
            }

            return true
          }
        })
  }),
  dataTermino: requiredDateSchema(
    yup.date().test({
      message:
        'Data término não pode ser anterior à data início ou anterior à data atual.',
      test: (dataTermino, context) =>
        dataTermino && context.parent.dataTermino
          ? (isAfter(
              startOfDay(dataTermino),
              startOfDay(context.parent.dataInicio)
            ) ||
              isSameDay(dataTermino, context.parent.dataInicio)) &&
            !isBefore(startOfDay(dataTermino), startOfToday())
          : true
    })
  ).when('tipoContratacao', {
    is: (tipo: string) => {
      return tipo === 'CONTRATO'
    },
    then: schema =>
      schema
        .test({
          name: 'data-termino-after-inicio-contrato',
          message: 'Data deve ser posterior à data de início do contrato.',
          test: (dataTermino, context) => {
            if (context.parent.Contrato && dataTermino) {
              const dataInicioContrato = new Date(
                context.parent.Contrato.dataInicio
              )

              return (
                isAfter(
                  startOfDay(dataTermino),
                  startOfDay(dataInicioContrato)
                ) || isSameDay(dataTermino, dataInicioContrato)
              )
            }

            return true
          }
        })
        .test({
          name: 'data-termino-before-termino-contrato',
          message: 'Data deve ser anterior à data de fim do contrato.',
          test: (dataTermino, context) => {
            if (context.parent.Contrato && dataTermino) {
              const dataTerminoContrato = new Date(
                context.parent.Contrato.dataTermino
              )

              return (
                isBefore(
                  startOfDay(dataTermino),
                  startOfDay(dataTerminoContrato)
                ) || isSameDay(dataTermino, dataTerminoContrato)
              )
            }

            return true
          }
        })
  }),
  files: yup.array(yup.object().shape({ id: yup.string() })).optional(),
  anexosCotacao: yup.array(yup.object().shape({ id: yup.string() })).optional(),
  diasParaCarregar: yup.number().typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
  diasParaDescarregar: yup
    .number()
    .typeError(MessagesYup.MENSAGEM_TIPO_NUMERICO),
  pernas: yup.array().when('tipoContratacao', {
    is: (tipo: string) => tipo === 'SPOT',
    then: yup.array(
      yup.object().shape({
        estadoOrigemPerna: yup
          .object()
          .nullable()
          .shape({
            name: yup
              .string()
              .test(
                '',
                MessagesYup.MENSAGEM_OBRIGATORIO,
                function (value, context) {
                  const index = Number(context.path.match(/\d+/)?.[0])
                  if (index > 0) {
                    return true
                  }
                  if (!value) {
                    return this.createError({
                      path: context.path.split('.').slice(0, 2).join('.'),
                      message: MessagesYup.MENSAGEM_OBRIGATORIO
                    })
                  }
                  return true
                }
              ),
            value: yup
              .string()
              .test(
                '',
                MessagesYup.MENSAGEM_OBRIGATORIO,
                function (value, context) {
                  const index = Number(context.path.match(/\d+/)?.[0])
                  if (index > 0) {
                    return true
                  }
                  if (!value) {
                    return this.createError({
                      path: context.path.split('.').slice(0, 2).join('.'),
                      message: MessagesYup.MENSAGEM_OBRIGATORIO
                    })
                  }
                  return true
                }
              )
          }),
        // .test(
        //   'estado-origem-perna-teste',
        //   MessagesYup.MENSAGEM_OBRIGATORIO,
        //   (value, context) => {
        //     const options = context.options as any
        //     if (options.index > 0) {
        //       return true
        //     }
        //     return value?.value !== null
        //   }
        // ),
        // .required(MessagesYup.MENSAGEM_OBRIGATORIO),
        cidadeOrigemPerna: yup
          .object()
          .nullable()
          .shape({
            name: yup
              .string()
              .test(
                '',
                MessagesYup.MENSAGEM_OBRIGATORIO,
                function (value, context) {
                  const index = Number(context.path.match(/\d+/)?.[0])
                  if (index > 0) {
                    return true
                  }
                  if (!value) {
                    return this.createError({
                      path: context.path.split('.').slice(0, 2).join('.'),
                      message: MessagesYup.MENSAGEM_OBRIGATORIO
                    })
                  }
                  return true
                }
              ),
            value: yup
              .string()
              .test(
                '',
                MessagesYup.MENSAGEM_OBRIGATORIO,
                function (value, context) {
                  const index = Number(context.path.match(/\d+/)?.[0])
                  if (index > 0) {
                    return true
                  }
                  if (!value) {
                    return this.createError({
                      path: context.path.split('.').slice(0, 2).join('.'),
                      message: MessagesYup.MENSAGEM_OBRIGATORIO
                    })
                  }
                  return true
                }
              )
          }),
        // .test(
        //   'cidade-origem-perna-teste',
        //   MessagesYup.MENSAGEM_OBRIGATORIO,
        //   (value, context) => {
        //     const options = context.options as any
        //     if (options.index > 0) {
        //       return true
        //     }
        //     return value?.value !== null
        //   }
        // ),
        // .required(MessagesYup.MENSAGEM_OBRIGATORIO),
        estadoDestinoPerna: yup
          .object()
          .nullable()
          .shape({
            name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
            value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
          })
          .required(MessagesYup.MENSAGEM_OBRIGATORIO),
        cidadeDestinoPerna: yup
          .object()
          .nullable()
          .shape({
            name: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO),
            value: yup.string().required(MessagesYup.MENSAGEM_OBRIGATORIO)
          })
          .required(MessagesYup.MENSAGEM_OBRIGATORIO),
        freteEmpresa: yup
          .number()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .moreThan(0, 'Frete Empresa deve ser maior que 0')
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
        operacaoKMM: yup
          .number()
          .transform((curr, orig) => (orig === '' ? null : curr))
          .nullable()
          .optional(),
        valorPedagio: yup.mixed().when('pedagio', {
          is: true,
          then: yup
            .number()
            .nullable()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
            .moreThan(0, 'Valor Pedágio deve ser maior que 0')
        }),
        aliquotaICMS: yup.mixed().when('$icms', {
          is: true,
          then: yup
            .number()
            .nullable()
            .required(MessagesYup.MENSAGEM_OBRIGATORIO)
        }),
        freteAgregado: yup
          .number()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .moreThan(0, 'Frete Agregado deve ser maior que 0')
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
        freteGrupo: yup
          .number()
          .nullable()
          .when('valorGrupo', {
            is: true,
            then: yup
              .number()
              .required(MessagesYup.MENSAGEM_OBRIGATORIO)
              .moreThan(0, 'Frete Grupo deve ser maior que 0')
              .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
          }),
        freteSubcontratadoPJ: yup
          .number()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .moreThan(0, 'Frete Subcontratado PJ deve ser maior que 0')
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO),
        freteSubcontratadoPF: yup
          .number()
          .required(MessagesYup.MENSAGEM_OBRIGATORIO)
          .moreThan(0, 'Frete Subcontratado PF deve ser maior que 0')
          .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
      })
    )
  })
}
export { initialValues, objectSchema, requiredDateSchema }
