import { useSort } from '@elentari/core'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { IoIosArrowDown } from 'react-icons/io'

const CustomTableCell = withStyles(() => ({
  root: {
    color: '',
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'left',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}))(TableCell)

interface TableCellHeadProps {
  label: string
  sort?: string
  className?: any
  children?: any
  key?: any
}

/**
 * @param label word to show in the column
 * @param sort word that will go in the URL for API
 */

export function TableCellHead({
  label,
  sort,
  className,
  children
}: TableCellHeadProps) {
  const [state, { sortBy }] = useSort()

  return (
    <CustomTableCell className={className}>
      {sort ? (
        <TableSortLabel
          IconComponent={IoIosArrowDown}
          direction={state.direction}
          onClick={() => sortBy(sort)}
        >
          {label}
        </TableSortLabel>
      ) : (
        label
      )}
      {!sort && (
        <span
          style={{
            paddingLeft: '4px'
          }}
        ></span>
      )}
      {children}
    </CustomTableCell>
  )
}
