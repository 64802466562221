import { ICreateRelatorioAdapter } from '../adapters/CreateRelatorioAdapter.interface'
import { CreateRelatorioLoteAdapter } from '../adapters/CreateRelatorioLote'
import { CreateRelatorioProgramacaoAdapter } from '../adapters/CreateRelatorioProgramacao'
import { LoteConfig } from '../components/lote/LoteConfig'
import { LoteFilters } from '../components/lote/LoteFilters'
import { ProgramacaoConfig } from '../components/programacao/ProgramacaoConfig'
import { ProgramacaoFilters } from '../components/programacao/ProgramacaoFilters'
import { TipoRelatorioInterno } from '../types'

export const filterSwitch: Record<TipoRelatorioInterno, () => JSX.Element> = {
  LOTE: LoteFilters,
  PROGRAMACAO: ProgramacaoFilters
}

export const configSwitch: Record<TipoRelatorioInterno, () => JSX.Element> = {
  LOTE: LoteConfig,
  PROGRAMACAO: ProgramacaoConfig
}

export const adapterSwitch: Record<
  TipoRelatorioInterno,
  ICreateRelatorioAdapter<any, any>
> = {
  LOTE: new CreateRelatorioLoteAdapter(),
  PROGRAMACAO: new CreateRelatorioProgramacaoAdapter()
}
