import { Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import DestinosInput from 'src/components/DestinoInput'
import { PureAsyncSearchInput } from 'src/components/Formik/Forms/PureAsyncSearchInput'
import TextInput from 'src/components/Formik/Forms/TextInput'
import { LotesFormData } from '../types'
import EstadosAsyncSearchInput from './EstadosAsyncSearchInput'

export const OrigemDestinoContrato = () => {
  const { values } = useFormikContext<LotesFormData>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <TextInput
          name="localCarregamento"
          label="Local de Carregamento (opcional)"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <EstadosAsyncSearchInput
          name="estadoOrigemId"
          label="Estado Origem"
          url="/estados/minimal"
          paramSearch="nome"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PureAsyncSearchInput
          name="origemId"
          label="Cidade Origem"
          url={`/estados/${values.estadoOrigemId?.value}/cidades`}
          labelField="nome"
          paramSearch="nome"
          disabled={!values.estadoOrigemId?.value}
        />
      </Grid>
      <Grid item xs={12} sm={12}>
        <DestinosInput name="destinos" />
      </Grid>
    </Grid>
  )
}
