import { useField } from 'formik'
import { isNil } from 'ramda'
import CurrencyField, { CurrencyFieldProps } from './Inputs/CurrencyField'

interface NumericInputProps extends CurrencyFieldProps {
  name: string
}

function NumericInput(props: NumericInputProps) {
  const [field, meta] = useField(props.name)

  return (
    <CurrencyField
      {...field}
      {...props}
      fullWidth
      variant="outlined"
      color="primary"
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched ? meta.error : undefined}
      InputLabelProps={
        !isNil(meta.value) && field.value !== '' ? { shrink: true } : {}
      }
    />
  )
}

export default NumericInput
