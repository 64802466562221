import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'
import { useRef } from 'react'
import ReactToPrint from 'react-to-print'
import When from 'src/components/When'
import { useDefaultGoBack } from 'src/hooks/useDefaultGoBack'
import { LoteList } from 'src/modules/lotes/types'
import { useAutorizacaoCarregamento } from '../../hooks/useAutorizacaoCarregamento'
import { useProgramacao } from '../../hooks/useProgramacao'
import { LoteProgramacaoFormData } from '../../types/formData'
import AutorizacaoCarregamentoPrint from '../autorizacao-carregamento/AutorizacaoCarregamentoPrint'
import userStore from 'src/stores/user'

interface ModalDesejaImprimirAutorizacaoProps {
  open: boolean
  lotes: LoteProgramacaoFormData[] | LoteList[]
}

function ModalDesejaImprimirAutorizacao({
  open,
  lotes
}: ModalDesejaImprimirAutorizacaoProps) {
  const goBack = useDefaultGoBack()

  const [programacaoState] = useProgramacao()
  const printRef = useRef(null)
  const {
    hasPrintAuthorization,
    configEstadias,
    configJudridico,
    configValidade,
    loadingConfigs,
    createAutorizacaoCarregamentoLog
  } = useAutorizacaoCarregamento()

  const handleCreateAutorizacaoCarregamentoLog = () => {
    createAutorizacaoCarregamentoLog(
      programacaoState,
      lotes,
      userStore.getUsername()
    )
  }

  return programacaoState.tag === 'with-data' ? (
    <When value={hasPrintAuthorization} equals>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Autorização de Carregamento
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Deseja imprimir a Autorização de Carregamento?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={goBack} color="default">
            Voltar à listagem
          </Button>
          <ReactToPrint
            documentTitle={`Autorização de Carregamento - Programação #${programacaoState.entity.codigo}`}
            trigger={() => (
              <Button color="primary" disabled={loadingConfigs}>
                Sim
              </Button>
            )}
            onAfterPrint={handleCreateAutorizacaoCarregamentoLog}
            content={() => printRef.current}
          />
          {hasPrintAuthorization && (
            <div hidden>
              <div ref={printRef}>
                <AutorizacaoCarregamentoPrint
                  programacao={programacaoState.entity}
                  configJudridico={configJudridico}
                  configEstadias={configEstadias}
                  configValidade={configValidade}
                />
              </div>
            </div>
          )}
        </DialogActions>
      </Dialog>
    </When>
  ) : null
}

export { ModalDesejaImprimirAutorizacao }
