import { makeAutoObservable } from 'mobx'
import ApisauceDataStore from 'src/packages/fetchx/ApisauceDataStore'
import { apiSauceInstance } from 'src/services/api'
import { PaisDetail } from '../types'

class PaisesStore {
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true })
  }

  paises = new ApisauceDataStore<PaisDetail>(apiSauceInstance, {
    useNodes: true,
    path: '/pais',
    resultsField: 'edges',
    countField: 'totalCount'
  })
}

export const paisesStore = new PaisesStore()
export default PaisesStore
