import { useQueryParams } from '@elentari/core'
import { useAbility } from '../login'
import { RelatorioInternoQueryFilter } from './types'
import { getAuthorizations } from 'src/utils/crudAuthorization'
import { useEffect } from 'react'
import relatorioInternoStore from './stores'
import { RelatorioInternoFilter } from './RelatorioInternoFilter'
import { RelatorioInternoTable } from './RelatorioInternoTable'

const RelatoriosInternos = () => {
  const ability = useAbility()
  const { initialValues } = useQueryParams<RelatorioInternoQueryFilter>()
  const authorization = getAuthorizations(ability, 'Relatórios Internos')

  const fetch = () => {
    relatorioInternoStore.relatorios.setPage(Number(initialValues.page) || 1)
    relatorioInternoStore.relatorios.setFilters(initialValues)
  }

  useEffect(() => {
    fetch()
  }, [initialValues]) // eslint-disable-line

  useEffect(() => {
    const interval = setInterval(fetch, 15_000)

    return () => {
      clearInterval(interval)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <RelatorioInternoFilter />
      <RelatorioInternoTable authorization={authorization} />
    </>
  )
}

export default RelatoriosInternos
