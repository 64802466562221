import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(themes => ({
  backdrop: {
    zIndex: themes.zIndex.drawer + 1,
    color: '#fff',
    position: 'absolute'
  }
}))

export const LoadingPage = ({ open }: { open: boolean }) => {
  const classes = useStyles()

  return (
    <Backdrop invisible className={classes.backdrop} open={open}>
      <CircularProgress />
    </Backdrop>
  )
}
