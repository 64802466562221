import { ReactNode, useEffect } from 'react'
import { Route, useHistory } from 'react-router-dom'
import { Item, ItemOnly } from './AppWrap/MenuItems'

type RouterByMenuProps = {
  menuItems: Item[]
  children?: ReactNode
}

export const RouteByMenu = ({ menuItems, children }: RouterByMenuProps) => {
  const history = useHistory()

  const findAndSetTitle = (itemArray: ItemOnly[], pathname: string) => {
    const item = itemArray.find(path => path.pathname === `/${pathname}`)

    document.title = item?.label
      ? `G10 Comercial - ${item?.label}`
      : 'G10 Comercial'
  }

  useEffect(() => {
    const pathname = history.location.pathname.split('/')[1]

    const allItems = menuItems.flatMap(item => (item.group ? item.items : item))

    findAndSetTitle(allItems, pathname)
  }, [history.location.pathname, menuItems])
  return (
    <>
      {menuItems.map(item => (
        <div key={item.name}>
          {item.group === false ? (
            <>
              {item.list && (
                <Route path={item.pathname} exact component={item.list} />
              )}
              {item.detail && (
                <Route path={`${item.pathname}/:id`} component={item.detail} />
              )}
            </>
          ) : (
            item.items.map(subItem => (
              <div key={subItem.name}>
                {subItem.list && (
                  <Route
                    path={subItem.pathname}
                    exact
                    component={subItem.list}
                  />
                )}
                {subItem.detail && (
                  <Route
                    path={`${subItem.pathname}/:id`}
                    component={subItem.detail}
                  />
                )}
              </div>
            ))
          )}
        </div>
      ))}
      {children}
    </>
  )
}
