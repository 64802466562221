import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import { TableCellHead } from './TableCellHead'

interface Props {
  loading?: boolean
}

const NoResultsTable = ({ loading = false }: Props) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCellHead label="" />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell align="center">
            {loading ? '' : 'Sem resultados'}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  )
}

export default NoResultsTable
