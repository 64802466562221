import { createFromXlsxResponse } from 'src/components/LeituraPlanilhaDialog/types'
import { apiSauceInstance } from 'src/services/api'

export const createFromXlsx = async (id: string) => {
  return apiSauceInstance.post<
    createFromXlsxResponse & { message: string; error: any }
  >(`/cotacoes/create-from-xlsx`, {
    id
  })
}
