import { Divider, Grid, Typography } from '@material-ui/core'
import { Fragment } from 'react'
import { ConsultaField, ConsultaFieldNode } from 'src/components/ConsultaField'
import { tiposNegociacao } from 'src/components/Formik/Forms/Inputs/TipoNegociacaoEmpresaSelectInput'
import { formatDate, formatNumber } from 'src/utils/formatters'
import { formatProdutoLote } from 'src/utils/formatters/formatProdutoLote'
import { formatValorLote } from 'src/utils/formatters/formatValorLote'
import FilesList from '../../../components/FilesList'
import { IntegracaoCarguero } from '../components/IntegracaoCarguero'
import { IntegracaoFreto } from '../components/IntegracaoFreto'
import { IntegracaoTrato } from '../components/IntegracaoTrato'
import { PrevisaoMargem } from '../components/PrevisaoMargem'
import { useLote } from '../hooks/useLote'
import { Perna, statusFormatted } from '../types'
import { AvisosGrupoNegociador } from '../components/AvisosGrupoNegociador'

const PernasInfo = ({ pernas }: { pernas: Perna[] }) => {
  return (
    <Grid container spacing={2}>
      {pernas.map((perna, index) => (
        <Fragment key={perna.id}>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              {`${index + 1}ª Perna`}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <ConsultaField
              title="FRETE EMPRESA"
              subtitle={formatValorLote(perna.freteEmpresa || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <ConsultaField
              title="FRETE AGREGADO"
              subtitle={formatValorLote(perna.freteAgregado || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <ConsultaField
              title="FRETE GRUPO"
              subtitle={formatValorLote(perna.freteGrupo || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <ConsultaField
              title="FRETE SUBCONTRATADO PJ"
              subtitle={formatValorLote(perna.freteSubcontratadoPJ || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <ConsultaField
              title="FRETE SUBCONTRATADO PF"
              subtitle={formatValorLote(perna.freteSubcontratadoPF || 0)}
            />
          </Grid>
          {pernas.length > 1 && (
            <Grid item xs={4}>
              <ConsultaField
                title="OPERAÇÃO KMM"
                subtitle={perna.operacaoKMM || 0}
              />
            </Grid>
          )}
          <Grid item xs={4}>
            <ConsultaField
              title="VALOR DO PEDÁGIO"
              subtitle={formatValorLote(perna.valorPedagio || 0)}
            />
          </Grid>
          <Grid item xs={4}>
            <ConsultaField
              title="ALÍQUOTA ICMS (%)"
              subtitle={formatNumber(perna.aliquotaICMS || 0, 2)}
            />
          </Grid>
        </Fragment>
      ))}
    </Grid>
  )
}

export const TabLote = () => {
  const [loteState] = useLote()

  const handleResumo = (pedagio: boolean, icms: boolean) => {
    if (pedagio === false && icms === false) {
      return 'Frete'
    }

    if (pedagio === true && icms === false) {
      return 'Frete + Pedágio'
    }

    if (icms === true && pedagio === false) {
      return 'Frete + ICMS'
    }

    return 'Frete + Pedágio + ICMS'
  }

  const handleEnum = (value: string) => {
    if (value) {
      const valueFormatted = value.replace('_', ' ')
      return valueFormatted
    }
    return 'N/A'
  }

  const getDescricaoTipoNegociacaoEmpresa = () => {
    if (loteState.tag === 'with-data') {
      const tipoNegociacao: string | undefined =
        loteState.entity.tipoNegociacaoEmpresa ||
        loteState.entity.Tabela?.tipoNegociacaoEmpresa

      if (!tipoNegociacao) {
        return 'N/A'
      }

      return tiposNegociacao.find(tipo => tipo.value === tipoNegociacao)?.label
    }

    return 'N/A'
  }

  return loteState.tag === 'with-data' ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Dados do Lote
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="CENTRO DE CUSTO"
          subtitle={loteState.entity.CentroCusto.descricao}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="TIPO DE CONTRATAÇÃO"
          subtitle={loteState.entity.tipoContratacao}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="CRIADO POR"
          subtitle={loteState.entity.emailCriador?.split('@')[0]}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="STATUS"
          subtitle={
            statusFormatted[loteState.entity.status] || loteState.entity.status
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Dados do Cliente
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="GRUPO NEGOCIADOR"
          subtitle={
            loteState.entity.GrupoNegociador?.nome ??
            loteState.entity.Tabela?.Contrato.GrupoNegociador.nome
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="TIPO DE LOTE"
          subtitle={loteState.entity.tipoLote}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="PRODUTO"
          subtitle={formatProdutoLote(loteState.entity.Produto, 'N/A')}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="QUANTIDADE TOTAL"
          subtitle={loteState.entity.quantidadeTotal || 'N/A'}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="DATA INÍCIO RETIRADA"
          subtitle={formatDate(loteState.entity.dataInicio)}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="DATA LIMITE RETIRADA"
          subtitle={formatDate(loteState.entity.dataTermino)}
        />
      </Grid>
      <Grid item xs={12}>
        <ConsultaField
          title="ORIGEM"
          subtitle={`${loteState.entity.localCarregamento || 'N/A'}, ${
            loteState.entity.Origem.nome
          }-${loteState.entity.Origem.Estado.sigla}`}
        />
      </Grid>
      {loteState.entity.DestinoLote?.length ? (
        loteState.entity.DestinoLote?.map((destino, index) => (
          <Grid item xs={6} key={index}>
            <ConsultaField
              title={
                loteState.entity.DestinoLote.length > 1
                  ? `${index + 1}° DESTINO`
                  : 'DESTINO'
              }
              subtitle={`${destino.localDescarregamento || 'N/A'}, ${
                destino.Cidade.nome
              }-${destino.Cidade.Estado.sigla}`}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <ConsultaField title={'DESTINO'} subtitle={'N/A'} />
        </Grid>
      )}
      <Grid item xs={12}>
        <ConsultaField
          title="POSSUI TROCA DE NOTA"
          subtitle={loteState.entity.segundaPerna ? 'SIM' : 'NÃO'}
        />
      </Grid>
      {loteState.entity.segundaPerna === true && loteState.entity.Pernas ? (
        <Grid item xs={12}>
          <ConsultaField
            title="Roteiro"
            subtitle={[
              `${loteState.entity.Pernas[0].CidadeOrigem.nome}-${loteState.entity.Pernas[0].CidadeOrigem.Estado.sigla}`,
              ...loteState.entity.Pernas.map(
                perna =>
                  `${perna.CidadeDestino.nome}-${perna.CidadeDestino.Estado.sigla}`
              )
            ].join(' → ')}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Dados da Negociação
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <ConsultaField
          title="RESUMO"
          subtitle={handleResumo(
            loteState.entity.pedagio,
            loteState.entity.icms
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <ConsultaFieldNode
          title="AVISOS"
          node={
            <AvisosGrupoNegociador
              avisos={loteState.entity.GrupoNegociador!.Avisos}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        {loteState.entity.Pernas.length >= 1 &&
          loteState.entity.segundaPerna && (
            <PernasInfo pernas={loteState.entity.Pernas} />
          )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Total da Negociação
        </Typography>
      </Grid>
      {loteState.entity.tipoContratacao === 'CONTRATO' ? (
        <>
          <Grid item xs={6}>
            <ConsultaField
              title="ID CONTRATO"
              subtitle={loteState.entity.Tabela?.Contrato.codigo}
            />
          </Grid>
          <Grid item xs={6}>
            <ConsultaField
              title="DESCRIÇÃO CONTRATO"
              subtitle={loteState.entity.Tabela?.Contrato.descricao}
            />
          </Grid>
          <Grid item xs={12}>
            <ConsultaField
              title="DESCRIÇÃO TABELA"
              subtitle={loteState.entity.Tabela?.descricao}
            />
          </Grid>
        </>
      ) : null}
      <Grid item xs={6}>
        <ConsultaField
          title="TIPO NEGOCIAÇÃO EMPRESA"
          subtitle={getDescricaoTipoNegociacaoEmpresa()}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="FRETE EMPRESA"
          subtitle={formatValorLote(loteState.entity.freteEmpresa, 'N/A')}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="FRETE + PEDÁGIO?"
          subtitle={
            loteState.entity.pedagio || loteState.entity.Tabela?.pedagio
              ? 'SIM'
              : 'NÃO'
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="FRETE + ICMS?"
          subtitle={
            loteState.entity.icms || loteState.entity.Tabela?.icms
              ? 'SIM'
              : 'NÃO'
          }
        />
      </Grid>
      {loteState.entity.tipoContratacao === 'CONTRATO' &&
      loteState.entity.Tabela?.tipoPedagio ? (
        <Grid item xs={6}>
          <ConsultaField
            title="TIPO DE PEDÁGIO"
            subtitle={handleEnum(loteState.entity.Tabela.tipoPedagio)}
          />
        </Grid>
      ) : null}
      <Grid item xs={6} sm={3}>
        <ConsultaField
          title="TIPO DE PEDÁGIO"
          subtitle={handleEnum(loteState.entity.tipoPedagio)}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <ConsultaField
          title="VALOR DO PEDÁGIO"
          subtitle={formatValorLote(loteState.entity.valorPedagio, 'N/A')}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <ConsultaField
          title="ALÍQUOTA ICMS (%)"
          subtitle={loteState.entity.aliquotaICMS?.toFixed(2) || 'N/A'}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <Typography style={{ color: 'gray' }} variant={'caption'}>
          PREVISÃO MARGEM
        </Typography>
        <PrevisaoMargem
          previsaoMargem={loteState.entity.previsaoMargem}
          color={loteState.entity.previsaoMargemColor}
        />
      </Grid>
      <Grid item xs={12}>
        <ConsultaField
          title="TIPO NEGOCIAÇÃO SUBCONTRATADO"
          subtitle={handleEnum(loteState.entity.tipoNegociacaoSubcontratado)}
        />
      </Grid>
      <Grid item xs={3}>
        <ConsultaField
          title="FRETE AGREGADO"
          subtitle={formatValorLote(loteState.entity.freteAgregado, 'N/A')}
        />
      </Grid>
      <Grid item xs={3}>
        <ConsultaField
          title="FRETE GRUPO"
          subtitle={
            loteState.entity.freteGrupo
              ? `${formatValorLote(
                  loteState.entity.freteGrupo,
                  'N/A'
                )} (${formatNumber(loteState.entity.margemGrupo, 2)}%)`
              : '3%'
          }
        />
      </Grid>
      <Grid item xs={3}>
        <ConsultaField
          title="FRETE SUBCONTRATADO PJ"
          subtitle={formatValorLote(
            loteState.entity.freteSubcontratadoPJ,
            'N/A'
          )}
        />
      </Grid>
      <Grid item xs={3}>
        <ConsultaField
          title="FRETE SUBCONTRATADO PF"
          subtitle={formatValorLote(
            loteState.entity.freteSubcontratadoPF,
            'N/A'
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5" style={{ fontWeight: 'bold' }}>
          Dados Adicionais
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="NÚMERO DO PEDIDO"
          subtitle={loteState.entity.numeroPedido || 'N/A'}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="CADÊNCIA PARA RETIRADA"
          subtitle={loteState.entity.cadencia || 'N/A'}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="OPERAÇÃO KMM"
          subtitle={
            loteState.entity.operacaoKMM ? loteState.entity.operacaoKMM : 'N/A'
          }
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="OBSERVAÇÃO"
          subtitle={loteState.entity.observacao || 'N/A'}
        />
      </Grid>
      <Grid item xs={6}>
        <ConsultaField
          title="ANALISTA RESPONSÁVEL"
          subtitle={loteState.entity.analistaResponsavel || 'N/A'}
        />
      </Grid>

      {loteState.entity.status === 'ATIVO' && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              Integrações Apps
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <IntegracaoFreto />
          </Grid>
          <Grid item xs={12} sm={3}>
            <IntegracaoCarguero />
          </Grid>
          <Grid item xs={12} sm={6}>
            <IntegracaoTrato />
          </Grid>
        </Grid>
      )}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <FilesList anexos={loteState.entity.Anexos} />
    </Grid>
  ) : null
}
