import {
  FormControl,
  FormHelperText,
  InputBaseComponentProps,
  InputLabel,
  InputLabelProps,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core'
import { useField } from 'formik'

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120
  },
  styledDisabled: {
    '&.Mui-disabled': {
      color: theme.palette.action.disabled
    }
  },
  disabled: {
    color: theme.palette.action.disabled
  }
}))

export interface SelectOption<T = number | string> {
  value: T
  label: string
}

interface SelectInputProps {
  label: string
  name: string
  options: SelectOption[]
  disabled?: boolean
  defaultValue?: string
  value?: any
  InputProps?: InputBaseComponentProps
  InputLabelProps?: InputLabelProps
  onChange?: any
  isConsulting?: boolean
  clearable?: boolean
}

function SelectInput(props: SelectInputProps) {
  const classes = useStyles()
  const [field, meta] = useField(props)
  const { label, name, options, defaultValue, disabled } = props

  return (
    <FormControl
      variant="outlined"
      className={classes.formControl}
      fullWidth
      error={meta.touched && Boolean(meta.error)}
    >
      <InputLabel
        style={props.isConsulting ? { color: 'rgba(0,0,0,0.88)' } : {}}
        className={disabled ? classes.disabled : ''}
        id={name}
      >
        {label}
      </InputLabel>
      <Select
        defaultChecked
        defaultValue={defaultValue}
        labelId={name}
        className={props.isConsulting ? classes.styledDisabled : ''}
        {...props}
        {...field}
        onChange={props.onChange || field.onChange}
      >
        <MenuItem value="" disabled={!props.clearable}>
          <em>Nenhuma opção</em>
        </MenuItem>

        {options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{meta.touched && meta.error}</FormHelperText>
    </FormControl>
  )
}

export default SelectInput
