import { useQueryParams } from '@elentari/core'
import { makeStyles, Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { GroupWork, GroupWorkOutlined } from '@material-ui/icons'
import { isEmpty } from 'ramda'
import React, { useEffect, useState } from 'react'
import { IoChevronDownSharp, IoChevronForwardSharp } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import {
  Cell,
  Column,
  useColumnOrder,
  useExpanded,
  useFilters,
  useGlobalFilter,
  useGroupBy,
  useTable
} from 'react-table'
import snackbarStore from 'src/stores/snackbar'
import { tableStore } from 'src/stores/TableStore'
import { ActionsTable } from './ActionsTable'
import { DefaultColumnFilter } from './ColumnsFiltersTable'
import { TableCellHead } from './TableCellHead'

export interface IColumn {
  Header: string
  accessor: string
  show?: boolean
  sort?: boolean
  search?: boolean
  selectable?: boolean
  group?: boolean
  Cell?: (props: Cell<object, any>) => JSX.Element
  Filter?: (props: Cell<object, any>) => JSX.Element
  defaultClick?: boolean
}

interface IRowClickProps {
  isActive: boolean
  link: string
  unalterableReason: string
  parameter: string
  addedLink?: string
}

interface IBasePropsTable<T extends object> {
  size?: 'small' | 'medium'
  actionsTable?: (() => JSX.Element)[]
  keyTable: string
  onClickRow?: () => void
  columns: Array<Column<T>>
  data: T[]
  linkWithRedirectClickRow: string
  addedLinkClickRow?: string
  unalterableReasonClickRow?: string
  hasConditionClickRow?: boolean
  sortMapper?: Map<string, string>
}

const useStyles = makeStyles(theme => ({
  row: {
    '&:hover': {
      backgroundColor: '#f5f5f5'
    }
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    backgroundColor: 'transparent',
    right: 0,
    top: 0,
    zIndex: 1,
    borderRadius: '16px'
  },
  group: {
    display: 'flex',
    padding: '5px',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconGroup: {
    opacity: 0
  },
  hoverIcon: {
    '&:hover': {
      '& $iconGroup': {
        opacity: 0.5
      }
    }
  },
  icon: {
    marginBottom: '-6px'
  }
}))

export const BaseTable = <T extends object>({
  columns,
  data,
  actionsTable,
  keyTable,
  linkWithRedirectClickRow,
  addedLinkClickRow,
  unalterableReasonClickRow: unalterableReason,
  hasConditionClickRow = true,
  size = 'small',
  sortMapper = new Map([])
}: IBasePropsTable<T>) => {
  const classes = useStyles()
  const history = useHistory()
  const notGroup = ['actions']
  const [isLoading, _] = useState(false)

  const queryParams = useQueryParams<{
    [key: string]: any
  }>()

  const [isSearch, setIsSearch] = useState(() => {
    const values = Object.keys(queryParams.initialValues)

    if (isEmpty(queryParams.initialValues)) {
      return false
    }
    if (values.length === 1 && values[0] === 'page') {
      return false
    }
    return true
  })

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter
    }),
    []
  )

  const {
    getTableProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    setColumnOrder,
    setHiddenColumns,
    state
  } = useTable<T>(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        groupBy: tableStore.getStateGroupColumns(keyTable) || []
      }
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useExpanded
  )

  const setColumns = (index: number, step: number) => {
    const elemento = allColumns[index]
    allColumns.splice(index, 1)
    allColumns.splice(index + step, 0, elemento)
    const newOrder = allColumns.map((column: any) => column.id)
    setColumnOrder(newOrder)
    tableStore.setStateColumnOrder(keyTable, newOrder)
  }

  const handleToggleHidden = (column: string) => {
    const hiddenColumns = state.hiddenColumns || []
    const index = hiddenColumns.indexOf(column)
    if (index === -1) {
      hiddenColumns.push(column)
    } else {
      hiddenColumns.splice(index, 1)
    }
    tableStore.setStateHiddenColumns(keyTable, hiddenColumns)
  }

  const handleGroupColumn = (group: boolean, id: string) => {
    const groupColumns = new Set<string>(
      tableStore.getStateGroupColumns(keyTable)
    )
    if (group) {
      groupColumns.delete(id)
    } else {
      groupColumns.add(id)
    }
    tableStore.setStateGroupColumns(keyTable, Array.from(groupColumns))
  }

  const handleClickRow = (props: IRowClickProps) => {
    if (props.isActive) {
      history.push(`${props.link}/${props.parameter}${props.addedLink ?? ''}`)
    } else {
      snackbarStore.setMessage(props.unalterableReason)
    }
  }

  useEffect(() => {
    const hasDefaultConfigDatabase =
      tableStore.getState(keyTable).hasDefaultConfigDatabase
    const baseHiddenStore = tableStore.getStateHiddenColumns(keyTable)
    let hasDefaultHidden = []

    if (!hasDefaultConfigDatabase && !baseHiddenStore.length) {
      hasDefaultHidden = columns
        .filter((col: any) => col.show === false)
        .map(col => col.accessor) as any
    }

    const hiddenColumns = new Set<string>([
      ...baseHiddenStore,
      ...hasDefaultHidden
    ])

    tableStore.setStateHiddenColumns(keyTable, Array.from(hiddenColumns))
  }, [])

  useEffect(() => {
    const defaultColumns: string[] = allColumns.map(col => col.id)

    const columnOrder = new Set<string>(
      tableStore.getStateColumnOrder(keyTable)
    )

    if (columnOrder.size !== defaultColumns.length) {
      const newColumnOrder = defaultColumns.filter(col => !columnOrder.has(col))
      columnOrder.forEach(col => {
        if (col === 'actions') {
          columnOrder.delete(col)
          newColumnOrder.map(newColumns => columnOrder.add(newColumns))
        }
      })
    }

    setHiddenColumns(tableStore.getStateHiddenColumns(keyTable))
    setColumnOrder(Array.from(columnOrder))
  }, [
    tableStore.state[keyTable]?.hiddenColumns,
    tableStore.state[keyTable]?.columnOrder
  ])

  return (
    <>
      <div className={classes.actions}>
        <ActionsTable
          keyTable={keyTable}
          handleToggleHidden={handleToggleHidden}
          setColumns={setColumns}
          allColumns={allColumns}
          loading={isLoading}
          isSearch={isSearch}
          handleSearch={() => setIsSearch((prevState: boolean) => !prevState)}
        />

        {actionsTable && actionsTable.map((Actions, i: number) => <Actions />)}
      </div>
      <Table size={size} {...getTableProps()}>
        <TableHead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <>
                  <TableCellHead
                    label={`${column.render('Header')}`}
                    sort={
                      column.id && (column as any).sort !== false
                        ? sortMapper.get(column.id) || column.id
                        : undefined
                    }
                    className={classes.hoverIcon}
                    {...column.getHeaderProps()}
                  >
                    {(column as any).group !== false &&
                    column.canGroupBy &&
                    !notGroup.includes(column.id) ? (
                      <span
                        className={classes.iconGroup}
                        {...column.getGroupByToggleProps()}
                      >
                        <span
                          title={`agrupar por ${column.render('Header')}`}
                          onClick={() =>
                            handleGroupColumn(column.isGrouped, column.id)
                          }
                        >
                          {column.isGrouped ? (
                            <GroupWork
                              className={classes.icon}
                              fontSize="small"
                            />
                          ) : (
                            <GroupWorkOutlined
                              className={classes.icon}
                              fontSize="small"
                            />
                          )}
                        </span>
                      </span>
                    ) : null}
                    {column.id !== 'actions' && isSearch && (
                      <div>
                        {column.canFilter && (column as any).search !== false
                          ? column.render('Filter')
                          : null}
                      </div>
                    )}
                  </TableCellHead>
                </>
              ))}
            </tr>
          ))}
        </TableHead>
        <TableBody>
          {rows.map((row, i) => {
            prepareRow(row)
            return (
              <TableRow className={classes.row} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <>
                      {(cell.column as any).defaultClick !== false ? (
                        <TableCell
                          onClick={() => {
                            const data = cell.row.original as any
                            data &&
                              handleClickRow({
                                isActive: hasConditionClickRow
                                  ? data?.isEditable
                                  : true,
                                link: linkWithRedirectClickRow,
                                addedLink: addedLinkClickRow,
                                parameter: data?.id,
                                unalterableReason: unalterableReason
                                  ? unalterableReason
                                  : `Não é possível acessar a informação com status "${data?.status}"`
                              })
                          }}
                          {...cell.getCellProps()}
                          style={{
                            cursor: cell.row.original ? 'pointer' : 'default',
                            fontWeight: cell.isGrouped ? 'bold' : 'normal'
                          }}
                        >
                          {cell.isGrouped ? (
                            <>
                              <span
                                className={classes.group}
                                {...row.getToggleRowExpandedProps()}
                                title="Expandir"
                              >
                                {row.isExpanded ? (
                                  <IoChevronDownSharp />
                                ) : (
                                  <IoChevronForwardSharp />
                                )}
                                {cell.render('Cell')}
                                <Typography variant="body2">
                                  ({row.subRows.length})
                                </Typography>
                              </span>
                            </>
                          ) : cell.isAggregated ? (
                            cell.render('Aggregated')
                          ) : cell.isPlaceholder ? null : (
                            cell.render('Cell')
                          )}
                        </TableCell>
                      ) : (
                        <TableCell {...cell.getCellProps()}>
                          {cell.render('Cell')}
                        </TableCell>
                      )}
                    </>
                  )
                })}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </>
  )
}
