import { useState } from 'react'
import snackbarStore from 'src/stores/snackbar'
import { divulgarCarguero, suspenderOfertaCarguero } from './lotesRepository'
import { useLote } from './useLote'

export const useDivulgarLoteCarguero = () => {
  const [loadingDivulgacao, setLoadingDivulgacao] = useState(false)
  const [loadingFinalizacao, setLoadingFinalizacao] = useState(false)
  const [loteState, loteActions] = useLote()

  const divulgarLote = (loteId: string) => {
    setLoadingDivulgacao(true)

    divulgarCarguero(loteId).then(response => {
      if (response.ok) {
        snackbarStore.setMessage('Lote divulgado com sucesso!')

        loteActions.fetch(loteId)
      } else {
        snackbarStore.setMessage(
          response.data?.message ?? 'Erro ao divulgar lote.'
        )
      }

      setLoadingDivulgacao(false)
    })
  }

  const finalizarLote = (loteId: string) => {
    setLoadingFinalizacao(true)

    suspenderOfertaCarguero(loteId).then(response => {
      setLoadingFinalizacao(false)

      if (!response.ok) {
        return snackbarStore.setMessage('Erro ao finalizar oferta na carguero.')
      }

      snackbarStore.setMessage('Oferta finalizada com sucesso')
      loteActions.fetch(loteId)
    })
  }

  return {
    loadingDivulgacao,
    loadingFinalizacao,
    loteDivulgado:
      loteState.tag === 'with-data' && !!loteState.entity.codigoCarguero,
    divulgarCarguero: divulgarLote,
    finalizarLote
  }
}
