import { LinearProgress } from '@material-ui/core'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Paper } from 'src/components'
import { TabelaICMSComponente } from './components/TabelaICMSComponente'
import tabelaIcmsStore from './store'
import { loadDataICMS } from './util'

const TabelaICMS = observer(() => {
  useEffect((): void => {
    loadDataICMS()
  }, [])

  useEffect(() => {
    return () => {
      tabelaIcmsStore.resetTabela()
      tabelaIcmsStore.clearSaveArray()
    }
  }, [])

  return (
    <Paper>
      {tabelaIcmsStore.tabela.columns.length !== 0 ? (
        <TabelaICMSComponente />
      ) : (
        <LinearProgress />
      )}
    </Paper>
  )
})

export default TabelaICMS
