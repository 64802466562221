import { useFormikContext } from 'formik'
import { ModalidadeTransportador } from '../../types'
import { ProgramacaoFormData } from '../../types/formData'
import { ColetaEntregaAgregadoPjPf } from './ColetaEntregaAgregadoPjPf'
import { ColetaEntregaFrota } from './ColetaEntregaFrota'
import { ColetaEntregaGrupo } from './ColetaEntregaGrupo'
import { ColetaEntregaProps } from './types'

export const ColetaEntrega = (props: ColetaEntregaProps) => {
  const formik = useFormikContext<ProgramacaoFormData>()

  if (!formik.values.modalidadeTransportador) {
    return null
  }

  if (formik.values.modalidadeTransportador === ModalidadeTransportador.FROTA) {
    return <ColetaEntregaFrota {...props} />
  }

  if (formik.values.modalidadeTransportador === ModalidadeTransportador.GRUPO) {
    return <ColetaEntregaGrupo {...props} />
  }

  return <ColetaEntregaAgregadoPjPf {...props} />
}
