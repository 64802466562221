import { Button, Grid, IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldArray, FieldArrayRenderProps, useField } from 'formik'
import TextInput from '../../../components/Formik/Forms/TextInput'
import When from '../../../components/When'

interface EmailInputProps {
  name: string
}

const EmailInput = ({ name }: EmailInputProps) => {
  const [field] = useField({ name })

  const addEmail = (arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.push({})
    }
  }

  const deleteEmail = (index: number, arrayHelpers: FieldArrayRenderProps) => {
    return () => {
      arrayHelpers.remove(index)
    }
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => (
        <Grid container spacing={2}>
          {field.value.map((email: { email: string }, index: number) => (
            <Grid item xs={12} key={index}>
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Grid item xs>
                  <TextInput
                    name={`emails[${index}].email`}
                    label="E-mail para envio da Cotação"
                    uppercase={false}
                  />
                </Grid>

                <When value={index !== 0} equals>
                  <Grid item container xs={1} justifyContent="center">
                    <Tooltip title={`Deletar ${index + 1}° e-mail`}>
                      <IconButton onClick={deleteEmail(index, arrayHelpers)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </When>
              </Grid>
            </Grid>
          ))}
          <Grid xs={12} item>
            <Button
              variant="outlined"
              color="primary"
              type="button"
              onClick={addEmail(arrayHelpers)}
              data-cy="add-perfil"
            >
              Adicionar e-mail
            </Button>
          </Grid>
        </Grid>
      )}
    ></FieldArray>
  )
}

export default EmailInput
