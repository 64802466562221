import moment from 'moment'
import { formatInTimeZone } from 'date-fns-tz'

export const formatDate = (date: Date) => {
  if (date) {
    const dateObject = new Date(date)
    const dateFormatted = new Date(
      dateObject.getUTCFullYear(),
      dateObject.getUTCMonth(),
      dateObject.getUTCDate()
    )

    return moment(dateFormatted).format('DD/MM/YYYY')
  }
  return 'Data não informada'
}

export const formatDateTime = (date: Date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY HH:mm:ss')
  }
  return 'Data não informada'
}

export const formatTime = (date: Date) => {
  if (date) {
    return moment(date).format('HH:mm')
  }
  return 'Hora não informada'
}

export const combineFullDateWithTime = (d: Date, t: Date) => {
  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    t.getHours(),
    t.getMinutes(),
    t.getSeconds(),
    t.getMilliseconds()
  )
}

export const combineDateWithTime = (d: Date, t: Date) => {
  return new Date(
    d.getFullYear(),
    d.getMonth(),
    d.getDate(),
    t.getHours(),
    t.getMinutes(),
    0,
    0
  )
}

export const formatDateToTimeZone = (
  value: Date,
  timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
  formatValue = 'yyyy-MM-dd HH:mm'
) => {
  const dateValue = formatInTimeZone(value, timeZone, formatValue)
  return new Date(dateValue)
}
