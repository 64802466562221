import { Button, Grid, IconButton, Tooltip } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { FieldArray, FieldArrayRenderProps, useField } from 'formik'
import SelectInput from 'src/components/Formik/Forms/SelectInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import { AvisoFormData, corAvisoOptions } from '../types/aviso'

export interface Props {
  name: string
}

export const AvisoInput = ({ name }: Props) => {
  const [field] = useField<AvisoFormData[]>(name)

  const deleteAviso = (index: number, arrayHelpers: FieldArrayRenderProps) => {
    const aviso = field.value[index]

    if (aviso) {
      arrayHelpers.remove(index)
    }
  }

  const addAviso = (arrayHelpers: FieldArrayRenderProps) => {
    arrayHelpers.push({ descricao: '', cor: '' })
  }

  return (
    <FieldArray
      name={name}
      render={arrayHelpers => {
        return (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {field.value.map((_, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid container item spacing={2} xs>
                    <Grid item xs={6} md={8}>
                      <TextInput
                        name={`${name}.${index}.descricao`}
                        label="Descrição"
                      />
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <SelectInput
                        name={`${name}.${index}.cor`}
                        label="Cor"
                        options={corAvisoOptions}
                      />
                    </Grid>
                  </Grid>

                  <Grid item>
                    <IconButton
                      onClick={() => deleteAviso(index, arrayHelpers)}
                    >
                      <Tooltip title="Deletar aviso">
                        <DeleteIcon />
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>

            <Grid xs={12} item>
              <Button
                variant="outlined"
                color="primary"
                type="button"
                onClick={() => addAviso(arrayHelpers)}
              >
                Adicionar aviso
              </Button>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}
