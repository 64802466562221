import { apiSauceInstance } from 'src/services/api'

export interface CreatedAnexo {
  id: string
}

export interface Anexo {
  id: string
  url: string
  filename: string
  mimeType: string
  originalName: string
  createdAt: Date
  updatedAt?: Date

  loteId?: string
  cotacaoId?: string
  contratosId?: string
  comentarioId?: string
}

export const uploadAnexos = async (files: File[]) => {
  const anexos: CreatedAnexo[] = []

  for (const file of files) {
    const formData = new FormData()
    formData.append('upload', file)

    const res = await apiSauceInstance.post<CreatedAnexo>(
      '/uploads/anexo',
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      }
    )

    if (res.ok) {
      anexos.push(res.data!)
    } else {
      return null
    }
  }

  return anexos
}

export const UPLOAD_ERROR_MESSAGE = 'Ocorreu um erro ao salvar o(s) anexos(s).'
