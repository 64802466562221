import { Divider, Grid } from '@material-ui/core'
import { useFormikContext } from 'formik'
import { ProgramacaoFormData } from '../types/formData'
import { InformacoesColetaEntregaLote } from './informacoes-coleta-entrega-lote/InformacoesColetaEntregaLote'
import { Produto } from './Produto'

export const DadosPorLote = () => {
  const formik = useFormikContext<ProgramacaoFormData>()
  const lotes = Object.values(formik.values.lotes)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {lotes.map((lote, index) => (
          <Grid container spacing={2} key={index}>
            <Grid item xs={12}>
              <InformacoesColetaEntregaLote lote={lote} />
            </Grid>

            <Grid item xs={12}>
              <Produto lote={lote} />
            </Grid>

            {index + 1 !== lotes.length ? (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            ) : null}
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
