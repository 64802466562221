import { Grid } from '@material-ui/core'
import { CheckboxInput } from 'src/packages/g10-components/CheckboxInput'
import { ConfigAccordion } from '../ConfigAccordion'
import { DadosConfigCentroCusto } from '../DadosConfigCentroCusto'
import { DadosLote } from '../DadosConfigLote'
import { DadosGrupoNegociador } from '../DadosConfigGrupoNegociador'
import { DadosCidade } from '../DadosConfigCidade'
import { DadosProduto } from '../DadosConfigProduto'

export const DadosProgramacao = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <CheckboxInput name="config.codigo" label="Código" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.dataHoraCarregamento"
          label="Data/Hora Carregamento"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.dataHoraDescarregamento"
          label="Data/Hora Descarregamento"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.capacidadeCargaVeiculo"
          label="Capacidade de Carga"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.observacoes" label="Observações" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.status" label="Status" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.nomeResponsavel"
          label="Nome do Responsável"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.telefoneResponsavel"
          label="Telefone do Responsável"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.motorista" label="Motorista" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.cpfMotorista" label="CPF do Motorista" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.telefoneMotorista"
          label="Telefone do Motorista"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.placaPrincipal" label="Placa Principal" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.placa_1" label="Placa 1" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.placa_2" label="Placa 2" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.placa_3" label="Placa 3" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.emailCriador" label="Criado Por" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.tipoPagamento" label="Tipo de Pagamento" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.numeroCartao" label="Número do Cartão" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput name="config.embarcador" label="Embarcador" />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.quantidadePrevista"
          label="Quantidade Prevista"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.valorFreteAcordado"
          label="Valor Frete Acordado"
        />
      </Grid>

      <Grid item xs={3}>
        <CheckboxInput
          name="config.categoriaTransportador"
          label="Categoria Transportador"
        />
      </Grid>
    </Grid>
  )
}

export const ProgramacaoConfig = () => {
  const programacaoKeys = [
    'codigo',
    'dataHoraCarregamento',
    'dataHoraDescarregamento',
    'capacidadeCargaVeiculo',
    'observacoes',
    'status',
    'nomeResponsavel',
    'telefoneResponsavel',
    'motorista',
    'cpfMotorista',
    'telefoneMotorista',
    'placaPrincipal',
    'placa_1',
    'placa_2',
    'placa_3',
    'emailCriador',
    'tipoPagamento',
    'numeroCartao',
    'embarcador',
    'quantidadePrevista',
    'valorFreteAcordado',
    'categoriaTransportador'
  ]

  const centroCustoKeys = [
    'centroCusto.codigo',
    'centroCusto.descricao',
    'centroCusto.unidadeNegocioDescricao'
  ]

  const loteKeys = [
    'lote.codigo',
    'lote.descricao',
    'lote.operacaoKMM',
    'lote.status',
    'lote.numeroPedido',
    'lote.freteEmpresa',
    'lote.freteSubcontratadoPJ',
    'lote.freteSubcontratadoPF',
    'lote.quantidadeRetirada',
    'lote.quantidadeTotal',
    'lote.dataInicio',
    'lote.dataTermino',
    'lote.tipoContratacao',
    'lote.especie',
    'lote.emailCriador',
    'lote.analistaResponsavel',
    'lote.saldo',
    'lote.previsaoMargem'
  ]

  const centroCustoLoteKeys = [
    'lote.centroCusto.codigo',
    'lote.centroCusto.descricao',
    'lote.centroCusto.unidadeNegocioDescricao'
  ]

  const grupoNegociadorLoteKeys = [
    'lote.grupoNegociador.codigo',
    'lote.grupoNegociador.nome',
    'lote.grupoNegociador.raizCnpj'
  ]

  const origemLoteKeys = [
    'lote.localCarregamento',
    'lote.origem.nome',
    'lote.origem.estadoNome',
    'lote.origem.estadoSigla'
  ]

  const destinoLoteKeys = [
    'lote.destino.localDescarregamento',
    'lote.destino.nome',
    'lote.destino.estadoNome',
    'lote.destino.estadoSigla'
  ]

  const produtoLoteKeys = [
    'lote.produto.codigo',
    'lote.produto.nome',
    'lote.produto.codigoNcm',
    'lote.produto.unidade',
    'lote.produto.tipoCarga',
    'lote.produto.agrupadorProdutosNome'
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ConfigAccordion keys={programacaoKeys} title="Dados da Programação">
          <DadosProgramacao />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion
          keys={centroCustoKeys}
          title="Dados do Centro de Custo da Programação"
        >
          <DadosConfigCentroCusto />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion keys={loteKeys} title="Dados do Lote">
          <DadosLote prefix="lote" />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion
          keys={centroCustoLoteKeys}
          title="Dados do Centro de Custo do Lote"
        >
          <DadosConfigCentroCusto />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion
          keys={grupoNegociadorLoteKeys}
          title="Dados do Grupo Negociador do Lote"
        >
          <DadosGrupoNegociador prefix="lote" />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion keys={origemLoteKeys} title="Dados da Origem do Lote">
          <DadosCidade
            prefix="lote.origem"
            localName="config.lote.localCarregamento"
            localLabel="Local de Carregamento"
          />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion
          keys={destinoLoteKeys}
          title="Dados do Destino do Lote"
        >
          <DadosCidade
            prefix="lote.destino"
            localName="config.lote.destino.localDescarregamento"
            localLabel="Local de Descarregamento"
          />
        </ConfigAccordion>
      </Grid>

      <Grid item xs={12}>
        <ConfigAccordion
          keys={produtoLoteKeys}
          title="Dados do Produto do Lote"
        >
          <DadosProduto prefix="lote" />
        </ConfigAccordion>
      </Grid>
    </Grid>
  )
}
