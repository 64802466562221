import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { lotesStore } from 'src/modules/lotes/stores/lotes'
import { schemaLotesDialog } from 'src/modules/lotes/validation/lotesDialogValidation'
import yupContextValidation from 'src/utils/yupContextValidation'
import { alterarStatus } from '../../modules/lotes/hooks/lotesRepository'
import {
  AlterarStatusData,
  StatusLote,
  StatusLoteEnum
} from '../../modules/lotes/types'
import snackbarStore from '../../stores/snackbar'
import DateInput from '../DateInput'
import SelectInput from '../Formik/Forms/SelectInput'

interface LotesDialogProps {
  open: boolean
  handleClose: () => void
  primaryAction?: () => void
  status: StatusLote
  optionsStatus: string[]
  id: string
}

const initialValues: AlterarStatusData = {
  newStatus: '',
  novaDataTermino: undefined
}

function LotesDialog({
  open,
  handleClose,
  primaryAction,
  status,
  optionsStatus,
  id
}: LotesDialogProps) {
  const history = useHistory()

  const handlePrimaryAction = () => {
    handleClose()
    primaryAction && primaryAction()
  }

  function getOptions(optionsStatus: string[]) {
    const options = optionsStatus.map((value: string) => {
      return { label: value, value }
    })
    return options
  }

  const handleSubmit = async (data: AlterarStatusData) => {
    handlePrimaryAction()
    if (
      status === StatusLoteEnum.VENCIDO &&
      data.newStatus === StatusLoteEnum.REABERTO
    ) {
      if (!data.novaDataTermino) {
        return snackbarStore.setMessage(
          'É necessário informar uma nova data limite de reabertura!'
        )
      }

      return history.push(
        `/lotes/${id}?reabrir=true&status=${data.newStatus}&dataReabertura=${data.novaDataTermino}`
      )
    }

    const res = await alterarStatus(id, data)
    if (res.ok) {
      snackbarStore.setMessage('Status alterado com sucesso!')
      lotesStore.lotes.populate()
    } else {
      snackbarStore.setMessage(
        res.data?.message ?? 'Error ao alterar status do lote'
      )
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">Editar Status</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validate={yupContextValidation(schemaLotesDialog, {
            oldStatus: status
          })}
          onSubmit={handleSubmit}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <SelectInput
                    name="newStatus"
                    label="Selecionar Status"
                    options={getOptions(optionsStatus)}
                  />
                </Grid>

                {status === StatusLoteEnum.VENCIDO &&
                  (values.newStatus === StatusLoteEnum.REABERTO ||
                    values.newStatus === StatusLoteEnum.ABERTO) && (
                    <Grid item xs={12}>
                      <DateInput
                        label="Nova data de retirada"
                        name="novaDataTermino"
                      />
                    </Grid>
                  )}

                <Grid container justifyContent="flex-end">
                  <DialogActions>
                    <Button onClick={handleClose} color="default" autoFocus>
                      Voltar
                    </Button>
                    <Button
                      disabled={!!!values.newStatus || isSubmitting}
                      color="primary"
                      type="submit"
                      autoFocus
                    >
                      {status === StatusLoteEnum.VENCIDO &&
                      values.newStatus === StatusLoteEnum.REABERTO
                        ? 'Avançar'
                        : 'Confirmar'}
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default LotesDialog
