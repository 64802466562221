import { useQueryParams } from '@elentari/core'
import { useEffect } from 'react'
import { ConfiguracoesTable } from './ConfiguracoesTable'
import { configuracaoStore } from './stores'

const Configuracoes = () => {
  const { initialValues } = useQueryParams<any>()

  useEffect(() => {
    configuracaoStore.configuracao.setPage(Number(initialValues.page) || 1)
    configuracaoStore.configuracao.setFilters(initialValues)
  }, [initialValues])

  return <ConfiguracoesTable />
}

export default Configuracoes
