import { isNil } from 'ramda'
import { IPernaInput } from 'src/components/PernaInput'
import { CotacaoDetail } from 'src/modules/cotacoes/types'
import snackbarStore from 'src/stores/snackbar'
import { hasToChangeStatus as hasToChangeStatusService } from '../hooks/lotesRepository'
import {
  DestinoFormData,
  LotesDetail,
  LotesFormData,
  OptionalProps,
  TipoContratacao
} from '../types'
import { initialValues } from './lotes.schema'
import { formatLabelTabelasContrato } from '../utils/formatLabelTabelasContrato'

export function formattedData(data: LotesFormData) {
  if (data.tipoContratacao === 'SPOT') {
    const spot = {
      id: data.id,
      descricao: data.descricao ? data.descricao : null,
      tipoContratacao: data.tipoContratacao,
      centroCustoId: data.centroCustoId.value,
      grupoNegociadorId: data.grupoNegociadorId?.value,
      origemId: data.pernas[0].cidadeOrigemPerna.value,
      localCarregamento: data.pernas[0].localCarregamento,
      destinos: [
        {
          cidadeId:
            data.pernas[data.pernas.length - 1].cidadeDestinoPerna.value,
          localDescarregamento: data.pernas[data.pernas.length - 1].localEntrega
        }
      ],
      produtoId: data.produtoId.value,
      tipoLote: data.tipoLote,
      quantidadeTotal: data.quantidadeTotal ?? undefined,
      pernas: data.pernas.map((perna: IPernaInput, index: number) => ({
        cidadeOrigemId: index
          ? data.pernas[index - 1].cidadeDestinoPerna.value
          : perna.cidadeOrigemPerna.value,
        localCarregamento: index
          ? data.pernas[index - 1].localEntrega
          : perna.localCarregamento,
        cidadeDestinoId: perna.cidadeDestinoPerna.value,
        localEntrega: perna.localEntrega,
        freteEmpresa: perna.freteEmpresa,
        freteAgregado: perna.freteAgregado,
        freteSubcontratadoPJ: perna.freteSubcontratadoPJ,
        freteSubcontratadoPF: perna.freteSubcontratadoPF,
        freteGrupo: perna.freteGrupo || null,
        operacaoKMM: perna.operacaoKMM || undefined,
        valorPedagio: perna.valorPedagio || undefined,
        aliquotaICMS: perna.aliquotaICMS,
        ordem: index + 1
      })),
      tipoPedagio: data.tipoPedagio || null,
      pedagio: data.pedagio,
      icms: data.icms,
      segundaPerna: data.segundaPerna,
      tipoNegociacaoSubcontratado: data.tipoNegociacaoSubcontratado,
      tipoNegociacaoEmpresa: data.tipoNegociacaoEmpresa
        ? data.tipoNegociacaoEmpresa
        : null,
      observacao: data.observacao,
      numeroPedido: data.numeroPedido,
      dataInicio: data.dataInicio,
      dataTermino: data.dataTermino,
      cadencia: data.cadencia,
      operacaoKMM:
        data?.pernas?.map(perna => perna.operacaoKMM).join(' | ') ??
        String(data.operacaoKMM),
      deletedDestinos: data.deletedDestinos,
      anexosCotacao: data.anexosCotacao,
      diasParaCarregar: data.diasParaCarregar,
      diasParaDescarregar: data.diasParaDescarregar,
      especie: data.especie ? data.especie : null,
      novaDataTermino: data?.novaDataTermino
        ? new Date(data?.novaDataTermino)
        : undefined,
      reabrir: data?.reabrir,
      status: data?.status
    }

    return [spot, null]
  }

  const contrato = formatDataForContrato(data)
  return [null, contrato]
}

function formatDataForContrato(data: LotesFormData) {
  return {
    id: data.id,
    descricao: data.descricao ? data.descricao : null,
    tipoContratacao: data.tipoContratacao,
    centroCustoId: data.centroCustoId.value,
    grupoNegociadorId: data.grupoNegociadorId?.value,
    tabelaId: data.tabela ? data.tabela?.value : null,
    origemId: data.origemId?.value,
    localCarregamento: data.localCarregamento,
    destinos: data.destinos
      .filter((destino: DestinoFormData) => destino.destinoId)
      .map((destino: DestinoFormData) => ({
        cidadeId: destino.destinoId.value,
        localDescarregamento: destino.localEntrega,
        id: destino.id
      })),
    produtoId: data.produtoId?.value || null,
    tipoLote: data.tipoLote,
    quantidadeTotal: data.quantidadeTotal ?? undefined,
    pernas: [],
    permiteTrocarNota: data.permiteTrocarNota,
    tipoPedagio: data.tipoPedagio || null,
    dataInicio: data.dataInicio,
    dataTermino: data.dataTermino,
    observacao: data.observacao,
    numeroPedido: data.numeroPedido,
    operacaoKMM: String(data.operacaoKMM) ?? null,
    deletedDestinos: data.deletedDestinos,
    pedagio: false,
    icms: false,
    anexosCotacao: data.anexosCotacao,
    diasParaCarregar: data.diasParaCarregar,
    diasParaDescarregar: data.diasParaDescarregar,
    especie: data.especie ? data.especie : null,
    novaDataTermino: data?.novaDataTermino
      ? new Date(data?.novaDataTermino)
      : undefined,
    reabrir: data?.reabrir,
    status: data?.status
  }
}

export async function hasToChangeStatus(data: any) {
  const response = await hasToChangeStatusService(data)

  if (response.ok) {
    return response.data!
  }

  snackbarStore.setMessage(
    'Falha ao verificar se há alterações sensíveis no lote.'
  )

  return false
}

export function formatDataFromExistingLote(
  values: LotesDetail,
  props?: OptionalProps
) {
  let dataProps = {}
  if (props?.reabrir && props?.dataReabertura) {
    dataProps = {
      dataTermino: new Date(props.dataReabertura)
    }
  }

  return {
    ...values,
    valorPedagio: values.valorPedagio ?? 0,
    aliquotaICMS: values.aliquotaICMS ?? 0,
    operacaoKMM: values.operacaoKMM ?? 0,
    tipoPedagio: values.tipoPedagio ?? '',
    margemGrupo: values.margemGrupo ?? 0,
    estadoOrigemId: values.Origem.Estado.id,
    contrato: values.Tabela?.Contrato
      ? {
          name: `${values.Tabela?.Contrato.codigo} - ${values.Tabela?.Contrato.GrupoNegociador.nome} - ${values.Tabela?.Contrato.descricao} - ${values.Tabela?.Contrato.AgrupadorProdutos.nome}`,
          value: values.Tabela.Contrato.id
        }
      : null,
    tabela: values.Tabela ? formatLabelTabelasContrato(values.Tabela) : null,
    files: [],
    anexosCotacao: [],
    destinos: values.DestinoLote.length
      ? values.DestinoLote.map(destino => {
          return {
            estadoDestinoId: {
              name: destino.Cidade?.Estado?.nome,
              value: destino.Cidade?.Estado?.id
            },
            destinoId: {
              name: destino.Cidade?.nome,
              value: destino.Cidade?.id
            },
            localEntrega: destino.localDescarregamento,
            id: destino.id
          }
        })
      : [
          {
            estadoDestinoId: '',
            destinoId: '',
            localEntrega: ''
          }
        ],
    pernas: values.Pernas
      ? values.Pernas.map(perna => {
          return {
            cidadeOrigemPerna: {
              name: perna.CidadeOrigem.nome,
              value: perna.CidadeOrigem.id
            },
            estadoOrigemPerna: {
              name: perna.CidadeOrigem.Estado.nome,
              value: perna.CidadeOrigem.Estado.id
            },
            cidadeDestinoPerna: {
              name: perna.CidadeDestino.nome,
              value: perna.CidadeDestino.id
            },
            estadoDestinoPerna: {
              name: perna.CidadeDestino.Estado.nome,
              value: perna.CidadeDestino.Estado.id
            },
            localCarregamento: perna.localCarregamento ?? '',
            localEntrega: perna.localEntrega ?? '',
            freteEmpresa: perna.freteEmpresa ?? 0,
            freteAgregado: perna.freteAgregado ?? 0,
            freteSubcontratadoPJ: perna.freteSubcontratadoPJ ?? 0,
            freteSubcontratadoPF: perna.freteSubcontratadoPF ?? 0,
            valorGrupo: !!perna.freteGrupo,
            freteGrupo: perna.freteGrupo ?? 0,
            operacaoKMM: perna.operacaoKMM ?? 0,
            valorPedagio: perna.valorPedagio ?? 0,
            aliquotaICMS: perna.aliquotaICMS ?? 0
          }
        })
      : '',
    valorGrupo: values.freteGrupo !== null,
    diasParaCarregar: !isNil(values.diasParaCarregar)
      ? values.diasParaCarregar
      : undefined,
    diasParaDescarregar: !isNil(values.diasParaDescarregar)
      ? values.diasParaDescarregar
      : undefined,
    ...dataProps
  }
}

export function formatDataFromCotacao(
  values: CotacaoDetail,
  props?: OptionalProps
) {
  let dataTermino = values.dataEmbarqueTermino || new Date()

  if (props?.reabrir && props?.dataReabertura) {
    dataTermino = new Date(props.dataReabertura)
  }

  return {
    ...initialValues,
    tipoContratacao: TipoContratacao.SPOT,
    centroCustoId: values.centroCustoId,
    pernas: [
      {
        localCarregamento: '',
        estadoOrigemPerna: values.Origem.Estado.id,
        cidadeOrigemPerna: values.origemId,
        localEntrega: '',
        estadoDestinoPerna: values.Destino.Estado.id,
        cidadeDestinoPerna: values.destinoId,
        freteEmpresa: Number(values.valorFreteFechado),
        freteAgregado: 0,
        freteGrupo: 0,
        freteSubcontratadoPJ: 0,
        freteSubcontratadoPF: 0,
        valorPedagio: Number(values.valorPedagio) ?? 0
      }
    ],
    tipoLote: 'QUANTIDADE',
    quantidadeTotal: Number(values.quantidade),
    segundaPerna: false,
    tipoNegociacaoEmpresa: values.tipoNegociacao,
    pedagio: values.hasPedagio,
    tipoPedagio: values.tipoPedagio ?? '',
    icms: values.icms,
    dataInicio: values.dataEmbarqueInicio,
    dataTermino: dataTermino,
    anexosCotacao: values.Anexos.map(anexo => ({ id: anexo.id }))
  }
}
