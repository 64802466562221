import { useState } from 'react'
import { LeituraPlanilhaDialog } from 'src/components/LeituraPlanilhaDialog'
import { LeituraPlanilhaDialogForm } from 'src/components/LeituraPlanilhaDialog/types'
import useCanSave from 'src/hooks/useCanSave'
import snackbarStore from 'src/stores/snackbar'
import { uploadAnexos, UPLOAD_ERROR_MESSAGE } from 'src/utils/anexo'
import { useCotacao } from '../hooks/useCotacao'
import { createFromXlsx } from '../services'
import cotacoesStore from '../store/cotacoes'

interface Props {
  open: boolean
  handleClose: () => void
}

export function LeiuturaPlanilhaCotacoesDialog({ open, handleClose }: Props) {
  const [loading, setLoading] = useState(false)
  const [cotacaoState] = useCotacao()

  const canSave = useCanSave(cotacaoState, 'Cotações')

  const handleSubmit = async ({ fileXlsx }: LeituraPlanilhaDialogForm) => {
    setLoading(true)
    const anexos = await uploadAnexos([fileXlsx!])

    if (!anexos) {
      setLoading(false)
      return snackbarStore.setMessage(UPLOAD_ERROR_MESSAGE)
    }

    const response = await createFromXlsx(anexos[0].id)

    if (!response.ok) {
      setLoading(false)
      return snackbarStore.setMessage(
        response.data?.message ?? 'Ocorreu um erro.'
      )
    }

    cotacoesStore.cotacoes.populate()
    handleClose()
    snackbarStore.setMessage(
      `Foram criadas ${response.data?.createdQuantity} cotações.`
    )

    setLoading(false)
  }

  return (
    <LeituraPlanilhaDialog
      isOpen={open}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      disabled={loading || !canSave}
      title="Leitura de planilha para criação de cotações"
      href="/template-vazio.xlsx"
      titlePrimaryButton="Criar Cotações"
    />
  )
}
