import { withStyles } from '@material-ui/core'
import TextInput from 'src/components/Formik/Forms/TextInput'

export const BlackDisabledTextInput = withStyles({
  root: {
    '& label.MuiFormLabel-root.Mui-disabled': {
      opacity: 1,
      color: '#676767'
    },
    '& input.MuiInputBase-input.Mui-disabled': {
      opacity: 1,
      color: '#676767'
    }
  }
})(TextInput)
