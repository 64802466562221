import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useFormikContext } from 'formik'
import DateInput from '../../../components/DateInput'
import NumericInput from '../../../components/Formik/Forms/NumericInput'
import TextInput from '../../../components/Formik/Forms/TextInput'
import { Perna, TipoContratacao } from '../types'
import { isTipoContrato } from '../utils'

export const InformacoesAdicionais = () => {
  const { values } = useFormikContext<{
    tipoContratacao: string
    Pernas: Perna[]
  }>()

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5">Informações Adicionais</Typography>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextInput name="numeroPedido" label="Número do Pedido (opcional)" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <DateInput label="Data Início Retirada" name="dataInicio" />
      </Grid>
      <Grid item xs={12} sm={3}>
        <DateInput label="Data Limite Retirada" name="dataTermino" />
      </Grid>
      {!isTipoContrato(values.tipoContratacao) && (
        <Grid
          item
          xs={12}
          sm={values.tipoContratacao !== TipoContratacao.CONTRATO ?? 12}
        >
          <TextInput
            name="cadencia"
            label="Cadência para Retirada (opcional)"
          />
        </Grid>
      )}
      {values.tipoContratacao === TipoContratacao.CONTRATO && (
        <Grid item xs={12} sm={12}>
          <NumericInput
            name="operacaoKMM"
            label="Operação KMM (opcional)"
            precision={0}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={12}>
        <TextInput name="observacao" label="Observação (opcional)" />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumericInput
          label="Dias para carregar"
          name="diasParaCarregar"
          precision={0}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <NumericInput
          label="Dias para descarregar"
          name="diasParaDescarregar"
          precision={0}
        />
      </Grid>
    </Grid>
  )
}
