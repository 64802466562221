import { createStyles, makeStyles, Typography } from '@material-ui/core'
import { useField } from 'formik'
import { DropzoneArea } from 'material-ui-dropzone'

type Props = {
  name: string
  dropzoneTitle?: string
  fileLimit?: number
  key?: number
  acceptedFiles?: string[]
  disabled?: boolean
}

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      ' & .MuiDropzoneArea-root > .MuiDropzoneArea-textContainer': {
        opacity: '0.6 !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: 40
      },
      ' & .MuiDropzonePreviewList-removeButton': {
        marginRight: 40
      }
    }
  })
)

const DropzoneInput = (props: Props) => {
  const [, meta, helper] = useField(props)
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <DropzoneArea
          key={props.key}
          onChange={files => {
            helper.setValue(files)
          }}
          dropzoneProps={{ disabled: props.disabled }}
          alertSnackbarProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right'
            }
          }}
          dropzoneText={props.dropzoneTitle || 'Arraste o documento aqui'}
          showPreviewsInDropzone
          acceptedFiles={
            props.acceptedFiles ?? [
              '.xlsx',
              '.xls',
              '.xmls',
              '.xlsm',
              '.jpg',
              '.pdf',
              '.png',
              '.jpeg',
              '.zip',
              '.rar',
              '.doc',
              '.docx',
              '.eml',
              '.msg',
              '.pptx',
              '.txt',
              '.xml'
            ]
          }
          showFileNames
          showAlerts={['success', 'info', 'error']}
          getFileRemovedMessage={filename => {
            return `Arquivo: ${filename} Removido!`
          }}
          getFileAddedMessage={filename => {
            return `Arquivo: ${filename} Adicionado.`
          }}
          getDropRejectMessage={(filename, accept, size) => {
            const filesAccepted = accept.map(n => n)

            return `Arquivo: ${
              filename.name
            } rejeitado, somente arquivos [${filesAccepted}] aceitos com limite de tamanho de ${
              size / 1000 / 1000
            }mb`
          }}
          filesLimit={props.fileLimit ?? Number.POSITIVE_INFINITY}
          getFileLimitExceedMessage={numberLimit => {
            return `Quantidade máxima que podem ser anexados é ${numberLimit}. Sendo assim, não foi possível adicionar devido o limite de upload.`
          }}
        />
      </div>
      <Typography variant="caption" style={{ color: 'red' }}>
        {meta.touched && meta.error}
      </Typography>
    </>
  )
}

export default DropzoneInput
