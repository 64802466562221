import { LoteList, LotesDetail } from 'src/modules/lotes/types'

interface FormatSaldoOptions {
  loteAbertoLabel?: string
  quantidadePrevista?: number
}

export function formatSaldoLote(
  lote: LoteList | LotesDetail,
  { quantidadePrevista = 0, loteAbertoLabel = '-' }: FormatSaldoOptions
) {
  return lote.tipoLote !== 'ABERTO'
    ? lote.saldo - quantidadePrevista
    : loteAbertoLabel
}
