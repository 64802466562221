import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { useState } from 'react'
import { ConditionalTooltip } from 'src/components/ConditionalTooltip'
import { ConsultaField } from 'src/components/ConsultaField'
import { StatusColors } from 'src/components/Validation/StatusColors'
import When from 'src/components/When'
import snackbarStore from 'src/stores/snackbar'
import { formatDateTime } from 'src/utils/formatters/formatDate'
import {
  enviarOfertaTrato,
  suspenderOfertaTrato
} from '../hooks/lotesRepository'
import { useIsTratoHabilitado } from '../hooks/useIsTratoHabilitado'
import { useLote } from '../hooks/useLote'
import { IntegracaoButton } from './IntegracaoButton'

export function IntegracaoTrato() {
  const [loteState, actions] = useLote()
  const { isTratoHabilitado } = useIsTratoHabilitado()

  const [loading, setLoading] = useState<boolean>(false)

  const isTipoContrato =
    loteState.tag === 'with-data' &&
    loteState.entity.tipoContratacao === 'CONTRATO'

  const suspenderOferta = async () => {
    if (loteState.tag === 'with-data') {
      setLoading(true)

      const response = await suspenderOfertaTrato(loteState.entity.id)
      if (response && !response.ok) {
        snackbarStore.setMessage(
          response.data?.message || 'Não foi possível suspender frete no Trato.'
        )
      }

      setLoading(false)
      actions.fetch(loteState.entity.id)
    }
  }

  const enviarOferta = async () => {
    if (loteState.tag === 'with-data') {
      setLoading(true)

      const response = await enviarOfertaTrato(loteState.entity.id)
      if (response && !response.ok) {
        snackbarStore.setMessage(
          response.data?.message || 'Não foi possível enviar frete ao Trato.'
        )
      }

      setLoading(false)
      actions.fetch(loteState.entity.id)
    }
  }

  return loteState.tag === 'with-data' ? (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          Trato
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ConditionalTooltip
              enabled={!isTratoHabilitado}
              title="A integração com o Trato está desabilitada."
            >
              <IntegracaoButton
                type="button"
                variant="outlined"
                onClick={() => enviarOferta()}
                style={{
                  backgroundColor: StatusColors.LIBERADA
                }}
                disabled={loading || isTipoContrato || !isTratoHabilitado}
              >
                {loading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  'DIVULGAR'
                )}
              </IntegracaoButton>
            </ConditionalTooltip>
          </Grid>
          <When
            value={loteState.entity.statusOfertaTrato === 'PUBLISHED'}
            equals
          >
            <Grid item xs={12}>
              <ConditionalTooltip
                enabled={!isTratoHabilitado}
                title="A integração com o Trato está desabilitada"
              >
                <IntegracaoButton
                  type="button"
                  variant="outlined"
                  onClick={() => suspenderOferta()}
                  style={{
                    backgroundColor: StatusColors.PENDENTE,
                    marginTop: '3px'
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    'SUSPENDER'
                  )}
                </IntegracaoButton>
              </ConditionalTooltip>
            </Grid>
          </When>
          <When value={!!loteState.entity.idOfertaTrato} equals>
            <Grid item xs={12}>
              <ConsultaField
                title="ID DA OFERTA"
                subtitle={`${loteState.entity.idOfertaTrato} ${
                  loteState.entity.statusOfertaTrato === 'PUBLISHED'
                    ? ''
                    : `(SUSPENSA)`
                }`}
              />
            </Grid>

            <Grid item xs={12}>
              <ConsultaField
                title="ÚLTIMO ENVIO"
                subtitle={`${formatDateTime(
                  loteState.entity.ultimoEnvioTrato
                )}`}
              />
            </Grid>
          </When>
        </Grid>
      </Grid>
    </Grid>
  ) : null
}
