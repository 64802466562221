import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { StatusLote, ValidationDialogFormData } from 'src/modules/lotes/types'
import { apiSauceInstance } from 'src/services/api'
import yupContextValidation from 'src/utils/yupContextValidation'
import * as yup from 'yup'
import { alterarStatus } from '../modules/lotes/hooks/lotesRepository'
import { MessagesYup } from '../modules/messages'
import snackbarStore from '../stores/snackbar'
import TextInput from './Formik/Forms/TextInput'

interface ValidationConfirmDialogProps {
  open: boolean
  handleClose: () => void
  primaryAction?: () => void
  status: StatusLote
  id: string
  liberadaGestor?: boolean
}

const initialValues: ValidationDialogFormData = {
  motivo: ''
}

export const schema = yup.object().shape({
  motivo: yup
    .string()
    .typeError(MessagesYup.MENSAGEM_OBRIGATORIO)
    .when('$status', {
      is: (status: StatusLote) =>
        status === 'REPROVADO' ||
        status === 'CANCELADO' ||
        status === 'PENDENTE',
      then: schema => schema.required(MessagesYup.MENSAGEM_OBRIGATORIO)
    })
})

function ValidationConfirmDialog({
  open,
  handleClose,
  primaryAction,
  status,
  id,
  liberadaGestor
}: ValidationConfirmDialogProps) {
  const history = useHistory()

  const handlePrimaryAction = (values: ValidationDialogFormData) => {
    if (values.motivo !== '') {
      handleClose()
      primaryAction && primaryAction()
    }
  }

  const handleSubmit = async (data: ValidationDialogFormData) => {
    if (status === 'PENDENTE') {
      await apiSauceInstance.post('/comentarios', {
        loteId: id,
        comentario: `Motivo da pendência: ${data.motivo}`
      })
    }

    if (status !== 'PENDENTE' && data.motivo) {
      await apiSauceInstance.post('/comentarios', {
        loteId: id,
        comentario: data.motivo
      })
    }

    const res = await alterarStatus(id, {
      newStatus: status,
      liberadaGestor: liberadaGestor || false,
      motivo: data.motivo
    })

    if (res.ok) {
      snackbarStore.setMessage('Status alterado com sucesso!')
      history.goBack()
    } else {
      snackbarStore.setMessage(
        res.data?.message ?? 'Error ao alterar status do lote'
      )
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="alert-dialog-title">
        Deseja alterar o status do lote para "{status}"?
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={yupContextValidation(schema, { status })}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                {(status === 'PENDENTE' ||
                  status === 'REPROVADO' ||
                  status === 'CANCELADO') && (
                  <Grid item xs={12}>
                    <TextInput name="motivo" label="Motivo" />
                  </Grid>
                )}
                <Grid container justifyContent="flex-end">
                  <DialogActions>
                    <Button onClick={handleClose} color="default" autoFocus>
                      Cancelar
                    </Button>
                    <Button
                      onClick={() => handlePrimaryAction(values)}
                      disabled={isSubmitting}
                      color="primary"
                      type="submit"
                      autoFocus
                    >
                      Confirmar
                    </Button>
                  </DialogActions>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default ValidationConfirmDialog
