import { useState } from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import BlockIcon from '@material-ui/icons/Block'
import SimpleDialog from './SimpleDialog'

type Props = {
  onCancel: () => void
  title?: string
  tooltipTitle?: string
  message?: string
  disabled?: boolean
}

export function CancelRecord({
  onCancel,
  title,
  message,
  tooltipTitle,
  disabled
}: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <>
      <Tooltip
        title={tooltipTitle ?? 'Cancelar registro'}
        aria-label={tooltipTitle ?? 'Cancelar registro'}
      >
        <IconButton disabled={disabled} onClick={() => setIsModalOpen(true)}>
          <BlockIcon />
        </IconButton>
      </Tooltip>
      <SimpleDialog
        message={message || 'Deseja realmente cancelar esse item?'}
        open={isModalOpen}
        buttonLabel="Não"
        handleClose={() => setIsModalOpen(false)}
        primaryAction={onCancel}
        title={title ?? tooltipTitle ?? 'Cancelar este Registro'}
        primaryActionButtonLabel="Sim"
      />
    </>
  )
}
