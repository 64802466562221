import React from 'react'
import { HeaderGroup } from 'react-table'
import { IColumn } from 'src/components/BaseTable'
import {
  DatePickerFilter,
  DefaultColumnFilter,
  NumericFilter
} from 'src/components/ColumnsFiltersTable'
import When from 'src/components/When'
import { DeleteRecord } from '../../components'

interface INegociacaoNaoFechadasColumnProps {
  onDelete: (id: string) => void
  authorization?: {
    canCreate?: boolean
    canDelete?: boolean
    canUpdate?: boolean
  }
}

export const NegociacaoNaoFechadasColumns = ({
  onDelete,
  authorization
}: INegociacaoNaoFechadasColumnProps): IColumn[] => {
  return [
    {
      Header: 'Código',
      accessor: 'codigo',
      Filter: props =>
        DefaultColumnFilter({
          column: props.column as HeaderGroup<{}>,
          onlyNumber: true
        })
    },
    {
      Header: 'Produto',
      accessor: 'produto'
    },
    {
      Header: 'Cliente',
      accessor: 'cliente'
    },
    {
      Header: 'Centro Custo',
      accessor: 'centroCusto'
    },
    {
      Header: 'Frete Cotado',
      accessor: 'freteCotado',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Frete Vencedor',
      accessor: 'freteVencedor',
      Filter: props =>
        NumericFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Data Negociação',
      accessor: 'dataNegociacao',
      Filter: props =>
        DatePickerFilter({
          column: props.column as HeaderGroup<{}>
        })
    },
    {
      Header: 'Origem',
      accessor: 'origem'
    },
    {
      Header: 'Destino',
      accessor: 'destino'
    },
    {
      Header: '',
      accessor: 'actions',
      sort: false,
      selectable: false,
      defaultClick: false,
      Cell: (props: any) => (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {props.row.original && (
            <>
              <When value={authorization?.canUpdate} equals>
                <DeleteRecord
                  onDelete={() => onDelete(props.row.original.id)}
                />
              </When>
            </>
          )}
        </div>
      )
    }
  ]
}
