import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { GrupoNegociadorColumns } from './GrupoNegociadorColumns'
import { grupoNegociadorStore } from './stores'
import { GrupoNegociadorDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const GrupoNegociadorTable = observer(
  ({ onDelete, authorization }: Props) => {
    const { pathname } = useLocation()

    const KEY_TABLE = resourceTabela.GRUPOS_NEGOCIADORES
    useConfigTable(KEY_TABLE)
    const tableState = tableStore.getState(KEY_TABLE)
    const isTableReady =
      !grupoNegociadorStore.grupoNegociador.isLoading &&
      tableState &&
      grupoNegociadorStore.grupoNegociador.list.length

    const columns: Array<Column & IColumn> = useMemo(
      () => GrupoNegociadorColumns({ onDelete, authorization }),
      [onDelete, authorization]
    )

    const rows = grupoNegociadorStore.grupoNegociador.list.map(
      ({ ...grupo }: GrupoNegociadorDetail) => {
        return {
          ...grupo
        }
      }
    )

    return (
      <>
        <Spacer y={4} />
        <Paper>
          <TableContainer
            loading={grupoNegociadorStore.grupoNegociador.isLoading}
            totalCount={grupoNegociadorStore.grupoNegociador.totalCount}
          >
            {isTableReady ? (
              <BaseTable
                keyTable={KEY_TABLE}
                columns={columns}
                hasConditionClickRow={false}
                linkWithRedirectClickRow={pathname}
                data={rows}
              />
            ) : (
              <NoResultsTable
                loading={grupoNegociadorStore.grupoNegociador.isLoading}
              />
            )}
          </TableContainer>
        </Paper>
        <AddRecord path={pathname} authorization={authorization} />
      </>
    )
  }
)
