import { apiSauceInstance } from 'src/services/api'

export enum AcaoLogAuditoria {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  OTHER = 'OTHER'
}

interface CreateLogDto {
  usuario: string
  acao: AcaoLogAuditoria
  modelo: string
  modeloId: string
  payload: unknown
  recurso?: string
}

export function createLog(data: CreateLogDto) {
  return apiSauceInstance.post<any, { message: string }>(
    '/logs-auditoria',
    data
  )
}
