import { SelectOption } from 'src/components/Formik/Forms/SelectInput'

export enum CorAviso {
  VERDE = 'VERDE',
  VERMELHO = 'VERMELHO',
  AMARELO = 'AMARELO'
}

export interface AvisoDetail {
  id: string
  descricao: string
  cor: CorAviso
  createdAt: Date
  updatedAt: Date
  grupoNegociadorId: string
}

export interface AvisoDto {
  descricao: string
  cor: CorAviso
}

export interface AvisoFormData {
  id: string
  descricao: string
  cor: CorAviso
}

export const corAvisoOptions: SelectOption[] = [
  { value: CorAviso.AMARELO, label: 'Amarelo' },
  { value: CorAviso.VERDE, label: 'Verde' },
  { value: CorAviso.VERMELHO, label: 'Vermelho' }
]
