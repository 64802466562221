import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useFormikContext } from 'formik'
import { get } from 'lodash'
import React, { useMemo, useState } from 'react'
import { RelatorioInternoFormData } from '../types'

const useStyles = makeStyles(() => ({
  heading: {
    flexBasis: '33.33%',
    flexShrink: 0
  }
}))

interface Props {
  keys: string[]
  title: string
  children: React.ReactNode
}

export const ConfigAccordion = ({ keys, children, title }: Props) => {
  const formik = useFormikContext<RelatorioInternoFormData>()
  const classes = useStyles()
  const [expanded, setExpanded] = useState(false)

  const values: boolean[] = keys.map(key => get(formik.values, `config.${key}`))
  const selected = values.every(value => value)

  const handleCheckAll = (keys: string[], checked: boolean) => {
    for (const key of keys) {
      formik.setFieldValue(`config.${key}`, checked)
    }
  }

  const countSelected = useMemo(
    () => {
      return values.reduce((prev, curr) => prev + Number(curr), 0)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    values
  )

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, expanded) => setExpanded(expanded)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className={classes.heading} variant="h6">
              {title}{' '}
              {!expanded
                ? `(${countSelected}/${keys.length} selecionados)`
                : ''}
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={selected}
                  onClick={e => {
                    e.stopPropagation()
                  }}
                  onChange={(_, checked) => {
                    // setSelected(checked)
                    handleCheckAll(keys, checked)

                    if (!expanded) {
                      setExpanded(true)
                    }
                  }}
                  color="primary"
                />
              }
              label="Selecionar todos"
            />
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
