import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { Column } from 'react-table'
import { BaseTable, IColumn } from 'src/components/BaseTable'
import { useConfigTable } from 'src/hooks/useConfigTable'
import { tableStore } from 'src/stores/TableStore'
import { resourceTabela } from 'src/utils/resourcesTabela'
import { AddRecord, Paper, TableContainer } from '../../components'
import NoResultsTable from '../../components/NoResultsTable'
import Spacer from '../../components/Spacer'
import { PaisesColumns } from './PaisColumns'
import { paisesStore } from './stores'
import { PaisDetail } from './types'

interface Props {
  onDelete: (id: string) => void
  checks?: {
    ids: string[]
    onCheck: (id: string) => void
  }
  authorization?: {
    canCreate: boolean
    canDelete: boolean
    canUpdate: boolean
  }
}

export const PaisesTable = observer(({ onDelete, authorization }: Props) => {
  const { pathname } = useLocation()

  const KEY_TABLE = resourceTabela.PAISES
  useConfigTable(KEY_TABLE)
  const tableState = tableStore.getState(KEY_TABLE)
  const isTableReady =
    !paisesStore.paises.isLoading &&
    tableState &&
    paisesStore.paises.list.length

  const columns: Array<Column & IColumn> = useMemo(
    () => PaisesColumns({ onDelete, authorization }),
    [onDelete, authorization]
  )

  const rows = paisesStore.paises.list.map(({ ...pais }: PaisDetail) => {
    return {
      ...pais
    }
  })

  return (
    <>
      <Spacer y={4} />
      <Paper>
        <TableContainer
          loading={paisesStore.paises.isLoading}
          totalCount={paisesStore.paises.totalCount}
        >
          {isTableReady ? (
            <BaseTable
              keyTable={KEY_TABLE}
              columns={columns}
              hasConditionClickRow={false}
              linkWithRedirectClickRow={pathname}
              data={rows}
            />
          ) : (
            <NoResultsTable loading={paisesStore.paises.isLoading} />
          )}
        </TableContainer>
      </Paper>
      <AddRecord path={pathname} authorization={authorization} />
    </>
  )
})
